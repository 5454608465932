$nasdaqBlue:                            #0094b3;
$nasdaqBlueHover:                       #00a9cc;
$blue:                                  #00bce3;
$blueMid:                               #4cd0eb;
$blueLight:                             #7fddf1;
$blueLightest:                          #bfeef8;
$grayDarkestHeader:                     #1d1e27;
$grayDarkest:                           #2d303a;
$grayDarker:                            #474a53;
$grayDark:                              #6b6d75;
$gray:                                  #96979c;
$grayMid:                               #b6b7ba;
$grayLight:                             #d5d6d8;
$grayLighter:                           #eaeaeb;
$grayLightest:                          #f4f4f5;
$yellow:                                #fecb00;
$yellowMid:                             #fed840;
$yellowLight:                           #fee57f;
$yellowLightest:                        #fff2bf;
$green:                                 #19b772;
$greenMid:                              #1dd182;
$greenLight:                            #85e6bb;
$greenLightest:                         #c9f4e1;
$red:                                   #da353b;

$grayLighterOpacity50:                  rgba(213, 214, 216, 0.5);
$lightGrayBackground:                   #F1F1F2;
$lightBlueBackground:                   #F1FAFE;
$lightMockGrayBackground:               #F7F7F9;
$lightMockBlueBackground:               #DDF8FF;
$badgeColorBackground:                  #EA4E56;
$badgeColorBackgroundBorder:            #BD2832;

$commonFontSize:                        13.5px;
$commonFontSizeLarge:                   16px;
$commonFontSizeSmall:                   11px;
$commonFontWeight:                      300;

$headerHight:                           68px;
$sidebarUpperBoxBackgroundColor:        #1B1D27;
$sidebarItemSelectedBackgroundColor:    #33363E;
$sidebarBackgroundColor:                $grayLighter;
$sidebarBackgroundHoverColor:           #ebeef0;
$sidebarItemBottomBorderColor:          unquote('#d5d6d880');
$sidebarItemHeight:                     61px;
$sidebarRightBorderColor:               #cbccce;
$sidebarWidth:                          240px;
$middlebarBackgroundColor:              #0C0D14;
$middlebarHeight:                       44px;
$highlightColor:                        #46ADDC;
$logoColor:                             #1080A2;
$rightMenuFontColor:                    #138DAC;
$documentSelectedBackground:            #E0F1F7;
$documentSelectedBackgroundHover:       #f6fdff;

$sideBarFontColor: $sidebarItemSelectedBackgroundColor;

/* Responsive settings */

$break-small: 320px;
$break-large: 800px;

/* Responsive settings */


$defaultFont:                           'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';

$nasdaqFontRegular:                     'SourceSansProRegular';
$nasdaqFontBold:                        'SourceSansProBold';
$nasdaqFontLight:                       'SourceSansProLight';
$nasdaqFontSemibold:                    'SourceSansProSemibold';

$resources-path:                        '..';
@import 'src/utils/variables';

dayview{
    height: 100%;

    > div{
        height: calc(100% - 67px);

        > div{
            &.header{
                position: relative;
                z-index: 1;
                /*box-shadow: 0px 2px 7px -4px #000;*/
                box-shadow: -1px 2px 4px -3px #000;
                height: 45px;

                > span{
                    font-size: 11.5px;
                    letter-spacing: normal;
                    line-height: 28px;
                    color: $grayDark;
                    font-family: $nasdaqFontLight;
                    text-align: center;
                    padding: 9px 0px 8px 0;
                    display: block;
                    float: left;
                    width: 80px;
                }

                > div{
                    width: calc(100% - 80px);
                    height: 45px;
                    margin-left: 80px;

                    event{
                        width: 100%;
                        position: relative;
                        display: block;
                        float: left;
                        z-index: 2;
                        padding: 2px;
                        height: 45px;

                        > div{
                            height: 100%;
                            background: rgba(234, 234, 235, 0.33);
                            border-left: 3px solid $yellow;
                            padding: 11px 7px;
                            font-size: 15px;
                            cursor: pointer;

                            span{
                                &:nth-child(1){
                                    display: block;
                                    font-family: $nasdaqFontSemibold;
                                    color: $grayDark;
                                }
                            }
                        }
                    }
                }
            }

            &.events{
                height: calc(100% - 45px);
                overflow: auto;
                overflow-x: hidden !important;

                ul{
                    &.hours-row{
                        margin-bottom: 5px;

                        li{
                            &.current-timeline{
                                border-right: 0;
                                border-bottom: 1px dashed $badgeColorBackground;
                                height: 0;
                                width: 100%;
                                z-index: 1;
                            }

                            &.current-timeline:before{
                                content: '';
                                height: 6px;
                                width: 6px;
                                border-radius: 5px;
                                background: $badgeColorBackground;
                                display: block;
                                top: -2.5px;
                                left: -9px;
                                position: relative;
                            }

                            titl{
                                left: -49px;
                                top: -9px;
                                position: absolute;
                                width: 39px;
                                display: block;
                                font-size: 13.5px;
                                text-align: right;
                                font-family: $nasdaqFontRegular;
                                color: $grayMid;
                            }
                            line{
                                border-bottom: 1px dashed $grayLighter;
                                width: 100%;
                                display: block;
                                position: absolute;
                                margin-top: 25px;
                            }

                            &:nth-child(2){
                                line{
                                    margin-top: 24px;
                                }
                                titl{
                                    display: none;
                                }
                            }
                        }
                    }

                    &:not(.hours-row){
                        position: relative;
                    }

                    li{
                        &:last-child{
                            height: 0;
                            border: 0;
                        }

                        event{
                            width: 100%;
                            position: relative;
                            display: block;
                            float: left;
                            z-index: 2;
                            padding: 0 2px;

                            > div{
                                height: 100%;
                                background: rgba(234, 234, 235, 0.33);
                                border-left: 3px solid $yellow;
                                padding: 7px;
                                font-size: 15px;
                                cursor: pointer;

                                span{
                                    &:nth-child(1){
                                        display: block;
                                        font-family: $nasdaqFontSemibold;
                                        color: $grayDark;
                                        max-width: calc(100% - 140px);
                                    }
                                    &:nth-child(2){
                                        font-size: 13px;
                                        position: relative;
                                        top: -2px;
                                        color: $gray;
                                    }
                                    &:nth-child(3){
                                        display: block;
                                        position: absolute;
                                        top: 12px;
                                        right: 12px;
                                        font-size: 12.5px;
                                        font-weight: 500;
                                        color: $grayDark;
                                    }
                                }
                            }

                            &.small{
                                > div{
                                    padding: 0 7px;

                                    span{
                                        &:nth-child(2),
                                        &:nth-child(3){
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .mCSB_scrollTools{
                    right: 0 !important;
                }
            }

            ul{
                height: 100%;

                &.hours-row{
                    display: block;

                    li{
                        margin-left: 55px;
                    }
                }

                li{
                    display: block;
                    height: 50px;
                    border-bottom: 1px solid $grayLighter;
                    border-right: 1px solid $grayLighter;
                    position: relative;

                    titl{
                        display: block;
                    }
                }
            }
        }
    }
}
@import 'src/utils/variables';
@import 'src/utils/classes';

.advanced-search{
    .results-list{
        width: calc(100% - 30px);

        > div{
            ul.table{
                li{
                    > div{
                        height: 57px;
                        font-size: 13.5px;

                        &:nth-child(1){
                            width: 30%;
                            text-align: left;
                            padding: 0px 10px 0px 0px;
                        }
                        &:nth-child(2){
                            width: 8%;
                            padding: 0;
                        }
                        &:nth-child(3){
                            color: $grayDarkest;
                            width: 15%;
                        }
                        &:nth-child(4){
                            color: $grayDarkest;
                            width: 10%;
                            text-align: left;
                        }

                        &:nth-child(5){
                            color: $grayDarkest;
                            width: 15%;
                            text-align: right;

                            div{
                                &:first-child{
                                    text-align: right;
                                    letter-spacing: -0.1px;
                                }
                                &:last-child{
                                    color: $grayDark;
                                    text-align: right;
                                    letter-spacing: -0.1px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
            }
        }

        .mCSB_outside + .mCSB_scrollTools{
            right: 0;
        }
    }
}

.ad-search{

    .results-list{
        height: 100%;

        spinner{
            &.show{
                display: block;

                #loading-spinner{
                    display: block !important;
                }
            }
        }
        >div{
            height: calc(100% - 61px);
            position: relative;

            > titl{
                height: 32px;
                width: 100%;
                color: $grayDarkestHeader;
                display: block;
                background: #d5d6d8;
                padding: 8px 10px;
                font-size: 11.5px;
                font-weight: 500;
            }

            ul.table{
                @extend .table;

                li{
                    &:hover {
                        background: $documentSelectedBackgroundHover;
                        cursor: pointer;
                    }

                    > div{
                        color: $nasdaqBlue;
                        font-size: 12px;
                        padding: 0px 10px;
                        vertical-align: middle;
                        text-align: left;
                        height: 47px;
                        border-bottom: 1px solid $grayLighter;

                        &:nth-child(1){
                            width: 30%;
                            text-align: left;
                        }
                        &:nth-child(2){
                            width: 8%;
                            padding: 0;
                        }
                        &:nth-child(3){
                            color: $grayDarkest;
                            width: 8%;
                        }
                        &:nth-child(4){
                            color: $grayDarkest;
                            width: 13%;
                            text-align: right;
                        }

                        > span{
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        icon{
                            img,
                            boardbook,
                            > div,
                            &::before{
                                width: 18px;
                                height: 23px;
                                display: inline-block;
                                font-size: 18px;
                                top: 3px;
                                position: relative;
                            }

                            &.folder > div {
                                color: $yellow;
                                font-family: SSStandard;
                            }
                        }
                    }

                    /*&.title{
                        height: 32px;
                        border-bottom: none;

                        titl{
                            display: block;
                            background: #d5d6d8;
                            width: 100%;
                            height: 100%;
                            padding: 8px 10px;
                            font-size: 11.5px;
                            font-weight: 500;
                        }
                    }*/

                }
            }
        }
    }

    search-filter-panel{
        width: 0px;
        overflow-x: hidden;
        height: 100%;
        float: left;
        border-right: 1px solid $grayLight;
        display: none;
        transition: width 300ms 0ms, left 300ms 0ms;
        -webkit-transition: width 300ms 0ms, left 300ms 0ms;

        &.show-panel{
            width: 300px;
        }
    }

    &.filter-panel{

        search-filter-panel{
            display: block;
        }

        .results-list{
            margin-left: 30px;

            &.show-panel{
                margin-left: 300px;
                transition: 300ms 0ms all;
                -webkit-transition: 300ms 0ms all;
                width: inherit;

                .top-panel{
                    padding-left: 10px;
                }

                > div{
                    padding-left: 10px;
                }
            }
        }

    }

}
@import 'src/utils/variables';

.bv-mobile .bv-repository-list .top-panel{

    titl:nth-child(2){
        width: calc(51% - 58px);
        max-width: inherit;
        margin-left: 40px;
    }

    .filename-resizer {
        display: none;
    }

    titl:nth-child(4){
           width:25%;
    }

}
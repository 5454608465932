@import 'src/utils/variables';

.newItemList .top-panel{
    width: 100%;
    height: 43px;
    border-bottom: 1px solid $grayLighter;
    font-size: $commonFontSize;
    color: $gray;
    padding-top: 19px;
    font-family: $nasdaqFontSemibold;

    titl{
        display: inline-block;
    }

    titl::after{
        content: "";
        display: inline-block;
        margin-left: 4px;
        position: relative;
        top: -1px;
        border-top: 6px solid $gray;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }

    titl:nth-child(1){
        margin-left: 20px;
    }

    titl:nth-child(2){
        margin-left: 17px;
        width: 100%;
        max-width: calc(100% - 544px);
    }
    titl:nth-child(3){
        width: 210px;
    }

    checkbox{
        float: right;
        margin-right: 24px;
        position: relative;
    }
}
@import 'src/utils/variables';

.bv-mobile .bvhome{

     .dropdown-menu{
        left:9px;
        min-width: 185px;
        top: 36px;
        border-radius: 0px;
    }

}
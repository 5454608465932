@import 'src/utils/variables';

.search-indexing{

    .modal-dialog{
        width: 770px;
    }

    section{
        border-bottom: 1px solid $grayLighter;
        display: block;

        button{
            float: right;
            font-size: 13px;
            padding: 5px 8px;
            outline: none !important;
        }

        > div{
            float: left;
            width: 160px;
            padding: 20px 18px 0;
            font-size: 13.5px;
            color: $grayDarkest;
        }

        > ul{
            display: block;
            margin-left: 160px;

            > li{
                height: 58px;
                padding: 20px;
                font-size: 13.5px;
                color: $grayDarker;

                >icon{
                    top: 2px;
                    position: relative;
                    color: $green;

                    &.false{
                        color: $red;
                    }
                }

                > button.status{
                    top: -5px;
                    position: relative;
                    color: #fff;
                    background: $nasdaqBlue;

                    &:hover{
                        color: #fff;
                        background: $nasdaqBlue;
                    }

                    &.index-status{
                        background: $red;
                        border: 1px solid $red;
                    }
                }

                /*> button::hover{
                    color: #fff;
                    background: $nasdaqBlue;
                }*/

                > ul{
                    > li{
                        display: inline-block;
                        width: 130px;
                        text-align: right;

                        &:first-child{
                            text-align: left;
                            width: 33px;
                        }
                    }
                }
            }
        }

        &:nth-child(3){

            > ul{
                > li{
                    height: 49px;
                    padding: 27px 20px 0px;
                    font-size: 11.5px;
                    font-family: SourceSansProRegular;
                    color: $gray;
                }
            }
        }

        &:nth-child(4){

            > ul{
                > li{
                    border-bottom: 1px solid $grayLighter;
                    margin-left: 20px;
                    padding: 19px 0px;

                    &:last-child{
                        border-bottom: none;
                    }
                }
            }
        }

        &:last-child{
            border-bottom: none;
            height: 68px;

            > div{
                button{
                    float: left;
                    margin: 0 5px;
                }
            }

            button{
                margin: 0 0px 0 20px;
            }
        }
    }
}
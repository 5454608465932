.nasdaq.bv-mobile{

    monthview{
        > div > div ul li > div {
            right: 0px;
            top: 60%;

            event{
                width: 12px;
                height: 12px;
                margin-top: 0px;
            }
        }
    }
}
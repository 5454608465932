@import 'src/utils/variables';

.follow-top-panel .top-panel{
    width: 100%;
    font-size: 11px;
    color: $gray;
    padding-top: 23px;
    font-family: $nasdaqFontSemibold;

    .title-box{
        position: relative;
        width: 100%;
        padding-top: 33px;
        border-bottom: 1px solid $grayLighter;
    }

	.title-box.tab-3{
		display: none;
	}
	
    titl{
        display: inline-block;
    }

    titl::after{
        content: "";
        display: inline-block;
        margin-left: 4px;
        position: relative;
        top: -1px;
        border-top: 6px solid $gray;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }

    .tab-2 titl:nth-child(3){
        right: 226px;
    }

    .nav-tabs{
        position: relative;
        display: block;
        top: 0;
        left: 0;
        border-bottom: 3px solid $grayLighter;
        padding: 0 20px;
    }

     .nav-tabs li{
        margin-bottom: -3px;
        border-bottom: 3px solid transparent;
        font-size: 11.5px;
        width: 25%;
        text-align: center;
     }

     .nav-tabs li bt{
        line-height: 0px;
        padding: 8px 12px;
        color: $gray;
        background: none;
        font-size: 11.5px;
     }

     .nav-tabs li.active,
     .nav-tabs li:hover{
        border-bottom: 3px solid $blue;
     }

     .nav-tabs li.active bt,
     .nav-tabs li:hover bt{
        color: $blue;
     }
     
     .tab-content{
     	display: none;
     }
     
     .tab-0 .follow-type-0{
     	margin-left: 10px;
        max-width: calc(100% - 670px);
        width: 100%;
     }
     .tab-0 .follow-name-0{
     	max-width: calc(100% - 462px);
        width: 100%;
     }
     .tab-0 .follow-addedby-0{
     	margin-left: 10px;
    	max-width: calc(100% - 548px);
    	width: 100%;
     }
     .tab-0 .follow-date-0{
     	margin-left: 10px;
    	max-width: calc(100% - 628px);
    	width: 100%;
     }
     
     .tab-1 .follow-name-1{
     	margin-left: 60px;
    	max-width: calc(100% - 292px);
    	width: 100%;
     }
     
     .tab-2 .follow-name-2{
     	margin-left: 60px;
    	max-width: calc(100% - 292px);
    	width: 100%;
     }

}
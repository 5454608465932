@import 'src/utils/variables';

.prefill-survey{
	.modal-dialog{
        width: 770px;
    
    	.modal-content{
		    .bv-input-btn-box{
			    .bv-input-btn:nth-child(1){
				    	right: 220px;
				    }
			    .bv-input-btn:nth-child(2){
			    	width: 200px;
			    }
		    }
		    .checkLabel{
		    	color: #4cd0eb;
		    }
	    }
	}
	.UserListIcon {
	    text-align: center;
	    width: 45px;
	    height: 45px;
	    overflow: hidden;
	    display: inline-block;
	    margin: 15px 12px 0 0;
	    float: left;
	}
	.filterBox1 {
	    overflow: hidden;
	    width: 100%;
	    padding: 14px 20px 20px 15px;
	    font-size: 16px;
	    position: relative;
	    border-bottom: 1px solid #eaeaeb; padding: 0px
	}
	.userList {
	    position: absolute;
	    overflow: hidden;
	    height: calc(100% - 99px);
	    width: 100%;
	}
	.userListItem {
	    height: 55px;
	    margin: 0 0 0px 0;
	    width: 100%;
	    display: inline-block;
	    padding: 6px 0px 6px 15px;
	    margin: 3px 0px 3px 0px;
	    border-bottom: 1px solid #eaeaeb;
    }
    .userAvatar {
	    height: 45px;
	    float: left;
	    white-space: nowrap;
	    width: 100%;
	}
	.userNameBox {
	    text-overflow: ellipsis;
	    overflow: hidden;
	    display: inline-block;
	    height: 48px;
	    width: calc(100% - 180px);
	}
	.userCheckBox {
	    text-overflow: ellipsis;
	    overflow: hidden;
	    display: inline-block;
	    height: 48px;
	    padding-top: 18px;
	    padding-right: 35px;
    	float: right;
	}
	.checkBoxIcon {
		color:#00bce3;
	}
	.searchUser1:focus {
		outline: none;
	}
	.searchUser1 {
		width: 100%;
		display: block;
		margin-top: -2px;
	}
	.disableLabel {
		color: #706D6D;
	}
	.userName {
	    font-size: 15px;
	    display: inline-block;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    max-width: 140px;
	    line-height: 48px;
    }
    .clickCursor {
    	cursor: pointer;
    }
}
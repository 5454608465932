@import '../../utils/variables';
@import '../../utils/mixins';
@import 'pdfviewer-fonts';

html[dir='rtl'] .user-comments-heading {
     margin-right:45px;
       	h3 {
            padding-left: 18px;
         }
}
html[dir='rtl'] 
{
   .add-new-comment{
      	margin-right: 40px !important;
      	width: 90% !important;
      	
      	.typeahead{
      		padding: 10px !important;
      	}
     }
     .user-comments-list{
      	margin-right: 40px !important;
      }
}


.nasdaq{
    .panel-summary {
        box-sizing: border-box;
        overflow: hidden;
        height: 100%;
    }
    
    .user-comments-heading {
        box-sizing: border-box;
        background-color: #ffffff;
        border-bottom: 1px solid $grayLighter;
        padding: 10px;
        width: 100%;

        h3 {
            font-family: $nasdaqFontRegular, Arial, Helvetica, sans-serif;
            font-weight: 400;
            line-height: 1.25em;
            text-rendering: optimizelegibility;
            letter-spacing: -0.03em;
            margin: 0 0 5px;
            color: #1D1E25;
            font-size: 1.8rem;
            font-weight: 400;
        }
    }

    .add-new-comment {
        border-bottom: 1px solid #eaeaeb;
        width: 100%;
        box-sizing: border-box;
        border-left: 5px solid #00bce3;
        color: black;
        font-size: 14px;
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: white;

        .typeahead {
            border-top: 1px solid $grayLight;
            padding: 10px 10px 10px 25px;
            overflow-x: auto;

            textarea {
                display: block;
                padding: 7px 11px 6px;
                border-radius: 6px;
                color: #1D1E25;
                font-size: 100%;
                width: 100%;
                box-sizing: border-box;
                line-height: 1.25em;
                vertical-align: middle;
                font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

                &:focus {
                    outline: 0;
                    border: 1px solid $blueMid;
                }
            }

            & .typeahead-actions-buttons {
                text-align: right;
                margin-top: 10px;
            }
        }
    }

    .user-comments-list {
        position: relative;
        padding: 0;
        margin: 0;
        list-style: none;
        overflow-y: auto;
        @include calc(height, "100% - 118px");
        div {
            height: auto;
        }

        .comments {
            border-bottom: 1px solid $grayLighter;
            display: block;
            width: 100%;
            box-sizing: border-box;
        }

        .add-annotation {
            border-left: 5px solid $blue;

            .typeahead {
                background-color: #ffffff;
                border: 0;
                display: block;
            }
        }

        .comment-actions-buttons {
            box-sizing: border-box;
            padding: 10px 0;
            width: 100%;

            .comment-delete {
                color: $grayLight;
                font-size: 0.9em;
                font-weight: 500;
            }

            .comment-edit {
                color: $grayLight;
                font-size: 0.9em;
                font-weight: 500;
            }

            .send-reply {
                color: $grayLight;
                text-decoration: none;
            }
        }

        .user-comment {
            padding: 10px 10px 0;

            .user-comment-meta {
                padding-left: 50px;
                position: relative;

                .avatar {
                    position: absolute;
                    left: 0;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        width: 40px;
                    }
                }

                .user-name {
                    padding: 5px 0 0;
                    font-size: 1em;
                    font-weight: 400;
                }

                .timestamp {
                    color: $gray;
                    font-size: 12px;
                    padding: 5px 0 0;
                }
            }

            .user-message {
                font-size: 1em;
                line-height: 1.2em;
                font-weight: 400;
                padding: 10px 0;
                word-wrap: break-word;

                .highlight {
                    background-color: #bfeef8;
                    padding: 0 1px;
                    border: 1px solid #00bce3;
                }
            }


            &.reply-message {
                background-color: $grayLightest;
                border-top: 1px solid $grayLight;
                padding: 0;

                & .reply-message-item, & .initiateReplyTypeahead {
                    padding: 10px 10px 10px 25px;
                }
            }

            .comment-edit, .send-reply {
                &:hover {
                    color: $gray;
                }
            }

            .comment-delete {
                &:hover {
                    color: #da353b;
                }
            }
        }

        .comment-name-highlight {
            display: inline-block;
            background-color: #bfeef8;
            padding: 0 1px;
            border: 1px solid #00bce3;
        }

        .view-more-comments, .view-more-posts {
            padding: 10px;
            text-align: center;
            border-top: 1px solid $grayLight;

            a {
                color: $nasdaqBlue;
                text-decoration: none;
                font-size: 1em;
                letter-spacing: 0px;
            }
        }

        .view-more-posts {
            border-top: none;
            border-bottom: 1px solid $grayLight;
        }

        .typeahead {
            background-color: $grayLightest;
            border-top: 1px solid $grayLight;
            border-left: 5px solid $grayLightest;
            padding: 10px 10px 10px 25px;
            position: relative;
            display: none;

            textarea {
                display: block;
                padding: 7px 11px 6px;
                border-radius: 6px;
                color: #1D1E25;
                font-size: 100%;
                width: 100%;
                box-sizing: border-box;
                line-height: 1.25em;
                vertical-align: middle;
                font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

                &:focus {
                    outline: 0;
                    border: 1px solid $blueMid;
                }
            }

            & .typeahead-actions-buttons {
                text-align: right;
                margin-top: 10px;
            }
        }
    }

    .typeahead {
        .custom-popup-wrapper {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1000;
            display: none;
            background-color: #f9f9f9;

            .message {
                padding: 10px 20px;
                border-bottom: 1px solid #ddd;
                color: #868686;
            }
        }
    }

    .typeahead-edit {
        textarea {
            display: block;
            padding: 7px 11px 6px;
            border-radius: 6px;
            color: #1D1E25;
            font-size: 100%;
            width: 100%;
            box-sizing: border-box;
            line-height: 1.25em;
            vertical-align: middle;
            font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

            &:focus {
                outline: 0;
                border: 1px solid $blueMid;
            }
        }

        & .typeahead-actions-buttons {
            text-align: right;
            margin-top: 10px;
        }
    }

    .temp-comment{
        height: 81px;
    }

    .comment-typeahead-edit {
        @extend .typeahead-edit;
        display: block;
        border-top: 0;
        margin-top: 10px;
        padding: 10px 0;
        background: white;
    }

    .reply-comment-typeahead-edit {
        @extend .typeahead-edit;
        border-top: 0;
        margin-top: 10px;
        padding: 10px 0;
    }

    .dropdown {
        position: relative;
        outline: none;

        .dropdown-menu {
            width: 100%;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1000;
            display: none;
            float: left;
            min-width: 160px;
            padding: 5px 0;
            margin: 2px 0 0;
            font-size: 14px;
            text-align: left;
            list-style: none;
            background-color: #fff;
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
            border: 1px solid #ccc;
            border: 1px solid rgba(0,0,0,.15);
            border-radius: 4px;
            -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
            box-shadow: 0 6px 12px rgba(0,0,0,.175);
            max-height: 250px;
            overflow-y: auto;

            li > a {
                display: block;
                padding: 3px 20px;
                clear: both;
                font-weight: 400;
                line-height: 1.42857143;
                color: #333;
                white-space: nowrap;

                &:hover {
                    color: #fff;
                    text-decoration: none;
                    background-color: #337ab7;
                    outline: 0;
                }
            }
        }

        &.open {
            .dropdown-menu {
                display: block;
            }
        }
    }

    .listItem.ng-enter {
        opacity: 0;
        transition: all .5s ease;
    }

    .listItem.ng-enter-active {
        opacity: 1;
    }

    .hide {
        display: none;
    }

    .clearfix::after {
        display: block;
        content: "";
        clear: both;
    }

    .text-right {
        text-align: right;
    }

    .tooltip {
        &.top {
            margin-left: 0;

            .tooltip-arrow {
                border-top-color: #000000;
                opacity: .9;
            }
        }

        .tooltip-inner {
            background-color: #000000;
            padding: 9px;
            opacity: .9;
        }
    }
    /* Animations */

    /*.user-comments-list li.ng-enter {
        transition: 0.5s linear all;
        max-height: 0;
    }

    .user-comments-list li.ng-enter.ng-enter-active {
        max-height: 500px;
    }

    .user-comments-list li.ng-leave {
        transition: 0.5s linear all;
        max-height: 500px;
    }

    .user-comments-list li.ng-leave.ng-leave-active {
        max-height: 0;
    }*/

    /* tag user dropdown */

    

    /* Buttons */
    .btn {
        display: inline-block;
        margin-bottom: 0;
        padding: 7px 11px 6px;
        border-radius: 3px;
        cursor: pointer;
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.25em;
        text-align: center;
        vertical-align: middle;
        -webkit-font-smoothing: antialiased;
        transition: background-color 0.2s linear 0.1s;
    }

    .btn input[type=radio],
    .btn input[type=checkbox] {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none;
    }

    label.btn {
        margin: 0;
    }

    .btn-group[data-toggle="buttons"] .btn {
        margin-left: -5px;
    }

    .btn-group[data-toggle="buttons"] .btn:first-of-type {
        margin-left: 0;
    }

    .btn--large {
        padding: 9px 14px;
        border-radius: 6px;
        font-size: 112.5%;
        line-height: normal;
    }

    .btn--large [class^="icon-"] {
        margin-top: 2px;
    }

    .btn--small {
        padding: 5px 9px 5px;
        font-size: 86%;
        line-height: 1.15em;
    }

    .btn--small [class^="icon-"] {
        margin-top: 0;
    }

    .btn--mini {
        padding: 3px 6px 3px;
        font-size: 78.57%;
        line-height: 1.1em;
    }

    .btn--block {
        display: block;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        box-sizing: border-box;
    }

    .btn--block + .btn--block {
        margin-top: 5px;
    }

    input[type="submit"].btn--block,
    input[type="reset"].btn--block,
    input[type="button"].btn--block {
        width: 100%;
    }

    .btn {
        background: #ffffff;
        border: 1px solid #0094b3;
        color: #0094b3;
    }

    .btn:hover,
    .btn:active,
    .btn:focus {
        background: #ffffff;
        border-color: #00b2d7;
        color: #00b2d7;
    }

    .btn.active {
        background: #0094b3;
        border-color: #0094b3;
        color: #ffffff;
    }

    .btn--primary {
        background: #0094b3;
        border: 1px solid #0094b3;
        color: #ffffff;
    }

    .btn--primary:hover,
    .btn--primary:active,
    .btn--primary:focus {
        background: #00a9cc;
        border-color: #00a9cc;
        color: #ffffff;
    }

    .btn--primary.active {
        background: #0094b3;
        border-color: #0094b3;
        color: #ffffff;
    }

    .btn--danger {
        background: #da353b;
        border: 1px solid #da353b;
        color: #ffffff;
    }

    .btn--danger:hover,
    .btn--danger:active,
    .btn--danger:focus {
        background: #f16669;
        border-color: #f16669;
        color: #ffffff;
    }

    .btn--danger.active {
        background: #0094b3;
        border-color: #0094b3;
        color: #ffffff;
    }

    .btn--success {
        background: #19b772;
        border: 1px solid #19b772;
        color: #ffffff;
    }

    .btn--success:hover,
    .btn--success:active,
    .btn--success:focus {
        background: #1dd182;
        border-color: #1dd182;
        color: #ffffff;
    }

    .btn--success.active {
        background: #0094b3;
        border-color: #0094b3;
        color: #ffffff;
    }

    .dark-theme .btn {
        background: transparent;
        border: 1px solid #0094b3;
        color: #0094b3;
    }

    .dark-theme .btn:hover,
    .dark-theme .btn:active,
    .dark-theme .btn:focus {
        background: #ffffff;
        border-color: #00b2d7;
        color: #00b2d7;
    }

    .dark-theme .btn.active {
        background: #0094b3;
        border-color: #0094b3;
        color: #ffffff;
    }

    .dark-theme .btn--primary {
        background: transparent;
        border: 1px solid #eaeaeb;
        color: #eaeaeb;
    }

    .dark-theme .btn--primary:hover,
    .dark-theme .btn--primary:active,
    .dark-theme .btn--primary:focus {
        background: transparent;
        border-color: #ffffff;
        color: #ffffff;
    }

    .dark-theme .btn--primary.active {
        background: #0094b3;
        border-color: #0094b3;
        color: #ffffff;
    }

    .dark-theme .btn--danger {
        background: transparent;
        border: 1px solid #da353b;
        color: #da353b;
    }

    .dark-theme .btn--danger:hover,
    .dark-theme .btn--danger:active,
    .dark-theme .btn--danger:focus {
        background: transparent;
        border-color: #f16669;
        color: #f16669;
    }

    .dark-theme .btn--danger.active {
        background: #0094b3;
        border-color: #0094b3;
        color: #ffffff;
    }

    .dark-theme .btn--success {
        background: transparent;
        border: 1px solid #19b772;
        color: #19b772;
    }

    .dark-theme .btn--success:hover,
    .dark-theme .btn--success:active,
    .dark-theme .btn--success:focus {
        background: transparent;
        border-color: #1dd182;
        color: #1dd182;
    }

    .dark-theme .btn--success.active {
        background: #0094b3;
        border-color: #0094b3;
        color: #ffffff;
    }

    .btn--link {
        background: transparent;
        border: 1px solid transparent;
        color: #0094b3;
        border: none;
        box-shadow: none;
    }

    .btn--link:hover,
    .btn--link:active,
    .btn--link:focus {
        background: tranparent;
        border-color: transparent;
        color: #00bee6;
    }

    .btn--link.active {
        background: #0094b3;
        border-color: #0094b3;
        color: #ffffff;
    }

    .dark-theme .btn--link,
    .bar--link {
        background: transparent;
        border: 1px solid transparent;
        color: #ffffff;
        border: none;
    }

    .dark-theme .btn--link:hover,
    .bar--link:hover,
    .dark-theme .btn--link:active,
    .bar--link:active,
    .dark-theme .btn--link:focus,
    .bar--link:focus {
        background: tranparent;
        border-color: transparent;
        color: #0094b3;
    }

    .dark-theme .btn--link.active,
    .bar--link.active {
        background: #0094b3;
        border-color: #0094b3;
        color: #ffffff;
    }

    .btn.disabled,
    .btn[disabled] {
        box-shadow: none;
        color: #b6b7ba;
        cursor: not-allowed;
    }

    .btn.disabled:not(.btn--link),
    .btn[disabled]:not(.btn--link) {
        background: #eaeaeb;
        border-color: #eaeaeb;
    }

    .dark-theme .btn.disabled:not(.btn--link),
    .dark-theme .btn[disabled]:not(.btn--link) {
        background: transparent;
        border: 1px solid #96979c;
        color: #96979c;
    }

    .dark-theme .btn.disabled:not(.btn--link):hover,
    .dark-theme .btn[disabled]:not(.btn--link):hover,
    .dark-theme .btn.disabled:not(.btn--link):active,
    .dark-theme .btn[disabled]:not(.btn--link):active,
    .dark-theme .btn.disabled:not(.btn--link):focus,
    .dark-theme .btn[disabled]:not(.btn--link):focus {
        background: transparent;
        border-color: #96979c;
        color: #96979c;
    }

    .dark-theme .btn.disabled:not(.btn--link).active,
    .dark-theme .btn[disabled]:not(.btn--link).active {
        background: #0094b3;
        border-color: #0094b3;
        color: #ffffff;
    }

    .btn__action-icon {
        font-size: 78.57%;
    }

    .loader-wrap {
        height: 0;

        .loader {
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.8);
            z-index: 99;
            text-align: center;

            img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
    }


}

mentio-menu {
        display: none;
        width:calc(20% - 44px);
        max-height: 140px;
        overflow-y: auto;
        min-width: 244px;
        margin: 17px 0 0 8px;
        border: 1px solid #ccc;
        right: 5px;
        border-radius:5px;
    }

    .user-search {
        background: #ffffff;
        border: 1px solid $grayLight;
        border-bottom: none;
        list-style-type: none;

        .list-group-item {
            padding: 5px;
            min-height: 23px;
            line-height: 23px;
            border-bottom: 1px solid $grayLight;

            .user-photo {
                max-width: 25px;
                max-height: 25px;
                border-radius: 15px;
                float: left;
                margin-right: 10px;
            }

            .user-first-letter {
                width: 24px;
                background-color: $nasdaqBlue;
                font-weight: lighter;
                font-size: 14px;
                display: inline-block;
                color: #fff;
                border-radius: 50%;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
            }

            &.active {
                color: #fff;
                background-color: $nasdaqBlue;
                border-color: $grayLight;

                .user-first-letter {
                    color: $nasdaqBlue;
                    background: #fff;
                }
            }

            .text-primary {
                font-size: 1.5em;
                max-width: 84%;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

        }
    }
@import 'src/utils/variables';

monthview{
    height: 100%;

    ul{
        width: 14.28%;
        height: 100%;
        display: inline-block;

        li{
            border-bottom: 1px solid $grayLighter;
            border-right: 1px solid $grayLighter;
        }
    }

    > div{
        height: calc(100% - 67px);

        > ul{
            height: 30px;

            li{
                font-size: 11.5px;
                letter-spacing: normal;
                line-height: 28px;
                padding: 2px;
                color: $grayDark;
                font-family: $nasdaqFontLight;
                text-align: center;
                height: 30px;
            }

            &.current-day{
                background: rgba(233, 248, 255, 0.5);

                li{
                    font-family: $nasdaqFontRegular;
                }
            }
        }

        > div{
            height: calc(100% - 30px);
            min-height: 330px;

            ul{
                li{
                    height: 19.989%;
                    text-align: right;
                    padding: 15px;
                    font-family: SourceSansProSemibold;
                    font-size: 16px;
                    color: $grayLight;
                    position: relative;

                    > div{
                        position: absolute;
                        right: 5px;
                        top: 40%;
                        width: calc(100% - 13px);

                        @media screen and (max-height: 800px) {
                            overflow: hidden;
                            height: 30px;

                            event{
                                width: 12px !important;
                                height: 12px !important;
                                margin-top: 15px;
                            }
                        }

                        event{
                            width: 15px;
                            height: 15px;
                            border-radius: 15px;
                            display: block;
                            background: $yellow;
                            float: right;
                            margin-right: 10px;
                            margin-bottom: 5px;
                            cursor: pointer;
                        }
                    }

                    &.current{
                        color: $grayDark;
                    }

                    &.current-day{
                        span{
                            z-index: 1;
                            position: relative;
                            color: #fff;
                            text-align: center;
                            width: 17.5px;
                            display: inline-block;
                        }
                    }
                    &.current-day::after{
                        content: '';
                        width: 22px;
                        height: 22px;
                        display: block;
                        border-radius: 15px;
                        position: absolute;
                        background: $blue;
                        right: 13px;
                        top: 14px;
                    }

                    &:before{
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                    }

                    &:hover{
                        &:before{
                            border: 2px solid $blue;
                        }
                    }

                    &.events-opened,
                    &.ng-click-active{
                        background: $grayLightest;

                        &:before{
                            border: 2px solid $blue;
                        }
                    }
                }

                &.weekend{
                    background: $lightMockGrayBackground;
                }

                &.six-weeks{
                    li{
                        height: 16.65%;
                    }
                }
            }
        }
    }
}





.nasdaq{

    .bv-conf-dialog .modal-dialog {
        position:relative;
        margin-top:10px;
        padding-top:0px;
        min-width: 800px;
        overflow: hidden;
    }

    /*.bv-conf-present-doc-dialog .modal-dialog {
        position:relative;
        margin-top:10px;
        padding-top:0px;
        min-width: 800px;
        min-height:600px;
    }*/

    .bv-conf-present-doc-dialog .modal-dialog {
        position: absolute;
        margin-top: 2px;
        padding-top: 0px;
        min-width: 800px;
        min-height: 600px;
    }

    /*.ngdialog-theme-default .bv-presentation-dialog {
        width:80%;
    }*/

    /*.ngdialog.ngdialog-theme-default div.ngdialog-content {
        width: 80%;
    }*/

    .bv-conf-main-container-div {
        height:500px;
    }

    .bv-conf-agenda-div-visible {
        /*width:300px;*/
        width:98%;
        height:100%;
        float:left;
        border-right: 1px solid #515151;
        border-right-height : 95%;
    }

    .bv-conf-agenda-div-visible1 {
        width:300px;
        height:100%;
        float:left;
        border-right: 1px solid #515151;
        border-right-height : 95%;
    }

    .bv-conf-agenda-div-invisible{
        width:0px;
        height:0px;
        display:none;
    }

    .bv-conf-non-agenda-div-with-agenda {
        width:500px;
        /*height:100%;*/
        float:right;
        margin:0 auto -30px;
        min-height:500px;
        height:auto !important;
        height:500px;
    }

    .bv-conf-non-agenda-div-wo-agenda {
        width:800px;
        /*height:100%;*/
        float:right;
        margin:0 auto -30px;
        min-height:500px;
        height:auto !important;
        height:500px;
    }

    .bv-conf-non-agenda-div-header {
        width: 100%;
        height: 100px;
        /*background-color:blue;*/
        position:relative;
        left:0;
        top:0;
        border-bottom: 1px solid #515151;
        border-bottom-width : 90%;
    }


    .bv-conf-non-agenda-div-footer-with-agenda {
        border-top: 1px solid #515151;
        border-top-width : 90%;
        width:100%;
        height: 80px;
    }

    .bv-conf-non-agenda-div-footer-wo-agenda {
        width:0px;
        height:0px;
        display:none;
        /*background-color:green;*/
    }

    .bv-conf-non-agenda-div-container-with-agenda
    {
        height:320px;
    }

    .bv-conf-non-agenda-div-container-wo-agenda
    {
        height:400px;
        overflow-y:auto;
    }


    /*.bv-conf-non-agenda-div-footer {
        width:100%;
        height: 60px;
    }*/

    .bv-conf-agenda-div-visible attachments{
        max-height: 400px;
        overflow-y: auto;
    }

    .bv-conf-agenda-div-visible attachments tr{
        margin: 10px 0px;
    }
    .bv-conf-agenda-div-visible attachments td>div{
        margin-top:1px;
        display: inline-block;
        height: 39px;
    }
    .bv-conf-agenda-div-visible attachments td img{
        width: 30px;
    }
    .bv-conf-agenda-div-visible attachments td>span{
        position: relative;
        left: 10px;
        vertical-align: top;
    }

    .bv-conf-agenda-div-visible1 attachments{
        max-height: 400px;
        overflow-y: auto;
    }

    .bv-conf-agenda-div-visible1 attachments tr{
        margin: 10px 0px;
    }
    .bv-conf-agenda-div-visible1 attachments td>div{
        margin-top:1px;
        display: inline-block;
        height: 39px;
    }
    .bv-conf-agenda-div-visible1 attachments td img{
        width: 30px;
    }
    .bv-conf-agenda-div-visible1 attachments td>span{
        position: relative;
        left: 10px;
        vertical-align: top;
    }

    /*.bv-conf-attachment-row {
        height: 65px;
        margin: 5px 0 0 5px;
        text-align: center;
        width: 98%;
        background: #FFFFFF;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        position: relative;
        display: inline-block;

    }*/

    /*.bv-conf-attachment-row:hover {
        overflow-x: auto;
        overflow-y: hidden;
    }*/

    .bv-conf-message-attachment {
        display: inline-block;
        margin: 0 5px 0 0;
    }

    .bv-conf-message-attachment-img {
        width: 30px;
        height: 40px;
        margin: 0 5px 5px 0;
        box-shadow: 5px 5px 3px 0px #888888;
        -webkit-box-shadow: 3px 10px 33px 0px #888888;
        -moz-box-shadow: 3px 10px 33px 0px #888888;
        border-top: 1px solid #DDDDDD;
        border-left: 1px solid #DDDDDD;
    }

    .bv-conf-atchmt-icon {
        display: inline-block;
        margin: 0 5px 0 0;
        height:45px;
    }
    .bv-conf-atchmt-icon  img{
        width: 30px;
        height: 40px;
        margin: 5px 5px 5px 0;
        box-shadow: 5px 5px 3px 0px #888888;
        -webkit-box-shadow: 0px 0px 10px 3px #888888;
        -moz-box-shadow: 0px 0px 10px 3px #888888;
        /*border-top: 1px solid #DDDDDD;
        border-left: 1px solid #DDDDDD;*/
    }

    /*.attachment-image:hover {
        margin: 0 5px 0 0;
    }*/

    .bv-conf-message-attachment-name {
        text-align:center;
        width: 30px;
        overflow: hidden;
    }

    .bv-conf-attendee span {
        cursor: pointer;
        color: #036EAA;
        -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; standard-user-select: none;
    }

    .bv-conf-attendee_right_click_menu {
        display: block;
        position: fixed;
        z-index: 1000;
        float: right;
        min-width: 180px;
        padding: 7px 0;
        margin: 0px 135px 0;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ccc;
        border: 1px solid rgba(0, 0, 0, .15);
        border-radius: 4px;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    }

    .bv-conf-alert-img {
        top:7px;
        height:30px;
        width:30px;
        display:block;
        position:relative;
        /*background:url('../assets/images/BVLiveryMeetingCenterChiclet@2x.png');*/
    }

    .bv-conf-present-btn{
        color: #FFF;
        min-width: 50px;
        background-color: #3596BD;
        border-style: none;
        max-height: 17px;
        min-height: 17px;
        border-radius: 4px;
        font-size: 12px;
    }

    .bv-conf-make-presenter-btn
    {
        color: #FFF;
        min-width: 50px;
        background-color: #3596BD;
        border-style: none;
        max-height: 17px;
        min-height: 17px;
        border-radius: 4px;
        font-size: 12px;
    }

    .bv-conf-pdf-view-lables{
        display: inline;
        font-size: 75%;
        font-weight: bold;
        line-height: 1;
        /* color: #fff; */
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .25em;
    }

    #confTabs div li > a {
      line-height: 0.428571;
    }

    @-webkit-keyframes bv-conf-blinkConfIcon {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    @keyframes bv-conf-blinkConfIcon {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    .bv-conf-blinkConfIcon {
        animation-duration: 1s;
        -webkit-animation-duration: 1s;
        animation-name: bv-conf-blinkConfIcon;
        -webkit-animation: bv-conf-blinkConfIcon;
        animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
        animation-direction: alternate;
        -webkit-animation-direction: alternate;
        animation-timing-function: ease-in-out;
        -webkit-animation-timing-function: ease-in-out;
    }

    .bv-conf-atchmt-list ul
    {
        list-style:none;
    }

    .bv-conf-atchmt-list li
    {
        display:list-item;
        cursor: pointer;
    }

    .bv-conf-atchmt-list a
    {
        margin-top:2px;
        display:block;
        width:100%;
        height:100%;
    }

    .bv-conf-atchmt-list-selected-a
    {
        background-color:#E6E6E6!important;
    }

    .bv-conf-atchmt-list-unselected-a
    {
        background-color:#FFFFFF!important;
    }

    .bv-conf-atchmt-list ul li
    {
        height:40px;
    }

    .bv-conf-atchmt-list .icon
    {
        display:block;
        float:left;
        position:relative;
        padding-left:5px;
    }

    .bv-conf-atchmt-list .icon .iconImage img
    {
        max-height: 38px;
        max-width: 35px;
        float:left;
    }

    .bv-conf-atchmt-list .bv-atchmnt-name
    {
        color: #333;
        height:50px;
        padding-top: 7px;
        padding-left:5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-right: 50px;
        /*max-width: 200px;*/
    }


    .bv-conf-pdf-nav-cntrl-div
    {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        background-color:gray;
        color:#FFF;
    }

    .bv-conf-pdf-nav-cntrl-div-span{
        color:#FFF;
        display: inline;
        font-size: 75%;
        font-weight: bold;
        line-height: 1;
        /* color: #fff; */
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .25em;
    }

    .bv-conf-pdf-goto-next-page-btn
    {
        background-image: url("../assets/images/findbarButton-previous-rtl.png"); /* 16px x 16px */
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: 0px 0px;
        border: none;
        cursor: pointer;
        height: 16px;
        width:16px;
        vertical-align: middle;
    }

    .bv-conf-pdf-goto-prev-page-btn
    {
        background-image: url("../assets/images/findbarButton-next-rtl.png"); /* 16px x 16px */
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: 0px 0px;
        border: none;
        cursor: pointer;
        height: 16px;
        vertical-align: middle;
        width:16px;
    }

    .bv-conf-view-doesnot-contains-agenda
    {
        width:0%;height:100%;float:left;display:none;padding-top:10px;padding-bottom:10px;
    }
    .bv-conf-view-atchmt-without-agenda
    {
        width:100%;height:100%;float:left;padding-top:10px;padding-bottom:10px;padding-left:10px;padding-right:10px;
    }


    .bv-conf-attachment-row { height: 100%; text-align: center; width: 100%; overflow-x: hidden;	white-space: nowrap; padding-top: 0px; overflow-y: auto; }
    .bv-conf-attachment-row .bv-slider > ul { text-align: center; min-width: 565px; min-height:465px; }
    .bv-conf-attachment-row .bv-slider > ul > li{ box-shadow: none !important; }
    .bv-conf-attachment-row .bv-slider .bv-slider-items-2 > li { width: 42%; margin: 20px 3.9%;}

    /*.bv-conf-attachment-row:hover {
        overflow-x: hidden;
    }*/

    .bv-conf-attachment-li {
        height: calc(100% - 70px);
        /* background: green; */
        margin-bottom: 20px;
        vertical-align: middle;
        display: inline-block;
        position: relative;
        width: 100%;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, .175);
    }

    /*.bv-conf-attachment-li:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -0.25em; /* Adjusts for spacing
    }*/

    .bv-conf-attachment-image {
        width: 100%;
        cursor: pointer;
        display: inline-block;
        max-height: 100%;
        max-width: 99%;
        vertical-align: middle;
        width: 99%;
    }

    .bv-conf-attachment-name {
        text-align:center;
        width: 100%;
        overflow: hidden;
        height:20px;
        top: 15px;
        position: relative;
        display: block;
    }
    .bv-conf-attachment-name > div{
        margin: 0 auto;
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .bv-conf-file-attachment-image {
        border: 1px solid #DDDDDD;
    }

    .bv-conf-message-attachment {
        height:100%;
        width:65%;
        display: inline-block;
        margin: 0 0px 0 10px;
        padding-bottom: 4px;
        padding-top:7px;
    }

    /*.bv-conf-agenda-div {
        height:100%;
        overflow:auto;*/
        /*overflow:visible;*/
        /*width:100%;*/
        /*padding:5px;
        border: 1px solid #ccc;
        border: 1px solid rgba(0, 0, 0, .15);
        border-radius: 4px;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, .175);*/
    /*}*/

    .bv-conf-agenda-div {
        height:100%;
        width:100%;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    /*.bv-conf-agenda-div:hover{
        overflow-y: auto !important;
    }
    */

    .bv-conf-agenda-list {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .bv-conf-agenda-list:hover {
        overflow-y: auto;
    }

    .bv-conf-agenda-list .mCSB_container {
        overflow: visible;
    }

    .bv-conf-details-desc {
        height:30px; width:100%; overflow:auto;
    }

    .bv-conf-details-address {
        height:30px; width:100%; overflow:auto;
    }

    .bv-conf-details-location {
        width:100%; overflow-x:auto; overflow-y:hidden;
    }

    .selectedCardShadow > div {
        box-shadow: 0px 0px 0px 3px #2273A5;
    }


    .bv-conf-view-main {
        white-space: nowrap;
        overflow-y: hidden;
        display: flex;
    }

    .bv-conf-view-left-div {
        width:280px !important;
        height:100% !important;
        z-index: 1;
        display: inline-block;
        white-space: normal;
          /*      box-shadow: -7px 0px 39px -4px #ccc inset;
           -moz-box-shadow: -7px 0px 39px -4px #ccc inset;
        -webkit-box-shadow: -7px 0px 39px -4px #ccc inset;*/
    }

    .bv-conf-view-right-div {
        width: calc(100% - 280px);
        height: 100%;
        float: right;
        display: inline-block;
        white-space: normal;
        z-index: 2;
    }

    /************Start of styles for Conf list View ***********/
    .bv-conf-font { color:#000000; }
    .bv-conf-font-color-dark { color:#515151; }
    .bv-conf-font-color-light {	color:#515151; }
    .bv-conf-font-color-gray { color:#A5A5A5;}
    .bv-conf-event-icon { margin-top:14px; }
    .bv-conf-event-selected { width:280px;padding:15px 7px 7px 15px;background-color:#f0f0f0; position: relative;}
    .bv-conf-event-not-selected { width:280px;padding:15px 7px 7px 15px;cursor:pointer; position: relative; }
    /*.bv-conf-event-not-selected:hover { background-color: #f4f4f4;}
    */.bv-conf-list-anchor a,.bv-conf-list-anchor a:link,.bv-conf-list-anchor a:hover,.bv-conf-list-anchor a:active,.bv-conf-list-anchor a:focus,.bv-conf-list-anchor a:visited {	color:#515151;	background-color:transparent; text-decoration: none; outline: 0; }
    .bv-conf-list-event-icon-div { width:15px;height:15px;/*margin-right:3px;float:right;*/ position: absolute !important; right: 17px; }
    .bv-conf-list-event-icon-div .dropdown-menu { top: 15px; left: -130px !important; min-width: 140px !important; border-radius: 0px !important;}
    .bv-conf-list-event-icon-div .dropdown-menu > li > a { color: #000000; padding: 3px 10px;}
    .bv-conf-list-event-icon-div .dropdown-menu > li:hover > a { background-color: #f5f5f5; }
    .bv-conf-list-event-icon-div svg { height: 17px; width: 17px;}
    .bv-conf-list-event-name-time-div { width:calc(100% - 30px); height:50px; float:left; text-overflow:ellipsis; overflow:hidden; white-space:nowrap; padding-top:5px; }
    .bv-conf-list-event-name { font-weight:500; width:195px; max-width:	195px;height:19px;line-height:17px;text-overflow: ellipsis;overflow:hidden; }
    .bv-conf-list-event-time { font-weight:400; width:100%;height:17px;line-height:17px;padding-bottom:3px;}
    .bv-conf-list-event-details { height:17px; font-size:14px; text-overflow: ellipsis; }
    .bv-conf-list-event-blue-div { height:30px;margin-top:15px;width:100%; color:#428bca;font-weight:400;cursor:pointer;}
    .bv-conf-list-event-blue-attendees{
        float:right;
    }
    .bv-conf-list-event-blue-details {
        float:left;
    }
    .bv-conf-list-event-start {
        float: right;
        margin-right: 10px;
        margin-top: -2px;
    }

    .bv-conf-list-event-blue-details,
    .bv-conf-list-event-blue-attendees{
        opacity: 0.3;
        cursor: default;
        pointer-events: none;

        &.enabled{
            opacity: 1;
            cursor: pointer;
            pointer-events: inherit;
        }
    }

    /************End of styles for Conf list View ************/

    /************Start of styles for Conf Details Popup ***********/
    .bv-conf-details .modal-dialog { width:570px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important;}
    .bv-conf-details-container-div { width: 100%; height: 470px; font-weight: normal; color:#000000; }
    .bv-conf-details-col1 { width:125px; float:left; font-weight: 500; }
    .bv-conf-details-col2 { width:400px; float: right; display: inline-block; position: absolute; }
    .bv-conf-details-col2-row1 {
        height:30px;
        width: 100%;
        display: table;
        margin-left: 10px;
    }

    .bv-conf-details-col2-row1-row1 {
        height: 35px;
        width: 100%;
        display: inline-table;
        position: relative;
    }

    .bv-conf-details-col2-row1-row2 {height:10px;width:100%;position:absolute;display:inline-block;}
    .bv-conf-details-colon {width:10px;display:inline-block;vertical-align:top;}
    .bv-conf-details-ui-dial-in-and-code {width:55px;display:inline-block;}
    .bv-conf-details-ui-dial-in-and-code-value {width:324px;display:inline-block;}
    .bv-conf-details-divider-row { width:100%; height:1px; background-color: #E5E5E5; margin-top:30px; margin-bottom:20px;}
    .bv-conf-details-row1 {	height:50px; overflow-x:auto; margin:20px 20px 0px 20px; }
    .bv-conf-details-row2 {	height:80px; overflow-x:auto; margin:0px 20px 0px 20px; }
    .bv-conf-details-row3 {	height:114px; overflow-x:auto; margin:0px 20px 0px 20px; }
    .bv-conf-details-row4 {	height:65px; overflow-x:auto; margin:0px 20px 30px 20px; }
    .bv-conf-details-ui-address-location {width:115px;display:inline-block;vertical-align:top;}
    .bv-conf-details-location-val {width:264px;display:inline-block;text-overflow:ellipsis;overflow: hidden;}
    .bv-conf-details-address-val {width:264px;height:45px;display:inline-block;overflow-x:hidden;overflow-y:hidden;}
    .bv-conf-details-address:hover {overflow-y: auto !important;}
    .bv-conf-details-notes-val {height:108px;display:inline-block;}
    /*overflow-x:hidden;overflow-y:hidden;}*/
    /*.bv-conf-details-notes-val:hover {overflow-y: auto !important;}
    */.bv-conf-details-attendee-statuts { line-height:17px;}
    .bv-conf-details-attendee-row { width:100%; height:1px; background-color: #E5E5E5;  margin-top: 15px;}
    .bv-conf-details-attandee-response {
        display: table-cell;
        width: 33.33%;
        text-align: center;
        /*border: 1px solid;*/
        position: relative;
    };

    .bv-conf-details-ui-accept {height:20px;margin-left:10px;width:76px;display:inline-block;}
    .bv-conf-details-ui-decline {height:20px;margin-left:84px;width:58px;display:inline-block;text-align:center;}
    .bv-conf-details-ui-tentative {height:20px;margin-left:91px;width:75px;display:inline-block;text-align:right;}
    .bv-conf-details-ui-accept-icon {height:10px;margin-left:10px;width:56px;display:inline-block;margin-bottom:2px;}
    .bv-conf-details-ui-decline-icon {height:10px;margin-left:105px;width:57px;display:inline-block;text-left:center;overflow:hidden;margin-bottom:2px;}
    .bv-conf-details-ui-tentative-icon {height:10px;margin-left:97px;width:68px;display:inline-block;text-align:left;;margin-bottom:2px;}
    .bv-conf-status-active { cursor:none;color:#515151;
        border-bottom: 10px solid;
        padding-bottom: 5px;
    }
    .bv-conf-status-non-active {cursor:pointer;color:#808080;}
    /************End of styles for Conf Details Popup ************/

    /************Start of styles for Attendee Popup ***********/
    .bv-conf-attendee .modal-dialog { width:510px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important; }
    .bv-conf-attendee-container-div { width: 100%; height: 360px; font-weight: normal; }
    .bv-conf-attendee-col1 { height:100%; width:240px; float:left; font-size:20px; font-weight:400;  }
    .bv-conf-attendee-col1-row1 { height: calc(100% - 50px);}
    .bv-conf-attendee-col1-row2 { height: 43px;display:inline-block;text-overflow:ellipsis;overflow: hidden;}
    .bv-conf-attendee-created-by-div { padding: 7px 20px 20px 20px; color:#808080; font-size:14px; width:240px; text-overflow:ellipsis;overflow:hidden;white-space:nowrap;}
    .bv-conf-attendee-col2 { height:100%; width: calc(100% - 240px); float: right; display: inline-block; position: absolute; }
    .bv-conf-attendee-row { height:55px;line-height:55px;width:100%; cursor: pointer; display:block; text-align:left; font-size:20px; font-weight:400; margin-top:7px; margin-bottom:13px; padding: 0px 0px 0px 20px; }
    .bv-conf-attendee-row-selected { background-color: #2273a5; color:#FFFFFF;}
    .bv-conf-attendee-row-not-selected { color: #2273a5;}
    .bv-conf-attendeeStatusBadge { position:relative;margin-left:12px; margin-bottom: 7px; display: inline-block; }
    .bv-conf-attendeeStatusBadge[data-badge]:after { content:attr(data-badge); position:absolute; top:-12px; right:-16px; background-color: #2273a5; color:#FFFFFF; width:25px;height:25px;line-height:25px; text-align:center; border-radius:50%;}
    .bv-conf-attendee-row-selected .bv-conf-attendeeStatusBadge[data-badge]:after { background-color: #FFFFFF; color: #2273a5; }
    .bv-conf-attendee-display-div { height:100%; padding: 0px 20px 20px 20px;}
    .bv-conf-attendee-display-div .bv-scroll-list> .scroll-list-items .scroll-list > li { display:block; width:100%; line-height:40px; height:40px; border-bottom:0px;}
    .bv-conf-attendee-display-div .bv-scroll-list> .scroll-list-items .scroll-list > li:hover { cursor: default !important; background-color: white !important;}
    .bv-conf-attendee-display-div .bv-scroll-list > .scroll-list-items { height: 253px; display: inline-block; width: 100%; }
    .bv-conf-attendee-display-div .bv-scroll-list > filter span { font-weight:normal;color:#000000;}
    .bv-conf-attendee-display-div .bv-scroll-list > filter input { margin-top:5px;}
    /************End of styles for Attendee Popup ************/

    /************Start of styles for Conf Settings Popup ***********/
    .bv-conf-settings .modal-dialog {
        width:920px;
    /*  	position: absolute;
        margin: auto;
        top: calc(50% - 300px);
        bottom: 0;
        left: 0;
        right: 0; */
     }
    .bv-conf-settings-container-div { width:100%; height: 569px; font-weight: normal; color:#000000; margin: 0px 20px 0px 0px; }
    .bv-conf-settings-col1 { height:100%; width:230px; float:left;  font-weight: 500;}
    .bv-conf-settings-col2 { height:100%; width:690px; float:right; display: inline-block; position: absolute; }
    .bv-conf-settings-row { min-height:60px;line-height:55px;width:100%; font-size:20px; font-weight:400; cursor:pointer; display:block; text-align:left; margin-top:7px; margin-bottom:13px; padding: 0px 0px 0px 20px;/* white-space: nowrap;*/}
    .bv-conf-settings-row > span {
        line-height: normal;
        vertical-align: middle;
        white-space: inherit;
        display: inline-block;
    }
    .bv-conf-settings-row-selected { background-color: #2273a5; color:#FFFFFF;}
    .bv-conf-settings-row-not-selected { color: #2273a5;}
    .bv-conf-settings-col2-row1 { width:100%; height:calc(100% - 49px);}
    .bv-conf-settings-col2-row1 > div { padding: 20px 20px 0px 20px;}
    .bv-conf-settings-col2-row2 { width:100%; height:70px; }
    .bv-conf-settings-col2-row2-button-div { float: right;}
    .bv-conf-adjustTimeDiv1 { position:relative; float:left; display:inline-block;height:480px; width:calc(100% - 110px); padding:0px; border-right:1px solid #E5E5E5;}
    .bv-conf-adjustTimeDiv2 { float:right; display:inline-block;height:100%; width:110px;}
    .bv-conf-addAttendeesDiv1 { float:left; display:inline-block;height:100%; width:55%; padding: 0px 0px 0px 0px; border-right:1px solid #E5E5E5;}
    .bv-conf-addAttendeesDiv2 { float:right; display:inline-block;height:100%; width:45%; padding: 0px 0px 0px 0px; }
    .bv-conf-addAttendeesDiv2-users {width:100%; height:100%;overflow-x: visible;/*overflow:hidden;*/font-weight:normal;}
    .bv-conf-addAttendeesDiv2-users .bv-item-list-main { padding: 0px 0px 0px 20px;}
    .bv-conf-addAttendeesDiv2-users .bv-item-list-main > div { height:480px; width:100%; }
    .bv-conf-addAttendeesDiv2-users .bv-item-list-grid { height:414px;}
    .bv-conf-addAttendeesDiv2-users .bv-item-list-ul1 { width: 273px;height:375px;}
    .bv-conf-addAttendeesDiv2-users .bv-item-list-ul1 .bv-item-list-ul2-li-checkbox     { line-height:40px; height:40px !important; display:block; position:absolute; width:50px !important; left:216px !important; }

    .bv-conf-addAttendeesDiv2-users .bv-item-list-ul1 .bv-item-list-ul2-li-checkbox .bv-item-small-close-svg { width: 22px; height: 22px; margin: 0px 0px 10px 10px; }

    .bv-conf-removeAttendeesDiv1 { float:left; display:inline-block;height:100%; width:55%; padding: 0px 0px 0px 0px; border-right:1px solid #E5E5E5;}
    .bv-conf-removeAttendeesDiv2 { float:right; display:inline-block;height:100%; width:45%; padding: 0px 0px 0px 0px; border-right:1px gray;}

    .bv-conf-activeInactive-ul2-li-details
    {
        display: inline-block;
        width: 50%;
    }

    .bv-conf-activeInactive-ul2-li-radiobutton
    {
        position: relative;
        display: block;
        width: 40%;
        /* left: 415px; */
        line-height: 0px;
        float: right;
        text-align: right;
    }

    .bv-conf-activeInactive-li-details
    {
        display: inline-block;
        width: 50%;
    }

    .bv-conf-activeInactive-div-radio
    {
        height: 20px;
        width: 17px;
        display: inline-block;
        float: right;
    	padding-left: 0px;
    	margin-top: 5px;
    }

    .bv-conf-activeInactive-div-radio-lbl
    {
        white-space: nowrap;
    	overflow: hidden;
    	text-overflow: ellipsis;
    	display: block;
    	width: 84%;
    	float: left;
    	line-height: 28px;
    	margin: 5px 3px 0 0;
    }


    .bv-conf-event-list > ul {height:100%; overflow:hidden;}
    .bv-conf-event-list > ul:hover {overflow-y:auto;}
    .bv-conf-event-list > ul > li { display:block; width:100%; line-height:60px; height:60px; border-bottom: 1px solid #ccc; margin-bottom:20px;padding-right:15px;}
    .bv-conf-event-list > ul > li:hover{ cursor:pointer; }



    .bv-conf-time-list-selected { background-color:#2273a5; color:#FFFFFF; }
    .bv-conf-time-list > ul {height:100%; overflow:hidden;}
    .bv-conf-time-list > ul:hover {overflow-y:auto;}
    .bv-conf-time-list > ul > li { display:block; line-height:35px; height:35px; padding:0px 10px 20px 20px;}
    .bv-conf-time-list > ul > li:hover{ color:#000000; cursor:pointer; }

    .bv-conf-attendee-ul1 {height:480px; position:relative; display:inline-block;}
    /*.bv-conf-attendee-ul1:hover {overflow-y:auto;}*/
    .bv-conf-attendee-ul1-event-item { display:block; /*width:calc(100% - 10px);*/ line-height:60px; height:60px; border-bottom:1px solid #ccc; margin-bottom:5px; padding-top:9px;}
    .bv-conf-attendee-ul1-event-item:first-child{
        border-top: 1px solid #ccc;
    }

    .bv-conf-activeInactive-ul{
        height: 480px;
        position: relative;
        display: block;
        overflow: hidden;
        width: 100%;
    }

    .bv-conf-event-active-name-div
    {
        height: 50px;
        width: 100%;
        margin-top: 5px;
    }

    .bv-conf-adjustTimeDiv1-ul2-li-details   { line-height:60px; height:60px; display:inline-block; position:relative; width:60%;}
    .bv-conf-adjustTimeDiv1-ul2-li-checkbox  { line-height:30px; height:30px; display:inline-block; position:relative; width:50px; float:right;}

    .bv-conf-addAttendees-ul2-li-details   { line-height:60px; height:60px; display:inline-block; position:relative; width:295px;}
    .bv-conf-addAttendees-ul2-li-checkbox  { line-height:30px; height:30px; display:inline-block; position:relative; width:30px; float:right;}

    .bv-conf-removeAttendees-ul2-li-details   { line-height:60px; height:60px; display:inline-block; position:relative; width:295px;}
    .bv-conf-removeAttendees-ul2-li-checkbox  { line-height:30px; height:30px; display:inline-block; position:relative; width:30px; float:right;}

    .bv-conf-event-setting-dt-div{ height:50px; width:100%;}
    .bv-conf-event-setting-dt-div-name{ font-weight:500; height:24px; line-height:15px; width:100%; margin-bottom:4px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap; }
    .bv-conf-event-setting-dt-div-time{ height:20px; font-weight:normal;line-height:2px; width:100%;}
    .bv-conf-event-setting-chkbox-div { height:50px; width:23px; padding-top:10px; float:right;}
    /*.bv-version-tracker-li-selection-div { margin-top:6px; height:25px; width:25px;}*/

    /************End of styles for Conf Settings Popup ************/

    .bv-mobile-meeting-details header{
        padding: 20px 20px 0;
        margin-bottom: 10px;
        font-size: 14px;
        position: relative;
        border-bottom: 1px solid #ccc;
    }
    .bv-mobile-meeting-details time{
        font-weight: 600;
        display: block;
        margin-bottom: 10px;
    }
    .bv-mobile-meeting-details youare {
        display: block;
    }
    .bv-mobile-meeting-details lastupdated{
        display: block;
    }
    .bv-mobile-meeting-details buttons{
        display: block;
        margin: 15px 0px;
    }
    .bv-mobile-meeting-details buttons > button{
        background-color: inherit;
        border: inherit;
        padding: 0;
        font-size: 16px;
        color: #428bca;
        padding: 6px;
        margin: -6px -6px;
    }
    .bv-mobile-meeting-details buttons > button:last-child{
        float: right;
    }
    .bv-mobile-meeting-details attachments > div{
        height: calc(100% - 163px);
    }
    .bv-mobile-meeting-details attachments .bv-slider > ul > li{
        width: 100% !important;
        margin: 20px 0 !important;
        box-shadow: inherit;
    }
    .bv-mobile-meeting-details attachments .bv-slider > ul{
        min-width: inherit;
        padding: 0px 16.36% 65px;
    }
    .bv-mobile-meeting-details attachments .bv-slider > ul > li > ul > li:first-child{
        margin-bottom: 20px;
    }
    .bv-mobile-meeting-details attachments .bv-slider > ul > li > card{
        position: relative;
        display: block;
        width: 80%;
        margin: 0 auto;
        height: 100%;
        box-shadow: 0px 0px 15px -3px #000;
    }

    .bv-mobile-meeting-details closebtn{
        position: absolute;
        top: -33px;
        left: 12px;
    }
    .bv-mobile-meeting-details closebtn svg{
        height: 20px;
        width: 20px;
        fill: #fff;
    }
    .bv-mobile-meeting-details buttons > button:first-child .bv-button-feedback-type-3{
        height: 72px !important;
        width: 85px !important;
        top: -19.5px !important;
        left: 0px !important;
    }
    .bv-mobile-meeting-details buttons > button:last-child .bv-button-feedback-type-3{
        height: 72px !important;
        width: 61px !important;
        top: -19.5px !important;
        left: 0px !important;
    }

    .bv-conf-mobile-details section{
        padding: 15px;
        border-bottom: 1px solid #ccc;
        font-size: 14px;
        min-width: 240px;
    }
    .bv-conf-mobile-details section:last-child{
        border-bottom: inherit;
    }
    .bv-conf-mobile-details conference,
    .bv-conf-mobile-details location,
    .bv-conf-mobile-details notes,
    .bv-conf-mobile-details attend{
        display: block;
        margin-bottom: 10px;
        font-weight: bold;
    }
    .bv-conf-mobile-details dialin,
    .bv-conf-mobile-details areacode,
    .bv-conf-mobile-details meetingroom,
    .bv-conf-mobile-details addr{
        display: block;
    }
    .bv-conf-mobile-details dialin > span:first-child,
    .bv-conf-mobile-details areacode > span:first-child,
    .bv-conf-mobile-details meetingroom > span:first-child,
    .bv-conf-mobile-details addr > span:first-child{
        display: inline-block;
        width: 104px;
        text-align: right;
        color: #808080;
        font-size: 14px;
        margin: 2px 0;
    }
    .bv-conf-mobile-details dialin > span:last-child,
    .bv-conf-mobile-details areacode > span:last-child,
    .bv-conf-mobile-details meetingroom > span:last-child,
    .bv-conf-mobile-details addr> span:last-child{
        display: inline-flex;
        width: calc(100% - 110px);
        min-width: 40px;
    }
    .bv-conf-mobile-details .bv-conf-status-active{
        border-bottom: 5px solid;
        padding-bottom: 5px;
        color: #000;
    }
    .bv-conf-mobile-details buttons{
        padding-bottom: 29px;
        border-bottom: 1px solid #ccc;
        display: block;
    }
    .bv-conf-mobile-details buttons > span{
        left: calc(50% - 80px);
        position: relative;
        float: left;
    }
    .bv-conf-mobile-details buttons > span:first-child{
        left: inherit;
        float: left;
    }
    .bv-conf-mobile-details buttons > span:last-child{
        left: inherit;
        float: right;
    }
    /* .bv-conf-mobile-details .bv-modal-body > div,
    .bv-conf-mobile-attendee .bv-modal-body > div{
        overflow-y: auto;
    } */
    .bv-conf-mobile-details wrap{
        min-height: 100%;
        display: block;
        margin-bottom: -90px;
    }
    .bv-conf-mobile-details wrap:after{
        content: "";
        display: block;
        height: 90px;
    }
    .bv-conf-mobile-details buttons .bv-button-feedback-type-1{
        left: 3px;
        top: -10px;
    }


    .bv-conf-mobile-attendee footer{
        color: #808080;
        font-size: 14px;
        padding: 0 20px;
    }
    .bv-conf-mobile-attendee .bv-conf-attendee-row{
        color: #2273a5;
     }
    .bv-conf-mobile-attendee .bv-conf-attendee-row{
        margin: 0;
        padding: 0;
    }
    .bv-conf-mobile-attendee section{
        border-bottom: 1px solid #ccc;
    /*     padding: 20px; */
    }
    .bv-conf-mobile-attendee section .bv-conf-attendee-row{
        padding: 20px;
    }
    .bv-conf-mobile-attendee .bv-conf-attendee-row{
        height: 95px;
    }

    .bv-conf-mobile-attendee section:last-child{
        border: inherit;
    }
    .bv-conf-mobile-attendee wrap{
        min-height: 100%;
        display: block;
        margin-bottom: -40px;
    }
    .bv-conf-mobile-attendee wrap:after{
        content: "";
        display: block;
        height: 40px;
    }
    .bv-conf-mobile-attendee .portalUserAvatar{
        float: inherit;
    }

    .bv-conf-mobile-attendee section.closed{
        padding: 0 20px;
        border-bottom: inherit;
    }
    .bv-conf-mobile-attendee section.opened{
        padding: 20px;
        border-bottom: 1px solid #ccc;
    }
    .bv-conf-mobile-attendee section.opened:last-child{
        border-bottom: inherit;
    }
    .bv-conf-mobile-attendee section{
        transition: 300ms all;
        -webkit-transition: 300ms all;
    }
    .bv-conf-mobile-attendee section > ul{
        height:0;
        overflow: hidden;
        transition: 300ms all;
        -webkit-transition: 300ms all;
    }
    .bv-conf-mobile-attendee section.opened > ul{
        height: inherit;
    }
    .bv-conf-mobile-attendee section.bv-bottom-border-show{
        border-bottom: 1px solid #ccc;
    }
    .bv-conf-mobile-attendee section.bv-bottom-border-initial{
        border-bottom: inherit;
    }
    .bv-conf-mobile-attendee .portalUserAvatar{
        margin-bottom: 10px;
        position: relative;
    }
    .bv-conf-mobile-attendee section > ul > li:last-child .portalUserAvatar{
        margin-bottom: inherit;
    }

    .bv-conf-mobile-attendee .portalUserName{
        font-size: 16px;
        max-width: inherit;
    }

    /* running confs */
    .bv-running-conf-list-dialog {
        z-index: 100;
    }

    .bv-running-conf-list-dialog .modal-dialog {
        width:500px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) !important;
        -ms-transform: translate(-50%,-50%) !important;
        -webkit-transform: translate(-50%,-50%) !important;
        margin: 0;
        z-index: 100;
    }

    .bv-running-conf-list-main {
        width:100%;
        margin: 0px 20px 0px 0px;
    }

    .bv-running-conf-list-header {
        width: 100%;
        height: 35px;
        line-height: 35px;
        padding-left: 20px;
    }

    .bv-running-conf-list-container {
        padding: 20px;
        height: 180px;
    }

    .bv-multiple-running-conf-list-container{
        height: 290px;
    }

    .bv-running-conf-list-cancel {
        position: absolute;
        right: 20px;
        bottom: 20px;
    }

    .bv-running-conf-details {
        position: relative;
    }
    
    .bv-running-conf-details .bv-heading-lable{
          width:353px;
          padding-top: 4px;
    }

    .space-between-meetings {
        margin-top: 15px;
        padding-top: 15px;
    }

    .bv-event-join-btn {
        position: absolute;
        right: 0px;
        top: 0px;
    }

    .bv-event-leave-btn {
        position: absolute;
        right: 0px;
        top: 0px;
    }

    .bv-running-conf-metadetails {
        margin-top: 15px;
    }
    /* running confs end */

    /* Event Presentation */

    .bv-event-presentation {
        z-index: 100 !important;
    }

    .bv-event-presentation .modal-dialog {
        width:950px;
        /*position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) !important;
        -ms-tranform: transform: translate(-50%,-50%) !important;
        -webkit-transform: translate(-50%,-50%) !important;
        margin: 0;*/
        z-index: 100 !important;
     }

    .bv-event-present-container {
        width:100%;
        height: 569px;
        margin: 0px 20px 0px 0px;
    }

    .bv-event-present-col1 {
        height:100%;
        width: 300px;
        float:left;
        position: relative;
        overflow: hidden;
    }

    .bv-event-present-col2 {
        height:100%;
        width: calc(100% - 300px);
        float:right;
        display: inline-block;
        position: absolute;
    }

    .bv-event-present-attachment-row {
        height: 100%;
        text-align: center;
        width: 100%;
        overflow-x: hidden;
        white-space: nowrap;
        padding-top: 0px;
        overflow-y: auto;
    }

    .bv-event-present-attachment-row .bv-slider > ul {
        text-align: center;
        min-width: 510px;
        min-height:465px;
    }

    .bv-event-present-attachment-row .bv-slider > ul > li{
        box-shadow: none !important;
    /*	width: 380px;
        margin: 20px 55px;*/
    }

    .bv-event-present-attachment-row .bv-slider .bv-slider-items-2 > li {
        width: 45%;
        margin: 20px 2.2%;
    }

    /*.bv-event-present-attachment-row:hover {
        overflow-x: hidden;
    }*/

    .bv-event-present-attachment-li {
        height: calc(100% - 70px);
        /* background: green; */
        margin-bottom: 20px;
        vertical-align: middle;
        display: inline-block;
        position: relative;
        width: 100%;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, .175);
    }

    .bv-event-present-event-details {
        width: calc(100% - 101px);
        padding: 17px 20px 10px 35px;
    }

    .bv-event-present-event-metadetails {
        width: 100%;
        padding: 0 20px 10px 35px;
    }

    .bv-event-present-end {
        position: absolute;
        right: 15px;
        top: 20px;
    }

    .hide-metadetails-button {
        position: absolute;
        left: 10px;
        top: 39px;
    }

    .eventPeople {
        height: calc(100% - 125px);
        overflow: auto;
    }

    .eventPeopleBig {
        height: calc(100% - 75px);
    }

    .bv-event-participant {
        height: 35px;
        position: relative;
    }

    .event-present-person-name {
        line-height: 35px;
        float: left;
        width: 100%;
    }

    .restrict-name-space {
        width: 136px !important;
    }

    .event-current-presenter, .event-make-presenter {
        position: absolute;
        right: 15px;
        line-height: 35px;
    }

    .presentDocument {
        position: absolute;
        right: 20px;
        bottom: 20px;
    }

    .copy-meetings .bv-conf-addAttendeesDiv2 > title{
        margin: 5px 0 5px 20px;
        font-weight: 500;
        display: block;
    }

    .copy-meetings .bv-conf-addAttendeesDiv2 > input{
        margin: 5px 20px;
        width: calc(100% - 20px);
    }

    .copy-meetings .bv-conf-addAttendeesDiv2 > span{
        position: relative;
        float: right;
        top: -36px;
    }
    .copy-meetings .bv-conf-addAttendeesDiv2 > span > img{
        width: 14px;
        height: 14px;
    }

    .copy-meetings .bv-conf-addAttendeesDiv2 .content-box > ul > li{
        line-height: 32px;
        display: block;
        height: 30px;
        padding: 5px 0;
        position: relative;
    }
    .copy-meetings .bv-conf-addAttendeesDiv2 .content-box > title,
    .copy-meetings .bv-conf-addAttendeesDiv2 .content-box > ul > li > title{
        float: left;
        display: inline;
        line-height: 18px;
    }
    .copy-meetings .bv-conf-addAttendeesDiv2 .content-box > ul > li > checkbox{
        right: 0;
        position: absolute;
        top: 7px;
    }
    .copy-meetings .bv-conf-addAttendeesDiv2 .content-box{
        margin: 20px 0 20px 20px;
        position: relative;
    }
    .copy-meetings .bv-conf-addAttendeesDiv2 .content-box > title{
        font-weight: 500;
    }
    .copy-meetings .bv-conf-addAttendeesDiv2 .content-box > checkbox{
        right: 0;
        position: absolute
    }
    .copy-meetings .bv-conf-addAttendeesDiv2 .content-box > hr{
        position: relative;
        clear: left;
        margin: 8px 0;
        top: 4px;
        border-color: #ccc;
    }

    .copy-meetings .bv-conf-addAttendeesDiv1 > title{
        display: block;
        margin: 5px 0 5px 0px;
        font-weight: 500;
        width: calc(100% - 20px);
    }
}
@import 'src/utils/variables';

.bv-repository-list .top-panel{
    width: 100%;
    height: 43px;
    border-bottom: 1px solid $grayLighter;
    font-size: 13px;
    color: $gray;
    padding-top: 19px;
    font-family: $nasdaqFontSemibold;

    titl{
        display: inline-block;
    }

    titl::after{
        content: "";
        display: inline-block;
        margin-left: 4px;
        position: relative;
        top: -1px;
        border-bottom: 6px solid $gray;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        display: none;
    }

    titl:nth-child(1){
        margin-left: 20px;
    }

    titl:nth-child(2){
        margin-left: 28px;
        width: 100%;
        min-width: 65px;
    }

    .filename-resizer {
        display: inline-block;
        background: #000;
        opacity: 0.15;
        box-sizing: border-box;
        background-clip: padding-box;
        height: 18px;
        width: 11px;
        margin: 0 -5px;
        border-left: 5px solid rgba(255,255,255, 0);
        border-right: 5px solid rgba(255,255,255, 0);
        cursor: col-resize;
    }

    .filename-resizer:hover {
        transition: all 2s ease;
        border-left: 5px solid rgba(0,0,0, 0.5);
        border-right: 5px solid rgba(0,0,0, 0.5);
    }

    titl:nth-child(4){
        margin-left: 5px;
        width: 210px;
    }

    checkbox{
        float: right;
        margin-right: 24px;
        position: relative;
    }
}
@import 'src/utils/variables';

.ad-search-dialog{
    top: 68px;

    &.modal-backdrop{
        opacity: 0;
    }

     &.fade{
        .modal-dialog{
            transform: translate(0, -100%);
            -webkit-transform: translate(0, -100%);
        }
    }
    &.in{
       .modal-dialog{
           transform: translate(0, 0);
           -webkit-transform: translate(0, 0);
       }
    }

    .modal-dialog{
        border: 1px solid $gray;
        border-radius: 0px;
        top: 0px;
        left: 57px;
        width: calc(100% - 555px);
        min-width: 550px;
        box-shadow: none;
        overflow: hidden;
        margin-top: 0;

        .modal-content{
            border-radius: 0px;
            border: none;
        }
    }

    .top-panel{
        titl{
            &::after{
                display: none !important;
            }
        }
    }

    .bottom-box{
        height: 37px;
        font-size: 13px;
        font-weight: 500;

        span{
            &:nth-child(1){
                display: inline-block;
                border-right: 1px solid #000;
                padding: 3px 10px 3px 5px;
                margin: 6px;
            }
        }

        button{
            outline: none;
            border: none;
            border-left: 1px solid $grayLighter;
            right: 0px;
            position: absolute;
            border-radius: 0px;
            padding: 10px;
            font-size: 14px;

            &:active,
            &:focus,
            &:hover{
                outline: none;
            }

            /*&:nth-child(1){
                right: 138px;
            }*/
        }
    }
}
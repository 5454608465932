@import 'src/utils/variables';
@import 'src/utils/classes';


/*.modal{
	&.meeting-details-react{
		    width: 470px;
    		left: auto;
	}
} 
.modal-backdrop{
&.meeting-details-react{
		    width: 470px;
    		left: auto;
	}
}*/
.meeting-details-react{

  meeting-ditails-react,
  .bv-modal-body{
    height: 100% !important;
  }

  z-index: 100 !important;

    .view-full-event{
        @extend .btn;
        height: 51px;
        width: 100%;
        border: none;
        border-bottom: 1px solid $grayLight;
        border-radius: 0;
        background: $grayLighter;
        color: $nasdaqBlue;
        outline: none;

        &:hover,
        &:active,
        &:focus{
            border: 1px solid $grayLight;
            background: $grayLighter;
        }
    }

    &.modal-backdrop{
        opacity: 0;
        top: 0;
        bottom: 0;
    }

    &.fade{
        .modal-dialog{
            transform: translate(100%, 0);
            -webkit-transform: translate(100%, 0);
        }
    }
    &.in{
       .modal-dialog{
           transform: translate(0, 0);
           -webkit-transform: translate(0, 0);
       }
    }

    .modal-dialog{
        width: 470px;
        position: absolute;
        right: 0;
        left: calc(100% - 470px);
        top: 162px;
        bottom: 0;
        transform: translate(100%, 0);
        -webkit-transform: translate(100%, 0);
        border-radius: 0px;
        margin: 0;
        padding: 0;
        box-shadow: none !important;
        background: #fff;
        border-left: 1px solid #ccc;

        .modal-content{
            border-radius: 0px;
            height: 100%;
        }
    }
}

.bv-mobile{
  .meeting-details-react{
    .modal-dialog{
      top: 94px;
      height: calc(100% - 140px);
      width: 470px;
    }
  }
}
@import 'src/utils/variables';

.class-card{
    .title{
        font-size: 25px;
        font-family: $nasdaqFontSemibold;
        color: $grayDarkest;
    }

    .list{
        li{
            .name{
                font-size: 17.5px;
                color: $grayDarkest;
            }
            .time{
                font-size: 13px;
                color: $grayDark;
            }
        }
    }
}
.noselect{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.button:hover,
.button:active,
.button:focus{
    color: $blue;
    cursor: pointer;
}
.button-red:hover,
.button-red:active,
.button-red:focus{
    color: $red;
}
.input:hover,
.input:active,
.input:focus{
    border-color: $blue;
}

.ellipsis-hidden{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.table{
    display: table;
    table-layout: fixed;

    > li{
        display: table-row;

        > div{
            display: table-cell;
        }
    }
}

/* TABS */
.nav-tabs {
    position: absolute;
    border: 0;
    top: -23px;
    left:0px;
    display: none;

    > li{
        > bt{
            position: relative;
            display: block;
            padding: 10px 15px;
            cursor:pointer;
            margin:0;
            border: 0;
            height: 23px;
            border-radius: 0px;
            line-height: 7px;
            color: #515151;
            font-size: 13px;
        }

        &.active{
            > bt,
            > bt:hover,
            > bt:focus {
                color: #fff;
                background: #036EAA;
                border: 0;
            }
        }
    }
}

/* NASDAQ styles*/
.btn{
    display: inline-block;
    margin-bottom: 0;
    padding: 7px 11px 6px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.25em;
    text-align: center;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    transition: background-color 0.2s linear 0.1s;
    -webkit-transition: background-color 0.2s linear 0.1s;
    background: #fff;
    border: 1px solid $nasdaqBlue;
    color: $nasdaqBlue;
}

.btn:hover,
.btn:active,
.btn:focus{
    background: #fff;
    border-color: $nasdaqBlueHover;
    color: $nasdaqBlueHover;
}

.btn--success{
    background: $green;
    border: 1px solid $green;
    color: #fff;
}

.btn--success:hover,
.btn--success:active,
.btn--success:focus{
    background: #fff;
    border-color: $greenMid;
    color: $greenMid;
}

.btn--primary{
    background: $nasdaqBlue;
    border: 1px solid $nasdaqBlue;
    color: #fff;
}

.btn--primary:hover,
.btn--primary:active,
.btn--primary:focus{
    background: #fff;
    border-color: $nasdaqBlueHover;
    color: $nasdaqBlueHover;
}


.chk,
.radio,
.chk-toggle {
  /* line-height: normal; */
  /* opacity: 0; */
  /* position: absolute; */
}
.chk + span:before,
.chk + label:before,
.radio + label:before {
  content: ' ';
  display: inline-block;
  margin-right: 8px;
  width: 1em;
  height: 1em;
  background-color: #ffffff;
  border: 1px solid #b6b7ba;
  line-height: normal;
  vertical-align: text-bottom;
}
.chk + span:before,
.chk + label:before {
  font-family: "SSStandard";
  font-weight: normal;
  border-radius: 3px;
  font-size: 86%;
  padding: 0 1px 1px 1px;
}
.chk:checked + span:before,
.chk:checked + label:before {
  content: '\2713';
  color: #474a53;
}
.chk:indeterminate + span:before,
.chk:indeterminate + label:before {
  content: '\002D';
  color: #474a53;
  font-weight: normal;
}
.chk:focus + span:before,
.chk:focus + label:before {
  border-color: #4cd0eb;
}
.chk[disabled] + span:before,
.chk[disabled] + label:before,
.radio[disabled] + label:before {
  background-color: #eaeaeb;
  cursor: not-allowed;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.chk[disabled] + label,
.radio[disabled] + label {
  cursor: not-allowed;
}
.radio + label:before {
  border-radius: 100%;
}
.radio:checked + label:before {
  box-shadow: inset 0px 0px 0px 0.25em #ffffff;
  background: #474a53;
}
.radio:focus + span:before,
.radio:focus + label:before {
  border-color: #4cd0eb;
}
.chk-toggle + label {
  position: relative;
}
.chk-toggle + label:before,
.chk-toggle + label:after {
  font-size: 10px;
  content: ' ';
}
.chk-toggle + label:after {
  position: relative;
  cursor: pointer;
  display: inline-block;
  height: 2em;
  width: 4em;
  border-radius: 4em;
  border: 1px solid #b6b7ba;
  background-color: #b6b7ba;
  transition: border-color 0.25s ease, background-color 0.25s ease;
  vertical-align: middle;
  margin-left: 8px;
}
.chk-toggle + label:before {
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin: .5em 0;
  margin-right: 2.5em;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  border-radius: 1em;
  transition: margin 0.25s ease;
  z-index: 1;
}
.chk-toggle:checked + label:after {
  border-color: #19b772;
  background-color: #19b772;
}
.chk-toggle:checked + label:before {
  margin-right: 0.5em;
}
.chk-toggle:focus + label:after,
.chk-toggle:hover + label:after {
  border-color: #d5d6d8;
  background-color: #d5d6d8;
}
.chk-toggle:checked:focus + label:after,
.chk-toggle:checked:hover + label:after {
  border-color: #1dd182;
  background-color: #1dd182;
}
.has-error .chk + span:before,
.has-error .chk + label:before,
.has-error .radio + label:before {
  border-color: #f16669;
}
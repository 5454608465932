@import 'src/utils/variables';
@import 'src/utils/classes';

.nasdaq{

    [state="home.meetings"]{
        .bv-transclude-content{
            min-width: 830px;
        }
    }
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 20px;
        height: 20px;
        top: 50%;
        left: 50%;
        margin-top: -10px;
        margin-left: -10px;
      }
      .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        margin: 8px;
        border: 2px solid #ccc;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #ccc transparent transparent transparent;
      }
      .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
      }
      .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
      }
      .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
      }
      @keyframes lds-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }  
    .manage-templates-wrap {
     & .header-wrapper {
         padding: 20px;
         display:inline-block;
         width: 100%;
         & .heading {
            font-family: 'SourceSansProRegular';
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1d1e25;
            width: 50%;
            float: left;
            padding-top: 10px;
         }
         .create-btn {
            font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #0094b3;
            float: right;
            width: 216px;
            height: 30px;
            border-radius: 3px;
            border: solid 1px #0094b3;
            padding: 6px 10px 10px 10px;
            cursor: pointer;
         }
    
     }
     & .emptymessage {
        display: block;
        font-size: 20px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        text-align: center;
        color: #d5d6d8;
        border-top: 1px solid #d5d6d8;

        & .emptytext {
            position: absolute;
            top: 48%;
            width: 100%;
        }
     }
     & .manage-agenda-table {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        table-layout: fixed;
        & th {
            border: none;
            text-align: left;
            padding: 8px;
            font-family: 'SourceSansProSemibold';
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-transform:uppercase;
            color:#6b6d75;

            &:first-child {
               padding-left: 20px;
               width: 75%;
            }
            &:nth-child(2) {
                width: 10%;
                text-align: center;
             }
             &:nth-child(3) {
                padding-left: 20px; 
                width: 15%;
             }
          }
       & td{
            border: 1px solid #d5d6d8;
            text-align: left;
            padding: 8px;
            height: 56px;
            color: #2d303a;
            &:first-child {
                padding-left: 20px;
                width: 75%;
             }
             &:nth-child(2) {
                 width: 10%;
                 text-align: center;
              }
              &:nth-child(3) {
                 padding-left: 20px; 
                 width: 15%;

             & span {
                 position: relative;
                 float:right;
                 right: 30px;
                
                 & i {
                    color:#0094b3;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.38px;
                    width: 19px;
                    font-family: "SSStandard";
                    cursor: pointer;
                 }
                & ul {
                    display: inline-block;
                    position: absolute;
                    background-color: #fff;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
                    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
                    top:12px;
                    right:0;
                    z-index: 1;

                    li{
                        border-bottom:1px solid #d5d6d8;
                        padding:10px;
                        cursor: pointer;
                        width: 110px;
                        text-align: center;

                        &:hover {
                            background: #f5f5f5;
                        }

                        &:nth-child(2){
                            border:none;
                        }
                    }
                }
             }
             & .hidden {
                 display: none;
             }
              }
          }
          
         & tr:nth-child(even) {
            background-color: #f8f8f8;
          }
          & .templatename a{
              color:#0094b3;
              white-space: nowrap; 
              width: 100%; 
              overflow: hidden;
              text-overflow: ellipsis;
              display: inline-block;
          }
      }
    }
	.attendance-response-dropdown{
	    z-index:9999;
		li{
			padding: 5px 10px;
		    border-bottom: 1px solid $grayLighter;
		    cursor: pointer;
		    &:hover{
		    	background:$gray;
		    }
		}
	}
    #bv-conference-tpl{
        .bv-conf-view-left-div{
            width: 260px !important;
            letter-spacing: 0px;

            .bv-conf-agenda-list{
                background: $lightMockGrayBackground;
                border-right: 1px solid $grayLighter;

                > div > div{
                    > li{
                        border-bottom: 1px solid $grayLighter;
                        padding: 17px 18px 0px 18px;
                        width: inherit;

                        &.bv-conf-event-selected{
                            background: $lightMockBlueBackground;
                            padding-bottom: 15px;
                        }

                        &.bv-conf-event-not-selected{
                            .bv-conf-list-event-time{
                                margin-top: 16px !important;
                                margin-bottom: 9px !important;
                            }
                            .bv-conf-list-event-icon-div{
                                display: none;
                            }
                        }

                        .bv-conf-list-event-details{
                            margin-top: 4px;
                            font-weight: 300;

                            span{
                                font-weight: 400;
                            }

                            &:nth-child(2){
                                margin-bottom: 12px;
                            }
                        }

                        .bv-conf-list-event-blue-div{
                            margin-top: 4px;
                            color: $nasdaqBlue;
                            height: inherit;

                            > span{
                                display: block;
                                clear: both;

                                .bv-conf-list-event-start{
                                    padding: 12px 0 0;
                                    margin: 0;
                                    float: none;

                                    button{
                                        @extend .btn;
                                        @extend .btn--success;

                                        font-size: 14.5px;
                                        font-weight: 400;
                                        width: 100%;
                                        height: 34px;

                                        &:hover{
                                            @extend .btn--success:hover;
                                        }
                                    }
                                }
                            }
                        }

                        .bv-conf-list-event-icon-div{
                            font-size: 13px;
                            color: $gray;
                        }

                        .event-bar{
                            height: 30px;
                            top: 14px;
                            left: 5px;
                            border-radius: 2px;
                            position: absolute;
                        }

                        .event-active-bar{
                            background: $nasdaqBlueHover;
                        }

                        .bv-conf-list-event-name-time-div{
                            height: inherit;
                            padding-top: 0px;
                            margin-left: 0px;

                            .bv-conf-list-event-name{
                                color: $grayDarker;
                                font-size: 14px !important;
                                font-weight: normal;
                                white-space: normal;
                                word-wrap: break-word;
                                line-height: 17px;
                                height: inherit;
                                position: relative;
                            }

                            .bv-conf-list-event-time{
                                line-height: 13px !important;
                                font-size: 13.5px;
                                color: $grayDark;
                                margin-top: 5px;
                                font-weight: 300;
                            }
                        }

                    }
                }
            }
        }
        .bv-conf-view-right-div{
            width: calc(100% - 260px);

            .bv-conf-attachment-li{
                height: calc(100% - 80px);
                border-radius: 6px;
                margin-bottom: 0px;

                .image-in-card{
                    height: calc(100% - 70px);
                    margin-top: -35px;
                }
            }

            .bv-conf-attachment-name{
                top: 35px;
                height: 70px;
                background: $lightGrayBackground;
                padding: 24px 0;
                font-size: 14px;
                border-bottom-right-radius: 6px;
                border-bottom-left-radius: 6px;
                color: $grayDarker;
            }

            .bv-slider{
                .bv-slider-items-2{
                    > li{
                        width: 48%;
                        margin: 60px 0.9%;
                    }
                }
            }

        }

    }
    .bv-meeting-track-attendees{
    	.modal-dialog{
    		    width: 920px;
    			height: 500px;
    			.modal-content, .mCSB_container{
    				height:100%;
    			}
    	}
    	.bv-meeting-track-attendees-wrap{
    	    .mCustomScrollBox{
    	     height:auto!important;
    	    }
    		height:100%;
    		.search-box{
    			.bv-placeholder-label{
    				& > span{
    					padding-left:25px;
    				}
    			}
    			input{
    				width: 100%;
				    height: 40px;
				    padding: 10px 10px 10px 30px;
    			}
    			icon{
    				    position: absolute;
					    top: 10px;
					    left: 9px;
					    color:$grayLighter;
    			}
    		}
    		.attendee-listWrap{
    			width: calc( 100% - 15px);
    			height: calc( 100% - 105px );
    			& .mCSB_scrollTools{
    			visibility: visible !important;
    			}
    			ul.attendee-list{
    				& > li{
    					padding: 10px;
    					border-bottom: 1px solid $grayLighter;
    					margin-top:-1px;
    					height:55px;
    					span.userName{
    						display: inline-block;
    						width: 80%;
    						.avatar{
    							float: left;
    							position: relative;
							    width: 30px;
							    height: 30px;
							    border-radius: 50%;
							    display: inline-block;
							    border: 1px solid #ccc;
							    overflow: hidden;
							    margin-right: 10px;
							    img{
							    	width: 30px;
							    	height: 30px;
							    }
							    i.ss-icon{
							    	color: $gray;
								    position: absolute;
								    top: 3px;
								    left: 5px;
								    font-size: 18px;
							    }
    						}
    					}
    					span.dropdownWrap{
    						display: inline-block;
    						.dropdown-toggle{
    						    position:relative;
    							border: 1px solid $grayLighter;
							    padding: 5px 10px;
							    border-radius: 5px;
							    background: #ffffff;
							    width: 160px;
							    display: inline-block;
							    font-size: 14px;
							    cursor: pointer;
							    &:after{
							    	    content: '';
									    display: block;
									    border-top: 6px solid #1080A2;
									    border-right: 4px solid transparent;
									    border-left: 4px solid transparent;
									    right: 10px;
									    position: absolute;
									    top: 12px;
							    }
    						}
    					}
    					
    				}
    			}
    		}
    		.action-buttons{}
    		
    	}
    }
	.fileuploader-navigator-react-overlay{
			z-index: 100 !important;
	}
}
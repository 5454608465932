
.userProfileDialog{

    .bv-modal-btn-custom-box{
        float: left;
        position: relative;
        margin: 3px 2px;

        button{
            outline: none;
            background: #fff;
            border: none;
            height: 15px;
            width: 15px;

            &.followed{
                background: green;
            }
        }
    }
}
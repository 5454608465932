@media screen, handheld, print{
    /* Disable long press link menu for all iPad/iPhone devices. */
    html {
        -webkit-touch-callout: none;
    }
}

body:not(.nasdaq){

    /* iPhone 6 in portrait & landscape. */
    @media only screen and (min-device-width : 275px) and (max-device-width
        : 736px), handheld, print {
        /* .bv-login-form{
            width: 95%;
        }
        .bv-mobile .bv-login-logo{
            width: 80%;
            min-width:inherit;
        } */
        /* LANDSCAPE ORIENTATION IS NOT SUPPORTED FOR PHOHES, SO NO COMMON CSS SETTINGS. */

        /* For signature we going to force landscap for drawing */
        &.bv-mobile{

            .editSignatureDialog .modal-dialog
            {
                min-width: 100% !important;
            }

            .signInitBox {
                height: 100% !important;
            }
            .signBox {
                width: 100% !important;
                margin-bottom: 20px;
            }

            .currentSign {
                margin-bottom: 10px;
                width: 100% !important;
            }

            .no-sign-label {
                padding-top: 48px;
                text-align: center;
            }

            .initBox {
                width: 50% !important;
                margin-bottom: 20px;
            }

            .currentInitials {
                margin-bottom: 10px;
                width: 100% !important;
            }

            .signatureMain
            {
                height: 100%;
            }

            .changeStyleButtons {
                position: absolute;
                bottom: 86px;
                display: inline-flex;
                left: 50%;
                transform: translateX(-50%);
            }

            .draw-place-holder {
                position: absolute;
                top: calc(50% - 45px);
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    @media only screen and (min-device-width : 275px) and (max-device-width
        : 736px) and (orientation : portrait), handheld, print {

        .onlyInLandscap {
            display: none;
        }

        .onlyInPortrait {
        }
    }

    /* iPhone 6 in landscape. */
    @media only screen and (min-device-width : 275px) and (max-device-width
        : 736px) and (orientation : landscape), handheld, print {

        /* LANDSCAPE ORIENTATION IS NOT SUPPORTED FOR PHOHES. */
        .onlyInLandscap {
        }

        .onlyInPortrait {
            display: none;
        }
    }

    /* Android Phones in portrait. */
    @media only screen and (min-device-width : 320px) and (max-device-width: 480px) and (max-aspect-ratio: 13/9), print {

        .bv-heading-label {
            font-size: 18px;
            font-weight: 600;
            padding-left: 0%;
        }

        .bvhome .bv-title>div>div>span {
            width: 98%;
        }

        &.bv-mobile{

            .bv-login-div {
                display: block;
                text-align: center;
                margin-top: 5px;
                float: none;
            }

            .bv-login-button-cancel {
                margin-right:0px;
            }

            .bv-challenge-question-button-cancel {
                margin-right:0px;
            }

            .approval-attachments-row .bv-slider > div {
                top: calc(60% - 50px) !important;
            }

            .approve-responses-no-signature{
                height: initial !important;
            }
            .approve-responses-no-signature .approval-comments{
                position: relative;
            }

            .survey-attachments-row .bv-slider > div {
                top: calc(60% - 50px) !important;
            }

            .userActionMenu {
                display: none !important;
            }

            .add-comments-main {
                height: 100%;
            }

            .add-comments-main textarea {
                height: 80%;
            }

            .bv-compose-message-screen .comments1 {
                height: 100%
            }

        }
        .mobile-attachments-back-btn {
            padding: 18px 81px 18px 0px;
            left: 41px !important;
        }

        /*Approvals*/
        .approval-main {
            min-width: 100%;
            width: 100%;
            height: 100%;
            min-height: 580px;
            position: relative;
            overflow: visible;
        }

        .approval-top-section {
            max-width: 100%;
            padding: 16px 16px 16px 16px;
        }

        .approval-top-left {
            display: block;
            float: none;
            width: 100%;
            height: 100%;
        }

        .approval-instructions {
            height: 100%;
            width: 100%;
        }

        .approval-top-right {
            display: block;
            float: none;
            /*width: 100%;*/
            height: 100%;
        }

        .approval-attachments-row {
            height: 177px;
            width: 79%;
        }

        .approval-attachments-row .bv-slider>ul {
            margin: 0 25px !important;
        }

        .approval-attachments-row .bv-slider>ul>li {
            width: 26%;
            height: 80%;
            margin: 10px 10px !important;
        }

        .approval-attachments-row .bv-slider > div.bv-slider-right-btn {
            right: -1% !important;
        }

        .approval-attachments-row .bv-slider > div.bv-slider-left-btn {
            left: -1% !important;
        }

        .approval-attachments-row .approval-attachment-image-container {
            width: 74px;
            height: 97px;
            margin: 0 10px 10px 0;
            box-shadow: none;
            position: relative;
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            background: #fff;
        }

        .approval-bottom-section {
            max-width: 100%;
            height: 85%;
            overflow: visible;
            padding: 22px;
        }

        .approval-bottom-left {
            width: 335px;
            height: 100%;
            overflow: auto;
            position: relative;
            border-right: none;
        }

        .approval-comments {
            width: 95%;
            position: absolute;
            bottom: 0px;
            padding-top: 15px;
        }

        .approval-allowed-to-respond-bottom-right {
            width: 100%;
        }

        .chart-div {
            width: 100% !important;
            height: 80%;
            margin-top: 10px;
            margin-bottom: 20%;
            position: relative;
        }

        .approval-users {
            width: 100% !important;
            height: calc(100% - 45px);
            padding-left: 10px;
            margin-top: 30px
        }

        .approval-main .approval-bottom-section .approval-users-medium {
          float: none !important;
          width: 100% !important;
        }

        .approvalsPieChart {
            width: 100%;
            overflow: inherit !important;
        }

        .showHidePieChart {
            top: 1%;
            left: 30%;
            z-index: 0;
        }
        

       .approval-submit-button-wrap{
       	position: absolute;
    		height: 50px;
    		width: 100%;
    		.approval-submit-button{
        		right: 50px;
        }
       }

        .approval-static-buttons-section {
            display: block !important;
            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 16px;
            text-align: center;
            background-color: #FFFFFF;
            box-shadow: 0px 0px 15px -3px #000;
        }

        .bv-add-comments .bv-modal-body {
            height: 45% !important;
            margin-bottom:15px;
        }

        .approval-top-right .no-content-msg-label{
            width: 50% !important;
            margin-top: 2% !important;
        }

        .approvalListProgressBar {
            background-color:#d6d6d6;
        }

        /*Meetings and Conference*/
        .bv-running-conf-list-dialog .modal-dialog {
            width: 100% !important;
            height: 100% !important;
            max-height: 100% !important;
            overflow-y : auto;
        }

        .bv-running-conf-list-header {
            width: 100%;
            height: 1%;
            line-height: 20px;
            padding: 2% 5% !important;
        }

        .bv-running-conf-list-main .status-bar-over-dialog {
            height: 15px;
        }

        .bv-running-conf-list-cancel {
            position: fixed;
        }

        .bv-conf-list-event-blue-attendees {
            text-align: start;
            width: 29%;
        }

        .mobile-conf-attendees {
             text-align: end !important;
        }

        .bv-conf-list-event-blue-details {
            text-align: inherit;
        }

        .mobile-conf-details {
              text-align: center !important;
        }

        .bv-conf-list-event-start {
           margin-top: -7px;
        }

        .eventMobilePresentAttachment .bv-slider > div {
            top: 68%;
        }

        .eventMobilePresentAttachment .bv-slider > ul > li {
            height: 68%;
            width: 68%;
            margin: 20px 16%;
        }

        .eventMobilePresentAttachment .bv-textFit {
           margin-top: 5%;
           text-align : center;
        }

        .eventMobilePresentAttachment .bv-slider > div {
             top: 68% !important;
        }
        .eventMobilePresentAttachment .bv-slider > div.bv-slider-right-btn {
            right: 5% !important;
        }

        .eventMobilePresentAttachment .bv-slider > div.bv-slider-left-btn {
            left: 5% !important;
        }


        /*Surveys*/
        .surveysMain {
            min-width: 100%;
        }

        .surveys-sectionOne {
            width: 90%;
        }

        .survey-menu-icon {
           display: none !important;
        }

        .surveysListDIV .bv-context-menu-arrow-box {
            display: none !important;
        }

        .surveysMain .rightClickMenu {
          display: none !important;
        }
        .survey-main  {
            min-width:0px;
            max-width: 100%;
            width: 100%;
            overflow: visible;
        }

        #respondSurveyMain .bv-slider {
           overflow: visible;
        }

        #respondSurveyMain .bv-slider > div,
        #surveyConsumerSectionDIV .bv-slider > div{
            top: calc(59% - 50px) !important;
        }

        .survey-instructions {
            min-height: 0%;
            max-height:100%;
        }

        .survey-second-section {
            width: 100%;
            min-height: 80%;
            max-height: 100%;
            overflow-y: visible;
            overflow-x: hidden;
        }

        .survey-top-section, .bv-survey-question-top-section {
            width: 100%;
            max-height: 100%;
            min-height: 200px;
            overflow: auto;
        }
        .survey-top-left, .bv-survey-question-top-left {
            width: 100% !important;
            height: 100% !important;
            overflow: visible;
            border-right: none !important;
        }

        .survey-top-right, .bv-survey-question-top-right {
            width: 100% !important;
            height: 100% !important;
            overflow: visible;
        }

        .survey-bottom-section {
            position: fixed !important;
            bottom: 46px !important;
            max-width: 100%;
            min-height: 10px;
            height: 10px;
        }

        .survey-section-cards .bv-slider > ul  {
           height: 100% !important;
        }

        .survey-section-cards .bv-slider > ul > li {
            width: 78%;
            margin: 20px 10%;
        }

        #respondSurveyMain .bv-cards-content>div:first-child {
           overflow: visible;
        }

        .surveyProgress div.bv-surveyProgress-left {
            width: 33.33%;
        }

        .surveyProgress div.bv-surveyProgress-middle {
            width: 33.33%;
        }

        .surveyProgress div.bv-surveyProgress-right {
            width: 33.33%;
        }

        #surveyConsumerQuestionDIV {
            position: fixed;
            bottom: 0px;
            height: 90% !important;
        }

        #surveyConsumerQuestionDIV .surveyProgress div.bv-surveyProgress-right {
           padding-left: 4px;
        }

        #respondSurveyMain .surveyProgress,
        #surveyConsumerSectionDIV .surveyProgress,
        #surveyConsumerQuestionDIV .surveyProgress {
           padding-left: 0px;
           padding-right: 0px;
        }

        .surveyConsumerSectionDIVBottom {
            padding-top: 4px;
        }

        .surveyProgress .bv-input-btn {
            height: 35px;
            width: 100%;
            margin-left: 0px;
        }

         #respondSurveyMain .surveyProgress .bv-surveyProgress-right .bv-input-btn {
           margin-left: 0%;

        }

         #surveyConsumerQuestionDIV .surveyProgress {
            margin-top: 2% !important;
        }

        #surveyConsumerQuestionDIV .bv-section-bottom-footer {
            width: 100% !important;
            margin-bottom: -5%;
        }

        #surveyConsumerQuestionDIV .bv-section-bottom-footer hr,
        #surveyConsumerSectionDIV .bv-section-bottom-footer hr {
           display: none !important;
        }

        #surveyConsumerQuestionDIV .questionProgressButton, #surveyConsumerQuestionDIV .finish-later-mobile {
           width: 100% !important;
        }

        .bv-question-type-header-mobile {
          display:block !important;
          font-weight: 500;
          font-size: 14px;
          margin-left: 2%;
        }

        .bv-question-detail-div {
          min-height: 150px;
          height: 100%;
          overflow-y: visible;
          overflow-x: hidden;
        }

         .surveyConsumerSectionDIVTop {
            height: 100%;
        }

        #surveyConsumerSectionDIV .bv-section-bottom-footer {
           width: 100% !important;
           height: 100px;
           margin-bottom: -6%;
        }

         #surveyConsumerSectionDIV .bv-survey-question-back-arrow,
         #surveyConsumerSectionDIV .bv-section-bottom-right-arrow,
         #surveyConsumerQuestionDIV .bv-survey-question-back-arrow,
         #surveyConsumerQuestionDIV .bv-section-bottom-right-arrow{
           margin-top: 9px;
        }

        .survey_response_bottom_right_options {
            display:none;
        }

         .bv-survey-back-button-label, .bv-survey-arrow-next-button-label,
        .bv-survey-question-back-button-label, .bv-survey-question-next-label {
           height: 35px;
           width: 100%;
           margin-left: 0px;
        }

        .progress-bar-openSurvey-box{
             display:none;
         }

        #respondSurveyMain .progress-bar-openSurvey-box-mobile,
        #surveyConsumerSectionDIV  .progress-bar-openSurvey-box-mobile,
        #surveyConsumerQuestionDIV .progress-bar-openSurvey-box-mobile {
             display:block !important;
             margin-bottom: 0%;
             width: 100% !important;
         }

         div.progress-bar-openSurvey-box-mobile {
            margin-left: 0px;
            height: 56px;
            background-color:#d6d6d6;
            margin-bottom:0px !important;
         }

         #respondSurveyMain .progress-bar-openSurvey-box-mobile {
            margin-top: 10%;
            padding-left: 0%;
         }

         #surveyConsumerSectionDIV  .progress-bar-openSurvey-box-mobile {
           margin-top:10%;
         }

         #surveyConsumerQuestionDIV .progress-bar-openSurvey-box-mobile {
           margin-top:12%;
         }

          .progress-bar-openSurvey-box-mobile .progressValue0 {
             padding-left: 160px !important;
             color:#808080 !important;
         }

         .bv-section-bottom-footer {
            bottom: 0px !important;
            left: 0px !important;
            position: fixed !important;
        }

        .finish-later-mobile {
            display: block !important;
            margin-left: -3px;
            margin-right: 7px;
        }

        .bv-survey-question-mobile-finish-later-label {
            width: 100%;
            height: 35px;
            border: none;
            margin: 0px 0px 0px 5.5px;
            font-family: Arial;
            font-weight: 500;
            font-size: 12px;
            letter-spacing: 0.6px;
            line-height: 22px;
            color: #fff;
            outline: none !important;
            white-space: nowrap;
            overflow: hidden;
            text-align: left;
            padding: 0px 15px;
        }

        .survey-attachments-row {
            height: 177px;
            width: 100%;
        }

        .survey-attachments-row .bv-slider>ul {
            margin: 0 10px !important;
        }

        .survey-attachments-row .bv-slider>ul>li {
            width: 26%;
            height: 80%;
            margin: 10px 10px !important;
        }

        .survey-attachments-row .bv-slider > div.bv-slider-right-btn {
            right: -1% !important;
        }

        .survey-attachments-row .bv-slider > div.bv-slider-left-btn {
            left: -1% !important;
        }

        .survey-attachments-row .survey-attachment-image-container {
            width: 74px;
            height: 97px;
            margin: 0 10px 10px 0;
            box-shadow: none;
            position: relative;
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            background: #fff;
        }

        .bv-question-center-yesno-div, .bv-question-center-truefalse-div, .bv-question-center-meetsdoesnotmeet-div {
            width: 90%;
        }

        .bv-question-details-commentsdiv {
            margin-left: 5%;
        }

        .bv-signature-dialog {
            top:10%;
            left:5%;
            right:5%;
        }

        .bv-signature-dialog .modal-dialog {
            height: 60% !important;
        }

        .bv-signature-dialog .bv-modal-body {
            height: 58% !important;
        }

        .bv-signature-dialog .signatureModal-sign-here-tooltip {
            left: 46%;
        }

    }

    /* Android Tablets in portrait. */
    @media only screen and (min-device-width : 601px) and (max-device-width
        : 900px) and (max-aspect-ratio: 13/9), handheld, print {

        /*Approvals*/
        .approval-main {
            min-width:100%;
        }

       .approval-top-section {
            max-width: 100%;
        }

        .approval-top-left {
            width: 100%;
        }


       .approval-bottom-section {
            max-width: 100%;
            height: 58% !important;
        }

        .approval-bottom-left {
            width: 50%;
        }

        .approval-allowed-to-respond-bottom-right {
            width: 50%;
        }

        .approval-signature-outer {
            width: 98%;
        }

        .chart-div {
            width: 50%;
        }

        nvd3-pie-chart svg {
            height: 85%;
        }

        .legend {
            width: 100%
        }

        .approval-users {
            width: 50%;
        }

        /*Surveys*/

        .surveysMain {
            min-width: 100%;
        }

        .surveys-sectionOne {
            width: 90%;
        }

        .survey-menu-icon {
           display: none !important;
        }

        .surveysListDIV .bv-context-menu-arrow-box {
            display: none !important;
        }

        .surveysMain .rightClickMenu {
          display: none !important;
        }

        .survey-main  {
            min-width:0px;
            max-width: 100%;
            width: 100%;
        }

         .survey-top-section {
            max-width: 100%;
        }

        .survey-top-left {
            width: 100%;
        }

        .bv-survey-question-top-left {
            width: 100% !important;
            border-right: none !important;
        }

        .survey-main .survey-section-cards .bv-slider > ul {
            white-space: normal;
        }

       .survey-second-section {
            max-width: 100%;
            height: 60%;
            max-height: 100%;
        }

        .survey-instructions {
            min-height: 0%;
            max-height:100%;
        }

        .survey-top-section, .bv-survey-question-top-section {
            width: 100%;
            max-height: 100%;
        }

        .mobile-attachments-back-btn {
            padding: 18px 81px 18px 0px;
            left: 41px !important;
        }

        #surveyConsumerQuestionDIV {
            position: fixed;
            bottom: 0px;
            height: 95% !important;
       }

       .bv-question-details {
            height: 68% !important;
       }

        &.bv-mobile{

            .add-comments-main {
                height: 100%;
            }

            .add-comments-main textarea {
                height: 80%;
            }

            .bv-compose-message-screen .comments1 {
                height: 100%
            }
        }
    }

    /* iPhone 6 in portrait. */
    @media only screen and (min-device-width : 0px) and (max-device-width
        : 867px) and (max-aspect-ratio: 13/9), handheld, print {

        html {
            -webkit-text-size-adjust: 85%;
        }

    }


    /* iPad in portrait & landscape. */
    @media only screen and (min-device-width : 768px) and (max-device-width
        : 1024px), handheld, print{
        .bvhome .bv-company-logo {
            margin: 3px 13px !important;
        }
        .bvhome .bv-team-space {
            top: 15px !important;
        }
        .bvhome .dropdown-menu {
    /* 		left: -150px !important;
            min-width: 175px !important; */
        }
        .bvhome .dn-timepicker-popup {
            min-width: 100% !important;
            max-height: 145px;
            overflow-y: scroll;
            left:0px !important;
            width:155px !important;
        }
        .bvhome .bv-search-bar {
            width: 35px;
            border: none;
        }
    /* 	.bvhome .bv-menu.expandLow {
            width: inherit;
        } */
        .bvhome .bv-menu {
            overflow: auto;
        }
        [compile="module.html"] {
            overflow: auto;
        }
        .bvhome .bv-title>div>div>span {
            font-size: 2.0em;
            top: -4px;
        }
        .bvhome .rightMenu li a .iconText {
            font-size: 1.3em;
        }
        .treeDoc {
            display: none;
        }
        .titleIcon {
            top: 8px;
        }

        /*Surveys*/
       .surveysMain {
            min-width: 100%;
        }

        .surveys-sectionOne {
            width: 90%;
        }

        .survey-menu-icon {
           display: none !important;
        }

        .surveysListDIV .bv-context-menu-arrow-box {
            display: none !important;
        }
        .surveysMain .rightClickMenu {
          display: none !important;
        }

        .survey-main  {
            min-width:0px;
            max-width: 100%;
            width: 100%;
        }

         .survey-top-section {
            max-width: 100%;
        }

       .survey-second-section {
            max-width: 100%;
        }

        #surveyConsumerQuestionDIV {
            position: fixed;
            bottom: 0px;
        }

        .bv-question-details {
            height: 68% !important;
            padding-top: 15% !important;
            min-height: 30% !important;
        }

        #surveyConsumerSectionDIV {
            height: 100%;
        }

        .mobile-attachments-back-btn {
            padding: 18px 81px 18px 0px;
            left: 41px !important;
        }

        #surveyConsumerQuestionDIV {
           height: inherit;
        }

        .bv-question-details-commentsdiv {
            width:100%;
        }

        &.bv-mobile{

            .bv-survey-question-likert-scale-div.bv-scrollbar::-webkit-scrollbar,
            .bv-survey-question-multichoice-div.bv-scrollbar::-webkit-scrollbar {
                display: block !important;
            }

            .bv-signature-dialog  .modal-dialog,
            .bv-signature-dialog .modal-dialog .modal-content {
                height: inherit !important;
                width: 400px;
            }

            .add-comments-main {
                height: 100%;
            }

            .add-comments-main textarea {
                height: 80%;
            }

            .bv-modal-body {
                height: calc(90% - 65px) !important;
            }

            .bv-compose-message-screen .comments1 {
                height: 100%
            }
        }
    }

    @media screen, handheld, print{

        .parent .row .cell:hover .events span{
            opacity: 0;
        }
        .parent .row .cell:hover .events .dot{
            opacity: 1;
        }

        &.bv-mobile{

            container.bvhome {
                padding-top: 0px;
                padding-bottom: 0px;
            }
            .bvhome>footer{
                display: none !important;
            }
            .bvhome [home-sidebar] {
                width: 87%;
                transition: 50ms all;
                -webkit-transition: 50ms all;
            }
            .bvhome .expandMenuForModuleSettings {
                display: none;
            }
            .bvhome .bv-content {
                /* margin-left: 58px; */
                /* transform: translateX(83%);
                -webkit-transform: translateX(83%); */
            /* 		transition: 50ms all;
                    -webkit-transition: 50ms all; */
            }
            .bvhome [home-right-sidebar] {
                display: none;
            }
            .bvhome [home-sidebar].expandHigh {
                width: 90%;
            }
            .bvhome .bv-content.expandLow{
                transform: translateX(256px);
                -webkit-transform: translateX(256px);
                margin-left: 0px;
                /* transition: 200ms all; */
                width: calc(100% - 256px);
            }
            &.bv-phone .bvhome .bv-content.expandLow{
                width: inherit;
                transform: translateX(calc(100% - 45px));
                -webkit-transform: translateX(calc(100% - 45px));
            }
            .bvhome header{
                display:none;
            }
            .bvhome [home-sidebar].expandLow{
                width: 256px;
            }
            &.bv-phone .bvhome [home-sidebar].expandLow{
                width: calc(100% - 45px);
            }
            .bvhome .bv-content.expandHigh {
                /* transition: 200ms all; */
                margin-left: 0px;
                width: 100%;
            }
            .bvhome .bv-mask-transition {
                top: 0;
            }
            .bvhome .rightMenu {
                display: none;
            }
            .bvhome .bv-footer-menu li {
                margin: 0 15px;
                font-size: 1.0em;
                letter-spacing: 1.0px;
            }
            .bvhome .bv-footer-menu li {
                margin: 0 9px;
                font-size: 0.9em;
            }
            .bvhome>footer>.progress {
                width: 20%;
            }
            .bvhome .rightMenu {
                right: 2px;
            }
            .bvhome .rightMenu li a .iconText {
                font-size: 0.7em;
            }
            .bv-calendar-title {
                font-size: 1.0em;
                margin: 3px 0px;
            }
            .bvhome .middleBarIconSvg{
                display: block;
            }
            .bvhome .expandMenu{
                padding: 10px;
                width: 50px;
                top: -2px;
                left: 1px;
                height: 50px;
                padding: 14px 160px 14px 10px;
                width: 200px;
            }
            .bvhome .expandMenu.show-back-on-middle-bar{
                padding: 14px 0px;
                width: 50px;
            }
            .bvhome .bv-toggle-menu .bv-button-feedback-type-1{
                padding: 14px 0px;
                width: 50px;
                left: 0px;
            }
            .bvhome .bv-title>div>div>span{
                padding: 13px 15px 0px 45px;
                max-width: inherit;
                font-size: 1.2em;
                top: -4px;
            }
            .bvhome .bv-title>div>div>span.show-back-on-middle-bar{
                padding: 13px 15px 0px 75px;
            }
            .bvhome .titleIcon{
                display: none;
            }
            .bvhome .titleIconMobile{
                top: 13px;
                left: 45px;
                display: block;
                width: 200px;
            }

            .bvhome .bv-team-space{
                display:none;
            }
            .bvhome .shadowBox{
                left: 87%;
            }
            .bvlogin .bv-buttons{
                padding-top: 30px;
            }
            .bv-mobile-retrive-user .bv-modal-dialog,
            .bv-mobile-retrive-password .bv-modal-dialog{
                min-height: 350px;
            }

            .bv-mobile-retrive-user .bv-top-block,
            .bv-mobile-retrive-password .bv-top-block{
                height: inherit !important;
                padding: 0;
            }

            .bvlogin .bv-login-form,
            .bv-mobile-retrive-user .bv-login-form,
            .bv-mobile-retrive-password .bv-login-form{
                max-width: 540px;
                width: inherit;
                margin: 30px auto;
                top: calc(50% - 165px);
            }
            .bvlogin .bv-login-form form{
                padding: 0px 0px 15px;
            }
            .bvlogin .bv-login-logo{
                width: 80%;
                min-width: 200px;
            }
            .bvlogin .bv-top-block{
                height: 30px;
            }
            .bvlogin .bv-login-rememberme-text{
                font-size: 0.85em !important;
            }
            .bvlogin .bv-forgot-text{
                font-size: 0.85em !important;
            }
            /*.bvlogin .bv-login-button,
            .bv-mobile-retrive-user .bv-login-button,
            .bv-mobile-retrive-password .bv-login-button,*/
            .bvlogin .bv-return-button,
            .bv-mobile-retrive-user .bv-return-button,
            .bv-mobile-retrive-password .bv-return-button{
                width: 60%;
                margin: 15px auto 0px;
                display: block;
                float: inherit !important;
            }
            /*ANDJUP-641*/
            /*/*/
            .bv-login-error-text {
                margin-bottom: 5px;
            }
            .bvlogin .bv-welcome-text,
            .bvlogin .bv-login-error-text{
                font-size: 16px;
            }
            .bvlogin .bv-login-skip-challenge-question-text{
                font-size: 15px;
            }
            container.bvlogin{
                min-height: 100%;
                height: 370px;
                margin-bottom: -60px;
             /*    top: 30%;
                transform: translateY(-30%);
                position: absolute; */
            }
            [state="login.changepassword"] container.bvlogin{
                 height: 450px !important;
            }
            [state="login.challengequestion"] container.bvlogin,
            [state="login.auth"] container.bvlogin{
                 height: 450px;
            }

            container.bvlogin:after{
                content: "";
                display: block;
                height: 60px;
            }

            .bvlogin .container{
                top: calc(50% - 227px);
            /*     transform: translateY(-30%);
                webkit-transform: translateY(-30%); */
                position: relative;
                display: block;
                margin:0;
                padding:0;
            }
            [state="login.changepassword"] .bvlogin .container{
                top: calc(50% - 270px) !important;
            }

            .bvhome .bv-mobile-menu{
                width: 40px;
                height: 45px;
                right: 0px;
                position: absolute;
                top: 0px;
                display: block;
            }
            .bvhome .bv-mobile-menu > div{
                height: 45px;
            }
            .bvhome .bv-mobile-menu > div > svg{
                fill: #fff;
                stroke: #fff;
                stroke-width: 3;
                display:block;
                width: 40px;
                padding: 10px 15px;
            }
            .bvhome .bv-mobile-menu .dropdown-menu{
        /* 		top: 37px;
                left: -114px;
                min-width: 140px;
                left: -170px;
                min-width: 205px;
                display: block;
                 */
                top: 37px;
                min-width: 205px;
                display: block;
                position: fixed;
                max-height: calc(100% - 45px);
                width: 205px;
                left: calc(100% - 230px);
                overflow-y: auto;
                overflow-x: hidden;
            }
            .bvhome .bv-menu,
            .bvhome .document-list-container{
                overflow-y: inherit;
            }
            .bv-menu-box .bv-mobile-teamspace{
                display: block;
            }
            .bv-menu-box .bv-mobile-teamspace .dropdown{
                display: inline-block;
                position: relative;
                left: 35px;
                top: 15px;
            }
            .bv-menu-box .bv-mobile-teamspace .dropdown svg{
                width: 20px;
                height: 20px;
                fill: #fff;
            }
            .bv-menu-box .bv-mobile-teamspace .dropdown-menu{
                display: block;
                left: 25px;
                max-height: calc(100% - 45px);
                max-width: 205px;
                padding:0;

            }
            .bv-menu-box .bv-mobile-teamspace .dropdown-toggle{
                -webkit-tap-highlight-color: rgba(0, 0, 0, .5);
                -webkit-tap-highlight-color: transparent;
              /*   padding: 14px 20px 17px 0; */
                position: absolute;
                top: -23px;
        /* 	    left: -260px; */
            }

            .bv-menu-box .bv-mobile-teamspace .dropdown-menu bt{
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .bv-menu-box .bv-mobile-teamspace .dropdown-menu bt .bv-button-feedback-type-1{
                height: 170px;
                top: -70px;
            }
            .bv-menu-box .bv-mobile-teamspace .dropdown-menu bt .bv-button-feedback-type-3{
                top: -80px;
                left: 0;
            }
            /*/*/
            .bv-menu-box .bv-mobile-teamspace .bv-button-feedback-type-1{
                left: 247px;
            }

            .bv-menu-box .bv-mobile-teamspace > div{
                display: inline-block;
                top: 12px;
                position: relative;
                left: 25px;
                color: #fff;
                max-width: 220px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 13px 50px 13px 20px;
                margin: -13px -50px -13px -20px;
                z-index: 2;
            }
            .bv-meeting-cards .bv-slider > ul{
                min-width: inherit;
                padding: 0px 15px 65px;
            }
            .bv-meeting-cards .bv-slider > ul > li{
                width: 300px !important;
                margin: 20px 15px !important;
            }
            .bv-modal-body {
                position: relative;
                height: calc(90% - 45px);
                width: 100%;
            }
            .bv-slider{
                overflow-y: hidden;
            }
            .bv-slider > ul{
                height: calc(100% + 30px);
                margin: inherit;
                overflow-x: inherit;
                padding-bottom: 65px;
            }
            .bv-slider > div{
                top: calc(99% - 50px);
            }
            .bv-calendar-content .bv-slider > ul{
                min-width: inherit;
                margin: 0px 9.36% 65px;
            }
            .bv-calendar-content .bv-slider > ul > li{
                width: 93%;
                    margin: 20px 3.3%;
            }
            .newItemsMain .bv-slider > ul{
                min-width: inherit;
                margin: 0px 9.36% 65px;
            }
            .newItemsMain .bv-slider > ul > li{
                width: 93% !important;
                margin: 20px 3.3%;
            }
            .newItemListContent .newItem {
             font-size:13.5px;
            }
            .newItemsMain .new-items-attachment-image-container{
                height: 100%;
                margin-bottom: 15px;
            }
            .bv-slider > div.bv-slider-left-btn{
                transform: scale(0.7);
                -webkit-transform: scale(0.7);
            }
            .bv-slider > div.bv-slider-right-btn{
                transform: scale(0.7);
                -webkit-transform: scale(0.7);
            }

            .modal-dialog{
                margin:0;
                height: inherit;
            }
            .modal-dialog,
            .modal-dialog .modal-content{
                height:100%;
                width: 100%;
            }
            .bv-confirm .modal-dialog,
            .bv-confirm .modal-content{
                height: inherit !important;
                width: 310px;
            }
            .bv-support .modal-dialog{
                width:100%;
                height:100%;
            }
            .bv-support .modal-dialog .modal-content{
                width:100%;
                font-size: 14px;
            }
            .bv-support{
                /* padding:30px; */
            }
            .bv-support-font {
                padding-left: 5px;
            }
            .bv-support .bv-modal-body ul li .versiondetails{
                padding-left:25px;
            }
            .bv-support .bv-modal-body ul li{
                padding-left:25px;
            }
            .bv-support .modal-dialog{
                min-width: inherit;
            }

            .bv-suppport .bv-modal-body .bv-float-left{
                padding-left:15px;
            }

            .bv-doc-prop .modal-dialog{
                width:100%;
            }
            .bv-doc-prop .bv-doc-prop-details-row2 > div{
                margin: 0 auto;
                padding: 20px;
                display: block;
            }

            .bv-doc-prop .modal-dialog .modal-content{
                width:100%;
            }
            .bv-doc-prop-col1{
                width:20%;
                display:none;
            }

            .bv-doc-prop-details-img{
                display: block;
                 margin: 0 auto;
            }
            .bv-doc-prop-col2{
                overflow: auto;
                hight: inherit;
                width: 100%;
                overflow: auto;
                padding: 0px 30px;
            }
            .bv-doc-prop-details-row1{
                display:none;
            }

            .bv-doc-prop-details-row3-col1{
                width:35%;
                word-wrap:break-word;
                overflow: hidden;
            }
            .bv-doc-prop-details-row3-col2{
                width:65%;
                word-wrap: break-word;
            }

            .prop-details-textarea{
                line-height: inherit; height: inherit;
                }

            .bv-doc-prop-col2 > div {
                padding: 0px;
            }
            .bv-doc-prop-details-row3 > ul > li {
                font-size: 13px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                height: inherit;
            }

            .documentsList ul li .bv-repository-checkbox{
                display:none;
            }
            .bv-repository-list .top-panel checkbox{
                display: none;
            }
            .documentsList .fileInfo {
                width: calc(100% - 60px);
                position:relative;
            }
            &.bv-surface .bv-modal-window-new-attachment .documentsList .fileInfo {
                width: calc(100% - 85px);
            }

            .documentsList ul li:hover .fileInfo .fileNameDiv {
                max-width:100%;
            }

            .documentsList ul li.checked .fileInfo .fileNameDiv {
                max-width: 100%;
            }

            .documentsList .icon, #showSharedDocsDIV .icon { padding:7px 10px 0px 10px; }

            .documentsList .fileInfo .fileNameDiv {
                position: relative;
                left: 0;
                top: 2px;
                width:51%;
                max-width: inherit !important;
            }

            /* .documentsList .fileInfo .fileDetailsDiv{
                left: 0;
                width: 100%;
                margin-top: 29px;
            }

            .documentsList .fileInfo .portal-name-filedetails.fileDetailsDiv{
                left: 0;
                margin-top: 22px;
                width: 100%;
            } */

            .documentsList .fileInfo .fileDetailsDiv .fileDetailsSpan{
                display:block;
            }

            .documentsList .fileInfo .portal-name-filedetails.fileDetailsDiv .fileDetailsSpan{
                font-size: 12px;
                padding-top: 0px;
                display:block;
            }

            .documentsList .fileInfo .fileDetailsDiv .fileDetailsSpan .fileByName{ display: block; padding-right: 3px}

            .documentsList .fileInfo .fileDetailsDiv .fileDetailsSpan br{
                display:none;
            }
            .searchResultsDialog .modal-dialog {
                margin: 0px;
                min-width: inherit;
            }
            .searchModalDialog .bv-modal-title,
            .searchModalDialog .searchText,
            .searchModalDialog .searchBtn{
                display:none;
            }
            .searchInputText {
                /* width:100%; */
            }
            .searchInputBox {
                background: url("../assets/images/search_icon_dark_grey.png") no-repeat;
                   border: none;
                position: fixed;
                top: 5px;
                left: 2px;
                width:90%;
                margin-right:10px;
                margin-bottom:0px;
            }
            .modal-backdrop{
                /*z-index: 99 !important;*/
            }
            .bv-search-results-mobile{
                /*z-index: 100 !important;*/
            }
            .bv-search-results-mobile .document-list-container{
                overflow: auto;
            }
            .modal{
                /*z-index: 101 !important;*/
                position: absolute;
            }
            .modal.fade .modal-dialog{
                /* -webkit-transition: inherit;
                transition: inherit;
                -webkit-transform: inherit;
                transform: inherit;
                 */
                transform: translate(0, 25%);
                -webkit-transform: translate(0, 25%);
                transition: none;
                -webkit-transition: none;
            }
            .modal.in .modal-dialog{
                transform: translate(0, 0);
                -webkit-transform: translate(0, 0);
                transition: none;
                -webkit-transition: none;
            }
            .bv-modal-blue-header{
                min-height: 45px;
                padding: 10px 12px;
            }
            .bv-modal-btn-close{
                top: -9px;
                right: -10px;
                padding: 20px;
                z-index: 2;
            }
            .bv-modal-btn-close .bv-button-feedback-type-1{
                top: 9px;
                z-index: 1;
                left: 8.4px;
            }

            .smallFont{
                font-size: 0.65em;
                font-weight:normal;
                overflow: hidden;
                white-space: nowrap;
                text-align: center;
            }
            .mobileNetworkStatus{
                width: 100%;
                height: 30px;
                margin: 5px 0px;
            }
            .mobileNetworkStatus > div{
                width: 160px;
                margin: 10px auto;
            }
            .connectedIconClass,
            .notAvailableIconClass{
                display: inline;
                padding: 0 10px;
                float:left;
            }
            .goOfflineIconClass > div > svg,
            .connectedIconClass > div > svg,
            .notAvailableIconClass > div > svg{
                width: 25px;
                height: 25px;
                transform: scale(1.4);
                margin: 5px auto;
            }

            .goOfflineIconClass{
                float:left;
                width: 55px;
                height: 44px;
                padding-left:11px;
            }
            .bv-settings-mobile .modal-dialog{
                width: 100%;
                height: 100%;
               }
            .bv-settings-mobile{
                /* z-index: 100 !important; */
            }
            .bv-settings-mobile .userIdShowOrNot{
                padding:35px;
            }
            .bv-settings-mobile  .bv-settings-item{
              padding:25px;
            }
            .bv-settings-mobile  .challegeQuestionChange{
                padding:25px;
            }
            .bv-settings-mobile .settingsLineSeparator{
                margin-top: 20px;
                margin-bottom: 0px;
            }
            .bv-challenge-questions .modal-dialog{
                width: 100%;
                height: 100%;
               }
            .bv-challenge-questions .bv-input-btn-box{
                display:none;
            }
            .bv-default-fill-color-black {
                fill: #000000;
            }
            .challange-questions .bv-dropdown .dropdown-menu li {
                display: block;
            }
            .bv-events-mobile .modal-dialog{
                margin: 0;
                padding: 40px;
            }
            .bv-events-mobile [dialog-arrow]{
                display: none;
            }
            .bv-events-mobile .bv-scrollbar{
                overflow: inherit;
            }
            /* .bv-mobile-documentviewer-actions{
                padding:5px;
            } */
            .bv-mobile-documentviewer-actions .documentActionsContent >div{
                padding:20px;
            }
            .bv-mobile-documentviewer-actions .documentActionsContent >hr{
                margin-top:5px;
                margin-bottom:5px;
            }
            .bv-mobile-documentviewer-actions .actionsSvg{
                width:20px;
                height:20px;
            }

             /*Mobile views -  annotation related - used only in mobile templates*/
            .mobile-annotation-list-container {
                /*padding: 20px;*/
            }

            .mobile-annotation-list {

            }

            .mobile-annotation-list  .header {
                padding: 10px 20px;
            }

            .mobile-annotation-list .mobile-annotation-list-item {
                display: inline-block;
                white-space: nowrap;
                width: 100%;
                height: 80px;
                padding: 20px;
            }
            .documentActionsContent .annotationCount {
                color: white;
                border-radius: 50%;
                min-width: 15px;
                padding: 0px 5px;
            }

            .mobile-annotation-list .mobile-annotation-list-item .section1 {
                width: 80px;
                display: inline-block;
                height: 100%;
            }
            .mobile-annotation-list .mobile-annotation-list-item .section1 img {
                height: 35px;
                padding: 5px 15px;
            }

            .mobile-annotation-list .mobile-annotation-list-item .section2 {
                display: inline-block;
                width: calc(100% - 180px);
                height: 100%;
                font-size: 14px;
            }

            .mobile-annotation-list .mobile-annotation-list-item .section3 {
                display: inline-block;
                height: 100%;
                position: relative;
            }

            .mobile-annotation-list .mobile-annotation-list-item .section3 > div {
                position: absolute;
                bottom: 0px;
                right: 0px;
                font-size: 14px;
            }

              /*   */

            .bv-button-animate{
                margin: 0 6px;
                height: 48px;
                min-width: 0;
                line-height: 48px;
                padding-left: 0;
                padding-right: 0;
                width: 48px;
                border-radius: 50%;
            }
            .bvhome .bv-menu a{
                position: relative;
                overflow: hidden;
               }

            .bv-button-feedback-type-1{
                position: absolute;
                width: 45px;
                height: 45px;
                top: 0;
                z-index: 1;
                left: 13.4px;
                border-color: rgb(0, 0, 0);
                background: rgb(0, 0, 0);
                opacity: .5;
                border-radius: 50%;
                transform: scale(0);
            }

            .bv-button-feedback-type-2{
                opacity: .2;
                border-color: rgb(0, 0, 0);
                background: rgb(0, 0, 0);
                border-radius: 50%;
                transform-origin: 50% 50%;
                width: calc(100% - 10px);
                height: 336px;
                position: absolute;
                top: -140px;
                left: 0px;
                transform: scale(0);
            }

            .bv-button-feedback-type-3{
                opacity: .2;
                border-color: rgb(0, 0, 0);
                background: rgb(0, 0, 0);
                border-radius: 50%;
                transform-origin: 50% 50%;
                width: calc(100% - 10px);
                height: 336px;
                position: absolute;
                top: -160px;
                left: -20px;
                transform: scale(0);
            }

            .bv-button-feedback-tapped{
                opacity: 0;
                transform: scale(1);
            }
            .bv-button-feedback-tapped-done{
                transition: inherit;
            }

            .documentsList .fileNameVal span{
                cursor: pointer;
                height: auto;
                z-index: 1;
                display: block;
                padding: 7px 0px 0 0;
            }

            .documentsList .fileNameVal{
                height: 65px;
            }
            .documentsList .fileInfo .fileDetailsDiv{
                width: 49%;
            }
            .inboxItemsList{
                min-width:100%;
                width:100%;
            }
            .inboxListAttachmentIcon{
                float:right;
            }
            .itemDetails{
                display:none;
            }
            .messagesMain{
             font-family:"SourceSansProRegular";
            }
            .bv-inbox-display-mobile{
                display:block;
            }
            li.inboxListItemCell{
                height:85px;
            }
            .inboxListLeftItems{
                max-width:250px;
            }
            .inboxListLeftItems .dateFont{
                font-size:13px;
            }
            /* 	.inboxListAttachmentIcon{
                    height:85px;
                } */
            .messages-top-panel .top-panel .nav-tabs{
                padding: 0;
            }
            .messages-top-panel .top-panel .nav-tabs li{
                width: 50%;
            }
            .messages-top-panel .top-panel .nav-tabs li bt{
                font-size: 16px;
                padding:4px 15px;
            }
            .inboxListItemCell > hr{
                margin-top:73px;
            }
            .inboxMobile{
                display:none;
                width:inherit;
                height:50px;
                padding-top: 13px;
                font-size: larger;
            }
            .inboxTextDivMobile{
                float:left;
                width:50%;
                text-align:center;
                text-decoration:underline;
            }
            .sentTextDivMobile{
                Float:left;
                width:50%;
                text-align:center;
                color: #D8D8D8;
            }
            .bv-open-message-mobile-comments{
                font-size: 14px;
            }
            .hrMarginMobile10{
                margin-top:0px;
                margin-bottom:0px;
                background:#F8F8F8;
            }
            .mobilePadding{
                padding-bottom:3px;
                margin-bottom:3px;
            }
            .bv-login-button{
                outline: none;
            }
            .bv-mobile-login{
                max-width: 540px;
                margin: 0 auto;
                padding: 10px 10px;
                z-index: 1;
                position: relative;
            }
            .bv-mobile-info-logo svg{
                width: 25px;
                    height: 25px;
            }
            .bv-mobile-network-status-logo svg{
                float:left;
                width: 25px;
                height: 25px;
            }
            .bv-mobile-support-reset-app-data{
                width: 185px;
                height: 35px;
                color: #F5F5F5;
                line-height: 2px;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0px;
                cursor: pointer;
            /* 	background-color: #ea6214; */
                background-color: #2273a5;
                border: 0;
                margin: 0px 0px;
                box-shadow: 0.6px 0.6px 0px 0px #000;
            }
            /* 	.reset-data{
                    padding-left:40%;
                } */
            .ipadBuildAndVersion{
                color: grey;
            }
            .values{
                padding-left:5px;
            }
            .bv-mobile-retrive-user .bv-welcome-text,
            .bv-mobile-retrive-password .bv-welcome-text{
                font-size: 18px;
            }
            /* .bv-mobile-retrive-user .bv-top-block,
            .bv-mobile-retrive-password .bv-top-block{
                height: 100px;
            } */
            .bv-mobile-retrive-password .bv-login-error-text,
            .bv-mobile-retrive-user .bv-login-error-text{
                padding-bottom: 10px;
            }
            .bv-login-input-text{
                margin: 7px 0px 0px;
            }
            .bv-login-button{
                width: 170px;
                height: 32px;
                font-size: 14px;
            }
            .bv-login-logo{
                margin: 0 auto;
            }
            .bv-top-block.bv-challange-top-block{
                height: inherit;
                padding: 0;
            }
            .bv-change-challenge-question{
                margin: 0 !important;
                max-width: inherit !important;
            }
            .bv-change-challenge-question form{
                padding: 0 !important;
            }
            .bv-change-challenge-question .bv-login-title{
                margin: 0;
            }
            .bv-scrollbar::-webkit-scrollbar{
                display: none !important;
            }

            .bv-survey-question-likert-scale-div.bv-scrollbar::-webkit-scrollbar,
            .bv-survey-question-multichoice-div.bv-scrollbar::-webkit-scrollbar {
                display: block !important;
            }

            .bv-survey-question-likert-scale-div, .bv-survey-question-multichoice-div {
                 padding-right: 10px;
            }

            .bv-survey-question-multichoice-div {
                width: 90%;
            }

            .no-content-msg-label {
                height: 19px;
                line-height: 19px;
                white-space: pre-line;
                /* overflow: hidden; */
                /* text-overflow: ellipsis; */
                color: #aeaeae;
                padding: 20px;
            }
            .bv-scroll-list > .scroll-list-items{
                overflow-y: inherit;
            }

            .mobile-remove-attachment-button {
                width: 12px;
                height: 12px;
                position: absolute;
                cursor: pointer;
                top: 1px;
                right: -8px;
                z-index: 1;
            }
            /* [state="login.changechallengequestionanswer"] .bvlogin{
                height: 485px;
            }
            [state="login.changechallengequestionanswer"] .bvlogin .container{
                top: calc(50% - 260px) !important;
            } */

            .bv-signature-list ul li {
                height: 140px;
                padding-left: 10px;
                padding-right: 15px;
            }

            .bv-signature-list .bv-signature-icon
            {
                padding-right: 20px;
            }
            .sign-here-tab-img {
                left: 37px;
                top: 25px;
                height: 35px;
            }

            .bv-signature-list .bv-signature-info
            {
                position: absolute;
                left: 115px;
                transform: none;
                display: inline-block;
                top: 50px;
            }

            .bv-signature-list .bv-signature-file-info {
                transform: none;
                float: none;
                position: absolute;
                top: 30px;
                left: 115px;
            }

            .bv-signature-list .bv-signature-file-name {
                font-size: 16px;
            }

            .bv-signature-list ul li:hover .bv-signature-file-info {
                max-width: 100%;
            }
            /*/*/
            .disable{
                color:#ccc;
            }
            .enable{

            }
        /* 	[state="login.changepassword"] footer,
            [state="login.challengequestion"] footer{
                display: none;
            }*/
            .meetingsMobileCurrentBtn{
                top: 100%;
                box-shadow: none;
                color: #2273A5;
            }

            .meetingsMobileCurrentBtnSpan {
                width: 100%;
                display: inline-block;
                text-align: center;
            }
            .dropdown-menu > li > bt{
                padding: 14px 20px;
            }
            .bv-mobile-teamspace .dropdown-menu > li > bt{
                padding: 20px 20px;
            }
            .resetBtn{
                text-align:center;
            }
            .bv-company-logo-mobile{
                display: block;
                position: relative;
            }
            .bvhome .bv-topmenu li bt{
                border-bottom-color: #000 !important;
                color: #000 !important;
            }
            .bvhome [home-sidebar]::before{
                background: none;
            }
            .bv-menu {
                margin-top: 180px;
            }
            .bv-topmenu {
                top: 184px !important;
                border-bottom: 1px solid #e5e5e5 !important;
                margin: 0 !important;
                padding: 4px 20px;
                width: 100%;
            }

            .bv-modules-list {
                width: 100%;
            }
            /*.bv-modules-list > li:first-child{
                margin: 0 0 20px;
            }*/

            sidebar .border-bottom {
                border-bottom: 0;
            }

            .bvhome .bv-topmenu li {
                width: 66px;
                margin: 0;
            }

            #Modules,
            #Users,
            #Groups {
                color: black !important;
            }

            .portalUserList {
                overflow-y:auto;
            }

            .portalUserListContainer {
                height: calc(100% - 350px);
            }

            .userList {
                margin-top: 72px;
            }

            .searchUser {
                width: auto;
            }

            .teamActionBar {
                display: none !important;
            }

            .bv-mobile-compose-message-tablet{
                transition: inherit;
                transform: translateX(350px);
                width: calc(100% - 350px);
                box-shadow: 0px 0px 33px -8px #000;
            }
            .bv-mobile-compose-message-tablet .bv-modal-title{
                display: none;
            }

            .documentsList .portal-name-lineheight.fileNameVal > span {
                line-height: 18px;
                padding: 0px 0px 0 0;
            }

            .new-items-attach-name{
                margin-top:-3px;
            }

            .portal-name-filedetails.new-items-attach-name{
                margin-top:-9px;
            }

            .new-items-attach-portal-name{
                margin-top:-1px;
            }

            .documentsList .fileNameVal > svg{
                margin: 5px 5px 0 5px;
                display: none !important;
            }

            .documentsList .fileNameVal .portal-name-span {
                padding-top: 0px;
            }
            /* [state="home.inbox"] #loading-spinner{
                display: none;
            } */
            .inboxListItemCell #loading-spinner{
                position: relative;
                top: calc(50% - 10px);
                left: calc(50% - 10px);
                padding: 0;
                margin: 0;
                border: 0;
                border-radius: 0;
                height: 20px;
                width: 20px;
            }
            .inboxListItemCell #loading-spinner .spinner-icon{
                width: 20px;
                height: 20px;
            }

            .rotateDeviceMessage {
                display: inline-block;
                top: 50%;
                position: absolute;
                left: 50%;
                transform: translate(-50%, -50%)
            }
            .folderViewDialog .modal-dialog{
                min-width: inherit;
                max-height: inherit;
            }
            .folderViewDialog .folderViewer{
                height: 100% !important;
            }
            .documentsList .fileNameVal .name-span:hover {
                text-decoration: inherit;
            }
            .rightClickMenu a{
                padding: 9px 5px;
            }
            .rightClickMenu bt{
                padding: 9px 5px;
            }
            .rightClickMenu ul li{
                height: inherit !important;
            }
            .rightClickMenu .documentsList ul li{
                height: inherit;
                padding-left: 15px;
            }
            .bv-ts-grid-ul-li {
                line-height:40px;
            }
            .bv-tree-grid-tree-node {
                height: 50px;
            }
            /* .bv-compose-message-screen {
                min-height: auto!important;
                max-height: none!important;
            } */
            .bv-compose-message-screen .outer {
                height: 100%;
            }
            .userActionMenu {
                display: none !important;
            }
            #inboxRightSection .bv-show-panel-content-arrow{
                top: 0px;
                left: -15px;
                z-index: 1;
                padding: 15px 30px 30px;
            }
            .bv-inbox-thread-panel{
                position: relative;
            }
            #inboxRightSection .bv-hide-show-content-arrow{
                margin: -10px 0 0 -15px;
                padding: 15px 30px 30px 15px;
            }
            &.bv-surface #inboxRightSection .bv-hide-show-content-arrow{
                position: absolute;
                top: 0px;
                left: -5px;
                margin: 0;
                padding: 15px 30px 30px 15px;
            }
            .bv-mobile-compose-message .bv-threads-background{
                background: #f0f0f0;
            }
        }
    }


    /* iPad in landscape. */
    @media only screen and (min-device-width : 0px) and (orientation : landscape), handheld, print { /* STYLES GO HERE */
        .documentsList .list-checkbox {
            margin-top: -40px;
        }
        /* .bv-mobile .bvhome .bv-content{
            transform: translateX(300px);
            -webkit-transform: translateX(300px);
            margin-left: 0px;
            transition: 200ms all;
        } */
        .approval-main {
            min-width:100%;
        }

        &.bv-mobile{
            .newItemsMain .bv-slider > ul{
                min-width: inherit;
                margin: 0px 4.36% 65px;
            }
            .newItemsMain .bv-slider > ul > li{
                width: 43.3% !important;
                margin: 107px 3.3%;
                height: calc(100% - 214px);
            }
            .bv-slider > div{
                top: calc(50% - 45px);
            }
            .bv-slider > div.bv-slider-right-btn{
                right: 20px;
            }
            .bv-slider > div.bv-slider-left-btn{
                left: 18px;
            }
            .portal-name-filedetails.new-items-attach-name{
                margin-top: 0px;
            }
            .bv-calendar-content .bv-slider > ul > li{
                margin: 40px 3.3%;
            }

            .bv-meeting-cards .bv-slider > ul{
                min-width: inherit;
                margin: 0 35px;
                padding: 0px 0px 65px;
            }
            .bv-meeting-cards .bv-slider > ul > li{
                width: 290px !important;
                margin: 40px 13.2px !important;
            }
            .bv-meeting-cards .bv-slider > div.bv-slider-left-btn{
                left: 5px;
            }
            .bv-meeting-cards .bv-slider > div.bv-slider-right-btn{
                right: 5px;
            }
            .expandHigh .bv-meeting-cards .bv-slider > ul > li{
                width: 375px !important;
            }
        }
    }

    /* iPad in portrait. */
    @media only screen and (min-device-width : 768px) and (max-device-width
        : 1024px) and (orientation : portrait), handheld, print {
        .bvhome .bv-title>div>div>span {
            font-size: 1.5em;
            letter-spacing: 2px;
        }
        .bvhome .rightMenu {
            right: 2px;
        }
        .bvhome .rightMenu li a .iconText {
            font-size: 0.9em;
        }

        .approval-main {
            min-width:100%;
        }
    }

    /* Portrait. */
    @media only screen and (min-device-width : 0px) and (orientation : portrait), handheld, print {

        &.bv-mobile{
            .bv-meeting-cards .bv-slider > ul{
                margin: 0px 9.36% 65px;
                padding: 0 0.1% 65px;
            }
            .bv-meeting-cards .bv-slider > ul > li{
                width: 93% !important;
                margin: 20px 3.3% !important;
            }
        }
    }

    @media screen, handheld, print{
        &.bv-mobile{

            .bv-support .bv-support-center{
                display: none;
            }

        }
    }
}
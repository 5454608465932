@import 'src/utils/variables';

.ad-search-top-panel{
    display: table;

    .top-panel{
        width: 100%;
        height: 30px;
        border-bottom: 1px solid $grayLighter;
        font-size: 13px;
        color: $gray;
        padding-top: 8px;
        font-family: $nasdaqFontRegular;
        white-space: nowrap;

        > span{
            display: none;
        }

        &.advanced{
            height: 61px;
            padding-top: 7px;

            ul.table{
                > li{
                    > div{
                        &:nth-child(1){
                            padding: 0px 10px 0 0;
                            width: 30%;
                        }
                        &:nth-child(2){
                            width: 8%;
                            padding: 0;
                        }
                        &:nth-child(3){
                            width: 15%;
                        }
                        &:nth-child(4){
                            display: table-cell;
                            width: 10%;
                        }
                        &:nth-child(5){
                            width: 15%;
                            text-align: right;
                        }
                    }
                }
            }

            > span{
                margin-bottom: 11px;
                display: inline-block;
                font-style: italic;
                font-family: $defaultFont;
                font-size: 13.5px;

                &.titleBtn{
                    color: $nasdaqBlue;
                    font-style: normal;
                    cursor: pointer;
                }
            }

            titl{
                &:nth-child(5){
                    margin-left: 6.2%;
                }
            }
        }

        .title-box{
            position: relative;
            width: 100%;
            padding-top: 33px;
        }

        ul.table{
            display: table;
            table-layout: fixed;
            width: 100%;

            > li{
                display: table-row;

                > div{
                    display: table-cell;
                    padding: 0px 10px;
                    text-align: left;

                    &:nth-child(1){
                        width: 30%;
                    }
                    &:nth-child(2){
                        width: 8%;
                        padding: 0;
                    }
                    &:nth-child(3){
                        width: 8%;
                    }
                    &:nth-child(4){
                        display: none;
                    }
                    &:nth-child(5){
                        width: 13%;
                        text-align: right;
                    }

                    titl{
                        display: block;
                        cursor: pointer;

                        &::after{
                            content: "";
                            display: inline-block;
                            margin-left: 4px;
                            position: relative;
                            top: -1px;
                            border-top: 6px solid $gray;
                            border-right: 4px solid transparent;
                            border-left: 4px solid transparent;
                        }

                        &.reversed{
                            &::after{
                                transform: rotate(180deg);
                                -webkit-transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }
    }
}
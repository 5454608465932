@import '../../../utils/variables';

@font-face {
  font-family: 'SourceSansProRegular';
  font-style: normal;
  font-weight: normal;
  src: url('#{$resources-path}/fonts/SourceSansPro/SourceSansPro-Regular-webfont.eot');
  src: url('#{$resources-path}/fonts/SourceSansPro/SourceSansPro-Regular-webfont-iefix.eot') format('embedded-opentype'),
       url('#{$resources-path}/fonts/SourceSansPro/SourceSansPro-Regular-webfont.woff') format('woff'),
       url('#{$resources-path}/fonts/SourceSansPro/SourceSansPro-Regular-webfont.ttf') format('truetype'),
       url('#{$resources-path}/fonts/SourceSansPro/SourceSansPro-Regular-webfont.svg') format('svg');
}
@font-face {
  font-family: 'SourceSansProBold';
  font-style: normal;
  font-weight: normal;
  src: url('#{$resources-path}/fonts/SourceSansPro/SourceSansPro-Bold-webfont.eot');
  src: url('#{$resources-path}/fonts/SourceSansPro/SourceSansPro-Bold-webfont-iefix.eot') format('embedded-opentype'),
       url('#{$resources-path}/fonts/SourceSansPro/SourceSansPro-Bold-webfont.woff') format('woff'),
       url('#{$resources-path}/fonts/SourceSansPro/SourceSansPro-Bold-webfont.ttf') format('truetype'),
       url('#{$resources-path}/fonts/SourceSansPro/SourceSansPro-Bold-webfont.svg') format('svg');
}
@font-face {
  font-family: 'SourceSansProLight';
  font-style: normal;
  font-weight: normal;
  src: url('#{$resources-path}/fonts/SourceSansPro/SourceSansPro-Light-webfont.eot');
  src: url('#{$resources-path}/fonts/SourceSansPro/SourceSansPro-Light-webfont-iefix.eot') format('embedded-opentype'),
       url('#{$resources-path}/fonts/SourceSansPro/SourceSansPro-Light-webfont.woff') format('woff'),
       url('#{$resources-path}/fonts/SourceSansPro/SourceSansPro-Light-webfont.ttf') format('truetype'),
       url('#{$resources-path}/fonts/SourceSansPro/SansPro-Light-webfont.svg') format('svg');
}
@font-face {
  font-family: 'SourceSansProSemibold';
  font-style: normal;
  font-weight: normal;
  src: url('#{$resources-path}/fonts/SourceSansPro/SourceSansPro-Semibold-webfont.eot');
  src: url('#{$resources-path}/fonts/SourceSansPro/SourceSansPro-Semibold-webfont-iefix.eot') format('embedded-opentype'),
       url('#{$resources-path}/fonts/SourceSansPro/SourceSansPro-Semibold-webfont.woff') format('woff'),
       url('#{$resources-path}/fonts/SourceSansPro/SourceSansPro-Semibold-webfont.ttf') format('truetype'),
       url('#{$resources-path}/fonts/SourceSansPro/SourceSansPro-Semibold-webfont.svg') format('svg');
}

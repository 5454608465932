@import 'src/utils/variables';

.annotation-handling-dialog{
    .modal-dialog{
        width: 770px;

        .bv-input-btn{
          width: 150px !important;
          &:first-child{
            right: 150px;
          }

          &[disabled]{
            color: #B6B7BA;
            background: #E4E4E5;
          }
        }

        .bv-modal-body > div {
          padding: 20px;

          > span{
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 19px;
            letter-spacing: 0.1px;
            display: block;
          }

          button{
            background: none;
            border: 2px solid #D6D6D6;
            border-radius: 5px;
            font-size: 14.2px;
            letter-spacing: 0.1px;
            width: 100%;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 20px;
            outline: none;
            text-align: left;

            &:hover{
              background: #F1FAFE;
            }

            &.active{
              border: 2px solid #60D5EC;
              background: #DBEFF5;
            }

            div{
              font-weight: 600;
              font-size: 16px;
              margin-bottom: 9px;
              letter-spacing: 0.1px;
            }

            > span{
              text-align: left;
              line-height: 17px;

              > span:first-child{
                font-weight: 600;
              }
            }

            &.keep{
              height: 89px;
            }
            &.move{
              height: 114px;
            }
            &.purge{
              margin-bottom: 0;
              height: 78px;
            }
          }
        }

        .warning{
          height: 43px;
          border: 1px solid #D6D6D6;
          border-radius: 5px;
          padding: 0 15px 0 50px;
          display: flex;
          position: relative;
          margin-bottom: 25px;
          
          div{
            align-self: center;
            font-size: 14.2px;
            letter-spacing: 0.1px;
          }

          &:after{
            content: '⚠';
            font-family: "SSSymbolicons";
            position: absolute;
            left: 25px;
            height: 17px;
            font-size: 14px;
            color: #FECB00;
            top: 11px;
          }
          &:before{
            content: '';
            width: 12px;
            height: 43px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            position: absolute;
            background: #FECB00;
            left: -1px;
            top: -1px;
          }
        }
    }
}
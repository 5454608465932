@import 'src/utils/variables';

.nasdaq.bv-mobile{
    .dialogs{

        &.bv-add-comments{

            .modal-dialog{
                width: 100%;

                .bv-modal-body{
                    height: calc(100% - 99px) !important;
                    margin-bottom: 0px;

                    .add-comments-main{
                        height: 100%;

                        textarea{
                            height: 94%;
                        }
                    }
                }
            }
        }

        &.bv-signature-dialog .bv-modal-body {
            height: calc(100% - 99px) !important;
        }

        &.bv-support .bv-support-center{
            display: none;
        }

        &.bv-search-results-mobile{
            .bv-modal-body{
                height: 100%;

                repositorylist{
                    height: calc(100% - 25px);
                }
                .top-panel{
                    ::after{
                        display: none;
                    }
                }
            }

            .bv-modal-btn-close{
                top: -2px;
            }

            .bv-search-results-mobile-search-icon{
                left: 47px;
                top: -29px;
            }

            back-button{
                left: 42px;
            }
            .back-button .bv-search-results-mobile-search-icon{
                left: 75px !important;
            }

            .searchInputText{
                padding-left: 64px;
            }
        }

        &.ad-search-dialog{
            top: 98px;
            height: calc(100% - 143px);
            border: 0;

            .modal-dialog{
                left: 0;
                border: 0;
                min-width: 0;

                .bv-modal-body{
                    height: 100%;
                }

                .bottom-box{
                    display: none;
                }

                .top-panel{
                    height: 44px;
                    padding-top: 15px;
                    font-size: 15px;
                    border: 0;
                }

                .title{
                    height: 44px;
                    font-size: 17px;
                    line-height: 30px;
                    background: $grayLightest;
                    color: $gray;
                }

                ul li{
                    height: 58px;

                    > div{
                        font-size: 16px;
                        font-weight: 300;
                    }
                }

                @media only screen and (orientation : portrait), handheld, print {
                    .ad-search{

                        ul.table{

                            li{
                                > div{
                                    &:nth-child(1){
                                        width: 50%;
                                    }
                                    &:nth-child(2){
                                        width: 10%;
                                    }
                                    &:nth-child(3){
                                        display: none;
                                    }
                                    &:nth-child(4),
                                    &:nth-child(5){
                                        width: 40%;
                                    }
                                }
                            }
                        }
                    }

                    .results-list{
                        > div{
                            position: absolute;
                            overflow-y: auto;
                            height: calc(100% - 44px);
                        }
                    }
                }
            }
        }
    }

    .bv-modal-window-new-mobile-attachment{
      .bv-modal-btn-close{
        padding: 15px !important;
        svg{
          width: 22px;
          height: 22px;
          fill: #fff;
          display: block !important;
        }
      }
      .bv-modal-btn-back{
        top: -1px;
        left: 0 !important;
        padding: 16px 15px;
      }
    }

    .modal-backdrop.ad-search-dialog{
        display: none;
    }

    &.sidebar-opened{
        .dialogs{
            &.ad-search-dialog{
                left: 256px;
            }
        }
    }
}
.n3a-auth-dialog {

    .modal-dialog{
        width: 850px;
    }
    iframe{
        border: 0;
        width: 100%;
        height: 720px;
    }
    .bv-modal-dialog{
        background: #000;
    }
    .bv-modal-header-small .bv-modal-btn-close > svg{
        fill: #fff !important;
    }

    .bv-modal-body{
        top: -27px;
    }

    .bv-modal-btn-close{
        z-index: 1;
    }

    .bv-modal-btn-close{
        right: 0px;
        position: absolute;
        top: 0px;
        cursor: pointer;
        background: transparent;
        width: 41px;
        height: 41px;
        padding: 13px;
        border-top-right-radius: 5px;
    }

}


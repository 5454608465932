@import 'src/utils/variables';
@import 'src/utils/classes';

.nasdaq.bv-mobile{

    .approval-top-panel .top-panel{
        padding: 0;
        height: auto;

        > div:nth-child(1){
            display: none;
        }

        > div:nth-child(2){
            padding-top: 10px;
        }
    }
   
    .approval-menu-icon {
      display: none !important;
    }

    .approval-list{
        height: calc(100% - 30px);
    }
}
@import 'src/utils/variables';

.bv-middle-bar{
    margin-top: -$middlebarHeight;
    position: relative;
    z-index: 3;

    .bv-title{
        height: $middlebarHeight;
    	// background: $grayDarkestHeader !important;
        z-index: 2;
        position: relative;
    }

    .bv-title > div > div > span{
        position: absolute;
        width: 100%;
        font-size: 15.2px;
        letter-spacing: 1.5px;
        font-weight: 400;
        padding: 12px 15px 0px 15px;
        float: left;
        display: inline;
        /* color: #fff; */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 330px);
    }

    .search-box{
        width: 260px;
        height: 44px;
        display: block;
        border-right: 1px solid $grayLight;
        top: -12px;
        position: relative;
        padding-top: 12px;
        float: left;
        margin-right: 10px;
        display: none;

        span{
            cursor: pointer;
        }
    }
	.module-name-label {
		text-overflow: ellipsis;
	    display: inline-block;
	    overflow: hidden;
	}
}
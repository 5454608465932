@charset "UTF-8";
/**
 * Icons
 * icons/icons.less
 */
.icon {
  font-family: "SSStandard";
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
.icon:before {
  content: attr(data-icon);
}
.icon.icon-symbol {
  font-family: "SSSymbolicons";
  font-weight: symbol;
  font-weight: 600;
}
.icon.icon-symbol-outline {
  font-family: "SSSymbolicons";
  font-weight: outline;
  font-weight: 200;
}
.icon.icon-glyphish,
.icon.icon-glyphish-filled {
  font-family: "SSSymbolicons";
  font-weight: glyphish;
  font-family: "SSGlyphish";
  font-weight: 600;
}
.icon.icon-glyphish-outlined {
  font-family: "SSSymbolicons";
  font-weight: glyphish-outlined;
  font-family: "SSGlyphish";
  font-weight: 200;
}



@import 'src/utils/variables';
@import 'src/utils/classes';

.bv-conf-details-panel{

    .view-full-event{
        @extend .btn;
        height: 51px;
        width: 100%;
        border: none;
        border-bottom: 1px solid $grayLight;
        border-radius: 0;
        background: $grayLighter;
        color: $nasdaqBlue;
        outline: none;

        &:hover,
        &:active,
        &:focus{
            border: 1px solid $grayLight;
            background: $grayLighter;
        }
    }

    &.modal-backdrop{
        opacity: 0.1;
        top: 112px;
        bottom: 35px;
    }

    &.fade{
        .modal-dialog{
            transform: translate(100%, 0);
            -webkit-transform: translate(100%, 0);
        }
    }
    &.in{
       .modal-dialog{
           transform: translate(0, 0);
           -webkit-transform: translate(0, 0);
       }
    }

    .modal-dialog{
        width: 380px;
        position: absolute;
        right: 0;
        left: calc(100% - 380px);
        top: 112px;
        bottom: 35px;
        transform: translate(100%, 0);
        -webkit-transform: translate(100%, 0);
        border-radius: 0px;
        margin: 0;
        padding: 0;
        box-shadow: none !important;
        background: #fff;

        .modal-content{
            border-radius: 0px;
            height: 100%;

            .bv-modal-blue-header{
                border-radius: 0px;
                background-color: $blue !important;

                .bv-modal-btn-close{
                    border-radius: 0px;
                    background-color: $grayDark !important;
                    opacity: 0.5;

                    &:hover{
                        background-color: $gray !important;
                    }
                }

                .bv-modal-title{
                    letter-spacing: 0px;
                }
            }

            .bv-modal-body{
                height: calc(100% - 50px);

                .details{
                    height: calc(100% - 161px);
                    overflow: auto;
                    margin-top: 9px;

                    &.show-full-event-btn{
                        height: calc(100% - 212px);
                    }

                    line{
                        display: block;
                        font-size: 14px;
                        padding-top: 5px;
                        color: $grayDarker;

                        span:nth-child(1){
                            display: inline-block;
                        }
                        span:nth-child(2){
                            display: inline-flex;
                            word-break: break-all;
                        }
                    }

                    section{
                        titl{
                            display: block;
                            font-size: 15.5px;
                            font-weight: 500;
                            font-family: $nasdaqFontSemibold;
                            color: $grayDarkest;
                            margin-bottom: 3px;
                        }
                        p{
                            font-size: 14px;
                            color: $grayDarker;
                        }
                    }
                    section:nth-child(1){
                        padding: 15px 20px;
                        min-height: 106px;
                        border-bottom: 1px solid $grayLighter;

                        line{
                            span:nth-child(1){
                                width: 60px;
                            }
                            span:nth-child(2){
                                width: 270px;
                            }
                        }
                    }
                    section:nth-child(2){
                        padding: 26px 20px;
                        min-height: 140px;
                        border-bottom: 1px solid $grayLighter;

                        line{
                            span:nth-child(1){
                                width: 115px;
                            }
                            span:nth-child(2){
                                width: 215px;
                            }
                        }
                    }
                    section:nth-child(3){
                        padding: 24px 20px;
                    }
                    section:nth-child(4){
                        padding: 28px 20px;
                        height: 110px;
                        border-top: 1px solid $grayLighter;
                        bottom: 0;
                        position: absolute;
                        width: 100%;
                    }

                    .nav-tabs{
                        border-bottom: none;
                        letter-spacing: normal;
                        top: 13px;
                        display: block;
                        position: relative;

                        li{
                            margin-bottom: -3px;
                            border-bottom: 3px solid transparent;
                            width: 33.3%;
                            text-align: center;

                            bt{
                                @extend .btn;
                                line-height: 0px;
                                padding: 13px 15px 11px;
                                width: 100%;
                                font-size: 12px;
                             }

                             &:nth-child(1) bt,
                             &:nth-child(2) bt{
                                border-right: none;
                             }

                             &:nth-child(2) bt{
                                border-radius: 0;
                             }
                             &:nth-child(1) bt{
                                border-top-right-radius: 0;
                                border-bottom-right-radius: 0;
                             }
                             &:nth-child(3) bt{
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                             }

                             &.active bt{
                                background: $nasdaqBlue;
                                color: #fff;
                             }
                         }
                    }
                }

                .attendees{
                    height: calc(100% - 42px);

                    &.full-event-btn{
                        height: calc(100% - 93px);
                    }

					.deactivated-user{
						color: #6b6d75;
						font-weight: 300;
						font-style: italic;
					}
                    section{
                        border-bottom: 1px solid $grayLighter;
                        padding: 26px 20px 13px;

                        titl{
                            display: inline-block;
                            font-size: 15.5px;
                            font-weight: 500;
                            font-family: $nasdaqFontSemibold;
                            color: $grayDarkest;
                            margin-bottom: 9px;
                        }
                        badge{
                            background: $nasdaqBlueHover;
                            color: #fff;
                            display: inline-block;
                            width: 28px;
                            height: 20px;
                            text-align: center;
                            border-radius: 10px;
                            font-size: 14px;

                            &.zero{
                                background: $grayMid;
                            }
                        }

                        > span{
                            display: block;
                            font-size: 14.5px;
                            padding: 2px 0;
                            color: $grayLight;
                            font-style: italic;
                        }

                        ul{
                            li{
                                font-size: 14.5px;
                                padding: 5px 0;
                                color: $grayDarker;
                            }
                        }
                    }

                    > section{
                        padding: 11px 20px;
                        font-size: 12.6px;
                        border-bottom: none;
                        background: #efefef;

                        icon{
                            position: absolute;
                            margin: 8px;
                            font-size: 11.5px;
                            color: $gray;
                        }
                        input{
                            padding: 5px 5px 5px 27px;
                            border: none;
                            width: 100%;
                            border-radius: 3px;
                        }
                    }

                    div{
                        height: calc(100% - 50px);
                        overflow: auto;

                        section{
                            &:last-child{
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
@import 'src/utils/variables';

.nasdaq{
    .bv-calendar-content{
        overflow:hidden;

        > div{

            .parent{
                height: 100%;
                background: #fff;
                padding: 10px 0;
                white-space:nowrap;
                overflow-x:auto;
                overflow-y:hidden;
            }

            .bv-slider{

                > ul{
                    min-width: 480px;
                    min-height: 415px;

                    > li{
                        width: 93% !important;
                        margin: 20px 3.3% !important;

                        canvas{
                            display:block;
                        }
                    }
                }
            }

            .parent > ul > li > div{
            /* 	  display:inline-block;
                  height: 100%;
                  width: 100%;
                  margin-right: 11px; */
                  padding: 10px 20px;
            }
            .parent .child:last-child{
                margin:0;
            }
            .parent .row{
                margin: 0.6% 0px;
                height: 20%;
            }
            .parent .rowplus .row{
                height: 16.4%;
            }

            .parent .row .cell:hover {
                border: 1px solid #2273a5;
            }

            .parent .row .cell{
                display: inline-block;
                width: 13.7%;
                margin: 0px 0.3%;
            /* 	background: #fff; */
                height: 100%;
                position: relative;
                border: 1px solid #ccc;
            }
            .parent .row .cell .day,
            .parent .row .cell .daysplit{
                padding: 0px;
                font-size: 14px;
                line-height: 30px;
                text-align: center;
                width: 30px;
                height: 30px;
                margin: 2px auto;
                border-radius: 30px;
                color: #000;
                opacity: 0;
                font-weight: 500;
            }
            .parent .row .cell.current .day,
            .parent .row .cell.current .daysplit{
                opacity: 1;
            }
            .parent .row .cell.current .bv-calendar-dot{
                opacity: 1;
            }
            .parent .row .cell.current .bv-calendar-dot:hover{
                cursor: pointer;
            }
            /*.parent .row .cell.current:hover{
                cursor: pointer;
            }*/
            .parent .row .cell.current:active{
                background: rgba(0,0,0,0.04);
            }
            .parent .row .cell.weekend{
                background: rgba(236, 236, 236, 1);
            }

            /* .parent .row .cell.current-day{
                background: rgba(0, 0, 0, 0.1);
            }
             */
             .parent .row .cell .day.current-day,
             .parent .row .cell .daysplit.current-day{
                color: #fff;
            }
            .parent .title-month{
                font-size: 30px;
                font-weight: 500;
                text-align: center;
            /* 	margin: 5px 0px 10px 0px; */
            }
            .parent .title-month.empty{
                display: inline-block;
            }
            .parent .week-days{
                display: inline-block;
                width: 14.3%;
                text-align: center;
                font-size: 14px;
                font-weight: 500;
            }
            .parent .events{
                position: absolute;
                right: 0px;
                font-size: 10px;
                top: 17px;
                overflow-x: hidden;
                overflow-y: auto;
                width: 50%;
                height: calc(100% - 17px);
            }
            .parent .events .dot{
            /* 	width: 7px;
                height: 7px;
                background: red;
                border-radius: 14px;
                opacity: 1;
                position: absolute;
                right: 5px;
                top: 3px; */
                margin-top: 2px;
                width: 7px;
                height: 7px;
                background: red;
                border-radius: 14px;
                opacity: 1;
                position: absolute;
                right: 5px;
                display: inline-block;

            }
            .parent .row .cell .events span{
                opacity: 0;
                position: relative;
                top: -2px;
                color: red;
                cursor: pointer;
            }
            .parent .row .cell:hover .events span{
                opacity: 1;
            }
            .parent .row .cell:hover .events .dot{
                opacity: 0;
            }
            .parent .row .cell .events:hover span{
                opacity: 1;
            }
            .parent .row .cell .events:hover .dot{
                opacity: 0;
            }

            &:hover .bv-carousel-button>div,
            &:hover .bv-carousel-button>div{
                -webkit-transform: translateX(0px);
                -moz-transform: translateX(0px);
                -o-transform: translateX(0px);
                transition: translateX(0px);
            }

            loader{
                background: url('../assets/images/loader_calendar.gif');
                height: 32px;
                width: 32px;
                display: block;
                margin: 0 auto;
                top: calc(50% - 32px);
                position: relative;
            }
        }
    }

    .ngdialog.calendar-dialog .ngdialog-overlay{
        z-index:-1;
    }

    .ngdialog.calendar-dialog .bv-dialog-user-list{
        width:200px;
        background:#f0f0f0;
        position:absolute;
        z-index:-1;
        top: 10px;
        right: 0px;
        bottom: 10px;
        border-radius: 5px;
    }
    .ngdialog.calendar-dialog .bv-dialog-user-list.expanded{
        right:-201px;
    }

    .ngdialog.calendar-dialog .bv-dialog-user-list .tab-content ul{
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
        display: block;
        padding: 0px 0px 0px 10px;
    }

    .event-bar{
        background: #aeaeae;
        float: left;
        width: 6px;
        margin-top: 6px;
    }

    .event-active-bar{
        background: #2273a5;
    }

    .event-active-bar-space{
        margin-left: 10px;
    }

    .bv-events-details{
        display: inline-block;
        max-width: calc(100% - 14px);
        position: relative;
    }

    .bv-events-list > .scroll-list-items .scroll-list {
        max-width: 100%;
    }

    .ngdialog.calendar-dialog .bv-dialog-user-list .tab-content{
        position: absolute;
        bottom: 15px;
        overflow-y: auto;
        top: 85px;
        width: 100%;
    }

    .ngdialog.calendar-dialog .bv-dialog-users-btn{
        right: 15px;
        background: #ccc;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 60px;
        cursor:pointer;
    }

    .ngdialog.calendar-dialog .bv-dialog-user-list .nav > li > a{
        padding: 5px 10px;
        border-radius: 0px;
        border: 0;
    }
    .ngdialog.calendar-dialog .bv-dialog-user-list .bv-users-title{
        padding:10px;
        display:block;
    }
    .ngdialog.calendar-dialog .bv-dialog-user-list .tab-content .tab-pane>ul>li:hover{
        background: rgba(0,0,0,0.04);
        cursor: pointer;
    }
    .ngdialog.calendar-dialog .bv-dialog-user-list .treeNode{
        white-space: nowrap;
    }
    .ngdialog.calendar-dialog .bv-dialog-user-list .treeNode.selected{
        background:none;
    }
    .ngdialog.calendar-dialog div[icon-renderer] img{
        height: 17px;
        padding:0;
        margin:0px 2px;
    }
    .ngdialog.calendar-dialog .bv-dialog-user-list div[data-tree-model] li{
        padding:0;
        line-height:20px;
    }
    .ngdialog.calendar-dialog .bv-dialog-user-list div[data-tree-model] li i,
    .ngdialog.calendar-dialog .bv-dialog-user-list div[data-tree-model] li span{
        top: 3px;
        width:100%;
    }
    .ngdialog .bv-dialog-user-list div[icon-renderer]{
        position: relative;
        top: 0px;
        display:inline-block;
    }
    .ngdialog.calendar-dialog ul{
        display: inline-table;
    }

    /* iPhone Portrait */
    @media screen and (max-device-width: 480px) and (orientation:portrait) {
        .bv-calendars-list>ul>li span.title {
            font-size: 1.0em;
        }
    }
    .bv-calendar .showWeeks{
        width:100%;
    }
    .bv-calendar .showWeeks>bv-cell{
        width: 25px;
    }
    .showWeeks > div{
        display:inline-block;
        width: 14%;
    }

    .bv-calendar-box{
        height: calc(100% - 150px);
    }


    .bv-calendar-mask{
        width: 100%;
        height: 100%;
        position: absolute;
        background: #FFF;
        z-index: 1;
        top: 0;
        opacity: 0.9;
    }

    .bv-calendar-line{
        height: 45px;
        padding-top: 10px;
    }
    .bv-calendar-line > div{
        background: #aeaeae;
        height: 8px;
    }

    .bv-calendar-dot{
        height: 16px;
        width: 16px;
        background: #ccc;
        margin: 0 auto;
        border-radius: 50%;
        position: absolute;
        top: 66%;
        left: calc(50% - 8px);
        opacity: 0;
    }
    .additional-day .bv-calendar-dot{
        left: calc(50% - 6px);
    }
    .building .parent{
        overflow-x: hidden;
        opacity: 0.5;
    }
    .building svg{
        display: none;
    }

    .bv-carousel-button{
        width: 97px;
        position: absolute;
        z-index: 1;
        top: calc(50% - 50px);
    }
    /* .bv-carousel-button:hover{
        background: rgba(204, 204, 204, 0.2);
    } */

    .bv-carousel-button>div:hover>svg{
        cursor: pointer;
        opacity: 1;
    }
    .bv-carousel-button-right{
        right:0;
    }
    .bv-carousel-button>div{
        position: absolute;
        left: calc(50% - 45px);
    }
    .bv-carousel-button>div>svg{
        fill:#515151;
        width: 69px;
        height: 97px;
        opacity: 0.2;
    }
    .bv-carousel-button-right>div{
        -webkit-transform: translateX(100px);
        -moz-transform: translateX(100px);
        -o-transform: translateX(100px);
        transition: translateX(100px);
    }
    .bv-carousel-button-left>div{
        -webkit-transform: translateX(-100px);
        -moz-transform: translateX(-100px);
        -o-transform: translateX(-100px);
        transition: translateX(-100px);
    }

    .bv-cards-content{
        overflow: hidden;
        position: absolute;
        /* height: calc(100% - 80px); */
    }

    [state="home.meetings"] .bv-slider > ul > li > div{
        padding: 20px;
    }

    .bv-cards-content>div:first-child{
        overflow: auto;
        position: absolute;
     }
     .bv-cards-content #meetingListView{
      overflow-y: hidden;
     }

    /* .bv-cards-content .bv-scrollbar,*/
     .bv-events-list.bv-scroll-list .scroll-list-items{
        height: calc(100% - 80px);
     }

    .bv-events{

        .modal-dialog{
            -webkit-transform: inherit !important;
            -ms-transform: translate(0,0) !important;
            transform: inherit !important;
            width: 340px;
        /* 	height: 100%; */
            margin: 150px auto;
            position: absolute;
            top: inherit;
            left: inherit;
        }
        .bv-modal-header-small{
            min-height: inherit;
            padding: inherit;
        }
        .bv-events-title{
            margin: -4px 0px 0px;
            font-size: 26px;
            font-weight: 600;
        }
        .modal-content{
            padding: 20px;
            height: 360px;
        /*  	max-height: 525px; */
        }
        .bv-modal-body{
            height: 100%;
        }
        .bv-events-list > .scroll-list-items .scroll-list > li:last-child{
            margin: inherit;
        }
        .bv-events-list > .scroll-list-items .scroll-list > li > div:last-child,
        .bv-events-list > .scroll-list-items .scroll-list > li .bv-events-list-teamspace{
            color: #898989;
            margin: -1.4px 0px;
        }
        .bv-arrow-left{
            position: absolute;
            bottom: 15px;
            left: -40px;
        }
        .bv-arrow-right{
            position: absolute;
            bottom: 15px;
            right: -40px;
        }
    }

    .bv-cards-content .bv-events-list > .scroll-list-items .scroll-list > li,
    .bv-events .bv-events-list > .scroll-list-items .scroll-list > li{
        /* margin-bottom: 33px; */
        height: 100% !important;
        border: inherit;
        border-bottom: none;
        margin-bottom: 17px;
    }

    .bv-events-list .bv-events {
        height: calc(100% - 80px);
    }

    .bv-cards-content .bv-events-list > .scroll-list-items .scroll-list > li .bv-events-list-name,
    .bv-events .bv-events-list > .scroll-list-items .scroll-list > li .bv-events-list-name{
        height:29px;
        font-size: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 1px;
    }

    .bv-events .bv-events-list > .scroll-list-items .scroll-list > li .bv-events-list-teamspace,
    .bv-cards-content .bv-events-list > .scroll-list-items .scroll-list > li .bv-events-list-teamspace
    {
        height:20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .bv-cards-content .bv-events-list > .scroll-list-items .scroll-list > li .bv-events-list-time {
        height:19px;
        line-height:19px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    /* .bv-cards-content .bv-events-list > .scroll-list-items .scroll-list > li > div:last-child, */
    .bv-cards-content .bv-events-list > .scroll-list-items,
    .bv-events .bv-events-list > .scroll-list-items{
        padding: 0;
    }

    /*.bv-cards-content .bv-slider > ul > li > div{
        padding: 15px 30px 30px 30px;
    }
    */
    .bv-cards-content .bv-cards-title{
        font-size: 30px;
        font-weight: 500;
    }

    .bv-meeting-cards .bv-slider > ul {
        min-height: 430px;
        min-width: 880px;
    }

    .bv-meeting-cards .bv-slider > ul > li {
        width:31.7%;
        margin: 20px 0.8%;
    }
    .lastrow > div{
        overflow: hidden;
    }
    .lastrow .additional-day .day{
        display: none;
    }
    .lastrow .additional-day .bv-calendar-dot{
        height: 12px;
        width: 12px;
    }


.month-calendar {
    .fc-toolbar.fc-header-toolbar
      {
       margin-bottom:0 !important;
       display: block !important;

     }
      .fc-toolbar h2
    {
      font-size: 18px !important;
    }
.fc-next-button, .fc-prev-button
      {
     border-radius: 13px !important;
    -webkit-border-radius: 13px !important;

     }
     .fc-button {
         background-color:#e6e6e6;
         border:1px solid;
         border-color: rgba(0,0,0,.1) rgba(0,0,0,.1) rgba(0,0,0,.25);
     }
    .fc-button:focus {
    box-shadow: none;
     }
}

    /* Windows screen - calcendar font and circle size */

    @media screen and (max-height: 700px) {
        .bv-calendar-content .parent .row .cell .daysplit{
            font-size: 12px;
            line-height: 20px;
            width: 20px;
            height: 20px;
        }
        .bv-calendar-content .parent .row .cell .day {
            padding: 0px;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            width: 20px;
            height: 20px;
            margin: 2px auto;
            border-radius: 20px;
            color: #000;
            opacity: 0;
            font-weight: 500;
        }

        .bv-calendar-dot {
            height: 12px;
            width: 12px;
            background: #ccc;
            margin: 0 auto;
            border-radius: 50%;
            position: absolute;
            top: 66%;
            left: calc(50% - 6px);
            opacity: 0;
        }
    }
    @media screen and (max-height: 800px) {
        .rowplus .bv-calendar-dot {
            height: 12px;
            width: 12px;
            background: #ccc;
            margin: 0 auto;
            border-radius: 50%;
            position: absolute;
            top: 66%;
            left: calc(50% - 6px);
            opacity: 0;
        }
    }

	//send-meeting-notification-window
	.send-meeting-notification-window{
		padding:10px;
		& .form-section{
            margin-bottom:15px;
            & .select2-container-multi {
                & .select2-choices {
                    & .select2-search-field input {
                        width:auto !important;
                     }
                }
            }    
			& .form-label{
				font-size:12px;
                display:block;
                font-weight: normal;
			}
			& .subjectBox{
				background: none;
                border:1px solid #d1d1d1;
                border-radius: 3px;
				& .bv-inbox-inputFile{
					width:100%;
					background: none;
                    font-size: 14px;
				}
			}
			& .messageBox{
                border:1px solid #d1d1d1;
                border-radius: 3px;
			}
			& #includeIcsFile{
				width:1px;
                height:1px;
                visibility: hidden;
				&:checked + label.checkbox-label:before {
				    content: '\2713';
				    color: #00bce3;
				}
			}
			& .checkbox-label{
				    font-weight: 400;
				    font-size: 12px;
				    position: relative;
				    padding-left: 22px;
					cursor: pointer;
				&::before {
				    content: '';
					display: inline-block;
				    color: #00bce3;
				    width: 16px;
				    height: 16px;
				    line-height: normal;
				    vertical-align: text-bottom;
				    font-family: "SSStandard";
				    font-weight: normal;
				    font-size: 11px;
				    padding: 1px 2px;
				    -webkit-transition: 100ms;
				    transition: 100ms;
				    border: 1px solid grey;
				    border-radius: 3px;
				    left: 0;
    				position: absolute;
				}
			}
			& .ui-select-container{
				width: 100%;
				& .select2-choices{
					    box-shadow: none;
					    background: none;
					    border: 1px solid #d1d1d1;
					    border-radius: 3px;
				}
				& .ui-select-match-item{
					background: #00bce3;
				    border-radius: 25px;
				    color: #fff;
				    height: 20px;
				    font-size: 13px;
				    padding: 5px 20px 0 5px;
                    position: relative;
                    text-transform: uppercase;
                    font-family:'SourceSansProBold';
					& .select2-search-choice-close{
                        right: 15px;
                        top: 5px;
					    background-image: none !important;
                        position: absolute;
                        opacity: 0.5;
						&::after{
                            transform: rotate(45deg);
                            position: absolute;
                            left: 15px;
                            content: ' ';
                            height: 8px;
                            width: 2px;
                            background-color: #fff;
                        }
                        &::before{
                            transform: rotate(-45deg);
                            position: absolute;
                            left: 15px;
                            content: ' ';
                            height: 8px;
                            width: 2px;
                            background-color: #fff;
                    }
                    &:hover {
                        opacity: 0.8;
                        background-image: none !important;
                    }
					}
				}
				
			}
		}
		
	}
	& .send-button-disabled{
			color: #ffffff!important;
		    background-color: #b6b7ba!important;
		    border-color: #b6b7ba!important;
		    box-shadow: none;
		    cursor: not-allowed!important;
    		pointer-events: none!important;
		}

}
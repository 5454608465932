.dialogs.viewer-bookmark{

    .bv-modal-body div{
        padding: 15px; 
        min-height: 150px;

        span{
            font-size: 14px;
            padding: 5px;
            display: block;
        }

        input{
            width: 100%;
            padding: 5px 10px;
            border: solid 1px #b6b7ba;
            border-radius: 3px;
            font-size: 14px;
        }
    }

}
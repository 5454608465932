@import 'src/utils/variables';

body:not(.nasdaq){

    .top-panel{
        display: none;
    }
	.button-disabled{
	    pointer-events: none;
	}
    /* home.css */
    container.bvhome {
        position: relative;
        height: 100%;
        display: block;
        padding-top: 100px;
        padding-bottom: 35px;
    }
    .bvhome sidebar{
    /* 	top: -45px;
        margin-bottom:-45px; */
        position: relative;
        display: block;
        height:100%;
    }
    .bvhome [home-sidebar]{
    /* 	background:#fff; */
        height: 100%;
        width: 350px;
        float: left;
    /* 	z-index: 2; */
        display: block;
    /*	box-shadow: -42px 0px 38px -50px #000 inset;*/
    }
    
    .tooltip-bv{   			
    		position: relative;
		}
		
		.tooltip-bv{
		
			&:before{
		    		border: solid;
		    		border-color: #333 transparent;
		    		border-width: 6px 6px 0 6px;
		    		bottom: 20px;
		    		content: "";
		    		left: 15%;
		    		position: absolute;
		    		z-index: 99;
				opacity:0;
				transition: opacity 0ms;
			}
		
			&:after{
	    		    background: #333;
			    background: rgba(0, 0, 0, 0.8);
			    border-radius: 5px;
			    -moz-border-radius: 5px;
			    -webkit-border-radius: 5px;
			    bottom: 26px;
			    color: #fff;
			    content: attr(title-bv);
			    left: -40px;
			    padding: 5px 15px;
			    position: absolute;
			    z-index: 98;    
			    font-size: 8px;
			    white-space: normal;
				opacity:0;
				transition: opacity 0ms;
			}
			
			&:hover{
				&:after,
				&:before{
					opacity: 1;
					transition: opacity 1000ms;
				}
			}
		}

    .bvhome .shadowBox {
        position: absolute;
        top: 0;
        /* right: -20px; */
        left: 250px;
        width: 20px;
        height: 100%;
    /*	box-shadow: -5px 0px 40px -3px rgba(0,0,0,0.4);
        -webkit-box-shadow: 6px 0px 50px 0px rgba(0,0,0,0.4);
        -moz-box-shadow: -5px 0px 50px 0px rgba(0,0,0,0.4);*/
        z-index: 2;
    }

    .bv-left-shadow {
        box-shadow: -2px 6px 15px 0px rgba(0,0,0,0.2);
        -webkit-box-shadow: -2px 6px 20px 0px rgba(0,0,0,0.2);
    }

    .bv-right-shadow {
        box-shadow: -2px 6px 15px 0px rgba(0,0,0,0.2);
        -webkit-box-shadow: 2px 6px 20px 0px rgba(0,0,0,0.2);
    }

    .bvhome [bv-right-sidebar]{
        float: right;
        top: 0px;
        position:relative;
        z-index: 3;
        width: 58px;
        background: rgb(255, 255, 255);
    /* 	box-shadow: 42px 0px 38px -50px #000 inset; */
    }
    .bvhome .bv-content{
        display: block;
        /* height: 100%; */
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index:2;
        background:#fff;
    /* 	margin-right: 58px; */
        margin-left: 250px;
    }

    .bvhome .expandHigh>.nav{
        left: 0px;
    }
    .bvhome .expandLow>.nav{
        left: 160px;
    }
    .bvhome [home-sidebar].expandLow{
        width: 70px;
    }
    .bvhome [home-sidebar].expandHigh{
        width: 250px;
    }
    .bvhome [home-sidebar].expandHighSettings{
        width: 609px;
    }
    .bvhome .bv-content.expandLow{
        margin-left:70px;
    }
    .bvhome .bv-content.expandHigh{
        margin-left: 250px;
    }
    /* .bvhome .bv-content.expandRightLow{
        margin-right: 58px;
    }
    .bvhome .bv-content.expandRightHigh{
        margin-right: 240px;
    }
    .bvhome .bv-content.expandRightHide{
        margin-right: 0px;
    } */
    .bvhome .bv-content.expandHighSettings{
        margin-left: 609px;
    }
    .bvhome .bv-content.bv-transition>content>.bv-transclude-content{
        overflow: hidden;
    }

    .bvhome [bv-right-sidebar].expandHigh{
        width: 350px;
    }
    .bvhome [bv-right-sidebar].expandLow{
        width: 70px;
    }

    .bvhome .teamUserProfile.expandHigh{
        right:350px;
    }
    /* .bvhome .teamUserProfile.expandLow{
        right:58px;
    } */
     .bvhome .expandHigh .portalUserList{
        width: 100%;
    }

    .topSettingsForMenuForLogoInExtendedUi {
        top:155px !important;
    }

     .bvhome .bv-mask-sidebar{
        position: absolute;
        height: 45px;
        width: 350px;
        /* z-index: 3; */
    }

     .bvhome .bv-mask-transition{
        position: absolute;
        top: 0px;
        height: 45px;
        z-index: 2;
       /*  left: 250px; */
    }
    .bvMaskTransitionExtended{
        height: 90px !important;
    }

    container.bvhome>content{
        display: table-row-group;
    }
    .bvhome header{
        height: 100px;
        width: 100%;
        display: block;
        margin-top: -100px;
        position: absolute;
        width: 100%;
    /* 	height: 60px;
        background: #036EAA;
        box-shadow: 2px 2px 10px 0px #ccc;
        position: relative;
        margin-top: 53px; */
    }
    .bvhome main{
        position: relative;
    }
    .bvhome footer{
        height: 35px;
        background: #0074AA;
        clear: both;
        display: table-caption;
        caption-side: bottom;
        z-index: 2;
        bottom: 0;
        position: absolute;
    }

    .bv-scroll-list > ul,
    .bv-scroll-list > .scroll-list-items .scroll-list > li
    {
        height: 40px;
    }
    .bv-scroll-list > ul{
        margin-right: 15px;
        overflow-y: hidden;
    }
    .bv-scroll-list > ul > li{
        font-weight: 500;
    }
    .bv-scroll-list > .scroll-list-items .scroll-list > li{
        border-bottom: 1px solid #ccc;
    }
    .bv-scroll-list > .scroll-list-items .scroll-list > li:last-child{
        border-bottom: inherit;
    }
    .bv-scroll-list > .scroll-list-items .scroll-list > li:hover{
        /*background: rgba(0,0,0,0.04);*/
        cursor:pointer;
    }
    .bv-scroll-list > .scroll-list-items .scroll-list > li.drag{
        border-bottom: inherit;
    }
    .bv-scroll-list > ul,
    .bv-scroll-list > .scroll-list-items .scroll-list{
        position: relative;
    }
    .bv-scroll-list > ul > li,
    .bv-scroll-list > .scroll-list-items .scroll-list > li > ul > li
    {
        display: block;
        position: absolute;
        line-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .bv-scroll-list > .scroll-list-items{
        overflow-y: hidden;
        overflow-x: hidden;
        padding-right: 15px;
    }

    .bv-scroll-list > .scroll-list-items:hover{
        overflow-y: overlay;
        overflow-y: auto;
    }

    .bv-scroll-list .bv-scroll-col{
        white-space: nowrap;
    }

    .bv-scroll-list > filter{
        width: 100%;
        display: block;
        margin: 15px 0px;
    }
    .bv-scroll-list > filter span{
        font-size: 16px;
        font-weight: 500;
    }
    .bv-scroll-list > filter input{
        height: 30px;
        width: 100%;
        border: 0;
        background: #EFEFEF;
        padding: 0px 5px;
    }


    .bv-recycle-content{
        padding: 20px;
    }

    .bv-recycle-content .bv-scroll-list > .scroll-list-items{
        height: 250px;
    }

    /* .bv-recycle-content .bv-scroll-list > ul,
    .bv-scroll-list > .scroll-list-items .scroll-list {
        width: 590px;
    } */

    /* .bv-recycle .bv-recycle-content{
        height: 400px;
        padding: 20px;
    }
    .bv-recycle .bv-recycle-content ul li{
        height: 27px;
        border-bottom: 1px solid #ccc;
    }
    .bv-recycle .bv-recycle-content a{
        display: block;
    } */




    /*
    .recycle-list
    {
        height: 300px;
        overflow: auto;
        text-overflow: ellipsis;
        margin-left:0;
    }
     */
    /* .recycleX
    {
        font-size: 27px;
        position: absolute;
        cursor: pointer;
        top: 0px;
        right: 14px;
    } */

    /* .recycle-dialog-class
    {
        height: 400px;
        position: fixed;
        bottom: 45px;
        right: 70px;
        width: 600px;
        background: #f0f0f0;
        margin: 0 auto;
        border-radius: 6px;
        box-shadow: 0px 0px 28px -7px #000;
    } */




    /* .recycle-dialog-class .documentsList
    {
        height: 319px;
        width: 95%;
    } */

    /* .recycle-title
    {
        font-size: 25px;
        font-weight: bold;
        margin: 5px 10px 6px 10px;
    }
     */

    /* BV styles */
    .bvhome bv-row.bv-wrapper{
        height:58px;
    }
    .bvhome bv-cell.bv-sidebar{
    /* 	background: #FAFAFA; */
        width: 350px;
        box-shadow: -7px 0px 39px -4px #ccc inset;
        /* box-shadow: 2px 2px 10px 0px #ccc; */
    /* 	padding: 12px 0px; */
    }
    .bvhome bv-cell.bv-sidebar wrapper{
        border:1px solid transparent;
    }


    /* start right side bar */
    .bvhome bv-cell.bv-right-sidebar{
    /* 	background: #FAFAFA; */
        width: 70px;
        box-shadow: -7px 0px 39px -4px #ccc inset;
        /* box-shadow: 2px 2px 10px 0px #ccc; */
    /* 	padding: 12px 0px; */
    }
    .bvhome bv-cell.bv-right-sidebar wrapper{
        border:1px solid transparent;
    }

    /*  end  */
    .bvhome bv-cell.bv-wrapper{
        width: 1px;
        min-width: 1px;
    }
    .bvhome .bv-content content{
        /* display: table; */
        position: relative;
        display:block;
        padding-top:45px;
    }
    .bvhome .bv-content .approvalsList-new{
        display:none!important;
    }
    .bvhome bv-row.bv-header{
        height: 100px;
    }
    .bvhome .bv-content .bv-main-box{
        box-shadow: 2px 2px 10px 0px #ccc;
    /* 	background: #fff; */
        display: table-cell;
        position: relative;
        height:100%;
    }
    .bvhome .bv-content .bv-main-box>div{
        overflow-y: auto;
        width: 100%;
        height:100%;
    /* 	position: relative; */
    /* 	top: 0px;
        bottom: 0px;  */
    }
    .bvhome .bv-footer-menu{
        position: relative;
        float:right;
        top: 6px;
        margin-right:20px;
    }
    .bvhome .bv-footer-menu li{
        display: inline;
        margin: 0px 0px 0px 40px;
        font-size: 13px;
        font-weight: 400;
        /*letter-spacing: 1.5px;*/
    }
    .bvhome .bv-header-menu{
        position: relative;
        float: right;
        top: 18px;
    }
    .bvhome .bv-header-menu li{
        display:inline;
        margin:0 20px;
        font-size: 1.11em;
        font-weight: 200;
        letter-spacing: 1.5px;
    }
    .bvhome .bv-header-menu li a{
        color: #515151;
    }
    .bvhome .bv-company-logo{
        width: 204px;
        height: 100px;
        margin: 0px 23px;
        display: table;
        float: left;
    /*	border: 1px solid #e5e5e5;*/
    }
    .bv-company-logo-mobile{
        width: 204px;
        height: 100px;
        margin: 10px 26px 0px;
        background: #fff;
        display: none;
    }
    .bvhome .bv-company-logo img{
        /* height: 60px; */
        height: 90px;
    /* 	width: 160px; */
        width: 184px;
    }
    /* .bvhome .bv-company-logo>div{
        display: table-cell;
        vertical-align: middle;
    } */
    .bvhome .bv-company-logo>div>img,
    .bvhome .bv-company-logo-mobile>div>img{
        display: block;
        margin: 0 auto;
    }
    .bvhome .bv-menu{
        position: absolute;
        overflow-y: hidden;
        overflow-x: hidden;
        bottom: 0px;
        top: 50px;
        padding: 10px 0px 0px 0px;
        /*z-index:2;*/
        width: 100%;
    }
    .bvhome .expandLow.bv-transition .bv-menu{
        width: inherit;
    }
    /*.bvhome .expandLow.bv-transition .bv-menu:hover{
        overflow-y: hidden;
    }*/
    .bvhome .bv-menu:hover{
        overflow-y: auto;
    }
    .bvhome .bv-menu bt,
    .bvhome .bv-topmenu bt{
        color: #515151;
        font-size: 20px;
    }
    /* .bvhome .bv-menu bt:active{
        background: red;

        }
     */
    .bvhome .bv-topmenu bt{
        /* color:#fff; */
    }
    .bvhome .bv-menu bt{
        height: 55px;
        display: table-cell;
        vertical-align: middle;
        padding: 0px 22px;
        white-space: nowrap;
    }

    &.jup-offline-mode{
    
    		.userFilterDiv {
            display:none;height:0px;
        }

        .userInviteDiv {
            display:none;height:0px;
        }
		
		.notAllowed{
			cursor: not-allowed;
			> bt {
				pointer-events: none;
			}
			.iconText,
			.badge1,
			.bv-module-row-icon-div{
				opacity:0.2;
			}
		}

		.bv-menu li[state-name="home.documents"],
		.bv-menu li[state-name="home.surveys"],
		.bv-menu li[state-name="home.approvals"]
		{
			@extend .notAllowed;
		}
		
		&.jup-dc-offline-mode{ 
			.bv-menu li[state-name="home.inbox"],
			.bv-menu li[state-name="home.signatures"]
			{
				@extend .notAllowed;
			}
		}

        .settingsMenu li[state-name="portalAdmin"]{
            cursor: not-allowed;
        }

        .settingsMenu li[state-name="portalAdmin"] > a{
            pointer-events: none;
            opacity:0.2;
        }

        .bv-footer-menu li[state-name="home.recycle"],
        .bv-footer-menu li[state-name="home.preferences"]{
            cursor: not-allowed;
        }

        .bv-footer-menu li[state-name="home.recycle"]> a,
        .bv-footer-menu li[state-name="home.preferences"]> a {
            pointer-events: none;
            opacity:0.2;
        }

        .rightClickMenu a[menu-name="edit"],
        .rightClickMenu a[menu-name="rename"],
        .rightClickMenu a[menu-name="pdf"],
        .rightClickMenu a[menu-name="viewas"],
        .rightClickMenu a[menu-name="move"],
        .rightClickMenu a[menu-name="present"],
        .rightClickMenu a[menu-name="editcollate"],
        .rightClickMenu a[menu-name="permission"],
        .rightClickMenu a[menu-name="addToFavorites"],
        .rightClickMenu a[menu-name="addToUserFavorites"],
        .rightClickMenu a[menu-name="boardbook"],
        .rightClickMenu a[menu-name="editboardbook"],
        .rightClickMenu a[menu-name="delete"],
        .rightClickMenu a[menu-name="share"],
        .rightClickMenu a[menu-name="editcollate"],
        .rightClickMenu a[menu-name="addToNewItems"],
        .rightClickMenu a[menu-name="download"],
        .rightClickMenu a[menu-name="versionTracker"],
        .rightClickMenu a[menu-name="copy"],
        .rightClickMenu a[menu-name="cut"],
        .rightClickMenu a[menu-name="paste"],
        .rightClickMenu a[menu-name="properties"],
        .rightClickMenu a[menu-name="send"],
        .rightClickMenu a[menu-name="removeFromBriefCase"],
        .rightClickMenu a[menu-name="removeFromNewItems"],
        .rightClickMenu a[menu-name="createShortCut"],
        .rightClickMenu a[menu-name="unhidefolder"],
        .rightClickMenu a[menu-name="hidefolder"],
        .rightClickMenu a[menu-name="rules"],
        .rightClickMenu a[menu-name="setFollowers"],
        .rightClickMenu a[menu-name="copylink"],
        .rightClickMenu a[menu-name="replace"],
        .rightClickMenu a[menu-name="auditTrail"],
        .rightClickMenu a[menu-name="secureMessage"],
        .rightClickMenu a[menu-name="follow"],
        .rightClickMenu a[menu-name="stopFollow"],
        .rightClickMenu a[menu-name="deleteAllAnnotations"]
        {
            opacity:0.2;
            pointer-events: none;
        }

        .rightClickMenu bt[menu-name="edit"],
        .rightClickMenu bt[menu-name="rename"],
        .rightClickMenu bt[menu-name="pdf"],
        .rightClickMenu bt[menu-name="viewas"],
        .rightClickMenu bt[menu-name="move"],
        .rightClickMenu bt[menu-name="present"],
        .rightClickMenu bt[menu-name="editcollate"],
        .rightClickMenu bt[menu-name="permission"],
        .rightClickMenu bt[menu-name="addToFavorites"],
        .rightClickMenu bt[menu-name="addToUserFavorites"],
        .rightClickMenu bt[menu-name="boardbook"],
        .rightClickMenu bt[menu-name="editboardbook"],
        .rightClickMenu bt[menu-name="delete"],
        .rightClickMenu bt[menu-name="share"],
        .rightClickMenu bt[menu-name="editcollate"],
        .rightClickMenu bt[menu-name="addToNewItems"],
        .rightClickMenu bt[menu-name="download"],
        .rightClickMenu bt[menu-name="versionTracker"],
        .rightClickMenu bt[menu-name="copy"],
        .rightClickMenu bt[menu-name="cut"],
        .rightClickMenu bt[menu-name="paste"],
        .rightClickMenu bt[menu-name="properties"],
        .rightClickMenu bt[menu-name="send"],
        .rightClickMenu bt[menu-name="removeFromBriefCase"],
        .rightClickMenu bt[menu-name="removeFromNewItems"],
        .rightClickMenu bt[menu-name="createShortCut"],
        .rightClickMenu bt[menu-name="unhidefolder"],
        .rightClickMenu bt[menu-name="hidefolder"],
        .rightClickMenu bt[menu-name="rules"],
        .rightClickMenu bt[menu-name="setFollowers"],
        .rightClickMenu bt[menu-name="copylink"],
        .rightClickMenu bt[menu-name="replace"],
        .rightClickMenu bt[menu-name="auditTrail"],
        .rightClickMenu bt[menu-name="secureMessage"],
        .rightClickMenu bt[menu-name="follow"],
        .rightClickMenu bt[menu-name="stopFollow"],
        .rightClickMenu bt[menu-name="deleteAllAnnotations"]
        {
            opacity:0.2;
            pointer-events: none;
        }

        .rightMenu li[state-name="NewMeeting"],
        .rightMenu li[state-name="ManageMeetings"] {
            cursor: not-allowed;
        }

        .rightMenu li[state-name="NewMeeting"]> bt,
        .rightMenu li[state-name="ManageMeetings"] > bt {
            pointer-events: none;
        }
        .rightMenu li[state-name="NewMeeting"] .iconText,
        .rightMenu li[state-name="ManageMeetings"] .iconText {
            opacity:0.2;
        }

        .remove-new-item-button {
            pointer-events: none;
        }

        .remove-new-item-button > svg{
            cursor: not-allowed;
            opacity:0.2;
        }

        .bv-conf-list-event-icon-div {
            pointer-events: none;
        }
        .bv-conf-list-event-icon-div > span > svg{
            cursor: not-allowed;
            opacity:0.2;
        }

        .bv-conf-list-event-icon-div > button{
            pointer-events: none;
        }

        .bv-conf-list-event-start {
            cursor: not-allowed;
            opacity:0.2;
        }

        .bv-conf-list-event-start > button {
            pointer-events:none;
        }

        .bv-conf-details-attandee-response {
            pointer-events: none;
        }
        .bv-conf-details-attandee-response > span {
            cursor: not-allowed;
            opacity:0.2;
        }

        .bv-support-center  {
            cursor: not-allowed;
            opacity:0.2;
        }

        .bv-support-center > a{
            pointer-events: none;
        }

		
        /* Inbox Offline */
        
        .bv-modal-btn-reply, 
        .bv-modal-btn-replyall,
        .bv-modal-btn-forward,       
        {
            opacity:0.2;
            pointer-events: none;
            cursor: not-allowed;
        }
        
        .bv-mobile-compose-message .bv-mobile-menu{
            display: none;
        }

        .bv-messages-mobile .dropdown-menu [ng-repeat="option in inboxMobileMenu"]{
            pointer-events: none;
            cursor: not-allowed;
            opacity:0.2;
        }

        .bv-jup-status
        {
            font-size: 13px;
            display: inline-block;
            position: absolute;
            height: 20px;
            top: 65px;
            right: 20px;
        }

        .bv-jup-sync-status-div .jup-sync-icon-div {
            cursor: not-allowed;
            opacity: 0.2;
        }
    }

    .bv-jup-status
    {
        font-size: 13px;
        display: inline-block;
        position: absolute;
        height: 20px;
        top: 65px;
        right: 20px;
    }

    .jup-offline-mode .bv-jup-status
    {
        font-size: 13px;
        display: inline-block;
        position: absolute;
        height: 20px;
        top: 65px;
        right: 20px;
    }

    .jup-offline-icon {
        display: inline-block; width: 13px; height: 16px;
        position: relative;
        top: 3px;
    }

    .jup-online-icon {
        display: inline-block; width: 13px; height: 16px;
        position: relative;
        top: 1px;
    }
    /* .bv-jup-status .jup-status-icon
    {
        display: inline-block; width: 13px; height: 16px;
        position: relative;
        top: 3px;
    }

    .jup-offline-mode .bv-jup-status .jup-status-icon
    {
        display: inline-block; width: 13px; height: 16px;
        /*background:url("../assets/images/JupOfflineIcon.png") left top no-repeat;
    }*/

    /*.bv-jup-status .jup-offline-span
    {
        display:none;
    }
    .bv-jup-status .jup-online-span
    {
        display:inline-block;
        color:#6f6f6f;
    }

    .jup-offline-mode .bv-jup-status .jup-offline-span
    {
        display:inline-block;
        color:#aeaeae;
    }
    .jup-offline-mode .bv-jup-status .jup-online-span
    {
        display:none;
    }*/

    .jup-offline-span
    {
        display:inline-block;
        color:#aeaeae;
    }

    .jup-online-span
    {
        display:inline-block;
    }


    .bv-jup-sync-status-div
    {
        height: 35px;
        /*width: 250px;
        max-width: 250px;
        position: absolute;*/
        display: inline-block;
        /*border: 1px solid red;*/
        float: left;
        margin-right: 5px;
    }

    .jup-offline-mode .bv-jup-sync-status-div .jup-sync-icon-div {
        cursor: not-allowed;
        opacity: 0.2;
    }

    .bv-jup-sync-status-div .jup-sync-icon-div {
        height: 23px;
        width: 25px;
        /*margin-top: 8px;*/
        margin-left: 20px;
        display: inline-block;
        position: relative;
        top: 8px;
        cursor: pointer;
    }

    .bv-jup-sync-status-div .jup-sync-status-span {
        font-size: 13px;
        /*position:absolute;
        top:9px;
        left:48px;*/
        display: inline-block;
    }

    /*.jup-offline-mode .bvhome .bv-team-space > div > span {
        cursor: not-allowed;
        opacity:0.2;
    }

    .jup-offline-mode .bvhome .bv-team-space > div > span > a {
        pointer-events: none;
    }*/

    /*.bvhome .bv-menu a:hover{
        background:#f4f4f4;
    }*/
    .bvhome .bv-menu li:first-child {
        margin: 20px 0px;
    }

    .bvhome .bv-menu li{
        height: 55px;
        display: table;
        width: 100%;
        margin: 0px 0px 20px 0px;
    }
    .bvhome .bv-menu li a>svg{
        display: inline-block;
        position: relative;
        top: 4px;
        left: -3px;
        width: 35px;
        height: 25px;
    }
    .bvhome .bv-topmenu{
        /* border-bottom:2px solid #515151; */
    /* 	margin: 23px 0px 30px;	 */
        top: 9px;
        position: absolute;
        opacity:1;
        margin-left: 20px;
    }
    .bvhome .bv-topmenu li{
        width: 66px;
        height: 30px;
        display: inline-block;
        text-align: center;
        margin: 3px 8px 0px 0px;
    }
    .bvhome .bv-topmenu li bt{
        font-size: 13px;
        /*font-weight: 400;*/
        display: inline-block;
        height: 100%;
        white-space: nowrap;
    }
    .bvhome .bv-topmenu li bt.active{
        border-bottom: 6px solid;
    }
    .bvhome .bv-topmenu li bt:hover{
    /*	font-weight: 500;
        border-bottom: 6px solid
    */
    }
    .bvhome .bv-setting-menu{
        position: relative;
        top: -28px;
        width: 39%;
        max-width: 500px;
    }
    .bvhome .bv-search-bar{
        position: relative;
        top: 30px;
        white-space: nowrap;
        overflow:hidden;
        background: #E5E5E5;
    }

    .bvhome .bv-search-bar input{
        height: 25px;
        background: transparent;
        border: none;
        width: 90%;
        /* color: #A3A3A3;
        font-weight: 100; */
        font-size: 13px;
        letter-spacing: 1px;
        padding-left:5px;
    }

    .bvhome .bv-search-bar img{
        width: 16px;
        position: absolute;
        right: 9px;
        top: 5px;
    }

    .bvhome .searchModalDialog{
        height:100%;
        margin-top:-80px;
    }

    .searchResultsMain {
        overflow: hidden;
        padding: 20px;
    }

    .bvhome .bv-company-name{
        color: #515151;
        font-size: 1.6em;
        font-weight: 300;
        letter-spacing: 1.7px;
        position: relative;
        top: 3px;
        left: 20px;
    }
    .bvhome .bv-team-space {
        margin-left: 190px;
        margin-right: 530px;
        min-width: 200px;
    }

    .bv-team-space-label:hover {
        cursor:pointer;
    }

    .bvhome .bv-team-space .bv-team-space-dropdown {
        max-height: 400px;
        overflow-x: hidden;
        overflow-y: auto;
        position: fixed;
        top: 50px;
        left: 249px;
        padding: 5px 0px;
    }

    .bvhome .bv-team-space > div,
    .bvhome .bv-team-space-footer{
        position: relative;
        left: 0px;
    /*	color: #515151;
    */	font-size: 22px;
        font-weight: 600;
        letter-spacing: 1.2px;
        display: flex;
        display: -webkit-flex;
        top: 28px;
    }
    .bvhome .bv-team-space > div > div{
        max-width: 542px;
    /* 	min-width: 200px; */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        float: left;
        color: #6f6f6f;
    }

    .bvhome .bv-team-space-footer>div{
        display: inline;
    }
    .bvhome .bv-team-space .dropdown>bt, .bvhome .bv-team-space-footer .dropdown>bt{
        display: inline-block;
        margin-left: 10px;
        position: relative;
        top: -2px;
    }
    .bvhome .bv-team-space bt svg, .bvhome .bv-team-space-footer bt svg{
        width: 18px;
        height: 12px;
    }
    .bvhome .nav-tabs {
        position: absolute;
        border: 0;
        top: -23px;
        left:0px;
        display: block;
    }
    .nav > li > bt{
        position: relative;
        display: block;
        padding: 10px 15px;
    }
    .nav-tabs > li > bt{
        cursor:pointer;
        margin:0;
        border: 0;
        height: 23px;
        border-radius: 0px;
        line-height: 7px;
        color: #515151;
        font-size: 13px;
    }
    .nav-tabs > li.active > bt,
    .nav-tabs > li.active > bt:hover,
    .nav-tabs > li.active > bt:focus {
        color: #fff;
        background: #036EAA;
        border: 0;
        /* font-weight: bold; */
        /*font-size: 13px;*/
    }
    .bvhome .bv-title{
        height: 45px;
    /* 	background: #036EAA; */
        z-index: 2;
        position: relative;
    }
    .bvhome .bv-middle-bar{
        margin-top: -45px;
    }
    .bvhome .bv-title>div>div>span{
        position: absolute;
        width:100%;
        font-size: 22px;
        letter-spacing: 1.5px;
        font-weight: 500;
        padding: 8px 15px 0px 15px;
        float: left;
        display: inline;
        /* color: #fff; */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 330px);
    }
    .bvTitleExtended{
        height: 90px !important;
        padding: 33px 10px 0px !important;
    }
    .bvhome .tab-content{
        display:none;
        position:absolute;
    }
    .bvhome .settingsMenu{
        clear: right;
        float: right;
        margin: 25px 5px 25px 20px;
        font-size: 13px;
    }
    .bvhome .settingsMenu span{
        color:#6f6f6f;
    /* 	font-size: 11px; */
    }
    .bvhome .settingsMenu li{
        margin: 10px 15px;
        display: block;
        float: left;
    }
    .bvhome .settingsMenu bt{
        display: block;
    }
    .bvhome .settingsMenu bt .icon{
        margin:0 auto;
        height: 22px;
        width: 22px;
        display: block;
    }
    .bvhome .settingsBtnIcon{
        fill: #515151;
    }
    /* .bvhome .bv-content content>div{
        display: table-caption;
    } */
    .bvhome .rightMenu{
        position: absolute;
        right: 5px;
        top: 18px;
        right: 20px;
        top: 4px;
        line-height: 45px;
        height: 40px;
    }
    .bvhome .rightMenuExtended{
        position: absolute;
        right: 5px;
        top: 18px;
    }
    .bvhome .rightMenu>ul{
        max-height: 40px
    }

    .bvhome .rightMenu>ul>li{
        display: inline;
        margin: 0px 0px 0px 40px;
    }
    .bvhome .rightMenu svg{
        width: 20px;
        height: 20px;
        display: inline;
        padding-top:3px;
    }
    .bvhome .rightMenu li bt .iconText{
        color:#fff;
        font-size: 13px;
        font-weight: 400;
        position: relative;
        top: -4px;
    }

    .bvhome .rightMenu .dropdown-menu{
        border-radius:0px !important;
        top: 15px;
        left: -119px;
    }
    .rightMenuExtended{
        top: 56px !important;
    }

    .bvhome .dropdown-menu{
        left:9px;
        min-width: 185px;
        top: 36px;
        border-radius: 0px;
    }
    .bvhome footer .dropdown-menu{
        left: -112px;
        min-width: 131px;
        top: inherit;
        bottom:25px;
    }

    .dropdown-menu>li>bt {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: 400;
        line-height: 1.42857143;
        color: #333;
        white-space: nowrap;
    }
    .bvhome .bv-team-space .dropdown-menu > li > bt{
        text-overflow: ellipsis;
        overflow: hidden;
        width: 200px;
    }
    .dropdown-menu>li>bt:focus, .dropdown-menu>li>bt:hover {
        color: #262626;
        text-decoration: none;
        background-color: #f5f5f5;
    }

    .ngdialog .bv-dialog-title{
        font-size: 1.5em;
        font-weight: bold;
        margin: 0px;
        padding: 0;
        color: #515151;
        margin-bottom: 30px;
    }
    .ngdialog ul{
        margin:0;
        padding:0;
    }
    .ngdialog ul li{
        margin:inherit;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .ngdialog title{
        display: inline-block;
        width: 110px;
        text-align: right;
    }
    .ngdialog main,
    .ngdialog guests,
    .ngdialog attachments,
    .ngdialog response,
    .ngdialog buttons{
        display: block;
        padding: 5px 0px;
        font-weight: 100;
    }
    .ngdialog attachments title{
        vertical-align:top;
        padding: 5px 0px;
    }
    .ngdialog main span,
    .ngdialog guests ul,
    .ngdialog response span,
    .ngdialog attachments ul{
        margin: 0px 0px 0px 20px;
    }
    .ngdialog attachments li>span,
    .ngdialog guests li span{
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .ngdialog attachments,
    .ngdialog guests{
        max-height: 300px;
        overflow-y: auto;
    }

    .ngdialog attachments li{
        margin: 10px 0px;
    }
    .ngdialog attachments li>div{
        display: inline-block;
        height: 39px;
    }
    .ngdialog attachments li img{
        width: 30px;
    }
    .ngdialog attachments li>span{
        position: relative;
        left: 10px;
        margin-right: 10px;
        vertical-align: top;
    }
    .ngdialog buttons{
        position: relative;
        height: 40px;
        text-align:right;
    }
    .ngdialog buttons .bv-dialog-button{
        /* position: absolute; */
        border: 0px solid;
        /* background: #036EAA; */
        height: 30px;
        width: 100px;
        /* color: #fff; */
        right: 0;
    }
    .ngdialog response img{
        margin: 0px 5px;
    }
    .ngdialog .eventsListDialog ul{
        width:100%;
        margin-bottom: 20px;
        padding: 0px 20px;
        max-height: 300px;
        overflow-y: auto;
        display: block;
    }
    .ngdialog .eventsListDialog li{
        border-bottom: 1px solid #515151;
        line-height: 35px;
    }
    .ngdialog .eventsListDialog li:last-child{
        border:none;
    }
    .ngdialog .eventsListDialog li:hover{
        background: rgba(0,0,0,0.04);
        cursor:pointer;
    }
    .ngdialog .eventsListDialog div{
        float: right;
        margin-top: -2px;
    }
    .ngdialog .eventsListDialog div>span{
        font-size: 0.65em;
    }

    .bv-textFit
    {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .bv-module-setting-btn{
        float: left;
        height: 35px;
        width: 35px;
        border: 0;
        color: #fff;
        cursor: pointer;
        position: relative;
        margin: 0 2px 0 0;
    /*	background: #707070;*/
        border-radius: 4px;
    }

    .bvhome .bv-module-setting-menu {
        height: 25px;
        width:100px;
        display: table-cell;
        /*vertical-align: middle;*/
        padding: 0px 0px;
        white-space: nowrap;
        /*color: #515151;
        font-size: 1.3em;
        letter-spacing: 0.5px;
        font-weight: 600;*/
    }

    .bvhome .bv-module-setting-menu a:hover{
        cursor: pointer;
        /*background:#d1d1d1;*/
    }
    .bvhome .bv-module-setting-menu li{
        height: 25px;
        display: table;
        width: 100%;
        margin: 5px 0px;
    }
    .bvhome .bv-module-setting-menu li a div{
        display: inline-block;
        position: relative;
        top: 4px;
        left: -3px;
        width: 35px;
        height: 25px;
    }
    .bv-modules-settings-title
    {
        position: absolute;
        top: 14px;
        left: 25px
    }
    .bv-module-main-div { height: calc(100% - 70px);margin-top:8px;width:100% }

    .bv-module-div0 { height:53px;width:100%; color:#000000; display:inline-block; }
    .bv-module-div1 { height:calc(100% - 109px);width:100%; display:inline-block; }
    .bv-module-div2 {
        position: absolute;
        right: 20px;
        bottom: 20px;
    }

    .bv-module-div0-header1      { height:20px;display:inline-block; width:100%;}
    .bv-module-div0-header1-div1 { float:left;display:inline-block;margin-left:290px;text-align:center;}
    .bv-module-div0-header1-div2 { float:right;display:inline-block;text-align:right;margin-right:61px;	}
    .bv-module-div0-header2      { height:15px;width:100%;margin-left:230px;}

    .bv-module-div0-header2 .bv-module-row-visible-all-div{
        width: 68px;
        margin:0;
    }
    .bv-module-div0-header2 .bv-module-row-visible-admin-div{
        width: 159px;
        margin:0;
    }
    .bv-module-div0-header2 .bv-module-row-landing-browser-div{
        width: 100px;
        margin:0;
    }
    .bv-module-div0-header2 .bv-module-row-landing-app-div {
        width: 60px;
        margin:0;
    }

    .bv-module-ul {
        height:100%;width:100%;
    }

    .moduleSortingHandle {
        position: relative;
        float: left;
        display: inline-block;
        top: 4px;
        padding-right: 17px;
        padding-left: 0px;
    }

    .bv-module-row:hover .drag-handle-image {
        display: block;
    }

    .bv-module-row {
        height: 65px;
        width: 100%;
        display: inline-block;
        background-color: #F4F4F4;
        padding: 18px 0 0 11px;
        margin-bottom: 15px;
        position: relative;
    }

    .bv-module-row-icon-div {
        width: 35px;
        height: 31px;
        float: left;
        margin: 0px 15px 0 0;
        display: inline-block;

        > svg {
            width: 100%;
            height: 100%;
        }
    }

    .bv-modules-list {
        width: 103%;
    }

    .bv-module-icon-calendar {
        margin-top: 2px;
    }

    .bv-module-row-check-all-icon-div {
        height:35px; width:35px; padding-top: 5px;
    }
    .bv-module-row-check-admin-icon-div {
        height:35px; width:35px; padding-top: 5px; margin-left: 72px;
    }
    .bv-module-row-option-broswer-icon-div {
        height:35px;
        width:35px;
        padding-left: 90px;
        padding-top: 5px;
    }
    .bv-module-row-option-app-icon-div {
        height:35px;
        width:35px;
        padding-left: 55px;
        padding-top: 5px;
    }

    .bv-module-row-name-div{
        width: 156px;
        height: 30px;
        float: left;
        display: inline-block;
        color: #515151;
        font-size: 20px;
        margin-right: 20px;
    }
    .bv-module-row-visible-all-div{
        width:30px;
        height:30px;
        float:left;
        display:inline-block;
        text-align:center;
        align:center;
    }
    .bv-module-row-visible-admin-div{
        max-width:160px;
        height:30px;
        float:left;
        margin-left:10px;
        text-align:center;
        align:center;
    }
    .bv-module-row-landing-browser-div{
        height:30px;
        float:left;
        margin-left: 9px;
        display:inline-block;
        text-align:center;
        align:center;
    }
    .bv-module-row-landing-app-div{
        height:30px;float:left;margin-left:23px;display:inline-block;
        text-align:center;align:center;
    }

    .bv-module-row-icon-td {
        width:30px;
    }

    /*.bv-module-row-icon-div {
        margin-top:4px;height:30px;width:30px;
    }*/

    .bv-module-row-checkbox-td {
        width:405px;font-size:12px;font-weight:normal;color: #515151;
    }

    .bv-module-row-handle-td {
        width:25px;
    }

    .bv-module-row-browser-column {
        border-radius:3px;float:left;
        background-color:#E6E6E6;
        padding-top:4px;padding-bottom:5px;padding-left:5px;padding-right:5px;
        height:28px;width:220px;
    }

    .bv-module-row-jup-column {
        border-radius:3px;float:right;
        background-color:#E6E6E6;
        padding-top:4px;padding-bottom:5px;padding-left:5px;padding-right:5px;
        height:28px;width:178px;
    }

    .bv-module-3-line-div {
        width: 8px;
        height: 8px;
        background: url("../assets/images/3lines.png");
        /*cursor: pointer;*/
        cursor: move;
        margin-right: 5px;
    }

    #drag-drop-coveredup
    {
        width: 260px;
        height: 40px;
        position: absolute;
        background-color: rgba(0,0,0,0.04);
        text-overflow: clip;
        overflow: hidden;
        font-size: 18px;
        padding: 6px 10px 10px 10px;
        color: #333;
    }

    .bv-support{
        .bv-modal-body > div{

            div{
                min-height: 22px;

                > ul{
                    margin-left: 70px;
                }
            }
        }
    }

    .bv-support-font
    {
    /* 	font-weight: 500;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
        padding-right: 5px;
    }
    .bv-support-underline {
        color: #333;
    }
    .bv-support-underline:link {
        text-decoration: underline;
    }

    .bv-support-underline:visited {
        text-decoration: underline;
    }

    .bv-support-underline:hover {
        text-decoration: underline;
    }

    .bv-support-underline:active {
        text-decoration: underline;
    }

    .supportEmailStyle{
        word-wrap :break-word;
    }

    .titleIcon,
    .titleIconMobile{
        width: 25px;
        height: 25px;
        float: left;
        position: relative;
        top: 5px;
        cursor: pointer;
    }
    .titleIconMobile{
        display: none;
    }

    .backIcon
    {
        width: 25px;
        height: 25px;
        float: left;
        top: 9px;
        left: 5px;
        position: relative;
    }

    .bv-signatures ul li{
        height:125px !important;
    }
    .searchResultsDialog .modal-dialog {
        min-width: 700px;
    /*	overflow: hidden;*/
    }

    .searchInputBox {
        width: 100%;
        margin-bottom: 20px;
    }

    .searchInputText {
    /*	padding: 0px 5px 0px 5px;
        border: 1px solid #EEE;*/
        width: 541px;
    /*	background: #EEE;
        line-height: 30px;
        font-size: 16px;*/
    }

    .item-list-table {
        height: 350px;
        margin: 0 0 10px 0;
        overflow-y: hidden;
        overflow-x: hidden;
        font-size: 16px;
        /*padding: 0 0px 0 15px;*/
    }

    .item-list-table:hover {
        overflow: auto;
    }

    .item-list-table table {
        width: 100%;
        table-layout: fixed;
    }

    .item-list-table tr:first-child {
        border-bottom: none !important;
    }

    .item-list-table tr {
        height: 40px;
        cursor: pointer;
        border-bottom: 1px solid #bbbbbb;
    }
    .item-list-table td {
        padding-right: 10px;
    }

    .item-list-table .column1 {
        width: 20%;
    }

    .item-list-table .column2 {
        width: 52%;
    }

    .item-list-table .column3 {
        width: 28%;
    }

    .item-list-table .column4 {

    }

    .item-list-table .column5 {

    }

    /*.searchResults tr:nth-child(even) {
        background: #EEE;
    }*/

    .bv-transclude-content{
    /* 	overflow-y: auto; */
    /* overflow: hidden; */
        position:absolute;
        background:#fff;
        /* display:inline-block; */
    }
    .bv-transclude-content:hover{
        overflow-y: auto;
    }
    .bvhome .progress{
        width: 100%;
        margin: 0;
        position: absolute;
        left: 0;
        right: 0;
        display: none;
        z-index: 1;
        height: 5px;
        top:45px;
    }
    .bvhome .progress .progress-bar{
        background-color: #E00F0F;
    }
    .bvhome .progress .progress-bar span{
        position: absolute;
        left:45%;
        color: rgb(5, 255, 246);
    }

    .bv-show-right-sidebar{
        display:block;
    }
    .expandMenu{
        width: 30px;
        height: 30px;
        position: absolute;
        right: 16px;
        top: 9px;
        cursor: pointer;
    }
    .expandMenuExtended{
        top: 33px;
    }
    .expandMenuForModuleSettings{
        width:30px;
        height:30px;
        position:absolute;
        left:9px;
        top:17px;
        cursor:pointer;
    }
    .expandMenuRight{
        width: 30px;
        height: 30px;
        position: absolute;
        left: 15px;
        top: 16px;
        cursor:pointer;
    }

    .badge1 {
       position:relative;
    }
    .badgeN {
       margin-left: 45px;
       top: 17px;
       position: absolute;
    }
    .badgeNExtended{
        top: 33px;
    }

    .badgeN[data-badge]:after {
       content:attr(data-badge);
       position:absolute;
       top:-10px;
       left:-11px;
       font-size:.7em;
       background:red;
       color:white;
       width:18px;height:18px;
       text-align:center;
       line-height:18px;
       border-radius:50%;
       box-shadow:0 0 1px #333;
    }

    .badge1[data-badge]:after {
       content:attr(data-badge);
       position:absolute;
       top:-10px;
       left:-11px;
       font-size:.5em;
       background:red;
       color:white;
       width:18px;height:18px;
       text-align:center;
       line-height:18px;
       border-radius:50%;
       box-shadow:0 0 1px #333;
    }

    .selected{
        background:#d1d1d1;
    }

    .bv-section-nav-item-div-active {
        background-color: #FFF;
        padding: 15px;
        height: 45px;
        width: 100%;
        text-decoration: none;
    }

    .modulesButton,
    .usersButton,
    .groupsButton
    {
        position: relative;
        height: 35px;
        width: 35px;
    /* 	background-color: #707070; */
        color: #fff;
        font-size: 25px;
        text-align: center;
        cursor: pointer;
        display: inline-block;
        margin: 13px 0px 0px 5px;
        left: 5px;
    }

    .moduleSettingsButton{
        position: relative;
        height: 27px;
        width: 27px;
        color: #fff;
        font-size: 25px;
        text-align: center;
        cursor: pointer;
        display: inline-block;
        margin-right: 67px;
        margin-top: 13px;
        float: right;
    }

    .moduleBarButton {
        height: 35px;
        width: 27px;
        cursor: pointer;
        display: inline-block;
        top: 13px;
        /*position: relative;
        margin: 0px 7px;*/
    }

    /* .modulesButton svg,
    .usersButton svg,
    .groupsButton svg{
        fill: #fff;
    } */
    .modulesButtonExtended{
        margin: 29px 0px 0px 5px;
    }
    .usersButtonExtended{
        margin: 29px 0px 0px 5px;
    }
    .groupsButtonExtended{
        margin: 29px 0px 0px 5px;
    }
    .usersButton svg,
    .groupsButton svg,
    .modulesButton svg
    {
        width: 30px;
        height: 30px;
        top: 4px;
        position: relative;
    }

    .animate-show{
        opacity:1;
    }
    .animate-show.ng-hide{
        opacity:0;
    }
    .bvTabExtended .nav{
        z-index: 5;
        top: 0px;
        /* box-shadow: 0px 0px 19px -4px #000 inset; */
        height:30px;
    }
    .bvTabExtended .nav-tabs > li > a{
        height:30px;
        line-height: 15px;
    }

    .bvTabExtendedLeft .nav{
        left:0px !important;
    }
    .bvContentExtendedScreen{
        height: calc(100% + 85px) !important;
    }
    .bvContentNormalScreen{
        height: 100%;
    }
    .bv-overflow-sliding{
        /* overflow:hidden;
        display:block;
        height: 100%; */
    }
    .showTest{
        display:none;
    }
    .showing{
        position: absolute;
        top: 60px;
        display: block;
    }

    .bv-menu-box{
        height: 45px;
        /*box-shadow: -42px 0px 38px -50px #000 inset;*/
    }
    .extended{
        height:90px;
    }

    .bv-select-teamspace {
        position: absolute;
        left: 30%;
        top: -5px;
    }
    .bv-user-id-title {
        position: absolute;
        bottom: -10px;
        right: 0px
    }

    /* bv item list */

    /* .bv-item-list-dialog  .modal-dialog{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) !important;
        -webkit-transform: translate(-50%,-50%) !important;
        -ms-transform:  translate(-50%,-50%) !important;

        margin: 0;
        height:5px;
        max-height:150px;
        max-width:350px;
    }
     */
    .bv-alert-item-list-dialog  .modal-dialog {
       position: absolute;
        top: 50%;
        left: 50%;
        /*transform: translate(-50%,-50%) !important;
        -webkit-transform: translate(-50%,-50%) !important;
        -ms-transform:  translate(-50%,-50%) !important;
        */
        margin: 0;
        max-width:350px;
        height:500px;
     }

    .bv-alert-item-list-dialog  .modal-dialog .bv-item-list-main > div {
        height: 480px;
        width: 100%;
     }

    .bv-item-list-main {
        padding: 0px 20px 20px 20px;
        /*padding: 20px;*/
        font-size: 16px;
        /*padding-top: 15px;*/
    }

    .bv-item-list {
        margin-bottom: 10px;
        margin-right: 10px;
    }


    .bv-item-list-action-menu {
        text-align: center;
        font-size: 16px;
        height: 40px;
        display: table;
        width: 100%;
        margin: auto;
    }

    .bv-item-list-action-menu li {
        display: inline;
        float: left;
        height: 30px;
        width: 50%;
        text-align: center;
        margin-top: 10px;
    }

    .bv-item-list-action-menu li a {
        display: inline-block;
        height: 30px;
        text-align: center;
        color: black;
    }

    .bv-item-list-action-menu li a:hover {
        cursor: pointer;
    }

    .bv-item-list-action-menu-selected {
        border-bottom: 4px solid;
        opacity: 1;
        cursor: pointer;
    }

    .bv-item-list-item {
        max-height: 40px;
        overflow: hidden;
    }

    .bv-item-list-grid {
        height: 350px;
        overflow-x: hidden;
        overflow-y: hidden;
        padding-right: 10px;
        margin-top: 10px;
        font-size: 16px;
        line-height: 40px;
    }

    .bv-item-list-grid:hover {
        overflow-y: auto;
    }

    .bv-item-type-header {
        font-size: 16px;
        font-weight: 500;
        /*border-bottom: 1px solid #808080;*/
    }

    .bv-item-list-ul1 {
        height: 350px;
        position: absolute;
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
        width: calc(100% - 40px);
    }

    .bv-item-list-ul1-li
    {
        line-height:32px;
        display:block; width:100%;
    }

    /*.bv-item-list-ul1:hover {overflow-y:auto;}*/
    /*.bv-item-list-ul1 > li { display:block; width:100%; line-height:30px;}*/

    .bv-item-list-ul1 .bv-item-list-ul2 {height: 40px; line-height: 40px;}
    .bv-item-list-ul1 .bv-item-list-ul2 > li { display:block; line-height:40px; height:40px;}

    .bv-item-list-ul2-li-name-heading-span1 {display:inline;}
    .bv-item-list-ul2-li-name-heading-span2 {display:inline;position:absolute; left: 170px;}

    .bv-item-list-ul1 .bv-item-list-ul2-li-name         { line-height:40px; height:40px !important; display:block; position:absolute; width: calc(100% - 45px) !important; }
    .bv-item-list-ul1 .bv-item-list-ul2-li-name > div   { width: 100%; text-overflow:ellipsis; overflow:hidden; white-space:nowrap;}
    .bv-item-list-ul1 .bv-item-list-ul2-li-checkbox     {
        line-height:40px;
        height:40px !important;
        display:block;
        position:relative;
        width:50px !important;
        /*left:260px !important; */
        float: right;
    }
    .bv-item-list-ul1 .bv-item-list-ul2-li-checkbox-div {
        line-height: 22px;
        height: 22px;
        width:22px;
        float:right;
        /*margin-right:20px; */
        padding-top:10px; }

    .bv-item-list-name {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 92%;
    }

    .bv-item-list-check-div {
        display: inline-block;
        float: right;
        margin: 10px 0px 0px 0;
    }

    .bv-item-checkbox input[type="checkbox"] {
        display:none;
    }
    .bv-item-checkbox input[type="checkbox"] + label {
        display:inline-block;
        width:27px;
        height:27px;
        margin:-1px 4px 0 0;
        vertical-align:middle;
        background:url("../assets/images/checkbox.png") left top no-repeat;
        cursor:pointer;
    }
    .bv-item-checkbox input[type="checkbox"]:checked + label {
        margin: 3px 1px 0 0;
        background:url("../assets/images/checkbox_on.png") left top no-repeat;
    }

    .bv-item-small-close-svg {
        display:inline-block;
        width:27px;
        height:27px;
        margin:-1px 4px 0 0;
        vertical-align:middle;
        cursor:pointer;
        fill:#000000;
        position:relative;
    }

    .bv-item-small-close-svg > svg {
        fill: #000 !important;
    }

    .bv-item-small-close-svg{
        opacity: 0.5;
    }
    .bv-item-small-close-svg:hover{
        opacity: 1;
    }

    .bv-modal-btn-delete{
        width: 20px;
        height: 20px;
        fill: #000000;
        position: absolute;
        top: 8px;
        cursor: pointer;
        visibility:hidden;
    }
    .bv-modal-btn-delete:hover
    {
        fill: #000000 !important;
        visibility:visible;
    }

    .jup-desktop-client .bv-active-windows{
        background: rgba(0,0,0,0.3) repeat scroll 0 0;
        border: 2px solid rgba(0,0,0,0.4);
        display: none;
        max-width: 157px;
        height: 33px;
        position: relative !important;
        left: inherit !important;
    }

    .bv-active-windows{
        background: rgba(0,0,0,0.3) repeat scroll 0 0;
        border: 2px solid rgba(0,0,0,0.4);
        display: none;
        position: absolute;
        left: 250px;
        max-width: 157px;
        width: 157px;
        height: 35px;
    }

    .bv-active-windows > span {
        color: #FFFFFF;
        height: 35px;
        padding: 7px 7px 0 7px;
        position: relative;
        top: 3px;
        font-size: 13px;
    }

    .bv-active-windows .bv-document-title {
        max-width: 110px;
        min-width: 30px;
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        text-align: center;
        vertical-align: -3px;
        outline: 0 none;
        cursor: default;
    }

    .bv-active-windows > div {
        background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
        border: medium none;
        color: #ffffff;
        height: 23px;
        width:	23px;
        outline: 0 none;
        margin-left: 10px;
    }

    .active-windows-remove{
        background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
        border: medium none;
        height: 23px;
        width:	23px;
        outline: 0 none;
        margin-left: 73px;
    }

    .bv-active-windows-list{
        margin: 20px;
    }

    .bv-active-windows-list .bv-scrollbar {
        height: 145px !important;
    }

    .bv-active-window-item{
        height: 32px !important;
        margin-top: 4px;
    }
    .bv-active-window-item:hover .bv-modal-btn-delete
    {
        fill:#CCCCCC;
        visibility:visible;
    }


    .bv-modal-btn-minimize{
        width: 23px;
        height: 23px;
        fill: #fff;
        right: 6px;
        position: absolute;
        top: 22px;
        cursor: pointer;
    }

    .bv-active-windows .bv-modal-btn-close {
        top: 8px;
        right: 0;
        width: 23px;
        height: 23px;
    }

    /* #bvModuleMenuDiv > ul{
        width: 250px;
    }
     */
     .bvhome .middleBarIconSvg{
        display: none;
    }
    .bvhome .bv-mobile-menu{
        display: none;
    }
    .bv-menu-box .bv-mobile-teamspace{
        display: none;
    }

    /*****       ******/

    .challange-questions .bv-dropdown .dropdown-menu{
        width: 100%;
        overflow-x: auto;
    }

    .challange-questions .bv-dropdown .dropdown-menu li {
        display: table-row-group;
    }

    .challange-questions .bv-dropdown > .btn {
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 25px;
        border: none;
    }
    .bv-last-sync{
        padding: 6px 20px 5px;
        border-bottom: 1px solid #eee;
        color: #ccc;
        font-size: 9px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 203px;
    }

    .connectedIconClass,
    .notAvailableIconClass,
    .goOfflineIconClass{
        fill: #bfbfbf;
    }
    .connectedIconClass.active,
    .notAvailableIconClass.active{
        fill: #2273a5;
    }
    .offlineSearchDisabledBtn{
        cursor: not-allowed !important;
        opacity: 0.2;
    }

    .bv-trash-content > .bv-scroll-list,
    .bv-trash-content > .bv-scroll-list > .scroll-list-items{
        height: 100%;
        min-height: 100%;
    }
    .bv-trash-content .bv-input-btn{
        float: right;
        margin-right: 20px;
        display: none;
    }

    .bv-trash-content .bv-scroll-list > ul > li,
    .bv-trash-content .bv-scroll-list > .scroll-list-items .scroll-list > li > ul > li{
        line-height: inherit;
        padding-left: 15px;
        font-size: 14px;
    }
    .bv-trash-content .bv-scroll-list > ul{
        height: 28px;
        background: #eee;
        margin-right: 0px;
    }
    .bv-trash-content .bv-scroll-list > ul > li{
        font-weight: inherit;
        height: 28px;
        padding-top: 5px;
        font-size: 12px;
        font-weight: 500;
    }

    .bv-trash-content .bv-scroll-list > .scroll-list-items .scroll-list > li{
        border-bottom: inherit;
        cursor:default;
    }
    .bv-trash-content .bv-scroll-list > .scroll-list-items .scroll-list > li:nth-child(even){
        background: rgb(247, 249, 250);
    }

    .bv-trash-content .bv-scroll-list > .scroll-list-items .scroll-list > li.checked{
        background: #dbdde1;
    }

    .bv-trash-content .bv-scroll-list > .scroll-list-items .scroll-list > li:hover{
        background: #ebeef0;
    }
    .bv-trash-content .bv-scroll-list > ul{
        display: none;
    }
    .bv-trash-content .bv-scroll-list > ul > li:hover{
        background: #ddd;
    }
    .bv-trash-content .bv-scroll-list > ul > li:last-child:hover{
        background: inherit;
    }
    .bv-trash-content .bv-scroll-list > ul > li:hover .sort-direction{
        display: block;
    }
    .bv-trash-content .bv-scroll-list > ul > li:last-child{
        text-align: right;
        padding-right: 40px;
    }
    /* .bv-trash-content .bv-scroll-list > ul > li.active{
        background: #ddd;
    } */
    .bv-trash-content .bv-scroll-list > .scroll-list-items .scroll-list > li > ul > li{
        margin-top: 9px;
    }
    .bv-trash-content .bv-scroll-list > .scroll-list-items{
        padding-right: 0px;
    }
    .bv-trash-content .bv-scroll-list > ul > li .sort-direction{
        width: 12px;
        height: 12px;
        stroke: #000;
        float: right;
        top: 9px;
        right: 5px;
        position: absolute;
        display: none;
    }

    .bv-trash-content .bv-scroll-list > ul > li .sort-direction.reverse{
        transform: rotateZ(180deg);
        -webkit-transform: rotateZ(180deg);
    }
    .bv-trash-content .bv-scroll-list > ul > li.active .sort-direction{
        display: block;
    }
    .bv-trash-content .mCSB_outside+.mCSB_scrollTools{
        right: 0;
    }

    .bv-trash-content .bv-svg-check-false,
    .bv-trash-content .bv-svg-check-true{
        float: right;
        top: 2px;
        position: relative;
        right: 15px;
    }

    .bv-trash-content .bv-scroll-list > ul .bv-scroll-col .bv-svg-check-false,
    .bv-trash-content .bv-scroll-list > ul .bv-scroll-col .bv-svg-check-true{
        position: absolute;
        top: 6px;
    }


    .bv-restoring-progress .modal-dialog{
        width: 450px;
    }
    .bv-restoring-progress .bv-input-btn-box button:last-child{
        display: none;
    }

    .bv-restoring-progress .bv-modal-body > div > div > span{
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }
    .bv-restoring-progress .progress{
        height: 24px;
        border-radius: 0;
        box-shadow: inherit;
        background: #fff;
        border: 1px solid #ddd;
        margin: 5px 0 19px;
    }
    .bv-restoring-progress .progress:last-child{
        margin: 0;
    }
    .bv-restoring-progress .progress-bar{
        line-height: 23px;
        transition: width .2s ease;
        -webkit-transition: width .2s ease;
    }
    .bv-confirm.bv-create-user-dialog .bv-confirm-content{
        white-space: pre-line;
    }
    .bv-badge-three-digits:after{
        /* font-size: 17px; */
        width: 26px !important;
        border-radius: 9px !important;
    }
    .bv-badge-four-digits:after{
        /* font-size: 17px; */
        width: 30px !important;
        border-radius: 9px !important;
    }
    .team-space-box{
        display: none;
    }
    /* home.css */

    /* common.css */
    b{
    	font-weight: 500 !important;
    }
    ul{
    	list-style: none;
    	margin:0;
    	padding:0;
    }
    a,
    a:link,
    a:hover,
    a:active,
    a:visited{
    	/* color: #fff; */
    	text-decoration:none;
    	outline: none;
    }
    bt{
    	cursor: pointer;
    }

    svg{
      display: block;
    /*   width:100%;
      height:100%; */
    }

    input{
    	outline: none;
    }

    input[type=password]::-ms-reveal,
    input[type=password]::-ms-clear
    {
        display: none;
    }

    textarea {
    	outline: none;
    }

    img {
        -webkit-user-drag: none;
    }

    .noselect{
    	-webkit-touch-callout: none;
    	-webkit-user-select: none;
    	-khtml-user-select: none;
    	-moz-user-select: none;
    	-ms-user-select: none;
    	user-select: none;
    }

    .modal{
    	overflow: hidden !important;
    }
    .modal-content{
    	border: inherit;
    	box-shadow: inherit;
    	border-radius: inherit;
    	-webkit-box-shadow: inherit;
        box-shadow: inherit;
    }
    .modal-dialog{
    	-webkit-box-shadow: 0 5px 15px rgba(0,0,0,.5);
        box-shadow: 0 5px 15px rgba(0,0,0,.5);
    }
    /* BV styles */
    bv-table{
    	display:table;
    }
    bv-row{
    	display: table-row;
    }
    bv-cell{
    	display: table-cell;
    }
    .bv-height-100{
    	height:100%;
    /* 	min-height:100%;	 */
    }
    .bv-width-100{
    	width:100%;
    }
    .bv-width-10{
    	width: 10%;
    }
    .bv-width-20{
    	width: 20%;
    }
    .bv-width-30{
    	width: 30%;
    }
    .bv-width-50{
    	width: 50%;
    }
    .bv-width-60{
    	width: 60%;
    }
    .bv-width-70{
    	width: 70%;
    }
    .bv-bottom-10{
    	margin-bottom:10px;
    }
    .bv-bottom-20{
    	margin-bottom:20px;
    }
    .bv-top-10{
    	margin-top:10px;
    }
    .bv-padding-15{
    	padding:15px;
    }
    .bv-padding-20{
    	padding:20px;
    }
    .bv-padding-5{
    	padding:5px;
    }
    .bv-margin-left-30{
    	margin-left: 30px;
    }
    .bv-svg-20{
    	width: 22px;
    	height: 20px;
    }
    .bv-svg-16{
    	width: 18px;
    	height: 16px;
    }
    .bv-svg-12{
    	width: 14px;
    	height: 12px;
    }
    .cursor-pointer:hover{
        cursor: pointer;
    }

    .bv-scrollbar{
    	-webkit-overflow-scrolling: touch;
    /* 	-webkit-tap-highlight-color:rgba(0,0,0,0); */
    }
    .bv-scrollbar{
     	overflow:auto;
    }
    .bv-position-relative{
    	position: relative;
    }
    .bv-scrollbar::-webkit-scrollbar{
    	background-color: rgba(214, 214, 214, 0.34);
    	width: 6px;
    	height:6px;
    	border-radius: 10px;
    }
    .bv-scrollbar::-webkit-scrollbar-thumb{
    	width: 6px;
    	height:6px;
    	/*background-color: #D5D5D5;*/
    	background-color: #A5A2A2;
    	border-radius: 10px;
    }
    .bv-scrollbar::-webkit-scrollbar-thumb:hover{
    	background-color:#A5A2A2;
    }
    .bv-overflow-auto{
    	overflow:auto;
    	overflow-x: hidden;
    }
    .bv-overflow-hidden{
    	overflow:hidden;
    }
    .bv-font-weight-400{
    	font-weight: 400;
    }
    .bv-font-weight-500{
    	font-weight: 500;
    }
    .bv-display-block {
    	display: block;
    }
    .bv-display-none {
    	display: none;
    }

    [ng-color-picker] ul {
        padding: 0;
        margin: 0;
        width: 100%;
    	display: table;
    }

    [ng-color-picker] li {
        width:20px;
        height: 30px;
        box-sizing:border-box;
    	display: table-cell;
    }

    [ng-color-picker] li.selected {
        border: 1px solid #333;
    }
    .bv-modal-header {
      min-height: 16.42857143px;
      border-bottom: 1px solid #e5e5e5;
      padding: 5px 15px 5px 15px;
    }

    .bv-modal-header .close {
      margin-top: -2px;
    }
    .bv-modal-title {
     	margin: 2px 0px 0px;
    	color: #fff;
    	font-size: 16px;
    	letter-spacing: 0.6px;
    	width: calc(100% - 20px);
      	overflow: hidden;
      	text-overflow: ellipsis;
      	overflow: hidden;
      	white-space: nowrap;
    }
    .bv-modal-body {
      position: relative;
      height: calc(100% - 35px);
      /* padding: 5px 15px 5px 15px; */
     /*  overflow-y: auto; */
    }

    .bv-modal-footer {
      padding: 5px 15px 5px 15px;
      margin-top: 7px;
      text-align: center;
      border-top: 1px solid #e5e5e5;
    }

    .bv-modal-btn-close{
    	right: 6px;
    	position: absolute;
    	top: 6px;
    	cursor: pointer;
    }

    .bv-modal-btn-close > svg{
    	width: 23px;
    	height: 23px;
    	fill: #fff;
    }


    .bv-modal-btn-back{
        position: absolute;
        float: left;
        top: -4px;
        padding: 17px 90px;
        left: -80px;
    }
    .bv-modal-btn-back  .bv-button-feedback{
        top: 4px;
        z-index: 1;
        left: 6.4px;
    }
    .bv-modal-btn-back > svg{
    	height: 20px;
        width: 20px;
        fill: #fff;
    }

    .bv-modal-header-small{
      min-height: 27px;
      padding: 5px 10px;
    }

    .bv-modal-header-small .bv-modal-btn-close > svg{
    	fill: #000;
    	height: 15px;
    	width: 15px;
    }

    .bv-input-label {
    	font-size: 16px;
    /*	font-weight: 500;*/
    	margin-bottom: 7px;
    }

    .bv-heading-label {
    	font-size: 16px;
    	font-weight: 500;
    }
    .bv-input-btn-small {
    	min-width: 70px !important;
    }

    .bv-background-orange {
    	background-color: #FF8100 !important;
    }

    .bv-background-green {
    	background-color: #22a63e !important;
    }

    .bv-input-btn {
    	min-width: 100px;
    	height: 28px;
    	border: none;
    	margin: 0px 0px 0px 7.5px;
    /* 	font-family: Arial; */
    	font-weight: 500;
    	font-size: 12px;
    	letter-spacing: 0.6px;
    	line-height: 22px;
    	color: #fff;
    	background-color: #000;
    	outline: none !important;
    	white-space: nowrap;
    	overflow: hidden;
    }

    .bv-input-text {
    	padding: 0px 5px 0px 10px;
      	border: #e5e5e5;
      	background: #e5e5e5;
      	height: 26px;
      	letter-spacing: 0.6px;
    }
     .bv-input-text.comments {
      	height: 100%;
    }
    .bv-input-text:focus,
    .bv-input-text:active {
    	-webkit-box-shadow:inset 0px 0px 0px 2px #99b2cc;
        -moz-box-shadow:inset 0px 0px 0px 2px #99b2cc;
        box-shadow:inset 0px 0px 0px 2px #99b2cc;
    }

    .cke_chrome {
    	-moz-box-shadow: none !important;
      	-webkit-box-shadow: none !important;
    	box-shadow: none !important;
    	border: none !important;
    }

    .bv-input-help {
    	white-space: nowrap;
    }

    .bv-input-help-label {
    	white-space: pre-wrap; /* This will take in IE and IE doesn't understand white-space: initial"; */
    	white-space: inherit; /* This will overwrite above one in other than IE */
    	color: #808080;
    	display: inline-block;
    	max-width: 97%;
    	font-size: 14px;
    }

    .bv-input-btn-large{
    	min-width: 120px;
    }

    .bv-input-btn-conf-black-small
    {
    	color:#FFFFFF;
    	min-width:65px;
    	background-color: #000000;
    	border-style:none;
    	max-height: 23.7px;
        min-height: 23.7px;
    }

    .bv-input-btn-conf-stop-sharing
    {
    	color:#FFFFFF;
    	min-width:50px;
    	background-color: #000000;
    	border-style:none;
    	max-height: 17px;
        min-height: 17px;
        margin: 1px 2px 3px 0px;
    }

    .bv-input-btn-conf-make-presenter {
    	min-width: 97px;
    	max-height: 17px;
        min-height: 17px;
    	border: none;
    	margin: 0px 2px;
    }

    /* .bv-input-btn-blue
    {
    	color:#FFFFFF;
    	min-width:125px;
    	background-color: #3596BD;
    	border-style:none;
    	max-height: 45px;
        min-height: 35px;
    } */

    .bv-input-btn-blue:disabled
    {
    	background-color:#D5D5D5;
    }
    /* .bv-input-btn-0{
    	background-color: #000000;
    	color:#FFFFFF;
    } */
    /* .bv-input-btn-black
    {
    	color:#FFFFFF;
    	min-width:125px;
    	background-color: #000000;
    	border-style:none;
    	max-height: 45px;
        min-height: 35px;
    }
     */
    .bv-blue-msg-dialog-body {
        width:415px;
        position: relative;
        background-color: #3596BD;
        text-align: center;
        color: #FFF;
        font-size: 18px;
        font-weight: normal;
        padding: 15px 0px 5px;
    }
    .bv-blue-msg-dialog-content {
        position: relative;
        background-color: #3596BD;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        outline: medium none;
    }
    .bv-blue-msg-dialog-footer {
        width:415px;
        background-color: #3596BD;
        margin-top: 0px;
        text-align: center;
        padding: 5px 0px 15px;
    }

    .bv-modal-window-compose-new-message .modal-dialog {
    	min-width: 1000px;
    	width: 1000px;
    	position: absolute;
    	top: 50%;
    	left: 50%;
    	transform: translate(-50%,-50.1%) !important;
    	-webkit-transform: translate(-50%,-50.1%) !important;
        -ms-transform:  translate(-50%,-50.1%) !important;
    	margin: 0;
    }
    .bv-support .modal-dialog {
        min-width: 390px;
        width: 390px;
    }
    
    .submit-selfordering .modal-dialog {
        min-width: 560px;
        width: 560px;
    }

	.submit-selfordering .bv-modal-body > div{
    	padding: 20px 20px 0px 20px
    }
    .jup-desktop-client .bv-support .modal-dialog {
        min-width: 480px;
        margin: 40px auto;
    }
    .jup-desktop-client .bv-support .resetBtn {
    	text-align:center;
    }

    .bv-modal-window-compose-new-message-recipents .modal-dialog {
        min-width: 450px;
    }
    .bv-modal-window-repository-rules .modal-dialog {
        min-width: 500px;
        max-height: 230px
    }
    .bv-modal-window-repository-rules-userSelection .modal-dialog {
        min-width: 710px;
        max-height: 230px
    }
    .bv-modal-window-repository-rename-selected-item .modal-dialog {
        min-width: 380px;
        max-height: 130px
    }
    .bv-text-align{
    	text-align:center;
    }
    .bv-modal-blue-header {
      min-height: 35px;
      padding: 5px 12px;
    }

    .bv-modal-background{
      background: url('../assets/images/dust.png') repeat scroll 0% 0% transparent;
    }

    /* Context menu */
    .bv-context-menu{
    	display: block;
    	position: fixed;
    	z-index: 1000;
    	float: left;
    	min-width: 160px;
    	padding: 5px 0;
    	margin: 2px 0 0;
    	font-size: 14px;
    	list-style: none;
    	background-color: #fff;
    	background-clip: padding-box;
    	border: 1px solid #ccc;
    	border: 1px solid rgba(0, 0, 0, .15);
    	border-radius: 4px;
    	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    	box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    }

    .bv-context-menu ul{
    	margin: 0px 0px 0px 0px;
    	position: relative;
    	line-height: 30px;
    }

    .bv-context-menu ul li {
    	height: 30px;
    	display: block;
    	border:none;
    	padding: 0px 0px 0px 17px;
    }
    .bv-context-menu ul li a{
    	display:block;
    	color: #515151;
    }
    .bv-context-menu ul li:hover{
    	background: rgba(0,0,0,0.04);
    	cursor: pointer;
    }

    .bv-left-float-checkbox
    {
    	background: url("../assets/images/checkbox.png") no-repeat;
    	min-width: 27px;
    	max-width: 27px;
    	min-height: 27px;
    	max-height: 27px;
     	float: left;
    	margin-top: 10px;
    	margin-left: 4px;
    }

    .bv-left-float-checkbox.checked {
    	background: url("../assets/images/checkbox_on.png") no-repeat;
    	min-width: 27px;
    	max-width: 27px;
    	min-height: 27px;
    	max-height: 27px;
     	float: left;
    	margin-top: 10px;
    	margin-left: 4px;
    }

    .bv-select-user {
    	background-color: white;
    	min-height: 280px;
    	max-height: 280px;
    	overflow-x: hidden;
    	overflow-y:auto;
    }

    .bv-select-user-img {
    	min-width: 35px;
    	max-width: 35px;
    	min-height: 35px;
    	max-height: 35px;
    	margin-top: 4px;
    	float:left;
    }

    .bv-select-user-name {
    	padding-top: 12px;
    	padding-left: 0px;
    	float: left;
    	min-width: 150px;
    	min-height: 35px;
    }

    .bv-select-user-search {
    	min-width:320px;
    	overflow-x: hidden;
    	overflow-y: hidden;
    }

    .bv-select-user-blue
    {
    	color:#FFFFFF;
    	min-width:65px;
    	background-color: #3596BD;
    	border-style:none;
    	max-height: 23.7px;
        min-height: 23.7px;
        border-radius: 4px;
    }

    .bv-cursor-pointer
    {
    	cursor: pointer;
    }

    .bv-cursor-not-allowed
    {
    	cursor: not-allowed;
    }

    .bv-left {
    	float:left;
    }

    .bv-right {
    	float:right;
    }

    .bv-red-color{
    	color:red;
    }
    .bv-green-color{
    	color:green;
    }
    .bv-orange-color{
    	color:orange;
    }

    .bv-input-btn-box{
    	text-align:center;
    	padding: 0px 20px 20px 20px;
    	position: relative;
    	z-index: 1;
    	background: #fff;
    	text-align: right;
    	width: 100%;
    }

    .bv-permissions .modal-dialog{
    	width: 520px;
    	position: absolute;
    	top: 50%;
    	left: 50%;
    	transform: translate(-50%,-50.1%) !important;
    	-webkit-transform: translate(-50%,-50.1%) !important;
        -ms-transform:  translate(-50%,-50.1%) !important;
    	margin: 0;
    }

    .bv-permissions .bv-table-drowing-lines{
    	height: 300px;
      	width: 100%;
    }
    .bv-permissions .bv-table-drowing-lines li{
      height: 100%;
      position: absolute;
      border-right: 1px solid #DFDFDF;
    }
    .bv-permissions .bv-table-drowing-lines li:first-child{
      width: 60px;
    }
    .bv-permissions .bv-table-drowing-lines li:last-child{
    	width: 220px;
    	left: 60px;
    }


    .bv-permissions .bv-item-list-grid{
    	/*height: 315px;*/
    	height: 270px;
    	overflow-y: auto;
    }
    .bv-permissions .bv-item-list-grid .bv-item-list-ul1 { height:305px;}
    .bv-permissions .bv-item-list-grid .bv-item-list-ul1 .bv-item-list-ul2-li-checkbox     { line-height:40px; height:40px !important; display:block; position:absolute; width:50px !important; left:210px !important; }


    .bv-permissions [bv-item-list]{
    	padding: 10px;
    	/*height:inherit;*/
    }

    .bv-permissions .bv-item-list-main{
    	height: calc(100% - 47px);
    	padding: 5px 10px;
    }

    .bv-permissions .bv-item-list-main > div{
    	height: 100%;
    }

    .bv-permissions .bv-item-list-main > div > div:last-child{
        height: 100% !important;
    }

    .bv-permissions .bv-dialog-folder-list {
    	padding: 10px 0px 10px 0px;
    	box-shadow: none;
    }

    .bv-permissions .bv-dialog-folder-list .shadowBox {
    	height: 100%;
        position: absolute;
        right: -7px;
        width: 7px;
        /*border: 1px solid;*/
        z-index: 1;
        top: 0px;
    }

    .bv-permissions .bv-dialog-user-list .shadowBox {
    	height: 100%;
        position: absolute;
        left: -7px;
        width: 7px;
        /*border: 1px solid;*/
        z-index: 1;
        top: 0px;
    }

    .bv-permissions .bv-dialog-user-list .bv-input-btn-box{
    	background: inherit;
    	position: absolute;
    	bottom: 0;
    }
    .bv-permissions .bv-dialog-user-list{
    	padding: 0px;
    	box-shadow: none;
    }
    .bv-permissions .bv-heading-label{
    	font-size: 15px;
    }
    .bv-permissions .bv-item-list-name{
    	font-size: 16px;
    	font-weight: inherit;
    }
    .bv-permissions .bv-tree-node-selected-chooser{
    	background:#dbdde1;
    }
    .bv-permissions .bv-dropdown .btn .caret{
    	top: 5px;
    }
    .bv-permissions .treeNode{
    	height: 49px;
    }
    .bv-permissions .treeNode:hover {
        background: #eaedee;
        cursor: pointer;
    }
    .bv-permissions .folder-attachment-radio{
    	display: none;
    }
    .bv-permissions .treeNode .treeImage{
    	margin-left: 15px;
    }
    .bv-permissions .treeNode .folder-attachment-treeNode-name-div{
    	float: none;
    }
    .bv-permissions [folder-tree-selection]{
    	height: 100%;
        width: 100%;
        overflow: auto;
    }

    .bv-permission-screen{
    	z-index: 2;
    	background: #fff;
    	position: relative;
    	padding: 10px;
    }
    .bv-permission-screen label{
    	font-weight: 500;
    	font-size: 0.9em;
    	margin: 0;
    	display: inline;
    }
    .bv-permission-screen > span{
    	font-size: 16px;
    	font-weight: bold;
    	line-height: 60px;
    	padding-left: 10px;
    	white-space: nowrap;
    	overflow: hidden;
    	text-overflow: ellipsis;
    	width: 100%;
    	display: block;
    }
    .bv-permission-screen .bv-dialog-folders-btn{
    	width: 10px;
    	height: 10px;
    	background: #ccc;
    }
    .bv-permission-screen .bv-search-users{
    	position:relative;
    	float: right;
    	margin-bottom: -5px;
    	margin-top: -10px;
    }
    .bv-permission-screen .bv-search-users img{
    	width: 15px;
    	left: 5px;
    	top: 5px;
    	position: absolute;
    }
    .bv-permission-screen .bv-search-users input{
    	padding-left: 22px;
    }

    .bv-permission-screen bv-cell{
    	padding:0px 5px;
    }
    .bv-permission-screen bv-row{
    	background: #EBEBEB;
    }
    .bv-permission-screen bv-row.title{
    	background: #E0E0E0;
    }
    .bv-permission-screen bv-cell:last-child{
    	width:20px;
    }
    .bv-permission-screen bv-cell:last-child div{
    	cursor:pointer;
    }
    .bv-permission-screen .dropdown-menu{
    	max-height: 310px;
    	overflow-y: auto;
    	overflow-x: hidden;
    	white-space: nowrap;
    	border-radius:0px !important;
    }
    .bv-permission-screen [icon-renderer] img{
    	width:15px;
    }

    .bv-permission-screen .dropdown-menu{
    	padding: 2px 0px;
    }
    .bv-permission-screen .dropdown-menu div[icon-renderer]{
    	display: inline-block;
    	top: -2px;
    	position: relative;
    	height: 15px;
    }
    .bv-permission-screen .bv-dialog-title-box{
    	height: 20px;
    	padding: 0px 5px;
    }

    .bv-permission-screen .bv-dialog-users-icon{
    	width: 20px;
    	height: 20px;
    	float: right;
    	cursor:pointer;
    }

    .bv-permission-screen .bv-dialog-user-btn,
    .bv-permission-screen .bv-dialog-folder-btn{
    	position: relative;
    	cursor: pointer;
    }
    .bv-permission-screen .bv-dialog-folder-btn{
    	float: left;
    	padding-left: 20px;
    }
    .bv-permission-screen .bv-dialog-user-btn{
    	float: right;
    	padding-right: 20px;
    }

    .bv-permission-screen .bv-dialog-user-btn svg,
    .bv-permission-screen .bv-dialog-folder-btn svg{
    	width: 17px;
    	height: 20px;
    	top: 0px;
    	position: absolute;
    	cursor: pointer;
    }

    .bv-permission-screen .bv-dialog-user-btn svg{
    	right: 0;
    }
    .bv-permission-screen .bv-dialog-folder-btn svg{
    	left: 0;
    }

    /* .bv-permission-screen .bv-dialog-users-btn.opened{
    	transform: rotate(180deg);
    } */
    .bv-permission-screen .dropdown-menu span{
    	padding-left: 20px;
    }
    .bv-permission-screen .dropdown-menu li:hover{
    	background: rgba(0,0,0,0.04);
    	cursor: pointer;
    }
    .bv-permission-screen .bv-table-css{
    	font-size: 0.8em;
    	line-height: 21px;
    	position: absolute;
      	top: 0;
      	width: 100%;
    }

    .bv-permission-screen .bv-table-css > ul > li{
    	margin: 0px 10px;
    	height: 27px;
    	border-bottom: 1px solid #DFDFDF;
    	font-size: 15px;
    	font-weight: 500;
    }
    .bv-permission-screen .bv-table-css .bv-table-title-col-1{
    	width: 35px;
    }
    .bv-permission-screen .bv-table-css .bv-table-title-col-2{
    	width: 190px;
      	left: 65px;
    }
    .bv-permission-screen .bv-table-css .bv-table-title-col-3{
    	width: 190px;
      	left: 285px;
    }

    .bv-permission-screen .bv-table-css > ul:first-child{
    	height: 30px;
    /* 	border-top: 1px solid #ccc;
    	border-bottom: 1px solid #ccc; */
    	position: relative;
    }
    .bv-permission-screen .bv-table-css > div > ul{
    	position: relative;
    	padding: 15px 0px;
    }

    .bv-permission-screen .mCSB_container{
    	overflow: inherit;
    }

    .bv-permissions-list {
    	position: relative;
    	padding: 15px 0px;
    }

    .bv-permission-screen .bv-table-css > ul,
    .bv-permission-screen .bv-table-css > div{
    	height: calc(100% - 40px);
    	overflow-y: auto;
    	overflow-x: hidden;
    /* 	border-bottom: 1px solid #ccc; */
    }
    /* .bv-permission-screen .bv-table-css > ul > li{
    	height: 27px;
    } */
    /* .bv-permission-screen .bv-table-css > ul > li:last-child{
    	height: 23px;
    	border-bottom: 1px solid #ccc;
    } */

    .bv-permission-screen .bv-table-css > .bv-scrollbar{
    	width: 97%;
    }

    .bv-permission-screen .bv-permissions-list-container {
    	width: 97%;
    }
    .bv-permission-screen .bv-permissions-list{
    	padding-bottom: 105px;
    }

    .bv-permission-screen .bv-table-css > ul:first-child > li,
    .bv-permission-screen .bv-table-css > div > ul > li > ul > li,
    .bv-permission-screen .bv-permissions-list > li > ul > li {
    /* 	border-left: 1px solid #ccc; */
    	display:block;
    /* 	padding-left: 3px;
    	height: 22px; */
    	position:absolute;
    /* 	background: #F2F2F2; */
    	border-right: inherit;
    	margin: 0px 10px;
    }
    .bv-permission-screen .bv-table-css.bv-table-3-cols div > ul > li > ul > li{
    	background: #F7F6E5;
    }
    .bv-permission-screen .bv-table-css .bv-table-col-2{
    	overflow: hidden;
    	text-overflow: ellipsis;
    	white-space: nowrap;
    }
    .bv-permission-screen .bv-table-css > div > ul > li{
    	height: 42px;
      	font-size: 16px;
    }
    .bv-permission-screen .bv-permissions-list > li {
    	height: 42px;
      	font-size: 16px;
    }


    .bv-permission-screen .bv-table-css > ul:first-child > li:last-child,
    .bv-permission-screen .bv-table-css > div > ul > li > ul > li:last-child{
    /* 	border-right: 1px solid #ccc; */
    }
    .bv-permission-screen .bv-table-css.bv-table-3-cols .bv-table-col-1{
    	width: 39px;
    }
    .bv-permission-screen .bv-table-css.bv-table-3-cols .bv-table-col-2{
    	width: 219px;
    	left: 35px;
    }
    .bv-permission-screen .bv-table-css.bv-table-3-cols .bv-table-col-3{
    	width: 76px;
    	left: 254px;
    }
    .bv-permission-screen .bv-table-css.bv-table-4-cols .bv-table-col-1{
    	width: 35px;
    }
    .bv-permission-screen .bv-table-css.bv-table-4-cols .bv-table-col-2{
    	width: 190px;
    	left: 65px;
    }
    .bv-permission-screen .bv-table-css.bv-table-4-cols .bv-table-col-3{
    	width: 160px;
    	left: 270px;
    }
    .bv-permission-screen .bv-table-css.bv-table-4-cols .bv-table-col-4{
    	width: 18px;
    	left: 460px;
    }

    .bv-permission-screen .bv-table-css div[icon-renderer]{
    	top: 4px;
    	left: 5px;
    	position: relative;
    }
    .bv-permission-screen .bv-table-css select{
    	width: 97%;
    	border: 1px solid #ccc;
    }
    .bv-permission-screen .bv-table-css select.empty{
    	border: 1px solid red;
    }
    .bv-permission-screen .bv-table-css .bv-dialog-remove-btn{
    	text-align: center;
    	cursor: pointer;
    }
    .bv-permission-screen .bv-table-css .bv-dialog-remove-btn svg{
    	height: 18px;
    }
    .bv-permission-screen .bv-table-css .bv-dialog-remove-btn svg.icon polygon{
    	transform: scale(0.8);
    	-webkit-transform: scale(0.8);
    }


    .bv-permission-screen .bv-permission-apply-to-subfolders {
    	position: absolute;
    	bottom: -30px;
    	margin: 0px 10px;
    	display: inline-block;
    }
    .bv-permission-screen .bv-permission-apply-to-subfolders > div{
    	width: 24px;
    	height: 20px;
    	position: relative;
    	display: inline-block;
    	top: 3px;
    	cursor: pointer;
    }
    .bv-permission-screen .bv-permission-apply-to-subfolders > label{
    	font-size: 13px;
    }
    .bv-permission-screen .bv-dropdown > .btn{
    	padding-top: 0;
    	padding-bottom: 0;
    	margin-top: -5px;
    	font-size: 16px;
    	font-weight: inherit;
    	background: #fff;
    	color: #000;
    	-webkit-transition: inherit;
    	-moz-transition: inherit;
    	-o-transition: inherit;
    	transition: inherit;
    }
    .bv-permission-screen .bv-dropdown > .btn.empty{
    	border: 1px solid red;
    }

    .bv-permission-screen .bv-dropdown > .btn .caret{
    	opacity: 0;
    }
    .bv-permission-screen .bv-dropdown > .btn:hover{
    	background: inherit;
    }
    .bv-permission-screen .bv-dropdown.open > .btn{
    	background: #DFDFDF;
    }
    .bv-permission-screen .bv-dropdown > .btn:hover .caret,
    .bv-permission-screen .bv-dropdown.open > .btn .caret{
    	opacity: 1;
    }
    .bv-permission-screen .bv-dropdown > .btn:active,
    .bv-permission-screen .bv-dropdown > .btn:focus{
    	box-shadow: inherit;
    }
    .bv-permission-screen .dropdown-menu{
      box-shadow: inherit;
      top: 20px;
      border-top: inherit;
      border-left: inherit;
      font-size: 16px;
    }
    .bv-permission-screen .dropdown-menu li{
    	height: 30px;
    }
    .bv-permission-screen .dropdown-menu li a{
        padding-left: 5px;
    }


    .bv-dialog-user-list .treeNode{
    	padding:0;
    }
    .bv-dialog-user-list .tab-pane > div[data-tree-id="mytree"]{
    	padding:0;
    }
    .bv-dialog-user-list div[data-tree-id="mytree"]{
    	padding-left: 10px;
    }
    .bv-dialog-user-list .treeNode span{
    	padding-left: 3px;
    }

    .bv-dialog-user-list,
    .bv-dialog-folder-list {
    	position: absolute;
    	top: 0px;
    	z-index: -1;
    	/* margin-left: -360px; */
    	/* margin-left: 360px; */
    	width: 300px;
      	height: calc(100% + 48px);
    	background: #fff;
    	/* box-shadow: 0 5px 15px rgba(0, 0, 0, .5); */
    	padding: 10px;
    	box-shadow: 90px 0px 50px -100px #000 inset;
    	overflow: hidden;
    }
    .bv-dialog-folder-list{
    	box-shadow: -90px 0px 50px -100px #000 inset;
    }
    .bv-dialog-folder-list .bv-tree-node,
    .bv-dialog-folder-list ul li{
    	cursor: pointer;
    }
    .bv-dialog-folder-list.show{
    	margin-left: -300px;
    }
    .bv-dialog-user-list.show{
    	margin-left: 520px;
    }
    .bv-dialog-user-list span{
    	font-weight: 500;
    	font-size: 0.9em;
    }
    .bv-dialog-user-list .nav-tabs{
    	border:0;
    }
    .bv-dialog-user-list .nav-tabs > li > a{
    	padding: 5px;
    	border-radius: 0px;
    	border: 0;
    }
    .bv-dialog-user-list .tab-pane{
    	position: absolute;
    	overflow: auto;
    	height: calc(100% - 72px);
    	left: 10px;
    	right: 10px;
    	margin: 10px 0px;
    }
    .bv-dialog-user-list div[icon-renderer]{
    	display:inline-block;
    	top: 3px;
    	position: relative;
    }
    .bv-dialog-user-list div[icon-renderer] img{
    	width: 15px;
    	padding:0;
    }
    .bv-dialog-user-list a[tab-heading-transclude]{
    	font-weight: 400 !important;
    }
    .bv-dialog-user-list .nav-tabs > li > a{
    	line-height: 14px;
    }

    .bv-dialog-close-x {
    	color: #fff !important;
    	text-decoration: none !important;
    	font-weight: 100;
    	display: block;
    	font-size: 35px;
    	opacity: .8;
    	padding: 0 !important;
    	background: none !important;
    	margin-top: -10px;
    	-webkit-appearance: none;
    	cursor: pointer;
    	border: 0;
    	line-height: 1;
      	text-shadow: 0 1px 0 #fff;
    }

    .bv-dialog-action-buttons {
    	position:absolute;
    	bottom:20px;
    	right:20px;
    	margin: 0 !important;
    }

    .bv-sup-red-astric {
    	color: #F00;
    	font-size: 16px;
    	font-weight: 500;
    	display: inline-block;
    	vertical-align: top;
    }

    .bv-sup-red-astric-small {
    	color: #F00;
    	font-size: 10px;
    	font-weight: 500;
    	display: inline-block;
    }

    .bv-required-notice-label {
    	color: #808080;
    	font-style: italic;
    	font-size: 11px;
    	display: inline-block;
    	max-width: 97%;
    }

    [bv-item-list] {
    	height: 450px
    }

    .bv-preferences .bv-modal-body > div,
    .bv-challenge-questions .bv-modal-body > div,
    .bv-change-password .bv-modal-body > div,
    .bv-virtual-meeting-configuration .bv-modal-body > div,
    .bv-support .bv-modal-body > div{
	    padding: 5px 20px 0px 20px;
    }

    .bv-challenge-questions .modal-dialog{
    	width: 355px;
    	height: 308px;
    }

    .bv-change-password .modal-dialog,
    .bv-virtual-meeting-configuration .modal-dialog
    {
    	width: 403px;
    }

    .bv-support .bv-modal-body > div > div{
    	margin-bottom: 20px;
    }
    .bv-dropdown{
    	width: 100%;
    	position: relative;
    }
    .bv-dropdown > .btn{
    	width: 100%;
    	text-align: left;
    	border-radius: 0;
    	color: #000;
    	height: 26px;
    	background: rgba(224, 221, 221, 0.38);
    	outline: none !important;
    	font-size: 16px;
      	font-weight: inherit;
        border: none;
        padding: 2px 10px !important;
    }

    .bv-dropdown.open > .btn{
    	background: rgba(224, 221, 221, 0.38);
    	color: #000;
    }
    .bv-dropdown > .btn:hover{
    	background: #E5E5E5;
    	color: #000;
    }
    .bv-dropdown .btn .caret{
    	right: 10px;
    	position: absolute;
    	top: 10px;
    	border-top: 8px solid #000;
    	border-right: 7px solid transparent;
    	border-left: 7px solid transparent;
    }
    .bv-dropdown .dropdown-menu{
    	left: inherit !important;
    	left: 0px;
    	width: 100%;
    }

    .bv-dropdown .dropdown-menu > li > a{
    	color: #000;
    	font-size: 16px;
    }
    .bv-dropdown .dropdown-menu > li > a:hover{
    	background-color: #2273a5;
    	color: #fff;
    }

    .bv-change-password .bv-login-input-text{
    	background-color: #ccc;
    	box-shadow: inherit;
    }

    .bv-preferences{
        .preferences-menu li{
            margin: 0;
            padding-bottom: 10px;
        }
        .preferences-menu li:last-child{
            padding: 0;
        }
        a{
            color: #515151;
        }

        .modal-dialog{
            width: 430px;
        }

        .follow-followsme,
        .search-indexing{
            display: none;
        }

    }

    .bv-confirm .modal-dialog{
    	width: 335px;
    	position: absolute;
    	top: 50%;
    	left: 50%;
    	transform: translate(-50%,-50.1%) !important;
    	-webkit-transform: translate(-50%,-50.1%) !important;
        -ms-transform:  translate(-50%,-50.1%) !important;
    	margin: 0;
    }
    .bv-confirm .bv-confirm-content{
    	/*min-height: 82px;*/
    	font-size: 16px;
    	width: 100%;
    	margin: 0 auto;
    	padding: 25px 30px 0px 30px;
    	font-weight: 500;
    	text-align: center;
    }
    .bv-confirm .bv-input-btn-box{
    	text-align: center;
    	padding: 18px 20px;
    }
    .bv-recycle{
    /* 	top: inherit;
    	left: inherit; */
    }
    .bv-recycle .modal-dialog{
    	/* margin: 0;
    	width: 650px;
    	position: fixed;
    	bottom: 55px;
    	right: 10px; */
    	position: absolute;
    	width: 650px;
    	top: 50%;
    	left: 50%;
    	transform: translate(-50%,-50.1%) !important;
    	-webkit-transform: translate(-50%,-50.1%) !important;
    	margin: 0;
    	height:30px;
    }
    /* .bv-recycle-back{
    	position: inherit;
    } */

    .bv-show-message .bv-confirm-content{
    	padding-bottom: 30px;
    	padding-top: 0px;
    }

    .bv-copy-link .modal-dialog {
    	width: 550px;
    }
    .bv-copy-link .bv-modal-body > div{
    	padding: 20px;
    	/*height: 110px;*/
    	height: 70px;
    }

    .bv-create-folder .bv-modal-body > div{
    	padding: 20px;
    }
    .bv-create-folder .bv-modal-body input{
    	border-radius: 0;
    }

    .bv-slider{
    	height: 100%;
    	position: relative;
    }
    .bv-slider > ul {
    	height: 100%;
    	white-space: nowrap;
    	overflow-x: hidden;
    	overflow-y: hidden;
    	margin: 0px 45px;
    	padding: 0 0.1% 0 0;
    	/* padding: 10px; */
    }
    .bv-slider > ul > li{
    	display: inline-block;
    	height: calc(100% - 40px);
    	width: 31.2%;
    	margin: 20px 2%;
    	box-shadow: 0px 0px 15px -3px #000;
    	position: relative;
    }
    .bv-slider > ul > li > div{
    	position: absolute;
    	width: 100%;
    	height: 100%;
    }

    .bv-slider > ul > li > card{
        position: relative;
        display: block;
        width: 80%;
        margin: 0 auto;
        height: 100%;
        box-shadow: 0px 0px 15px -3px #000;
    }

    /* .bv-slider .bv-slider-items-4 > li {
    	width: 26%;
    }

    .bv-slider .bv-slider-items-2 > li {
    	width: 46%;
    }

    .bv-slider .bv-slider-items-1 > li {
    	width: 97% !important;
    }*/

    .bv-slider > div {
    	position: absolute;
    	top: calc(50% - 45px);
    	width: 40px;
    	height: 50px;
    	padding-top: 5px;
    	/*fill: #E5E5E5;*/
    }
    .bv-slider > div:hover {
    	cursor: pointer;
    	fill: #aaa;
    }
    .bv-slider > div.small{
    	width: 12px;
    	height: 18px;
    	top: calc(50% - 18px);
    }

    .bv-slider > div.bv-slider-left-btn{
    	left: 10px;
    }
    .bv-slider > div.bv-slider-right-btn{
    	right: 10px;
    }

    .bv-arrow-left {
    	width: 0;
    	height: 0;
    	border-top: 20px solid transparent;
    	border-bottom: 20px solid transparent;
    	border-right:20px solid #fff;
    }
    .bv-arrow-right {
    	width: 0;
    	height: 0;
    	border-top: 20px solid transparent;
    	border-bottom: 20px solid transparent;
    	border-left:20px solid #fff;
    }

    /*
    .bv-item-list-check-div {
    	height:35px; width:35px; padding-left: 6px; padding-top: 5px; margin-left: 21px;
    }*/


    .bv-item-remove-button {
    	display: inline-block;
    	width: 17px;
    	height: 17px;
    	margin: 0px 0px 0 0;
    	vertical-align: middle;
    	cursor: pointer;
    	fill: #000000;
    	position: relative;
    }

    .grayFilter {
      -webkit-filter: grayscale(0.5);
      filter: grayscale(0.5);
    }

    .bv-default-background-color-blue
    {
    	background-color: #2273a5;
    }

    .bv-default-background-color-gray
    {
    	background-color: #BBBBBB;
    }

    .bv-default-background-color-gray-dark
    {
    	background-color: #808080;
    }

    .bv-default-background-color-gray-light
    {
    	background-color: #E5E5E5;
    }

    .bv-default-background-color-gray-lighter
    {
    	background-color: #F4F4F4;
    }

    .bv-default-background-color-black
    {
    	background-color: #000000;
    }

    .bv-background-color-red
    {
    	background-color: #AB0808;
    }

    .bv-background-color-green
    {
    	background-color: #016100;
    }
    .bv-default-fill-color-blue
    {
    	fill: #2273a5;
    }

    .bv-default-fill-color-blue-plusIcon{
    	cursor:pointer;
    }
    .bv-default-fill-color-blue-plusIcon #inner_circle,
    .bv-default-fill-color-blue-plusIcon:hover #plus_symbol{
    	fill: #ffffff;
    }
    .bv-default-fill-color-blue-plusIcon #outer_circle,
    .bv-default-fill-color-blue-plusIcon #plus_symbol{
    	fill:#2574A7;
    }
    .bv-default-fill-color-blue-plusIcon:hover #inner_circle{
    	fill:#2574A7 !important;
    }
    .bv-default-fill-color-blue-plusIcon:hover #inner_circle,
    .bv-default-fill-color-blue-plusIcon:hover #plus_symbol{
      transition: 200ms;
      transition-timing-function: ease-in-out;
      -webkit-transition: 200ms;
      -webkit-transition-timing-function: ease-in-out;
    }
    .bv-mobile .bv-default-fill-color-blue-plusIcon:hover #inner_circle{
    	fill: #ffffff !important;
    }
    .bv-mobile .bv-default-fill-color-blue-plusIcon:hover #plus_symbol{
    	fill: #2574A7;
    }

    .bv-default-fill-color-gray
    {
    	fill: #BBBBBB;
    }

    .bv-default-fill-color-gray-dark
    {
    	fill: #808080;
    }

    .bv-default-fill-color-gray-light
    {
    	fill: #E5E5E5;
    }

    .bv-padding-10 {
    	padding-left:10px;
    	padding-right:10px;
    }

    .bv-padding-top
    {
    	padding-top:17px
    }
    .bv-default-fill-color-black
    {
    	fill: #000000;
    }

    .bv-default-fill-color-white
    {
    	fill: #FFFFFF;
    }

    .bv-default-font-color-red
    {
    	color: #F00;
    }

    .bv-default-font-color-blue
    {
    	color: #2273a5;
    }

    .bv-default-font-color-gray
    {
    	color: #BBBBBB;
    }

    .bv-default-font-color-gray-dark
    {
    	color: #808080;
    }

    .bv-default-font-color-gray-light
    {
    	color: #E5E5E5;
    }
    .bv-default-font-color-black
    {
    	color: #000000;
    }

    .bv-admin-auth .modal-dialog{
    	width: 540px;
    }
    .bv-admin-auth .modal-dialog .bv-modal-body > div{
    	height: 350px;
    }
    .bv-admin-auth-select > span{
    	font-weight: 600;
    }
    .bv-admin-auth-select > div{
    	padding-left: 17px;
    }

    .grayText{
    	color: lightgray;
    }
    .bv-header-company-name{
    	text-overflow: ellipsis;
    	width: 178px;
    	height: 20px;
    	overflow: hidden;
    	text-align: center;
    	display: inline-block;
    	white-space: nowrap;
    	margin-top: 33px;
    }

    .border-black-box {
    	border: 1px solid #000;
    }

    .border-gray-box {
    	border: 1px solid #e5e5e5;
    }

    .border-box {
    	border: 1px solid #e5e5e5;
    }

    .border-bottom {
    	border-bottom: 1px solid #e5e5e5;
    }

    .border-right {
    	border-right: 1px solid #e5e5e5;
    }

    .border-top {
    	border-top: 1px solid #e5e5e5;
    }

    .border-left {
    	border-left: 1px solid #e5e5e5;
    }

    .bv-svg-check-true
    {
    	height:22px; width:22px;
    	cursor: pointer;
    }

    .bv-svg-check-false
    {
    	height:22px; width:22px;
    	cursor: pointer;
    }

    .bv-svg-option-true
    {
    	height:16px;
    	width:18px;
    	cursor: pointer;
    }
    .bv-svg-option-true > svg{
    	height:16px;
    	width:18px;
    }

    .bv-svg-option-false
    {
    	height:16px;
    	width:18px;
    	cursor: pointer;
    }
    .bv-svg-option-false > svg{
    	height:16px;
    	width:18px;
    }

    .bv-doc-preview-portrait .modal-dialog {
    	height:660px;
    	width:480px;
    	/*margin: 35px auto !important;*/
    	position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50.1%) !important; -webkit-transform: translate(-50%,-50.1%) !important; -ms-transform:  translate(-50%,-50.1%) !important;
    }

    .bv-doc-preview-container {
    	height:625px;
    	text-align: center;
    }

    .bv-doc-preview-img {
    	position: relative;
    	top: 50%;
    	transform: translateY(-50%);
    	-webkit-transform: translateY(-50%);
    	-ms-transform:  translateY(-50%);
    	max-height: 100%;
    	max-width: 99%;
    	width: auto;
    }

    .bv-doc-preview-landscape .modal-dialog{
    	height:580px;
    	width:625px;
    	margin:70px auto !important;
    }
    .bv-doc-preview-landscape .modal-dialog > div > div > div > div > img {
    	height:515px;
    }
    .brandingDialog .modal-dialog{
    	position: absolute;
    	top: 50%;
    	left: 50%;
    	transform: translate(-50%,-50.1%) !important;
    	-webkit-transform: translate(-50%,-50.1%) !important;
    	margin: 0;
    	width: 1060px;
    	height: 617px;
    }
    .center-div {
        display:table;
        text-align: center;
    }
    .center-div-content {
    	display:table-cell;
        vertical-align:middle;
    }
    .no-content-msg-label {
    	height:19px;
    	line-height:19px;
    	white-space: nowrap;
    	overflow: hidden;
    	text-overflow: ellipsis;
    	color: #aeaeae;
    	padding-left: 20px;
    }


    .bv-context-menu-arrow-box {
    	position: fixed;
    	z-index: 1001;
    }

    .bv-context-menu-arrow {
    	position: relative;
    }

    .bv-context-menu-arrow:before, .bv-context-menu-arrow:after {
    	content: "";
    	width: 0;
    	height: 0;
    	border-style: solid;
    	border-color: transparent;
    	border-left: 0;
    	position: absolute;
    }

    .bv-context-menu-arrow:before {
    	border-right-color: rgba(128, 128, 128, 0.23);
    	border-width: 16px;
        top: 0px;
        left: -7px;
    }

    .bv-context-menu-arrow:after {
    	border-right-color: #fff;
    	border-width: 14px;
        top: 1px;
        left: -4px;
    }

    .image-in-card,
    .image-in-card img{
    	height: 100%;
    	max-width: 100%;
    	width: auto;
    	vertical-align: middle;
    	display: inline-block;
    	top: 50%;
    	position: relative;
    	transform: translateY(-50%);
    	-webkit-transform: translateY(-50%);
    	-ms-transform:  translateY(-50%);
    	margin-left: auto;
        margin-right: auto;
        display: block;
    }
    .image-in-card > div,
    .image-in-card > div > svg,
    .image-in-card > boardbook > svg{
    	width: 100%;
    	height: 100%;
    }
    .image-in-card img{
    	height: auto;
    	max-height: 100%;
    }
    .image-in-card > div > div > svg{
        width: 100%;
        height: 100%;
    }

    .bv-modules-settings .modal-dialog{
    	width: 670px;
    }
    .modal-dialog{
    	position: absolute;
    	top: 50%;
    	left: 50%;
    	transform: translate(-50%,-50.2%) !important;
    	-webkit-transform: translate(-50%,-50.2%) !important;
    	margin:0;
    }

    .bv-modules-settings .bv-modal-body{
    	padding: 20px;
    }
    .bv-modules-settings .bv-module-div1{
    	height: 410px;
    }
    .bv-modules-settings .bv-module-ul{
    	padding: 0px;
    }
    .bv-modules-settings li:last-child .bv-module-row{
    	margin:0;
    }

    .bv-modules-settings .showModuleSettings .bvMenuColor{
    	color: #000;
    	white-space: nowrap;
      	overflow: hidden;
      	text-overflow: ellipsis;
    }

    .sortingHandle {
    	cursor: move;
        cursor: -moz-grab;
        cursor: -webkit-grab;
        cursor: grab;
    }

    .grabbing-item {
    	cursor: move !important;
        cursor: -moz-grabbing !important;
        cursor: -webkit-grabbing !important;
        cursor: grabbing !important;
    }

    .ellipsis-overflow {
    	overflow:hidden;
    	white-space: nowrap;
    	text-overflow: ellipsis;
    }

    .hover-background {
    	/*background-color: #f9f9f9;*/
    	/*background-color: rgba(0,0,0,0.04);*/
        background-color : #ebeef0;
    }

    .drag-handle {
    	width: 14px;
    	min-height: 10px;
    	float: left;
        position: relative;
        top: 25px;
      	padding-left: 4px;
    }

    .drag-handle-image {
    	display: none;
    	/*margin-top: 25px;*/
    }

    .bv-search-results-mobile .searchInputText{
        position: absolute;
        font-size: 15px;
        top: -37px;
        margin: 0 35px 0 10px;
        width: calc(100% - 55px);
        background-color: rgba(0, 0, 0, 0.25);
        color: #FFF;
        padding-left: 35px;
        height: 28px;
        box-shadow: inherit;
    }
    .bv-search-results-mobile .searchInputText::-webkit-input-placeholder{
    	color: #FFF;
    }
    .bv-search-results-mobile .searchInputText:-moz-placeholder{
    	color: #FFF;
    }
    .bv-search-results-mobile .searchInputText::-moz-placeholder{
    	color: #FFF;
    }
    .bv-search-results-mobile .searchInputText:-ms-input-placeholder {
    	color: #FFF;
    }

    .bv-search-results-mobile .bv-search-results-mobile-search-icon{
        width: 16px;
        height: 16px;
        top: -31px;
        position: absolute;
        left: 20px;
        fill: #fff;
        z-index: 1;
    }
    .bv-search-results-mobile .back-button .searchInputText{
        margin: 0 35px 0 45px !important;
    }
    .bv-search-results-mobile .back-button .bv-search-results-mobile-search-icon{
        left: 55px !important;
    }
    .bv-search-results-mobile back-button{
        top: -32px;
        left: 11px;
        position: absolute;
        fill: #fff;
    }

    .bv-small-font{
    	font-size: 14px !important;
    }

    .bv-very-small-font {
    	font-size: 12px !important;
    }

    .bv-modal-window-new-mobile-attachment .treeDoc,
    .bv-modal-window-new-mobile-attachment .document-filter-container,
    .bv-modal-window-new-mobile-attachment [bv-splitter]{
    	display: none;
    }

    .bv-modal-window-new-mobile-attachment .file-chooser-padding{
    	padding: 0;
    }
    .bv-modal-window-new-mobile-attachment .repositoryLeft{
    	float: inherit;
        width: 100%;
    }
    .bv-modal-window-new-mobile-attachment .document-list-container{
        padding: 0;
        width: 100%;
    }
    .bv-modal-window-new-mobile-attachment [attachment-listing] > div > div{
    	display: none;
    }

    .bv-modal-window-new-mobile-attachment [attachment-listing],
    .bv-modal-window-new-mobile-attachment .attachment-list{
    	height: 100%;
    }

    .bv-modal-mobile-action-button {
    	position: relative;
    	float: left;
    	margin-right: 10px;
    }

    .bv-modal-mobile-action-button > img {
    	height: 22px;
    }

    .modal-dialog .bv-mobile-menu{
    	width: 57px;
    	height: 45px;
        right: 0px;
        position: absolute;
        top: 0px;
        display: block;
    }
    .modal-dialog .bv-mobile-menu > div{
    	height: 45px;
    }
    .modal-dialog .bv-mobile-menu > div > svg{
        fill: #fff;
        stroke: #fff;
        stroke-width: 3;
        display:block;
        width: 40px;
    	padding: 8px 29px;
      height: 46px;
      overflow: inherit;
    }
    .modal-dialog .bv-mobile-menu .dropdown-menu{
    	top: 43px;
        /* left: -114px;
        min-width: 140px; */
        left: -170px;
    		min-width: 205px;
        display: block;
    }

    .status-bar-over-dialog {
    	height: 10px;
    	width: 100%;
    	position: absolute;
    	top: -45px;
    }

    /* ng scrollbars related Start */

    .mCSB_scrollTools {
    	width: 8px;
    }

    .mCSB_inside>.mCSB_container {
    	margin-right: 20px;
    }

    .mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    	/*background-color: #000;*/
        background-color: rgba(0,0,0,.45) !important;
    }

    /*.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
    	background-color: rgba(0, 0, 0, 0) !important;
    }*/

    .mCSB_scrollTools, .mCSB_scrollTools .mCSB_buttonDown,
    .mCSB_scrollTools .mCSB_buttonLeft,
    .mCSB_scrollTools .mCSB_buttonRight,
    .mCSB_scrollTools .mCSB_buttonUp,
    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    	-webkit-transition: "";
        -moz-transition: "";
        -o-transition: "";
        transition: "";
    }

    .mCS-autoHide:hover>.mCustomScrollBox>.mCSB_scrollTools,
    .mCS-autoHide:hover>.mCustomScrollBox~.mCSB_scrollTools,
    .mCustomScrollBox:hover>.mCSB_scrollTools,
    .mCustomScrollBox:hover~.mCSB_scrollTools,
    .mCustomScrollbar>.mCustomScrollBox>.mCSB_scrollTools.mCSB_scrollTools_onDrag,
    .mCustomScrollbar>.mCustomScrollBox~.mCSB_scrollTools.mCSB_scrollTools_onDrag {
    	opacity: 0.75;
        filter: "alpha(opacity=75)";
        -ms-filter: "alpha(opacity=75)";
    }

    .mCSB_outside+.mCSB_scrollTools {
    	right: -13px;
    }

    .mCSB_outside+.mCS-minimal-dark.mCSB_scrollTools_vertical,
    .mCSB_outside+.mCS-minimal.mCSB_scrollTools_vertical
    {
    	right: -2px;
    	margin: 0px;
    }

    /* ng scrollbars related end */

    /* bv accordion*/
    .bv-accordion {
    	position: relative;
        width: 100%;
        display: inline-block;
    }

    .bv-accordion .bv-panel-heading {
        padding-left: 32px;
        height: 32px;
        line-height: 32px;
        font-weight: 500;
    }

    .bv-accordion .bv-panel-content {
    	padding-left: 32px;
    }

    .bv-hide-show-content-arrow {
        height: 15px;
        width: 15px;
        position: absolute;
    	left: 10px;
    }

    .bv-hide-panel-content-arrow {
    	top: 9px;
    }

    .bv-show-panel-content-arrow {
        top: 10px;
        left: 8px;
    }

    .bv-hide-show-content-arrow svg {
    	fill: #AEAEAE;
        transition: all 0.5s;
      	transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform-origin: 50% 40%;
        -moz-transform: rotate(90deg);
        -moz-transform-origin: 7px 7px;
        height: 15px;
        width: 15px;
    }

    .bv-show-panel-content-arrow svg {
    	transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        /* margin-bottom: 5px; */
        transform-origin: 50% 40%;
        -moz-transform: rotate(45deg);
        -moz-transform-origin: 7px 7px;
    }
    /*  accordion end */

    .bv-status-online-fill{
    	fill: #2273a5;
    }
    .bv-status-offline-fill{
    	fill: #bfbfbf;
    }

    .bv-print-approval .modal-dialog {
    	width: 600px;
    	/*height: 735px;*/
    }

    .bv-print-approval-options .modal-dialog {
    	width: 300px;
    }

    .bv-add-comments .modal-dialog{
    	width: 600px;
    	position: absolute;
    	top: 50%;
    	left: 50%;
    	transform: translate(-50%,-50.1%) !important;
    	-webkit-transform: translate(-50%,-50.1%) !important;
        -ms-transform:  translate(-50%,-50.1%) !important;
    	margin: 0;
    }

    /* tooltip directive */


    .bv-tooltip {
    	display: inline-block;
    	background-color: #2273a5;
    	border-radius: 5px;
    	padding: 7px;
    	color: #fff;
    }

    .bv-tooltip-down-arrow {
    	width: 0;
    	height: 0;
    	border-left: 7px solid transparent;
    	border-right: 7px solid transparent;
    	border-top: 7px solid #2273a5;
      	margin-left: calc(50% - 7px);
    }

    /* toggle buttons */
    .bv-toggle-buttons {
        padding-bottom: 5px;
    }
    .bv-toggle-buttons li.bv-toggle-button {
    	display: inline-block;
        margin: 0 20px;
    }

    .bv-toggle-buttons li.active {

    }

    .bv-toggle-buttons li.inactive {
    	-webkit-filter: grayscale(0.5);
    	filter: grayscale(0.5);
    	color: #BBBBBB;
    }

    .bv-toggle-buttons li.bv-toggle-button span.bv-toggle-button-label {
        padding-bottom: 3px;
        border-bottom: 5px solid;
    }

    .bv-toggle-buttons li.active span.bv-toggle-button-label {

    }

    .bv-toggle-buttons li.inactive span.bv-toggle-button-label {
    	border-bottom: none;
    }

    nvd3-pie-chart svg {
    	height: 100%;
    	width: 100%;
    }

    .bv-modal-window-folder-agenda-creator .model-dialog {
    	width: 400px;
    }

    /* end */

    /********************/

    @media (min-width: 768px){
    	.container{
    		width: inherit;
    	}
    }

    repositorylist{
    	display: block;
    }


    .vEditDialog .modal-dialog
    {
    	box-shadow: 0 0 0 0;
    	height: 97%;
    	margin: 0.1%;
    	min-width: 300px;
    	min-height: 300px;
    	overflow: hidden;
    	width: 96%;
    }
    .vEditDialog .modal-content
    {
    	background: transparent;
    	height: 100%;
    	width: 100%;
    }

    .veditHeaderMenuDiv {
    	position:absolute;height:3%;width:50%;left:25%;opacity:0;
    }

    .veditHeaderMenuDiv:hover {
    	opacity:1;
    }

    .veditHeaderSubMenuFullScreen {
    	display: inline-block;
    }
    :-webkit-full-screen .veditHeaderSubMenuFullScreen{
    	display: none;
    }
    :-moz-full-screen .veditHeaderSubMenuFullScreen{
    	display:none;
    }
    :-ms-full-screen .veditHeaderSubMenuFullScreen{
    	display:none;
    }
    :full-screen .veditHeaderSubMenuFullScreen{
    	display:none;
    }

    .veditHeaderSubMenuExitFullScreen {
    	display:none;
    }
    :-webkit-full-screen .veditHeaderSubMenuExitFullScreen{
    	display:inline-block;
    }
    :-moz-full-screen .veditHeaderSubMenuExitFullScreen{
    	display:inline-block;
    }
    :-ms-full-screen .veditHeaderSubMenuExitFullScreen{
    	display:inline-block;
    }
    :full-screen .veditHeaderSubMenuExitFullScreen{
    	display:inline-block;
    }


    .veditHeaderMenuDiv:hover .veditHeaderMenu {
    	display:block;
    	cursor:pointer;
    	/*background-color: #605e5d;*/
    	background-color: #666666;
    	color:#FFFFFF;
    }

    .veditHeaderMenu {
    	position: absolute;
      	display: none;
      	padding: 5px;
      	border: 1px solid #ccc;
    }

    .veditHeaderMenu > li {
      	/*display: inline-block;*/
        cursor: pointer;
        padding-right: 5px;
        padding-left: 5px;
    }

    .bv-lang-main-div { height: calc(100% - 70px);margin-top:8px;width:100% }

    .bv-lang .modal-dialog{
    	width: 500px;
    }
    .bv-lang .bv-lang-div0{
    	height: 40px;
    }
    .bv-lang .bv-lang-div1{
    	height: 30px;
    }
    .bv-lang .bv-modal-body{
    	padding: 20px;
    }
    .bv-dialog-user-list.moduleCreatorShowMe{
    	margin-left: 564px;
    	display:block;
    	height: 502px;
    }
    .bv-dialog-user-list.moduleCreatorNoShow{
    	display:none;
    }
    .moduleCreatorPermissionsDIV{
    	width:100%;
    	height:300px;
    	padding:20px;
    }

    .verticalLine{
        border-right: 1px solid #eee;
        top: 0;
        bottom: 0;
    }
    .bv-module-permission-option-row-selected {
        background-color: #eee;
    }
    .bv-module-permission-option-row-not-selected{
    }
    .module-creatorsDIV{
    	width:40%;
    }
    .module-creator-heading{
    	padding:10px;
    }
    .module-creator-hr-left{
    	margin-top:0px;
    	margin-bottom:10px;
    	margin-right: 30px;
    }
    .module-creator-hr-right{
    	margin-top:0px;
    	margin-bottom:10px;
    	margin-left: 20px;
    }
    .module-creator-module-name{
    	padding:10px;
    	cursor:pointer;
    	overflow: hidden;
      	text-overflow: ellipsis;
      	white-space: nowrap;
      	max-width:183px;
    }
    .module-creator-users{
    	width:60%;
    }
    .module-creator-users-heading{
    	padding:10px;
    	margin-left:15px;
    }
    .module-creator-user-li{
    	padding:10px;
    	clear:left;
    }
    .module-creator-remove-user{
    	width:15px;
    	float:right;
    	cursor:pointer;
    	margin-top:3px;
    }
    .module-creator-user-style{
    	max-width:200px;
    	overflow: hidden;
      	text-overflow: ellipsis;
      	white-space: nowrap;
    }
    .bv-module-creator-user-item {width: 100%; display: list-item; height: 35px;}
    /* .bv-module-creator-user-item:hover{
    	background:#ebeef0;
    } */
    .bv-approval-user-option-section1 .bv-dialog-user-btn svg {
        right: 0;
    }
    /* .bv-approval-user-option-section1 .bv-dialog-user-btn svg, .bv-permission-screen .bv-dialog-folder-btn svg {
        width: 17px;
        height: 20px;
        top: 0px;
        position: absolute;
        cursor: pointer;
    } */
    .bv-approval-user-option-section1 .bv-dialog-user-btn svg, .bv-permission-screen .bv-dialog-folder-btn svg {
        width: 17px;
        height: 20px;
        top: 0px;
        position: absolute;
        cursor: pointer;
        /* padding-top: 29px; */
        left: 0px;
        }
    .bv-permisson-folder-arrow-svg {
        width: 17px;
        height: 20px;
        position: absolute;
        cursor: pointer;
        /* padding-top: 29px; */
        left: 67px;
        }
    .bv-span-plusIcon{
    	width:21px;
    	height:21px;
    	cursor:pointer;
    }
    .bv-input-btn-box-remove-shadow{
    	text-align: center;
        padding: 0px 20px 20px 20px;
        position: relative;
        z-index: 1;
        text-align: right;
        width: 100%;
    }
    .bv-nonrespondents-svg
    {
    	width:7%;
    	height:20%;
    }
    .bv-surveys-itemList
    {
    	height:95%;
    	width:100%;
    }
    .bv-notSurveys-itemList
    {
    	height:100%;
    	width:100%;
    }
    .bv-survey-nonRespondants-label
    {
    	position:relative;
    	top:2px;
    	font-size:14px;
    }

    .display-inline {
        display: inline-block;
    }

    .session-timeout-message {
        padding: 30px 20px 20px 20px !important;
    }

    .nav>li.disabled>bt {
        color: #DCDCDC;
        cursor: not-allowed;
    }

    .bv-user-management-toolkit{
    	font-size: 14px;
    }
    .bv-user-management-toolkit .bv-user-management-toolkit-form{
    	padding: 20px;
    	height: 460px;
    }
    .bv-user-management-toolkit .bv-dialog-title-box{
    	z-index: 1;
        position: relative;
    }
    .bv-user-management-toolkit .bv-dialog-title-box input{
    	width: calc(100% - 80px);
    }
    .bv-user-management-toolkit .bv-dialog-user-btn{
    	float: right;
        margin-top: 5px;
        max-width: 88px;
        width:auto;
    }
    .bv-user-management-toolkit .bv-dialog-user-btn > span{
        font-size: 15px;
    }
    .bv-user-management-toolkit .bv-dialog-user-btn > svg{
    	height: 15px;
        margin: 3px 5px;
        float: right;
        fill: #818587;
        width: 9px;
    }
    .bv-user-management-toolkit .bv-dialog-user-list{
    	display: none;
    	margin-left: 600px;
    }
    .bv-user-management-toolkit .bv-dialog-user-list.show{
    	display: block;
    }
    .bv-user-management-toolkit .bv-user-management-toolkit-form > div:last-child{
    	top: -15px;
    }
    .bv-user-management-toolkit .bv-scroll-list > filter{
    	width: 84%;
    }
    .bv-user-management-toolkit [bv-item-list]{
    	height: 463px;
    }
    .bv-user-management-toolkit .checkbox-disabled > svg{
    	opacity: 0.5;
    	cursor: default;
    }


    .bv-scroll-list-table > ul > li > svg,
    .bv-scroll-list-table .scroll-list > li > ul > li svg{
        float: right;
        top: 12px;
        position: relative;
        margin-left: 5px;
        width: 18px;
        height: 17px;
    }
    .bv-scroll-list-table > ul{
    	margin: 0 !important;
    	background: #EFEFEF;
    }
    .bv-scroll-list-table > ul > li,
    .bv-scroll-list-table .scroll-list > li > ul > li{
        padding: 0 10px;
        text-align: right;
        height: 40px;
    	cursor: default;
    }
    .bv-scroll-list-table .scroll-list > li > ul > li span{
        width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
    }
    .bv-scroll-list-table > ul > li:first-child,
    .bv-scroll-list-table .scroll-list > li > ul > li:first-child{
    	text-align: left;
    }
    .bv-scroll-list-table .scroll-list > li > ul > li:nth-child(1){
    	overflow: visible !important;
    }
    .bv-scroll-list-table .scroll-list-items{
    	padding-right: 0 !important;
    	margin-top: 20px;
    	height: 315px;
    }
    .bv-scroll-list-table .scroll-list > li{
    	border: 0 !important;
    }
    .bv-scroll-list-table .scroll-list > li:nth-child(odd){
    	background: #f7f9fa;
    }
    .bv-scroll-list-table .delete-btn{
    	width: 16px;
        opacity: 0;
        right: -30px;
        z-index: 1;
        position: absolute !important;
    }
    .bv-scroll-list-table .scroll-list > li:hover .delete-btn{
    	opacity: 0.2;
    }
    .bv-scroll-list-table .scroll-list > li .delete-btn:hover{
    	opacity: 1;
    	cursor: pointer;
    }
    .bv-scroll-list-table .scroll-list > li .delete-btn.delete-disabled:hover{
    	opacity: 0.2;
    	cursor: default;
    }

    .bv-scroll-list-table svg.checkbox-disabled{
    	opacity: 0.3;
    	cursor: default;
    }
    .bv-scroll-list-table svg.sort-direction{
    	display: inline;
        width: 15px;
        float: none;
        top: 0px;
        display: none;
    }
    .bv-scroll-list-table .bv-scroll-col{
    	white-space: normal !important;
    }

    .bv-permission-list:hover .deleteIconSvg
    {
    	fill:#D3D3D3;
    	visibility:visible;
    }
    .deleteIconSvg
    {
    	fill:black;
    	visibility:hidden;
    }
    .deleteIconSvg:hover
    {
    	fill:black !important;
    	visibility:visible;
    }

    checkbox svg{
        width: 18px;
        height: 18px;
    }
    checkbox .active svg{
        fill: #2273a5;
    }
    /* common.css */

    /* calendar.css */
   .ngdialog.calendar-dialog .ngdialog-overlay{
    z-index:-1;
   }

   .ngdialog.calendar-dialog .bv-dialog-user-list{
    width:200px;
    background:#f0f0f0;
    position:absolute;
    z-index:-1;
    top: 10px;
    right: 0px;
    bottom: 10px;
    border-radius: 5px;
   }
   .ngdialog.calendar-dialog .bv-dialog-user-list.expanded{
    right:-201px;
   }

   .ngdialog.calendar-dialog .bv-dialog-user-list .tab-content ul{
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    display: block;
    padding: 0px 0px 0px 10px;
   }

   .event-bar{
       background: #aeaeae;
       float: left;
       width: 6px;
       margin-top: 6px;
   }

   .event-active-bar{
       background: #2273a5;
   }

   .event-running-bar {

   }


   .event-active-bar-space{
    margin-left: 10px;
   }

   .bv-events-details{
    display: inline-block;
    max-width: calc(100% - 14px);
       position: relative;
   }

   .bv-events-list > .scroll-list-items .scroll-list {
    max-width: 100%;
   }

   .ngdialog.calendar-dialog .bv-dialog-user-list .tab-content{
    position: absolute;
    bottom: 15px;
    overflow-y: auto;
    top: 85px;
    width: 100%;
   }

   .ngdialog.calendar-dialog .bv-dialog-users-btn{
    right: 15px;
    background: #ccc;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 60px;
    cursor:pointer;
   }

   .ngdialog.calendar-dialog .bv-dialog-user-list .nav > li > a{
    padding: 5px 10px;
    border-radius: 0px;
    border: 0;
   }
   .ngdialog.calendar-dialog .bv-dialog-user-list .bv-users-title{
    padding:10px;
    display:block;
   }
   .ngdialog.calendar-dialog .bv-dialog-user-list .tab-content .tab-pane>ul>li:hover{
    background: rgba(0,0,0,0.04);
    cursor: pointer;
   }
   .ngdialog.calendar-dialog .bv-dialog-user-list .treeNode{
    white-space: nowrap;
   }
   .ngdialog.calendar-dialog .bv-dialog-user-list .treeNode.selected{
    background:none;
   }
   .ngdialog.calendar-dialog div[icon-renderer] img{
    height: 17px;
    padding:0;
    margin:0px 2px;
   }
   .ngdialog.calendar-dialog .bv-dialog-user-list div[data-tree-model] li{
    padding:0;
    line-height:20px;
   }
   .ngdialog.calendar-dialog .bv-dialog-user-list div[data-tree-model] li i,
   .ngdialog.calendar-dialog .bv-dialog-user-list div[data-tree-model] li span{
    top: 3px;
    width:100%;
   }
   .ngdialog .bv-dialog-user-list div[icon-renderer]{
    position: relative;
    top: 0px;
    display:inline-block;
   }
   .ngdialog.calendar-dialog ul{
    display: inline-table;
   }

   .bv-calendar{
    height:100%;
    width:100%;
    min-height: 280px;
   /* 	padding-bottom:5px; */
    outline: 0;
    overflow:hidden;
    -webkit-overflow-scrolling: touch;
   }
   .bv-calendar .well{
    height: 100%;
    box-shadow:none;
    border:none;
    background:none;
    outline: none;
   }
   .bv-calendar .well>table{
    height: 100%;
    width: 100%;
    outline: none;
   }
   .bv-calendar .well .title{
    text-align:center;
    background:none;
    display: block;
    font-size: 1.8em;
    color: #515151;
   }
   .bv-calendar .well .btn{
    height: 100%;
    padding:2px 10px;
    font-size: 22px;
    text-align:left;
    border:none;
    color:#515151;
   }
   /* .bv-calendar .well .btn-default:hover,
   .bv-calendar .well .btn-default:focus,
   .bv-calendar .well .btn-default:active,
   .bv-calendar .well .btn-default.active{
    border:none;
   } */

   /* .bvhome .bv-calendar .carousel-control{
    width:10px;
    bottom: inherit;
   }
   .bvhome .bv-calendar .carousel-control.right span{
    right: 15px;
    top: 40px;
   }
   .bvhome .bv-calendar .carousel-control.left span{
    left: 30px;
    top: 40px;
   } */
   .bv-calendar .well .event{
    position: absolute;
    font-size: 0.9em;
    right: 8px;
    top: 5px;
    bottom: 3px;
    width: 70%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: right;
    padding: 0px 1px;
    color: rgb(255, 46, 18);
    border-radius: 0px;
   }
   .bv-calendar .well .event:hover{
    overflow-y: auto;
   }
   .bv-calendar .well .event span:hover{
   /* 	font-weight:bold; */
    text-decoration: underline;
    cursor:pointer;
   }
   /* .bvhome .bv-calendar .well table td{
    position:relative;
    padding: 0px 2px;
   } */
   /* .bvhome .well table tbody>tr>td>button{
    position:absolute;
   } */
   .bv-calendar .well .current{
    border: 1px solid #FF8500 !important;
   }

   .bv-calendar .well .currentUnderline{
    cursor: pointer;
    text-decoration: underline;
   }

   .bv-calendar .well .noUnderline{
    cursor: pointer;
   }

   .bv-calendars-list{
    padding: 5px 30px 30px 30px;
    display:block;
   }
   .bv-resizer .bv-calendars-list{
    padding:5px;
   }
   .bv-calendars-list>ul>li{
    border-bottom: 1px solid #515151;
    padding: 10px 10px;
    position: relative;
    overflow: hidden;
    min-height: 55px;
   }
   .bv-calendars-list>ul>li:last-child{
    border:none;
   }
   .bv-calendars-list>ul>li:hover{
    background: rgba(0,0,0,0.04);
    cursor: pointer;
   }
   .bv-calendars-list>ul>li span.title{
    font-size: 1.7em;
    color: #515151;
    max-width: 50%;
    display: block;
    float: left;
    white-space: normal;
   }
   .bv-resizer .bv-calendars-list>ul>li span.title{
    font-size: 1.2em;
   }
   .bv-calendars-list>ul>li span.time{
    bottom: 10px;
    position: absolute;
    right: 10px;
   }
   .bv-calendars-list>ul>li .color{
    width: 70px;
    height: 40px;
    display: inline-block;
    right: 10px;
    position: absolute;
    border: 1px solid #515151;
   }
   .bv-calendar .carousel-inner > .item {
   /*   -webkit-transition: left 400ms ease;
             transition: left 400ms ease;
     -webkit-transform: translate3d(-100px, 0px, 0px); */
   }
   .bv-calendar-title{
    height: 40px;
    width: 100%;
    text-align: center;
    background: none;
    display: block;
    font-size: 1.8em;
    font-weight: bold;
    color: #515151;
    line-height: 40px;
    margin: 5px 0px;
   }

   .bv-calendar .rn-carousel-control.rn-carousel-control-prev:before,
   .bv-calendar .rn-carousel-control.rn-carousel-control-next:before{
    content:none;
   }
   .bv-calendar .rn-carousel-control.rn-carousel-control-prev,
   .bv-calendar .rn-carousel-control.rn-carousel-control-next{
    width: 21px;
    height: 34px;
   }
   .bv-calendar .rn-carousel-control.rn-carousel-control-prev{
    background:url('../assets/images/leftarrow.png');
    left: 1em;
    top: 1.4em;
   }
   .bv-calendar .rn-carousel-control.rn-carousel-control-next{
    background:url('../assets/images/rightarrow.png');
    right: 1em;
    top: 1.4em;
   }
   /* .bv-calendar .well .bv-calendar-cell{
    top:0px;
    background: #fff;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500 ease;
    transition: all 500ms ease;
   } */
   .bv-calendar .well bv-table.bv-day.hover:hover{
   /* 	-webkit-transform: scale3d(1.4,1.4,1.4); */
    /* width: 130%; */
   /* 	height:240%;
    width:200%;
    z-index: 2;
        border: 5px solid #ccc;
        top:0px;
        position:absolute; */
        /* font-size:2em; */
   }
   .bv-calendar .well .hover .event span{
    opacity:0;
   }
   .bv-calendar .well .hover:hover .event span{
    opacity:1;
   }
   .bv-calendar .well .event div>div{
    display:block;
    width:7px;
    height:7px;
    background: red;
    float: right;
    border-radius: 14px;
    opacity:1;
    top: 5px;
    position: relative;
   }
   .bv-calendar .well .hover:hover .event>div>div{
    opacity:0;
   }
   .bv-calendar .well .hover .event .time{
    float: left;
    opacity:0;
    padding-right:5px;
    text-decoration:none;
   }
   .bv-calendar .well .hover:hover .event .time{
    opacity:1;
   }
   .bv-calendar .well bv-table.active{
    background:#EEE8E8;
   }
   .bv-calendar .well bv-table bv-table:active{
    background:#EEE8E8;
   }
   .bv-calendar-cell{
    vertical-align: middle;
    position: relative;
    padding: 2px;
   }
   .bv-calendar-cell bv-cell{
    vertical-align:bottom;
   }
   .bv-calendar-day-number{
    padding: 10px;
    position: relative;
    font-size: 1.5em;
    color: #515151;text-align: left;
   }
   .bv-calendar ul[rn-carousel-transition="hexagon"]{
    overflow:hidden;
   }


   .bv-calendar ul[rn-carousel] li .bv-calendar{
    padding: 10px;
   /* border: 1px solid #ccc; */
    background: #F5F5F5;
   /* 	box-shadow: 0px 0px 5px 1px #ddd;
    border-radius: 10px;*/
   }

   .bv-cards{
    outline:0;
   }
   .bv-cards ul[rn-carousel]{
    width:33.3%;
    overflow:inherit;
   }
   .bv-cards ul[rn-carousel] li>div>div>ul,
   .bv-cards ul[rn-carousel] li>div{
    padding:10px;
   }
   .bv-cards ul[rn-carousel] li>div>div{
    padding:10px;
    border: 1px solid #ccc;
    background: #F5F5F5;
    box-shadow: 0px 0px 5px 1px #ddd;
    border-radius: 10px;
    overflow:hidden;
   }

   .bv-cards ul[rn-carousel] li>div>div>title{
    display: block;
    font-size: 2em;
    font-weight: 500;
    line-height: 27px;
    margin-bottom:30px;
   }
   .bv-cards ul[rn-carousel] li>div>div>title:hover{
    text-decoration: underline;
    cursor:pointer;
   }
   .bv-cards ul[rn-carousel] li>div>div>label{
    display: block;
    font-size: 1em;
    line-height: 10px;
   }
   .bv-cards ul[rn-carousel] li>div>div>info{
    font-size: 0.9em;
    margin-bottom: 23px;
    display: block;
   }

   .bv-cards-module ul[rn-carousel] li>div{
    padding:5px;
   }
   .bv-cards-module ul[rn-carousel] li>div>div>title{
    font-size: 1.5em;
    line-height: 20px;
    margin-bottom: 15px;
    height: 41px;
    text-overflow: ellipsis;
    overflow: hidden;
   }
   .bv-cards-module ul[rn-carousel] li>div>div>info{
    font-size: 0.8em;
    margin-bottom: 13px;
   }



   /* .bv-cards ul[rn-carousel] li>div>div>type{
    font-size: 1.1em;
   }
   .bv-cards ul[rn-carousel] li>div>div>line{
    background: rgb(169, 109, 29);
    height: 6px;
    width: 100%;
    display: block;
   } */
   /* .bv-cards ul[rn-carousel] li>div>div>ul li{
    padding: 15px 0px;
   }
   .bv-cards ul[rn-carousel] li>div>div>ul li>title{
    display: block;
    font-size: 1.4em;
    font-weight: 500;
   }
   .bv-cards ul[rn-carousel] li>div>div>ul li>info{
    font-size: 0.9em;
   } */
   .bv-cards div[rn-carousel-indicators]{
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
   }
   .bv-cards div[rn-carousel-indicators] span{
    margin: 0 2px;
   }

   /* iPhone Portrait */
   @media screen and (max-device-width: 480px) and (orientation:portrait) {
    .bv-calendars-list>ul>li span.title {
        font-size: 1.0em;
    }
   }
   .bv-calendar .showWeeks{
    width:100%;
   }
   .bv-calendar .showWeeks>bv-cell{
    width: 25px;
   }
   .showWeeks > div{
    display:inline-block;
    width: 14%;
   }

   /* .row{
    margin:1px 0px;
    height: 13.8%;
   }
   .row > div{
    display:inline-block;
    width: 14%;
    margin: 0px 1px;
    background: #fff;
    height: 100%;
   } */



   .bv-calendar-content .parent{
    height: 100%;
    background: #fff;
    padding: 10px 0;
    white-space:nowrap;
        overflow-x:auto;
        overflow-y:hidden;
   }
   .bv-calendar-content .bv-slider > ul
   {
    min-width: 480px;
    min-height: 415px;
   }
   .bv-calendar-content .bv-slider > ul > li{
    width: 93%;
       margin: 20px 3.3%;
   }

   .bv-calendar-content .bv-slider > ul > li canvas{
    display:block;
   }

   .bv-calendar-content .parent > ul > li > div{
   /* 	  display:inline-block;
      height: 100%;
      width: 100%;
      margin-right: 11px; */
      padding: 10px 20px;
   }
   .bv-calendar-content .parent .child:last-child{
    margin:0;
   }
   .bv-calendar-content .parent .row{
    margin: 0.6% 0px;
    height: 20%;
   }
   .bv-calendar-content .parent .rowplus .row{
    height: 16.4%;
   }

   .bv-calendar-content .parent .row .cell:hover {
    border: 1px solid #2273a5;
   }

   .bv-calendar-content .parent .row .cell{
    display: inline-block;
    width: 13.7%;
    margin: 0px 0.3%;
   /* 	background: #fff; */
    height: 100%;
    position: relative;
    border: 1px solid #ccc;
   }
   .bv-calendar-content .parent .row .cell .day,
   .bv-calendar-content .parent .row .cell .daysplit{
    padding: 0px;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    width: 30px;
    height: 30px;
    margin: 2px auto;
    border-radius: 30px;
    color: #000;
    opacity: 0;
    font-weight: 500;
   }
   .bv-calendar-content .parent .row .cell.current .day,
   .bv-calendar-content .parent .row .cell.current .daysplit{
    opacity: 1;
   }
   .bv-calendar-content .parent .row .cell.current .bv-calendar-dot{
    opacity: 1;
   }
   .bv-calendar-content .parent .row .cell.current .bv-calendar-dot:hover{
    cursor: pointer;
   }
   /*.bv-calendar-content .parent .row .cell.current:hover{
    cursor: pointer;
   }*/
   .bv-calendar-content .parent .row .cell.current:active{
    background: rgba(0,0,0,0.04);
   }
   .bv-calendar-content .parent .row .cell.weekend{
    background: rgba(236, 236, 236, 1);
   }

   /* .bv-calendar-content .parent .row .cell.current-day{
    background: rgba(0, 0, 0, 0.1);
   }
    */
    .bv-calendar-content .parent .row .cell .day.current-day,
    .bv-calendar-content .parent .row .cell .daysplit.current-day{
    color: #fff;
   }
   .bv-calendar-content .parent .title-month{
    font-size: 30px;
    font-weight: 500;
    text-align: center;
   /* 	margin: 5px 0px 10px 0px; */
   }
   .bv-calendar-content .parent .title-month.empty{
    display: inline-block;
   }
   .bv-calendar-content .parent .week-days{
    display: inline-block;
    width: 14.3%;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
   }
   .bv-calendar-content .parent .events{
    position: absolute;
    right: 0px;
    font-size: 10px;
    top: 17px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 50%;
    height: calc(100% - 17px);
   }
   .bv-calendar-content .parent .events .dot{
   /* 	width: 7px;
    height: 7px;
    background: red;
    border-radius: 14px;
    opacity: 1;
    position: absolute;
    right: 5px;
    top: 3px; */
    margin-top: 2px;
    width: 7px;
    height: 7px;
    background: red;
    border-radius: 14px;
    opacity: 1;
    position: absolute;
    right: 5px;
    display: inline-block;

   }
   .bv-calendar-content .parent .row .cell .events span{
    opacity: 0;
    position: relative;
    top: -2px;
    color: red;
    cursor: pointer;
   }
   .bv-calendar-content .parent .row .cell:hover .events span{
    opacity: 1;
   }
   .bv-calendar-content .parent .row .cell:hover .events .dot{
    opacity: 0;
   }
   .bv-calendar-content .parent .row .cell .events:hover span{
    opacity: 1;
   }
   .bv-calendar-content .parent .row .cell .events:hover .dot{
    opacity: 0;
   }

   .bv-calendar-box{
    height: calc(100% - 150px);
   }

   .leftAtrrow{
    width: 40px;
    height: 40px;
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
    z-index: 1;
   }
   .rightAtrrow{
    width: 40px;
    height: 40px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1;
   }

   .bv-calendar-mask{
    width: 100%;
    height: 100%;
    position: absolute;
    background: #FFF;
    z-index: 1;
    top: 0;
    opacity: 0.9;
   }

   .bv-calendar-line{
    height: 45px;
        padding-top: 10px;
   }
   .bv-calendar-line > div{
    background: #aeaeae;
    height: 8px;
   }

   .bv-calendar-dot{
    height: 16px;
    width: 16px;
    background: #ccc;
    margin: 0 auto;
    border-radius: 50%;
    position: absolute;
    top: 66%;
    left: calc(50% - 8px);
    opacity: 0;
   }
   .additional-day .bv-calendar-dot{
    left: calc(50% - 6px);
   }
   .building .parent{
    overflow-x: hidden;
    opacity: 0.5;
   }
   .building svg{
    display: none;
   }

   .bv-carousel-button{
    width: 97px;
    position: absolute;
    z-index: 1;
    top: calc(50% - 50px);
   }
   /* .bv-carousel-button:hover{
    background: rgba(204, 204, 204, 0.2);
   } */

   .bv-carousel-button>div:hover>svg{
    cursor: pointer;
    opacity: 1;
   }
   .bv-carousel-button-right{
    right:0;
   }
   .bv-carousel-button>div{
    position: absolute;
    left: calc(50% - 45px);
   }
   .bv-carousel-button>div>svg{
    fill:#515151;
    width: 69px;
    height: 97px;
    opacity: 0.2;
   }
   .bv-carousel-button-right>div{
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -o-transform: translateX(100px);
    transition: translateX(100px);
   }
   .bv-carousel-button-left>div{
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transition: translateX(-100px);
   }
   .bv-calendar-content{
    overflow:hidden;
   }
   .bv-calendar-content:hover .bv-carousel-button>div,
   .bv-cards-content:hover .bv-carousel-button>div{
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -o-transform: translateX(0px);
    transition: translateX(0px);
   }
   .bv-cards-content{
    overflow: hidden;
    position: absolute;
   }

   [state="home.meetings"] .bv-slider > ul > li > div{
       padding: 20px;
   }

   .bv-cards-content>div:first-child{
        overflow: auto;
        position: absolute;
    }

   /* .bv-cards-content .bv-scrollbar,*/
    .bv-events-list.bv-scroll-list .scroll-list-items{
        height: calc(100% - 80px);
    }

   .bv-events .modal-dialog{
    -webkit-transform: inherit !important;
    -ms-transform: translate(0,0) !important;
    transform: inherit !important;
    width: 340px;
   /* 	height: 100%; */
    margin: 150px auto;
    top: inherit;
    left: inherit;
   }

   .bv-events .bv-modal-header-small{
    min-height: inherit;
    padding: inherit;
   }
   .bv-events .bv-events-title{
    margin: -4px 0px 0px;
    font-size: 26px;
    font-weight: 600;
   }
   .bv-events .modal-content{
    padding: 20px;
    height: 360px;
   /*  	max-height: 525px; */
   }
   /* .bv-events .bv-events-list > .scroll-list-items .scroll-list{
    max-height: 300px;
   } */

   .bv-events .bv-modal-body{
    height: 100%;
   }

   /* .bv-cards-content .bv-events-list > .scroll-list-items .scroll-list > li,
   .bv-events .bv-events-list > .scroll-list-items .scroll-list > li{
    height: 70px;
    border: inherit;
    border-bottom: none;
    margin-bottom: 22px;
   } */

   .bv-cards-content .bv-events-list > .scroll-list-items .scroll-list > li,
   .bv-events .bv-events-list > .scroll-list-items .scroll-list > li{
    /* margin-bottom: 33px; */
    height: 100% !important;
    border: inherit;
    border-bottom: none;
    margin-bottom: 17px;
   }
   .bv-events .bv-events-list > .scroll-list-items .scroll-list > li:last-child{
    margin: inherit;
   }

   .bv-events-list .bv-events {
    height: calc(100% - 80px);
   }

   .bv-cards-content .bv-events-list > .scroll-list-items .scroll-list > li .bv-events-list-name,
   .bv-events .bv-events-list > .scroll-list-items .scroll-list > li .bv-events-list-name{
    height:29px;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 1px;
   }

   .bv-events .bv-events-list > .scroll-list-items .scroll-list > li .bv-events-list-teamspace,
   .bv-cards-content .bv-events-list > .scroll-list-items .scroll-list > li .bv-events-list-teamspace
   {
    height:20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
   }

   .bv-cards-content .bv-events-list > .scroll-list-items .scroll-list > li .bv-events-list-time {
    height:19px;
    line-height:19px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
   }

   /* .bv-cards-content .bv-events-list > .scroll-list-items .scroll-list > li > div:last-child, */
   .bv-events .bv-events-list > .scroll-list-items .scroll-list > li > div:last-child,
   .bv-events .bv-events-list > .scroll-list-items .scroll-list > li .bv-events-list-teamspace{
    color: #898989;
    margin: -1.4px 0px;
   }
   .bv-cards-content .bv-events-list > .scroll-list-items,
   .bv-events .bv-events-list > .scroll-list-items{
    padding: 0;
   }
   .bv-events .bv-arrow-left{
    position: absolute;
    bottom: 15px;
    left: -40px;
   }
   .bv-events .bv-arrow-right{
    position: absolute;
    bottom: 15px;
    right: -40px;
   }

   .bv-cards-content .bv-slider > ul > li > div{
    padding: 15px 30px 30px 30px;
   }

   .bv-cards-content .bv-cards-title{
    font-size: 30px;
    font-weight: 500;
   }

   .bv-meeting-cards .bv-slider > ul {
    min-height: 430px;
    min-width: 880px;
   }

   .bv-meeting-cards .bv-slider > ul > li {
    width:31.7%;
    margin: 20px 0.8%;
   }
   .lastrow > div{
    overflow: hidden;
   }
   .lastrow .additional-day .day{
    display: none;
   }
   .lastrow .additional-day .bv-calendar-dot{
       height: 12px;
       width: 12px;
   }

   .bv-calendar-content loader{
    background: url('../assets/images/loader_calendar.gif');
    height: 32px;
       width: 32px;
       display: block;
       margin: 0 auto;
       top: calc(50% - 32px);
       position: relative;
   }

   /* Windows screen - calcendar font and circle size */

   @media screen and (max-height: 700px) {
    .bv-calendar-content .parent .row .cell .daysplit{
        font-size: 12px;
        line-height: 20px;
        width: 20px;
        height: 20px;
    }
    .bv-calendar-content .parent .row .cell .day {
        padding: 0px;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        width: 20px;
        height: 20px;
        margin: 2px auto;
        border-radius: 20px;
        color: #000;
        opacity: 0;
        font-weight: 500;
    }

    .bv-calendar-dot {
        height: 12px;
        width: 12px;
        background: #ccc;
        margin: 0 auto;
        border-radius: 50%;
        position: absolute;
        top: 66%;
        left: calc(50% - 6px);
        opacity: 0;
    }
   }
   @media screen and (max-height: 800px) {
    .rowplus .bv-calendar-dot {
        height: 12px;
        width: 12px;
        background: #ccc;
        margin: 0 auto;
        border-radius: 50%;
        position: absolute;
        top: 66%;
        left: calc(50% - 6px);
        opacity: 0;
    }
   }
   /* calendar.css */

   /* directory.css */
   .attachmentCheckBox {
   	height: 59px;
   	width: 100%;
   	padding-top: 9px;
   	padding-left: 6px;
   	margin: 0px;
   }

   .attachmentIcon {
   	height: 33px;
   }

   .attachmentIcon img {
   	height: 100%;
   	position: relative;
   	top: 3px;
   	left: 13px;
   }

   .attachmentIconText {
   	position: relative;
   	top: 8px;
   	left: 13px;
   }

   .attachmentIconTextFont {
   	font-weight: 300;
   	font-size: 20px;
   }

   .attachmentTitle {
   	display: block;
   	margin-top: 5px;
   	margin-left: 5px;
   }


   .list-checkbox {
   	background: url("../assets/images/checkbox.png") no-repeat;
   	height: 27px;
   	width: 27px;
   	margin-top: 19px;
   	float: right;
   }

   .list-checkbox.collate-checkbox {
   	top: 50%;
   	float: left;
   	position: absolute;
   	margin-top: -14px;
   }

   .signatureList ul li {
   	height: 125px;
   }

   bv-repository-list { overflow:hidden; padding: 20px 20px 20px 0px; }

   .documentsList { text-align:left; height:100%; position:relative;}

   .document-list-ul li:nth-child(odd) { background: #f7f9fa;}
   .document-list-ul li:hover { background:#ebeef0;}

          .documentsList ul li { height: 60px; padding-left:10px; padding-right: 15px;}
   .medium.documentsList ul li { height: 46px; }
    .small.documentsList ul li { height: 27px; }

   .documentsList ul li.checked { background:#dbdde1; cursor: pointer; }
   .documentsList ul li.checked:hover { background:#ebeef0;}

   /* .documentsList ul li.uncheckedClass { } */

   .documentsList-row
   {
   	display: block;
   	width: 100%;
   	height: 100%;
   	cursor: default;
   }

   .medium.documentsList .list-checkbox { margin-top: 11px; }
   .small.documentsList .list-checkbox { margin-top: 6px; }

   .list-checkbox.checked { background: url("../assets/images/checkbox_on.png") no-repeat; }
   .documentsList .list-checkbox.checked {	background: url("../assets/images/checkbox_on.png") no-repeat; }

          .documentsList .icon, #showSharedDocsDIV .icon { padding:9px 10px 0px 10px; display:block; float:left;}
   .medium.documentsList .icon, #showSharedDocsDIV .icon { padding:6px 8px 3px 3px; }
    .small.documentsList .icon, #showSharedDocsDIV .icon { padding:0 8px 0 0; }


    .documentsList .converting .icon{
        padding: 12px 10px 0px 10px;
    }
    .medium.documentsList .converting .icon{
        padding: 9px 8px 2px 3px;
    }
    .small.documentsList .converting .icon{
        padding: 2px 8px 0 0;
    }

   .documentsList .show-portal-name .icon, #showSharedDocsDIV .icon { position: relative;}

   .documentsList li[data-extensiontype=folder] .icon {
   	margin: 15px;
   }

   .documentsList .icon .iconImage img,
   .documentsList .icon .iconImage boardbook,
   .documentsList .icon .iconImage > div { width: 35px; height: 44px; float: left; }
   .medium.documentsList .icon .iconImage img,
   .medium.documentsList .icon .iconImage boardbook,
   .medium.documentsList .icon .iconImage > div { width: 27px; height: 33.94px; }
   .small.documentsList .icon .iconImage img,
   .small.documentsList .icon .iconImage boardbook,
   .small.documentsList .icon .iconImage > div { width: 15px; height: 23.88px; position:relative; top:4px;}

   .documentsList .converting .icon .iconImage img{
        width: 35px;
        height: 35px;
   }
   .medium.documentsList .converting .icon .iconImage > img {
        width: 27px;
        height: 27px;
   }
    .small.documentsList .icon .iconImage img{
        width: 15px;
        height: 15px;
    }

    documentsList .portal-name-lineheight.fileNameVal > span { padding-top: 10px;}
   .medium.documentsList .portal-name-lineheight.fileNameVal > span { padding-top: 5px;}
    .small.documentsList .portal-name-lineheight.fileNameVal > span { padding-top: 5px;}

   .documentsList .fileNameVal .name-span {cursor: pointer;display: block;}
   .small.documentsList .fileNameVal .name-span {display: inline;}
   .documentsList .fileNameVal .name-span:hover { text-decoration: underline;}
   .documentsList .fileNameVal .name-span.bv-open-disabled:hover { text-decoration: inherit; }

   .fileNameVal .virus-infected {
       font-style: italic;
       color: red;
   }

   .documentsList .fileSizeVal, .documentsList .fileDate, .documentsList .fileDateVal,
   	.documentsList .fileUploadedBy, .documentsList .fileUploadedByVal,
   	.documentsList .deliver {
   	font-size: 14px;
   }

   .documentlist-selection-text:hover { /*text-decoration: underline;*/}

   .medium.documentsList .fileSizeVal, .medium.documentsList .fileDate, .medium.documentsList .fileDateVal,
   	.medium.documentsList .fileUploadedBy, .medium.documentsList .fileUploadedByVal,
   	.medium.documentsList .deliver {
   	font-size: 12px;
   }

   .small.documentsList .small.fileSizeVal, .small.documentsList .fileDate, .small.documentsList .fileDateVal,
   	.small.documentsList .fileUploadedBy, .small.documentsList .fileUploadedByVal,
   	.small.documentsList .deliver {
   	font-size: 9px;
   }

   .documentsList .fileInfo.recycleFileInfo { width: 75%;}

   .documentsList .fileInfo .fileNameDiv {height: 100%;display: inline-block;width: 100%;}
   .documentsList .fileInfo .agendaFileNameDiv {height: 100%;display: inline-block;width: 100%;}

          .documentsList .fileInfo .fileDetailsDiv { font-size: 14px; display: inline-block; position: absolute; width: 210px;margin-top: 8px;}
   .medium.documentsList .fileInfo .fileDetailsDiv { line-height:15px;  font-size: 12px; width: 180px; margin-top: 8px;}
    .small.documentsList .fileInfo .fileDetailsDiv { line-height:12px;  font-size: 10px; margin-top: 6px;width: 280px;}

   		.documentsList ul li:hover .fileInfo .fileNameDiv { max-width: calc(100% - 245px);}
   		.documentsList ul li.checked .fileInfo .fileNameDiv { max-width: calc(100% - 245px);}

   		.small.documentsList ul li:hover .fileInfo .fileNameDiv { max-width: calc(100% - 280px);}
   		.small.documentsList ul li.checked .fileInfo .fileNameDiv { max-width: calc(100% - 280px);}

   		.documentsList ul li:hover .fileInfo .portal-name-span { max-width: calc(100% - 245px);}
   	.small.documentsList ul li:hover .fileInfo .portal-name-span { display: none;}

   		.documentsList ul li:hover .fileInfo .fileDetailsDiv .fileDetailsSpan { display: block; }
   		.documentsList ul li.checked .fileInfo .fileDetailsDiv .fileDetailsSpan { display: block; }

   /* 		.documentsList .fileInfo .document-menu span svg { width: 0; height: 0; } */
   /* 		.documentsList ul li:hover .fileInfo .document-menu { display: block; } */

   .documentsList .fileNameVal > svg{height: 15px;width: 11px;position: relative;fill: rgb(0, 0, 0);float:left;margin: 22px 5px 0 5px;display: none;
   }
   .medium.documentsList .fileNameVal > svg {margin: 15px 5px 0 5px}
    .small.documentsList .fileNameVal > svg {margin: 5px 5px 0 5px}


     .portal-name-lineheight.fileNameVal > svg{ margin: 15px 5px 0 5px ;}
   .medium.documentsList .portal-name-lineheight.fileNameVal > svg{ margin: 10px 5px 0 5px ;}
    .small.documentsList .portal-name-lineheight.fileNameVal > svg{ margin: 5px 5px 0 5px ;}

   .documentsList .fileNameVal > span{
   	float: left;
   	max-width: calc(100% - 30px);
   	cursor: pointer;
   	padding-top: 16px;
   	font-size:18px;
   	height: 100%
   }
    .medium.documentsList .fileNameVal > span{
   	padding-top: 13px;
   	font-size:14px;
   }
    .small.documentsList .fileNameVal > span{
   	padding-top: 6px;
   	font-size:11px;
   }

    .documentsList .portal-name-lineheight.fileNameVal > span { padding-top: 10px;}
   .medium.documentsList .portal-name-lineheight.fileNameVal > span { padding-top: 6px;}
    .small.documentsList .portal-name-lineheight.fileNameVal > span { padding-top: 6px;}


   .documentsList .fileNameVal { display: block;height: 100%;}

   .documentsList .fileNameVal .portal-name-span{
       font-size: 12px;
       left: 0;
       color: #868585;
       top: 34px;
       display: block;
       position: absolute;
   }

   .medium.documentsList .fileNameVal .portal-name-span{
   	 top: 24px;
   	 font-size: 11px;
   	}

   .small.documentsList .fileNameVal .portal-name-span{
       display: inline;
       position: inherit;
       line-height:15px;
   }

   .documentsList .fileNameVal .default-template-span {
       font-size: 12px;
       position: absolute;
       left: 0;
       color: #868585;
       top: 37px;
   }

   .medium.documentsList .fileNameVal .default-template-span {
   	 top: 28px;
   	 font-size: 11px;
   	}

   .small.documentsList .fileNameVal .default-template-span {
    	 top: 20px;
    	 font-size: 9px;
   }

   .documentsList ul li:hover .fileNameVal > svg {display: block;}

   .documentsList .fileInfo .fileDetailsDiv .fileDetailsSpan {display: none;}
   .documentsList .fileInfo .fileDetailsDiv .fileDetailsSpan .fileByName{ display: block;padding-right: 50px}
   .small.documentsList .fileInfo .fileDetailsDiv .fileDetailsSpan > span{ display: inline; }

          .documentsList .fileInfo { height:55px; width: calc(100% - 85px); color: #000; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; float: left;}
   .medium.documentsList .fileInfo { height:42px; width: calc(100% - 70px);}
    .small.documentsList .fileInfo { height:27px; width: calc(100% - 60px); }

    .documentsList .show-portal-name .fileInfo {position: relative;}

                         .bv-repository-checkbox { margin-top:22px; height:27px; width:27px; float:right;}
   .medium.documentsList .bv-repository-checkbox {	margin-top:14px; height:22px; width:22px;}
    .small.documentsList .bv-repository-checkbox { margin-top:7px;  height:17px; width:17px; }

                        .bv-tree-node { padding-left: 20px; line-height: 59px; height: 60px; }
   .medium.documentTree .bv-tree-node { padding-left: 20px; line-height: 45px; height: 45px; }
    .small.documentTree .bv-tree-node { padding-left: 20px; line-height: 33px; height: 30px; }

   .bv-tree-node-selected { background:#dbdde1;}
   .bv-tree-node:hover { background: #eaedee; cursor:pointer; }

                        .bv-tree-node .treeImage { height: 60px; padding:7px 10px 13px 0px; display:inline-block; position:relative; }
   .medium.documentTree .bv-tree-node .treeImage { height: 45px; padding:5px 10px 13px 0px; }
    .small.documentTree .bv-tree-node .treeImage { height: 33px; padding:3px 10px 13px 0px; }

                        .bv-tree-node .treeImage img,
                        .bv-tree-node .treeImage > div { padding:0px; width:35px; height: 44px; }
   .medium.documentTree .bv-tree-node .treeImage img,
   .medium.documentTree .bv-tree-node .treeImage > div { padding:0px; width:27px; height: 33.94px; }
    .small.documentTree .bv-tree-node .treeImage img,
    .small.documentTree .bv-tree-node .treeImage > div { padding:0px; width:19px; height: 23.88px; }

   /*.bv-tree-node-name {font-size:35px; display:inline-block;position:absolute;text-overflow:ellipsis; overflow:hidden; white-space:nowrap; }*/
   .bv-tree-node .bv-tree-node-name {font-size:18px; display:inline-block;position:absolute;text-overflow:ellipsis; overflow:hidden; white-space:nowrap;}
   .medium.documentTree .bv-tree-node .bv-tree-node-name {font-size:14px;}
   .small.documentTree .bv-tree-node .bv-tree-node-name {font-size:11px;}

   .bv-tree-node-name:hover  { text-decoration: underline; }

   .bv-tree-minimize-div {
   	cursor:pointer;
   	position: absolute;
   	height: 20px;
   	width: 20px;
   	border: 0px solid blue;
   	top: 0;
   	display: block;
   	z-index: 3;
   }
   .bv-tree-minimize-div-showTree {
   	left: 10px;
   }
   .bv-tree-minimize-div-hideTree {
   	left:239px;
   }
   .bv-tree-minimize-div-hideTree > .bv-tree-caret {
   	right: 0px;
   	position: absolute;
   	top: 7px;
   	border-top: 12px solid #E5E5E5;
   	border-right: 9px solid transparent;
   	border-left: 9px solid transparent;
   }
   .bv-tree-minimize-div-showTree > .bv-tree-caret {
   	right: 3px;
   	position: absolute;
   	top: 7px;
   	border-top: 9px solid transparent;
   	border-bottom: 9px solid transparent;
   	border-left: 12px solid #E5E5E5;
   }

   .breadcrumb {
   	padding: 3px 15px 0px;
   	list-style: none;
   	border-radius: 4px;
   	margin: 0;
   	background: none;
   }

   .breadcrumb a {
   	color: #FFF;
   	text-decoration: none;
   	font-weight: 300;
   }

   .breadcrumb-currentFolder {
   	margin: 0px 300px 0px 8px;
   	overflow: hidden;
   	text-overflow: ellipsis;
   	white-space: nowrap;
   	font-size: 2.1em;
   	color: #fff;
   	font-weight: 100;
   }

   .createFolder-div {
   	width: auto;
   	min-height: 68px;
   	max-height: none;
   	height: auto;
   }

   .small-dialog-class {
   	min-height: 150px;
   }

   .small-alert-dialog-class {
   	min-height: 70px;
   	padding-top: 20px;
   }

   .small-version-class {
   	min-height: 300px;
   }

   .createFolder-div form {
   	display: block;
   	margin-top: 1em;
   }

   .popup-title {
   	font-size: 25px;
   	font-weight: bold;
   	margin: 0px;
   	padding: 0;
   	color: #515151;
   	margin-bottom: 30px;
   }

   .ui-dialog-buttonpane {
   	bottom: 12px;
   	position: absolute;
   	right: 12px;
   }

   /* .ui-dialog-buttonpane button{
       background-color: rgb(0,72,118);
       color: #FFFFFF;
       border: 0px;
   } */
   .ui-dialog-buttonpane span {
   	padding: 4em;
   }

   .rightClickedOn {
   	background: #dbdde1 !important;
   }

   .rightClickMenu {
   	display: block;
   	position: fixed;
   	z-index: 1000;
   	float: left;
   	min-width: 210px;
   	padding: 5px 0;
   	margin: 2px 0 0;
   	font-size: 14px;
   	list-style: none;
   	background-color: #fff;
   	background-clip: padding-box;
   	border: 1px solid #ccc;
   	border: 1px solid rgba(0, 0, 0, .15);
   	border-radius: 0px;
   	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
   	box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
   	max-height: 551px;
   	overflow-y: hidden;
   	overflow-x: hidden;
   }

   .rightClickMenu:hover {
   	overflow: auto;
   }

   .rightClickMenu ul li  {
   	padding: 0px 15px 0px 15px;
   	overflow-x: hidden;
   	display: block;
   	height: 27px !important;
   }

   .rightClickMenu ul {
   	margin: 0px 0px 0px 0px;
   	position: relative;
   	line-height: 27px;
   	padding: 0;
   	overflow-x: hidden;
   }

   .rightClickMenu ul li.menu-ul-seprator-height
   {
   	height: 9px !important;
   	pointer-events: none;
   }

   .rightClickMenu ul li hr
   {
   	margin-top: 4px;
   	margin-bottom: 0px;
   }

   .rightClickMenu-a {
   	color: #000;
   	text-decoration: none;
   }

   .rightClickMenu a {
   	color: #000;
   	text-decoration: none;
   	font-weight: 300;
   	display: block;
   	white-space: nowrap;
   }

   .rightClickMenu bt {
   	color: #000;
   	text-decoration: none;
   	font-weight: 300;
   	display: block;
   	white-space: nowrap;
   }

   .fileChooser {
   	/* height: auto; */
   	height: 371px;
   	width: 420px;
   	display: block;
   	min-height: 372px;
   }

   p.pOne {
   	font-size: 25px;
   	font-weight: bold;
   	margin: 0;
   	padding: 0;
   	color: #515151;
   	margin-bottom: 30px;
   }

   p.pThree {
   	color: #515151;
   	font-size: 13px;
   	margin: -30px 0px 15px 0px;
   	font-weight: 300;
   }

   .treeDiv {
   	clear: left;
   	background: #fff;
   	border: 1px solid #ccc;
   	padding: 10px;
   	border-radius: 3px;
   	margin-bottom: 5px;
   	max-height: 280px;
   	overflow: auto;
   }

   .showTreeClass {
   	/*width: 79.5%;*/
   	width:calc(100% - 251px);	/* 1px width is of ruler */
   }

   .hideTreeClass {
   	width: 100% !important;
   }

   .treeDoc {
   	float: left;
   	position: relative;
   	width: 250px;
   	height: 100% ;
   	overflow: hidden;
   	/*margin-top:15px;*/
   }

   .treeDoc:hover {
   	overflow-y: auto;
   }

   .docmain {
   	height: 100%;
   	width: 100%;
   	margin-top: 9px;
   }

   .collatedocImg img {
   	height: 38px;
   }

   .shareDIV {
   	width: 26px;
   	height: 19px;
   	float: left;
   	margin: 3px 10px;
   	cursor: pointer;
   	background: url("../assets/images/people_share.png") no-repeat;
   }

   .expandDoc {
   	width: 30px;
   	height: 30px;
   	position: absolute;
   	left: 231px;
   	top: 21px;
   	cursor: pointer;
   }

   .small-file-list {
   	color: #6D6D6D;
   	width: 350px;
   	float: left;
   	overflow: hidden;
   	text-overflow: ellipsis;
   	padding-left: 10px;
   }

   .small-version-list {
   	color: #000;
   	width: 300px;
   	float: left;
   	overflow: hidden;
   	text-overflow: ellipsis;
   	padding-left: 10px;
   	float: left;
   }

   .upload-small-icon {
   	float: left;
   	position: relative;
   	top: 4px;
   }

   .upload-small-icon img {
   	min-height: 34px;
   	float: left;
   	padding-left: 4px;
   	max-height: 38px;
   }

   .badgeImg {
   	position: absolute;
   	bottom: 0px;
   	left: 0;
   	height: 18px !important;
   	width: 26px !important;
   }

   .version-semiMedium-icon img {
   	min-height: 65px;
   	float: left;
   	padding-left: 4px;
   	max-height: 65px;
   }

   .version-semiMedium-icon {
   	float: left;
   	margin-top: 3px;
   }

   .small-file-name-val {
   	font-size: 11px;
   	position: relative;
   	display: block;
   }

   .small-file-size-val {
   	top: -5px;
   }

   .hideFolderClass {
   	opacity: 0.25;
   }
   .hideFolderNameClass {
   	opacity: 0.35;
   }

   .holder-uploadfile {
   	height: 270px;
   	/*overflow: hidden;*/
   	margin: 20px;
   }

   .holder-uploadfile-row {
   	height:66px;
   }

   .holder-uploadfile-row .icon {
   	padding: 10px;
     	display: block;
     	float: left;
     	position: relative;
   }

   .holder-uploadfile-row .closeIcon {
        height: 20px;
        width: 20px;
        float: right;
        margin-top: 19px;
        display: none;
        position: relative;
    }

    .holder-uploadfile li:hover .holder-uploadfile-row .closeIcon{
    	display: inline-block;
    }

   .holder-uploadfile-row .icon .iconImage img,
   .holder-uploadfile-row .icon .iconImage > div{
   	width: 35px;
   	height:	45px;
   	float: left;
   	height: 45px;
   }

   .holder-uploadfile .fileInfo {
   	width: calc(100% - 85px);
   	float: left;
   }

   .holder-uploadfile .selectMsg{
   	height: 100%;
     	width: 100%;
     	text-align: center;
     	padding-top: 113px
   }

   .replaceFileSelectMsg {
   	padding-top: 22px !important;
   }

   .holder-uploadfile .fileNameDiv {
   	color: #333;
   	padding-top: 21px;
   	text-overflow: ellipsis;
   	overflow: hidden;
   	white-space: nowrap;
   	margin-right: 50px;
   	width:100%;
   }

   .holder-replacefile-height {
   	height: 60px;
   }

   .holder-uploadfile > ul {
   	height:100%;
   	overflow:hidden;
   	display: block;
   	/*padding-right:20px;*/
   }

   .holder-uploadfile-replace {
   	overflow:hidden !important;
   	padding-right:0px !important;
   }

   .holder-uploadfile > ul:hover {
   	overflow-y:auto;
   }

   .holder-uploadfile > ul > li {
   	width: 100%;
   	height: 66px;
   	border-bottom: 1px solid #e5e5e5;
   }

   .closeBtn {
   	width: 8px;
   	height: 8px;
   	background-image: url(../assets/images/popup_collate_remove-item-X.png);
   	cursor: pointer;
   	position: relative;
   	/* right: 33px; */
   	top: 16px;
   	margin-right: 10px;
   	float: right;
   	display: inline-block;
   }



   .ngDialog-properties-Width .ngdialog-content {
   	width: 605px !important;
   }

   .holder-version-tracker .list-checkbox.checked {
   	background: url("../assets/images/checkbox_on.png") no-repeat;
   	height: 27px;
   	width: 27px;
   	float: right;
   	margin-top: 30px;
   	margin-right: 5px;
   }

   .discussionAttachment .icon .iconImage img {
   	max-height: 38px;
   	float: left;
   	padding-left: 5px;
   	padding-right: 5px;
   }

   .attachmentTreePostition {
   	position: relative;
   	left: -25px;
   }

   .attachmentTreePostition ul {
   	display: block;
   }

   .uploadFileProgressBar {
   	height: 100%;
   	background-color: grey;
   	border-radius: 0px !important;
   }

   .uploadFileProgressBar >div {
   	height: 44px;
       padding-top: 11px;
   }

   .uploadFileProgressBar >div >span {
     font-size: 16px;
     font-weight: 400;
   }

   .viewerDialogClass {
   	border: 1px solid #808080 !important;
   	top: 1% !important;
   	left: 1% !important;
   	height: 97% !important;
   	min-height: 490px;
   }

   .viewerMobileDialogClass{
       border: 1px solid #808080 !important;
       top: 0 !important;
       left: 0 !important;
       height: 100% !important;
       width: 100% !important;
       min-height: 250px;
   }

   .viewerDialog.ui-dialog-content,
   .agendaDialog.ui-dialog-content {
   	bottom: 0 !important;
   	left: 0 !important;
   	overflow: hidden !important;
   	padding: 0 !important;
   	position: absolute !important;
   	right: 0 !important;
   	top: 0 !important;
   	background: #dadada !important;
   }

   /*.viewerPresentMode.ui-dialog-content {
       border-top: solid 10px #22a63e !important;
   }*/

   .folderViewer {
   	width: 100%;
   	/* height: 320px; */
       overflow: hidden;
       height: 560px;
       border: 1px solid #D1D1D1;
   }

   /*.folderViewer:hover {
   	overflow-y: auto;
   }*/

   .repositoryLeft {
   	float: left;
   }

   .document-placeholder-highlight {
   	height: 35px;
   	background-color: #CCCCCC;
   }

   /* iPhone Portrait */
   @media screen and (max-device-width: 480px) and (orientation:portrait) {
   	.documentsList .fileSizeVal, .documentsList .fileDate, .documentsList .fileDateVal,
   		.documentsList .fileUploadedBy, .documentsList .fileUploadedByVal,
   		.documentsList .deliver {
   		font-size: 10px;
   		font-weight: lighter;
   		font-weight: 100;
   		letter-spacing: 0.3px;
   	}
   }

   .dragOverBackground {
   	background: #E8E8E8;
   }
   
   .dragDropEvent{
		position:relative;
		z-index:999;	
	}
	.dragDropEvent ul{
			position:relative;
			z-index:-1;
		}
	.documentsList.dragDropEventInner{
		z-index:11;
	}
	.documentsList.dragDropEventInner ul{
		z-index:12;
	}
   .showDocumentViewer {
   	display: block;
   }

   .hideDocumentViewer {
   	display: none;
   }

   .collate-centered-div {
   	margin-left: auto;
   	margin-right: auto;
   	width: 90%;
   	margin-bottom: 10px;
   }

   .collate-save-folder {
   	line-height: 33px;
   	margin-right: 0.5em;
   }

   .collate-title-input {
   	width: 100%;
   }

   .collate-list-heading {
   	margin-top: 5px;
   }

   .collate-seperator-label {
   	padding-left: 30px;
   }

   .collate-seperator-label a {
   	color: #2273a5;
   }

   .collate-row {
   	padding-bottom: 35px;
   }

   .collate-row .drag-handle {
   	top: 9px !important;
   }

   .collate-row:hover .drag-handle-image{
   	display: block;
   }

   .collate-box img {
   	width: 26px;
   	/*border: 1px solid #d1d1d1;*/
   	margin-left: auto;
   	margin-right: auto;
   }

   .collate-box.collate-seperator-box {
   	width: calc(26% - 13px);
   	text-align: left;
   	/*padding-left: 8px;*/
   	margin-right: 15px;
   }

   .collate-box.collate-document-box .fileNameVal {
   	max-width: calc(100% - 50px);
   	display: inline-block;
   }

   .collate-box.collate-document-box .fileNameVal:hover {
   	cursor: pointer;
   	text-decoration: underline;
   }

   .collate-box.collate-document-box {
   	width: calc(74% - 31px);
   	text-align: left;
   	margin-left: 15px;
   }

   .collate-box {
   	/*background:#CCC;*/
   	width: 10%;
   	float: left;
   	text-align: center;
   	/*border: 1px solid #D1D1D1;*/
   	height: 35px;
   	line-height: 35px;
   	position: relative;
   }

   .collate-remove-itm-btn {
   	width: 17px;
   	height: 17px;
   	position: absolute;
   	cursor: pointer;
   	/* margin-top: 22px; */
   	display: inline;
   	right: 20px;
   	top: 10px;
   }
   .collate-remove-itm-btn-hidden {
   	width: 17px;
   	height: 17px;
   	position: absolute;
   	cursor: pointer;
   	/* margin-top: 22px; */
   	display: inline;
   	right: 20px;
   	top: 10px;
   	fill:black;
   	visibility:hidden;
   }
   .collateRowHover:hover .collate-remove-itm-btn-hidden
   {
   	fill:#CCCCCC;
   	visibility:visible;
   }
   .collate-remove-itm-btn-hidden:hover
   {
   	fill: black !important;
   	visibility:visible;
   }
   .collate-document-box svg:hover #removeButtonsvg{
   	fill:black;
   	visibility:visible;
   }

   .collate-plusIcon-itm-btn {
   	width: 21px;
   	height: 21px;
   	position: absolute;
   	cursor: pointer;
   	/* margin-top: 22px; */
   	display: inline;
   	right: 20px;
   	top: 5px;
   	fill:#2574A7;
   }
   .collate-folderIcon-itm-btn {
   	width: 20px;
   	height: 20px;
   	cursor: pointer;
   	/* margin-top: 22px; */
   	display: inline;
   	color: #2574A7;
   	position:relative;
   	top:6px;
   	fill:#2574A7;

   }

   .collate-check-div {
   	top: 50%;
   	float: left;
   	position: absolute;
   	margin-top: -10px;
   }

   .collate-notice {
   	position: absolute;
   	bottom: 20px;
   	left: 20px;
   }

   .as-sortable-item, .as-sortable-placeholder {

   }

   .rulesDeleteUserBtn {
   	color: #000;
   	min-width: 19px;
   	max-width: 19px;
   	background-color: rgb(190, 185, 185);
   	border-style: none;
   	max-height: 19px;
   	min-height: 19px;
   	border-radius: 50%;
   	float: left;
   	background-image:
   		url("../assets/images/BVLiveryBriefcaseDownloadCancel.png");
   	background-repeat: no-repeat;
   	background-position: 50% 50%;
   	background-size: 12px auto, 12px auto;
   	margin-top: 5px;
   	margin-left: 4px;
   	padding-top: 3px;
   	white-space: normal;
   	width: 23px;
   	height: 25px;
   	vertical-align: -webkit-baseline-middle;
   }

   .bv-attachment-body {
   	width: 100%;
   	padding: 10px;
   	height: 500px;
   	overflow: hidden;
   }

   .bv-atchmnt-tree {
   	top: 17px;
   	border-right: 1px solid #e5e5e5;
   	height: 460px;
   }

   .bv-atchmnt-filter {
   	float: right;
   	width: 77%;
   }

   .atchmnt-list-height {
   	height: 390px;
   	width: 80%;
   }

   .attchmnt-checkbox-margin {
   	margin-top: 11px;
   }

   .bv-copy-link-confirm .modal-dialog {
   	width: 50%;
   }

   .bv-copy-link-confirm .bv-confirm-content {
   	word-wrap: break-word;
   	font-style: oblique;
   	color: blue;
   	cursor: pointer;
   	width: 100%;
   	padding: 10px 10px;
   }

   .copy-link-btn {
   	position: absolute;
   	bottom: 20px;
   	right: 20px;
   }

   .copy-link-status {
   	position: absolute;
   	bottom: 20px;
   	left: 20px;
   }

   .bv-file-size-confirm .modal-dialog {
   	width: 400px !important;
   }

   .bv-file-size-confirm .bv-confirm-content {
       white-space: pre-wrap;
       overflow: hidden;
       text-overflow: ellipsis;
       text-align: center;
   }

   .bv-files-not-uploaded .modal-dialog {
   	width: 400px;
   }

   .bv-files-not-uploaded .bv-confirm-content {
   	text-align: left;
       white-space: pre;
       overflow: hidden;
       text-overflow: ellipsis;
   }

   .bv-modal-window-repository-upload-file .modal-dialog {
   	width: 450px;
   	height: 350px;
   	position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important;
   }

   .bv-modal-window-repository-replace-file .modal-dialog {
   	width: 450px;
   	height: 185px;
   	position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important;
   }

   .document-list-container {
   	height: 100%;
   	overflow-y: auto;
   }

   .document-list-container-filter {
   	height: calc(100% - 38px);
   	width: 100%;
   }

   .document-list-container:hover {
   }

   .vruler-document-list {
   	width: 0px;
   	height: 100%;
       margin-top:0px;
       position: relative;
       cursor: col-resize;
       padding: 0px 5px;
       margin: 0px -5px;
       z-index: 1;
   }
   .vruler-document-list::after{
   	content: "";
   	height: 100%;
       width: 1px;
       display: block;
       background-color: #e5e5e5;
   }

   .vruler-document-list-filter{
     margin-top: 23px;
     height: calc(100% - 23px);
   }

   .document-filter-container {
   	padding-left: 20px;
   	margin-bottom: 20px;
   }

   .attachment-list {
   	height: 485px;
   }

   .bv-modal-window-new-attachment .modal-dialog {
   	min-width: 750px;
   	width: 750px;
   }


   .bv-dialog-selected-attachments-list {
   	right: -300px;
   	height: 100%;
   	position: absolute;
   	top: 0px;
   	z-index: -1;
   	width: 300px;
   	background: #fff;
   	overflow: hidden;
   	font-size: 14px;
   }

   .bv-dialog-selected-attachments-list ul {
   	height: calc(100% - 30px);
   	margin: 15px 0px 0 0;
   	/* padding-bottom: 15px; */
   	width: 300px;

   }
   .bv-dialog-selected-attachments-list .mCSB_scrollTools_vertical {
   	right: -1px;
   }

   .selected-item {
   	height: 35px;
   	line-height: 35px;
   	padding-left: 15px;
   }

   .selected-item span{
   	display: inline-block;
   	width: calc(100% - 35px);
   }

   .selected-item .btn-remove {
   	padding-top: 0px;
   	display: none;
   	position: absolute;
   	right: 6px;
   	cursor: pointer;
   }

   .selected-item:hover .btn-remove {
   	display: inline-block;
   	opacity: .5;
   }
   .btn-remove:hover {
       display: inline-block;
       opacity: 1 !important;
   }
   .bv-modal-window-properties .modal-dialog {
   	min-width: 600px;
   	width: 600px;
   	margin-top: 110px;
   }

   .searchDocument
   {
     height: 30px;
     padding: 0px 10px 0px 10px;
     border: 1px solid #EEE;
     width: 100%;
     background: #EEE;
     font-size: 16px;
     display: block;
   }

   .folder-attachment-dialog .modal-dialog
   {
     height: 470px;
     min-width: 500px;
     width: 500px;
     font-size: 14px;
   }

   .folder-attachment-container
   {
     height: 370px;
     overflow: hidden;
     margin: 15px 20px 29px 10px;
   }

   .folder-attachment-container:hover {
   	overflow-y: auto;
   }

   .folder-attachment-treeNode-name-div {
   	text-overflow: ellipsis;
   	overflow: hidden;
   	white-space: nowrap;
   	max-width: 350px;
   	float:left;
   	padding-bottom: 7px;
   	position: relative;
   	margin-top: 15px;
   }
   .folder-attachment-dialog .treeNode .treeImage img{
   	max-width: 37px;
   }

   .folder-attachment-radio
   {
   	width: 22px;
       height: 22px;
       position: relative;
       float: right;
       margin-top: 18px;
       display: inline-block;
   }

   .collate-files {
   	border-top: 1px solid #e5e5e5;
   }

   .collateMain {
   	padding: 20px;
   	font-size: 16px;
   	overflow: hidden;
   }

   .collate-title-box {
   	white-space: nowrap;
   	margin-bottom: 15px;
   }

   .collate-title {
   	display: inline-block;
   	margin-right: 40px;
   }

   .collate-saveTo {
   	display: inline-block;
   	width: calc(50% - 87px);
   }

   .collate-actions {
   	position: absolute;
   	bottom: 15px;
   	right: 20px;
   }

   .collate-list-heading .collate-seperator-box {
   	border-bottom: 1px solid #e5e5e5;
   	line-height: 30px;
   }

   .collate-list-heading .collate-document-box {
   	border-bottom: 1px solid #e5e5e5;
   	line-height: 30px;
   }


   .collate-files-list{
   	overflow-x: hidden;
   	overflow-y: hidden;
   	height: calc(100% - 205px);
   	position: absolute;
   	width: calc(100% - 40px);
   	margin-top: 10px;
   }

   .collate-files-list:hover {
   	overflow-y: auto;
   }

   .collate-vertical-hr {
   	height: calc(100% - 165px);
   	width: 1px;
   	position: absolute;
   	left: calc(26% + 25px);
   	background-color: #e5e5e5;
   	margin-top: 10px;
   }

   .file-chooser-padding
   {
   	padding: 15px 15px 0px 0px;
   }


   /************Start of styles for Document Properties Popup ***********/
   .bv-doc-prop .modal-dialog { width:970px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important; }
   .bv-doc-prop-container-div { width:100%; height: 569px; font-weight: normal; color:#000000; margin: 0px; }
   .bv-doc-prop-col1 { height:100%; width:230px; float:left;  font-weight: 500;}
   .bv-doc-prop-col2 { height:100%; width:740px; float:right; display: inline-block; position: absolute; }
   .bv-doc-prop-col2 > div { padding:20px;}
   .bv-doc-prop-row { height:55px;line-height:55px;width:100%; font-size:20px; font-weight:400; cursor:pointer; display:block; text-align:left; margin-top:7px; margin-bottom:13px; padding: 0px 0px 0px 20px; }
   .bv-doc-prop-row-selected { background-color: #2273a5; color:#FFFFFF;}
   .bv-doc-prop-row-not-selected { color: #2273a5;}
   .bv-doc-prop-details-row1 { width:100%; height: 20px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap;}
   .bv-doc-prop-details-row2 { width:100%; height: 150px; }
   .bv-doc-prop-details-row2 > div { width:50%; height:145px; padding: 20px 0px 20px 175px;display: inline-block; }
   .bv-doc-prop-details-img { width: 90px; height: 115px; box-shadow: 0 0 10px 0 #888; border: 1px solid #d1d1d1;}
   .bv-doc-prop-details-row3 { width:100%; height: calc(100% - 175px);}
   .bv-doc-prop-details-row3 > ul > li { display:block; width:100%; margin-bottom:7px;line-height: 25px;height: 25px;}
   .bv-doc-prop-details-row3-col1 {height: 25px;width:24%;float:left;display:inline-block;text-align: right;font-weight: 500;}
   /* .bv-doc-prop-details-row3-col2 { height: 25px; width:76%; float:right; display:inline-block;text-align: left; padding:1px 0px 0px 10px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap;} */

   .bv-doc-prop-details-row3-col2{
       float: right;
       padding: 1px 0 0 10px;
       white-space: normal;
       word-wrap: break-word;
       width:76%;
       text-align: left;
       }

   .bv-doc-prop-perm-header { line-height:40px; height:40px; width:100%; }
   .bv-doc-prop-perm-details { line-height:490px; height:490px; width:100%; }
   .bv-doc-prop-perm-divider { height: 30px; line-height: 30px;width:1px; background: #E5E5E5; display: inline-block;}

   .bv-doc-prop-perm-value-li { display:block; height:30px;}
   .bv-doc-prop-perm-value-li-ul { }

   .bv-doc-prop-perm-header-type-li-div {width: 45px;border-bottom:1px solid #ccc;line-height:20px;height:27px;font-weight: 500;}
   .bv-doc-prop-perm-header-name-li-div {width: 500px;border-bottom:1px solid #ccc;line-height:20px;height:27px;font-weight: 500;}
   .bv-doc-prop-perm-header-perm-li-div {width:90px; border-bottom:1px solid #ccc;line-height:20px;height:27px; font-weight: 500;}

   .bv-doc-prop-perm-header > ul  { display:block; position:absolute; width:700px;}
   .bv-doc-prop-perm-details .scroll-list-items { display:block; position:absolute; width:700px; height:490px; overflow:hidden;}
   /*.bv-doc-prop-perm-details > ul:hover {overflow-y:auto;}
   */

   .bv-doc-prop-perm-li-type {line-height:30px;height:30px;width: 55px;display: block;position: absolute;}
   .bv-doc-prop-perm-li-type-icon {line-height:30px;height:30px;width: 45px;display: block;position: absolute;margin-left: 5px;margin-top: 5px;}
   .bv-doc-prop-perm-li-name {line-height:30px;height:30px;width: 500px;display: block;position: absolute;left: 70px;}
   .bv-doc-prop-perm-li-perm {line-height:30px;height:30px;width:110px;display: block;position: absolute;left: 595px;}

   .bv-doc-prop-per-li-type-right-border {
   	height: calc(100% - 42px);
   	width: 1px;
   	position: absolute;
   	left: 77px;
   	background-color: #e5e5e5;
   	margin-top: 0px;
   }

   .bv-doc-prop-per-li-name-right-border {
   	height: calc(100% - 42px);
   	width: 1px;
   	position: absolute;
   	left: 602px;
   	background-color: #e5e5e5;
   	margin-top: 0px;
   }

   .bv-doc-prop-perm-li-type-filler { line-height:10px; height:10px; width:90px;  display: block; position: absolute; top: 30px;}
   .bv-doc-prop-perm-li-name-filler { line-height:10px; height:10px; width:450px; display: block; position: absolute; top: 30px; left:120px;}
   .bv-doc-prop-perm-li-perm-filler { line-height:10px; height:10px; width:110px; display: block; position: absolute; top: 30px; left:580px;}

   .bv-doc-prop-perm-fav-panel0 { line-height:30px; height:30px; width:100%; margin-left: 5px;}
   .bv-doc-prop-perm-fav-panel0 > ul  { display:block; position:absolute; width:700px;}
   .bv-doc-prop-perm-fav-panel0 > ul > li { font-weight:500; line-height:25px; height:30px; width: 100%; display: block; position: absolute; border-bottom:1px solid #E5E5E5;}

   .bv-doc-prop-perm-fav-panel1 { height:470px;overflow:hidden;}
   .bv-doc-prop-perm-fav-panel2 { height:30px;overflow:hidden;}

   .bv-doc-prop-perm-fav-panel1 .scroll-list-items { display:block; position:absolute; width:700px; height:440px; margin-top:10px;margin-bottom:20px;overflow:hidden;}
   /*.bv-doc-prop-perm-fav-panel1 .scroll-list-items:hover {overflow-y:auto;}*/
   .bv-doc-prop-perm-fav-panel1 .scroll-list-items > li { display:block; height:40px;}
   .bv-doc-prop-perm-fav-panel1 .scroll-list-items > li:hover { background: #f4f4f4; }

   .bv-doc-prop-perm-fav-panel1-name { display:inline;float:left;font-weight:400; line-height:40px; width:calc(100% - 45px); text-overflow:ellipsis; overflow:hidden; white-space:nowrap; margin-left: 5px;}
   .bv-doc-prop-perm-fav-panel1-close-icon { display:inline;float:right;width:20px;height:20px;margin-top:7px;margin-right:0px;}
   .bv-doc-prop-perm-fav-panel1-close-icon > span {}
   .bv-doc-prop-perm-fav-panel1-close-icon > span > img { width:17px;height:17px; cursor:pointer;}

   /************End of styles for Document Properties Popup ************/

   /************Start of styles for Document Properties Popup ***********/
   .bv-rules-dialog .modal-dialog {height: 156px; min-width: 70px;width:340px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important; }
   .bv-rules-container-div   { width:100%; height:120px; font-weight: normal; color:#000000; margin: 0px; }
   .bv-rules-row1 {width:100%;height:calc(100% - 70px);padding: 20px 20px 0px 62px;}
   .bv-rules-chk-div { height:30px; width:30px; display:inline-block;}
   .bv-rules-delete-opt-div { display:inline-block; height:30px; top:19px; position:absolute;}
   .bv-rules-row2 {width:100%; height:70px;}
   .bv-rules-row2-button-div { margin: 20px 20px 20px 58px;}
   .bv-rules-text {   width:48px; margin-right:7px; margin-left:7px;}
   /************End of styles for Document Properties Popup ************/

   /************Start of styles for Document Properties Popup ***********/

   /************End of styles for Document Properties Popup ************/

   .bv-version-tracker-dialog .modal-dialog { min-width: 870px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important; }
   .bv-version-tracker-container-div   { width:100%; height:499px; font-weight: normal; color:#000000; margin: 0px; }
   .bv-version-tracker-row1 {width:100%;height:calc(100% - 70px);padding: 20px 20px 0px 20px;}
   .bv-version-tracker-row2 {width:100%;}
   .bv-version-tracker-row2-btn-div { height:100%; float:right; margin:20px 20px 20px 0px;}

   .bv-version-tracker-header { line-height:35px; height:35px; width:100%; }
   .bv-version-tracker-details { line-height:375px; height:375px; width:100%; }

   .bv-version-tracker-header > ul  { display:block; position:absolute; width:830px; font-weight:500;}
   .bv-version-tracker-details > ul { display:block; position:absolute; width:830px; height:370px; overflow:hidden;}
   .bv-version-tracker-details > ul:hover {overflow-y:auto;}


   .bv-version-tracker-li-version    { line-height:30px; height:30px; display: block; position: absolute; width:80px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap;cursor:pointer;}
   .bv-version-tracker-li-dtModified { line-height:30px; height:30px; display: block; position: absolute; width:215px; left:85px;  text-overflow:ellipsis; overflow:hidden; white-space:nowrap;cursor:pointer;}
   .bv-version-tracker-li-modifiedBy { line-height:30px; height:30px; display: block; position: absolute; width:195px; left:325px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap;cursor:pointer;}
   .bv-version-tracker-li-comments   { line-height:30px; height:30px; display: block; position: absolute; width:250px; left:540px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap;cursor:pointer;}
   .bv-version-tracker-li-selection  {
   	line-height: 30px;
       height: 30px;
       display: inline-block;
       position: relative;
       width: 23px;
       float: right;
       text-overflow: ellipsis;
       overflow: hidden;
       white-space: nowrap;
   }

   .bv-version-tracker-li-selection-div { margin-top:6px; height:25px; width:25px;}

   .bv-version-tracker-value-li { display:block; height:41px; padding-top:4px; border-bottom:1px solid #E5E5E5;}
   .bv-version-tracker-value-li-ul { }

   .bv-audit-trail-dialog .modal-dialog { min-width: 618px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important; }
   .bv-audit-trail-container-div   { width:100%; height:445px; font-weight: normal; color:#000000; margin: 0px; }
   .bv-audit-trail-row1 {width:100%;height:100%;padding: 20px 20px 20px 20px;}

   .bv-audit-trail-header { line-height:35px; height:35px; width:100%; }
   .bv-audit-trail-details { line-height:370px; height:370px; width:100%; }

   .bv-audit-trail-header > ul  { display:block; position:absolute; width:578px; font-weight:500;}
   .bv-audit-trail-details > ul { display:block; position:absolute; width:578px; height:370px; overflow:hidden;}
   .bv-audit-trail-details > ul:hover {overflow-y:auto;}

   .bv-audit-trail-li-user    { line-height:30px; height:30px; display: block; position: absolute; width:202px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap; padding-right: 10px;}
   .bv-audit-trail-li-action { line-height:30px; height:30px; display: block; position: absolute; width:192px; left:202px;  text-overflow:ellipsis; overflow:hidden; white-space:nowrap;}
   .bv-audit-trail-li-date { line-height:30px; height:30px; display: block; position: absolute; width:182px; left:394px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap;}
   .bv-audit-trail-value-li { display:block; height:41px; padding-top:4px; border-bottom:1px solid #ccc;}

   .bv-addToUserFavorites-dialog .modal-dialog { min-width: 680px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important; }
   .bv-addToUserFavorites-container-div   { width:100%; height:445px; font-weight: normal; color:#000000; margin: 0px; }
   .bv-addToUserFavorites-row1 {width:100%;height:100%;padding: 0 20px 10px 20px;}
   .bv-addToUserFavoritesDiv1 { float:left; display:inline-block;height:100%; width:60%; padding: 0px 10px 0px 0px; border-right:1px solid #E5E5E5;}
   .bv-addToUserFavoritesDiv2 { float:right; display:inline-block;height:100%; width:40%; padding: 0px 0px 0px 10px; border-right:1px gray;}
   .bv-addToUserFavoritesDiv-upSection { width:100%; height: 45px; font-weight: 500; color:#000000; margin: 0px; border-bottom:1px solid #E5E5E5; padding: 20px 0px 0px 0px;}
   .bv-addToUserFavoritesDiv-downSection { width:100%; height: calc(100% - 45px); color:#000000; margin: 0px; padding: 10px 0px 0px 0px; overflow-x: hidden; overflow-y: hidden;}
   .bv-addToUserFavoritesDiv-downSection:hover {overflow-y:auto; }
   .bv-addToUserFavorites-user {position: relative; height: 30px; margin-bottom: 5px;}
   .bv-addToUserFavorites-user:hover .favoritesDeleteIcon{opacity:0.2;visibility:visible;}
   .bv-addToUserFavorites-attachment {position: relative;height: 60px;margin-bottom: 20px;}
   .bv-addToUserFavorites-attachment:hover .drag-handle-image{display: block;}
   .bv-addToUserFavorites-attachment:hover .favoritesDeleteIcon{opacity:0.2;visibility:visible}
   .bv-briefcase-properties-users:hover .biefcase-properties-delete-icon
   {
   	opacity:0.5;
   	visibility:visible;
   }
   .bv-briefcase-properties-users{
   	height: 40px;
   }

   .biefcase-properties-delete-icon
   {
   	opacity:1;
   	visibility:hidden;
   }
   .biefcase-properties-delete-icon:hover
   {
   	visibility:visible !important;
   	opacity:1 !important;
   }


   .favoritesDeleteIcon
   {
   	visibility:hidden;
   	opacity:1;
   }
   .favoritesDeleteIcon:hover
   {
   	visibility:visible !important;
   	opacity:1 !important;
   }



   .bv-item-list-dialog-mobile .bv-item-list-main{
   	height: 100%;
   }
   .bv-item-list-dialog-mobile .bv-item-list-grid{
   	height: calc(100% - 68px);
       position: relative;
   }
   .bv-item-list-dialog-mobile .bv-item-list-ul1{
       width: 100%;
       height: 100%;
       overflow-y: auto;
   }
   .bv-item-list-dialog-mobile .bv-item-list-ul2 svg{
   	width: 22px;
   	height: 22px;
   }

   .treeNodeDiv
   {
   	height:46px;
   }

   /*Start of styles for Briefcase Intstallations Dialog*/
   .bv-briefcase-list .modal-dialog { width:970px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important; }
   .bv-briefcase-list-container-div { width:100%; height: 469px; font-weight: normal; color:#000000; margin: 0px; font-size: 12px;}
   .bv-briefcase-list-col1 { height:100%; width:160px; float:left;  font-weight: 500;}
   .bv-briefcase-list-col2 { height:100%; width:810px; float:right; display: inline-block; position: absolute; }
   .bv-briefcase-list-row { height:55px;line-height:55px;width:100%; font-size:20px; font-weight:400; cursor:pointer; display:block; text-align:left; margin-top:7px; margin-bottom:13px; padding: 0px 0px 0px 20px; }
   .bv-briefcase-list-row-selected { background-color: #2273a5; color:#FFFFFF;}
   .bv-briefcase-list-row-not-selected { color: #2273a5;}
   .bv-briefcase-btn-close {width: 14px; height: 14px; margin-left: 9px; cursor: pointer;}
   .bv-briefcase-btn-close:hover {fill: rgba(0,0,0, 1);}

   /*active tab*/
   .bv-briefcase-list-active-header { line-height:25px; height:30px; width:100%; background: #e6e6e6;}
   .bv-briefcase-list-active-details { line-height:430px; height:430px;}
   .bv-briefcase-list-active-details .mCSB_outside+.mCSB_scrollTools {right: -2px;}
   .bv-briefcase-list-active-value-li { display:block; height:30px; line-height: 10px; padding-top: 10px;}
   .bv-briefcase-list-active-value-li:nth-child(even) {background: rgb(247, 249, 250);}
   .bv-briefcase-list-active-value-li-ul { }

   .bv-briefcase-list-active-header-id-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px; margin-left: 10px;}
   .bv-briefcase-list-active-header-host-addr-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}
   .bv-briefcase-list-active-header-host-name-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}
   .bv-briefcase-list-active-header-os-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}
   .bv-briefcase-list-active-header-user-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}
   .bv-briefcase-list-active-header-date-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}
   .bv-briefcase-list-active-header-purge-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}

   .bv-briefcase-list-active-header > ul  { display:block; position:absolute; width:700px;}
   .bv-briefcase-list-active-details .scroll-list-items { display:block; position:absolute; width:99.5%; height:430px; overflow:hidden;}

   .bv-briefcase-list-active-li-id { width:75px; display: block; position: absolute; min-height: 16px;}
   .bv-briefcase-list-active-li-host-addr { width:85px;  display: block; position: absolute; left: 100px; min-height: 16px;}
   .bv-briefcase-list-active-li-host-name { width:150px;  display: block; position: absolute; left: 200px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; min-height: 16px;}
   .bv-briefcase-list-active-li-os { width:115px;  display: block; position: absolute; left: 365px; min-height: 16px;}
   .bv-briefcase-list-active-li-user { width:90px;  display: block; position: absolute; left: 490px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; min-height: 16px;}
   .bv-briefcase-list-active-li-date { width:140px;  display: block; position: absolute; left: 585px; min-height: 16px;}
   .bv-briefcase-list-active-li-purge { width:35px;  display: block; position: absolute; left: 750px; min-height: 16px;}

   .bv-briefcase-list-active-li-id.ng-binding {left: 10px; width: 66px; background: none;}

   /*purge tab*/
   .bv-briefcase-list-purge-header { line-height:25px; height:30px; width:100%; background: #e6e6e6;}
   .bv-briefcase-list-purge-details { line-height:430px; height:430px;}
   .bv-briefcase-list-purge-details .mCSB_outside+.mCSB_scrollTools {right: -2px;}
   .bv-briefcase-list-purge-value-li { display:block; height:30px; line-height: 10px; padding-top: 10px;}
   .bv-briefcase-list-purge-value-li:nth-child(even) {background: rgb(247, 249, 250);}
   .bv-briefcase-list-purge-value-li-ul { }

   .bv-briefcase-list-purge-header-id-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px; margin-left: 10px;}
   .bv-briefcase-list-purge-header-host-addr-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}
   .bv-briefcase-list-purge-header-host-name-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}
   .bv-briefcase-list-purge-header-os-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}
   .bv-briefcase-list-purge-header-user-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}
   .bv-briefcase-list-purge-header-date-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}
   .bv-briefcase-list-purge-header-purge-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}

   .bv-briefcase-list-purge-header > ul  { display:block; position:absolute; width:700px;}
   .bv-briefcase-list-purge-details .scroll-list-items { display:block; position:absolute; width:99.5%; height:430px; overflow:hidden;}

   .bv-briefcase-list-purge-li-id { width:75px;  display: block; position: absolute; min-height: 16px;}
   .bv-briefcase-list-purge-li-host-addr { width:85px;  display: block; position: absolute; left: 100px; min-height: 16px;}
   .bv-briefcase-list-purge-li-host-name { width:150px;  display: block; position: absolute; left: 200px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; min-height: 16px;}
   .bv-briefcase-list-purge-li-os { width:115px;  display: block; position: absolute; left: 365px; min-height: 16px;}
   .bv-briefcase-list-purge-li-user { width:90px;  display: block; position: absolute; left: 490px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; min-height: 16px;}
   .bv-briefcase-list-purge-li-date { width:140px;  display: block; position: absolute; left: 585px; min-height: 16px;}
   .bv-briefcase-list-purge-li-purge { width:80px;  display: block; position: absolute; left: 726px; min-height: 16px; text-align: center;}

   .bv-briefcase-list-purge-li-id.ng-binding {left: 10px; width: 66px; background: none;}

   /*End of styles for Briefcase Intstallations Dialog*/
   .spanUnclickable
   {
   	text-decoration: none !important;
   }
   .spanUnclickable:hover
   {
   	cursor: default;
   }
   /*Start of styles for Agenda Builder Dialog*/
   .agendaDialog .modal-dialog
   {
   	height: 97%;
   	margin: 0.1%;
   	min-width: 300px;
   	min-height: 300px;
   	overflow: hidden;
   	width: 98%;
   }
   .agendaDialog .modal-content
   {
   	height: 100%;
   	width: 100%;
   }
   .agenda-file-chooser-header
   {
   	background-color: #2273a5;
   	width:400px;
   	height:40px;
   	position:absolute;
   	top:0;
   }
   .agendaIcon
   {
   	position: absolute;
   	width: 23px;
   	height: 23px;
   	top: 9px;
   	fill:#fff;
   	cursor: pointer;
   }
   #saveLinksFade {
       display: none;
       position: absolute;
       top: 0%;
       left: 0%;
       width: 100%;
       height: 100%;
       background-color: #ababab;
       z-index: 10001;
       -moz-opacity: 0.6;
       opacity: .60;
       filter: alpha(opacity=60);
   }
   #openAgendaFade {
       display: none;
       position: absolute;
       top: 0%;
       left: 0%;
       width:calc(100% - 400px);
       height: 100%;
       background-color: #ababab;
       z-index: 10001;
       -moz-opacity: 0.6;
       opacity: .60;
       filter: alpha(opacity=60);
   }
   #saveLinksModal {
       display: none;
       position: absolute;
       top: 45%;
       left: 45%;
       width: 74px;
       height: 74px;
       box-shadow: 0px 0px 15px #505050;
       border-radius: 8px;
       background-color: #000000;
       z-index: 10002;
       text-align: center;
       overflow: hidden;
       font-size:  12px;
       color: #FFFFFF
   }

   #saveLinksModal > span
   {
     position: relative;
     top: 10px;
     letter-spacing: .7px;
   }

   #saveLinksModal > img
   {
     position: relative;
     margin: 0 auto;
     top: 20px;
   }
   img.addDocLink {
       height: 15px;
       width: 18px;
       cursor: pointer;
   }
   span.spacer {
       width: 27px;
       height: 15px;
       float: left;
       position: relative;
   }
   /*End of styles for Agenda Builder Dialog*/

   /*Start of styles for signature status dialog*/
   .bv-signature-status-dialog .modal-dialog { min-width: 618px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important; }
   .bv-signature-status-container-div   { width:100%; height:445px; font-weight: normal; color:#000000; margin: 0px; }
   .bv-signature-status-row1 {width:100%;height:20%;padding: 20px 20px 20px 20px;}
   .bv-signature-status-row2 {width:100%;height:80%;padding: 20px 20px 20px 20px;}

   .bv-signature-status-header { line-height:30px; height:30px; width:100%; }
   .bv-signature-status-details { line-height:370px; height:370px; width:100%; }

   .bv-signature-status-header > ul  { display:block; position:absolute; width:578px; font-weight:500;}
   .bv-signature-status-details > ul { display:block; position:absolute; width:578px; height:370px; overflow:hidden;}
   .bv-signature-status-details > ul:hover {overflow-y:auto;}

   .bv-signature-status-li-progress{line-height:25px; height:25px; display: block; position: absolute; width:202px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap; padding-right: 10px;}
   .bv-signature-status-li-progress-bar{line-height:25px; height:25px; display: block; position: absolute; width:350px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap; padding-right: 10px;}
   .bv-signature-status-li-user{ line-height:20px; height:20px; display: block; position: absolute; width:202px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap; padding-right: 10px;}
   .bv-signature-status-li-action { line-height:20px; height:20px; display: block; position: absolute; width:192px; left:202px;  text-overflow:ellipsis; overflow:hidden; white-space:nowrap;}
   .bv-signature-status-li-date { line-height:20px; height:20px; display: block; position: absolute; width:182px; left:394px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap;}
   .bv-signature-status-value-li { display:block; height:32px; padding-top:6px; border-bottom:1px solid #ccc;}

   .bv-signature-status-value-li-ul {
       font-size: 14px;
       font-weight: 400;
   }

   .bv-signature-status-progress-bar-value
   {
   	background-color:#2273A6;
   	color: #2273A6;
   }

   .bv-signature-status-progress-bar-container
   {
       width: 350px;
       height: 24px;
       border: 1px solid #d6d6d6;
       background-color: white;
       cursor: pointer;
   }
   /*End of styles for signature status dialog*/

   /* Folder Agenda Builder */

   .tabs-container {
       width: 100%;
   }

   .tab-container {
       width: 100%;
       height: 500px;
       padding-top:10px;
   }

   .folder-agenda-main {
       padding: 20px;
       height: 100%;
       width: 100%;
       overflow: hidden;
   }

   .folder-agenda-template {
       width: 100%;
       height: 150px;
       overflow: hidden;
       margin-bottom: 10px;
   }


   .folder-agenda-templates .bv-slider > ul {
       text-align: center;
       width: 470px;
       min-width: 470px;
       min-height: 445px;
   }

   .folder-agenda-templates .bv-slider > ul > li {
       box-shadow: none !important;
       margin: 20px 3%;
       width: 94% !important;
   }

   .folder-agenda-templates-list {
       height: 100%;
   }

   .folder-agenda-templates .no-content-msg-label {
   	white-space: normal !important;
   }

   .folder-agenda-template-image-container {
       height: calc(100% - 15px);
       width: 100%;
       margin-bottom: 8px;
       position: relative;
       cursor: pointer;
       /* box-shadow: 0px 0px 10px 0px #888888; */
       display: inline-block;
       vertical-align: middle;
   }

   .folder-agenda-template-name {
       text-align: center;
       width: 100%;
       text-overflow: ellipsis;
       overflow: hidden;
       white-space: nowrap;
       margin-top: 5px;
       font-size: 16px;
   }

   .default-agenda-template-image {
       height: auto;
       width: 66%;
       max-width: 100%;
       position: relative;
       display: block;
       margin-left: auto;
       margin-right: auto;
   }

   .folder-agenda-styles {
       padding: 20px 0 0 0;
       height: 400px;
       width: 100%;
       /*margin-bottom: 20px;*/
   }

   .agenda-style-image {
   	margin: 20px;
   	width: calc(100% - 40px);
   	max-height: 200px;
   }

   .selectedStyle {
   	-webkit-box-shadow: 0px 0px 0px 2px #99b2cc;
       -moz-box-shadow: 0px 0px 0px 2px #99b2cc;
       box-shadow: 0px 0px 0px 2px #99b2cc;
   }
   /* directory.css */

    /* approval.css */
    .bv-float-left {
    	float : left !important;
    }
    .bv-float-right {
    	float : right !important;
    }
    .bv-approval-section-one-quarter {
    	width: 20%;
    	float: left;
    	list-style: none;
    	padding: 0;
    }
    /* .bv-input-btn-blue
    {
    	color:#FFFFFF;
    	min-width:100px;
    	background-color: #036EAA;
    	border-style:none;
    	max-height: 45px;
        min-height: 30px;
    } */

    .bv-arrow-next {
    	width: 21px;
    	margin: -106px 0 0 93.81185%;
    }
    .bv-approval-margin {
    	margin-left : 2%;
    	margin-top : 2%;
    	margin-right : 2%;
    	margin-bottom : 2%;
    }
    .bv-approval-owner {
    	font-weight: bold;
    }
    .bv-approval-dueTime {
    	font-size: x-small;
    	font-weight: bold;
    }
    .bv-approval-image {
    	margin-top : 5px;
    	width: 80px;
    	height: 100px;
    }
    .bv-approval-icon {
    	width: 30px;
    	height: 30px;
    	margin-left:5px;
    }

 .approvalsList{
       .approval-list-container{
            height: 100%;
            overflow-x: hidden;
            width: calc(100% - 3px);
        }
}
    .approval-list-wrapper{
	        height:100%;
	       
		& .showScroll{
				min-height:95vh;
			}
	}
    .section{
    	overflow:hidden;
    	width:100%;
    	/* border-bottom: 1px solid #DCDCDC; */
    	height: 50px;
        padding: 5px 13px 5px 13px;
    }

    .section:nth-child(odd) {
        background: #f7f9fa;
    }
    .sectionOne {
    	float:left;
    	/* width:675px; */
    	width: calc(100% - 450px);
    	overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .sectionTwo {
    	float:left;
    	/* width:calc(100% - 675px); */
    	width:450px;
    	padding-top: 7px;
    }
    [ng-if="tabs[0].active"] .sectionTwo{
    	float: right !important;
    	width: 30px !important;
    }
    [ng-if="tabs[0].active"] .sectionTwo > div{
    	width: 20px;
    	margin: 3px 5px;
    	height: 20px;
    }

    .approvalName {
    	font-size: 14px;
    	display: inline;
    	cursor: pointer;
    }

    .approvalName:hover {
        text-decoration: underline;
    }

    .approval-menu-icon {
      height: 10px;
      width: 11px;
      position: relative;
      fill: rgb(0, 0, 0);
      margin: 0px 0px 0 0px;
      display: none;
      cursor: pointer;
    }

    .approvalDue {
    	font-size: 12px;
    	overflow: hidden;
        text-overflow: ellipsis;
    }

    .section:hover {
    	background: #ebeef0;
    }

    .section:hover .approval-menu-icon {
      display: inline;
    }

    .approvalListProgressBar {
        width: 100%;
        height: 24px;
        pointer-events:none;
        background-color: #d6d6d6;
    }
    .progress-bar-approval-value
    {
    	background-color:#2273A6;
    	color: #2273A6;
    }

    .approvalListProgressBar .progress-bar{
    	-webkit-box-shadow: none;
    	box-shadow: none;
    }
    .approvalListProgressBar .progress-bar span{
    	line-height: 25px;
    	color:#ffffff;
    }
    .progress-bar-approval-zero-value
    {
    	background-color:#d6d6d6;
    }
    .progress-bar-approval-zero-value span{
    	color: gray !important;
    }
    /* .approvalMain {
    	overflow-y: auto;
    	width: 100%;
    	height: 100%;
    } */
    /* .section {
    	overflow:hidden;
    	width:98%;
    	border-bottom: 1px solid #DCDCDC;
    	margin-left : 1%;
    	margin-top : 1.3%;
    	margin-right : 1%;
    	margin-bottom : 2%;
    }
    .section-up {
    	width:100%;
    	height:115px;
    }
    .section-down {
    	width:100%;
    	height:15px;
    	text-align: right;
    }

    .section-first {
    	height:115px;
    	min-width:90px;
    	float:left;
    	position: relative;
    }

    .not-responded-text {
      position: absolute;
      margin: -35px 0 0 0;
      width: 49px;
      height: 22px;
      font-size: 9px;
      border: 1px solid;
      color: white;
      text-align: center;
      font-family: SANS-SERIF;
    }
    .section-second {
    	height:115px;
    	min-width:200px;
    	float:left;
    	width: 50%;
    	padding-left : 2%;
    	padding-top : 1.2%;
    }
    .section-third {
    	height:115px;
    	min-width:170px;
    	padding-left : 2%;
    	padding-top : 1.2%;
    }
    .section-third-left {
    	height:115px;
    	min-width:35px;
    }
    .section-third-right {
    	height:115px;
    	min-width:100px;
    } */

    /*   Approval    */

    .approval-main {
      width:100%;
      height:100%;
      min-width:1000px;
      min-height: 580px;
      position: relative;
    /*   overflow: auto; */
    }

    .approval-top-section {
      width: 100%;
      min-height: 210px;
      overflow: hidden;
      padding: 16px 20px 20px 20px;
    }

    .approval-top-left {
      width: calc(100% - 440px);
      height: 100%;
      overflow: hidden;
    }

    .approval-instructions {
      /*margin-top: 10px;*/
      height: 80px;
      width: calc(100% - 15px);
    }

    .approval-top-right {
    	width: 440px;
    	height:100%;
    	vertical-align: middle;
    	position: relative;
    	/*   top: 20%; */
    	white-space: nowrap;
    	/*display: inline-block;*/
    	overflow: hidden;
    	/*   margin: 10px; */
    	text-align: center;
    }


    .approval-attachments-row {
      height: 170px;
      text-align: center;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
      padding: 10px 10px 10px 10px;
      display: inline-block;
    }

    .approval-attachments-row .bv-slider > ul > li {
      margin: 10px 10px !important;
      width: 27%;
      height: 75%;
    }

    .bv-slider > div > svg {
        fill: #808080;
    }

    .approval-attachment-image-container {
      width: 100%;
      height: 115px;
      margin: 0 10px 10px 0;
      box-shadow: none;
      position: relative;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      background: #fff;
    }

    .approval-attachment-text-center{
      margin: 20px 0px 0px 0px;
      cursor: pointer;
    }

    .float-right {
      float:right !important;
    }


    .float-left {
      float:left !important;
    }

    /* .approval-attachment-image {
      width: 90px;
      height: 110px;
      margin: 20px 10px 5px 0;
      border: 1px solid #D1D1D1;
    } */

    .attachment-name {
      text-align:center;
    }

    .approval-bottom-section {
      width:100%;
      height:calc(100% - 210px);
      overflow: hidden;
      padding: 20px;
    }

    .approval-bottom-left {
      width:325px;
      height: 100%;
      overflow: hidden;
      position: relative;
    }

    .approval-responses {
      white-space: nowrap;
      width:calc(100% - 20px);
      min-height: calc(100% - 230px);
      margin-top: 15px;
    }

    /*
    .approve-responses-no-signature
    {
        height: calc(100% - 100px) !important;
    }
    */

    .approval-response-choice {
        min-height: 25px;
        position: relative;
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
    }

    .approval-response-choice svg {
        display: inline-block;
        width: 22px;
        height: 22px;
        float: left;
    }

    .approval-response-choice span {
         padding-left: 7px;
         margin-top: -2px;
         overflow: hidden;
         text-overflow: ellipsis;
         max-width: 250px;
     }

     .approval-response-choice span:hover{
         overflow: visible;
         white-space: normal;
         height:auto;
     }

    .approval-responses-options {
      margin: 10px 0 0 0;
    }
    .approval-signature, .approval-anonymous, .approval-tally{
    	height:22px;
    	width:22px;
    	cursor:pointer;
    	padding-right:25px;
    }
    .approval-response{
    	height:22px;
    	width:22px;
    	cursor:pointer;
    	padding-right:25px;
    	padding-left:30px;
    }

    .approval-sign-here-tooltip {
      position: absolute;
      left: 110px;
    }

    .approval-signature-outer {
    	width: calc(100% - 20px);
    	height: 90px;
    	/*background: #AC2738;*/
    	margin: 0px 0px 7px 0px;
    	position: absolute;
    	bottom: 55px;
    }

    .approval-signature-inside {
        width: 80%;
        height: 80px;
        background: #FFFFFF;
        margin: 5px 1% 5px 18%;
        position: absolute;
        text-align: center;
    }

    .approval-signature-image {
    	height: 60px;
    }

    .approval-signature-meta {
      position: absolute;
      bottom: 5px;
      width: calc(100% - 70px);
      right: 10px;
    }

    .signature-pen {
      width: 16%;
      height: 75px;
      float: left;
      margin: 10px 0 0 1%;
    }

    .approval-comments {
      width: calc(100% - 20px);
      position: absolute;
      bottom: 0px;
      padding-top: 15px;
    }

    .approval-comments span {
      position: absolute;
    }

    .approval-add-comments-img {
      width:35px;
      margin-right: 5px;
      height: 28px;
      display: inline-block;
    }

    .approval-submit-button {
      position: absolute;
      bottom: 20px;
      right: 20px;
      display:block;
    }

    .showHidePieChart {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 25px;
      left: 16px;
      cursor: pointer;
      z-index: 2;
    }

    .chart-div {
      width:40%;
      height:100%;
      position: relative;
      /* margin: 0 0 0 10px; */
    }

    .chart-div-small {
      width: 150px !important;
    }

    .chart-div-medium {
      width: 30% !important;
    }

    .legends {
      white-space: nowrap;
      padding-left: 15px;
    }

    .legend {
      width: 50%;
      display: inline-block;
      white-space: nowrap;
    }

    .legends-print {
      /*white-space: nowrap; */
        padding-left: 15px;
        position: absolute;
        bottom: 40px;
        /* width: 100%; */
        right: 0px;
    }

    .legend-print {
    /*    width: 100%;
        display: inline-block;
        white-space: nowrap;*/
    }

    .legend-circle {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      display: inline-block;
    }

    .legend-circle-print {
      width: 12px;
      height: 12px;
      display: inline-block;
    }

    .legend-title {
      display: inline-block;
    }

    .approval-users {
      height: calc(100% - 45px);
      padding-left : 10px;
      width: 60%;
    /*  overflow-y: hidden;
      overflow-x: visible;*/
      position: relative;
    }
    
    .approval-allowed-to-respond-bottom-right {
      width: calc(100% - 325px);
    }

    .approval-users-large {
      width: 100% !important;
    }

    .approval-users-small {
      width: 70% !important;
    }

    .approval-users-medium {
      width: calc(100% - 150px) !important;
    }

    .approval-user-responses {
      height: 100%;
    }

    .approval-approve-as-title {
      border-bottom: 8px solid #0A3F6E;
      margin: 5px 0px 5px 5px;
      padding-left: 25px;
    }

    .approval-not-responded-as-title {
      font-weight:500;
      border-bottom: 8px solid #D6D6D6;
      padding-bottom: 5px;
    }

    .approval-response-users {
      margin: 5px 0 0 5px;
      overflow: hidden;
    }

    .approval-response-users:hover {
    	overflow-x: auto;
    }

    .approval-user-icon {
      width: 35px;
      display: table-cell;
      vertical-align: middle;
    }

    .approval-response-info {
      width: 100%;
      display: inline-block;
      white-space: nowrap;
      line-height: 20px;
    }

    .approval-response-details {
      word-wrap: break-word;
    }

    .approval-response-sign {
      height: 80px;
    }

    .bv-radio-button {
      position: relative;
    }

    .bv-radio-button  > input[type="radio"] {
      opacity:0;
      height: 25px;
      width: 25px;
      position: absolute;
      top: 5;
      left: 5;
      z-index: 2;
    }
    
    .bv-radio-button > input[type="radio"] + span{
      background-image: url("../assets/images/BVLiverySurveyResponseNo.png");
      background-repeat: no-repeat;
      background-position: left bottom;
      /*background:url(../assets/checkbox-empty.jpg) no-repeat 0 0;*/
      height: 25px;
      width: 100%;
      display:inline-block;
      padding: 0 0 0 0px;
      position: absolute;
      top: 5;
      left: 5;
      z-index: 1;

    }

    .bv-radio-button > input[type="radio"]:Checked + span{
      background-image: url("../assets/images/BVLiverySurveyResponseYes.png");
      background-repeat: no-repeat;
      height: 25px;
      width: 100%;
      display:inline-block;
      padding: 0 0 0 0px;
    }

    a.approval-create:link {
        color: #036EAA;
    }
    a.approval-create:visited {
    	color: #036EAA;
    }
    a.approval-create {
    	font-size: 20px;
        margin-right: 20px;
        border: 1px solid;
        padding: 5px;
    }

    .approval-createDiv
    {
    	height: 25px;
    	margin-top: 10px;
    	margin: 5px 0px 0px 15px;
    }

    /*Create approval */
    .bv-create-approval-main{
    	padding: 5px 20px;
    }

    .bv-create-approval-half {
    	width:50%;
    	position: relative;
    	padding-right: 10px;
    	padding-bottom: 20px;
    }
    .bv-create-approval-form-field {
    	padding: 8px;
    }
    .bv-create-approval-form-field div:first-child button{
    	background: #ffffff;
    }
    .bv-create-approval-form-field img{
    	cursor:pointer;
    	width:12px
    }

    .bv-create-approval-form-label {
    	font-weight: bold;
    }

    .bv-create-approval-title-input {
    	margin-top: 10px;
    	width:375px;
    }

    .bv-create-approval-choice-input {
    	margin-bottom: 10px;
        font-weight: normal;
        background-color: #e5e5e5;
        border: none;
        width: calc(100% - 6px);
        height: 28px;
        padding-left: 10px;
        display: inline;
    }

    .bv-create-approval-date-input {
    	width:125px;
    }

    .bv-create-approval-priority {
      display: inline-block;
     	margin-left: 20px;
    }

    .bv-create-approval-main .nav-tabs{
    	top:0px;
    }
    .attachmentRow-approval {
    	/* margin: 5px 0 0 0px; */
      	text-align: left;
      	overflow: hidden;
      	white-space: nowrap;
    /*  	padding-right: 10px; */
      	width: 100%;
    }

    .attachmentRow-approval:hover {
    	overflow-x: auto;
    }

    .bv-new-approval-attchmt-main-div {
        height: 115px;
    }

    .bv-new-approval-attchmt-div {
    	width: 100%;
    /* 	padding-top: 2px; */
    	height: 110px;
    	position: relative;
    }


    .message-attachment-approval {
    /*   	height:100%;
    	display: inline-block; */
    /* 	margin: 0 0px 0 10px; */
    /* 	padding-bottom: 4px; */
    }

    .attachment-image-approval {
     	width: 50px;
      	height: 60px;
      	/*margin: 0 10px 25px 0;*/
      	border: 1px solid #eeeeee;
      	cursor: pointer;
    }
    .attachment-image-approval > img,
    .attachment-image-approval > div{
     	width: 46.5px;
      	height: 60px;
    }

    .attachmentRow-approval .bv-slider > ul{
    	margin: 0px 20px;
    }

    .attachmentRow-approval .bv-slider > ul > li{
    	width: 33.2%;
    	margin: 0;
    	box-shadow: inherit;
    }

    .attachmentRow-approval .bv-slider > ul > li .bv-new-approval-attachment-li > img{
    	box-shadow: 0px 0px 15px -3px #000;
    }

    /*.attachment-image:hover {
    	margin: 0 5px 0 0;
    }*/

    .bv-new-approval-attachment-li {
      	height: calc(100% - 20px);
    }

    .bv-new-approval-attachment-name {
    	text-align:center;
    	width: 100%;
    	overflow: hidden;
    	height:20px;
    	font-weight: normal;
    }

    .bv-new-approval-attachment-name div:first-child{
    	width:100px;
    	overflow:hidden;
    	width: 100px;
    	text-overflow: ellipsis;
    	display: inline-block;
    }

    .bv-new-approval-attachment-name img{
    	position: relative;
    	top: -6px;
    }

    .attachment-name-approval {
    	width: 50px;
      	height: 60px;
      	/*margin: 0 10px 25px 0;*/
      	border: 1px solid #eeeeee;
      	cursor: pointer;
    }

    .file-attachment-image-approval {
    	border: 1px solid #DDDDDD;
    	margin: 0 auto;
        display: block;
    }

    .bv-show-remove-btn{
    	display: inline;
    }

    .bv-show-remove-btn:hover .bv-remove-choice-btn {
    	display:inline;
    }

    .bv-remove-choice-btn {
    	margin-left: -26px;
      	display: none;
      	position: absolute;
      	cursor: pointer;
      	margin-top: 6px;
    }

    .bv-create-approval-title-error {
    	color: red;
      	display: inline;
      	padding-left: 10px;
      	font-weight: bold;
    }
    .bv-create-approval-attendees-role {
    	width:70px;
    	padding-top:8px;
    	display:inline;
    	padding-right: 20px;

    }
    .bv-create-approval-attendees-role label{
    	font-weight: normal;
    }
    .bv-create-approval-remove-attendee-btn{
    	width:30px;
    	padding-bottom:1px;
    	display:inline;
    }
    .bv-create-approval-attendes {
    	padding-left:4px;
    	width:100%;
    	padding-top: 10px;
    }

    .approval-row-first{
        width: 100%;
        min-width: 1050px;
        height: calc(100% - 68px);
        overflow: hidden;
        /* padding-bottom: 22px; */
        display: list-item;
        list-style-type: none;
        /* border: 1px solid; */
    }

    .approval-row-second{
    /*	width:100%;
    	height: 6%;
    	border: 1px solid blue;
    	display: list-item;
    	padding-right: 15px;*/
    	position: absolute;
    	right: 20px;
    	bottom: 20px;
    }

    .approval-col-details{
    	height: 100%;
    	width: 370px;
    	padding:15px 16px 20px 20px;
    	border-right: 1px solid #e5e5e5;
    	float: left;
    	overflow: hidden;
    	position: relative;
    }
    .approval-col-both-attachments{
    	height: 100%;
    	float: right;
    	width: calc(100% - 370px);
    }


    .approval-col-attachments{
        height: 100%;
        display: inline-block;
        width: 50%;
        padding: 15px 15px 0px 15px;
        float: left;
    }

    .approval-col-users{
        height: calc(100% - 25px);
        width: 50%;
        float: right;
        padding: 15px 15px 0px 15px;
    }

    .approval-label {
    	margin-bottom: 5px;
    }

    .approval-attachments{
    	height: calc(100% - 20px);
    	width: 100%;
    	overflow-x:hidden;
    	overflow-y:hidden;
    }
    .approval-attachments:hover{
    	overflow-y:auto;
    }

    .approval-attachment:hover .drag-handle-image{
    	display: block;
    }

    .approval-attachment {
    	position: relative;
    	height: 60px;
    	margin-bottom: 20px;
    }
    .approval-attachment-text-center{
    	margin: 20px 0px 0px 0px;
    	cursor: pointer;
    }
    .approvalDropDown{
    	width:100%;
    	background-color:#e5e5e5;
    	border-radius:0;
    	height:26px;
    	border:none;
    	padding-left: 9px;
        -webkit-appearance: none;
        -webkit-border-radius: 0px;
    }
    .approval-text-truncate{
    	width: 100%;
      	white-space: nowrap;
      	overflow: hidden;
      	text-overflow: ellipsis;
    }
    .btn-remove {
    	width:21px;
    	float:right;
    	display:inline;
    	padding-top: 19px;
    }
    .form-group-approval{
    	margin-bottom: 5px;
    	width: 100%;
    	float: left;
    }
    .form-group-approvalChoices{
    	margin-bottom: 20px;
    	width: 100%;
    	float: left;
    }
    .approval-textbox{
    	width: 100%;
    	margin-bottom:5px;
    }
    .approval-dueDate {
    	width: 47%;
    	float: left;
    	display:inline;
    	margin-bottom:5px;

    	icon {
            position: absolute;
            right: 6px;
            top: 32px;
            font-size: 14px;
        }
    }
    .approval-prioirty {
    	width: 47%;
    	float: right;
    	display:inline;
    	margin-bottom:5px;
    }
    .approval-col-details-form {
    	height: 96%;
    	width: 100%;
    	position: relative;
    }
    .approval-col-details-form .form-group {
    	margin: 0px;
    }
    .approval-textarea{
    	width: 100%;
    	/*border: 0px none;*/
    	line-height: 1.4;
    	/*padding: 2px 10px;*/
    	margin-bottom: 5px;
    	resize:vertical;
    	overflow: auto;
    	height: 55px;
    	/*outline: none;*/
    }
    .addApprovalChoice{
    	font-size:x-large;
    	cursor:pointer;
    }
    .approval-user-unchecked{
    	font-weight: normal;
    	color: #808080;
    	font-size: 14px;
    }

    .sign-user-name {
    	max-width: 135px;
    }

    .bv-approval-user-approved-bar {
    	display: inline-block;
        width: 10px;
        height: 28px;
        float: left;
        margin-top: 10px;
        background: #a5bc4e;
        }
    .bv-approval-user-notApproved-bar {
    	display: inline-block;
        width: 10px;
        height: 28px;
        background: #caca9e;
        float: left;
        margin-top: 10px;
        }
    .bv-approval-user-abstained-bar {
    	display: inline-block;
        width: 10px;
        height: 28px;
        background: #1b95d9;
        float: left;
        margin-top: 10px;
        }
    .notResponded-bar{
    	background:#e48701;
    	display: inline-block;
        width: 10px;
        height: 28px;
        float: left;
        margin-top: 10px;
    	}
    	.choice4-bar{
    	background:#6693b0;
    	display: inline-block;
        width: 10px;
        height: 28px;
        float: left;
        margin-top: 10px;
    	}
    	.choice5-bar{
    	background:#f05e27;
    	display: inline-block;
        width: 10px;
        height: 28px;
        float: left;
        margin-top: 10px;
    	}
    	.choice6-bar{
    	background:#86d1e4;
    	display: inline-block;
        width: 10px;
        height: 28px;
        float: left;
        margin-top: 10px;
    	}
    	.choice7-bar{
    	background:#e4f9a0;
    	display: inline-block;
        width: 10px;
        height: 28px;
        float: left;
        margin-top: 10px;
    	}
    .bv-approval-user-default-bar {
    	display: inline-block;
        width: 10px;
        height: 28px;
        background: #A1D690;
        float: left;
        margin-top: 10px;
        }
        .aprvlDrpDwnList{
        	min-width:100% !important;
        	top : 25px !important;
        }
        .aprvlDrpDwnBkgrndColor{
        	background : #e5e5e5 !important;
        	color: #000 !important;
        }
    .bv-approval-user-option .modal-dialog { width:565px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important; }
    .bv-approval-user-option-container-div { width:100%; height: 502px; font-weight: normal; color:#000000; margin: 0px; }
    .bv-approval-user-option-col1 { height:100%; width:240px; float:left;  font-weight: 500;}
    .bv-approval-user-option-col2 { height:100%; width:324px; float:right; display: inline-block; position: absolute; }
    .bv-approval-user-option-col2 .padding-left-20 { padding:20px;}
    .bv-approval-user-option-row { height:60px;line-height:55px;width:100%; font-size:20px; font-weight:400; cursor:pointer; display:block; text-align:left; margin-top:7px; margin-bottom:13px; padding: 0px 0px 0px 20px; }
    .bv-approval-user-option-row > span {
    	line-height: normal;
        vertical-align: middle;
        white-space: inherit;
        display: inline-block;
    }
    .bv-approval-user-option-row-selected { background-color: #2273a5; color:#FFFFFF;}
    .bv-approval-user-option-row-not-selected { color: #2273a5;}
    .bv-approval-user-option-text-truncate {max-width: 90%;float: left; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
    .bv-approval-user-option-section1 {width: 100%; height: 90%;}
    .bv-approval-user-option-section2 {width: 100%; height: 10%;}
    .bv-approval-user-accepted-bar {display: inline-block; width: 5px; height: 15px; background: #199c48; float: left; margin-top:3px}
    .bv-approval-user-not-responded-bar {display: inline-block; width: 5px; height: 15px; background: #959596;float: left; margin-top:3px}
    .bv-approval-user-tentative-bar {display: inline-block; width: 5px; height: 15px; background: #ea9930;float: left; margin-top:3px}
    .bv-approval-user-declined-bar {display: inline-block; width: 5px; height: 15px; background: #ea252f;float: left; margin-top:3px}
    .bv-approval-user-users {height: calc(100% - 41px); width: 100%; overflow-x:hidden; overflow-y:hidden;}
    .bv-approval-user-users:hover{overflow-y:auto; }
    .bv-approval-user-checkbox {width:27px; float: right;display: inline;}
    .bv-approval-user-checkbox-inner {float: right;display: inline;cursor: pointer;}
    .bv-approval-user-item-width {width: 310px;}
    .bv-approval-user-item {width: 100%; display: list-item; height: 35px;}
    .bv-approval-user-seperator {margin-top:5px;margin-bottom:12px;border-color: #e5e5e5}


    .add-comments-main {
      padding: 20px;
    }

    .add-comments-main textarea {
      resize: none;
      width: 100%;
      height: 200px;
      border: none;
    }

    .summary-label {
      padding-left: 15px;
      left: 0px;
      position: absolute;
    }
    /* */
    .approval-options-main {
      height: 130px;
      padding: 20px;
    }

    .approval-options {
      height: 30px;
    }

    .options-label {
      margin-left: 10px;
    }
    /* */

    /* Print Approval */
    .approval-print-button {
      position: absolute;
      bottom: 20px;
      right: 20px;
    }

    .print-approval-main {
      height: 700px;
      padding: 20px;
      overflow: auto;
    }

    .print-approval-container {
      height: 610px;
    }

    .print-approval-main .approval-attachments-row {
      height: 100%;
      width: 100%;
      position: relative;
    }

    .print-approval-main .approval-attachment {
      position: relative;
      height: 150px;
      width: 90px;
      float: left;
      margin-right: 17px;
    }

    .print-approval-main .approval-approve-as-title {
      padding-left: 0px !important;
    }
    .disableUserItem{
    	opacity: 0.2;
    	pointer-events: none;
    }
    .disableEditApprovalItems{
    	opacity: 0.5;
    	pointer-events: none;
    }
    .dimText{
    	opacity: 0.5;
    }
    .extraSpacing{
    	margin-bottom:10px;
    }

    .bv-approval-user-item-list .bv-item-list-main{
        height: 100%;
    }
    .bv-approval-user-item-list .bv-item-list-main > div{
        height: 100%;
    }
    .bv-approval-message-center-div
    {
    	position:relative;
    	margin-top:14%;
    }
    .approval-checkbox-padding{
    	padding-top:2px;
    }

    .approvalsList-Mobile section{
        height: 45px;
        border-bottom: 1px solid #ccc;
        overflow: hidden;
    }

    .approvalsList-Mobile open,
    .approvalsList-Mobile closed,
    .approvalsList-Mobile own{
        width: 32%;
        height: 100%;
        display: inline-block;
        text-align: center;
        font-size: 18px;
        position: relative;
        padding-top: 10px;
    	/*color: #ababab;*/
    }

    .approvalsList-Mobile open.active span,
    .approvalsList-Mobile closed.active span,
    .approvalsList-Mobile own.active span{
    	color: #000;
    	border-bottom: 5px solid #000;
        padding-bottom: 7px;
    }

    .approvalsList-Mobile .sectionOne {
    	float:left;
    	/* width:675px; */
    	width: 50%;
    	overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .approvalsList-Mobile .sectionTwo {
    	float:left;
    	width:50%;
    	padding-top: 7px;
    }

    [state="home.approvals"] .rightMenu .tile-checkbox svg{
    	fill: #fff;
    }
    .approval-published-bar {
    	background: #d6d6d6;
        float: left;
        width: 6px;
        position: relative;
        height: 30px;
        margin: 5px 5px 5px 0px;
    }
    .approval-published-bar.active{
    	background: #2273a5;
    }
    .form-group-approval .approval-dueDate .approval-signature{
        z-index: 1;
        position: relative;
        top: 3px;
    }

    .form-group-approval .approval-dueDate.disabled .approval-label,
    .form-group-approval .approval-dueDate.disabled input,
    .form-group-approval .approval-dueDate.disabled .form-control-feedback{
    	opacity: 0.5;
    }

    .bv-conf-approvals-settings .bv-conf-settings-container-div .bv-conf-settings-row:first-child{
    	display: none;
    }
    .bv-moduleCreatorList:hover .removeModuleCreatorUser
    {
    	opacity:0.2;
    	visibility:visible;
    }
    .removeModuleCreatorUser
    {
    	visibility:hidden;
    }
    .removeModuleCreatorUser:hover
    {
    	opacity:1.0 !important;
    	visibility:visible !important;
    }

    .bv-conf-approvals-settings .bv-ts-grid-ul-li .bv-item-small-close-svg{
    	visibility: hidden;
    }
    .bv-conf-approvals-settings .bv-ts-grid-ul-li:hover .bv-item-small-close-svg{
    	visibility: visible;
    }
    .bv-conf-approvals-settings .bv-ts-grid-ul-li:hover{
    	background: #ebeef0;
    }
    .bv-conf-approvals-settings .bv-ts-grid-ul-li .bv-ts-grid-node-lbl,
    .bv-conf-approvals-settings .bv-ts-grid-ul-li .bv-ts-grid-chk-div{
    	top: 3px;
    }
    /* approval.css */

    /* surveys.css */
    .container1 {
        min-width: 375px;
        float:left;
    }
    .container2 {
        width: calc(100% - 375px);
        float:left;
        overflow:hidden;
    }

    .surveyImg
    {
    	float: left;
        width: 30%;
        padding:4%;
        padding-top: 7%;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .surveyDetails
    {
    	float:left;
    	width: 65%;
    	overflow: hidden;
    	text-overflow: ellipsis;
    }

    .surveyDescription
    {
    	width: calc(100% - 150px);
        overflow:hidden;
        font-size: small;
        text-overflow: ellipsis;
        float:left;
    	height:145px;
    	overflow: auto;
    	text-align:left;
    	padding-top: 4%;
    }

    .surveyStatus
    {
        float: left;
        width: 150px;
        margin-top: 4%;
        overflow:hidden;
    }
    .surveyBtn
    {
        padding-bottom:4%;
    }

    .editSurveyBtn{
    	padding-bottom:1%;
    }

    .surveyStatusBar{
    	 padding-left: 6%;
    	 font-size: x-small;
    }

    progress.surveys-bar::-moz-progress-bar {
        background: black;
    }

    /* Chrome */
    progress.surveys-bar::-webkit-progress-bar
    {
    	background: #d6d6d6;
    }
    progress.surveys-bar::-webkit-progress-value {
        background: black;
    }

    /* Polyfill */
    progress.surveys-bar[aria-valuenow]:before  {
        background: #d6d6d6;
    }
    .cke_toolgroup {
	    margin: 1px 0px 6px 0px;
	    padding-right: 0px;
	}
    /*firefox*/
    @-moz-document url-prefix() {
        .closeButtonDetailsFirefox {
            position:relative;
            margin-right: 5px;
        }
        .bv-survey-arrow-next-details
        {
    		position: relative;
    		margin-left: 0 !important;
    		margin-top: 2px !important;
    		right: 43px;
    		width: 17px;
        }
    }

    progress.surveys-bar{
    	-webkit-appearance: none;
         -moz-appearance: none;
         appearance: none;
      	 color: #515151;
      	 border-radius: 2px;
      	 box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
      	 background-color:#d6d6d6;
      	 width: 100px;
    	 height: 8px;

    }


    progress.open-survey-bar::-moz-progress-bar {
        background: black;
    }

    /* Chrome */
    progress.open-survey-bar::-webkit-progress-bar
    {
    	background: #d6d6d6;
    }
    progress.open-survey-bar::-webkit-progress-value {
        background: black;
    }

    /* Polyfill */
    progress.open-survey-bar[aria-valuenow]:before  {
        background: #d6d6d6;
    }
    .bv-slider > div > svg {
        fill: #808080;
    }
    progress.open-survey-bar{
    	-webkit-appearance: none;
         -moz-appearance: none;
         appearance: none;
      	 color: #515151;
      	 border-radius: 2px;
      	 box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
      	 background-color:#d6d6d6;
      	 width: 100%;
    	 height: 30px;

    }
    div.bv-surveyProgress-left
    {
    	float:left;
    	/* padding-top:1%; */
    	/* width:16%; */
    	width:120px;
    }
    div.bv-surveyProgress-middle
    {
    	width: calc(100% - 240px);
    	/* padding-left:1%;
    	padding-top:1%; */
    	float:left;
    }
    div.bv-surveyProgress-right
    {
    	/* padding-left:1%;
    	padding-top:1%; */
    	float:left;
    	width:120px;
    	/* overflow:auto; */
    }

    .surveyPortal
    {
    	padding-top: 10%;
    	font-weight: bold;
    	white-space:nowrap;
    }

    .surveyPriority
    {
    	padding-top:1%;
    	font-size:small;
    	line-height:0.5;
    	overflow:hidden;
    	text-overflow:ellipsis;
    	white-space:nowrap;
    }

    .surveyHrBar
    {
    	overflow:hidden;
    	width:100%;
    	border-bottom: 2.5px solid #DCDCDC;
    }

    .surveyGroupHrBar
    {
    	overflow:hidden;
    	width:100%;
    	hight:20px;
    	background-color: #DCDCDC;
    	padding-left: 2%;
    	font-weight:bold;
    }

    .bv-surveys-image{
    	padding-top:1px;
    	height: 90px;
    }

    .bv-surveys-attachment-image{
      width: 100px;
      height: 120px;
      margin: 20px 10px 5px 0;
      border:1px solid #021a40;
    }

    .review-survey-form{
      width:100%;
      height:100vh;
    }

    .surveyScreenDIV{
    	  height:100%;
    	  width:100%;
    }
    .surveyOverview{
    	padding-left:3%;
    	padding-top:1%;
    	/* width:60%; */
    	height:100%;
    	overflow:auto;
    	float:left;
    	width: calc(100% - 465px);

    }

    .surveySections{
    	padding:2% 2% 2% 2%;
    	width:450px;
    	overflow:auto;
    	float:left;
    	height:100%;
    }

    .surveySectionsInside{
    	background-color:#F0F0F0;
    	border: 1px solid #DBDBDB;
    	border-radius: 15px;
    	padding:5% 3% 0% 3%;
    }

    .surveyProgress{
    	/* height: 15%; */
    	/* padding-top:1%; */
    	/* padding-left:2%; */
    	background-color:white;
    	width:100%;
    }

    .surveyProgressNoBackground{
    	height: 15%;
    	/* padding-top:1%; */
    	padding-left:2%;
    	width:100%;
    }

    .surveyProgressText{
    	padding-left:36%;
    	font-size:smaller;
    }
    .surveyText{
    	padding-left: 2%;
    	padding-right:2%;
    	text-align: left;
    	overflow-y: scroll;
    /* 	height:250px;
    	overflow:hidden;
    	margin-bottom: 2%; */
    }

    .surveyAttachments{
    	white-space: nowrap;
    	display:inline;
    	/* overflow-x:scroll; */
    	padding-left:5%;
    	/* height:100%; */
    }

    .innerSection{
    	background-color:#fff;
    	padding-left:3%;
    	padding-right:2%;
    	margin-left:25%;
    	margin-right:25%;
    	/* margin-top:1%; */
    	position:relative;
    	height:100%;
    	overflow:auto;
    	border: 1px;
    	border-radius: 10px;
    }

    .bv-input-btn-grey
    {
    	color:#FFFFFF;
    	min-width:100px;
    	background-color: #424242;
    	border-style:none;
    	max-height: 45px;
        min-height: 30px;
    }

    .bv-surveys-card-image{
    	padding-bottom:3%;
    	margin: 0 auto;
    	display: block;
    /* 	padding-left:40%; */
    }

    .surveyQuestionDIV, .surveySectionDIV{
    	height:100%;
    	width:100%;
    }

    .sendSurveyAlertDIV{
    	/* height:550px; */
    	padding-left:3%;
    	padding-right:3%;
    	padding-top:0%;
    	padding-bottom:3%;
    	/* overflow:scroll; */
    }
    .userSurveyPreviewDIV{
    	height:550px;
    	padding:20px;
    	/* overflow:scroll; */
    }
    .surveyQuestionOverview {
    	height: 200px;
    	width:100%;
    	padding :15px;
    }

    .surveyQuestionSections {
    	background-color:#FFFFFF;
    	height: calc(100% - 50%);
    	width:100%;
    	padding-top:0.8%;
    	padding-bottom:0.8%;
    	text-align:left;
    	overflow:scroll;
    }

    .surveyQuestionSectionsError, .responseCommentsError {
    	border: 1px solid #F8E0E6;
    	background-color: #FBEFF2;
    }

    .surveySectionAttachments {
    	height: calc(100% - 230px);
    	width:100%;
    	padding:15px;
    }
    .questionDetail {
    	/* padding-left:2%;
    	padding-right:2%;
    	margin-bottom:2%; */
    }

    .surveyQuestionProgress {
    	width:100%;
    	height: 30px;
    	padding-top:0.5%;
    	padding-left:2%;
    	padding-right:2%;
    	margin-top:1%;
    	margin-bottom:1%;
    }
    .surveyQuestionText {
    	width:100%;
    	height: 100%;
    	padding-left: 2%;
    	padding-right:2%;
    }
    .bv-input-btn-white
    {
    	color:#000000;
    	background-color: #FFFFFF;
    	border-style:none;
    	max-height: 45px;
        min-height: 30px;
    }
    /* .bv-input-btn-black {
    	color:#FFFFFF;
    	min-width:100px;
    	background-color: #000000;
    	border-style:none;
    	max-height: 45px;
        min-height: 30px;
    } */
    .questionFontBold {
    	font-weight: bold;
    }
    .questionFontMandatory {
    	color: #FF0000;
    	font-weight: bold;
    }
    .questionFont {
    	font-weight: bold;
    	font-size: 16px;
    }
    .questionSection {
    	width:100%;
    	text-align:left;
    	ul{
          list-style-type: initial;
		  margin-left: 20px;
          
        }
    }
    .survey-add-comments-img {
      width:35px;
    }
    .questionProgressLeft {
    	width: 48%;
    	height: 100%;
    }
    .questionProgressRight {
    	width: 52%;
    	height: 100%;
    }
    .questionProgressButton {
    	/* width: 32%; */
    	height: 100%;
    	/* margin-left: 2%; */
    	position: relative;
    }
    .bv-survey-arrow-next {
        width: 17px;
        position: absolute;
        margin-left: -27px;
        margin-top: 5px;
    }
    .bv-survey-arrow-back {
        width: 17px;
        position: absolute;
        margin-left: 16px;
        margin-top: 5px;
    }

    .bv-survey-arrow-next-details {
        width: 17px;
        position: absolute;
        margin-left: -37px;
        margin-top: 5px;
       
    }
    .bv-survey-attach-next {
    	position: absolute;
    	margin-left: 170px;
    	margin-top:10px;
    }

    .carousel-control.left, .carousel-control.right
    {
    	background: none !important;
    }
    .carousel{
    	position:relative !important;
    	height:100% !important;
    }

    .carousel-inner{
    	position:relative !important;
    	width:100% !important;
    	height:100% !important;
    }
    .carousel .carousel-indicators {
    	visibility: hidden !important;
    	}

    .carousel-control{
    	color: #000000 !important;
    }

    .carousel .item{
    	text-align:left !important;
    }

    /*.modal-dialog{
    	height:450px !important;
    	width:800px !important;
    }*/

    .yesNoClass, .trueFalseClass, .meetsOrNotClass{
    	font-size:x-large;
    	width: 50px;
    	height: 50px;
    	margin-left:30%;
    	display:inline;
    }

    .bv-likert-scale{
    	display:inline;
    	margin-left: 0.2em;
    	margin-top:2%;
    	float:left;
    }

    .bv-multi-choice, .bv-multi-choice-multi-value{
    	margin-left: 0.2em;
    	margin-top:2%;
    }

    .yesNoClass > input[type="radio"], .meetsOrNotClass > input[type="radio"], .trueFalseClass > input[type="radio"], .bv-likert-scale > input[type="radio"], .bv-multi-choice-multi-value > input[type="checkbox"], .bv-multi-choice > input[type="radio"]{
      width   : 28px;
      margin  : 0;
      padding : 0;
      opacity : 0;
    }

    .yesNoClass > input[type="radio"] + label, .meetsOrNotClass > input[type="radio"] + label, .trueFalseClass > input[type="radio"] + label, .bv-likert-scale > input[type="radio"] + label{
      background-image: url("../assets/images/BVLiverySurveyResponseNo.png");
      background-repeat: no-repeat;
      background-position: left bottom;
      background-size:27px;
      height: 100%;
      width: 100%;
      display:inline;
      position: relative;
      z-index: 1;
      margin-left: 9px;
      padding-left: 32px;

    }

    .yesNoClass > input[type="radio"]:Checked + label, .meetsOrNotClass > input[type="radio"]:Checked + label, .trueFalseClass > input[type="radio"]:Checked + label, .bv-likert-scale > input[type="radio"]:Checked + label, .bv-multi-choice > input[type="radio"]:Checked + label{
      background-image: url("../assets/images/BVLiverySurveyResponseYes.png");
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      display:inline;
      padding: 0 0 0 0px;
      margin-left: 9px;
      padding-left: 32px;
    }

    .bv-likert-scale > input[type="radio"] + label{
    	margin-left: 0px;
    	background-size: 17px;
    }

    .bv-likert-scale > input[type="radio"]:Checked + label{
    	margin-left: 0px;
    	background-size: 17px;
    }

    .bv-multi-choice-multi-value > input[type="checkbox"] + label{
      background-image: url("../assets/images/checkbox-empty.png");
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      display:block;
      padding: 0 0 0 0px;
      margin-left: 9px;
      padding-left: 32px;
     }

     .bv-multi-choice-multi-value > input[type="checkbox"]:Checked + label{
       background-image: url("../assets/images/checkbox-marked.png");
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      display:block;
      padding: 0 0 0 0px;
      margin-left: 9px;
      padding-left: 32px;
     }

     .bv-multi-choice > input[type="radio"] + label{
      background-image: url("../assets/images/BVLiverySurveyResponseNo.png");
      background-repeat: no-repeat;
      background-position: left bottom;
      background-size: 20px;
      height: 100%;
      width: 100%;
      display:block;
      position: relative;
      z-index: 1;
      margin-left: 9px;
      padding-left: 32px;
    }

    .bv-multi-choice > input[type="radio"]:Checked + label{
      background-image: url("../assets/images/BVLiverySurveyResponseYes.png");
      background-repeat: no-repeat;
      background-size: 20px;
      height: 100%;
      width: 100%;
      display:block;
      padding: 0 0 0 0px;
      margin-left: 9px;
      padding-left: 32px;
    }

    div.progress-bar-openSurvey-box
    {
    	background-color:#d6d6d6;
    	margin-bottom:0px;
        width: 100%;
        height: 28px;
        margin-left: -2px;

    }
    div.progress-bar-openSurvey-value
    {
    	background-color:#3596BD;

    }
    div.progress-bar-Survey-box
    {
    	background-color:#d6d6d6;
    	width:100px;
    	height:10px;
    	margin-bottom:0px;
    }

    .progress-bar-Survey-box-openSurvey{
    	background-color:#d6d6d6;
    	width:100px;
    	height:5px;
    	margin-bottom:0px;
    }
    div.progress-bar-Survey-value
    {
    	background-color:#3596BD;
    }
    .glyphicon-chevron-left:before
    {
    	content: url("resources/assets/images/leftarrow.png")
    }
    .glyphicon-chevron-right:before
    {
    	content: url("resources/assets/images/rightarrow.png")
    }
    .openSurveyDescription{
    	line-height:1.5;
    	padding-bottom:2%;
    	text-align:left;
    	padding-right:3%;
    }

    /* .bv-modal{
    	position: absolute;
    	top: -15%;
    	right: 0;
    	bottom: 0;
    	left: 0;
    	z-index: 1050;
    	display: none;
    	overflow: auto;
    	overflow-y: scroll;
    	-webkit-overflow-scrolling: touch;
    	outline: 0;
    } */

    .bv-report-modal, .bv-participantsList-modal, .bv-send-alert-modal, .bv-admin-preview-modal, .bv-printable-reports-modal{
    	position: fixed;
    	/* top: -15%; */
    	right: 0;
    	bottom: 0;
    	left: 0;
    	z-index: 1050;
    	display: none;
    	overflow: auto;
    	overflow-y: scroll;
    	-webkit-overflow-scrolling: touch;
    	outline: 0;
    }
    .bv-copy-survey-modal{
    	/* width:500px; */
    	position: fixed;
    	right: 0;
    	bottom: 0;
    	/* left: 35%; */
    	z-index: 1050;
    	display: none;
    	overflow: hidden;
    	overflow-y: hidden;
    	-webkit-overflow-scrolling: touch;
    	outline: 0;
    }
    .bv-signature-modal{
    	top: 60%;
    	left: 85%;
    	max-width: 300px;
    	max-height: 300px;
    	display: none;
    	-webkit-overflow-scrolling: touch;
    	outline: 0;
    }
    .surveys-signature-outer {
    	width: 57%;
    	height: 70px;
    	background: #AC2738;
    	margin: 0px 0px 7px 0px;
    	position: absolute;
    	bottom: 35px;
    }

    .surveys-signature-inside {
      width: 86%;
      height: 60px;
      background: #FFFFFF;
      margin: 5px 1% 5px 13%;
      border-radius: 5px 5px 5px 5px;
      position: absolute;
      text-align: center;
    }

    .surveys-signature-image {
    	height: 60px;
    }

    .bv-document-name{
    	display: inline-block;
    	width: 90px;
    	color: #428bca !important;
    	text-overflow: ellipsis;
    	white-space: nowrap;
    	overflow: hidden;
    	font-size: small;
    }

    .bv-document-name:hover{
    	text-decoration: underline;
    }
    .sectionName{
    	width: 100%;
        /* min-width: 100px; */
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    /* .sectionBoxLeft .sectionName:hover{
    	text-decoration: underline;
    	cursor : pointer;
    } */
    .sectionNameText:hover{
    	text-decoration: underline;
    	cursor : pointer;
    }

    .bigFont{
    	font-size:x-large;
    	font-weight:bold;
    }

    .boldFont{
    	font-weight:bold;
    }

    .underline{
    	text-decoration: underline;
    }

    .capitalize{
    	text-transform:capitalize;
    }

    .blueFont{
    	color:#2273a5;
    }
    .redFont{
    	color:#FF0000;
    }
    .surveyPriorityDescription{
    	padding-top: 3%;
    	font-size: small;
    	line-height: 0.5;
    	overflow: scroll;
    	bottom: 0px;
    	top: 0px;
    }
    .carousel .item
    {
        height:100%;
        padding:20px;
    }

    .surveyQuestionAttachments{
    	/* float: left; */
    	height:20%;
    	width:100%;
    	padding-left:2%;
    	padding-top: 2%;
    }

    .bv-input-btn-grey-disabled{
    	color: #FFFFFF;
    	min-width: 100px;
    	border-style: none;
    	max-height: 45px;
    	min-height: 30px;
    }

    .bv-input-btn-blue-disabled
    {
    	color:#FFFFFF;
    	min-width:125px;
    	border-style:none;
    	max-height: 45px;
        min-height: 35px;
    }

    .surveyReportsTab{
    	padding-left:2%;
    	padding-top:2%;
    	padding-right:2%;
    	padding-bottom:2%;
    }
    .surveyReportsDIV{
    	height:550px;
    	overflow:scroll;
    	padding-left:3%;
    	padding-right:3%;
    	padding-top:3%;
    	padding-bottom:3%;
    	text-align: justify;
    }

    .participantsListDIV{
    	height:300px;
    	overflow:scroll;
    	padding-left: 5%;
    	padding-top: 5%;
    	padding-right: 5%;
    	padding-bottom: 5%;
    }

    .participantsList > ul >li{
    	padding: 3% 0% 3% 3%;
    }

    .gridStyle {
        border: 1px solid rgb(212,212,212);
        min-width: 250px;
        min-height: 180px;
        overflow:scroll;
    }
    .reportsQuestions{
    	 border:1px solid;
    	 padding-left:2%;
    	 padding-right:2%;
    }

    .barOrPieChart{
    	padding-bottom:2%;
    }

    .surveyReportTab, .surveyUserPreviewTab{
    	background-color:#555;
    }

    .surveyNameInReport{
    	padding:1% 2% 2% 2%;
    	float:left;
    }

    .participantListClass{
    	width:20px;
    	height:20px;
    }

    .toClass{
    	overflow:scroll;
    }
    .subjectClass{
    	overflow:scroll;
    }
    .participantsGridStyle{
    	border: 1px solid rgb(212,212,212);
        width: 100%;
       	max-height: 200px;
        overflow: auto;
    }
    .previewNgCell{
    	color:black;
    	text-decoration:underline;
    	text-align:center;
    }

    .eachQuestion{
    	border: 1px solid rgb(212,212,212);
    	padding:2%;
    }
    .copySurveyDIV{
    	padding: 5%;
    }

    .copySurveyNameClass{
    	width:100%;
    }
    .previewProgress{
    	margin-left:14%;
    	font-size:xx-small;
    	padding-top: 4%;
    }
    .printableReportsDIV{
    	/* padding:2%; */
    	padding:25px 25px 25px 25px;
    	height:100%;
    }
    .surveyDetailsInPrintableReports{
    	padding:3%;
    	border: 1px solid;
        border-radius: 5px;
        background: #D9D9D9;
        background: url("../assets/images/dust.png");
        text-align: justify;
    }
    .sectionDetailsInPrintableReports{
    	padding: 3%;
    	border: 1px solid;
        border-radius: 5px;
        background: #D9D9D9;
        background: url("../assets/images/dust.png");
        text-align: justify;
    }
    .questionDetailsInPrintableReports{
    	padding:3%;
    	border: 1px solid;
        border-radius: 5px;
        text-align: justify;
    }
    .printableReportsDIV{
    	height:550px;
    	overflow:auto;
    	background:#FFFFFF;
    }
    .printableReportsHeader{
    	padding: 0% 2% 4% 3%;
    }

    .surveyReportSaveName{
    	padding-top: 5%;
    	padding-left: 4%;
    }

    .accordionContent{
    	padding:2% 2% 2% 2%;
    }

    .accordionSectionDescription{
    	text-overflow: ellipsis;
    	overflow: hidden;
    	white-space: nowrap;
    	width:100%;
    }

    /* .questionInOverview{
    	text-overflow: ellipsis;
    	overflow: hidden;
    	white-space: pre;
    	padding:2% 0% 2% 2%;
    } */

    /* .questionInOverview > p{
    	text-overflow: ellipsis;
    	overflow: hidden;
    	white-space: nowrap;
    	padding-left:2%;
    	padding-right:2%;

    } */
    .accordionIndex{
    	width:5%;
    	padding-left:2%;
    	float:left;
    }
    .answeredOrNot{
    	padding-left:4%;
    	width:10%;
    	float:left;
    }
    .accordionQuestion{
    	float:left;
    	width:80%;
    	padding-left:3%;
    	text-overflow: ellipsis;
    	overflow: hidden;
    	white-space: nowrap;
    	padding-left:7%;
    	padding-right:2%;
    	height: 28px;
    	display:inline-block;
    }
    .accordionQuestion >p{
    	text-overflow: ellipsis;
    	overflow: hidden;
    	white-space: nowrap;
    }
    .surveyOverviewAccordion{
    	width:100%;
    }

    .bv-input-btn-blue-surveys
    {
    	color:#FFFFFF;
    	background-color: #3596BD;
    	border-style:none;
        font-size:smaller;
    }

    .bv-input-btn-blue:disabled-surveys
    {
    	background-color:#D5D5D5;
    	font-size:smaller;
    }

    .bv-input-btn-black-surveys
    {
    	color:#FFFFFF;
    	background-color: #000000;
    	border-style:none;
        font-size:smaller;
    }

    .bv-input-btn-grey-surveys{
    	color:#FFFFFF;
    	background-color: #424242;
    	border-style:none;
        font-size:smaller;
    }

    .bv-input-btn-grey-disabled-surveys{
    	color: #FFFFFF;
    	border-style: none;
    	font-size:smaller;
    }

    .sectionQuestionsAnsweredCount{
    	padding-right:2%;
    }

    .xSmallProgressBar{
    	padding-left:38%;
    	font-size:xx-small;
    }

    .pointer {
        cursor: pointer;
    }

    .highlight {
    	background-color:#D5D5D5;
    	font-style: italic;
    	overflow:hidden;
    	display:inline-block;
      }

     .panel-heading{
      height:40px;
     }
     .bv-surveys-accordion-heading-class{
     	width: 100%;
     }
     .bv-create-survey-tabs
     {
     	width:100%;
     	height:100%;
     	top:0px;
     }
    .bv-progress-bar-question
    {
    	background-color: #d6d6d6;
     	 width: 100px;
     	 height: 10px;
      	margin-bottom: 0px;
      	display:inline-block;
    }
    .bv-survey-section-button
    {
    	float:right;
    	margin-right:5px;
    }
    .bv-survey-question-button
    {
    	float:right;
    	margin-right:5px;
    }
    .question-placeholder-highlight {
    	height: 15px;
    	background-color: #CCCCCC;
    }
    .questions-list-order
    {
    	overflow-x: hidden;
      	overflow-y: hidden;
      	height: calc(100% - 0px);
      	position: relative;
      	width: calc(100% - 40px);
      	margin-top: 10px;
    }
    .left-half-survey-details
    {
    	width:80%;
    	float:left;
    }
    .div-input-survey-name
    {
    	padding:50px 0px 0px 10px;
    	width:80%;
    }
    .input-survey-name
    {
    	width:100%;
    }
    .desrciption-div
    {
    	padding:20px 0px 0px 10px;
    }
    .span-survey-description-character-count
    {
    	padding:0px 0px 0px 10%;
    }
    .survey-description-barbox
    {
      background-color: #d6d6d6;
      height: 10px;
      margin-bottom: 0px;
      display: inline-block;
      width: 35%;
      margin-left: 20%;
    }
    .div-description-text-area
    {
    	padding:0px 10px 0px 10px;
    }
    .div-radio-options-survey-details
    {
    	width:100%;
    	height:110px;
    }
    .div-label-types-survey-details
    {
    	width:15%;
    	float:left;
    	padding:10px 0px 0px 10px;
    }
    .div-survey-due-date
    {
    	padding:11px 0px 0px 0px;
    }
    .div-survey-priority
    {
    	padding:22px 0px 0px 0px;
    }
    .div-survey-details-imageTypes
    {
    	width:2%;
    	float:left
    }
    .subdiv-survey-details-imageTypes
    {
    	padding:10px 0px 0px 10%;
    }
    .svg-survey-details-imageTypes
    {
    	display:inline-block;
    	position:relative;
    	top:4px;
    }
    .input-field-survey-dueDate
    {
    	width:180px;
    	height:30px;
    }
    .div-survey-details-responseTypes1
    {
    	width:19%;
    	float:left;
    	padding:13px 0px 0px 0%;
    }
    .subdiv-survey-details-responseTypes1
    {
    	padding:50px 0px 0px 0px;
    }
    .span-priorityMedium-survey-details
    {
    	padding:0px 0px 0px 10%;
    }
    .svg-priorityMedium-survey-details
    {
    	display:inline-block;
    	position:relative;
    	top:2px;
    }
    .div-survey-details-imagesTypes2
    {
    	width:2%;
    	float:left;
    }
    .subdiv-survey-details-imagesTypes2
    {
    	padding:10px 0px 0px 10%;
    }
    .svg-survey-details-imagesTypes2
    {
    	display:inline-block;
    	position:relative;
    	top:4px;
    }
    .div-priorityLow-survey-details
    {
    	padding:12px 0px 0px 10%;
    }
    .div-survey-details-responsetypes2
    {
    	width:15%;
    	float:left;
    	padding:13px 0px 0px 0px;
    }
    .label-survey-details-low
    {
    	padding:47px 0px 0px 0%;
    }
    .div-survey-details-imagesTypes3
    {
    	width:2%;
    	float:left
    }
    .subdiv-survey-details-imagesTypes3
    {
    	padding:10px 0px 0px 10%;
    }
    .svg-survey-details-imagesTypes3
    {
    	display:inline-block;
    	position:relative;
    	top:5px
    }
    .div-survey-details-responseTypes3
    {
    	width:25%;
    	float:left;
    	padding:13px 0px 0px 1%;
    }
    .label-survey-sequentalNumber
    {
    	padding:15px 0px 0px 0%;
    }
    .div-rightHalf-survey-details
    {
    	float:left;
    	width:20%;
    }
    .div-topBar-survey-details
    {
    	padding-top:10px;
    }
    .button-show-owners-survey
    {
    	margin-top:10px;
    }
    .div-topSection-survey-question
    {
    	width:100%;
    	height:56px;
    }
    .div-topSection-title-survey-question
    {
    	width:70%;
    	float:left;
    	font-size:x-large;
    	padding:28px 0 0 10px;
    }
    .div-edit-question-survey
    {
    	width:30%;
    	float:right;
    	padding:35px 0px 0px 0px;
    }
    .div-edit-questions-charactersdiv
    {
    	margin-top:1px;
    }
    .div-edit-question-charactersLabel
    {
    	padding:10px 0px 0px 10px;
    }
    .div-survey-question-barbox
    {
      display: inline-block;
      width: 65%;
      margin-left: 5%;
      background-color: #d6d6d6;
      height: 10px;
      margin-bottom: 0px;
    }
    .div-survey-question-imageTypes
    {
    	width:19%;
    	float:left;
    	padding:0px 0px 0px 10px;
    }
    .svg-survey-dropDownItem
    {
    	display:inline-block;
    	position:relative;
    	top:4px;
    }
    .div-survey-question-textArea
    {
    	padding:10px 10px 0px 10px;
    }
    .div-survey-question-imageTypes2
    {
    	width:1.5%;
    	float:left;
    	padding:20px 0px 0px 0px;
    }
    .svg-survey-multipleChoiceItem
    {
    	display:inline-block;
    	position:relative;
    	top:4px;
    }
    .div-survey-question-multipleChoiceLabel
    {
    	width:15%;
    	float:left;
    	padding:20px 0px 0px 0px;
    }
    .div-survey-question-imageTypes3
    {
    	width:1.5%;
    	float:left;
    	padding:20px 0px 0px 0px;
    }
    .svg-survey-question-openAnswerItem
    {
    	display:inline-block;
    	position:relative;
    	top:4px;
    }
    .div-survey-question-openAnswerLabel
    {
    	width:15%;
    	float:left;
    	padding:20px 0px 0px 0px;
    }
    .div-survey-question-imageTypes4
    {
    	width:1.5%;
    	float:left;
    	padding:20px 0px 0px 0px;
    }
    .svg-survey-question-likertScaleItem
    {
    	display:inline-block;
    	position:relative;
    	top:4px;
    }
    .div-survey-question-likertScaleLabel
    {
    	width:15%;
    	float:left;
    	padding:20px 0px 0px 0px;
    	margin-bottom: 15px;
    }
    .div-survey-question-multipleChoiceorLikertScale
    {
    	/* width:100%; */
    	/* padding:20px 0px 0px 0px; */
    	/* float:left; */
    	/* margin-bottom: 15px; */
    }
    .div-survey-question-multipleChoiceDiv
    {
    	/* padding:0px 0px 0px 10px; */
    }
    .svg-survey-question-allowMultipleChoice
    {
    	display:inline-block;
    	position:relative;
    	top:9px;
    }
    .label-survey-question-allowMultipleChoice
    {
    	/* padding-left:10px; */
    	padding-bottom:10px;
    	padding-left: 50px;
    }
    .div-survey-questions-multipleChoiceOptions
    {
    	/* padding:10px 0px 0px 10px; */
    }
    .inputtext-survey-questions-multipleChoiceOptions
    {
    	width:87%;
    }
    .div-survey-question-likertScaleDiv
    {
    	/* padding:0px 0px 0px 10px; */
    }
    .div-survey-question-likertScaleOptions
    {
    	/* padding:10px 0px 0px 10px; */
    }
    .inputtext1-survey-question-likertScaleOptions
    {
    	width:75%;
    }
    .inputtext2-survey-question-likertScaleOptions
    {
    	    width: 10%;
    }
    .div-survey-question-reply
    {
    	width:100%;
    	padding:0px 0px 0px 10px;
    	float:left;
    }
    .div-survey-question-replyandcomment
    {
    	width:45%;
    }
    .bv-survey-admin-preview-modal .modal-dialog, .bv-survey-userAnsweres-preview-modal .modal-dialog{
     	width:900px;
     	/* height:500px; */
     	/* margin-top: 5%; */
     }
     .bv-survey-create-preview-modal .modal-dialog{
     	width:900px;
     	height:550px;
     }
     .bv-survey-alert-modal .modal-dialog, .bv-report-modal .modal-dialog, .bv-printable-reports-modal .modal-dialog{
     	width: 900px;
     	/* margin-top: 5%; */
     }
     .bv-report-modal .nv-axis .tick line, .bv-printable-reports-modal .nv-axis .tick line {
            display:none;
        }
        .surveys-menu-icon {
        height: 10px;
        width: 11px;
        position: relative;
        fill: rgb(0, 0, 0);
        margin: 0px 0px 0 0px;
        display: none;
        cursor: pointer;
    }
    .surveyDetails:hover .surveys-menu-icon {
      display: inline;
    }
    .survey-sign-here-tooltip {
      position: absolute;
      left: 110px;
    }

    .survey-signature-outer {
    	width: calc(100% - 20px);
    	height: 90px;
    	/*background: #AC2738;*/
    	margin: 0px 0px 7px 0px;
    	position: absolute;
    	bottom: 55px;
    }

    .survey-signature-inside {
        width: 80%;
        height: 80px;
        background: #FFFFFF;
        margin: 5px 1% 5px 18%;
        position: absolute;
        text-align: center;
    }

    .survey-signature-image {
    	height: 60px;
    }

    .survey-signature-meta {
      position: absolute;
      bottom: 5px;
      width: calc(100% - 70px);
      right: 10px;
    }

    .signature-pen {
      width: 16%;
      height: 75px;
      float: left;
      margin: 10px 0 0 1%;
    }
    
    .surveysListDIV{
        .survey-list-container{
             height: 100%;
             overflow-x: hidden;
             width: calc(100% - 3px);
         }
    }
    .survey-list-wrapper{
        height:100%;
       
    & .showScroll{
            min-height:95vh;
        }
    }

    .surveysMain{
      width:100%;
      height:100%;
      min-width:1000px;
      min-height: 580px;
      position: relative;
      &.showScroll{
            min-height:95vh;
      }
    /*   overflow: auto; */
    }
    .surveys-section{
    	overflow:hidden;
    	width:100%;
    	/* border-bottom: 1px solid #DCDCDC; */
    	min-height: 50px;
        padding: 5px 13px 5px 5px;
    }
    .surveys-section:nth-child(odd){
        background: #f7f9fa;
    }
    .surveys-sectionOne {
    	float:left;
    	/* width:675px; */
    	width: calc(100% - 450px);
    	overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .surveys-sectionTwo {
    	float:left;
    	/* width:calc(100% - 675px); */
    	width:450px;
    	padding-top: 7px;
    }
    .surveyName {
    	font-size: 14px;
    	display: inline;
    	cursor: pointer;
    	padding-left:18px;
    }
     /*added as part of MEETX-24908*/
    .closedSurveyName {
    	font-size: 14px;
    	display: inline;
    	cursor: pointer;
    	padding-left:18px;
    }
   .surveyName:hover {
        text-decoration: underline;
   }

    .survey-menu-icon {
      height: 10px;
      width: 11px;
      position: relative;
      fill: rgb(0, 0, 0);
      margin: 0px 0px 0 0px;
      display: none;
      cursor: pointer;
    }

    .surveyDue {
    	font-size: 12px;
    	overflow: hidden;
        text-overflow: ellipsis;
        padding-left:20px;
    }

    .surveys-section:hover,.questionBox:hover,.manage-survey-section:hover, .participantTableDataHover:hover, .bv-survey-removeAttendeesDiv2 .bv-ts-grid-ul-li:hover{
    	background: #ebeef0;
    }
    .clickSection{
       ul{
          list-style-type: initial;
		  margin-left: 20px;
          
        }
    }
    .clickSection:hover{
       box-shadow: 0px 0px 15px 0px #000;
    }

    .surveys-section:hover .survey-menu-icon {
      display: inline;
    }
    .surveys-section:hover > div > svg {
      display: inline;
    }

    .surveyListProgressBar {
        width: 100%;
        height: 24px;
        border: 1px solid #d6d6d6;
        pointer-events:none;
        background-color: #d6d6d6;
    }
    .disabledClass{
    	pointer-events:none;
    }
    .progress-bar-survey-value
    {
    	background-color:#2273A6;
    	color: #2273A6;
    }
    .bv-survey-message-center-div
    {
    	position:relative;
    	margin-top:14%;
    }
    .survey-row-first{
        width: 100%;
        min-width: 850px;
        height: calc(100% - 50px);
        overflow: hidden;
        /* padding-bottom: 22px; */
        display: list-item;
        list-style-type: none;
        padding-bottom: 20px;
        /* border: 1px solid; */
    }

    .survey-row-second{
    /* 	position: absolute;
    	right: 20px;
    	bottom: 20px;
    	height: 25px;
    	height: 25px; */
    	float: right;
    	padding-right: 15px;
    	padding-bottom: 20px;
    	height: 50px;
    }

    .survey-col-details{
    	height: 100%;
    	width: 370px;
    	padding:15px 16px 20px 20px;
    	border-right: 1px solid #e5e5e5;
    	float: left;
    	overflow: hidden;
    	position: relative;
    }

    .survey-col-details,
    .bv-survey-section-details,
    #questionCreateDiv{
        .cke{
            iframe{
                background:  #e5e5e5;
            }
        }
    }

    .survey-col-both-attachments{
    	height: 100%;
    	float: right;
    	width: calc(100% - 370px);
    }


    .survey-col-attachments{
        height: 100%;
        display: inline-block;
        width: 50%;
        padding: 15px 15px 0px 15px;
        float: left;
    }

    .survey-col-users{
        height: calc(100% - 25px);
        width: 50%;
        float: right;
        padding: 15px 15px 0px 15px;
    }

    .survey-label {
    	margin-bottom: 5px;
    }
    .survey-attachment {
    	position: relative;
    	height: 60px;
    	margin-bottom: 20px;
    }

    .survey-attachments{
    	height: calc(100% - 20px);
    	width: 100%;
    	overflow-x:hidden;
    	overflow-y:hidden;
    }
    .survey-attachments:hover{
    	overflow-y:auto;
    }

    .survey-attachment:hover .drag-handle-image, .questionBox:hover .drag-handle-image{
    	display: block;
    }
    .sectionCreateDivRight .drag-handle-image{
    	display: none;
    	margin-top: -12px;
    }
    .bv-survey-attachment-section-li:hover .drag-handle-image{
    	display: block;
    	margin-top: -12px;
    }
    .questionBox .drag-handle {
        width: 14px;
        min-height: 10px;
        float: left;
        position: relative;
        top: 3px;
        padding-left: 0px;
        }
    .sectionBox:hover{
    	background-color:#DEDEDE;
    }
    .questionBox .survey-attachment {
    	position: relative;
    	height: 60px;
    	margin-bottom: 20px;
    }
    .survey-attachment-text-center{
    	margin: 20px 0px 0px 0px;
    	cursor: pointer;
    }
    .surveyDropDown{
    	width:100%;
    	background-color:#e5e5e5;
    	border-radius:0;
    	height:26px;
    	border:none;
    	padding-left: 9px;
        -webkit-appearance: none;
        -webkit-border-radius: 0px;
    }
    .survey-text-truncate{
    	width: 100%;
      	white-space: nowrap;
      	overflow: hidden;
      	text-overflow: ellipsis;
    }
    /* .btn-remove {
    	width:21px;
    	float:right;
    	display:inline;
    	padding-top: 19px;
    } */
    .form-group-survey{
    	margin-bottom: 5px;
    	width: 100%;
    	float: left;
    }
    .survey-textbox{
    	width: 100%;
    	margin-bottom:5px;
    	font-size:14px;
    }
    .survey-dueDate {
    	width: 47%;
    	float: left;
    	display:inline;
    	margin-bottom:5px;
    	icon {
			    position: absolute;
			    right: 6px;
			    top: 30px;
			    font-size: 14px;
			}
    }
    
    .survey-datePick-wraper{
	    position: relative;
	    float: left;
	    padding-left: 10px;
	    width: 160px;
	    input {
	    	width:100%;
	    }
	    
	    	icon{
	    		right: 7px !important;
	    	}
	    	
	   
    }    
    .survey-prioirty {
    	width: 47%;
    	float: right;
    	display:inline;
    	margin-bottom:5px;
    }
    .survey-col-details-form {
    	height: 100%;
    	width: 100%;
    	position: relative;
    }
    .survey-col-details-form .form-group {
    	margin: 0px;
    }
    .survey-textarea{
    	width: 100%;
    	/*border: 0px none;*/
    	line-height: 1.4;
    	/*padding: 2px 10px;*/
    	margin-bottom: 5px;
    	resize:vertical;
    	overflow: auto;
    	height: 85px;
    	/*outline: none;*/
    	font-size:14px;
    }
    .survey-user-unchecked{
    	font-weight: normal;
    	color: #808080;
    	font-size: 14px;
    }
    .attachment-image-survey {
     	width: 50px;
      	height: 60px;
      	/*margin: 0 10px 25px 0;*/
      	border: 1px solid #eeeeee;
      	cursor: pointer;
    }
    .attachment-image-survey img,
    .attachment-image-survey > div{
    	width: 47px;
      	height: 60px;
    }
    .attachmentRow-survey .bv-slider > ul{
    	margin: 0px 20px;
    }

    .attachmentRow-survey .bv-slider > ul > li{
    	width: 33.2%;
    	margin: 0;
    	box-shadow: inherit;
    }
    .survey-checkbox-padding{
    	padding-top:2px;
    }
    .surveyDrpDwnList{
        	min-width:100% !important;
        	top : 25px !important;
        }
    .surveyDrpDwnBkgrndColor{
        	background : #e5e5e5 !important;
        	color: #000 !important;
        }
    .survey-response{
    	height:22px;
    	width:22px;
    	cursor:pointer;
    	padding-right:25px;
    	padding-left:30px;
    }
    .moveCursor{
    	cursor:move !important;
    }
    .bv-survey-user-option .modal-dialog { width:565px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important; }
    .bv-survey-user-option-container-div { width:100%; height: 502px; font-weight: normal; color:#000000; margin: 0px; }
    .bv-survey-user-option-col1 { height:100%; width:240px; float:left;  font-weight: 500;}
    .bv-survey-user-option-col2 { height:100%; width:324px; float:right; display: inline-block; position: absolute; }
    .bv-survey-user-option-col2 .padding-left-20 { padding:20px;}
    .bv-survey-user-option-row { height:60px;line-height:55px;width:100%; font-size:20px; font-weight:400; cursor:pointer; display:block; text-align:left; margin-top:7px; margin-bottom:13px; padding: 0px 0px 0px 20px; }
    .bv-survey-user-option-row > span {
    	line-height: normal;
        vertical-align: middle;
        white-space: inherit;
        display: inline-block;
    }
    .bv-survey-user-option-col2-publishUnpublish{height:100%; width:680px; float:right; display: inline-block; position: absolute;padding:15px;padding-bottom:0px;}
    .manageSurveyPublishCol1{
    	width:550px;
    	padding-top:5px;
    }
    .manageSurveyPublishCol2{
    	width:calc(100% - 550px);
    	padding-top:5px;

    }
    .manageSurveyPublishCol1AddRespdnts{
    	width:300px;;
    	padding-top:5px;
    }
    .manageSurveyPublishCol2AddRespdnts{
    	width:calc(100% - 300px);
    	padding-top: 15px;
        padding-left: 25px;
    }
    .manageSurveyPublishCol3{
    	width:155px;
    }
    .bv-survey-user-option-row-selected { background-color: #2273a5; color:#FFFFFF;}
    .bv-survey-user-option-row-not-selected { color: #2273a5;}
    .bv-survey-user-option-text-truncate {max-width: 90%;float: left; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
    .bv-survey-user-option-section1 {width: 100%; height: 90%;}
    .bv-survey-user-option-section2 {width: 100%; height: 10%;}
    .bv-survey-user-accepted-bar {display: inline-block; width: 5px; height: 15px; background: #199c48; float: left; margin-top:3px}
    .bv-survey-user-not-responded-bar {display: inline-block; width: 5px; height: 15px; background: #959596;float: left; margin-top:3px}
    .bv-survey-user-tentative-bar {display: inline-block; width: 5px; height: 15px; background: #ea9930;float: left; margin-top:3px}
    .bv-survey-user-declined-bar {display: inline-block; width: 5px; height: 15px; background: #ea252f;float: left; margin-top:3px}
    .bv-survey-user-users {height: calc(100% - 41px); width: 100%; overflow-x:hidden; overflow-y:hidden;}
    .bv-survey-user-users:hover{overflow-y:auto; }
    .bv-survey-user-checkbox {width:27px; float: right;display: inline;}
    .bv-survey-user-checkbox-inner {float: right;display: inline;cursor: pointer;}
    .bv-survey-user-item-width {width: 310px;}
    .bv-survey-user-item {width: 100%; display: list-item; height: 35px;}
    .bv-survey-user-seperator {margin-top:5px;margin-bottom:12px;border-color: #e5e5e5}
    .qMgrTopSection {
        width: 100%;
        height: 25px;
        overflow: hidden;
        /* padding: 16px 20px 20px 20px; */
    }

    .qMgrMiddleSection{
    	height:calc(100% - 28px);
    }

    /* View survey */

    .survey-main {
      width:100%;
      height:100%;
      min-width:1000px;
      min-height: 520px;
      position: relative;
      overflow: auto;
    }

    .survey-top-section {
      width: 100%;
      min-height: 200px;
      overflow: hidden;
      padding: 16px 20px 10px 20px;
    }
    .survey-top-section-preview {
      width: 100%;
      height: 175px;
      overflow: hidden;
     /*  padding: 16px 20px 10px 20px; */
    }

    .survey-top-section-preview .survey-top-left{
    	padding: 16px 20px 10px 20px !important;
    	height:100%;
    }

    .survey-top-section-preview .survey-top-right{
    	padding: 0px 20px 10px 20px !important;
    	height:100%;
    }
    .survey-top-left {
      width: calc(100% - 440px);
      height: 100%;
      overflow: hidden;
    }

    .survey-instructions {
      /*margin-top: 10px;*/
      min-height: 30px;
      max-height: 65px;
      width: calc(100% - 15px);
      text-align: left;
      ul{
          list-style-type: initial;
		  margin-left: 20px;
          
        }
    }

    .survey-top-right {
    	width: 440px;
    	height:100%;
    	vertical-align: middle;
    	position: relative;
    	/*   top: 20%; */
    	white-space: nowrap;
    	/*display: inline-block;*/
    	overflow: hidden;
    	/*   margin: 10px; */
    	text-align: center;
    }


    .survey-attachments-row {
      height: 170px;
      text-align: center;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
      padding: 10px 10px 10px 10px;
      display: inline-block;
    }
    .survey-attachments-row-sections
    {
      height: 170px;
      text-align: center;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
      padding: 10px 10px 10px 10px;
      display: inline-block;
    }

    .survey-attachments-row .bv-slider > ul > li {
      margin: 10px 11px !important;
      width: 90px;
      height: calc(100% - 36px);
      box-shadow:none;
    }
    .userPreviewResponseRight .bv-slider > ul > li {
      margin: 10px 7px !important;
      width: 90px;
      height: 115px;
       box-shadow:none;
      /* box-shadow:1px 1px 1px 1px; */
    }

    .userPreviewTopRight .bv-slider > ul > li {
      margin: 10px 8px !important;
      width: 90px;
      height: 115px;
       box-shadow:none;
      /* box-shadow:1px 1px 1px 1px; */
    }
    .userPreviewSectionDetailsRight .bv-slider > ul > li {
      margin: 10px 7px !important;
      width: 90px;
      height: 115px;
       box-shadow:none;
      /* box-shadow:1px 1px 1px 1px; */
    }

    .survey-attachments-row-sections .bv-slider > ul > li{
    	display: inline-block;
        height: calc(100% - 40px);
        box-shadow: none;
        margin: 0;
        padding-top: 7px;
    }
    /* .userPreviewTopRight .bv-slider > ul > li{
    	display: inline-block;
        height: calc(100% - 40px);
        width: 31.2%;
        margin: 20px 1%;
        box-shadow: 0px 0px 15px 0px;
        position: relative;
    } */
    /*  .userPreviewSectionDetailsRight .bv-slider > ul > li{
     	display: inline-block;
        height: calc(100% - 40px);
        width: 31.2%;
        margin: 20px 1%;
        box-shadow:  0px 0px 15px 0px;
        position: relative;

     } */
    /*  .userPreviewResponseRight .bv-slider > ul > li{
     	display: inline-block;
        height: calc(100% - 40px);
        width: 31.2%;
        margin: 20px 1%;
        box-shadow:  0px 0px 15px 0px;
        position: relative;

     } */

    .bv-slider > div > svg {
        fill: #808080;
    }

    .survey-attachment-image-container {
      width: 90px;
      height: 115px;
      margin: 0 10px 10px 0;
     /*  box-shadow: none; */
      box-shadow: 0 0 7px 0 #707070;
      position: relative;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      background: #fff;
    }

    .survey-attachment-text-center{
      margin: 20px 0px 0px 0px;
      cursor: pointer;
    }
    .survey-second-section {
      width:100%;
      height:calc(100% - 260px);
      overflow: hidden;
      position:relative;
      /* padding: 20px; */
    }
    #surveyConsumerSectionDIV .survey-second-section{
      width:100%;
      height:calc(100% - 260px);
      overflow: hidden;
      position:relative;
      padding: 20px;
    }
    #surveyConsumerSectionDIV{
    	padding: 20px 20px 20px 20px;
    }
    #surveyConsumerQuestionDIV{
    	padding:20px;
    }
    #surveyConsumerSectionDIV .survey-bottom-section{
    	width:100%;
    	min-height:50px;
      	position:relative;
     	 /* padding: 20px; */
    }
    .survey-section-cards .bv-slider > ul > li {
    	width:31.7%;
    	margin: 20px 0.8%;
    	/* height: calc(100% - 305px); */
    	overflow:hidden;
    }
    .bv-surveys-cards-line {
        height: 6px;
        }
    .bv-surveys-cards-line > div{
    	background: #aeaeae;
    	height: 6px;
    }
    .bv-survey-section-cards-title{
    	font-size: 17px;
        font-weight: 500;
    }
    .section-description-title{
    	padding-top: 13px;
    	font-weight: 500;
    }
    .section-description{
    	padding-top: 5px;
        overflow: scroll;
        height: calc(100% - 100px);
        white-space: normal;
        text-align: left;
    }
    .survey-bottom-section{
    	width:100%;
    	min-height:50px;
      	position:relative;
      	padding-left: 20px;
        padding-right: 15px;
    }
    #idSurveyQuestionManager{
    	padding: 15px 20px 20px 20px;
    	height:100%;
    	width:100%;
    }
    .sectionBox {
        background: #e5e5e5;
        width: 100%;
        padding: 10px;
        margin-top: 15px;
        border: 1px solid rgb(218, 216, 216);
        height:40px;
    }
    .sectionBoxLeft{
    	/* width:100px; */
    	/* max-width:calc(100% - 100px); */
    	width:calc(100% - 100px);
    	min-width:100px;
    	overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .sectionBoxRight{
    	/* width:calc(100% - 100px); */
    	overflow:hidden;

    }
    .questionBox {
        padding: 10px;
        border: 1px solid rgb(218, 216, 216);
        border-top: 0;
       /*  margin-top: 15px; */
       /*  margin-left:20px;
        width:calc(100% - 20px); */
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor:pointer;
    }
    .sectionQuestionLeft{
    	/* max-width:calc(100% - 100px); */
	    	width:calc(100% - 100px);
	    	min-width:100px;
	    	overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-height: 20px;
    }
    .sectionQuestionRight{
    	overflow:hidden;
    }
    .survey-drop-down-arrow {
        height: 13px;
        width: 13px;
        position: relative;
        fill: rgb(0, 0, 0);
        margin: 0px 0px 0 0px;
        cursor: pointer;
        display: inline;
        transition: 200ms;
        -webkit-transition: 200ms;
        padding-left: 3px;
        padding-top: 1px;
    }
    .survey-drop-down-arrow.opened{
    	transform: rotate(90deg);
    	-webkit-transform: rotate(90deg);
        transition: 200ms;
       	margin-top: 3px;
    }
    /* .sectionBoxRight{
    	display: inline;
    } */
    .bv-show-remove-btn-surveys{
    	display: table-cell;
    	cursor: pointer;
    }

    .bv-show-remove-btn-surveys:hover .btn-remove-surveySection {
    	display:inherit;
    }

    /* .bv-remove-choice-btn {
    	margin-left: -26px;
      	display: none;
      	position: absolute;
      	cursor: pointer;
      	margin-top: 6px;
    } */
    .btn-remove-surveySection {
    	width: 21px;
        float: right;
        display: inline;
        cursor:pointer;
        display:none;
    }


    .bv-survey-question-details-option-section1 {width: 100%; height: calc(100% - 50px);}
    .bv-survey-question-details-option-section2 {width: 100%; height: 50px;padding-top:20px;}
    .addSectionBtn{
    	cursor:pointer;
    }

    survey-question-div,
    survey-section-div
    {
    	padding:0;
    	overflow:hidden;
    	width:100%;
    	height:100%;
         transition: height 300ms;
        -webkit-transition: height 300ms;
    	display: block;
    }
    survey-section-div .bv-survey-section-details,
    survey-question-div #questionCreateDiv{
    	height: 100%;
    	border: 2px solid #2273A6;
    	padding: 15px;
    }

    /* .opened survey-question-div,
    .opened survey-section-div
    {
    	height:300px;
    } */
    .sectionQuestionsArea{
    	overflow: hidden;
    	 transition: height 300ms;
        -webkit-transition: height 300ms;
        height: 0;
    }
    .sectionQuestionsArea.opened{
    	height: inherit;
    }
    #idSurveyQuestionManager > div > .mCustomScrollbar .mCSB_container{
    	 transition: all 300ms;
        -webkit-transition: all 300ms;

    }

    .sectionCreateDivLeft{
    	width:100%;
    	height:100%;
    	/* padding-right: 20px;
    	border-right: 1px solid #e5e5e5; */
    }
    .sectionCreateDivRight hr{
    	margin-top: 25px;
        border-color: #e5e5e5;
    }
    .survey-section-textarea {
        width: 100%;
        /* border: 0px none; */
        line-height: 1.4;
        /* padding: 2px 10px; */
        margin-bottom: 5px;
        resize: none;
        overflow: auto;
        height: 125px;
        /* outline: none; */
        }
        .survey-section-question-textarea {
        width: 100%;
        /* border: 0px none; */
        line-height: 1.4;
        /* padding: 2px 10px; */
        /* margin-bottom: 5px; */
        resize: none;
        overflow: auto;
        height: 65px;
        /* outline: none; */
        }
    .bv-survey-section-details-option-section1 {
        width: 100%;
        height: calc(100% - 30px);
        padding-bottom:20px;
    }

    .bv-survey-section-details-option-section2 {
        width: 100%;
        height: 30px;
    }
    .section-table{
    	float: right;
        width: initial;
        overflow: visible;
    }
    .bv-survey-question-details-option-section2 .bv-input-btn-box {
    	padding: 0px 0px 20px 20px;
    }
    .bv-survey-question-details-option-section2 .bv-input-btn-box button{
        transition: 200ms;
        -webki-transition: 200ms;
    }
    .bv-survey-question-details-option-section2 .bv-input-btn-box .meeting-notice  {
    	left:0px;
    }
    .form-group-survey-more-margin {
        margin-bottom: 15px;
        }

      .dropdown-menu-survey{
        left: 9px;
        margin-left: 23px;
        min-width: 185px;
        top: 23px !important;
        border-radius: 0px;}

        .disableStyle{
        background:#d6d6d6 !important;
        }
        #idSurveySummary{
        	height:100%;
        	width:100%;
        }
       .summaryLeft{
       		width:700px;
       		float:left;
       		padding:20px;
       		height:100%;
       		border-right: 1px solid #e5e5e5;
       }
       .summaryRight{
       		width:calc(100% - 700px);
       		float:left;
       		padding:20px;
       		height: calc(100% - 25px);
       		/* border-left: 1px solid #e5e5e5; */
       }
       .summaryLeftTop{
       		height:265px;
       		padding:10px;
       }
       .summaryLeftBottom{
        	height:calc(100% - 265px);
        	padding-left:10px;
        	padding-right: 10px;
        	padding-bottom: 10px;
       }
       .surveyDescriptionSummary{
       		padding:10px;
       		/* height: 100px; */
       		text-align: left;
       		ul{
              list-style-type: initial;
    		  margin-left: 20px;
              
            }
       }
          .surveyDescriptionSummaryStatus{
       		padding:10px;
       		height: 100px;
       		/* height:100%; */
       		text-align: left;
       }
       .adminPreviewDIV{
    	   	height:550px;
       }
       .statusTop{
       		height:250px;
       }
       .statusBottom{
       		height: calc(100% - 300px);
        	padding: 20px;
       }
       .statusButtons{
       	height: 50px;
        padding: 0 20px 0px 20px;
    }

       .participantsTable{
       		/* position:fixed; */
       		max-width: 100%;
        	/* display:block; */
        	/* max-height: 200px; */
        	/* overflow: scroll;	 */
       }
       .participantsTableBody{
       	   display:block;
    	  /* height:200px; */
    	  /* overflow:auto; */
    	  width:100%;
       }
       .statusBottomFirst{
       	width:225px;
       	padding:5px 15px 5px 5px;
       	border-right: 1px solid #e5e5e5;
       	cursor: pointer;
       }
          .statusBottomFirstAnonymous{
       	width:700px;
       	padding:5px 15px 5px 5px;
       	border-right: 1px solid #e5e5e5;
       	cursor: pointer;
       }
        .statusBottomSecond{
          width:175px;
          padding:5px 15px 5px 15px;
          border-right: 1px solid #e5e5e5;
       }
        .statusBottomThird{
          width:350px;
          padding:5px 15px 5px 15px;
          border-right: 1px solid #e5e5e5;
       }

       .statusBottomFourth{
      	 width:110px;
          padding:5px 0px 5px 15px;
          padding-left:8px;
       }
        .statusBottomFourthAnonymous{
       	width:200px;
       	padding:5px 0px 5px 5px;
       	/* border-right: 1px solid #e5e5e5; */
       	cursor: pointer;
       }
       .statusBottomFirstData{
       	width:225px;
       }
          .statusBottomFirstDataAnonymous{
       	width:700px;
       }
       .addSectionHeader .sectionBox{
       	cursor:pointer;
       }
       .btn-remove-surveySection > svg {
        fill: #aeaeae;
    }
    .btn-remove-surveySection:hover > svg{
    	fill: #231f20;
    }

    .bv-survey-openSurveys-icon-div
    {
    	float:right;
    }
    .bv-survey-openSurvey-completeIcon
    {
    	width:20px;
    	height:20px;
    	position:relative;
    	top:4px;

    }
    .bv-survey-openSurvey-icon-div
    {
    	float: right;
        /* width: calc(100% - 675px); */
        width: 33px;
        padding-top: 7px;
    }

    .bv-survey-next-button
    {
    	position:relative;
    	left: -19%;
    	top:21px;
    }
    .bv-survey-multipleChoice-list-item
    {
    	padding-bottom:5px;
    	padding-top:5px;
    }
    .bv-survey-multipleChoice-span
    {
    	position: relative;
        top: -3px;
        margin-left: 25px;
        display: block;
    }
    .bv-survey-yesno-span
    {
    	position: relative;
        top: -3px;
        margin-left: 23px;
        display: block;
    }
    .bv-survey-question-center-div
    {
    	position:relative;

    }
    .bv-question-center-yesno-div
    {
    	position:relative;
    	margin:0 auto;
    	width:25%;
        padding-top: 55px;
    }
    .bv-question-center-meetsdoesnotmeet-div
    {
    	position:relative;
    	margin:0 auto;
    	width:35%;
    	padding-top: 50px;
    }
    .bv-question-center-truefalse-div
    {
    	position:relative;
    	margin:0 auto;
    	width:25%;
    	padding-top: 50px;
    }
    .bv-survey-question-yes-item
    {
    	padding-right:100px;
    }
    .bv-survey-question-yesno-label
    {
    	font-size:13px;
    	padding-left:5px;
    }
    .bv-survey-question-likert-scale-div
    {
    	position:relative;
    	width:90%;
    	height: 155px;
        overflow-x: auto;
        margin:0 auto;
    }
    .bv-survey-likert-scale-itemName-span
    {
    	padding-left:5px;
    }
    .bv-survey-openAnswer-div
    {
    	margin:0 auto;
    	padding-left:10%;
    	padding-right:10%;
    }
    .bv-question-detail-div
    {
    	height: 150px;
        overflow-x: auto;
        width: 629px;
        margin: 0 auto;
        padding-top:10px;
    }
    .bv-question-detail-div2
    {
    	height:100%;
    }
    .bv-question-bottom-footer
    {
    	position: absolute;
        right: 20px;
        bottom: -6px;
    }
    .bv-question-bottom-line
    {
    	position:relative;
    	top:8px;
    	padding-bottom:20px;
    }
    .bv-section-bottom-footer
    {
    	/* position: absolute;
        bottom: 22px; */
    }
    .bottom-overview-footer
    {
    	/* position: absolute;
        bottom: 40px;
        width: 93%;
        padding-left:10px; */
        /* padding-top:15px; */
        padding-top:15px;
    }
    .bottom-overview-footer .bottom-overview-footer-hr
    {
    	width:100%;
    	margin:0;
        padding-bottom: 5px;
    	border-top: 1px solid #D0C9C9;
    }
    .bv-section-bottom-right-arrow
    {
    	width: 17px;
        position: absolute;
        margin-left: 70px;
        margin-top: 5px;
        cursor:pointer;
    }
    .question-comments{
        height: calc(100% - 40px);
        ul{
          list-style-type: initial;
		  margin-left: 20px;
        }
     }
    .bv-question-comments-icon
    {
    	fill:#2574A7;
    	width:20px;
    	height:20px;
    }

    .progressValueList{
    	padding-left:215px;
    	color:#808080 !important;
    }
    .progress-bar-openSurvey-box .progressValue0{
    	position:absolute;
    	left:50%;
    	color:#808080 !important;
    }

    .progressPaddingPreview {
    	padding-left:300px;
    	color: #808080 !important;
    }
    .signatureModal-signature-outer {
    	/* width: calc(100% - 20px); */
    	height: 90px;
    	/*background: #AC2738;*/
    	/* margin: 0px 0px 7px 0px; */
    	position: absolute;
    	bottom: 55px;
    	margin-left:15px;
    	width:83%;
    }

    .signatureModal-signature-inside {
        width: 80%;
        height: 80px;
        background: #FFFFFF;
        margin: 5px 1% 5px 18%;
        position: absolute;
        text-align: center;
    }

    .signatureModal-signature-image {
    	height: 60px;
    }

    .signatureModal-signature-meta {
      position: absolute;
      bottom: 5px;
      width: calc(100% - 83px);
      right: 15px;
    }
    .signatureModal-pen {
     /*  width: 16%; */
      height: 75px;
      float: left;
      margin: 10px 0 0 1%;
    }
    .bv-signature-dialog .modal-dialog{
    	width: 400px;
    	position: absolute;
    	top: 50%;
    	left: 50%;
    	transform: translate(-50%,-50.1%) !important;
    	-webkit-transform: translate(-50%,-50.1%) !important;
        -ms-transform:  translate(-50%,-50.1%) !important;
    	margin: 0;
    }
    .signatureModal-sign-here-tooltip {
      position: absolute;
      left: 200px;
      top: 17px;
    }
    .userPreviewTop{
    	height:200px;
    }
    .userPreviewBottom{
    	height:calc(100% - 220px);
    }
    .userPreviewTopReport{
    	height:200px;
    }
    .userPreviewBottomReport{
    	height:calc(100% - 290px);
    	padding:0px 20px 20px 20px;
    }
    .userPreviewTopLeft{
    	width:450px;
    	padding-top: 10px;
    	height:100%;
    }
    .userPreviewTopRight{
    	width:calc(100% - 450px);
    	height:100%;
    	    /* padding-top: 20px; */
    }
    .userPreviewSectionBoxLeft {
        width: calc(100% - 200px);
        min-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .userPreviewSectionBoxRight {
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .userPreviewSectionBox {
        background: #e5e5e5;
        width: 100%;
        padding-top: 10px;
        min-height: 40px;
        margin-top: 15px;
        border: 1px solid rgb(218, 216, 216);
    }
    .userPreviewSectionDetailsLeft{
    	width:427px;
    }
    .userPreviewSectionDetailsRight{
    	width:calc(100% - 427px);
    	height:175px;
    }
    .userPreviewQuestionDetailsLeft{
    	width:500px;
    }
    .userPreviewQuestionDetailsRight{
    	width:calc(100% - 500px);
    	text-align: right;
    }
    .userPreviewQuestionDetailsRight:hover{
    	text-decoration:underline;
    	cursor:pointer;
    }
    .userPreviewResponseLeft{
    	width: 427px;
        text-align: left;
        word-wrap: break-word;
    }
    .userPreviewResponseRight{
    	width:calc(100% - 427px);
    	cursor:pointer;
    	height:175px;
    }

    .userPreviewSectionDetails{
    	min-height: 100px;
        padding: 15px;
        text-align: left;
        overflow: hidden;
    }
    .userPreviewQuestionDetails{
        min-height: 40px;
        padding: 10px;
    }
    .userPreviewResponse{
    	min-height: 65px;
    	padding: 15px 15px 25px 15px;
    	background:white;
    	overflow:hidden;
    }
    .participantTableBody .progressValue0 {
        padding-left: 150px;
        color: #808080 !important;
    }
    .lockIcon{
    	width: 12px;
        height: 11px;
        top: 10px;
        position: relative;
        margin-right: -12px;
    }
    .horizontalBar .bv-show-remove-btn-surveys{
    	display:list-item !important;
    }
    .statusBottomFirst .bv-default-fill-color-blue-plusIcon{
    	width:100%;
    	height:100%;
    }
    .bv-survey-manage-surveys-modal .modal-dialog{
    	width:920px;
    	height:500px;
    }
    .manage-survey-item{
    	height:50px;width:100%;
    	/* border-bottom: 1px solid #DCDCDC; */
    }
    .manage-surveys-surveyDue {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .manage-surveys-name{
    	overflow:hidden;text-overflow: ellipsis;
    	white-space: nowrap;
    }
    .manage-surveys-name:hover{
    	text-decoration:underline;
    	cursor:pointer;
    }
    #newSurveyCreation .middleBarIconSvg{
    	fill: #fff;
    }
    .form-group-survey-questionAndSection-margin {
        margin-bottom: 5px;
    }
    .bv-survey-question-multipleChoice-plusIcon
    {
    	padding-left:10px;
    }
    .bv-survey-greyoutLabel
    {
    	opacity:0.2;
    }
    .bv-survey-label-14
    {
    	font-size:14px;
    }
    .profileCountryName .bv-dropdown .dropdown-menu > li > a
    {
    	font-size:14px;
    	 color: #000;
    }
    .bv-survey-heading-label
    {
    	font-size:14px;
    	font-weight: 500;
    }
    .bv-survey-section-create-attachment-img,
    .bv-survey-section-create-attachment-img img,
    .bv-survey-section-create-attachment-img > div
    {
    	width:27px;
    	height:34px;
    }
    .bv-survey-section-center-label-attachment
    {
    	margin: 6px 0px 0px 0px;
        cursor: pointer;
    }

    .bv-survey-attachment-section-li
    {
    	position: relative;
        height: 40px;
        margin-bottom: 15px;
    }
    .bv-survey-back-button-label
    {
    	min-width: 100px;
        height: 28px;
        border: none;
        margin: 0px 0px 0px 7.5px;
        /* font-family: Arial; */
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.6px;
        line-height: 22px;
        color: #fff;
        outline: none !important;
        white-space: nowrap;
        overflow: hidden;
        text-align: end;
        padding: 0px 26px;
    }
    .bv-survey-arrow-back-create-questionManager
    {
        width: 17px;
        position: absolute;
        margin-left: 22px;
        margin-top: 5px;
    }
    .bv-survey-arrow-next-button-label
    {
    	min-width: 100px;
        height: 28px;
        border: none;
        margin: 0px 0px 0px 7.5px;
        /* font-family: Arial; */
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.6px;
        line-height: 22px;
        color: #fff;
        outline: none !important;
        white-space: nowrap;
        overflow: hidden;
        text-align: left;
        padding: 0 0px 0 0px;
        text-align: center;
        
    }
    .bv-survey-next-arrow-questionManager
    {
    	width: 17px;
        position: absolute;
        margin-left: -37px;
        margin-top: 5px;
    }
    .bv-survey-question-back-button-label
    {
    	min-width: 100px;
        height: 28px;
        border: none;
        margin: 0px 0px 0px 7.5px;
        /* font-family: Arial; */
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.6px;
        line-height: 22px;
        color: #fff;
        outline: none !important;
        white-space: nowrap;
        overflow: hidden;
        text-align: end;
        padding: 0 30px;

    }
    .bv-survey-question-back-arrow
    {
    	width: 17px;
        position: absolute;
        margin-left: 24px;
        margin-top: 5px;
        cursor:pointer;
    }
    .bv-survey-question-next-label
    {
    	min-width: 100px;
        height: 28px;
        border: none;
        margin: 0px 0px 0px 7.5px;
        /* font-family: Arial; */
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.6px;
        line-height: 22px;
        color: #fff;
        outline: none !important;
        white-space: nowrap;
        overflow: hidden;
        text-align: left;
        padding: 0px 30px;
    }
    .create-question-replyOptionsDiv-likertScaleon
    {
    	margin-top:100px;
    }
    .create-question-typeofquestion-likertScaleon
    {
    	position:relative;
    	top: 0px;

    }
    .create-question-typeofquestion-likertScaleoff
    {
    	position:relative;


    }

    .bv-likertscaleormultipleChoiceOn
    {
    	position: relative;
        padding-bottom: 20px;
    }
    .sectionCreateDivLeft > div > .mCustomScrollbar .mCSB_container
    {
    	height:400px;
    }
    .divforScroll-on
    {
    	height:320px;
    	width:100%;
    }
    .divforScroll-off
    {
    	height:0px;
    	width:100%;
    }
    .typeOfQuestionLabel
    {
    	padding-right:10px;
    }
    .yesnocheckbox
    {
    	margin:5px 0 20px;
    	line-height: 16px;
    }
    .14pxFont .bv-survey-itemlist li
    {
    	font-size:14px !important;
    }
    .survey-main-preview{
    	width: 100%;
        height: 100%;
        //min-width: 1000px;
        min-height: 520px;
        position: relative;
       /*  overflow: auto; */
    }
    .survey-second-section-preview{
    	width: 100%;
        height: 295px;
        overflow: hidden;
        /* position: relative; */
        /* padding: 20px; */
    }
    .sliderHeightPreviewMode{
    	height:300px;
    }

    .survey-report-section-box {
        width: 100%;
        min-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .survey-report-top{
    	padding: 20px 20px 20px 20px;
    }
    .survey-report-top-left{
    	width:93%;
    }
    .survey-report-top-left > div{
    	font-size:13px;
    }
    .survey-report-top-left > div >div{
    	font-size:13px;
    }
    .survey-report-top-right{
    	width:7%;
    }
    .surveyBarChart .nvd3 text{
    	font: 400 11px Arial;
    }
    .surveyPieChart .nvd3 text{
    	font: 400 11px Arial;
    }
    .surveyPieChart .nvd3-pie-chart svg{
    	height:50%;
    	width:50%;
    }
    .surveyBarChart .nvd3-pie-chart svg{
    	height:50%;
    	width:50%;
    }
    .survey-report-respondents-arrow{
    	float:left;
    	padding-right:5px;
    	height:15px;
    	width:15px;
    }
    .survey-report-horizontal-title {
      border-bottom: 8px solid #0A3F6E;
      /* margin: 5px 0px 5px 5px; */
      /* padding-left: 25px; */
      float:left;
      width:100%;
      margin-bottom: 15px;
    }
    .showHideSurveyPieChart{
    	width: 20px;
        height: 20px;
        /* top: 25px;
        left: 16px; */
        cursor: pointer;
       /*  z-index: 2; */
    }
    .survey-response-sign {
      height: 80px;
    }
    .sectionNameFont{
    	font-size:20px;
    	font-weight:500;
    }
    .sectionAttachmentsFont {
    	font-size:16px;
    	font-weight:500;
    }
    .sectionCreateDivLeft .caret{
    	    top: 5px !important;
    }
    .survey-admin-status-remove-usr-left{
    	width: 195px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
       }
       .survey-admin-status-remove-usr-leftAnonymous{
    	width: 670px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
       }
      .survey-admin-status-remove-usr-right{
      	width:30px;
      	padding-top:3px;
      }
      .bv-survey-removeAttendeesDiv1 { float:left; display:inline-block;height:100%; width:55%; padding: 0px 0px 0px 0px; border-right:1px solid #E5E5E5;}
      .bv-survey-removeAttendeesDiv2 { float:right; display:inline-block;height:100%; width:45%; padding: 0px 0px 0px 0px; border-right:1px gray;}

      .bv-survey-addAttendeesDiv1 { float:left; display:inline-block;height:100%; width:55%; padding: 0px 0px 0px 0px; border-right:1px solid #E5E5E5;}
      .bv-survey-addAttendeesDiv2 { float:right; display:inline-block;height:100%; width:45%; padding: 0px 0px 0px 0px; }

      .survey-published-bar{
        background: #aeaeae;
        float: left;
        width: 6px;
        position: absolute;
    	margin-top: -16px;
        height: 30px;
    }
    .bv-survey-addAttendeesDiv2-users {width:100%; height:100%;overflow-x: visible;/*overflow:hidden;*/font-weight:normal;}
    .bv-survey-addAttendeesDiv2-users {width:100%; height:100%;overflow-x: visible;/*overflow:hidden;*/font-weight:normal;}
    .bv-survey-addAttendeesDiv2-users .bv-item-list-main { padding: 0px 0px 0px 20px;}
    .bv-survey-addAttendeesDiv2-users .bv-item-list-main > div { height:480px; width:100%; }
    .bv-survey-addAttendeesDiv2-users .bv-item-list-grid { height:414px;}
    .bv-survey-addAttendeesDiv2-users .bv-item-list-ul1 { width: 273px;height:375px;}
    .bv-survey-addAttendeesDiv2-users .bv-item-list-ul1 .bv-item-list-ul2-li-checkbox     { line-height:40px; height:40px !important; display:block; position:absolute; width:50px !important; left:216px !important; }

    .bv-survey-addAttendeesDiv2-users .bv-item-list-ul1 .bv-item-list-ul2-li-checkbox .bv-item-small-close-svg { width: 22px; height: 22px; margin: 0px 0px 10px 10px; }
    .bv-survey-attachment-section-li:hover .bv-survey-removeBtn
    {
    	visibility: visible;

    }
    .bv-survey-removeBtn
    {
    	opacity:0.2;
    	width: 21px;
        float: right;
        display: inline;
        padding-top: 8px;
        visibility:hidden;
    }
    .bv-survey-removeBtn:hover
    {
    	width: 21px;
        float: right;
        display: inline;
        padding-top: 8px;
        opacity:1;
        visibility:visible;
    }
    #idSurveyQuestionManager{
    	min-width:1000px;
    }
    .surveyConsumerSectionDIVTop{
    	width:100%;
    	height:calc(100% - 120px);
    }
    .surveyConsumerSectionDIVTop-preview{
    	width:100%;
    	height:calc(100% - 120px);
    }
    .surveyConsumerSectionDIVBottom{
    	width:100%;height:120px;padding-top: 20px;
    }
    .surveyConsumerSectionDIVBottom-preview{
    	width:100%;height:120px;padding-top: 23px;
    }
    .surveyName > svg{
    	height: 10px;
    width: 11px;
    position: relative;
    fill: rgb(0, 0, 0);
    margin: 0px 0px 0 0px;
    display: none;
    cursor: pointer;
    }
    .disableEditSurveyItems {
        opacity: 0.5;
        pointer-events: none;
    }

    #idSurveyQuestionManager{
    	overflow-x: hidden;
    }

    #idSurveyQuestionManager .bv-survey-question-details-options-left-panel{
    	width: 100%;
        transition: width 125ms, opacity 92ms 22ms;
        -webkit-transition: width 125ms, opacity 92ms 22ms;
        height: 100%;
        opacity: 1;
    }
    #idSurveyQuestionManager.editing .bv-survey-question-details-options-left-panel{
    	width: calc(50% - 10px);
    }
    #idSurveyQuestionManager.editing.first-creation .bv-survey-question-details-options-left-panel{
    	width: calc(50% - 10px);
    	opacity: 0;
    }

    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel{
        transition: left 0ms 200ms, width 0ms 200ms, opacity 200ms, transform 200ms;
        -webkit-transition: left 0ms 200ms, width 0ms 200ms, opacity 200ms, transform 200ms;
        margin-top: 15px;
        height: calc(100% - 40px);
        position: relative;
        overflow: hidden;
        transform: translateX(120%);
        -webkit-transform: translateX(120%);
        opacity: 0;
        top: -100%;
        left: 0;
        width: 0;
    }
    #idSurveyQuestionManager.editing .bv-survey-question-details-options-right-panel{
    	transition: left 0ms 10ms, width 0ms 10ms, opacity 200ms, transform 200ms;
        -webkit-transition: left 0ms 10ms, width 0ms 10ms, opacity 200ms, transform 200ms;
    	transform: translateX(0);
    	-webkit-transform: translateX(0);
        opacity: 1;
        width: calc(50% - 10px);
        left: calc(50% + 10px);
    }
    #idSurveyQuestionManager.editing.first-creation .bv-survey-question-details-options-right-panel{
    	width: 100%;
    	left: 0;
    }
    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel .hidden{
    	transform: translateX(110%);
    	-webkit-transform: translateX(110%);
    	opacity: 0;
    }

    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel .survey-section-div,
    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel .survey-section-div .bv-survey-section-details{
    	height: 100%;
    }

    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel #sectionCreateDiv,
    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel #questionShowDiv,
    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel #sectionShowDiv,
    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel #sectionCreateDivOnClickHeader{
    	transition: 300ms all;
    	-webkit-transition: 300ms all;
        position: absolute;
        opacity: 0;
    }
    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel #sectionCreateDiv.opened,
    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel #questionShowDiv.opened,
    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel #sectionShowDiv.opened,
    #idSurveyQuestionManager .bv-survey-question-details-options-right-panel #sectionCreateDivOnClickHeader.opened{
    	opacity: 1;
    	z-index: 1;
    }

    #idSurveyQuestionManager #questionShowDiv .create-question-typeofquestion-likertScaleoff{
    	margin: 10px 0;
    }
    #idSurveyQuestionManager #questionShowDiv .typeofquestion-box{
    	height: 26px;
        line-height: 24px;
        margin-bottom: 10px;
    }
    #idSurveyQuestionManager #questionShowDiv .default-title{
    	height: 24px;
        text-align: right;
        line-height: 22px;
    }

    #idSurveyQuestionManager #questionShowDiv .yesnocheckbox hr{
    	margin: 8px 0;
    }
    #idSurveyQuestionManager #questionShowDiv .reply-options-box{
    	margin: 5px 0 20px;
    }
    #idSurveyQuestionManager #questionShowDiv .reply-options-box div{
    	display: inline-block;
        margin: 0 2px 0 10px;
        top: 2px;
        position: relative;
    }
    #idSurveyQuestionManager #questionShowDiv .reply-options-box div:first-child{
    	margin-left: 0px;
    }

    #idSurveyQuestionManager .bv-survey-question-details-options-left-panel .questionBox:hover #inner_circle{
    	fill: #ebeef0;
    }
    #idSurveyQuestionManager .bv-survey-question-details-options-left-panel .sectionBox #inner_circle{
    	fill: #e5e5e5;
    }
    #idSurveyQuestionManager .bv-survey-question-details-options-left-panel .sectionBox:hover #inner_circle{
    	fill: #DEDEDE;
    }

    .inviteUserSelectionDialog.survey .modal-dialog{
        transform: translate(-50%, -47%) !important;
        -webkit-transform: translate(-50%, -47%) !important;
        -ms-transform: translate(-50%, -47%) !important;
        top: 50%;
        margin-top: 0 !important;
    }
    /* surveys.css */

    /* conference.css */

    .bv-conf-dialog .modal-dialog {
    	position:relative;
    	margin-top:10px;
    	padding-top:0px;
    	min-width: 800px;
    	overflow: hidden;
    }

    /*.bv-conf-present-doc-dialog .modal-dialog {
    	position:relative;
    	margin-top:10px;
    	padding-top:0px;
    	min-width: 800px;
    	min-height:600px;
    }*/

    .bv-conf-present-doc-dialog .modal-dialog {
    	position: absolute;
    	margin-top: 2px;
    	padding-top: 0px;
    	min-width: 800px;
    	min-height: 600px;
    }

    /*.ngdialog-theme-default .bv-presentation-dialog {
    	width:80%;
    }*/

    /*.ngdialog.ngdialog-theme-default div.ngdialog-content {
        width: 80%;
    }*/

    .bv-conf-main-container-div {
    	height:500px;
    }

    .bv-conf-agenda-div-visible {
    	/*width:300px;*/
    	width:98%;
    	height:100%;
    	float:left;
    	border-right: 1px solid #515151;
    	border-right-height : 95%;
    }

    .bv-conf-agenda-div-visible1 {
    	width:300px;
    	height:100%;
    	float:left;
    	border-right: 1px solid #515151;
    	border-right-height : 95%;
    }

    .bv-conf-agenda-div-invisible{
    	width:0px;
    	height:0px;
    	display:none;
    }

    .bv-conf-non-agenda-div-with-agenda {
    	width:500px;
    	/*height:100%;*/
    	float:right;
    	margin:0 auto -30px;
        min-height:500px;
        height:auto !important;
        height:500px;
    }

    .bv-conf-non-agenda-div-wo-agenda {
    	width:800px;
    	/*height:100%;*/
    	float:right;
    	margin:0 auto -30px;
        min-height:500px;
        height:auto !important;
        height:500px;
    }

    .bv-conf-non-agenda-div-header {
    	width: 100%;
        height: 100px;
        /*background-color:blue;*/
        position:relative;
        left:0;
        top:0;
        border-bottom: 1px solid #515151;
        border-bottom-width : 90%;
    }


    .bv-conf-non-agenda-div-footer-with-agenda {
    	border-top: 1px solid #515151;
    	border-top-width : 90%;
    	width:100%;
        height: 80px;
    }

    .bv-conf-non-agenda-div-footer-wo-agenda {
    	width:0px;
    	height:0px;
    	display:none;
        /*background-color:green;*/
    }

    .bv-conf-non-agenda-div-container-with-agenda
    {
    	height:320px;
    }

    .bv-conf-non-agenda-div-container-wo-agenda
    {
    	height:400px;
    	overflow-y:auto;
    }


    /*.bv-conf-non-agenda-div-footer {
    	width:100%;
        height: 60px;
    }*/

    .bv-conf-agenda-div-visible attachments{
    	max-height: 400px;
    	overflow-y: auto;
    }

    .bv-conf-agenda-div-visible attachments tr{
    	margin: 10px 0px;
    }
    .bv-conf-agenda-div-visible attachments td>div{
    	margin-top:1px;
    	display: inline-block;
    	height: 39px;
    }
    .bv-conf-agenda-div-visible attachments td img{
    	width: 30px;
    }
    .bv-conf-agenda-div-visible attachments td>span{
    	position: relative;
    	left: 10px;
    	vertical-align: top;
    }

    .bv-conf-agenda-div-visible1 attachments{
    	max-height: 400px;
    	overflow-y: auto;
    }

    .bv-conf-agenda-div-visible1 attachments tr{
    	margin: 10px 0px;
    }
    .bv-conf-agenda-div-visible1 attachments td>div{
    	margin-top:1px;
    	display: inline-block;
    	height: 39px;
    }
    .bv-conf-agenda-div-visible1 attachments td img{
    	width: 30px;
    }
    .bv-conf-agenda-div-visible1 attachments td>span{
    	position: relative;
    	left: 10px;
    	vertical-align: top;
    }

    /*.bv-conf-attachment-row {
    	height: 65px;
    	margin: 5px 0 0 5px;
    	text-align: center;
    	width: 98%;
    	background: #FFFFFF;
    	overflow-x: auto;
    	overflow-y: hidden;
    	white-space: nowrap;
    	position: relative;
    	display: inline-block;

    }*/

    /*.bv-conf-attachment-row:hover {
    	overflow-x: auto;
    	overflow-y: hidden;
    }*/

    .bv-conf-message-attachment {
    	display: inline-block;
    	margin: 0 5px 0 0;
    }

    .bv-conf-message-attachment-img {
    	width: 30px;
    	height: 40px;
    	margin: 0 5px 5px 0;
    	box-shadow: 5px 5px 3px 0px #888888;
    	-webkit-box-shadow: 3px 10px 33px 0px #888888;
    	-moz-box-shadow: 3px 10px 33px 0px #888888;
    	border-top: 1px solid #DDDDDD;
    	border-left: 1px solid #DDDDDD;
    }

    .bv-conf-atchmt-icon {
    	display: inline-block;
    	margin: 0 5px 0 0;
    	height:45px;
    }
    .bv-conf-atchmt-icon  img{
    	width: 30px;
    	height: 40px;
    	margin: 5px 5px 5px 0;
    	box-shadow: 5px 5px 3px 0px #888888;
    	-webkit-box-shadow: 0px 0px 10px 3px #888888;
    	-moz-box-shadow: 0px 0px 10px 3px #888888;
    	/*border-top: 1px solid #DDDDDD;
    	border-left: 1px solid #DDDDDD;*/
    }

    /*.attachment-image:hover {
    	margin: 0 5px 0 0;
    }*/

    .bv-conf-message-attachment-name {
    	text-align:center;
    	width: 30px;
    	overflow: hidden;
    }

    .bv-conf-attendee span {
    	cursor: pointer;
        color: #036EAA;
        -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; standard-user-select: none;
    }

    .bv-conf-attendee_right_click_menu {
    	display: block;
    	position: fixed;
    	z-index: 1000;
    	float: right;
    	min-width: 180px;
    	padding: 7px 0;
    	margin: 0px 135px 0;
    	list-style: none;
    	background-color: #fff;
    	background-clip: padding-box;
    	border: 1px solid #ccc;
    	border: 1px solid rgba(0, 0, 0, .15);
    	border-radius: 4px;
    	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    	box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    }

    .bv-conf-alert-img {
    	top:7px;
    	height:30px;
    	width:30px;
    	display:block;
    	position:relative;
    	/*background:url('../assets/images/BVLiveryMeetingCenterChiclet@2x.png');*/
    }

    .bv-conf-present-btn{
    	color: #FFF;
    	min-width: 50px;
    	background-color: #3596BD;
    	border-style: none;
    	max-height: 17px;
    	min-height: 17px;
    	border-radius: 4px;
    	font-size: 12px;
    }

    .bv-conf-make-presenter-btn
    {
    	color: #FFF;
    	min-width: 50px;
    	background-color: #3596BD;
    	border-style: none;
    	max-height: 17px;
    	min-height: 17px;
    	border-radius: 4px;
    	font-size: 12px;
    }

    .bv-conf-pdf-view-lables{
    	display: inline;
    	font-size: 75%;
    	font-weight: bold;
    	line-height: 1;
    	/* color: #fff; */
    	text-align: center;
    	white-space: nowrap;
    	vertical-align: baseline;
    	border-radius: .25em;
    }

    #confTabs div li > a {
      line-height: 0.428571;
    }

    @-webkit-keyframes bv-conf-blinkConfIcon {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    @keyframes bv-conf-blinkConfIcon {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }

    .bv-conf-blinkConfIcon {
        animation-duration: 1s;
        -webkit-animation-duration: 1s;
        animation-name: bv-conf-blinkConfIcon;
        -webkit-animation: bv-conf-blinkConfIcon;
        animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
        animation-direction: alternate;
        -webkit-animation-direction: alternate;
        animation-timing-function: ease-in-out;
        -webkit-animation-timing-function: ease-in-out;
    }

    .bv-conf-atchmt-list ul
    {
    	list-style:none;
    }

    .bv-conf-atchmt-list li
    {
    	display:list-item;
    	cursor: pointer;
    }

    .bv-conf-atchmt-list a
    {
    	margin-top:2px;
    	display:block;
    	width:100%;
    	height:100%;
    }

    .bv-conf-atchmt-list-selected-a
    {
    	background-color:#E6E6E6!important;
    }

    .bv-conf-atchmt-list-unselected-a
    {
    	background-color:#FFFFFF!important;
    }

    .bv-conf-atchmt-list ul li
    {
    	height:40px;
    }

    .bv-conf-atchmt-list .icon
    {
    	display:block;
    	float:left;
    	position:relative;
    	padding-left:5px;
    }

    .bv-conf-atchmt-list .icon .iconImage img
    {
    	max-height: 38px;
    	max-width: 35px;
    	float:left;
    }

    .bv-conf-atchmt-list .bv-atchmnt-name
    {
    	color: #333;
    	height:50px;
    	padding-top: 7px;
    	padding-left:5px;
    	text-overflow: ellipsis;
    	overflow: hidden;
    	white-space: nowrap;
    	margin-right: 50px;
    	/*max-width: 200px;*/
    }


    .bv-conf-pdf-nav-cntrl-div
    {
    	position: relative;
    	display: inline-block;
    	vertical-align: middle;
    	background-color:gray;
    	color:#FFF;
    }

    .bv-conf-pdf-nav-cntrl-div-span{
    	color:#FFF;
    	display: inline;
    	font-size: 75%;
    	font-weight: bold;
    	line-height: 1;
    	/* color: #fff; */
    	text-align: center;
    	white-space: nowrap;
    	vertical-align: baseline;
    	border-radius: .25em;
    }

    .bv-conf-pdf-goto-next-page-btn
    {
        background-image: url("../assets/images/findbarButton-previous-rtl.png"); /* 16px x 16px */
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: 0px 0px;
        border: none;
        cursor: pointer;
        height: 16px;
        width:16px;
        vertical-align: middle;
    }

    .bv-conf-pdf-goto-prev-page-btn
    {
        background-image: url("../assets/images/findbarButton-next-rtl.png"); /* 16px x 16px */
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: 0px 0px;
        border: none;
        cursor: pointer;
        height: 16px;
        vertical-align: middle;
        width:16px;
    }

    .bv-conf-view-doesnot-contains-agenda
    {
    	width:0%;height:100%;float:left;display:none;padding-top:10px;padding-bottom:10px;
    }
    .bv-conf-view-atchmt-without-agenda
    {
    	width:100%;height:100%;float:left;padding-top:10px;padding-bottom:10px;padding-left:10px;padding-right:10px;
    }


    .bv-conf-attachment-row { height: 100%; text-align: center; width: 100%; overflow-x: hidden;	white-space: nowrap; padding-top: 0px; overflow-y: auto; }
    .bv-conf-attachment-row .bv-slider > ul { text-align: center; min-width: 565px; min-height:465px; }
    .bv-conf-attachment-row .bv-slider > ul > li{ box-shadow: none !important; }
    .bv-conf-attachment-row .bv-slider .bv-slider-items-2 > li { width: 42%; margin: 20px 3.9%;}

    /*.bv-conf-attachment-row:hover {
    	overflow-x: hidden;
    }*/

    .bv-conf-attachment-li {
    	height: calc(100% - 70px);
    	/* background: green; */
    	margin-bottom: 20px;
    	vertical-align: middle;
    	display: inline-block;
    	position: relative;
    	width: 100%;
    	box-shadow: 0 0 5px 2px rgba(0, 0, 0, .175);
    }

    /*.bv-conf-attachment-li:before {
    	content: '';
    	display: inline-block;
    	height: 100%;
    	vertical-align: middle;
    	margin-right: -0.25em; /* Adjusts for spacing
    }*/

    .bv-conf-attachment-image {
    	width: 100%;
    	cursor: pointer;
    	display: inline-block;
    	max-height: 100%;
    	max-width: 99%;
    	vertical-align: middle;
    	width: 99%;
    }

    .bv-conf-attachment-name {
    	text-align:center;
    	width: 100%;
    	overflow: hidden;
    	height:20px;
    	top: 15px;
        position: relative;
        display: block;
    }
    .bv-conf-attachment-name > div{
        margin: 0 auto;
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .bv-conf-file-attachment-image {
    	border: 1px solid #DDDDDD;
    }

    .bv-conf-message-attachment {
    	height:100%;
    	width:65%;
    	display: inline-block;
    	margin: 0 0px 0 10px;
    	padding-bottom: 4px;
    	padding-top:7px;
    }

    /*.bv-conf-agenda-div {
    	height:100%;
    	overflow:auto;*/
    	/*overflow:visible;*/
    	/*width:100%;*/
    	/*padding:5px;
    	border: 1px solid #ccc;
    	border: 1px solid rgba(0, 0, 0, .15);
    	border-radius: 4px;
    	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    	box-shadow: 0 6px 12px rgba(0, 0, 0, .175);*/
    /*}*/

    .bv-conf-agenda-div {
    	height:100%;
    	width:100%;
    	overflow-y: hidden;
    	overflow-x: hidden;
    }

    /*.bv-conf-agenda-div:hover{
    	overflow-y: auto !important;
    }
    */

    .bv-conf-agenda-list {
    	width: 100%;
    	height: 100%;
    	overflow-x: hidden;
    	overflow-y: hidden;
    }

    .bv-conf-agenda-list:hover {
    	overflow-y: auto;
    }

    .bv-conf-agenda-list .mCSB_container {
        overflow: visible;
    }

    .bv-conf-details-desc {
    	height:30px; width:100%; overflow:auto;
    }

    .bv-conf-details-address {
    	height:30px; width:100%; overflow:auto;
    }

    .bv-conf-details-location {
    	width:100%; overflow-x:auto; overflow-y:hidden;
    }

    .selectedCardShadow > div {
    	box-shadow: 0px 0px 0px 3px #2273A5;
    }


    .bv-conf-view-main {
    	white-space: nowrap;
        overflow-y: hidden;
        display: flex;
    }

    .bv-conf-view-left-div {
    	width:280px !important;
    	height:100% !important;
    	z-index: 1;
        display: inline-block;
        white-space: normal;
    	  /*      box-shadow: -7px 0px 39px -4px #ccc inset;
    	   -moz-box-shadow: -7px 0px 39px -4px #ccc inset;
    	-webkit-box-shadow: -7px 0px 39px -4px #ccc inset;*/
    }

    .bv-conf-view-right-div {
    	width: calc(100% - 280px);
        height: 100%;
        float: right;
        display: inline-block;
        white-space: normal;
        z-index: 2;
    }

    /************Start of styles for Conf list View ***********/
    .bv-conf-font { color:#000000; }
    .bv-conf-font-color-dark { color:#515151; }
    .bv-conf-font-color-light {	color:#515151; }
    .bv-conf-font-color-gray { color:#A5A5A5;}
    .bv-conf-event-icon { margin-top:14px; }
    .bv-conf-event-selected { width:280px;padding:15px 7px 7px 15px;background-color:#f0f0f0; position: relative;}
    .bv-conf-event-not-selected { width:280px;padding:15px 7px 7px 15px;cursor:pointer; position: relative; }
    /*.bv-conf-event-not-selected:hover { background-color: #f4f4f4;}
    */.bv-conf-list-anchor a,.bv-conf-list-anchor a:link,.bv-conf-list-anchor a:hover,.bv-conf-list-anchor a:active,.bv-conf-list-anchor a:focus,.bv-conf-list-anchor a:visited {	color:#515151;	background-color:transparent; text-decoration: none; outline: 0; }
    .bv-conf-list-event-icon-div { width:15px;height:15px;/*margin-right:3px;float:right;*/ position: absolute; left: 248px; }
    .bv-conf-list-event-icon-div .dropdown-menu { top: 29px; left: -124px; min-width: 140px; border-radius: 0px !important;}
    .bv-conf-list-event-icon-div .dropdown-menu > li > a { color: #000000; padding: 3px 10px;}
    .bv-conf-list-event-icon-div .dropdown-menu > li:hover > a { background-color: #f5f5f5; }
    .bv-conf-list-event-icon-div svg { height: 17px; width: 17px;}
    .bv-conf-list-event-name-time-div { width:calc(100% - 30px); height:50px; float:left; text-overflow:ellipsis; overflow:hidden; white-space:nowrap; padding-top:5px; }
    .bv-conf-list-event-name { font-weight:500; width:195px; max-width:	195px;height:19px;line-height:17px;text-overflow: ellipsis;overflow:hidden; }
    .bv-conf-list-event-time { font-weight:400; width:100%;height:17px;line-height:17px;padding-bottom:3px;}
    .bv-conf-list-event-details { height:17px; font-size:14px; text-overflow: ellipsis; }
    .bv-conf-list-event-blue-div { height:30px;margin-top:15px;width:100%; color:#428bca;font-weight:400;cursor:pointer;}
    .bv-conf-list-event-blue-attendees{	width:33%;float:left;text-align:left; }
    .bv-conf-list-event-blue-details {width:33%;float:right;text-align:center;}
    .bv-conf-list-event-start {
    	float: right;
    	margin-right: 10px;
    	margin-top: -2px;
    }

    .bv-conf-list-event-blue-details,
    .bv-conf-list-event-blue-attendees{
        opacity: 0.3;
        cursor: default;
        pointer-events: none;

        &.enabled{
            opacity: 1;
            cursor: pointer;
            pointer-events: inherit;
        }
    }

    /************End of styles for Conf list View ************/

    /************Start of styles for Conf Details Popup ***********/
    .bv-conf-details .modal-dialog { width:570px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important;}
    .bv-conf-details-container-div { width: 100%; height: 470px; font-weight: normal; color:#000000; }
    .bv-conf-details-col1 { width:125px; float:left; font-weight: 500; }
    .bv-conf-details-col2 { width:400px; float: right; display: inline-block; position: absolute; }
    .bv-conf-details-col2-row1 {
    	height:30px;
    	width: 100%;
    	display: table;
    	margin-left: 10px;
    }

    .bv-conf-details-col2-row1-row1 {
        height: 35px;
        width: 100%;
        display: inline-table;
        position: relative;
    }

    .bv-conf-details-col2-row1-row2 {height:10px;width:100%;position:absolute;display:inline-block;}
    .bv-conf-details-colon {width:10px;display:inline-block;vertical-align:top;}
    .bv-conf-details-ui-dial-in-and-code {width:55px;display:inline-block;}
    .bv-conf-details-ui-dial-in-and-code-value {width:324px;display:inline-block;}
    .bv-conf-details-divider-row { width:100%; height:1px; background-color: #E5E5E5; margin-top:30px; margin-bottom:20px;}
    .bv-conf-details-row1 {	height:50px; overflow-x:auto; margin:20px 20px 0px 20px; }
    .bv-conf-details-row2 {	height:80px; overflow-x:auto; margin:0px 20px 0px 20px; }
    .bv-conf-details-row3 {	height:114px; overflow-x:auto; margin:0px 20px 0px 20px; }
    .bv-conf-details-row4 {	height:65px; overflow-x:auto; margin:0px 20px 30px 20px; }
    .bv-conf-details-ui-address-location {width:115px;display:inline-block;vertical-align:top;}
    .bv-conf-details-location-val {width:264px;display:inline-block;text-overflow:ellipsis;overflow: hidden;}
    .bv-conf-details-address-val {width:264px;height:45px;display:inline-block;overflow-x:hidden;overflow-y:hidden;}
    .bv-conf-details-address:hover {overflow-y: auto !important;}
    .bv-conf-details-notes-val {height:108px;display:inline-block;}
    /*overflow-x:hidden;overflow-y:hidden;}*/
    /*.bv-conf-details-notes-val:hover {overflow-y: auto !important;}
    */.bv-conf-details-attendee-statuts { line-height:17px;}
    .bv-conf-details-attendee-row { width:100%; height:1px; background-color: #E5E5E5;  margin-top: 15px;}
    .bv-conf-details-attandee-response {
    	display: table-cell;
        width: 33.33%;
        text-align: center;
        /*border: 1px solid;*/
        position: relative;
    };

    .bv-conf-details-ui-accept {height:20px;margin-left:10px;width:76px;display:inline-block;}
    .bv-conf-details-ui-decline {height:20px;margin-left:84px;width:58px;display:inline-block;text-align:center;}
    .bv-conf-details-ui-tentative {height:20px;margin-left:91px;width:75px;display:inline-block;text-align:right;}
    .bv-conf-details-ui-accept-icon {height:10px;margin-left:10px;width:56px;display:inline-block;margin-bottom:2px;}
    .bv-conf-details-ui-decline-icon {height:10px;margin-left:105px;width:57px;display:inline-block;text-left:center;overflow:hidden;margin-bottom:2px;}
    .bv-conf-details-ui-tentative-icon {height:10px;margin-left:97px;width:68px;display:inline-block;text-align:left;;margin-bottom:2px;}
    .bv-conf-status-active { cursor:none;color:#515151;
    	border-bottom: 10px solid;
        padding-bottom: 5px;
    }
    .bv-conf-status-non-active {cursor:pointer;color:#808080;}
    /************End of styles for Conf Details Popup ************/

    /************Start of styles for Attendee Popup ***********/
    .bv-conf-attendee .modal-dialog { width:510px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important; }
    .bv-conf-attendee-container-div { width: 100%; height: 360px; font-weight: normal; }
    .bv-conf-attendee-col1 { height:100%; width:240px; float:left; font-size:20px; font-weight:400;  }
    .bv-conf-attendee-col1-row1 { height: calc(100% - 50px);}
    .bv-conf-attendee-col1-row2 { height: 43px;display:inline-block;text-overflow:ellipsis;overflow: hidden;}
    .bv-conf-attendee-created-by-div { padding: 7px 20px 20px 20px; color:#808080; font-size:14px; width:240px; text-overflow:ellipsis;overflow:hidden;white-space:nowrap;}
    .bv-conf-attendee-col2 { height:100%; width: calc(100% - 240px); float: right; display: inline-block; position: absolute; }
    .bv-conf-attendee-row { height:55px;line-height:55px;width:100%; cursor: pointer; display:block; text-align:left; font-size:20px; font-weight:400; margin-top:7px; margin-bottom:13px; padding: 0px 0px 0px 20px; }
    .bv-conf-attendee-row-selected { background-color: #2273a5; color:#FFFFFF;}
    .bv-conf-attendee-row-not-selected { color: #2273a5;}
    .bv-conf-attendeeStatusBadge { position:relative;margin-left:12px; margin-bottom: 7px; display: inline-block; }
    .bv-conf-attendeeStatusBadge[data-badge]:after { content:attr(data-badge); position:absolute; top:-12px; right:-16px; background-color: #2273a5; color:#FFFFFF; width:25px;height:25px;line-height:25px; text-align:center; border-radius:50%;}
    .bv-conf-attendee-row-selected .bv-conf-attendeeStatusBadge[data-badge]:after { background-color: #FFFFFF; color: #2273a5; }
    .bv-conf-attendee-display-div { height:100%; padding: 0px 20px 20px 20px;}
    .bv-conf-attendee-display-div .bv-scroll-list> .scroll-list-items .scroll-list > li { display:block; width:100%; line-height:40px; height:40px; border-bottom:0px;}
    .bv-conf-attendee-display-div .bv-scroll-list> .scroll-list-items .scroll-list > li:hover { cursor: default !important; background-color: white !important;}
    .bv-conf-attendee-display-div .bv-scroll-list > .scroll-list-items { height: 253px; display: inline-block; width: 100%; }
    .bv-conf-attendee-display-div .bv-scroll-list > filter span { font-weight:normal;color:#000000;}
    .bv-conf-attendee-display-div .bv-scroll-list > filter input { margin-top:5px;}
    /************End of styles for Attendee Popup ************/

    /************Start of styles for Conf Settings Popup ***********/
    .bv-conf-settings .modal-dialog {
    	width:920px;
    /*  	position: absolute;
     	margin: auto;
        top: calc(50% - 300px);
        bottom: 0;
        left: 0;
        right: 0; */
     }
    .bv-conf-settings-container-div { width:100%; height: 569px; font-weight: normal; color:#000000; margin: 0px 20px 0px 0px; }
    .bv-conf-settings-col1 { height:100%; width:230px; float:left;  font-weight: 500;}
    .bv-conf-settings-col2 { height:100%; width:690px; float:right; display: inline-block; position: absolute; }
    .bv-conf-settings-row { height:60px;line-height:55px;width:100%; font-size:20px; font-weight:400; cursor:pointer; display:block; text-align:left; margin-top:7px; margin-bottom:13px; padding: 0px 0px 0px 20px;/* white-space: nowrap;*/}
    .bv-conf-settings-row > span {
        line-height: normal;
        vertical-align: middle;
        white-space: inherit;
        display: inline-block;
    }
    .bv-conf-settings-row-selected { background-color: #2273a5; color:#FFFFFF;}
    .bv-conf-settings-row-not-selected { color: #2273a5;}
    .bv-conf-settings-col2-row1 { width:100%; height:calc(100% - 70px);}
    .bv-conf-settings-col2-row1 > div { padding: 20px 20px 0px 20px;}
    .bv-conf-settings-col2-row2 { width:100%; height:70px; }
    .bv-conf-settings-col2-row2-button-div { margin: 20px 20px 20px 0px; float: right;}
    .bv-conf-adjustTimeDiv1 { position:relative; float:left; display:inline-block;height:480px; width:calc(100% - 110px); padding:0px; border-right:1px solid #E5E5E5;}
    .bv-conf-adjustTimeDiv2 { float:right; display:inline-block;height:100%; width:110px;}
    .bv-conf-addAttendeesDiv1 { float:left; display:inline-block;height:100%; width:55%; padding: 0px 0px 0px 0px; border-right:1px solid #E5E5E5;}
    .bv-conf-addAttendeesDiv2 { float:right; display:inline-block;height:100%; width:45%; padding: 0px 0px 0px 0px; }
    .bv-conf-addAttendeesDiv2-users {width:100%; height:100%;overflow-x: visible;/*overflow:hidden;*/font-weight:normal;}
    .bv-conf-addAttendeesDiv2-users .bv-item-list-main { padding: 0px 0px 0px 20px;}
    .bv-conf-addAttendeesDiv2-users .bv-item-list-main > div { height:480px; width:100%; }
    .bv-conf-addAttendeesDiv2-users .bv-item-list-grid { height:414px;}
    .bv-conf-addAttendeesDiv2-users .bv-item-list-ul1 { width: 273px;height:375px;}
    .bv-conf-addAttendeesDiv2-users .bv-item-list-ul1 .bv-item-list-ul2-li-checkbox     { line-height:40px; height:40px !important; display:block; position:absolute; width:50px !important; left:216px !important; }

    .bv-conf-addAttendeesDiv2-users .bv-item-list-ul1 .bv-item-list-ul2-li-checkbox .bv-item-small-close-svg { width: 22px; height: 22px; margin: 0px 0px 10px 10px; }

    .bv-conf-removeAttendeesDiv1 { float:left; display:inline-block;height:100%; width:55%; padding: 0px 0px 0px 0px; border-right:1px solid #E5E5E5;}
    .bv-conf-removeAttendeesDiv2 { float:right; display:inline-block;height:100%; width:45%; padding: 0px 0px 0px 0px; border-right:1px gray;}

    .bv-conf-activeInactive-ul2-li-details
    {
    	display: inline-block;
    	width: 50%;
    }

    .bv-conf-activeInactive-ul2-li-radiobutton
    {
    	position: relative;
        display: inline-block;
        width: 40%;
        /* left: 415px; */
        line-height: 0px;
        float: right;
        text-align: right;
    }

    .bv-conf-activeInactive-li-details
    {
    	display: inline-block;
    	width: 50%;
    }

    .bv-conf-activeInactive-div-radio
    {
    	height: 20px;
    	width: 20px;
    	display: inline-block;
    }

    .bv-conf-activeInactive-div-radio-lbl
    {
    	display: inline;
    	margin-right: 5px;
    }


    .bv-conf-event-list > ul {height:100%; overflow:hidden;}
    .bv-conf-event-list > ul:hover {overflow-y:auto;}
    .bv-conf-event-list > ul > li { display:block; width:100%; line-height:60px; height:60px; border-bottom: 1px solid #ccc; margin-bottom:20px;padding-right:15px;}
    .bv-conf-event-list > ul > li:hover{ cursor:pointer; }



    .bv-conf-time-list-selected { background-color:#2273a5; color:#FFFFFF; }
    .bv-conf-time-list > ul {height:100%; overflow:hidden;}
    .bv-conf-time-list > ul:hover {overflow-y:auto;}
    .bv-conf-time-list > ul > li { display:block; line-height:35px; height:35px; padding:0px 10px 20px 20px;}
    .bv-conf-time-list > ul > li:hover{ color:#000000; cursor:pointer; }

    .bv-conf-attendee-ul1 {height:480px; position:relative; display:inline-block;}
    /*.bv-conf-attendee-ul1:hover {overflow-y:auto;}*/
    .bv-conf-attendee-ul1-event-item { display:block; /*width:calc(100% - 10px);*/ line-height:60px; height:60px; border-bottom:1px solid #ccc; margin-bottom:5px; padding-top:9px;}
    .bv-conf-attendee-ul1-event-item:first-child{
        border-top: 1px solid #ccc;
    }

    .bv-conf-activeInactive-ul{
    	height: 480px;
        position: relative;
        display: block;
        overflow: hidden;
        width: 100%;
    }

    .bv-conf-event-active-name-div
    {
    	height: 50px;
        width: 100%;
        margin-top: 5px;
    }

    .bv-conf-adjustTimeDiv1-ul2-li-details   { line-height:60px; height:60px; display:inline-block; position:relative; width:60%;}
    .bv-conf-adjustTimeDiv1-ul2-li-checkbox  { line-height:30px; height:30px; display:inline-block; position:relative; width:50px; float:right;}

    .bv-conf-addAttendees-ul2-li-details   { line-height:60px; height:60px; display:inline-block; position:relative; width:295px;}
    .bv-conf-addAttendees-ul2-li-checkbox  { line-height:30px; height:30px; display:inline-block; position:relative; width:30px; float:right;}

    .bv-conf-removeAttendees-ul2-li-details   { line-height:60px; height:60px; display:inline-block; position:relative; width:295px;}
    .bv-conf-removeAttendees-ul2-li-checkbox  { line-height:30px; height:30px; display:inline-block; position:relative; width:30px; float:right;}

    .bv-conf-event-setting-dt-div{ height:50px; width:100%;}
    .bv-conf-event-setting-dt-div-name{ font-weight:500; height:24px; line-height:15px; width:100%; margin-bottom:4px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap; }
    .bv-conf-event-setting-dt-div-time{ height:20px; font-weight:normal;line-height:2px; width:100%;}
    .bv-conf-event-setting-chkbox-div { height:50px; width:23px; padding-top:10px; float:right;}
    /*.bv-version-tracker-li-selection-div { margin-top:6px; height:25px; width:25px;}*/

    /************End of styles for Conf Settings Popup ************/

    .bv-mobile-meeting-details header{
        padding: 20px 20px 0;
        margin-bottom: 10px;
        font-size: 14px;
        position: relative;
        border-bottom: 1px solid #ccc;
    }
    .bv-mobile-meeting-details time{
        font-weight: 600;
        display: block;
        margin-bottom: 10px;
    }
    .bv-mobile-meeting-details youare {
        display: block;
    }
    .bv-mobile-meeting-details lastupdated{
        display: block;
    }
    .bv-mobile-meeting-details buttons{
        display: block;
        margin: 15px 0px;
    }
    .bv-mobile-meeting-details buttons > button{
        background-color: inherit;
        border: inherit;
        padding: 0;
        font-size: 16px;
        color: #428bca;
        padding: 6px;
        margin: -6px -6px;
    }
    .bv-mobile-meeting-details buttons > button:last-child{
    	float: right;
    }
    .bv-mobile-meeting-details attachments > div{
    	height: calc(100% - 163px);
    }
    .bv-mobile-meeting-details attachments .bv-slider > ul > li{
    	width: 100% !important;
        margin: 20px 0 !important;
        box-shadow: inherit;
    }
    .bv-mobile-meeting-details attachments .bv-slider > ul{
    	min-width: inherit;
    	padding: 0px 16.36% 65px;
    }
    .bv-mobile-meeting-details attachments .bv-slider > ul > li > ul > li:first-child{
    	margin-bottom: 20px;
    }
    .bv-mobile-meeting-details attachments .bv-slider > ul > li > card{
        position: relative;
        display: block;
        width: 80%;
        margin: 0 auto;
        height: 100%;
        box-shadow: 0px 0px 15px -3px #000;
    }

    .bv-mobile-meeting-details closebtn{
        position: absolute;
        top: -33px;
        left: 12px;
    }
    .bv-mobile-meeting-details closebtn svg{
        height: 20px;
        width: 20px;
        fill: #fff;
    }
    .bv-mobile-meeting-details buttons > button:first-child .bv-button-feedback-type-3{
    	height: 72px !important;
        width: 85px !important;
        top: -19.5px !important;
        left: 0px !important;
    }
    .bv-mobile-meeting-details buttons > button:last-child .bv-button-feedback-type-3{
    	height: 72px !important;
        width: 61px !important;
        top: -19.5px !important;
        left: 0px !important;
    }

    .bv-conf-mobile-details section{
    	padding: 15px;
        border-bottom: 1px solid #ccc;
        font-size: 14px;
        min-width: 240px;
    }
    .bv-conf-mobile-details section:last-child{
    	border-bottom: inherit;
    }
    .bv-conf-mobile-details conference,
    .bv-conf-mobile-details location,
    .bv-conf-mobile-details notes,
    .bv-conf-mobile-details attend{
    	display: block;
        margin-bottom: 10px;
        font-weight: bold;
    }
    .bv-conf-mobile-details dialin,
    .bv-conf-mobile-details areacode,
    .bv-conf-mobile-details meetingroom,
    .bv-conf-mobile-details addr{
        display: block;
    }
    .bv-conf-mobile-details dialin > span:first-child,
    .bv-conf-mobile-details areacode > span:first-child,
    .bv-conf-mobile-details meetingroom > span:first-child,
    .bv-conf-mobile-details addr > span:first-child{
        display: inline-block;
        width: 104px;
        text-align: right;
        color: #808080;
        font-size: 14px;
        margin: 2px 0;
    }
    .bv-conf-mobile-details dialin > span:last-child,
    .bv-conf-mobile-details areacode > span:last-child,
    .bv-conf-mobile-details meetingroom > span:last-child,
    .bv-conf-mobile-details addr> span:last-child{
        display: inline-flex;
        width: calc(100% - 110px);
        min-width: 40px;
    }
    .bv-conf-mobile-details .bv-conf-status-active{
    	border-bottom: 5px solid;
        padding-bottom: 5px;
    	color: #000;
    }
    .bv-conf-mobile-details buttons{
    	padding-bottom: 29px;
        border-bottom: 1px solid #ccc;
        display: block;
    }
    .bv-conf-mobile-details buttons > span{
        left: calc(50% - 80px);
        position: relative;
        float: left;
    }
    .bv-conf-mobile-details buttons > span:first-child{
    	left: inherit;
    	float: left;
    }
    .bv-conf-mobile-details buttons > span:last-child{
    	left: inherit;
    	float: right;
    }
    /* .bv-conf-mobile-details .bv-modal-body > div,
    .bv-conf-mobile-attendee .bv-modal-body > div{
    	overflow-y: auto;
    } */
    .bv-conf-mobile-details wrap{
        min-height: 100%;
        display: block;
        margin-bottom: -90px;
    }
    .bv-conf-mobile-details wrap:after{
        content: "";
        display: block;
        height: 90px;
    }
    .bv-conf-mobile-details buttons .bv-button-feedback-type-1{
    	left: 3px;
        top: -10px;
    }


    .bv-conf-mobile-attendee footer{
        color: #808080;
        font-size: 14px;
        padding: 0 20px;
    }
    .bv-conf-mobile-attendee .bv-conf-attendee-row{
        color: #2273a5;
     }
    .bv-conf-mobile-attendee .bv-conf-attendee-row{
    	margin: 0;
    	padding: 0;
    }
    .bv-conf-mobile-attendee section{
        border-bottom: 1px solid #ccc;
    /*     padding: 20px; */
    }
    .bv-conf-mobile-attendee section .bv-conf-attendee-row{
    	padding: 20px;
    }
    .bv-conf-mobile-attendee .bv-conf-attendee-row{
    	height: 95px;
    }

    .bv-conf-mobile-attendee section:last-child{
    	border: inherit;
    }
    .bv-conf-mobile-attendee wrap{
    	min-height: 100%;
        display: block;
        margin-bottom: -40px;
    }
    .bv-conf-mobile-attendee wrap:after{
    	content: "";
        display: block;
        height: 40px;
    }
    .bv-conf-mobile-attendee .portalUserAvatar{
    	float: inherit;
    }

    .bv-conf-mobile-attendee section.closed{
    	padding: 0 20px;
    	border-bottom: inherit;
    }
    .bv-conf-mobile-attendee section.opened{
    	padding: 20px;
    	border-bottom: 1px solid #ccc;
    }
    .bv-conf-mobile-attendee section.opened:last-child{
    	border-bottom: inherit;
    }
    .bv-conf-mobile-attendee section{
    	transition: 300ms all;
    	-webkit-transition: 300ms all;
    }
    .bv-conf-mobile-attendee section > ul{
    	height:0;
    	overflow: hidden;
    	transition: 300ms all;
    	-webkit-transition: 300ms all;
    }
    .bv-conf-mobile-attendee section.opened > ul{
    	height: inherit;
    }
    .bv-conf-mobile-attendee section.bv-bottom-border-show{
    	border-bottom: 1px solid #ccc;
    }
    .bv-conf-mobile-attendee section.bv-bottom-border-initial{
    	border-bottom: inherit;
    }
    .bv-conf-mobile-attendee .portalUserAvatar{
        margin-bottom: 10px;
        position: relative;
    }
    .bv-conf-mobile-attendee section > ul > li:last-child .portalUserAvatar{
    	margin-bottom: inherit;
    }

    .bv-conf-mobile-attendee .portalUserName{
    	font-size: 16px;
    	max-width: inherit;
    }

    /* running confs */
    .bv-running-conf-list-dialog {
    	z-index: 100 !important;
    }

    .bv-running-conf-list-dialog .modal-dialog {
    	width:500px;
     	position: absolute;
    	top: 50%;
    	left: 50%;
    	transform: translate(-50%,-50%) !important;
    	-ms-transform: translate(-50%,-50%) !important;
    	-webkit-transform: translate(-50%,-50%) !important;
    	margin: 0;
    	z-index: 100 !important;
    }

    .bv-running-conf-list-main {
    	width:100%;
    	margin: 0px 20px 0px 0px;
    }

    .bv-running-conf-list-header {
    	width: 100%;
    	height: 35px;
    	line-height: 35px;
    	padding-left: 20px;
    }

    .bv-running-conf-list-container {
    	padding: 20px;
    	height: 180px;
    }

    .bv-multiple-running-conf-list-container{
    	height: 290px;
    }

    .bv-running-conf-list-cancel {
    	position: absolute;
    	right: 20px;
    	bottom: 20px;
    }

    .bv-running-conf-details {
    	position: relative;
    }

    .space-between-meetings {
    	margin-top: 15px;
    	padding-top: 15px;
    }

    .bv-event-join-btn {
    	position: absolute;
    	right: 0px;
    	top: 4px;
    }

    .bv-event-leave-btn {
    	position: absolute;
    	right: 0px;
    	top: 4px;
    }

    .bv-running-conf-metadetails {
    	margin-top: 15px;
    }
    /* running confs end */

    /* Event Presentation */

    .bv-event-presentation {
    	z-index: 100 !important;
    }
	.bv-event-presentation-overflow{
		z-index: 99 !important;
	}
    .bv-event-presentation .modal-dialog {
    	width:950px;
     	/*position: absolute;
    	top: 50%;
    	left: 50%;
    	transform: translate(-50%,-50%) !important;
    	-ms-tranform: transform: translate(-50%,-50%) !important;
    	-webkit-transform: translate(-50%,-50%) !important;
    	margin: 0;*/
    	z-index: 100 !important;
     }

    .bv-event-present-container {
    	width:100%;
    	height: 569px;
    	margin: 0px 20px 0px 0px;
    }

    .bv-event-present-col1 {
    	height:100%;
    	width: 300px;
    	float:left;
    	position: relative;
    	overflow: hidden;
    }

    .bv-event-present-col2 {
    	height:100%;
    	width: calc(100% - 300px);
    	float:right;
    	display: inline-block;
    	position: absolute;
    }

    .bv-event-present-attachment-row {
    	height: 100%;
    	text-align: center;
    	width: 100%;
    	overflow-x: hidden;
    	white-space: nowrap;
    	padding-top: 0px;
    	overflow-y: auto;
    }

    .bv-event-present-attachment-row .bv-slider > ul {
    	text-align: center;
    	min-width: 510px;
    	min-height:465px;
    }

    .bv-event-present-attachment-row .bv-slider > ul > li{
    	box-shadow: none !important;
    /*	width: 380px;
    	margin: 20px 55px;*/
    }

    .bv-event-present-attachment-row .bv-slider .bv-slider-items-2 > li {
    	width: 45%;
    	margin: 20px 2.2%;
    }

    /*.bv-event-present-attachment-row:hover {
    	overflow-x: hidden;
    }*/

    .bv-event-present-attachment-li {
    	height: calc(100% - 70px);
    	/* background: green; */
    	margin-bottom: 20px;
    	vertical-align: middle;
    	display: inline-block;
    	position: relative;
    	width: 100%;
    	box-shadow: 0 0 5px 2px rgba(0, 0, 0, .175);
    }

    .bv-event-present-event-details {
    	width: calc(100% - 101px);
    	padding: 17px 20px 10px 35px;
    }

    .bv-event-present-event-metadetails {
    	width: 100%;
    	padding: 0 20px 10px 35px;
    }

    .bv-event-present-end {
    	position: absolute;
    	right: 15px;
    	top: 20px;
    }

    .hide-metadetails-button {
        position: absolute;
        left: 10px;
        top: 39px;
    }

    .eventPeople {
    	height: calc(100% - 125px);
    	overflow: auto;
    }

    .eventPeopleBig {
    	height: calc(100% - 75px);
    }

    .bv-event-participant {
    	height: 35px;
    	position: relative;
    }

    .event-present-person-name {
    	line-height: 35px;
    	float: left;
    	width: 100%;
    }

    .restrict-name-space {
    	width: 136px !important;
    }

    .event-current-presenter, .event-make-presenter {
    	position: absolute;
    	right: 15px;
    	line-height: 35px;
    }

    .presentDocument {
    	position: absolute;
    	right: 20px;
    	bottom: 20px;
    }

    .copy-meetings .bv-conf-addAttendeesDiv2 > title{
        margin: 5px 0 5px 20px;
        font-weight: 500;
        display: block;
    }

    .copy-meetings .bv-conf-addAttendeesDiv2 > input{
        margin: 5px 20px;
        width: calc(100% - 20px);
    }

    .copy-meetings .bv-conf-addAttendeesDiv2 > span{
        position: relative;
        float: right;
        top: -36px;
    }
    .copy-meetings .bv-conf-addAttendeesDiv2 > span > img{
        width: 14px;
        height: 14px;
    }

    .copy-meetings .bv-conf-addAttendeesDiv2 .content-box > ul > li{
        line-height: 32px;
        display: block;
        height: 30px;
        padding: 5px 0;
        position: relative;
    }
    .copy-meetings .bv-conf-addAttendeesDiv2 .content-box > title,
    .copy-meetings .bv-conf-addAttendeesDiv2 .content-box > ul > li > title{
        float: left;
        display: inline;
        line-height: 18px;
    }
    .copy-meetings .bv-conf-addAttendeesDiv2 .content-box > ul > li > checkbox{
        right: 0;
        position: absolute;
        top: 7px;
    }
    .copy-meetings .bv-conf-addAttendeesDiv2 .content-box{
        margin: 20px 0 20px 20px;
        position: relative;
    }
    .copy-meetings .bv-conf-addAttendeesDiv2 .content-box > title{
        font-weight: 500;
    }
    .copy-meetings .bv-conf-addAttendeesDiv2 .content-box > checkbox{
        right: 0;
        position: absolute
    }
    .copy-meetings .bv-conf-addAttendeesDiv2 .content-box > hr{
        position: relative;
        clear: left;
        margin: 8px 0;
        top: 4px;
        border-color: #ccc;
    }

    .copy-meetings .bv-conf-addAttendeesDiv1 > title{
        display: block;
        margin: 5px 0 5px 0px;
        font-weight: 500;
        width: calc(100% - 20px);
    }

    /* conference.css */

    /* team.css */

    .teamSideBarPullButton {
        position: absolute;
        background: lightgray;
        width: 32px;
        height: 32px;
        right: 0px;
        border-radius: 5px 0 0 5px;
        font-size: 26px;
        font-weight: 700;
        text-align: center;
        z-index: 3;
        top: 110px;
    }

    .inviteEmail {
    /*	height: 35px;
        border-radius: 3px;
        padding: 0px 33px 0px 10px;
         margin: 0px 13px;
        border: 1px solid #ccc;
        font-size: 14px;*/
        width: 100%;
        border: none;
        resize: vertical;
        padding: 0px 5px 0px 10px;
        border: #e5e5e5;
        background: #e5e5e5;
        letter-spacing: 0.6px;
    }

    .newGroupNameInput {
        height: 35px;
        border-radius: 3px;
        padding: 0px 10px 0px 10px;
        border: 1px solid #ccc;
        font-size: 14px;
        width: 66%;
        margin-right: 4px;
    }

    .teamSideBar {
    /*	height: calc(100% - 60px);*/
        /* width: 100%; */
        /*box-shadow: 42px 0px 38px -50px #000 inset;*/
    /*	background: #fff;*/
    }

    .inviteUserDialog .modal-dialog {
        min-width: 345px;
        width: 345px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%,0) !important;
        -webkit-transform: translate(-50%,0) !important;
        -ms-transform:  translate(-50%,0) !important;
        margin: 0;
        margin-top: 100px !important;
    /* 	height: 100%; */
    }

    .inviteUserSelectionDialog .modal-dialog {
        min-width: 300px;
        width: 350px;
        margin-left: 347px;
        /* margin-top: 110px; */
        top: 0;
        transform: translate(-50%,0) !important;
        -webkit-transform: translate(-50%,0) !important;
        -ms-transform:  translate(-50%,0) !important;
        position: absolute;
        margin-top: 100px !important;
    /* 	height: 100%; */
    }

    .inviteUserSelectionDialog  .modal-dialog .bv-item-list-main > div {
        height: 435px;
        width: 100%;
     }

    .inviteUserMain {
        width: 100%;
        /* min-height: 45px; */
        overflow: hidden;
        padding: 20px;
        margin-top: -5px;
    }


    .inviteChooseFromListBox {
        margin-top: 10px;
    }

    .inviteChooseFromListBox .bv-input-btn-large {
        width: 170px !important;
    }

    .addGroupMain {
        width: 100%;
        min-height: 45px;
        overflow: hidden;
    }

    .inviteUserFooter {
        width: 100%;
        margin: 0 0 0 0;
        float: left;
        text-align: center;
    }

    .filterBox {
    /*	position: absolute;
        display: block;*/
        overflow: hidden;
    /*	white-space: nowrap;*/
    /*	bottom: 0px;*/
        width: 100%;
        padding: 14px 20px 20px 15px;
        /*z-index: 1;*/
        font-size: 16px;
        position:relative;
    }

    .inviteButton {
        /* float: left; */
        margin: 0px 0px;
        height: 35px;
        width: 35px;
        background: url("../assets/images/add_members_V2.png") no-repeat;
        border: 0;
        color: #fff;
        cursor: pointer;
        /* z-index: 1; */
        position: absolute;
        right: 5px;
        top: 13px;
    }

    .searchGlass {
        width: 30px;
        height: 32px;
        float: right;
        margin: -33px 5px;
        border: 0;
        position: relative;
        /* border-right: 1px solid #D1D1D1; */
        background-color: white;
    }

    .searchUser {
        width: 100%;
        display: block;
        margin-top: -2px;
    }

    .itemListFilterInput {
        width: 100%;
        display: block;
    }

    .searchUserBox {
        position: absolute;
        margin: 10px 3% 5px 3%;
        width: 94%;
        display: block;
        overflow: hidden;
        bottom: 0px;
    }

    .portalUserList {
        position: absolute;
        overflow: hidden;
        /*float: left;*/
        /*width: 100%;*/
        /* 	margin: -5px 0 0 0; */
    /*	box-shadow: 42px 0px 38px -50px #000 inset;*/
        /* background: #fff; */

    }

    sidebar .userList{
        position: relative;
        height: calc(100% - 44px);
    }

    .bv-mobile sidebar .userList{
        height: calc(100% - 226px);
    }

    .portalUserListContainer {
        height: calc(100% - 138px);
    /*	box-shadow: 42px 0px 38px -50px #000 inset;*/
    }

    .portalUserListContainerBig {
        height: calc(100% - 99px);
    /*	box-shadow: 42px 0px 38px -50px #000 inset;*/
    }

    .userSelectListContainer {
        height: calc(100% - 160px);
        margin-top: 5px;
        width: 276px;
    }
    /* .portalUserListWithFilter {
        height: calc(100% - 103px);
    } */

    /* .portalUserListNoFilter {
        height: calc(100% - 60px);
    }
     */
    [right-sidebar].bv-transition .portalUserList {
        width: inherit;
    }

    [home-right-sidebar].bv-transition .portalUserList:hover {
        overflow-y: hidden;
    }

    .portalUserList:hover {
        overflow-y: scroll;
    }

    .portalUserListItem {
        height: 55px;
        margin: 0 0 0px 0;
        width: 100%;
        display: inline-block;
        padding: 6px 0px 6px 15px;
        margin: 3px 0px 3px 0px;
    }

    .portalUserListItem:hover {
        /*background: #f4f4f4;*/
        cursor:pointer;
    }

    .user-selected {
        background: #d1d1d1 !important;
    }

    .portalUserAvatar {
        height: 45px;
        float: left;
        white-space: nowrap;
    }

    .DportalUserHeader {
        width: 100%;
        /*border-bottom: 1px solid;*/
        /*padding: 10px 10px 0 10px;*/
        /*margin-top: 5px;*/
    }

    .portalUserImage {
        text-align: center;
        float: left;
        width: 45px;
        height: 45px;
        position: relative;
        display: block;
        background: none;
        z-index: 1;
        border-radius: 50%;
    }

    .DportalUserImage {
        text-align: center;
        float: left;
        width: 120px;
        height: 120px;
        position: relative;
        display: block;
        background: none;
        z-index: 1;
    }

    .portalUserListCircle {
        text-align: center;
        width: 45px;
        height: 45px;
        overflow: hidden;
        border-radius: 50%;
        /* box-shadow: rgb(204, 204, 204) 0px 0px 6px 1px; */
        background: rgb(81, 81, 81);
        display: inline-block;
        margin: 0 12px 0 0;
        float: left;
    }

    .portalUserListCircle-withImage {
        background: #fff !important;
    }

    .DportalUserListCircle {
        text-align: center;
        width: 120px;
        height: 120px;
        overflow: hidden;
        border-radius: 50%;
        /* box-shadow: rgb(204, 204, 204) 0px 0px 6px 1px; */
        background: rgb(81, 81, 81);
        display: inline-block;
        margin: 0 12px 5px 0;
        float: left;
    }

    .portalUserFirstLetter {
        position: absolute;
        width: 45px;
        margin-top: 8px;
        color: white;
        font-weight: lighter;
        font-size: 20px;
        /*letter-spacing: 1px;*/
        display: block;
    }

    .DportalUserFirstLetter {
        position: absolute;
        width: 120px;
        margin-top: 28px;
        color: white;
        font-weight: lighter;
        font-size: 45px;
        /* letter-spacing: 1px; */
        display: block;
    }

    .portalUserNameBox {
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        height: 48px;
    }

    .DportalUserNameBox {
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        height: 120px;
    }

    .portalUserName {
        /* color: #706D6D; */
        /* margin: 6px 0 0 10px; */
        font-size: 20px;
        /* font-weight: lighter; */
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 140px;
        line-height: 48px;
    }

    .teamUserProfile .portalUserName {
        max-width: 230px !important;
        width: 230px !important;
        line-height: 25px;
    }

    .portalUserList .portalUserName {
        max-width: 160px !important;
        width: 160px !important;
        font-size: 16px;
    }

    .groupUserList .portalUserName {
        max-width: 140px !important;
        width: 140px !important;
        font-size: 16px;
    }

    .userProfileDialog .modal-dialog {
          min-width: 300px;
          width: 300px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%) !important;
          -webkit-transform: translate(-50%,-50%) !important;
          -ms-transform:  translate(-50%,-50%) !important;
          margin: 0;
      }

    .editEmailWizard .modal-dialog {
        min-width: 300px;
        width: 300px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) !important;
        -webkit-transform: translate(-50%,-50%) !important;
        -ms-transform:  translate(-50%,-50%) !important;
        margin: 0;
    }

    .teamUserProfile {
        /*height: calc(100% - 60px);*/
        /* border-left: 1px solid #706D6D; */
        /* position: absolute; */
        /*top: 60px;*/
        /* right: 58px; */
        /*left: 340px;*/
        /*background: white;*/
        width: 300px;
        /*box-shadow: 0px 0px 38px -5px #000;*/
        /*height: 480px;*/
        padding: 20px;
    }

    .closeUserProfile {
        color:black !important;
        padding: 2px 2px 0 0 !important;
    }

    /*.teamUserProfile.ng-hide-add,
    .teamUserProfile.ng-hide-remove {
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
    }

    .teamUserProfile.ng-hide {
        right: 0px;
    }*/
    .teamUserProfileDetails {
        margin: 0px 0 0px 0;
        width: 100%;
        /* padding-right: 10px; */
        /*height: calc(100% - 140px);*/
        min-height: 280px;
        max-height: 335px;
        /*position: absolute;*/
        overflow: hidden;
        /* border-bottom: 1px solid; */
        font-size: 16px;
    }

    .teamUserProfileDetails:hover {
        overflow-y: auto;
    }

    .teamProfileFieldDisplay {
        margin-bottom: 20px;
    }
    .teamProfileFieldDisplay:last-child{
        margin-bottom: 10p;
    }
    .teamProfileField {
        white-space: nowrap;
        margin: 10px 0 0px 0;
    }

    .teamProfileFieldNameDisplay {
    /*	display: inline-block;
        width: 100%;
        text-align: left;
        vertical-align: middle;*/
    /*	font-size: 16px;*/
    /*	font-weight: 500;*/
    /*	font-weight: bold;*/
        margin-bottom: 3px;
    }

    .teamProfileFieldName {
    /*	display: inline-block;
        width: 25%;
        text-align: right;
        vertical-align: middle;
        /* 	padding-right: 4px;
        font-size: 13px;*/
    /*	font-weight: 500;*/
    /*	font-weight: bold;*/
    }

    /*.teamProfileFieldValueEdit {
        display: inline-block;
        width: 74%;
        max-width: 74%;
        padding-left: 4px;
        white-space: pre-line;
        max-height: 96px;
        min-height: 16px;
        font-size: 13px;
        vertical-align: middle;
    }*/

    .teamProfileFieldSelectEdit {
        /*display: inline-block;
        width: 74%;
        max-width: 74%;
        padding-left: 4px;
        white-space: pre-line;
        max-height: 96px;
        min-height: 25px;
        font-size: 13px;
        vertical-align: middle;*/
        -webkit-appearance: none;
        -webkit-border-radius: 0px;
        background: url('../assets/images/arrow-drop-down.png') no-repeat #e5e5e5;
        -moz-appearance: none;
        background-position: 97%;
        border: none;
        width: 100%;
        height: 26px;
        background-size: 15px;
        /*border: 1px solid #D1D1D1;*/
        padding-left: 5px;
    }

    .profileCountryName button {
        background-color: #e5e5e5 !important;
        color: #000 !important;
    }

    .profileCountryName  ul {
        max-height: 220px;
        overflow: auto;
    }

    .profileCountryName li {
        font-size: 16px;
    }

    .dropDownArrow {
        position: relative;
        float: right;
        margin-top: -15px;
        margin-right: 5px;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid black;
        pointer-events: none;
    }

    .teamProfileFieldValue {
        display: inline-block;
        /* background: #DCDCDC; */
        width: 100%;
        max-width: 100%;
        /* padding-left: 4px; */
        /*white-space: pre-line;*/
        max-height: 96px;
        overflow: hidden;
        /* font-weight: 500; */
        min-height: 20px;
    /*	font-size: 16px;*/
        /*vertical-align: middle;*/
        /*color: #706D6D;*/
        text-overflow: ellipsis;
    }

    .bioField {
        max-height: 100% !important;
    }

    .bioField font{
        font-size: 16px;
    }

    .bioFieldSet font{
        font-size: 16px;
    }

    .teamProfileFieldValue:hover {
        overflow-y: auto;
    }

    .teamActionBar {
        /*position: absolute;
        bottom: 5px;*/
        text-align: center;
        white-space: nowrap;
        width: 100%;
        margin-left: -3.5px;
        margin-top: 20px;
    }

    .actionButtonSet {
        display: inline-block;
        text-align: center;
        margin: 0px 0 0px 5px;
    }

    .actionName {
        font-size: 10px;
        color: #515151;
        font-weight: bold;
    }

    .actionButton {
        height: 35px;
        width: 35px;
        position: relative;
        margin: 0 auto 0 auto;
    }

    .yesButton {
        border: 0px solid;
        background: #036EAA;
        height: 30px;
        width: 100px;
        color: #fff;
        margin: 0 10px;
    }

    .noButton {
        border: 0px solid;
        background: #000;
        height: 30px;
        width: 100px;
        color: #fff;
        margin: 0 10px;
    }

    .editProfileDialog .modal-dialog {
        min-width: 970px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) !important;
        -webkit-transform: translate(-50%,-50%) !important;
        -ms-transform:  translate(-50%,-50%) !important;
        margin: 0;
    }

    .editSignatureDialog .modal-dialog {
        min-width: 650px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) !important;
        -webkit-transform: translate(-50%,-50%) !important;
        -ms-transform:  translate(-50%,-50%) !important;
        margin: 0;
    }

    .editProfileMain {
        width: 100%;
        height: 500px;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 20px;
        font-size: 16px;
    }

    .editProfileLong .mCSB_container {
        min-height: 590px;
    }

    .fieldsWithPictureMain {
        white-space: nowrap;
        position: relative;
    }

    .fieldsWithPictureMain .DportalUserHeader {
        margin-top: 5px;
    }

    .fieldsWithPicture {
        width: calc(100% - 135px);
        display: inline-block;
        margin-top: -5px;
    }
    .customFieldsMain {
        width: 100%;
        float: left;
        margin-top: -21px;
    }

    .customFieldsColumn {
        width: 66.6%;
    }

    .fieldSet .remove-field-button {
        right: 5px !important;
    }

    .remove-field-button {
        width: 17px;
        height: 17px;
        cursor: pointer;
        margin-top: 8px;
        position: absolute;
        right: 35px;
        top: -2px;
        fill: #CCCCCC;
    }
    .remove-field-button:hover
    {
        fill:#000000 !important;
    }

    /* .editProfileMain:hover {
        overflow-y: auto;
    } */

    .editProfileImageBox {
        text-align: center;
        width: 80px;
        height: 80px;
        overflow: hidden;
        box-shadow: rgb(204, 204, 204) 0px 0px 6px 1px;
        background: rgb(81, 81, 81);
        display: inline-block;
        margin: 1.5px;
    }

    .editProfileImage {
        text-align: center;
        float: left;
        width: 80px;
        height: 80px;
        z-index: 1;
        position: relative;
        display: block;
        background: none;
    }

    .editProfileFirstLetter {
        position: absolute;
        width: 80px;
        margin-top: 12px;
        color: white;
        font-weight: lighter;
        font-size: 40px;
        letter-spacing: 1px;
        display: block;
    }

    .editProfileUserNameBox {
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        height: 53px;
    }

    .editProfileUserName {
        color: #706D6D;
        margin: 18px 0 0 10px;
        font-size: 30px;
        font-weight: lighter;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        /*max-width: 169px;*/
    }

    .leftFields {
        width: 50%;
        float: left;
    }

    .rightFields {
        width: 50%;
        float: right;
    }

    .editProfileFooter {
        width: 100%;
        margin: 20px 0 0 0;
        float: left;
        text-align: center;
    }

    .fieldsMain {
        overflow-y: hidden;
        overflow-x: hidden;
        float: left;
        width: 100%;
        height: 420px;
        margin: 0px 0 0 0;
    /*	font-size: 16px;*/
        -webkit-transform: translate3d(0,0,0);
    }

     .fieldsMain:hover {
        overflow-y: auto;
    }

    .profileFields {
        float: left;
        width: 750px;
        height: 300px;
    }

    .fieldsColumn {
        width: 33.33%;
        /*padding-left : 10px;*/
        padding-right: 30px;
        margin-top: -3px;
    }

    .lastFieldsColumn {
        padding-right: 0 !important;
    }

    .fieldSet {
        margin-bottom: 14px;
    }

    .customFieldSet {
        margin-top: 20px;
        width: 50%;
        padding-right: 30px;
        display: inline-block;
        position: relative;
    /*	padding-left: 10px;*/
    }

    .customFieldSet .bv-placeholder-label span {
        letter-spacing: 0.6px !important;
        font-size: 16px;
    }

    .editProfileInputText {
        width: 100%;
    /*	font-size: 16px;*/
    }

    .createUserInputText {
        width: 100%;
    /*	font-size: 16px;*/
    }


    .createUserLicenseType button{
        font-size: 16px;
        font-weight: 400;
        background-color: #e5e5e5 !important;
    }

    .createUserLicenseType  ul {
        max-height: 220px;
        overflow: auto;
    }

    .createUserLicenseType li {
        font-size: 16px;
    }


    .secondaryEmails {
        height: 95px !important;
    }

    .editProfileCustomFieldName {
        display: inline-block;
        width: 12.5%;
        text-align: right;
        vertical-align: middle;
        padding-right: 4px;
        font-size: 13px;
    }

    .newCustomFieldValueEdit {
        display: inline-block;
        width: 12.5%;
        max-width: 12.5%;
        text-align: right;
        font-size: 13px;
        vertical-align: middle;
    }

    .newCustomFieldName {
        background: #FFF;
        width: 100% !important;
        padding-right: 27px;
        font-weight: 400;
    }

    .editProfileCustomFieldValueEdit {
        display: inline-block;
        width: 87%;
        max-width: 87%;
        padding-left: 4px;
        white-space: pre-line;
        max-height: 96px;
        min-height: 16px;
        font-size: 13px;
        vertical-align: middle;
        padding-left: 4px;
    }

    .addCustomFieldButton {
        float: left;
        height: 35px;
        width: 200px;
        background: url("../assets/images/add_members_V2.png") no-repeat;
        border: 0;
        color: #fff;
        cursor: pointer;
        /* z-index: 1; */
        position: relative;
        color: gray;
    }

    .bioFieldSet {
        margin-top: -5px;
    }

    .bioEditor {
        resize: none;
        height: 120px;
    }

    .bioFieldSet .cke_wysiwyg_frame, .cke_wysiwyg_div {
        background-color: #e5e5e5 !important;
    }

    .bioFieldSet .cke_toolgroup {
        background: #e5e5e5 !important;
    }

    .bioFieldSet .cke_bottom {
        background: #e5e5e5 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    .bioFieldSet .cke_inner {
        background: #e5e5e5 !important;
    }

    .createUser-notice {
        margin-top: -12px;
    }

    .signatureMain {
        padding: 20px;
        margin-top: -5px;
        height: 431px;
    }

    .signature-middle-line {
        width: 100%;
        background-color: #e5e5e5;
        position: absolute;
        top: 156px;
        height: 0px;
    }

    .signBox {
        width: 398px;
        /*float: left;*/
        /* white-space: pre-line; */
        margin-right: 17px;
    }

    .initBox {
        width: 190px;
        /*float: left;*/
    /*	white-space: pre-line;*/
    }

    .signInitBox{
        margin: 0px 0px 0px 0px;
        height: 176px;
        /*padding-left: 15px;*/
    }

    .signCanvas {
        /* 	width:300px;
        height:100px; */
        background: white;
        cursor: pointer;
        margin-top: 5px;
        position: relative;
        /* 	cursor:url("../assets/images/BVLiveryDrawingPalettePenToolOverlayPhone.png), auto; */
        -ms-touch-action: none;
    }

    .initialsCanvas {
        /* 	width:200px;
        height:100px; */

    /*	border-radius: 10px;*/
        cursor: pointer;
        background: white;
        margin-top: 5px;
        position: relative;
        -ms-touch-action: none;
    }

    .currentSign {
        width: 402px;
        height: 115px;
        background: white;
        margin-top: 5px;
    }

    .currentInitials {
        width: 190px;
        height: 115px;
        background: white;
        margin-top: 5px;
    }

    .autoGenerateButtons {
        /* float: left; */
        position: absolute;
        bottom: 20px;
        left: 20px;
    }

    .changeStyleButtons {
        white-space: nowrap;
        display: inline-flex;
        float: left;
    }

    .prevStyleButton {
        margin-right: 5px;
        cursor: pointer;
    }

    .nextStyleButton {
        margin-left: 5px;
        cursor: pointer;
    }

    .clearButton {
        float:right;
        cursor: pointer;
    }

    .canvasCaption {
        color: #706D6D;
        font-size: 15px;
    }

    /* Groups */
    .showGroupsButton {
        top: 14px;
        position: absolute;
        right: 45px;
        height: 35px;
        width: 35px;
        background-color: #707070;
        font-color: #fff;
        color: #fff;
        font-size: 25px;
        text-align: center;
        cursor: pointer;
    }

    .groupsBox {
        height: calc(100% - 137px);
        overflow: hidden;
        position: absolute;
        width: 100%;
        /*width: calc(100% - 18px);*/
        /* padding-left: 10px; */
        /* padding-right: 3px; */
        /* padding-top: 5px; */
    }

    .groupsBox:hover {
        overflow-y: auto;
    }


    .groupsBox .panel {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .groupsBox .panel-default {
        border: none;
        border-radius: 0px;
        margin-bottom: 20px;
        background-color: inherit;
    }

    .groupsBox .panel-default > .panel-heading {
      background-color: inherit;
      border-color: inherit;
    }

    .groupsBox .panel-default > .panel-heading:hover {
        background-color: #f4f4f4;
    }

    .groupsBox .panel-group .panel-heading {
        padding: 0px;
    }

    .groupsBox .panel-group .panel-heading:hover {
        background-color: #f4f4f4;
    }

    .groupsBox .panel-title {
    /*	font-size: 20px;
    */	font-weight: 400;
    }

    .groupsBox .groupHeader {
        padding: 10px 30px 15px 15px;
    /* 	background-color: white; */
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
        white-space: nowrap;
    }

    /*.groupsBox .groupHeader:hover{
        background-color: #f0f0f0;
    }*/

    .groupsBox .groupHeaderOpen {
        background-color: #f0f0f0 !important;
    }

    .groupsBox .panel-group .panel-heading + .panel-collapse .panel-body {
        border-top: none;
        padding: 10px 0 0 0;
        overflow: hidden;
    }

    .groupsBox .panel-collapse.in {
      background: #f0f0f0;
    /*  padding-top: 10px;*/
    }

    .userSelectionDialog .modal-dialog {
        min-width: 300px;
        width: 300px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) !important;
        -webkit-transform: translate(-50%,-50%) !important;
        -ms-transform:  translate(-50%,-50%) !important;
        margin: 0;
    }

    /*
    .groupUserList {
        position: relative;
        overflow: hidden;
        width: 100%;
        max-height: 400px;
    }

    .groupUserList:hover {
        overflow-y: auto;
    }

    .panel-body {
        padding: 0 !important;
    }*/

    .groupAdminDropDown {
        position: absolute;
        right: 15px;
    }

    .groupAdminButton {
        float:right;
        width:20px;
        height: 20px;
        margin-top: 3px;
    }

    .groupAdminButtonSVG {
        fill: #CCC;
    }

    .groupAdminButtonSVG:hover {
        fill: #000;
    }

    .groupsBox .dropdown-menu {
        left: -170px;
        border-radius:0px !important;
        top: 25px;
    }

    .removeX {
        font-size: 19px;
        margin-top: 6px;
        padding-right: 7px;
        position: absolute;
        cursor: pointer;
    }

    .userX {
        left: 214px;
    }

    .userFromGroupX {
        margin: 17px 0px 0 0;
        height: 17px;
        width: 17px;
        position: absolute;
        right: 20px;
    }

    .groupScrollbar::-webkit-scrollbar {
        background-color: rgba(214, 214, 214, 0.34);
        width: 0px;
        height: 8px;
        border-radius: 10px;
    }

    .groupScrollbar::-webkit-scrollbar-thumb {
        width: 0px;
        height: 8px;
        background-color: #D5D5D5;
        border-radius: 10px;
    }

    .groupScrollbar::-webkit-scrollbar-thumb:hover {
        background-color: #A5A2A2;
    }

    /* Helvetica Neue Medium Font Stack */
    .Helvetica-Neue-Medium {
        font-family: "HelveticaNeueMedium", "HelveticaNeue-Medium", "Helvetica Neue Medium", "HelveticaNeue", "Helvetica Neue", 'TeXGyreHerosRegular', "Helvetica", "Tahoma", "Geneva", "Arial", sans-serif;
        font-stretch:normal;
    }

    .inputGuideLabel {
        font-size: 16px;
        color: gray
    }

    .userActionMenu {
        /* width: 100%; */
        text-align: center;
        font-size: 16px;
        height: 40px;
        /*border-bottom: 1px solid #e5e5e5;*/
        /* position: relative; */
        display: table;
        width: auto;
        margin: auto;
    }

    .userActionMenu li {
        display: inline;
        /* width: 50%; */
        float: left;
        height: 30px;
        /* border-bottom: 1px solid; */
        width: 115px;
        text-align: center;
        margin-top: 10px;
        /* margin-top: 23px; */
        /* position: absolute; */
    }

    .userActionMenu li a {
        display: inline-block;
        text-align: center;
        color: black;
        opacity: 0.2;
        /*max-width: 50px;*/
    }

    .userActionMenu li a:hover {
        border-bottom: 8px solid;
        opacity: 1;
        cursor: pointer;
    }


    .createUserDialog .modal-dialog {
        width: 325px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) !important;
        -webkit-transform: translate(-50%,-50%) !important;
        -ms-transform:  translate(-50%,-50%) !important;
        margin: 0;
    }

    .createUserMain {
        width: 100%;
        height: 575px;
        overflow: hidden;
        padding: 20px;
        font-size: 16px;
    }

    .createUserFieldsMain {
        overflow: hidden;
        float: left;
        width: 100%;
    }

    .createUserFieldsColumn {
        width: 100%;
    /* 	padding-right: 10px;
        padding-left: 10px; */
    }


    .editPhotoDialog .modal-dialog {
        min-width: 370px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) !important;
        -webkit-transform: translate(-50%,-50%) !important;
        -ms-transform:  translate(-50%,-50%) !important;
        margin: 0;
    }

    .editPhotoMain {
        width: 100%;
        height: 400px;
        overflow: hidden;
        /* padding-top: 30px; */
        /* padding-left: 10px; */
        font-size: 16px;
        padding: 55px;
    }

    .editPhotoBtn {
        display: inline-block;
        overflow: hidden;
        line-height: 40px;
    }

    .cropArea {
        background: #E4E4E4;
        overflow: hidden;
        width: 245px;
        height: 245px;
        margin: auto;
    }

    .selectPhoto {
        position: relative;
        /* overflow: hidden; */
        /* border: none; */
        /* line-height: 16px; */
        display: inline-block;
        /* color: #fff; */
        /* transition: background .2s; */
        /* text-shadow: none; */
        margin: 2px 0px 0 7.5px;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        padding-top: 2px;
    }

    .selectPhoto span {
        vertical-align: inherit;
    }

    .selectPhoto input.selectPhotoBtn {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
        width: 100px;
    }

    /* team.css */

    /* messages.css */

    .messagesMain {
    	height: 100%;
    	width: 100%;
    	white-space: nowrap;
    	overflow-y:hidden;
    	/* min-width: 640px;  - MEETX-5023 */
    }

    .inboxItemsList{
     	height: 100%;
     	min-width: 305px;
      	max-width: 305px;
    	z-index: 1;
    	position: relative;
    	float: left;
    	display: inline-block;
    }

    .itemComments {
    	width: 100%;
    	overflow: hidden;
    	overflow-y: auto;
    	padding: 14px 25px 15px 35px;
    	display: inline-block;
    	font-weight: 500;
    }

    .inboxItemsGrid {
    	width: 100%;
     	height: 100%;
    	overflow: hidden;
    }

    .inboxItemsGrid:hover {
    	overflow-y: auto;
    	width: 100%;
    }

    .inboxItemsGrid:hover li.inboxListItemCell {
    	width: 100%;
    }

    .is-selected {
    	background: #f0f0f0 !important;
    }
    .is-selected-mid {
    	min-width: 203px;
    	max-width: 203px;
    }

    .hasMore {
    	height: 100%;
    }

    .moreBtnRow {
      	margin: 10px 20px 20px 175px;
    }

    .itemDetails {
    	height: 100%;
    	background: #f0f0f0;
    	width: calc(100% - 305px);
    	float: right;
    	z-index: 2;
    	position: relative;

    	@media (min-height : 0px) and (max-height : 650px){
            overflow: auto;
        }
    }
    .itemDetails .panel{
    	transition: all 500ms;
    }
    .itemDetails .panel.open{
    	transform: translateY(-40px);
    }

    .itemDetails .bv-slider > ul > li{
    	  display: inline-block;
      	  height: calc(100% - 40px);
          box-shadow: none;
          margin: 0;
          padding-top:7px;
    }

    .unreadItem {
        min-width: 30px;
        max-width: 30px;
        height: 100%;
        padding: 23px 0 0 15px;
        float: left;
        display: inline-block;
    }

    .unreadItemCircle{
        background: #2273A6;
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }

    li.inboxListItemCell {
    	 white-space: nowrap;
    	 height: 65px;
    	 padding: 11px 8px;
    }

    .inboxListCellItem {
    	margin: 0 0 0 5px;
    	overflow: hidden;
    	max-height: 20px;
    }

    .inboxListLeftItems {
    	float: left;
    	min-width: 221px;
    	max-width: 221px;
    	height: 100%;
    	text-align: left;
    	padding: 0 5px 0 0;
    	display: inline-block;
    }
    .bv-inbox-link,
    .bv-inbox-link:link,
    .bv-inbox-link:hover,
    .bv-inbox-link:active,
    .bv-inbox-link:visited{
    	color: #000000;
    	text-decoration:none;
    }


    .inboxListAttachmentIcon {
     	float: left;
    	min-width: 18px;
    	max-width: 18px;
    	height: 100%;
    }
    .inboxListSettingIcon {
    	float: left;
    	min-width: 17px;
    	max-width: 17px;
    	height: 100%;
    	text-align: right;
    	display: inline-block;
    	padding-bottom: 10px;
    	margin-right:7px;
    }

    .inboxItemCreatedBy {
    	font-weight: 600;
    	font-size: 14px;
    }

    .inboxItemCreatedOn {
    	font-size: 11px;
    }

    .attachmentPinImage {
    	width: 20px;
    	height: 20px;
    	position: relative;
    	top: 7px;
    }

    .inboxListFooter {
    	width: 30%;
    	text-align: left;
    }

    .itemHeader {
    	width: 100%;
    	background: white;
    	position: relative;
    	min-height: 110px;
    	height: 122px;
        padding: 10px 0px 11px 25px;
        border-bottom: 1.5px solid #DDDDDD;

        @media (min-width : 0px) and (max-width : 1160px){
            height: 140px;
        }
    }

    .itemRow {
    	height: auto;
    	position: relative;
    	display: flex;
    	line-height: 20px;
    }
    .itemRow1 {
    	height: auto;
    	padding: 7px 0 0 0;
    	position: relative;
    	display: flex;
    	line-height: 20px;
    }

    .itemHeaderLabel {
    	text-align: right;
    	min-width: 64px;
    	max-width: 64px;
    	float: left;
    	position: relative;
    }

    .itemHeaderValue {
    	text-overflow: ellipsis;
    	overflow: hidden;
    }

    .hrBreak {
    	border: 0;
        height: 1px;
        background: #333;
        margin-top: 5px;
        margin-bottom: 0px;
        background-image: -webkit-linear-gradient(left, #ccc, #333, #ccc);
        background-image:    -moz-linear-gradient(left, #ccc, #333, #ccc);
        background-image:     -ms-linear-gradient(left, #ccc, #333, #ccc);
        background-image:      -o-linear-gradient(left, #ccc, #333, #ccc);
    }

    .wrapText {
    	white-space: pre-wrap;      /* CSS3 */
       	white-space: -moz-pre-wrap; /* Firefox */
       	white-space: -pre-wrap;     /* Opera <7 */
       	white-space: -o-pre-wrap;   /* Opera 7 */
       	word-wrap: break-word;      /* IE */
    }

    .listAttachmentRow {
    	margin: 4px 0 0 10px;
    	text-align: right;
    	width: 100%;
    	overflow-x: auto;
    	white-space: nowrap;
    	display: inline-block;
    	overflow-y: hidden;
    }

    .listAttachmentRow:hover {
    	overflow-x: auto;
    }

    .attachmentRow {
    	height: 170px;
    	text-align: center;
    	width: 100%;
    	background: #FFFFFF;
    	overflow: hidden;
    	white-space: nowrap;
    	position: relative;
    	border-top: 1.5px solid #DDDDDD;
    	padding: 10px 10px 10px 10px;
    	display: inline-block;

    }

    .attachmentRow:hover {
    	overflow-x: auto;
    }

    .list-message-attachment {
    	display: inline-block;
    	margin: 0 5px 0 0;
    }

    .message-attachment {
    	display: inline-block;
    	margin: 0 20px 0 0;
    	vertical-align: top;
    }



    .message-attachment-image-container {

    	width: 90px;
    	height: 115px;
    	margin: 0 10px 10px 0;
    	box-shadow: 0 0 7px 0 #707070;
    	position: relative;
    	cursor: pointer;
    	display: inline-block;
    	vertical-align: middle;
    }

    .list-attachment-image {
    	width: 70px;
    	height: 71px;
    	margin: 0 10px 5px 0;
    }

    .file-attachment-image {
    	border: 1px solid #d1d1d1;
    }

    .attachment-image {
    	max-height: 100%;
    	max-width: 100%;
    	width: 100%;
    	vertical-align: middle;
    	display: inline-block;
    }

    .attachment-name {
    	text-align:center;
    	width: 100%;
    	overflow: hidden;
    	text-overflow: ellipsis;
    	font-size: 15px;
    }

    .ngViewport.ng-scope {
    	overflow-y: hidden;
    }

    .ngViewport.ng-scope:hover {
    	overflow-y: auto;
    }

    .bv-inbox-newmsg-dialog {
    	max-height: 415px;
        min-height: 415px;
        overflow-y: none;
        overflow-x: none;
    }
    .bv-inbox-up-section {
    	min-height: 135px;
    	/*max-height: 100px;*/
    	overflow-y: auto;
        overflow-x: none;
        background: #FFFFFF;
    }
    .bv-inbox-section-one-third {
    	height: 33.333333px;
    	width: 100%;
    }
    .bv-inbox-section-left {
    	height: 33.333333px;
    	width: 120px;
    	text-align: right;
    	padding-top: 3px;
    	float:left;
    }
    .bv-inbox-section-mid {
    	min-height: 33.333333px;
    	max-height: 33.333333px;
    	min-width: 600px;
    	max-width: 600px;
    	padding-left: 5px;
    	float:left;
    	overflow-x:hidden;
    	overflow-y:auto;
    }

    .bv-inbox-section-right {
    	min-height: 33.333333px;
    	max-height: 33.333333px;
    	min-width: 30px;
    	max-width: 30px;
    	padding: 5px;
    	float:right;
    }

    .bv-inbox-down-section {
    	min-height: 270px;
    	max-height: 270px;
    	overflow-y: auto;
        overflow-x: none;
        margin-top: 2px;
        background: #FFFFFF;

    }
    .bv-inbox-add-button {
    	width:18px;
    	height:18px;
    }
    .bv-inbox-input {
    	border: none;
    	width:100px;
    	margin-top: 2px;
    	text-align: left;
    }
    .bv-inbox-input-recipent-search {
    	border: none;
    	width:420px;
    	text-align: left;
    	border-radius: 5px;
    }
    .bv-inbox-msgbody-input {
    	min-width: 770px;
    	max-height: 265px;
    	min-height: 265px;
    	border: none;
    	padding: 5px;
    	resize: none;
    	overflow-y: auto;
        overflow-x: none;
    }
    .bv-inbox-msgbody-input:focus {
    	outline-width: 0;
    }
    .bv-inbox-btn-blue
    {
    	color:#FFFFFF;
    	min-width:65px;
    	background-color: #3596BD;
    	border-style:none;
    	max-height: 23.7px;
        min-height: 23.7px;
        border-radius: 4px;
    }
    .bv-inbox-btn-orange
    {
    	color:#FFFFFF;
    	min-width:65px;
    	background-color: #FF8000;
    	border-style:none;
    	max-height: 23.7px;
        min-height: 23.7px;
        border-radius: 4px;
    }
    .bv-inbox-recipents-img {
    	min-width: 35px;
    	max-width: 35px;
    	min-height: 35px;
    	max-height: 35px;
    	padding-top: 4px;
    }
    .bv-inbox-recipents {
    	background-color: white;
    	min-height: 360px;
    	max-height: 360px;
    	overflow-x: hidden;
    	overflow-y:auto;
    }
    .bv-inbox-recipents-search {
    	min-width:400px;
    	overflow-x: hidden;
    	overflow-y: hidden;
    }
    .bv-inbox-recipents-name {
    	padding-top: 8px;
    	padding-left: 3px;
    	min-width: 350px;
    	min-height: 35px;
    }
    .bv-inbox-attachment-button {
    	color:#000000;
    	min-width:60px;
    	background-color: #E0E0E0;
    	border-style:none;
    	max-height: 20px;
        min-height: 20px;
        border-radius: 8px;
        float:left;
        background-image: url('../assets/images/BVLiveryBriefcaseDownloadCancel.png');
        background-repeat: no-repeat;
        background-position: 97% 50%;
        background-size: 12px, 12px;
        margin-top: 3px;
        padding-top: 3px;
        font-size: 10px;
        white-space: normal;
    }
    .bv-inbox-section-mid p {
    	margin-bottom: 0px;
    	padding-left:10px;
    	padding-right:22px;
    }
    .bv-inbox-recipent-result {
    	padding-left:5px;
    	padding-right:7px;
    	white-space: normal;
    }
    .bv-inbox-recipent-result:hover {
    	background-color: #3596BD;
        color: #FFFFFF;
    }
    .bv-inbox-recipents-name:hover {
    	background-color: #3596BD;
        color: #FFFFFF;
    }

    .bc-inbox-expiry-text
    {
    	display: inline;
    	float: left;
    	padding-left: 74px;
    	padding-right: 10px;
    }

    .bv-inbox-expiry-combo
    {
    	display: inline;
    	width: 91px;
    	background-position: 100%;
    	background-size: 21px;
    	border: 1px solid #D1D1D1;
    	float: left;
    }



    /* AVatar */

    .messageUserAvatar {
    	margin: 10px 10px 0px 10px;
    	display: inline;
    }


    .messageUserImage {
    	text-align: center;
    	float: left;
    	width: 25px;
    	height: 25px;
    	position: relative;
    	display: block;
    	background: none;
    	z-index: 1;
    }

    .messageUserListCircle {
    	text-align: center;
    	width: 50px;
    	height: 50px;
    	overflow: hidden;
    	border-radius: 50%;
    	box-shadow: rgb(204, 204, 204) 0px 0px 6px 1px;
    	background: rgb(81, 81, 81);
    	display: inline-block;
    }

    .messageUserFirstLetter {
    	width: 50px;
    	margin-top: 8px;
    	color: white;
    	font-weight: lighter;
    	font-size: 25px;
    	letter-spacing: 1px;
    	display: block;
    }

    .bv-lite-pdf-viewer {
    	border: 1px solid #D1D1D1;
    	max-height:400px;
    }

    .messageLeft {
      float: left;
      position: relative;
      width: 20%;
      height: 100%;
      overflow: hidden;
    }

    .messageRight {
      float: left;
      position: relative;
      width: 80%;
      height: 100%;
      overflow: hidden;
    }

    .messageTitle {
    	color: #333;
    }
    .messageTitle:hover {
        color: #333;
    }
    .bv-cursor {
    	cursor:pointer;
    }
    .bv-inbox-lbl-black{
    	font-size: 16px;
    	color: #000000;
    	font-style: normal;
    	letter-spacing: 0.03em;
    	text-align: left;
    }
    .bv-inbox-lbl-blue{
    	font-size: 16px;
    	color: #2273a6;
    	font-style: normal;
    	letter-spacing: 0.03em;
    	text-align: left;
    }
    .bv-inbox-lbl-500 {
    	font-weight: 500;
    }
    .bv-inbox-lbl-400 {
    	font-weight: 400;
    	font-size: 16px;
    }
    .bv-inbox-attachment{
        font-size: 13px;
        text-align: center;
    }
    .bv-inbox-padding-10 {
    	padding-left:10px;
    }
    .inboxListSettingIcon .dropdown-menu {
    	left: -124px;
    	text-align: left;
    	min-width:140px;
    	max-width:140px;
    	border-radius: 0px !important;
    }

    .inboxSetting {
        text-align: right;
        position: relative;
        top: -42px;

        @media (min-width : 0px) and (max-width : 1160px){
            text-align: inherit;
            top: -22px;
        }

        > div{
            width: 80px;
            display: inline-block;
            text-align: center;

            @media (min-width : 0px) and (max-width : 1160px){
                width: 23.8%;
            }
        }
    }

    .thread .inboxSetting{
        top: -22px;

        @media (min-width : 0px) and (max-width : 1160px){
            top: 0px;
        }
    }

    .inbox-setting-btn {
    	cursor: pointer;
    	font-weight:400;
    	font-size:13px;
    }

    .bv-compose-message-screen .select2-container-multi .select2-choices .select2-search-field input{
    	letter-spacing: 0.6px;
    }

    .bv-mobile-compose-message .select2-container-multi .select2-choices .select2-search-field input{
    	letter-spacing: 0.6px;
    }
    .bv-mobile-compose-message section{
    	padding: 20px;
    }

    .bv-mobile-compose-message wrap section:first-child{
    	border-bottom: 1px solid #ccc;
    	position: relative;
    }
    .bv-mobile-compose-message .bv-modal-body > div > div > section,
    .bv-mobile-compose-message .bv-modal-body > div > div > div > section,
    .bv-mobile-compose-message .bv-modal-body ul > li > div > section{
    	border-top: 1px solid #ccc;
    	height: 175px;
    	padding: 20px 0;
    }
    .bv-mobile-compose-message .bv-modal-body ul > li > div > section{
    	position: relative;
    	top: -45px;
    }
    .bv-mobile-compose-message to,
    .bv-mobile-compose-message subject{
        margin-bottom: 20px;
        display: inline-block;
        color: #AFAFAF;
        font-weight: 500;
    }
    .bv-mobile-compose-message to{
    	margin-bottom: 15px;
    }
    .bv-mobile-compose-message to > btn{
    /*     background: url('../assets/images/android_icons_add-circle.png'); */
        width: 20px;
        height: 20px;
        position: absolute;
        right: 25px;
        top: 22px;
    }
    .bv-mobile-compose-message to > btn > svg{
    	width: 21px;
        height: 21px;
    /*     fill: #2574A7; */
    }
    .bv-mobile-compose-message .ui-select-container{
    	width: calc(100% - 80px);
    	font-size: 14px;
    }

    .bv-mobile-compose-message .select2-container-multi .select2-choices .select2-search-choice {
    	padding: 0px 18px 3px 3px;
        margin: 0px 0 3px 5px;
    }

    .bv-mobile-compose-message .select2-container-multi .select2-search-choice-close {
    	margin-top: -3px;
    }

    .bv-mobile-compose-message .select2-container-multi .select2-choices {
    	min-height: 24px;
    	max-height: 24px;
    }
    .bv-mobile-compose-message .select2-container-multi .select2-choices .select2-search-field {
    	height: 20px;
    	margin-top: -3px;
    }
    .bv-mobile-compose-message .select2-container-multi .select2-choices .select2-search-field input {
        height: 18px;
        padding: 0px 0 0 5px;
        letter-spacing: 0.6px;
        font-family: inherit;
        font-size: inherit;
    }
    .bv-mobile-compose-message {
    	font-size: 14px;
    }
    .bv-mobile-compose-message subjectinput{
    	width: calc(100% - 115px);
        display: inline-block;
    }
    .bv-mobile-compose-message subjectinput input{
    	width: 100%;
    	font-size: 14px;
    }
    .bv-mobile-compose-message .bv-sup-red-astric{
    	left: -4px;
        position: relative;
    }
    .bv-mobile-compose-message .select2-choices{
    	padding: 6px 0px !important;
    }
    .bv-mobile-compose-message wrap{
    	min-height: 100%;
        display: block;
        margin-bottom: -165px;
        height: 500px;
    }
    .bv-mobile-compose-message wrap:after{
    	content: "";
        display: block;
        height: 165px;
    }
    .bv-mobile .bv-compose-message-screen .outerComments1{
    	height: 100%;
    }

    .bv-mobile-compose-message attachments{
        top: -32px;
        position: relative;
        background-color: #fff;
        margin: 0 auto;
        display: block;
        width: 140px;
        text-align: center;
        color: #AFAFAF;
        font-weight: 500;
    }
    .bv-mobile-compose-message wrap section:last-child{
        height: calc(100% - 290px);
        min-height: 214px;
    }
    .bv-mobile-compose-message .bv-threads-background wrap section:last-child{
    	height: calc(100% - 380px);
    }

    .bv-mobile-compose-message attachments > btn{
        width: 20px;
        height: 20px;
        position: absolute;
        display: block;
        right: 0px;
        top: 1px;
    }
    .bv-mobile-compose-message attachments > btn > svg{
    	width: 21px;
        height: 21px;
    /*     fill: #2574A7; */
    }

    .bv-mobile-compose-message .compose-notice {
    	position: absolute;
    	top: 85px;
    }

    .bv-messages-mobile section{
        height: 45px;
        border-bottom: 1px solid #ccc;
        overflow: hidden;
    }
    .bv-messages-mobile inbox,
    .bv-messages-mobile sent{
        width: 50%;
        height: 100%;
        display: inline-block;
        text-align: center;
        font-size: 18px;
        position: relative;
        padding-top: 10px;
    	/*color: #ababab;*/
    }
    .bv-messages-mobile inbox.active span,
    .bv-messages-mobile sent.active span{
    	color: #000;
    	border-bottom: 5px solid #000;
        padding-bottom: 7px;
    }

    .bv-messages-mobile .inboxItemsGrid li{
    	border-bottom: 1px solid #ccc;
    	padding-right: 20px;
    }

    .bv-messages-mobile .inboxItemsGrid li:last-child{
    	border-bottom: inherit;
    }
    .bv-messages-mobile .inboxItemsGrid{
    	height: calc(100% - 45px);
    	overflow-y: auto;
    }
    .bv-messages-mobile .inboxItemsGrid li{
    	padding:0;
    	position:relative;
    }
    .bv-messages-mobile .inboxItemsGrid li > div{
    	transition: all 50ms;
    	height: 100%;
    	width: 100%;
    	background-color: #fff;
    	padding:10px;
    	position: relative;
    	z-index: 2;
    }
    .bv-messages-mobile .bv-inbox-delete-btn{
    	width: 100px;
        height: 100%;
        display: block;
        background-color: #E20023;
        position: absolute;
        right: 0;
        top: 0;
        border: inherit;
        color: #fff;
        z-index: 1;
    }

    .bv-messages-mobile .inboxListAttachmentIcon{
    	margin: 0 10px;
    }

    .bv-messages-mobile .inboxItemsGrid li > div.delete{
    	-webkit-transform: translateX(-100px);
    	transform: translateX(-100px);
    }
    /* .bv-messages-mobile .inboxItemsGrid li > div.delete .inboxListAttachmentIcon{
    	transform: translateX(-100px);
    }
     */

    .bv-messages-mobile .bv-button-feedback-type-2{
    	z-index: 2;
    	left:0;
    }

    .bv-open-message-mobile-header-row {
    	font-weight: 500;
    }

    .bv-open-message-mobile-header-row .lbl {
        width: 70px;
        display: inline-block;
        position: relative;
        text-align: right;
        color: #d1d1d1;
        margin-right: 5px;
    }

    .bv-open-message-mobile-header-row .val {
        display: inline-block;
        width: calc(100% - 85px);
    }

    .bv-open-message-mobile-subject {
        height: calc(100% - 20px);
        position: relative;
        padding-bottom: 20px;
        width: 100%;
     }
    .bv-open-message-mobile-comments {
    	position: relative;
    	overflow: auto;
    	font-size: 20px;
    }

    .bv-mobile-compose-message .bv-slider {
    	height: 165px;
        width: 100%;
        margin-top: -32px;
        text-align: center;
        text-align: -webkit-center;
    }
    .bv-mobile-compose-message .bv-slider > div {
    	top: calc(50% - 40px) !important;
    }

    .bv-mobile-compose-message .bv-slider > div.bv-slider-right-btn {
    	right: -5px;
    }

    .bv-mobile-compose-message .bv-slider > div.bv-slider-left-btn {
    	left: -5px;
    }
    .bv-mobile-compose-message .bv-slider > ul > li{
        width: 33.33333% !important;
        margin: 20px 0 !important;
        box-shadow: none;
    }
    .bv-mobile-compose-message .bv-slider > ul > li > card{
    	box-shadow: none;
    }

    .bv-mobile-compose-message .bv-slider > ul{
        min-width: inherit;
        margin: 0 25px !important;

    }
    .bv-mobile-compose-message .bv-slider > ul > li > ul > li:first-child{
    	margin-bottom: 20px;
    }
    .bv-mobile-compose-message .message-attachment-image-container {
    	width: 75px;
        height: 96px;
    }
    .bv-mobile-compose-message .bv-button-feedback-type-1{
        top: -12px !important;
        left: -12px !important;
    }
    .bv-mobile-compose-message .bv-mobile-menu .bv-button-feedback-type-1{
    	top: 0px !important;
    	left: 11px !important;
    }
    .bv-mobile-compose-message {
    /*  	z-index: 100 !important; */
    }
    .bv-mobile-compose-message-backdrop{
    /*  	z-index: 100 !important; */
     }

    #detailSection .itemComments {
        height: calc(100% - 295px);
        width: calc(100% - 2px);
        overflow: hidden;
        overflow-y: auto;
        padding: 14px 43px 10px 43px;
        display: inline-block;
        font-weight: 500;
        background: #fff;

        @media (min-height : 0px) and (max-height : 650px){
            height: calc(100% - 122px);
        }
    }
    #detailSection .itemDetails {
    	height: 100%;
    	width: calc(100% - 305px);
    	float: right;
    	background: #fff;
    	display: inline-block;
    	z-index: 2;
    	overflow: hidden;
    }
    #detailSection .itemHeader {
    	width: 100%;
    	background: white;
    	position: relative;
    	height: 122px;
        padding: 0;
    	border-bottom: 1.5px solid #DDDDDD;
    	margin-left: 0px;

    	.item-rows {
            padding: 16px 22px 21px 22px;
        }

        @media (min-width : 0px) and (max-width : 1160px){
            height: 140px;
        }
    }
    #detailSection .itemRow {
    	height: auto;
    	position: relative;
    	display: flex;
    	line-height:20px;
    }
    #detailSection .itemRow1 {
    	height: auto;
    	padding: 7px 0 0 0;
    	position: relative;
    	display: flex;
    	line-height:20px;
    }
    #inboxRightSection{
    	height: calc(100% - 25px);
    	list-style-type: none;
    	margin:0;
    	padding:0;
    	transition: 400ms all;
    	position: relative;
    }
    .inboxRightSection-li{
      width: 100%;
      background: #fff;
      transition: 500ms all;
      margin-bottom: 5px;
    }
    .inboxRightSection-li.opened{
      height: 100%;
    }
    .bv-inbox-thread-panel{
    	height: 40px;
    	padding: 8px;
    }
    #inboxRightSection .itemComments {
    	min-height: 100px;
    }
    #inboxRightSection .bv-hide-show-content-arrow {
    	cursor: pointer;
        width: 15px;
        height: 26px;
        float: left;
        margin: 7px 5px 0 0;
    }
    #inboxRightSection .bv-show-panel-content-arrow {
    	height: 15px;
        position: absolute;
        top: 6px;
        left: -17px;
        cursor: pointer;
        width: 15px;
    }
    #inboxRightSection .bv-show-panel-content-arrow svg {
    	transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        margin-bottom: 5px;
        fill:#000;
        height: 15px;
        width: 15px;
    }
    #inboxRightSection .bv-hide-show-content-arrow svg {
    	fill:#000;
    	transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform-origin: 0 0;
        -moz-transform: rotate(0deg);
    }
    #inboxRightSection .itemRow{
    	padding-top:3px;
    }
    .messageDetail{
    	opacity:0;
    	transition: opacity 700ms ease-in;
    	height: 100%;
    }
    .messageDetail.opened{
    	opacity:1;
    }
    .bv-inbox-subject-box {
    	width: calc(100% - 21px);
    	float: right;
    }
    .heightRecal {
    	height:685px;
    }
    .itemDetails .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: rgba(0,0,0,0.35);
    }

    .bv-mobile-compose-message .bv-modal-btn-custom-box{
    	position: absolute;
        top: 10px;
    }
    .bv-mobile-compose-message .bv-modal-btn-custom-box > div{
    	position: relative;
        display: inline-block;
        width: 25px;
        height: 25px;
        fill: white;
        margin: 0px 15px 0 0;
    }
    .bv-mobile-compose-message .bv-modal-btn-custom-box > div > div{
    	width: 25px;
        height: 25px;
    }
    .bv-mobile-compose-message .bv-modal-btn-custom [ng-if="showHeader"]{
    	margin-left: 130px;
    }

    /* messages.css */

    /* newitems.css */

     .newItemsMain {
    	height: 100%;
    	width: 100%;
    	white-space: nowrap;
    }

    .newItemsMain .bv-slider > ul {
    	text-align: center;
    	min-width: 880px;
    	min-height: 435px;
    }

    .newItemsMain .bv-slider > ul > li{
    	box-shadow: none !important;
    	margin: 20px 1.4%;
    	width: 30.4% !important;
    }

    .newItemsMain .bv-slider .bv-slider-items-2 > li {
    	width: 48% !important;
    }/*
    @media screen and (max-width: 900px) {
    	.newItemsMain .bv-slider > ul > li {
    		width: 47% !important;
    	}
    }

    @media screen and (max-width: 700px) {
    	.newItemsMain .bv-slider > ul > li {
    		width: 92% !important;
    	}
    }

    @media screen and (max-height: 700px) {
    	.newItemsMain .bv-slider > ul > li {
    		height: calc(100% - 200px) !important;
    		width: 23%;
    	}
    } */

    .newItemsList{
    	height: 100%;
    	width: 100%;
    	/* background: #fff; */
    	z-index: 1;
    /*	border-right: 1px solid #DDDDDD;*/
    	float: left;
    	display: inline-block;
    /*	padding-left: 100px;
    	padding-right: 100px;*/
    }

    .newItemsGrid {
    	/*border: 1px solid #D4D4D4;*/
    	width: 100%;
    	height: 100%;
    	overflow: hidden;
    }

    .newItemsGrid:hover {
    	overflow-y: scroll;
    }

    .newItemsGrid:hover li.sharedListItemCell {
    	padding-right:10px;
    }

    .newItemsGrid li:hover {
    	background: #F0F0F0;
    }

    .new-item-is-selected {
    	background: #F0F0F0 !important;
    /*	color: #fff;*/
    }


    .newItemsMoreBtnRow {
    	margin: 0px 11px 0 5px;
    	float: right;
    }

    .newItemsMoreBtn {
    	color:#FFFFFF;
    	min-width:50px;
    /* 	background-color: #036EAA; */
    	border-style:none;
    	max-height: 45px;
        min-height: 35px;
        margin: 5px 0 0 5px;
    }

    .newItemsMoreBtn:disabled {
    	background-color: #DDDDDD;
    }

    .newItemDetails {
    	height: 99%;
    	width: 100%;
    	float: right;
    	background: #fff;
    	display: inline-block;
    	z-index: 2;
    }

    li.newListItemCell {
    	 white-space: nowrap;
    	 border-bottom: 1px solid #D1D1D1;
    	 height:90px;
    	 padding-right: 18px;
    	 overflow: hidden;
    }

    /*li.sharedListItemCell:nth-child(odd) {
    	background: #ECECEC;
    }*/

    .newListCellItem {
    	margin: 5px 0px 0px 0px;
    }

    .newListLeftItems {
    	float: left;
    	width: 58%;
    	text-align: left;
    	margin: 5px 0 0 0;
    	display: inline-block;
    	font-size: 13px;
    	max-width: 600px;
    	overflow: hidden;
    }

    .newItemsListMsgInfo {
    	width: 62%;
    	/* text-align: right; */
    	margin: 7px 0 0 0;
    	display: inline-block;
    	font-size: 12px;
    	max-width: 600px;
    	overflow: hidden;
    }

    .newListRightItems {
    /*	float: right;*/
    	width: 55%;
    	text-align: right;
    	margin: 5px 0px 0px 5px;
    	display: inline-block;
    }

    .newItemsListTopItems {
    	width: 100%;
    	display: inline-block;
    	white-space: nowrap;
    }

    .newItemCreatedBy {
    	font-weight: 600;
    }

    .newItemCreatedOn {
    	font-size: 11px;
    }

    .newListFooter {
    	width: 30%;
    	text-align: left;
    }

    .newItemHeader {
    	width: 100%;
    	background: white;
    	position: relative;
    	min-height: 110px;
    	border-bottom: 1px solid #DDDDDD;
    }

    .newItemRow {
    	height: auto;
    	padding: 5px 0 0 0;
    	position: relative;
    	display: flex;
    }

    .newItemHeaderLabel {
    	text-align: right;
    	width: 60px;
    	float: left;
    	position: relative;
    }

    .newItemHeaderValue {
    	text-align: left;
    	/*width: 90%;*/
    	padding: 0 0 0 5px;
    	font-weight: 700;
    	float: right;
    	white-space: normal;
    }

    .newItemComments {
    	/* height: calc(100% - 254px); */
    /*	height: -moz-calc(100% - 254px);
    	 height: -webkit-calc(100% - 254px);
    	height: -o-calc(100% - 254px);*/
    	margin: 5px 0px 0 0px;
    /*	width: 100%;*/
    	overflow: hidden;
    	padding-right: 10px;
    	padding-left: 10px;
    	float: right;
    	width: 70%;
    	display: inline-block;
    	height: 100px;
    /*	border-left: 1px solid #D1D1D1;*/
    }

    .newItemComments:hover {
    	overflow-y: auto;
    	padding-right: 10px;
    }

    .newItemsAttachmentRow {
    	margin: 10px 0 0 0px;
    	text-align: left;
    	/* max-width: 338px; */
    	overflow: hidden;
    	white-space: nowrap;
    	/* position: absolute; */
    	/* border-top: 1px solid #DDDDDD; */
    	padding-right: 10px;
    	display: inline-block;
    	/* width: 25%; */
    	width: 40%;
    	/* text-align: right; */
    	/* margin: 5px 0px 0px 5px; */
    	display: inline-block;
    /*	background: green;*/

    }

    .newItemsAttachmentRow:hover {
    	overflow-x: auto;
    }

    .new-items-message-attachment {
    /* 	margin: 0 0px 0 0px;
    	padding-bottom: 4px;
    	width: 100%; */
    	/* background: green; */
    	height: 100%;
    }

    .new-items-attachment-image {
    	width: 60px;
    	height: 67px;
    	margin: 0 0px 0px 0;
    }

    .new-items-attach-portal-name{
    	color:#868585;
    	font-size: 12px;
    	text-align:center;
    	width: 100%;
    	text-overflow: ellipsis;
    	overflow: hidden;
    	white-space: nowrap;
    	margin-top:5px;
    	font-size: 12px;
    }

    .new-items-attach-name{
    	text-align:center;
    	width: 100%;
    	text-overflow: ellipsis;
    	overflow: hidden;
    	white-space: nowrap;
    	margin-top:5px;
    	font-size: 16px;
    }


    .new-items-attachment-row-in-card {
     	height: calc(100% - 100px);
    	/* margin: 0px 0 0 0px; */
    	text-align: center;
    /* 	width: 206px; */
    /*	overflow: hidden;
    	white-space: nowrap;*/
    	/* position: absolute; */
    	/* border-top: 1px solid #DDDDDD; */
    	/* padding-top: 20px; */
    /*	position: relative;*/
    /* 	background: red; */
    /*	height: 100%;*/
    }

    /*.new-items-attachment-row-in-card:hover {
    	overflow-x: auto;
    }*/

    .new-items-attachment-list {
    	height: 100%;
    }

    .new-items-attachment-image-container {
    	height: calc(100% - 100px);
    	width: 100%;
    	margin-bottom: 30px;
    	position: relative;
    	cursor: pointer;
    	box-shadow: 0px 0px 10px 0px #888888;
    	display: inline-block;
    	vertical-align: middle;
    }

    /*.new-items-attachment-image-container:before {
    	content: '';
    	display: inline-block;
    	height: 100%;
    	vertical-align: middle;
    	margin-right: -0.35em; /* Adjusts for spacing
    }*/

    .new-items-attachment-image-in-card {
    	/* width: 182px; */
    	/* height: 270px; */
    	/* margin: 10px 0px 0px 0; */
    	max-height: 100%;
    	max-width: 100%;
    	width: auto;
    	/* position: relative; */
    	/* box-shadow: 0px 0px 20px 0px #888888; */
        vertical-align: middle;
        display: inline-block;
    }

    .new-items-file-attachment-image {
    	border: 1px solid #DDDDDD;
    }

    .remove-new-item-button {
    	width: 15px;
    	height: 15px;
    	position: absolute;
    	cursor: pointer;
    	top: 10px;
    	right: 10px;
    }

    .remove-new-item-button > svg{
    	fill: #aeaeae;
    }

    .remove-new-item-button:hover > svg{
    	fill: #231f20;
    }
    .ngViewport.ng-scope {
    	overflow-y: hidden;
    }

    .ngViewport.ng-scope:hover {
    	overflow-y: auto;
    }


    /* User Avatar */

    .newItemsUserAvatar {
    	margin: 15px 10px;
    	/* height: 50px; */
    	/* float: left; */
    	/* white-space: nowrap; */
    	display: inline;
    }


    .newItemsUserImage {
    	text-align: center;
    	float: left;
    	width: 50px;
    	height: 50px;
    	position: relative;
    	display: block;
    	background: none;
    	z-index: 1;
    }

    .newItemsUserListCircle {
    	text-align: center;
    	width: 50px;
    	height: 50px;
    	overflow: hidden;
    	border-radius: 50%;
    	box-shadow: rgb(204, 204, 204) 0px 0px 6px 1px;
    	background: rgb(81, 81, 81);
    	display: inline-block;
    }

    .newItemsUserFirstLetter {
    	width: 50px;
    	margin-top: 8px;
    	color: white;
    	font-weight: lighter;
    	font-size: 25px;
    	letter-spacing: 1px;
    	display: block;
    }

    /************************************/

    .folderViewDialog .modal-dialog {
    	min-width: 900px;
    	overflow: hidden;
    }



    /*********** Add to new Items *************/

    .addToNewItemsDialog .modal-dialog {
    	width: 370px;
    	overflow: hidden;
    	position: absolute;
    	top: 50%;
    	left: 50%;
    	transform: translate(-50%,-50%) !important;
    	-webkit-transform: translate(-50%,-50%) !important;
        -ms-transform:  translate(-50%,-50%) !important;
    	margin: 0;
    }

    .addToNewItemsDialog .bv-input-btn-0 {
      background-color: #000;
    }

    .add-new-items-main {
    	min-height: 490px;
    	padding: 20px;
    	margin-top: -3px;
    }

    .attachmentLbl {
    	border-bottom: 1px solid #e5e5e5;
    	font-weight: 500;
    	width: 100%;
    	padding-bottom: 5px;
    }

    .add-new-items {
    	width: 100%;
    	height: 420px;
    	overflow-x: hidden;
    	overflow-y: hidden;
    	margin: 10px 0 10px 0;
    }

    .add-new-items:hover {
    	overflow-y: auto;
    }

    .add-new-list-item {
    	white-space:nowrap;
    	margin:10px 0 0 0;
    }

    .add-new-item-attachment-row {
    	width: 100%;
    	height: 60px;
    	display: inline-block;
    	overflow: hidden;
    	margin: 10px 0 10px 0;
    /*	cursor: pointer;
    */	padding-right: 10px;
    }
    /*
    .add-new-item-attachment-row:hover {
    	background-color: #f4f4f4;
    }*/
    .add-new-item-attachment-row:hover .drag-handle-image {
    	display: block;
    }
    .add-new-item-attachment-row:hover .add-new-item-attachment-remove-btn
    {
    	fill:#D3D3D3;
    	visibility:visible;
    }

    .add-new-item-attachment-image {
    	width: 50px;
    	height: 60px;
    	margin: 0 10px 0 0;
    	cursor: pointer;
    }
    .add-new-item-attachment-image div,
    .add-new-item-attachment-image img{
    	width: 45px;
    	height: 55px;
    }
    .add-new-item-attachment-name {
    	width: 200px;
    /*	line-height: 60px;
    */	text-overflow: ellipsis;
    	overflow: hidden;
    	white-space: nowrap;
    	margin-top: 20px;
    }
    .add-new-item-attachment-name:hover {
    	cursor: pointer;
    	text-decoration: underline;
    }

    .add-new-item-attachment-remove {
    	height: 100%;
    }

    .add-new-item-attachment-remove-btn {
    	width: 17px;
    	height: 17px;
    	position: relative;
    	cursor: pointer;
    	margin-top: 22px;
    	visibility:hidden;
    }
    .add-new-item-attachment-remove-btn:hover
    {
    	visibility:visible !important;
    	fill:black !important;
    }

    .add-new-item-expiry {
    	width: 160px;
    }

    .expiryLbl {
    	font-weight: 500;
    	width: 100%;
    	padding-bottom: 5px;
    }
    /******************************/



    /*** New Items Cards ***/


    .bv-newitems-cards{
    	outline:0;
    	font-size: 16px;
    }
    .bv-newitems-cards ul[rn-carousel]{
    	width:33.3%;
    	overflow:inherit;
    }
    .bv-newitems-cards ul[rn-carousel] li>div>div>ul,
    .bv-newitems-cards ul[rn-carousel] li>div{
    	padding:10px;
    }
    .bv-newitems-cards ul[rn-carousel] li>div>div{
    	/* padding: 10px; */
    	/* border: 1px solid #ccc; */
    	/* background: #F5F5F5; */
    	/*box-shadow: 0px 0px 5px 1px #ddd;*/
    	/* border-radius: 10px; */
    	overflow: hidden;
    }

    .bv-newitems-cards ul[rn-carousel] li>div>div>title{
    	display: block;
    	font-size: 2em;
    	font-weight: 500;
    	line-height: 27px;
    	margin-bottom:30px;
    }
    .bv-newitems-cards ul[rn-carousel] li>div>div>title:hover{
    	text-decoration: underline;
    	cursor:pointer;
    }
    .bv-newitems-cards ul[rn-carousel] li>div>div>label{
    	display: block;
    	font-size: 1em;
    	line-height: 10px;
    }
    .bv-newitems-cards ul[rn-carousel] li>div>div>info{
    	font-size: 0.9em;
    	margin-bottom: 23px;
    	display: block;
    }

    .bv-newitems-cards-module ul[rn-carousel] li>div{
    	padding:5px;
    }
    .bv-newitems-cards-module ul[rn-carousel] li>div>div>title{
    	font-size: 1.5em;
    	line-height: 20px;
    	margin-bottom: 15px;
    	height: 41px;
    	text-overflow: ellipsis;
    	overflow: hidden;
    }
    .bv-newitems-cards-module ul[rn-carousel] li>div>div>info{
    	font-size: 0.8em;
    	margin-bottom: 13px;
    }



    /* .bv-newitems-cards ul[rn-carousel] li>div>div>type{
    	font-size: 1.1em;
    }
    .bv-newitems-cards ul[rn-carousel] li>div>div>line{
    	background: rgb(169, 109, 29);
    	height: 6px;
    	width: 100%;
    	display: block;
    } */
    /* .bv-newitems-cards ul[rn-carousel] li>div>div>ul li{
    	padding: 15px 0px;
    }
    .bv-newitems-cards ul[rn-carousel] li>div>div>ul li>title{
    	display: block;
    	font-size: 1.4em;
    	font-weight: 500;
    }
    .bv-newitems-cards ul[rn-carousel] li>div>div>ul li>info{
    	font-size: 0.9em;
    } */
    .bv-newitems-cards div[rn-carousel-indicators]{
    	position: absolute;
    	bottom: 20px;
    	width: 100%;
    	text-align: center;
    }
    .bv-newitems-cards div[rn-carousel-indicators] span{
    	margin: 0 2px;
    }
    
    .bv-conf-settings .show-company-tree
    {
    		height : calc(100% - 30px);
    }
    
    /***************************/


    .new-items-prev {
    	position: absolute;
    	top: 0;
    	width: 100px;
    	text-align: center;
    	height: 100%;
    }

    .new-items-next {
    	position: absolute;
    	top: 0;
    	width: 100px;
    	right:0;
    	text-align: center;
    	height: 100%;
    }

    .newItemList{
        display: none;
    }
    /* newitems.css */

}
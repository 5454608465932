.nasdaq.bv-mobile{

    [state="home.meetings"]{
        .bv-transclude-content{
            min-width: inherit;
        }

        .bv-conf-event-selected{
            width: inherit;
        }
    }

}
@import 'src/utils/variables';

.nasdaq{

    .p-t-5{
       padding-top: 5px;
    }

    .attachmentCheckBox {
        height: 59px;
        width: 100%;
        padding-top: 9px;
        padding-left: 6px;
        margin: 0px;
    }

    .attachmentIcon {
        height: 33px;
    }

    .attachmentIcon img {
        height: 100%;
        position: relative;
        top: 3px;
        left: 13px;
    }

    .attachmentIconText {
        position: relative;
        top: 8px;
        left: 13px;
    }

    .attachmentIconTextFont {
        font-weight: 300;
        font-size: 20px;
    }

    .attachmentTitle {
        display: block;
        margin-top: 5px;
        margin-left: 5px;
    }


    .list-checkbox {
        background: url("#{$resources-path}/assets/images/checkbox.png") no-repeat;
        height: 27px;
        width: 27px;
        margin-top: 19px;
        float: right;
    }

    .list-checkbox.collate-checkbox {
        top: 50%;
        float: left;
        position: absolute;
        margin-top: -14px;
    }

    .signatureList ul li {
        height: 125px;
    }

    bv-repository-list { overflow:hidden; padding: 20px 20px 20px 0px; }

    .documentsList { text-align:left; height:100%; position:relative;}

    /*.document-list-ul li:nth-child(odd) { background: #f7f9fa;}*/
    .document-list-ul li:hover {
        background: $documentSelectedBackgroundHover;
    }

    .documentsList ul li {
       height: 52px;
       padding-left:10px;
       padding-right: 15px;
    }
    
    /*.documentsList .briefcaseList ul li{
       height: 100px;
    }*/
    
    .large.documentsList ul li {
        height: 60px;
    }
    /*.large.documentsList .briefcaseList ul li {
        height: 108px;
    }*/
    .small.documentsList ul li {
        height: 38px;
    }
    .small.documentsList .show-portal-name ul li {
        height: 44px;
    }
    /*.small.documentsList .fileNameVal .documentDetails{
       padding-bottom: 5px;
    }
    
    .large.documentsList .briefcaseList .fileNameVal .documentDetails{
       padding-bottom: 22px;
    }
    
    .small.documentsList .briefcaseList .fileNameVal .documentDetails{
        padding-bottom: 25px;
    }*/
    .documentsList ul li.checked {
        background: $documentSelectedBackground;
        cursor: pointer;
    }
    .documentsList ul li.checked:hover {
        background: $documentSelectedBackgroundHover;
    }

    .documentsList ul.document-list-ul > li {
         border-bottom: 1px solid $grayLighter;
    }
    /* .documentsList ul li.uncheckedClass { } */

    .documentsList-row
    {
        display: block;
        width: 100%;
        height: 100%;
        cursor: default;
    }
    
    /*.documentsList ul li .documentsList-row .icon {
        padding: 18px 10px 15px 10px;
    }*/

    .medium.documentsList .list-checkbox { margin-top: 11px; }
    .small.documentsList .list-checkbox { margin-top: 6px; }

    .list-checkbox.checked { background: url("#{$resources-path}/assets/images/checkbox_on.png") no-repeat; }
    .documentsList .list-checkbox.checked {	background: url("#{$resources-path}/assets/images/checkbox_on.png") no-repeat; }

    .documentsList .icon, #showSharedDocsDIV .icon { padding:5px 16px 0px 10px; display:block; float:left;}
    .medium.documentsList .icon, #showSharedDocsDIV .icon { padding:6px 8px 3px 3px; }
    .large.documentsList .icon, #showSharedDocsDIV .icon { padding:12px 22px 0 10px; }
    .small.documentsList .icon, #showSharedDocsDIV .icon {
        padding:0 24px 0 0;
        position: relative;
        top: -5px;
        margin-left: 5px;
    }

    .documentsList .converting .icon{
        padding: 16px 10px 0 10px;
    }
    .small.documentsList .converting .icon{
        padding: 12px 10px 0 0px;
    }

    .documentsList .show-portal-name .icon, #showSharedDocsDIV .icon { position: relative;}

    .documentsList li[data-extensiontype=folder] .icon {
        margin: 15px;
    }

    .documentsList .icon .iconImage img,
    .documentsList .icon .iconImage boardbook,
    .documentsList .icon .iconImage > div,
    .documentsList .icon .iconImage::before{
        width: 23px;
        height: 44px;
        float: left;
        font-size: 25px;
        line-height: 45px;
    }

    .documentsList .folder .icon .iconImage > div {
        color: $yellowLight;
    }

    .small.documentsList .folder .icon .iconImage > div {
        font-size: 20px;
     }
    .large.documentsList .folder .icon .iconImage > div {
       font-size: 30px;
    }

    .documentsList .icon .iconImage > div > svg,
    .documentsList .icon .iconImage boardbook > svg{
        top: 5px;
        position: relative;
    }

    .medium.documentsList .icon .iconImage img,
    .medium.documentsList .icon .iconImage boardbook,
    .medium.documentsList .icon .iconImage > div { width: 27px; height: 33.94px; }
    .small.documentsList .icon .iconImage img,
    .small.documentsList .icon .iconImage boardbook,
    .small.documentsList .icon .iconImage > div { width: 15px; height: 23.88px; position:relative; top:4px;}
    .small.documentsList .icon .iconImage > div > svg {
        width: 15px;
        height: 23.88px;
        position: relative;
        top: 8px;
    }
    .small.documentsList .show-portal-name .icon .iconImage > div > svg {
        top: 11px;
    }

    .documentsList .converting .icon .iconImage img {
        width: 23px;
        height: 23px;
    }
    .small.documentsList .converting .icon .iconImage img {
        width: 15px;
        height: 15px;
    }

     documentsList .portal-name-lineheight.fileNameVal > span { padding-top: 10px;}
    .medium.documentsList .portal-name-lineheight.fileNameVal > span { padding-top: 5px;}
     .small.documentsList .portal-name-lineheight.fileNameVal > span { padding-top: 5px;}

    .documentsList .fileNameVal .name-span {cursor: pointer;display: inline;color: #0094b3;font-weight: bold;font-size: 14px;}
    .small.documentsList .fileNameVal .name-span {font-size:12px;}
    .large.documentsList .fileNameVal .name-span {font-size:16px;}
    //.small.documentsList .briefcaseList .fileNameVal .name-span {display: block;}
    .documentsList .fileNameVal .name-span:hover { text-decoration: underline;}
    .documentsList .fileNameVal .name-span.bv-open-disabled:hover { text-decoration: inherit; }

    .documentsList .fileSizeVal, .documentsList .fileDate, .documentsList .fileDateVal,
        .documentsList .fileUploadedBy, .documentsList .fileUploadedByVal,
        .documentsList .deliver {
        font-size: 14px;
    }

    .documentlist-selection-text:hover { /*text-decoration: underline;*/}

    .medium.documentsList .fileSizeVal, .medium.documentsList .fileDate, .medium.documentsList .fileDateVal,
        .medium.documentsList .fileUploadedBy, .medium.documentsList .fileUploadedByVal,
        .medium.documentsList .deliver {
        font-size: 12px;
    }

    .small.documentsList .small.fileSizeVal, .small.documentsList .fileDate, .small.documentsList .fileDateVal,
        .small.documentsList .fileUploadedBy, .small.documentsList .fileUploadedByVal,
        .small.documentsList .deliver {
        font-size: 9px;
    }

    .documentsList .fileInfo.recycleFileInfo { width: 75%;}

    .documentsList .fileInfo .fileNameDiv {
        height: 100%;display:
        inline-block;
        width: 100%;
        min-width: 65px;
        max-width: calc(100% - 25px) !important;
        padding: 14px 0;
    }
    .documentsList .show-portal-name .fileInfo .fileNameDiv{
        padding: 7px 0;
    }
    .small.documentsList .fileInfo .fileNameDiv{
        padding: 8.2px 0;
    }
    .small.documentsList .show-portal-name .fileInfo .fileNameDiv{
        padding: 5px 0;
    }
    .large.documentsList .fileInfo .fileNameDiv{
        padding: 18px 0;
    }
    .large.documentsList .show-portal-name .fileInfo .fileNameDiv{
        padding: 10px 0;
    }
    .documentsList .fileInfo .agendaFileNameDiv {
        height: 100%;
        display: inline-block;
        width: 100%;
    }
    .documentsList .fileInfo .agendaFileNameDiv.full-width {       
            max-width: 100% !important;
            .fileNameVal{
                padding: 15px 0;
            }
    }
	
    .documentsList .fileInfo .fileDetailsDiv{
        font-size: $commonFontSize;
        vertical-align: top;
        width: 350px;
        font-weight: $commonFontWeight;
        float: right;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .documentsList .show-portal-name .fileInfo .fileDetailsDiv{
        padding-top: 7px;
    }

    .documentsList .fileInfo .statusDiv{
        font-size: $commonFontSize;
        display: inline-block;
        vertical-align: top;
        width: 100px;
        margin-top: 20px;
        font-weight: $commonFontWeight;
    }

    .large.documentsList .fileInfo .fileDetailsDiv {
        font-size: $commonFontSizeLarge;
    }
    
    /*.large.documentsList .fileNameVal .name-span , .large.documentsList .fileInfo .fileDetailsDiv .fileDetailsSpan .fileByName{
        font-size: 16px;
    }
    
    .small.documentsList .fileNameVal .name-span{
        font-size: 12px;
    }
    
    .small.documentsList .fileInfo .fileDetailsDiv .fileDetailsSpan .fileByName{
        font-size: 11px;
    }*/
    
    .small.documentsList .fileInfo .fileDetailsDiv{
        font-size: $commonFontSizeSmall;
        > span > span{
            &.fileByDates{
                padding-right: 5px;
            }
        }
    }

    /*.small.documentsList ul li:hover .fileInfo .fileNameDiv,
    .small.documentsList ul li.checked .fileInfo .fileNameDiv{
        max-width: calc(100% - 280px);
    }*/

    .documentsList ul li:hover .fileInfo .portal-name-span { max-width: calc(100% - 25px) !important;}
    .bv-mobile .documentsList ul li .fileInfo .portal-name-span { max-width: calc(100% - 230px) !important;}
    .documentsList ul li .fileInfo .portal-name-span { max-width: calc(100% - 430px);}

    .documentsList ul li:hover .fileInfo .fileDetailsDiv .fileDetailsSpan,
    .documentsList ul li.checked .fileInfo .fileDetailsDiv .fileDetailsSpan{
        display: block;
    }

    /* 		.documentsList .fileInfo .document-menu span svg { width: 0; height: 0; } */
    /* 		.documentsList ul li:hover .fileInfo .document-menu { display: block; } */

    .documentsList .fileNameVal > svg{height: 15px;width: 11px;position: relative;fill: rgb(0, 0, 0);margin: 0 5px 0;display: none;top:-3px;
    }
    .medium.documentsList .fileNameVal > svg {
        margin: 15px 5px 0 5px
    }
    .small.documentsList .fileNameVal > svg {
        width: 10px;
        top: -1px;
    }
    .small.documentsList .show-portal-name .fileNameVal > svg {
        top: -15px;
    }
    .large.documentsList .fileNameVal > svg {
        width: 12px;
        top: -3px;
    }
    .large.documentsList .show-portal-name .fileNameVal > svg {
        margin: 0;
    }


      .portal-name-lineheight.fileNameVal > svg{ margin: 15px 5px 0 5px ;}
    .medium.documentsList .portal-name-lineheight.fileNameVal > svg{ margin: 10px 5px 0 5px ;}
     .small.documentsList .portal-name-lineheight.fileNameVal > svg{ margin: 5px 5px 0 5px ;}
     //.small.documentsList .briefcaseList .portal-name-lineheight.fileNameVal > svg{ margin: 2px 5px 0 5px ;}

    .documentsList
    {
        .fileNameVal > span
        {
            max-width: calc(100% - 30px);
            cursor: pointer;
            font-size: $commonFontSize;
            height: 100%;
            font-weight: $commonFontWeight;
            text-overflow: ellipsis;
    		overflow: hidden;
        }
        
        .fileDetailsDiv .virus-infected {
            font-style: italic;
            color: red;
            display: block;
        }
    }
    /* .small.documentsList .fileNameVal>span.virus-infected , .documentsList .briefcaseList .fileNameVal>span.virus-infected , .small.documentsList .briefcaseList .fileNameVal>span.virus-infected{
         padding-top: 2px !important;
     }
     .large.documentsList .fileNameVal>span.virus-infected{
         padding-top: 0;
     }*/
     .large.documentsList .fileNameVal > span{
        padding-top: 17px;
        font-size: $commonFontSizeLarge;
    }
     .small.documentsList .fileNameVal > span{
        padding-top: 10px;
        font-size: $commonFontSizeSmall;
    }

    .documentsList .portal-name-lineheight.fileNameVal > span { padding-top: 10px;}
    .medium.documentsList .portal-name-lineheight.fileNameVal > span { padding-top: 6px;}
    .small.documentsList .portal-name-lineheight.fileNameVal > span { padding-top: 6px;}

    .documentsList .fileNameVal {
        display: block;
        height: 100%;
    }

    .documentsList .fileNameDiv .fileNameVal .documentDetails{
        display: inline-block;
        max-width: calc(100% - 430px);
    }
    .documentsList .document-list-ul li:hover .fileNameDiv .fileNameVal .documentDetails{
        max-width: calc(100% - 450px);
    }

    /************Android Tablet*************************/

    @media screen and (device-width: 601px)  and (device-height: 906px)  and (-webkit-min-device-pixel-ratio: 1.331)  and (-webkit-max-device-pixel-ratio: 1.332) {
       .documentsList .document-list-ul li:hover .fileNameDiv .fileNameVal .documentDetails{
          max-width: calc(100% - 276px);
       }
    }
    @media  (min-device-width: 800px)  and (max-device-width: 1280px)and (-webkit-min-device-pixel-ratio: 2) {
        .documentsList .document-list-ul li:hover .fileNameDiv .fileNameVal .documentDetails{
            max-width: calc(100% - 276px);
         }
    }

    
    .small.documentsList .document-list-ul li:hover .fileNameDiv .fileNameVal .documentDetails{
        max-width: calc(100% - 445px);
    }
    .large.documentsList .document-list-ul li:hover .fileNameDiv .fileNameVal .documentDetails{
        max-width: calc(100% - 455px);
    }

    .documentsList .fileNameVal .documentDetails .name-span{
        display: inline-block;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
    }

    /*.documentsList .briefcaseList .fileNameVal .documentDetails{
        padding-bottom: 20px;
    }*/

    .documentsList .fileNameVal .portal-name-span{
        font-size: 12px;
        left: 0;
        color: #868585;
        top: 25px;
        display: block;
        position: absolute;
    }

    .medium.documentsList .fileNameVal .portal-name-span{
         top: 24px;
         font-size: 11px;
        }

    .small.documentsList .fileNameVal .portal-name-span{
        display: inline;
        position: inherit;
        line-height:15px;
    }
    .small.documentsList .show-portal-name .fileNameVal .portal-name-span{
        display: block;
        line-height: 11px;
    }
    .large.documentsList .show-portal-name .fileNameVal .portal-name-span{
        top: 33px;
    }
    /*.documentsList .briefcaseList .fileNameVal .portal-name-span{
         position: absolute;
         top: 33px;
    }

    .large.documentsList .briefcaseList .fileNameVal .portal-name-span{
      font-size: 13px;
    }*/
    .documentsList .fileNameVal .default-template-span {
        font-size: 12px;
        position: absolute;
        left: 0;
        color: #868585;
        top: 37px;
    }

    .medium.documentsList .fileNameVal .default-template-span {
         top: 28px;
         font-size: 11px;
        }

    .small.documentsList .fileNameVal .default-template-span {
         top: 20px;
         font-size: 9px;
    }

    .documentsList ul li:hover .fileNameVal > svg {display: inline;}
    .documentsList .show-portal-name ul li:hover .fileNameVal > svg {margin: 0 !important;}

     /*.documentsList .fileInfo .fileDetailsDiv .fileDetailsSpan {
          display: block;
          clear: both;
    }*/
   

    .documentsList .fileInfo .fileDetailsDiv .fileDetailsSpan .fileByName{      
        white-space: nowrap;
   		overflow: hidden;
    	text-overflow: ellipsis;
    	width:100%;

    }
    .documentsList .fileInfo .fileDetailsDiv .fileDetailsSpan .fileByDates{
   		width:100%;
        white-space: nowrap;
   		overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 5px;
    }
    /* .small.documentsList .fileInfo .fileDetailsDiv .fileDetailsSpan > span{
        display: inline;
    }
    */


    .documentsList .fileInfo {
        height: 50px;
        width: calc(100% - 125px);
        color: #000;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        float: left;
        margin-left: 25px;
    }
    /*.documentsList .briefcaseList .fileInfo{
       height: 100px;
    }*/
    
    .medium.documentsList .fileInfo {
        height:42px;
        width: calc(100% - 70px);
    }
    .small.documentsList .fileInfo {
        height: 37px;
        width: calc(100% - 119px);
    }
    .small.documentsList .show-portal-name .fileInfo{
        height: 42px;
    }
    .large.documentsList .fileInfo{
        height:60px;
    }
    
    /*.small.documentsList .briefcaseList .fileInfo{
         height: 90px;
     }*/

     .documentsList .show-portal-name .fileInfo {position: relative;}
     .documentsList .bv-repository-checkbox {
        margin-top:0px;
        line-height: 50px;
     }
     
      .documentsList .briefcaseList .bv-repository-checkbox{
          line-height: 52px;
      }
     
     .small.documentsList .bv-repository-checkbox{
         line-height: 20px;
     }
     
     .small.documentsList .briefcaseList .bv-repository-checkbox{
         line-height: 20px;
     }

     .small.documentsList .show-portal-name .bv-repository-checkbox{
         line-height: 25px;
     }
     
     .large.documentsList .bv-repository-checkbox{
         line-height: 65px;
     }
     
     .large.documentsList .briefcaseList .bv-repository-checkbox{
         line-height: 68px;
     }

     .large.documentsList .show-portal-name .bv-repository-checkbox{
        line-height: 65px;
     }

    .bv-repository-checkbox {
        margin-top:18px;
        height:27px;
        width:27px;
        float:right;
    }
    .medium.documentsList .bv-repository-checkbox {
        margin-top:14px;
        height:22px;
        width:22px;
    }
    .small.documentsList .bv-repository-checkbox {
        margin-top:8px;
        height:17px;
        width:17px;
        padding-right: 30px;
    }

    .bv-tree-node {
        padding-left: 20px;
        line-height: 40px;
        height: 40px;
    }
    .bv-tree-node::before{
        content: "";
        border-top: 6px solid #1080A2;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        top: -5px;
        right: 7px;
        position: relative;
        display: inline-block;
        transform: rotate(270deg);
    }
    .collpsed.bv-tree-node::before{
        transform: rotate(0);
    }
    .folderHidden{
    	& > .bv-tree-node{
    	   .treeImage{
    	     color:$grayMid!important;
    	   }
    	   .bv-tree-node-name{
    	     	color:$grayMid;
    	     }
           &:before{
	        border-top: 6px solid $grayMid;
         }
        }
    }
    .medium.documentTree .bv-tree-node { padding-left: 20px; line-height: 45px; height: 45px; }
    .small.documentTree .bv-tree-node { padding-left: 20px; line-height: 33px; height: 30px; }

    .bv-tree-node-selected {
        background: $documentSelectedBackground;
    }
    .bv-tree-node-selected .treeImage{
        color: $yellowMid !important;
    }

    .bv-tree-node:hover {
        background: $documentSelectedBackgroundHover;
        cursor:pointer;
    }

    .bv-tree-node .treeImage {
        padding: 7px 10px 0 0px;
        display: inline-block;
        position: relative;
        color: $yellowLight;
    }
    .medium.documentTree .bv-tree-node .treeImage { height: 45px; padding:5px 10px 13px 0px; }
    .small.documentTree .bv-tree-node .treeImage { height: 33px; padding:3px 10px 13px 0px; }

    .bv-tree-node .treeImage img,
    .bv-tree-node .treeImage > div,
    .bv-tree-node .treeImage::before{
        padding:0px;
       /* width:35px;
        height: 44px; */
        width: 18px;
        height: 21px;
        font-size: 18px;
        line-height: 31px;
    }

    .medium.documentTree .bv-tree-node .treeImage img,
    .medium.documentTree .bv-tree-node .treeImage > div { padding:0px; width:27px; height: 33.94px; }
    .small.documentTree .bv-tree-node .treeImage img,
    .small.documentTree .bv-tree-node .treeImage > div { padding:0px; width:19px; height: 23.88px; }

    /*.bv-tree-node-name {font-size:35px; display:inline-block;position:absolute;text-overflow:ellipsis; overflow:hidden; white-space:nowrap; }*/
    .bv-tree-node .bv-tree-node-name {
        font-size: $commonFontSize;
        display: inline-block;
        position: absolute;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: $nasdaqBlue;
        font-weight: $commonFontWeight;
    }
    .large.documentTree .bv-tree-node .bv-tree-node-name {
        font-size: $commonFontSizeLarge;
    }
    .small.documentTree .bv-tree-node .bv-tree-node-name {
        font-size: $commonFontSizeSmall;
    }

    .bv-tree-node-name:hover  { text-decoration: underline; }

    .bv-tree-minimize-div {
        cursor:pointer;
        position: absolute;
        height: 20px;
        width: 20px;
        border: 0px solid blue;
        top: 0;
        display: block;
        z-index: 3;
    }
    .bv-tree-minimize-div-showTree {
        left: 10px;
    }
    .bv-tree-minimize-div-hideTree {
        left:239px;
    }
    .bv-tree-minimize-div-hideTree > .bv-tree-caret {
        right: 0px;
        position: absolute;
        top: 7px;
        border-top: 12px solid #E5E5E5;
        border-right: 9px solid transparent;
        border-left: 9px solid transparent;
    }
    .bv-tree-minimize-div-showTree > .bv-tree-caret {
        right: 3px;
        position: absolute;
        top: 7px;
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
        border-left: 12px solid #E5E5E5;
    }

    .breadcrumb {
        padding: 3px 15px 0px;
        list-style: none;
        border-radius: 4px;
        margin: 0;
        background: none;
    }

    .breadcrumb a {
        color: #FFF;
        text-decoration: none;
        font-weight: 300;
    }

    .breadcrumb-currentFolder {
        margin: 0px 300px 0px 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 2.1em;
        color: #fff;
        font-weight: 100;
    }

    .createFolder-div {
        width: auto;
        min-height: 68px;
        max-height: none;
        height: auto;
    }

    .small-dialog-class {
        min-height: 150px;
    }

    .small-alert-dialog-class {
        min-height: 70px;
        padding-top: 20px;
    }

    .small-version-class {
        min-height: 300px;
    }

    .createFolder-div form {
        display: block;
        margin-top: 1em;
    }

    .popup-title {
        font-size: 25px;
        font-weight: bold;
        margin: 0px;
        padding: 0;
        color: #515151;
        margin-bottom: 30px;
    }

    .ui-dialog-buttonpane {
        bottom: 12px;
        position: absolute;
        right: 12px;
    }

    /* .ui-dialog-buttonpane button{
        background-color: rgb(0,72,118);
        color: #FFFFFF;
        border: 0px;
    } */
    .ui-dialog-buttonpane span {
        padding: 4em;
    }

    .rightClickedOn {
        background: $documentSelectedBackground !important;
    }

    .rightClickMenu {
        display: block;
        position: fixed;
        z-index: 1000;
        float: left;
        min-width: 210px;
        padding: 5px 0;
        margin: 2px 0 0;
        font-size: 14px;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ccc;
        border: 1px solid rgba(0, 0, 0, .15);
        border-radius: 0px;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        max-height: 551px;
        overflow-y: hidden;
        overflow-x: hidden;
            margin-top: -45px;
    }

    .rightClickMenu:hover {
        overflow: auto;
    }

    .rightClickMenu ul li  {
        padding: 0px 15px 0px 15px;
        overflow-x: hidden;
        display: block;
        height: 27px !important;
    }

    .rightClickMenu ul {
        margin: 0px 0px 0px 0px;
        position: relative;
        line-height: 27px;
        padding: 0;
        overflow-x: hidden;
    }

    .rightClickMenu ul li.menu-ul-seprator-height
    {
        height: 9px !important;
        pointer-events: none;
    }

    .rightClickMenu ul li hr
    {
        margin-top: 4px;
        margin-bottom: 0px;
    }

    .rightClickMenu-a {
        color: #000;
        text-decoration: none;
    }

    .rightClickMenu a {
        color: #000;
        text-decoration: none;
        font-weight: 300;
        display: block;
        white-space: nowrap;
    }

    .rightClickMenu bt {
        color: #000;
        text-decoration: none;
        font-weight: 300;
        display: block;
        white-space: nowrap;
    }

    .fileChooser {
        /* height: auto; */
        height: 371px;
        width: 420px;
        display: block;
        min-height: 372px;
    }

    p.pOne {
        font-size: 25px;
        font-weight: bold;
        margin: 0;
        padding: 0;
        color: #515151;
        margin-bottom: 30px;
    }

    p.pThree {
        color: #515151;
        font-size: 13px;
        margin: -30px 0px 15px 0px;
        font-weight: 300;
    }

    .treeDiv {
        clear: left;
        background: #fff;
        border: 1px solid #ccc;
        padding: 10px;
        border-radius: 3px;
        margin-bottom: 5px;
        max-height: 280px;
        overflow: auto;
    }

    .showTreeClass {
        /*width: 79.5%;*/
        width:calc(100% - 251px);	/* 1px width is of ruler */
    }

    .hideTreeClass {
        width: 100% !important;
    }

    .treeDoc {
        float: left;
        position: relative;
        width: 250px;
        height: 100% ;
       /* overflow: hidden;*/
        background: $lightMockGrayBackground;
        /*margin-top:15px;*/
        overflow:hidden !important; 
    }

    .treeDoc:hover {
        overflow-y: auto;
    }

    .docmain {
        height: 100%;
        width: 100%;
        margin-top: 9px;
    }

    .collatedocImg img {
        height: 38px;
    }

    .shareDIV {
        width: 26px;
        height: 19px;
        float: left;
        margin: 3px 10px;
        cursor: pointer;
        background: url("#{$resources-path}/assets/images/people_share.png") no-repeat;
    }

    .expandDoc {
        width: 30px;
        height: 30px;
        position: absolute;
        left: 231px;
        top: 21px;
        cursor: pointer;
    }

    .small-file-list {
        color: #6D6D6D;
        width: 350px;
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 10px;
    }

    .small-version-list {
        color: #000;
        width: 300px;
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 10px;
        float: left;
    }

    .upload-small-icon {
        float: left;
        position: relative;
        top: 4px;
    }

    .upload-small-icon img {
        min-height: 34px;
        float: left;
        padding-left: 4px;
        max-height: 38px;
    }

    .badgeImg {
        position: absolute;
        bottom: 0px;
        left: 0;
        height: 18px !important;
        width: 26px !important;
    }

    .version-semiMedium-icon img {
        min-height: 65px;
        float: left;
        padding-left: 4px;
        max-height: 65px;
    }

    .version-semiMedium-icon {
        float: left;
        margin-top: 3px;
    }

    .small-file-name-val {
        font-size: 11px;
        position: relative;
        display: block;
    }

    .small-file-size-val {
        top: -5px;
    }

    .hideFolderClass {
        opacity: 0.25;
    }
    .hideFolderNameClass {
        opacity: 0.35;
    }

    .holder-uploadfile {
        height: 270px;
        /*overflow: hidden;*/
        margin: 20px;
    }

    .holder-uploadfile-row {
        height:66px;
        border-top: 1px solid #DFDFDF;
        border-bottom: 1px solid #DFDFDF;
    }

    .holder-uploadfile-row .icon {
        padding: 10px;
        display: block;
        float: left;
        position: relative;
    }

    .holder-uploadfile-row .closeIcon {
        height: 20px;
        width: 20px;
        float: right;
        margin-top: 19px;
        display: none;
     }

     .holder-uploadfile li:hover .holder-uploadfile-row .closeIcon{
        display: inline-block;
     }

    .holder-uploadfile-row .icon .iconImage img,
    .holder-uploadfile-row .icon .iconImage > div{
        width: 35px;
        height:	45px;
        float: left;
        height: 45px;
    }

    .holder-uploadfile .fileInfo {
        width: calc(100% - 85px);
        float: left;
    }

    .holder-uploadfile .selectMsg{
        height: 100%;
        width: 100%;
        text-align: center;
        padding-top: 113px
    }

    .replaceFileSelectMsg {
        padding-top: 22px !important;
		padding-left:80px !important;
    }

    .holder-uploadfile .fileNameDiv {
        color: #333;
        padding-top: 21px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-right: 50px;
        width:100%;
        font-size: 16px;
    }

    .holder-replacefile-height {
        height: 60px;
    }

    .holder-uploadfile > ul {
        height:100%;
        overflow:hidden;
        display: block;
        /*padding-right:20px;*/
    }

    .holder-uploadfile-replace {
        overflow:hidden !important;
        padding-right:0px !important;
    }

    .holder-uploadfile > ul:hover {
        overflow-y:auto;
    }

    .holder-uploadfile > ul > li {
        width: 100%;
        height: 66px;
        border-bottom: 1px solid #e5e5e5;
    }

    .closeBtn {
        width: 8px;
        height: 8px;
        background-image: url(#{$resources-path}/assets/images/popup_collate_remove-item-X.png);
        cursor: pointer;
        position: relative;
        /* right: 33px; */
        top: 16px;
        margin-right: 10px;
        float: right;
        display: inline-block;
    }



    .ngDialog-properties-Width .ngdialog-content {
        width: 605px !important;
    }

    .holder-version-tracker .list-checkbox.checked {
        background: url("#{$resources-path}/assets/images/checkbox_on.png") no-repeat;
        height: 27px;
        width: 27px;
        float: right;
        margin-top: 30px;
        margin-right: 5px;
    }

    .discussionAttachment .icon .iconImage img {
        max-height: 38px;
        float: left;
        padding-left: 5px;
        padding-right: 5px;
    }

    .attachmentTreePostition {
        position: relative;
        left: -25px;
    }

    .attachmentTreePostition ul {
        display: block;
    }

    .uploadFileProgressBar {
        height: 100%;
        background-color: grey;
        border-radius: 0px !important;
    }

    .uploadFileProgressBar >div {
        height: 44px;
        padding-top: 11px;
    }

    .uploadFileProgressBar >div >span {
      font-size: 16px;
      font-weight: 400;
    }

    .viewerDialogClass {
        border: 1px solid #808080 !important;
        top: 1% !important;
        left: 1% !important;
        height: 97% !important;
        min-height: 490px;
    }

    &.folderViewerListingModalOpen .viewerDialogClass {z-index: 1051 !important; }     

    .viewerMobileDialogClass{
        border: 1px solid #808080 !important;
        top: 0 !important;
        left: 0 !important;
        height: 100% !important;
        width: 100% !important;
        min-height: 250px;
    }

    .viewerDialog.ui-dialog-content,
    .agendaDialog.ui-dialog-content {
        bottom: 0 !important;
        left: 0 !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        right: 0 !important;
        top: 0 !important;
        background: #dadada !important;
    }
    
    .bv-modal-blue-header {
            background-color: $grayDarkest !important;
            min-height: 50px;
            padding: 11px 12px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        
        .bv-modal-btn-close{
            right: 0px;
            position: absolute;
            top: 0px;
            cursor: pointer;
            background: $grayDarker;
            width: 49px;
            height: 50px;
            padding: 19px;
            border-top-right-radius: 5px;
        }
        .bv-modal-btn-close:hover{
            background: $grayDark;
        }

        .bv-modal-btn-close > svg{
            width: 11px;
            height: 11px;
            fill: #000;
        }

    /*.viewerPresentMode.ui-dialog-content {
        border-top: solid 10px #22a63e !important;
    }*/

    .folderViewer {
        width: 100%;
        /* height: 320px; */
        overflow: hidden;
        height: 560px;
        border: 1px solid #D1D1D1;
    }

    /*.folderViewer:hover {
        overflow-y: auto;
    }*/

    .repositoryLeft {
        float: left;
    }
    .documentsList .agenda-folder-modal{
        &.selectAll-checkBoxHide{
            & .top-panel{
                visibility: hidden;
            }
        }
    }
    
    .documentsList .agenda-folder-modal .top-panel{
        padding-left:19px;
        padding-top:15px;
        width: 100%;
        

        titl.add-icon::after{    
            display: inline-block;                 
        }
        titl.asc-rotate::after{            
            border-bottom: 6px solid #96979c;          
        }
        titl.desc-rotate::after{            
            border-top: 6px solid #96979c;   
            border-bottom: 0 !important;       
        }

        titl{
          display:inline-block;

           &:nth-child(1){
              margin-left: 0;
              width:62px;
           }
        
           &:nth-child(2){
              width: calc(100% - 516px);
              margin-left: 0;
             
           }

           &:nth-child(4){
               width: 320px;
               overflow:hidden;
               text-overflow: ellipsis;
               vertical-align: middle;
           }
        }
       
            & .selectAll-area{
                    float: right;
                    padding-right: 15px;
                    color: #0094b3;
                    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
                    font-size: 12px;
                    font-weight: bold;
                    cursor: pointer;
                    & .selectAllLbl-txt{
                        display: block;
                        cursor: pointer;
                    }
                    & .deSelectAllLbl-txt{
                        display: none;
                        cursor: pointer;
                    }

                    &.deSelectAllLbl{
                        & .selectAllLbl-txt{
                            display: none;
                        }
                        & .deSelectAllLbl-txt{
                            display: block;
                        }
                    }
                    &.selectAllLbl{
                        & .deSelectAllLbl-txt{
                            display: none;
                        }
                        & .selectAllLbl-txt{
                            display: block;
                        }
                    }
              
                 }
        
        
        div.filename-resizer{
           padding-left:0px;
           display: none;
        }
     }
     .documentsList.showModifiedConfig .agenda-folder-modal .top-panel titl:nth-child(4){
        visibility: hidden;
    }
    .documentsList.showModifiedConfig .fileNameDiv .fileNameVal .documentDetails{
        max-width: calc(100%);
    }
     &.bv-mobile .documentsList .agenda-folder-modal .top-panel titl:nth-child(2){
        @media screen and  (max-width: 1000px) {
            width: 44% !important;
          }
        @media screen and  (min-width: 1024px) {
            width: 46% !important;
          }
          @media screen and  (min-width: 2300px) {
            width: 46.5% !important;
          }       
    }
    &.bv-mobile{
        .documentsList{
            ul li{
                min-height: 40px;
            }
            .document-list-container{
               padding-top: 0;
            }
            .agenda-folder-modal{
                display: block !important;
                .top-panel{
                    display: block !important; 
                    titl:nth-child(1){
                        margin-right: 10px;
                        width: 43px !important; 
                    }
                    titl:nth-child(2){
                        width: calc(100% - 296px) !important;  
                    }
                    titl:nth-child(4){
                        width: 230px;
                    } 
                    .selectAll-area{
                        display: none;
                    }
                    @media (orientation: landscape){
                        titl:nth-child(1){
                            width: 43px !important;  
                        }
                        titl:nth-child(2){
                            width: calc(100% - 400px) !important;  
                        }
                        titl:nth-child(4){
                            width: 285px;
                        }
                    }
                }
            }
            .fileInfo{
                margin-left: 10px;
                min-height: 40px !important;
                width: calc(100% - 55px);
                .fileNameDiv{
                    padding: 10px 0 !important;
                    .fileNameVal .documentDetails{
                        max-width: calc(100% - 276px) !important;
                        padding-left: 12px;
    
                        @media (orientation: landscape){
                            max-width: calc(100% - 360px);
                        }
    
                       .portal-name-span{
                           padding-left: 12px;
                       }
                    }
                } 
                .fileDetailsDiv{
                    width: 213px;
                    @media (orientation: landscape){
                        width: 318px;
                    }
                    .virus-infected{
                        padding-top: 0 !important;
                    }
                }
            } 
            .show-portal-name .fileInfo .fileDetailsDiv{
                padding-top: 0;
            }
        }
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) { 
        &.bv-mobile{
           .documentsList{
              .agenda-folder-modal{
                 .top-panel{
                      titl:nth-child(2){
                         width: 44% !important;
                      }
                      titl:nth-child(4){
                          width: 25% !important;
                      }
                    }
                }
               .fileInfo{
                  .fileNameDiv{
                      .fileNameVal{
                         .documentDetails{
                              max-width: 44% !important;
                          }
                        }
                    }
                   .fileDetailsDiv{
                        width: 38% !important;
                    }
                }
            }
        }
    }
    .small.documentsList .agenda-folder-modal .top-panel{
        padding-left: 14px;
    }
    .large.documentsList .agenda-folder-modal .top-panel{
        padding-left: 20px;
    }
    .large.documentsList .agenda-folder-modal .top-panel titl:nth-child(1){
        width: 67px;
    }
    .document-placeholder-highlight {
        height: 35px;
        background-color: #CCCCCC;
    }

    /* iPhone Portrait */
    @media screen and (max-device-width: 480px) and (orientation:portrait) {
        .documentsList .fileSizeVal, .documentsList .fileDate, .documentsList .fileDateVal,
            .documentsList .fileUploadedBy, .documentsList .fileUploadedByVal,
            .documentsList .deliver {
            font-size: 10px;
            font-weight: lighter;
            font-weight: 100;
            letter-spacing: 0.3px;
        }
    }

    .dragOverBackground {
        background: #E8E8E8;
    }

    .showDocumentViewer {
        display: block;
    }

    .hideDocumentViewer {
        display: none;
    }

    .collate-centered-div {
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        margin-bottom: 10px;
    }

    .collate-save-folder {
        line-height: 33px;
        margin-right: 0.5em;
    }

    .collate-title-input {
        width: 100%;
    }

    .collate-list-heading {
        margin-top: 5px;
    }

    .collate-seperator-label {
        padding-left: 30px;
    }

    .collate-seperator-label a {
        color: #2273a5;
    }

    .collate-row {
        padding-bottom: 35px;
        width: 65%;
    }
     .full-width-bbk {
      width: 100% !important;
     }
     .width-65 {
         width: 65% !important;
     }
     .p-l-0 {
        padding-left: 0;
       }
    .collate-row .drag-handle {
        top: 9px !important;
    }

    .collate-row:hover .drag-handle-image{
        display: block;
    }

    .collate-box img {
        width: 26px;
        /*border: 1px solid #d1d1d1;*/
        margin-left: auto;
        margin-right: auto;
    }

    .collate-box.collate-seperator-box {
        width: calc(26% - 13px);
        text-align: left;
        /*padding-left: 8px;*/
        margin-right: 15px;
    }
    
/*    .collate-box.collate-seperator-box .pointerDisabled {
    	pointer-events: none;
  		cursor: default;
    }
    
    .collate-box.collate-document-box .ncTextColor {
        color: red;
    }
*/
    .collate-box.collate-document-box .fileNameVal {
        max-width: calc(100% - 50px);
        display: inline-block;
    }

    .collate-box.collate-document-box .fileNameVal:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    .collate-box.collate-document-box {
        width: calc(74% - 31px);
        text-align: left;
        margin-left: 15px;
    }
    .addborder {
        border-left: 1px solid #e5e5e5;
        height: 100%;
    }
    .collate-incompatible-list{
        width:35%;
        position: absolute;
        right: 0;
        top: 0;
        padding:0 10px;
        & .m-b-20 {
            margin-bottom: 20px;
        }
            & .err-doclist {
                display: block;
                clear: both;
                & li {
                    list-style-type: none;
                    border-bottom: 1px solid #CECFD2;
                    padding: 15px;
                    clear: both;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 21px;
                    letter-spacing: 0.3325px;
                    color: #424242;
                }
                  & .file-icon {
                    float: left;
                    width: 19px;
                    height: 29px;
                    margin-right:5%;
                    & svg {
                        height: 29px;
                    }
                }
                & .file-name{
                    width: 66%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: inline-block;
                }
                & .file-size{
                    float: right;
                }
               & #removedocs {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 21px;
                    letter-spacing: 0.3325px;
                    text-align: center;
                    padding: 10px 0;
                    color:#0094BD;
                    display: block;
                    cursor: pointer;
                }  
            }
      
        & .warn-border {
            border: 1px solid #D9BB4F;
        }
        & .err-border {
            border: 1px solid #CB2A32; 
        }
        & .error-msg{
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            font-style: normal;
            color: #1B1B1B;
            float: left;
            padding: 10px;
            & .review {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                font-style: normal;
                color: #29A3C5; 
                cursor: pointer;
                float: right;
                letter-spacing: 0.3325px;
                width: 114px;
                position: relative;
             & .arrow {
                border: solid #29A3C5;
                border-width: 0 3px 3px 0;
                display: inline-block;
                padding: 3px;
                position:absolute;
                top: 3px;
                right: 3px;
              }
              & .up {
                transform: rotate(-135deg);
                -webkit-transform: rotate(-135deg);
                top: 7px;
              }
              
             & .down {
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
              }
            }
            & .err-text {
                float: left;
                width: calc(100% - 139px);
                padding: 0 5px;
                font-family:"SourceSansProRegular";
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
            }
            & .err-larger-text {
                display: block;
                clear: both;
                padding-top: 5px;
            }
        }
       & .m-t-20 {
            margin-top: 20px;
        }
        & .error {
            background: #FFE8E4;
            border: 1px solid #CB2A32;
            & .icon {
                color: #CB2A32;
                float: left;
                width: 15px;
            }
           
        }
        & .warn {
            background: #FFFAE4;
            border: 1px solid #D9BB4F;
            & .icon {
                color: #D0A400;
                float: left;
                width: 15px;
            }
        }
    }
    .width-full {
        width:100%;
        padding-left:0;
    }
    .p-l-16 {
        padding-left:16%;
    }
    #saveBtn:disabled, #saveBtn:disabled:hover, #saveBtn:disabled:focus {
        color: #ffffff;
        background-color: #b6b7ba;
        border-color: #b6b7ba;
        box-shadow: none;
        cursor: not-allowed;
    }
    .collate-box {
        /*background:#CCC;*/
        width: 10%;
        float: left;
        text-align: center;
        /*border: 1px solid #D1D1D1;*/
        height: 35px;
        line-height: 35px;
        position: relative;
    }

    .collate-remove-itm-btn {
        width: 17px;
        height: 17px;
        position: absolute;
        cursor: pointer;
        /* margin-top: 22px; */
        display: inline;
        right: 20px;
        top: 10px;
    }
    .collate-remove-itm-btn-hidden {
        width: 17px;
        height: 17px;
        position: absolute;
        cursor: pointer;
        /* margin-top: 22px; */
        display: inline;
        right: 20px;
        top: 10px;
        fill:black;
        visibility:hidden;
    }
    .collateRowHover:hover .collate-remove-itm-btn-hidden
    {
        fill:#CCCCCC;
        visibility:visible;
    }
    .collate-remove-itm-btn-hidden:hover
    {
        fill: black !important;
        visibility:visible;
    }
    .collate-document-box svg:hover #removeButtonsvg{
        fill:black;
        visibility:visible;
    }

    .collate-plusIcon-itm-btn {
        width: 21px;
        height: 21px;
        position: absolute;
        cursor: pointer;
        /* margin-top: 22px; */
        display: inline;
        right: 20px;
        top: 5px;
        fill:#2574A7;
    }
    .collate-folderIcon-itm-btn {
        width: 20px;
        height: 20px;
        cursor: pointer;
        /* margin-top: 22px; */
        display: inline;
        color: #2574A7;
        position:relative;
        top:6px;
        fill:#2574A7;

    }

    .collate-check-div {
        top: 50%;
        float: left;
        position: absolute;
        margin-top: -10px;
    }

    .collate-notice {
        position: absolute;
        bottom: 20px;
        left: 20px;
    }

    .as-sortable-item, .as-sortable-placeholder {

    }

    .rulesDeleteUserBtn {
        color: #000;
        min-width: 19px;
        max-width: 19px;
        background-color: rgb(190, 185, 185);
        border-style: none;
        max-height: 19px;
        min-height: 19px;
        border-radius: 50%;
        float: left;
        background-image:
            url("#{$resources-path}/assets/images/BVLiveryBriefcaseDownloadCancel.png");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 12px auto, 12px auto;
        margin-top: 5px;
        margin-left: 4px;
        padding-top: 3px;
        white-space: normal;
        width: 23px;
        height: 25px;
        vertical-align: -webkit-baseline-middle;
    }

    .bv-attachment-body {
        width: 100%;
        padding: 10px;
        height: 500px;
        overflow: hidden;
    }

    .bv-atchmnt-tree {
        top: 17px;
        border-right: 1px solid #e5e5e5;
        height: 460px;
    }

    .bv-atchmnt-filter {
        float: right;
        width: 77%;
    }

    .atchmnt-list-height {
        height: 390px;
        width: 80%;
    }

    .attchmnt-checkbox-margin {
        margin-top: 11px;
    }

    .bv-copy-link-confirm .modal-dialog {
        width: 50%;
    }

    .bv-copy-link-confirm .bv-confirm-content {
        word-wrap: break-word;
        font-style: oblique;
        color: blue;
        cursor: pointer;
        width: 100%;
        padding: 10px 10px;
    }

    .copy-link-btn {
        position: absolute;
        bottom: 20px;
        right: 20px;
    }

    .copy-link-status {
        position: absolute;
        bottom: 20px;
        left: 20px;
    }

    .bv-file-size-confirm .modal-dialog {
        width: 400px !important;
    }

    .bv-file-size-confirm .bv-confirm-content {
        text-align: center;
        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .bv-files-not-uploaded .modal-dialog {
        width: 400px;
    }

    .bv-files-not-uploaded .bv-confirm-content {
        text-align: left;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .bv-modal-window-repository-upload-file {
        .modal-dialog {
            width: 850px;
            height: 400px;
            position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important;
        }

        .bv-upload-file-list {
            width: 100%;
            border-bottom: 1px solid #DFDFDF;
        }

        .view-permissions-button {
            width: 140px;
            float: left;
            padding-top: 21px;
        }

        .bv-textFitButton {
            cursor: pointer;
            border-color: #35abc3;
            border-radius: 5px;
            border-width: 2px;
            border-style: solid;
            padding: 5px;
            color: #35abc3;
            font-weight: 700;
            white-space: nowrap;
        }

        .closeIcon {
            padding-top: 7px;
        }

        .holder-uploadfile .fileInfo {
            width: calc(100% - 240px);
        }

        .holder-uploadfile-row.selected-file {
            background-color: #dff6fb;
        }  

        .fixed-toaster-container {

            height: 65px;
            background: #ffffff;
            padding: 10px 10px 10px 50px;
            border-left: 7px solid #fecb00 !important;
            border-radius: 5px;
            border: 1px solid lightgray;
            width: calc(100% - 36px);
            margin: 18px;
            display: inline-flex;
            margin-bottom: 10px;

            &::before {
                content: '⚠';
                font-family: SSSymbolicons;
                color: #fecb00;
                position: relative;
                top: 0px;
                left: -30px;
            }
        }  

        .fixed-toaster-message {


        }

        .bv-upload-file-permissions {
            display: block;
            width: 50%;
            position: absolute;
            left: 50%;
            height: calc(100% - 56px);
            border-left: 1px solid #DFDFDF;

            .bv-dialog-title-box {
                margin-bottom: 20px;
                font-weight: 700;
                padding: 10px;
                height: 28px;
            }

            .bv-dialog-search-box{
                border-top: 1px solid #DFDFDF;
                padding-top: 8px;
                padding-bottom: 2px;
            }

            .itemListFilterInput {
                background: #ffffff;
                height: 34px;
            }
            
        }

        .bv-table-title-col-1, .bv-table-col-1 {
            width: 34px !important;
            //left: 0px !important;
        }

        .bv-pemission-overlay-list {
            height: calc(100% - 86px);

            .bv-table-css >div {
                height: 100%;
            }
        }

        .deleteIconSvg {
            width: 11px !important;
            height: 11px !important;
            margin-top: 2px;
        }

        .btn .caret{
            top: 4px !important;
        }

        .bv-table-title-col-2, .bv-table-col-2 {
            width: 235px !important;
            left: 35px !important;
        }

        .bv-table-title-col-3, .bv-table-col-3 {
            width: 110px !important;
            left: 270px !important;

            &.search-user {
                left: 295px !important;
            }
        }

        .bv-table-title-col-4, .bv-table-col-4 {
            width: 18px !important;
            left: 376px !important;
        }

        .bv-dialog-close-btn {
            width: 14px;
            float: right;
            cursor: pointer;

            .icon {
                visibility: visible;
            }
        }
    }
    
    

    .bv-modal-window-repository-replace-file .modal-dialog {
        width: 450px;
        height: 185px;
        position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important;
    }

    .document-list-container {
        height: calc(100% - 43px);
        overflow-y: auto;
    }

    /*.document-list-container .mCSB_container {
        height: 100%;
    }*/

    .document-list-container-filter {
        /*height: calc(100% - 58px);
        overflow: hidden;*/
        height: calc(100% - 38px);
        width: 100%;
        /* overflow: hidden; */
        /*width: 100%;*/
        /*padding-right: 10px;*/
    }

    .document-list-container:hover {
        /* overflow-y: auto; */
    }

    .vruler-document-list {
        width: 0px;
        height: 100%;
        /* background-color: #e5e5e5;  */
        /*margin: 0px;   */
        margin-top:0px;
        position: relative;
        cursor: col-resize;
        padding: 0px 5px;
        margin: 0px -5px;
        z-index: 1;
    }
    .vruler-document-list::after{
        content: "";
        height: 100%;
        width: 1px;
        display: block;
        background-color: $grayLight;
    }

    .vruler-document-list-filter{
      margin-top: 23px;
      height: calc(100% - 23px);
    }

    .document-filter-container {
        padding-left: 20px;
        margin-bottom: 20px;
    }

    .attachment-list {
        height: 459px;
    }

    .bv-modal-window-new-attachment .modal-dialog {
        min-width: 750px;
        width: 750px;
    /* 	position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50.1%,-50.1%) !important;
        -webkit-transform: translate(-50.1%,-50.1%) !important;
        -ms-transform:  translate(-50.1%,-50.1%) !important;
        margin: 0; */
    }


    .bv-dialog-selected-attachments-list {
        right: -300px;
        height: 100%;
        position: absolute;
        top: 0px;
        z-index: -1;
        width: 300px;
        background: #fff;
        overflow: hidden;
        font-size: 14px;
    }

    .bv-dialog-selected-attachments-list ul {
        height: calc(100% - 30px);
        margin: 15px 0px 0 0;
        /* padding-bottom: 15px; */
        width: 300px;

    }
    .bv-dialog-selected-attachments-list .mCSB_scrollTools_vertical {
        right: -1px;
    }

    .selected-item {
        height: 35px;
        line-height: 35px;
        padding-left: 15px;
    }

    .selected-item span{
        display: inline-block;
        width: calc(100% - 35px);
    }

    .selected-item .btn-remove {
        padding-top: 0px;
        display: none;
        position: absolute;
        right: 6px;
        cursor: pointer;
    }

    .selected-item:hover .btn-remove {
        display: inline-block;
        opacity: .5;
    }
    .btn-remove:hover {
        display: inline-block;
        opacity: 1 !important;
    }
    .bv-modal-window-properties .modal-dialog {
        min-width: 600px;
        width: 600px;
        margin-top: 110px;
    }

    .searchDocument
    {
      height: 30px;
      padding: 0px 10px 0px 10px;
      border: 1px solid #EEE;
      width: 100%;
      background: #EEE;
      font-size: 16px;
      display: block;
    }

    .folder-attachment-dialog .modal-dialog
    {
      height: 470px;
      min-width: 500px;
      width: 500px;
      font-size: 14px;
    }

    .folder-attachment-container
    {
      height: 370px;
      overflow: hidden;
      margin: 15px 20px 29px 10px;
    }

    .folder-attachment-container:hover {
        overflow-y: auto;
    }

    .folder-attachment-treeNode-name-div {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 350px;
        float:left;
        padding-bottom: 7px;
        position: relative;
        margin-top: 15px;
    }
    .folder-attachment-dialog .treeNode .treeImage img{
        max-width: 37px;
    }

    .folder-attachment-radio
    {
        width: 22px;
        height: 22px;
        position: relative;
        float: right;
        margin-top: 18px;
        display: inline-block;
    }

    .collate-files {
        border-top: 1px solid #e5e5e5;
    }

    .collateMain {
        padding: 20px;
        font-size: 16px;
        overflow: hidden;
    }

    .collate-title-box {
        white-space: nowrap;
        margin-bottom: 15px;
    }

    .collate-title {
        display: inline-block;
        margin-right: 40px;
    }

    .collate-saveTo {
        display: inline-block;
        width: calc(50% - 87px);
    }

    .collate-actions {
        position: absolute;
        bottom: 15px;
        right: 20px;
    }

    .collate-list-heading .collate-seperator-box {
        border-bottom: 1px solid #e5e5e5;
        line-height: 30px;
    }

    .collate-list-heading .collate-document-box {
        border-bottom: 1px solid #e5e5e5;
        line-height: 30px;

        titl{
            cursor: pointer;

            &:after {
                content: "";
                margin-left: 4px;
                position: relative;
                top: -1px;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
                display: none;
            }

            &.inactive:after {
                display: none;
            }

            &.reverse:after {
                border-top: none;
                border-bottom: 6px solid #96979c;
            }
        }
    }


    .collate-files-list{
        overflow-x: hidden;
        overflow-y: hidden;
        height: calc(100% - 205px);
        position: absolute;
        width: calc(100% - 40px);
        margin-top: 10px;
    }
    
    .collate-files-list:hover {
        overflow-y: auto;
    }

    .collate-vertical-hr {
        height: calc(100% - 165px);
        width: 1px;
        position: absolute;
        left: calc(26% + 25px);
        background-color: #e5e5e5;
        margin-top: 10px;
    }
    .file-chooser-padding
    {
        padding: 15px 15px 7px 0px;
    }


    /************Start of styles for Document Properties Popup ***********/
    .bv-doc-prop .modal-dialog { width:970px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important; }
    .bv-doc-prop-container-div { width:100%; height: 569px; font-weight: normal; color:#000000; margin: 0px; }
    .bv-doc-prop-col1 { height:100%; width:230px; float:left;  font-weight: 500;}
    .bv-doc-prop-col2 { height:100%; width:740px; float:right; display: inline-block; position: absolute; }
    .bv-doc-prop-col2 > div { padding:20px;}
    .bv-doc-prop-row { height:55px;line-height:55px;width:100%; font-size:20px; font-weight:400; cursor:pointer; display:block; text-align:left; margin-top:7px; margin-bottom:13px; padding: 0px 0px 0px 20px; }
    .bv-doc-prop-row-selected { background-color: #2273a5; color:#FFFFFF;}
    .bv-doc-prop-row-not-selected { color: #2273a5;}
    .bv-doc-prop-details-row1 { width:100%; height: 20px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap;}
    .bv-doc-prop-details-row2 { width:100%; height: 150px; }
    .bv-doc-prop-details-row2 > div { width:50%; height:145px; padding: 20px 0px 20px 175px;display: inline-block; }
    .bv-doc-prop-details-img { width: 90px; height: 115px; box-shadow: 0 0 10px 0 #888; border: 1px solid #d1d1d1;}
    .bv-doc-prop-details-row3 { width:100%; height: calc(100% - 175px);}
    .bv-doc-prop-details-row3 > ul > li { display:block; width:100%; margin-bottom:7px;line-height: 25px;height: 25px;}
    .bv-doc-prop-details-row3-col1 {min-height: 25px;width:24%;float:left;display:inline-block;text-align: right;font-weight: 500;clear: left;}
    /* .bv-doc-prop-details-row3-col2 { height: 25px; width:76%; float:right; display:inline-block;text-align: left; padding:1px 0px 0px 10px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap;} */

    .bv-doc-prop-details-row3-col2{
        float: right;
        padding: 1px 0 0 10px;
        white-space: normal;
        word-wrap: break-word;
        width:76%;
        text-align: left;
        }

    .bv-doc-prop-perm-header { line-height:40px; height:40px; width:100%; }
    .bv-doc-prop-perm-details { line-height:490px; height:490px; width:100%; }
    .bv-doc-prop-perm-divider { height: 30px; line-height: 30px;width:1px; background: #E5E5E5; display: inline-block;}

    .bv-doc-prop-perm-value-li { display:block; height:30px;}
    .bv-doc-prop-perm-value-li-ul { }

    .bv-doc-prop-perm-header-type-li-div {width: 62px;border-bottom:1px solid #ccc;line-height:20px;height:27px;font-weight: 500;}
    .bv-doc-prop-perm-header-name-li-div {width: 480px;border-bottom:1px solid #ccc;line-height:20px;height:27px;font-weight: 500;}
    .bv-doc-prop-perm-header-perm-li-div {width:130px; border-bottom:1px solid #ccc;line-height:20px;height:27px; font-weight: 500;}

    .bv-doc-prop-perm-header > ul  { display:block; position:absolute; width:700px;}
    .bv-doc-prop-perm-details .scroll-list-items { display:block; position:absolute; width:700px; height:490px; overflow:hidden;}
    /*.bv-doc-prop-perm-details > ul:hover {overflow-y:auto;}
    */

    .bv-doc-prop-perm-li-type {line-height:30px;height:30px;width: 65px;display: block;position: absolute;}
    .bv-doc-prop-perm-li-type-icon {line-height:30px;height:30px;width: 45px;display: block;position: absolute;margin-left: 5px;margin-top: 5px;}
    .bv-doc-prop-perm-li-name {line-height:30px;height:30px;width: 480px;display: block;position: absolute;left: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;}
    .bv-doc-prop-perm-li-perm {line-height:30px;height:30px;width:120px;display: block;position: absolute;left: 585px;}

.bv-doc-prop-perm-value-li-ul{
	& .bv-doc-prop-perm-li-name{
	font-size:14px;
}
& .bv-doc-prop-perm-li-perm{
	font-size:14px;
}
	}

    .bv-doc-prop-per-li-type-right-border {
        height: calc(100% - 42px);
        width: 1px;
        position: absolute;
        left: 87px;
        background-color: #e5e5e5;
        margin-top: 0px;
    }

    .bv-doc-prop-per-li-name-right-border {
        height: calc(100% - 42px);
        width: 1px;
        position: absolute;
        left: 592px;
        background-color: #e5e5e5;
        margin-top: 0px;
    }

    .bv-doc-prop-perm-li-type-filler { line-height:10px; height:10px; width:90px;  display: block; position: absolute; top: 30px;}
    .bv-doc-prop-perm-li-name-filler { line-height:10px; height:10px; width:450px; display: block; position: absolute; top: 30px; left:120px;}
    .bv-doc-prop-perm-li-perm-filler { line-height:10px; height:10px; width:110px; display: block; position: absolute; top: 30px; left:580px;}

    .bv-doc-prop-perm-fav-panel0 { line-height:30px; height:30px; width:100%; margin-left: 5px;}
    .bv-doc-prop-perm-fav-panel0 > ul  { display:block; position:absolute; width:700px;}
    .bv-doc-prop-perm-fav-panel0 > ul > li { font-weight:500; line-height:25px; height:30px; width: 100%; display: block; position: absolute; border-bottom:1px solid #E5E5E5;}

    .bv-doc-prop-perm-fav-panel1 { height:470px;overflow:hidden;}
    .bv-doc-prop-perm-fav-panel2 { height:30px;overflow:hidden;}

    .bv-doc-prop-perm-fav-panel1 .scroll-list-items { display:block; position:absolute; width:700px; height:440px; margin-top:10px;margin-bottom:20px;overflow:hidden;}
    /*.bv-doc-prop-perm-fav-panel1 .scroll-list-items:hover {overflow-y:auto;}*/
    .bv-doc-prop-perm-fav-panel1 .scroll-list-items > li { display:block; height:40px;}
    .bv-doc-prop-perm-fav-panel1 .scroll-list-items > li:hover { background: #f4f4f4; }

    .bv-doc-prop-perm-fav-panel1-name { display:inline;float:left;font-weight:400; line-height:40px; width:calc(100% - 45px); text-overflow:ellipsis; overflow:hidden; white-space:nowrap; margin-left: 5px;}
    .bv-doc-prop-perm-fav-panel1-close-icon { display:inline;float:right;width:20px;height:20px;margin-top:7px;margin-right:0px;}
    .bv-doc-prop-perm-fav-panel1-close-icon > span {}
    .bv-doc-prop-perm-fav-panel1-close-icon > span > img { width:17px;height:17px; cursor:pointer;}

    /************End of styles for Document Properties Popup ************/

    /************Start of styles for Document Properties Popup ***********/
    .bv-rules-dialog .modal-dialog {height: 156px; min-width: 70px;width:340px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important; }
    .bv-rules-container-div   { width:100%; height:120px; font-weight: normal; color:#000000; margin: 0px; }
    .bv-rules-row1 {width:100%;height:calc(100% - 70px);padding: 20px 20px 0px 62px;}
    .bv-rules-chk-div { height:30px; width:30px; display:inline-block;}
    .bv-rules-delete-opt-div { display:inline-block; height:30px; top:19px; position:absolute;}
    .bv-rules-row2 {width:100%; height:70px;}
    .bv-rules-row2-button-div { margin: 20px 20px 20px 58px;}
    .bv-rules-text {   width:48px; margin-right:7px; margin-left:7px;}
    /************End of styles for Document Properties Popup ************/

    /************Start of styles for Document Properties Popup ***********/

    /************End of styles for Document Properties Popup ************/

    .bv-version-tracker-dialog .modal-dialog { min-width: 870px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important; }
    .bv-version-tracker-container-div   { width:100%; height:499px; font-weight: normal; color:#000000; margin: 0px; }
    .bv-version-tracker-row1 {width:100%;height:calc(100% - 70px);padding: 20px 20px 0px 20px;}
    .bv-version-tracker-row2 {width:100%;}
    .bv-version-tracker-row2-btn-div { height:100%; float:right; margin:20px 20px 20px 0px;}

    .bv-version-tracker-header { line-height:35px; height:35px; width:100%; }
    .bv-version-tracker-details { line-height:375px; height:375px; width:100%; }

    .bv-version-tracker-header > ul  { display:block; position:absolute; width:830px; font-weight:500;}
    .bv-version-tracker-details > ul { display:block; position:absolute; width:830px; height:370px; overflow:hidden;}
    .bv-version-tracker-details > ul:hover {overflow-y:auto;}


    .bv-version-tracker-li-version    { line-height:30px; height:30px; display: block; position: absolute; width:80px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap;cursor:pointer;}
    .bv-version-tracker-li-dtModified { line-height:30px; height:30px; display: block; position: absolute; width:215px; left:85px;  text-overflow:ellipsis; overflow:hidden; white-space:nowrap;cursor:pointer;}
    .bv-version-tracker-li-modifiedBy { line-height:30px; height:30px; display: block; position: absolute; width:195px; left:325px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap;cursor:pointer;}
    .bv-version-tracker-li-comments   { line-height:30px; height:30px; display: block; position: absolute; width:250px; left:540px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap;cursor:pointer;}
    .bv-version-tracker-li-selection  {
        line-height: 30px;
        height: 30px;
        display: inline-block;
        position: relative;
        width: 23px;
        float: right;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .bv-version-tracker-li-selection-div { margin-top:6px; height:25px; width:25px;}

    .bv-version-tracker-value-li { display:block; height:41px; padding-top:4px; border-bottom:1px solid #E5E5E5;}
    .bv-version-tracker-value-li-ul { }

    /*.bv-doc-prop-perm-value-li { display:block; height:30px;}
    .bv-doc-prop-perm-value-li-ul { }
    .bv-doc-prop-perm-header-type-li-div {width:50px; border-bottom:1px solid #ccc;line-height:27px;height:27px; font-weight: 500;     font-size: 14px;}
    .bv-doc-prop-perm-header-name-li-div {width:435px; border-bottom:1px solid #ccc;line-height:27px;height:27px;font-weight: 500;}
    .bv-doc-prop-perm-header-perm-li-div {width:90px; border-bottom:1px solid #ccc;line-height:27px;height:27px; font-weight: 500;}*/



    /*
    .holder-version-tracker li {
        background: rgba(204, 204, 204, 0.25);
    }

    .holder-version-tracker ul {
        width: 100%;
    }
    .holder-version-tracker .list-checkbox {
        background: url("../assets/images/checkbox.png") no-repeat;
        height: 27px;
        width: 27px;
        float: right;
        margin-top: 30px;
        margin-right: 5px;
    }
    .holder-version-tracker {
        max-height: 270px;
        overflow: auto;
        margin: 21px 12px 10px 8px;
    }
    .version-file-name {
        display: block;
    }

    .version-file-size {
        font-size: 13px;
    }
    */

    .bv-audit-trail-dialog .modal-dialog { min-width: 618px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important; }
    .bv-audit-trail-container-div   { width:100%; height:445px; font-weight: normal; color:#000000; margin: 0px; }
    .bv-audit-trail-row1 {width:100%;height:100%;padding: 20px 20px 20px 20px;}

    .bv-audit-trail-header { line-height:35px; height:35px; width:100%; }
    .bv-audit-trail-details { line-height:370px; height:370px; width:100%; }

    .bv-audit-trail-header > ul  { display:block; position:absolute; width:578px; font-weight:500;}
    .bv-audit-trail-details > ul { display:block; position:absolute; width:578px; height:370px; overflow:hidden;}
    .bv-audit-trail-details > ul:hover {overflow-y:auto;}

    .bv-audit-trail-li-user    { line-height:30px; height:30px; display: block; position: absolute; width:202px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap; padding-right: 10px;}
    .bv-audit-trail-li-action { line-height:30px; height:30px; display: block; position: absolute; width:192px; left:202px;  text-overflow:ellipsis; overflow:hidden; white-space:nowrap;}
    .bv-audit-trail-li-date { line-height:30px; height:30px; display: block; position: absolute; width:182px; left:394px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap;}  
    .bv-audit-trail-value-li { display:block; height:41px; padding-top:4px; border-bottom:1px solid #ccc;}

    .bv-addToUserFavorites-dialog .modal-dialog { min-width: 680px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important; }
    .bv-addToUserFavorites-container-div   { width:100%; height:445px; font-weight: normal; color:#000000; margin: 0px; }
    .bv-addToUserFavorites-row1 {width:100%;height:100%;padding: 0 20px 10px 20px;}
    .bv-addToUserFavoritesDiv1 { float:left; display:inline-block;height:100%; width:60%; padding: 0px 10px 0px 0px; border-right:1px solid #E5E5E5;}
    .bv-addToUserFavoritesDiv2 { float:right; display:inline-block;height:100%; width:40%; padding: 0px 0px 0px 10px; border-right:1px gray;}
    .bv-addToUserFavoritesDiv-upSection { width:100%; height: 45px; font-weight: 500; color:#000000; margin: 0px; border-bottom:1px solid #E5E5E5; padding: 20px 0px 0px 0px;}
    .bv-addToUserFavoritesDiv-downSection { width:100%; height: calc(100% - 45px); color:#000000; margin: 0px; padding: 10px 0px 0px 0px; overflow-x: hidden; overflow-y: hidden;}
    .bv-addToUserFavoritesDiv-downSection:hover {overflow-y:auto; }
    .bv-addToUserFavorites-user {position: relative; height: 30px; margin-bottom: 5px;}
    .bv-addToUserFavorites-user:hover .favoritesDeleteIcon{opacity:0.2;visibility:visible;}
    .bv-addToUserFavorites-attachment {position: relative;height: 60px;margin-bottom: 20px;}
    .bv-addToUserFavorites-attachment:hover .drag-handle-image{display: block;}
    .bv-addToUserFavorites-attachment:hover .favoritesDeleteIcon{opacity:0.2;visibility:visible}
    .bv-briefcase-properties-users:hover .biefcase-properties-delete-icon
    {
        opacity:0.5;
        visibility:visible;
    }
    .bv-briefcase-properties-users{
        height: 40px;
    }

    .biefcase-properties-delete-icon
    {
        opacity:1;
        visibility:hidden;
    }
    .biefcase-properties-delete-icon:hover
    {
        visibility:visible !important;
        opacity:1 !important;
    }


    .favoritesDeleteIcon
    {
        visibility:hidden;
        opacity:1;
    }
    .favoritesDeleteIcon:hover
    {
        visibility:visible !important;
        opacity:1 !important;
    }



    .bv-item-list-dialog-mobile .bv-item-list-main{
        height: 100%;
    }
    .bv-item-list-dialog-mobile .bv-item-list-grid{
        height: calc(100% - 68px);
        position: relative;
    }
    .bv-item-list-dialog-mobile .bv-item-list-ul1{
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }
    .bv-item-list-dialog-mobile .bv-item-list-ul2 svg{
        width: 22px;
        height: 22px;
    }

    .treeNodeDiv
    {
        height:46px;
    }

    .bv-follow-followme .modal-dialog{
        width:720px;
        position: absolute;
        margin: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) !important;
        -webkit-transform: translate(-50%,-50%) !important;
        -ms-transform:  translate(-50%,-50%) !important;
    }

    .bv-follow-followme .iconImage,
    .bv-follow-followme .iconImage svg{
        cursor: pointer;
        height: 26px;
        width: 20px;
    }

    .bv-follow-followme-container
    {
        width:100%;
         overflow: auto;
    }

    .bv-follow-followme-notification-bold-text
    {
        display: inline-block;
        padding-left: 10px;
        font-size: 11.5px;
        margin-top: 8px;
        font-weight: bold;
    }

    .bv-follow-followme-notification-text
    {
        display: inline-block;
        padding-left: 10px;
        font-size: 11.5px;
        margin-top: 5px;
    }

    .bv-follow-followme-notification-section
    {
        width: 100%;
        border-bottom: 1px solid #eaeaeb;
        padding: 5px 8px 5px 5px;
        height: 40px;
    }

    .bv-follow-followme-notification-section-bold
    {
        width: 100%;
        border-bottom: 1px solid #eaeaeb;
        padding: 5px 8px 5px 5px;
        padding-left: 16px;
        height: 30px;
    }

    .bv-follow-followme-notification-section-1
    {
        float: left;
    }

    .bv-follow-followme-notification-section-2
    {
        float: right;
        margin-right: 10px;
    }
    
    .bv-follow-followme-notification-section-3
    {
        float: right;
        margin-right: 10px;
    }

    .bv-follow-followme-type
    {
        float: left;
        width: calc(100% - 424px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }


    .bv-follow-followme-item-name
    {
        display: inline-block;
        cursor: pointer;
        padding-left: 18px;
        font-size: 13.5px;
        color: #0094b3;
        font-weight: 300;
        margin-top: 14px;
    }

    .bv-follow-followme-fileInfo
    {
        width: calc(100% - 190px);
        color: #000;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        float: left;
        margin-left: 25px;
        padding-top: 3px;
    }
    .bv-follow-followme-fileNameDiv {
        height: 100%;
        display: inline-block;
        width: 100%;
        max-width: calc(100% - 265px);
        float: left;
        white-space: normal;
    }

    .bv-follow-followme-Div {
        height: 100%;
        display: inline-block;
        width: calc(100% - 308px);
        padding-left: 25px;
        float: left;
    }

    .bv-follow-followme-textfit{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;

    }

    .bv-follow-followme-svg-size {
        width: 20px;
        margin-left: 10px;
        float: left;
    }

    .bv-follow-followme-li-div{
        height: 45px;
        padding:10px;
        border-bottom: 1px solid #eaeaeb;
    }

    .bv-follow-followme-text-font
    {
        display: inline-block;
        font-size: 12.5px;
        color: #0094b3;
        font-weight: 300;
        white-space: normal;
    }

    .bv-follow-followme-text-font-other {
        font-size: 12.5px;
        display: inline-block;
        font-weight: 300;
    }

    .itemList .icon .iconImage > div {
        color: $yellowLight;
    }

    .bv-follow-followme-button-position
    {
        float: right;
        // margin-right: 30px;
    }

    .bv-follow-followme-user-circle
    {
        text-align: center;
        width: 30px;
        height: 30px;
        overflow: hidden;
        border-radius: 50%;
        box-shadow: rgb(204, 204, 204) 0px 0px 6px 1px;
        background: rgb(81, 81, 81);
        display: inline-block;
        margin: 0 12px 0 0;
        float: left;
    }

    .bv-follow-followme-user-image
    {
        text-align: center;
        float: left;
        width: 30px;
        height: 30px;
        position: relative;
        display: block;
        background: none;
        z-index: 1;
        border-radius: 50%;
    }

    .bv-follow-followme-user-letter
    {
        position: absolute;
        width: 30px;
        margin-top: 8px;
        color: white;
        font-weight: lighter;
        font-size: 12px;
        letter-spacing: 1px;
        display: block;
    }

    .bv-follow-followme-user-follow-info
    {
        width: calc(100% - 210px);
        color: #000;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        float: left;
        margin-left: 7px;
        padding-top: 3px;
    }

    .bv-follow-followme-user-follow-NameDiv
    {
        height: 100%;
        display: inline-block;
        width: 100%;
        max-width: calc(100% - 100px);
        float: left;
        white-space: normal;
    }

    .follow-followme-btn:hover,
    .follow-followme-btn:active,
    .follow-followme-btn:focus {
      background: #ffffff;
      border-color: #00b2d7;
      color: #00b2d7;
    }
    .follow-followme-btn.active {
      background: #0094b3;
      border-color: #0094b3;
      color: #ffffff;
    }

    .follow-followme-btn {
      background: #ffffff;
      border: 1px solid #0094b3;
      color: #0094b3;
      display: inline-block;
      margin-bottom: 0;
      padding: 7px 11px 6px;
      border-radius: 3px;
      cursor: pointer;
      font-size: 0.75rem;
      font-weight: bold;
      line-height: 0.5em;
      text-align: center;
      vertical-align: middle;
      -webkit-font-smoothing: antialiased;
      transition: background-color 0.2s linear 0.1s;
    }

    .follow-chk-toggle + label
    {
      position: relative;
    }

    .follow-chk-toggle:checked + label:before
    {
      margin-right: 0.5em;
    }

    .follow-chk-toggle:checked:focus + label:after, .follow-chk-toggle:checked:hover + label:after
    {
      border-color: #1dd182;
    background-color: #1dd182;
    }

    .follow-form-element-control > label
    {
      cursor: pointer;
    }

    .follow-form-element-control > input[type="checkbox"]
    {
      width: auto;
      cursor: pointer;
    }

    .follow-chk-toggle{
      line-height: normal;
    opacity: 0;
    position: absolute;
    }
    .follow-chk-toggle:checked + label:after
    {
     border-color: #19b772;
    background-color: #19b772;
    }

    .follow-chk-toggle + label:before
    {
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin: .5em 0;
    margin-right: 2.5em;
    border: 1px solid #ffffff;
    background-color: #ffffff;
    border-radius: 1em;
    transition: margin 0.25s ease;
    z-index: 1;
    }
    .follow-chk-toggle + label:after
    {
    position: relative;
    cursor: pointer;
    display: inline-block;
    height: 2em;
    width: 4em;
    border-radius: 4em;
    border: 1px solid #b6b7ba;
    background-color: #b6b7ba;
    transition: border-color 0.25s ease, background-color 0.25s ease;
    vertical-align: middle;
    margin-left: 8px;
    }
    .follow-chk-toggle:focus + label:after, .follow-chk-toggle:hover + label:after {
        border-color: #d5d6d8;
        background-color: #d5d6d8;
    }
    .follow-chk-toggle + label:before, .follow-chk-toggle + label:after
    {
    font-size: 12px;
    content: ' ';
    }
    .follow-form-element-control
    {
      position: relative;
    }

    .following-button {
      background: #F0F0F0;
      border: 1px solid #A9A9A9;
      color: #484848;
    }

    .following-button {
      display: inline-block;
      margin-top: -11px;
      margin-left: 5px;
      padding: 3px 3px;
      border-radius: 8px;
      cursor: pointer;
      font-size: 0.5rem;
      font-weight: 600;
      line-height: 1.0em;
      text-align: center;
      vertical-align: middle;
      -webkit-font-smoothing: antialiased;
      transition: background-color 0.2s linear 0.1s;
    }
    
    .following-button-large {
      background: #F0F0F0;
      border: 1px solid #A9A9A9;
      color: #484848;
    }

    .following-button-large {
      display: inline-block;
      margin-top: -17px;
      margin-left: 5px;
      padding: 3px 3px;
      border-radius: 8px;
      cursor: pointer;
      font-size: 0.5rem;
      font-weight: 600;
      line-height: 1.0em;
      text-align: center;
      vertical-align: middle;
      -webkit-font-smoothing: antialiased;
      transition: background-color 0.2s linear 0.1s;
    }
    
    .following-button-small {
      background: #F0F0F0;
      border: 1px solid #A9A9A9;
      color: #484848;
    }

    .following-button-small {
      display: inline-block;
      margin-top: -9px;
      margin-left: 5px;
      padding: 3px 3px;
      border-radius: 8px;
      cursor: pointer;
      font-size: 0.4rem;
      font-weight: 600;
      line-height: 1.0em;
      text-align: center;
      vertical-align: middle;
      -webkit-font-smoothing: antialiased;
      transition: background-color 0.2s linear 0.1s;
    }

    /*Start of styles for Briefcase Intstallations Dialog*/
    .bv-briefcase-list .modal-dialog { width:970px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important; }
    .bv-briefcase-list-container-div { width:100%; height: 469px; font-weight: normal; color:#000000; margin: 0px; font-size: 12px;}
    .bv-briefcase-list-col1 { height:100%; width:160px; float:left;  font-weight: 500;}
    .bv-briefcase-list-col2 { height:100%; width:810px; float:right; display: inline-block; position: absolute; }
    .bv-briefcase-list-row { height:55px;line-height:55px;width:100%; font-size:20px; font-weight:400; cursor:pointer; display:block; text-align:left; margin-top:7px; margin-bottom:13px; padding: 0px 0px 0px 20px; }
    .bv-briefcase-list-row-selected { background-color: #2273a5; color:#FFFFFF;}
    .bv-briefcase-list-row-not-selected { color: #2273a5;}
    .bv-briefcase-btn-close {width: 14px; height: 14px; margin-left: 9px; cursor: pointer;}
    .bv-briefcase-btn-close:hover {fill: rgba(0,0,0, 1);}

    /*active tab*/
    .bv-briefcase-list-active-header { line-height:25px; height:30px; width:100%; background: #e6e6e6;}
    .bv-briefcase-list-active-details { line-height:430px; height:430px;}
    .bv-briefcase-list-active-details .mCSB_outside+.mCSB_scrollTools {right: -2px;}
    .bv-briefcase-list-active-value-li { display:block; height:30px; line-height: 10px; padding-top: 10px;}
    .bv-briefcase-list-active-value-li:nth-child(even) {background: rgb(247, 249, 250);}
    .bv-briefcase-list-active-value-li-ul { }

    .bv-briefcase-list-active-header-id-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px; margin-left: 10px;}
    .bv-briefcase-list-active-header-host-addr-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}
    .bv-briefcase-list-active-header-host-name-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}
    .bv-briefcase-list-active-header-os-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}
    .bv-briefcase-list-active-header-user-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}
    .bv-briefcase-list-active-header-date-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}
    .bv-briefcase-list-active-header-purge-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}

    .bv-briefcase-list-active-header > ul  { display:block; position:absolute; width:700px;}
    .bv-briefcase-list-active-details .scroll-list-items { display:block; position:absolute; width:99.5%; height:430px; overflow:hidden;}

    .bv-briefcase-list-active-li-id { width:75px; display: block; position: absolute; min-height: 16px;}
    .bv-briefcase-list-active-li-host-addr { width:85px;  display: block; position: absolute; left: 100px; min-height: 16px;}
    .bv-briefcase-list-active-li-host-name { width:150px;  display: block; position: absolute; left: 200px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; min-height: 16px;}
    .bv-briefcase-list-active-li-os { width:115px;  display: block; position: absolute; left: 365px; min-height: 16px;}
    .bv-briefcase-list-active-li-user { width:90px;  display: block; position: absolute; left: 490px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; min-height: 16px;}
    .bv-briefcase-list-active-li-date { width:140px;  display: block; position: absolute; left: 585px; min-height: 16px;}
    .bv-briefcase-list-active-li-purge { width:35px;  display: block; position: absolute; left: 750px; min-height: 16px;}

    .bv-briefcase-list-active-li-id.ng-binding {left: 10px; width: 66px; background: none;}

    /*purge tab*/
    .bv-briefcase-list-purge-header { line-height:25px; height:30px; width:100%; background: #e6e6e6;}
    .bv-briefcase-list-purge-details { line-height:430px; height:430px;}
    .bv-briefcase-list-purge-details .mCSB_outside+.mCSB_scrollTools {right: -2px;}
    .bv-briefcase-list-purge-value-li { display:block; height:30px; line-height: 10px; padding-top: 10px;}
    .bv-briefcase-list-purge-value-li:nth-child(even) {background: rgb(247, 249, 250);}
    .bv-briefcase-list-purge-value-li-ul { }

    .bv-briefcase-list-purge-header-id-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px; margin-left: 10px;}
    .bv-briefcase-list-purge-header-host-addr-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}
    .bv-briefcase-list-purge-header-host-name-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}
    .bv-briefcase-list-purge-header-os-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}
    .bv-briefcase-list-purge-header-user-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}
    .bv-briefcase-list-purge-header-date-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}
    .bv-briefcase-list-purge-header-purge-li-div {line-height:20px; height:25px; font-weight: 500; margin-top: 5px;}

    .bv-briefcase-list-purge-header > ul  { display:block; position:absolute; width:700px;}
    .bv-briefcase-list-purge-details .scroll-list-items { display:block; position:absolute; width:99.5%; height:430px; overflow:hidden;}

    .bv-briefcase-list-purge-li-id { width:75px;  display: block; position: absolute; min-height: 16px;}
    .bv-briefcase-list-purge-li-host-addr { width:85px;  display: block; position: absolute; left: 100px; min-height: 16px;}
    .bv-briefcase-list-purge-li-host-name { width:150px;  display: block; position: absolute; left: 200px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; min-height: 16px;}
    .bv-briefcase-list-purge-li-os { width:115px;  display: block; position: absolute; left: 365px; min-height: 16px;}
    .bv-briefcase-list-purge-li-user { width:90px;  display: block; position: absolute; left: 490px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; min-height: 16px;}
    .bv-briefcase-list-purge-li-date { width:140px;  display: block; position: absolute; left: 585px; min-height: 16px;}
    .bv-briefcase-list-purge-li-purge { width:80px;  display: block; position: absolute; left: 726px; min-height: 16px; text-align: center;}

    .bv-briefcase-list-purge-li-id.ng-binding {left: 10px; width: 66px; background: none;}

    /*End of styles for Briefcase Intstallations Dialog*/
    .spanUnclickable
    {
        text-decoration: none !important;
    }
    .spanUnclickable:hover
    {
        cursor: default;
    }
    /*Start of styles for Agenda Builder Dialog*/
    .agendaDialog .modal-dialog
    {
        height: 97%;
        margin: 0.1%;
        min-width: 300px;
        min-height: 300px;
        overflow: hidden;
        width: 98%;
    }
    .agendaDialog .modal-content
    {
        height: 100%;
        width: 100%;
    }
    .agenda-file-chooser-header
    {
        background-color: #2273a5;
        width:400px;
        height:40px;
        position:absolute;
        top:0;
    }
    .agendaIcon
    {
        position: absolute;
        width: 23px;
        height: 23px;
        top: 9px;
        fill:#fff;
        cursor: pointer;
    }
    #saveLinksFade {
        display: none;
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        background-color: #ababab;
        z-index: 10001;
        -moz-opacity: 0.6;
        opacity: .60;
        filter: alpha(opacity=60);
    }
    #openAgendaFade {
        display: none;
        position: absolute;
        top: 0%;
        left: 0%;
        width:calc(100% - 400px);
        height: 100%;
        background-color: #ababab;
        z-index: 10001;
        -moz-opacity: 0.6;
        opacity: .60;
        filter: alpha(opacity=60);
    }
    #saveLinksModal {
        display: none;
        position: absolute;
        top: 45%;
        left: 45%;
        width: 74px;
        height: 74px;
        box-shadow: 0px 0px 15px #505050;
        border-radius: 8px;
        background-color: #000000;
        z-index: 10002;
        text-align: center;
        overflow: hidden;
        font-size:  12px;
        color: #FFFFFF
    }

    #saveLinksModal > span
    {
      position: relative;
      top: 10px;
      letter-spacing: .7px;
    }

    #saveLinksModal > img
    {
      position: relative;
      margin: 0 auto;
      top: 20px;
    }
    img.addDocLink {
        height: 15px;
        width: 18px;
        cursor: pointer;
    }
    span.spacer {
        width: 27px;
        height: 15px;
        float: left;
        position: relative;
    }
    /*End of styles for Agenda Builder Dialog*/

    /*Start of styles for signature status dialog*/
    .bv-signature-status-dialog .modal-dialog { min-width: 618px; position: absolute; margin: 0; top: 50%; left: 50%; transform: translate(-50%,-50%) !important; -webkit-transform: translate(-50%,-50%) !important; -ms-transform:  translate(-50%,-50%) !important; }
    .bv-signature-status-container-div   { width:100%; height:445px; font-weight: normal; color:#000000; margin: 0px; }
    .bv-signature-status-row1 {width:100%;height:20%;padding: 20px 20px 20px 20px;}
    .bv-signature-status-row2 {width:100%;height:80%;padding: 20px 20px 20px 20px;}

    .bv-signature-status-header { line-height:30px; height:30px; width:100%; }
    .bv-signature-status-details { line-height:370px; height:370px; width:100%; }

    .bv-signature-status-header > ul  { display:block; position:absolute; width:578px; font-weight:500;}
    .bv-signature-status-details > ul { display:block; position:absolute; width:578px; height:370px; overflow:hidden;}
    .bv-signature-status-details > ul:hover {overflow-y:auto;}

    .bv-signature-status-li-progress{line-height:25px; height:25px; display: block; position: absolute; width:202px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap; padding-right: 10px;}
    .bv-signature-status-li-progress-bar{line-height:25px; height:25px; display: block; position: absolute; width:350px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap; padding-right: 10px;}
    .bv-signature-status-li-user{ line-height:20px; height:20px; display: block; position: absolute; width:202px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap; padding-right: 10px;}
    .bv-signature-status-li-action { line-height:20px; height:20px; display: block; position: absolute; width:192px; left:202px;  text-overflow:ellipsis; overflow:hidden; white-space:nowrap;}
    .bv-signature-status-li-date { line-height:20px; height:20px; display: block; position: absolute; width:182px; left:394px; text-overflow:ellipsis; overflow:hidden; white-space:nowrap;}
    .bv-signature-status-value-li { display:block; height:32px; padding-top:6px; border-bottom:1px solid #ccc;}

    .bv-signature-status-value-li-ul {
        font-size: 14px;
        font-weight: 400;
    }

    .bv-signature-status-progress-bar-value
    {
        background-color:#2273A6;
        color: #2273A6;
    }

    .bv-signature-status-progress-bar-container
    {
        width: 350px;
        height: 24px;
        border: 1px solid #d6d6d6;
        background-color: white;
        cursor: pointer;
    }
    /*End of styles for signature status dialog*/

    /* Folder Agenda Builder */

    .tabs-container {
        width: 100%;
    }

    .tab-container {
        width: 100%;
        height: 500px;
        padding-top:10px;
    }

    .folder-agenda-main {
        padding: 20px;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    .folder-agenda-template {
        width: 100%;
        height: 150px;
        overflow: hidden;
        margin-bottom: 10px;
    }


    .folder-agenda-templates .bv-slider > ul {
        text-align: center;
        width: 470px;
        min-width: 470px;
        min-height: 445px;
    }

    .folder-agenda-templates .bv-slider > ul > li {
        box-shadow: none !important;
        margin: 20px 3%;
        width: 94% !important;
    }

    .folder-agenda-templates-list {
        height: 100%;
    }

    .folder-agenda-templates .no-content-msg-label {
        white-space: normal !important;
    }

    .folder-agenda-template-image-container {
        height: calc(100% - 15px);
        width: 100%;
        margin-bottom: 8px;
        position: relative;
        cursor: pointer;
        /* box-shadow: 0px 0px 10px 0px #888888; */
        display: inline-block;
        vertical-align: middle;
    }

    .folder-agenda-template-name {
        text-align: center;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-top: 5px;
        font-size: 16px;
    }

    .default-agenda-template-image {
        height: auto;
        width: 66%;
        max-width: 100%;
        position: relative;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .folder-agenda-styles {
        padding: 20px 0 0 0;
        height: 400px;
        width: 100%;
        /*margin-bottom: 20px;*/
    }

    .agenda-style-image {
        margin: 20px;
        width: calc(100% - 40px);
        max-height: 200px;
    }

    .selectedStyle {
        -webkit-box-shadow: 0px 0px 0px 2px #99b2cc;
        -moz-box-shadow: 0px 0px 0px 2px #99b2cc;
        box-shadow: 0px 0px 0px 2px #99b2cc;
    }

    .treeDoc .trash-button{
        height: 42px;
        width: 100%;
        position: relative;
        background: $lightGrayBackground;
        border-bottom: 1px solid $grayLighterOpacity50;
        border-top: 1px solid $grayLighterOpacity50;
        padding: 9px 28px;
        font-size: 13.3px;
        margin-top: 40px;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .treeDoc .trash-button:hover titl{
        text-decoration: underline;
    }
    .treeDoc .trash-button icon{
        color: $gray;
        font-size: 15px;
        top: 2px;
        position: relative;
    }
    .treeDoc .trash-button titl{
        display: inline;
        color: $nasdaqBlue;
    }

    [current-instance="FILE_CHOOSER"]{

        .treeDoc{
            background: none;
        }

        .trash-button,
        .top-panel{
            display: none;
        }

    }
}
.nasdaq.bv-mobile{
	.documentsList ul.document-list-ul > li{
		min-height: 50px;
		padding-left: 10px;
		padding-right: 15px;
		float: left;
		height: auto;
		width: 100%;
	}
	.documentsList .fileInfo{
		min-height: 55px;
		height: auto;
	}
	.documentsList .fileInfo .fileDetailsDiv .fileDetailsSpan .fileByName{
		    white-space: normal;
	}
}

.dragDropEvent{
		position:relative;
		z-index:999;	
	}
	.dragDropEvent ul{
			position:relative;
			z-index:-1;
		}
.documentsList.dragDropEventInner{
		z-index:11;
	}
	.documentsList.dragDropEventInner ul{
		z-index:12;
    }
    
    .folderViewerListingModalOpen .viewerDialogClass {z-index: 1051 !important; }  

.virtual-meeting-configuration-wrapper {
    display: inline-block;

    .virtual-meeting-configuration-item {
        float: left;
        height: 100px;
        margin-right: 20px;
        background: lightgray;

        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
        -o-transition: all 1s ease;
        
        &:last-child {
            margin-right: 0;
        }

        &.visible {
            opacity: 1;           
        }

        &.faded {
            opacity: 0;
        }

        &.hidden {
            display: none;
        }

        &.closing {
            width: 650px;

            .virtual-meeting-configuration-button {
                opacity: 0;
                display: none;
            }
        }


        &.opened {
            height: 500px;
            width: 650px;

            .virtual-meeting-configuration-button {
                opacity: 0;
                display: none;
            }
            
            .virtual-meeting-configuration-connect {
                opacity: 1; 
                display: block;
            }
        }

        .virtual-meeting-configuration-connect {
            display: none;

            .virtual-meeting-configuration-close {
                height: 20px;
                float: right;
                position: absolute;
                left: 650px;
            }

            iframe {
                height: 500px;
                width: 650px;
            }
        }

        .virtual-meeting-configuration-button {
            display: inline;
            -webkit-transition: all 1s ease;
            -moz-transition: all 1s ease;
            -ms-transition: all 1s ease;
            -o-transition: all 1s ease;

            .virtual-meeting-configuration-icon {
                float: left;
                width: 80px;
                height: 100%;
                background-size: 60px;
                background-repeat: no-repeat;
                background-position: center center;

                &.zoom {
                    background-image: url("#{$resources-path}/assets/images/zoom_image.png");
                }

                &.teams {
                    background-image: url("#{$resources-path}/assets/images/teams_image.png");
                }

                &.webex {
                    background-image: url("#{$resources-path}/assets/images/webex_image.png");
                }
            }
            
            .virtual-meeting-configuration-description {
                float: left;
                padding: 10px;
                text-align: center;

                h1 {
                    margin-top: 10px;
                    font-size: 18px;
                }

                a {
                    padding: 5px 10px;
                    text-transform: uppercase;
                    background: #1087B1;
                    color: #C1E4ED;
                    cursor: pointer;
                }
            }
        }
    }
}

/* ***** */

@import 'src/utils/variables';

.nasdaq{
    .messagesMain {
        height: calc(100% - 51px) !important;
        width: 100%;
        white-space: nowrap;
        overflow-y:hidden;
        min-width: 640px;
    }

    .inboxItemsList{
        height: 100%;
        min-width: 305px;
        max-width: 305px;
        z-index: 1;
        position: relative;
        float: left;
        display: inline-block;
        border-right: 1px solid $grayLighter;
    }

    .itemComments {
        /*height: calc(100% - 290px);
        max-height:calc(100% - 290px);*/
        width: 100%;
        overflow: hidden;
        overflow-y: auto;
        padding: 14px 25px 15px 35px;
        display: inline-block;
        font-weight: 500;
    }

    .inboxItemsGrid {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .inboxItemsGrid:hover {
        overflow-y: auto;
        width: 100%;
    }

    .inboxItemsGrid:hover li.inboxListItemCell {
        width: 100%;
    }

    .is-selected {
        background: #f0f0f0 !important;
    }
    .is-selected-mid {
        min-width: 203px;
        max-width: 203px;
    }

    .hasMore {
        height: 100%;
    }

    .moreBtnRow {
        margin: 10px 20px 20px 175px;
    }

    .itemDetails {
        height: 100%;
        background: #f0f0f0;
        width: calc(100% - 305px);
        float: right;
        z-index: 2;
        position: relative;

        @media (min-height : 0px) and (max-height : 650px){
            overflow: auto;
        }
    }
    .itemDetails .panel{
        transition: all 500ms;
    }
    .itemDetails .panel.open{
        transform: translateY(-40px);
    }

    .itemDetails .bv-slider > ul > li{
          display: inline-block;
          height: calc(100% - 40px);
          box-shadow: none;
          margin: 0;
          padding-top:7px;
    }

    .unreadItem {
        min-width: 30px;
        max-width: 30px;
        height: 100%;
        padding: 23px 0 0 15px;
        float: left;
        display: inline-block;
    }

    .unreadItemCircle{
        background: #2273A6;
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }

    li.inboxListItemCell {
         white-space: nowrap;
         height: 65px;
         padding: 11px 8px;
    }

    .inboxListCellItem {
        margin: 0 0 0 5px;
        overflow: hidden;
        max-height: 20px;
    }

    .inboxListLeftItems {
        float: left;
        min-width: 221px;
        max-width: 221px;
        height: 100%;
        text-align: left;
        padding: 0 5px 0 0;
        display: inline-block;
    }
    .bv-inbox-link,
    .bv-inbox-link:link,
    .bv-inbox-link:hover,
    .bv-inbox-link:active,
    .bv-inbox-link:visited{
        color: #000000;
        text-decoration:none;
    }


    .inboxListAttachmentIcon {
        float: left;
        min-width: 18px;
        max-width: 18px;
        height: 100%;
    }
    .inboxListSettingIcon {
        float: left;
        min-width: 17px;
        max-width: 17px;
        height: 100%;
        text-align: right;
        display: inline-block;
        padding-bottom: 10px;
        margin-right:7px;
    }

    .inboxItemCreatedBy {
        font-weight: 600;
        font-size: 14px;
    }

    .inboxItemCreatedOn {
        font-size: 11px;
    }

    .attachmentPinImage {
        width: 20px;
        height: 20px;
        position: relative;
        top: 7px;
    }

    .inboxListFooter {
        width: 30%;
        text-align: left;
    }

    .itemHeader {
        width: 100%;
        background: white;
        position: relative;
        min-height: 110px;
        height: 122px;
        padding: 10px 0px 11px 25px;
        border-bottom: 1.5px solid #DDDDDD;

        @media (min-width : 0px) and (max-width : 1160px){
            height: 140px;
        }
    }

    .itemRow {
        height: auto;
        position: relative;
        display: flex;
        line-height: 20px;
    }
    .itemRow1 {
        height: auto;
        padding: 7px 0 0 0;
        position: relative;
        display: flex;
        line-height: 20px;
    }

    .itemHeaderLabel {
        text-align: right;
        min-width: 64px;
        max-width: 64px;
        float: left;
        position: relative;
    }

    .itemHeaderValue {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .hrBreak {
        border: 0;
        height: 1px;
        background: #333;
        margin-top: 5px;
        margin-bottom: 0px;
        background-image: -webkit-linear-gradient(left, #ccc, #333, #ccc);
        background-image:    -moz-linear-gradient(left, #ccc, #333, #ccc);
        background-image:     -ms-linear-gradient(left, #ccc, #333, #ccc);
        background-image:      -o-linear-gradient(left, #ccc, #333, #ccc);
    }

    .wrapText {
        white-space: pre-wrap;      /* CSS3 */
        white-space: -moz-pre-wrap; /* Firefox */
        white-space: -pre-wrap;     /* Opera <7 */
        white-space: -o-pre-wrap;   /* Opera 7 */
        word-wrap: break-word;      /* IE */
    }

    .listAttachmentRow {
        margin: 4px 0 0 10px;
        text-align: right;
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        display: inline-block;
        overflow-y: hidden;
    }

    .listAttachmentRow:hover {
        overflow-x: auto;
    }

    .attachmentRow {
        height: 170px;
        text-align: center;
        width: 100%;
        background: #FFFFFF;
        overflow: hidden;
        white-space: nowrap;
        position: relative;
        border-top: 1.5px solid #DDDDDD;
        padding: 10px 10px 10px 10px;
        display: inline-block;

    }

    .attachmentRow:hover {
        overflow-x: auto;
    }

    .list-message-attachment {
        display: inline-block;
        margin: 0 5px 0 0;
    }

    .message-attachment {
        display: inline-block;
        margin: 0 20px 0 0;
        vertical-align: top;
    }



    .message-attachment-image-container {

        width: 90px;
        height: 115px;
        margin: 0 10px 10px 0;
        box-shadow: 0 0 7px 0 #707070;
        position: relative;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
    }

    .list-attachment-image {
        width: 70px;
        height: 71px;
        margin: 0 10px 5px 0;
    }

    .file-attachment-image {
        border: 1px solid #d1d1d1;
    }

    .attachment-image {
        max-height: 100%;
        max-width: 100%;
        width: 100%;
        vertical-align: middle;
        display: inline-block;
    }

    .attachment-name {
        text-align:center;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 15px;
    }

    .ngViewport.ng-scope {
        overflow-y: hidden;
    }

    .ngViewport.ng-scope:hover {
        overflow-y: auto;
    }

    .bv-inbox-newmsg-dialog {
        max-height: 415px;
        min-height: 415px;
        overflow-y: none;
        overflow-x: none;
    }
    .bv-inbox-up-section {
        min-height: 135px;
        /*max-height: 100px;*/
        overflow-y: auto;
        overflow-x: none;
        background: #FFFFFF;
    }
    .bv-inbox-section-one-third {
        height: 33.333333px;
        width: 100%;
    }
    .bv-inbox-section-left {
        height: 33.333333px;
        width: 120px;
        text-align: right;
        padding-top: 3px;
        float:left;
    }
    .bv-inbox-section-mid {
        min-height: 33.333333px;
        max-height: 33.333333px;
        min-width: 600px;
        max-width: 600px;
        padding-left: 5px;
        float:left;
        overflow-x:hidden;
        overflow-y:auto;
    }

    .bv-inbox-section-right {
        min-height: 33.333333px;
        max-height: 33.333333px;
        min-width: 30px;
        max-width: 30px;
        padding: 5px;
        float:right;
    }

    .bv-inbox-down-section {
        min-height: 270px;
        max-height: 270px;
        overflow-y: auto;
        overflow-x: none;
        margin-top: 2px;
        background: #FFFFFF;

    }
    .bv-inbox-add-button {
        width:18px;
        height:18px;
    }
    .bv-inbox-input {
        border: none;
        width:100px;
        margin-top: 2px;
        text-align: left;
    }
    .bv-inbox-input-recipent-search {
        border: none;
        width:420px;
        text-align: left;
        border-radius: 5px;
    }
    .bv-inbox-msgbody-input {
        min-width: 770px;
        max-height: 265px;
        min-height: 265px;
        border: none;
        padding: 5px;
        resize: none;
        overflow-y: auto;
        overflow-x: none;
    }
    .bv-inbox-msgbody-input:focus {
        outline-width: 0;
    }
    .bv-inbox-btn-blue
    {
        color:#FFFFFF;
        min-width:65px;
        background-color: #3596BD;
        border-style:none;
        max-height: 23.7px;
        min-height: 23.7px;
        border-radius: 4px;
    }
    .bv-inbox-btn-orange
    {
        color:#FFFFFF;
        min-width:65px;
        background-color: #FF8000;
        border-style:none;
        max-height: 23.7px;
        min-height: 23.7px;
        border-radius: 4px;
    }
    .bv-inbox-recipents-img {
        min-width: 35px;
        max-width: 35px;
        min-height: 35px;
        max-height: 35px;
        padding-top: 4px;
    }
    .bv-inbox-recipents {
        background-color: white;
        min-height: 360px;
        max-height: 360px;
        overflow-x: hidden;
        overflow-y:auto;
    }
    .bv-inbox-recipents-search {
        min-width:400px;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    .bv-inbox-recipents-name {
        padding-top: 8px;
        padding-left: 3px;
        min-width: 350px;
        min-height: 35px;
    }
    .bv-inbox-attachment-button {
        color:#000000;
        min-width:60px;
        background-color: #E0E0E0;
        border-style:none;
        max-height: 20px;
        min-height: 20px;
        border-radius: 8px;
        float:left;
        background-image: url('../assets/images/BVLiveryBriefcaseDownloadCancel.png');
        background-repeat: no-repeat;
        background-position: 97% 50%;
        background-size: 12px, 12px;
        margin-top: 3px;
        padding-top: 3px;
        font-size: 10px;
        white-space: normal;
    }
    .bv-inbox-section-mid p {
        margin-bottom: 0px;
        padding-left:10px;
        padding-right:22px;
    }
    .bv-inbox-recipent-result {
        padding-left:5px;
        padding-right:7px;
        white-space: normal;
    }
    .bv-inbox-recipent-result:hover {
        background-color: #3596BD;
        color: #FFFFFF;
    }
    .bv-inbox-recipents-name:hover {
        background-color: #3596BD;
        color: #FFFFFF;
    }

    .bc-inbox-expiry-text
    {
        display: inline;
        float: left;
        padding-left: 74px;
        padding-right: 10px;
    }

    .bv-inbox-expiry-combo
    {
        display: inline;
        width: 91px;
        background-position: 100%;
        background-size: 21px;
        border: 1px solid #D1D1D1;
        float: left;
    }



    /* AVatar */

    .messageUserAvatar {
        margin: 10px 10px 0px 10px;
        display: inline;
    }


    .messageUserImage {
        text-align: center;
        float: left;
        width: 25px;
        height: 25px;
        position: relative;
        display: block;
        background: none;
        z-index: 1;
    }

    .messageUserListCircle {
        text-align: center;
        width: 50px;
        height: 50px;
        overflow: hidden;
        border-radius: 50%;
        box-shadow: rgb(204, 204, 204) 0px 0px 6px 1px;
        background: rgb(81, 81, 81);
        display: inline-block;
    }

    .messageUserFirstLetter {
        width: 50px;
        margin-top: 8px;
        color: white;
        font-weight: lighter;
        font-size: 25px;
        letter-spacing: 1px;
        display: block;
    }

    .bv-lite-pdf-viewer {
        border: 1px solid #D1D1D1;
        max-height:400px;
    }

    .messageLeft {
      float: left;
      position: relative;
      width: 20%;
      height: 100%;
      overflow: hidden;
    }

    .messageRight {
      float: left;
      position: relative;
      width: 80%;
      height: 100%;
      overflow: hidden;
    }

    .messageTitle {
        color: #333;
    }
    .messageTitle:hover {
        color: #333;
    }
    .bv-cursor {
        cursor:pointer;
    }
    .bv-inbox-lbl-black{
        font-size: 16px;
        color: #000000;
        font-style: normal;
        letter-spacing: 0.03em;
        text-align: left;
    }
    .bv-inbox-lbl-blue{
        font-size: 16px;
        color: #2273a6;
        font-style: normal;
        letter-spacing: 0.03em;
        text-align: left;
    }
    .bv-inbox-lbl-500 {
        font-weight: 500;
    }
    .bv-inbox-lbl-400 {
        font-weight: 400;
        font-size: 16px;
    }
    .bv-inbox-attachment{
        font-size: 13px;
        text-align: center;
    }
    .bv-inbox-padding-10 {
        padding-left:10px;
    }
    .inboxListSettingIcon .dropdown-menu {
        left: -124px;
        text-align: left;
        min-width:140px;
        max-width:140px;
        border-radius: 0px !important;
    }

    .inboxSetting {
        text-align: right;
        position: relative;
        top: -42px;

        @media (min-width : 0px) and (max-width : 1160px){
            text-align: inherit;
            top: -22px;
        }

        > div{
            display: inline-block;
            text-align: center;

            @media (min-width : 0px) and (max-width : 1160px){
                width: 23.8%;
            }
        }
    }

    .thread .inboxSetting{
        top: -22px;

        @media (min-width : 0px) and (max-width : 1160px){
            top: 0px;
        }
    }

    .inbox-setting-btn {
        cursor: pointer;
        font-weight:400;
        font-size:13px;
    }

    .bv-compose-message-screen .select2-container-multi .select2-choices .select2-search-field input{
        letter-spacing: 0.6px;
    }

    .bv-mobile-compose-message .select2-container-multi .select2-choices .select2-search-field input{
        letter-spacing: 0.6px;
    }
    .bv-mobile-compose-message section{
        padding: 20px;
    }

    .bv-mobile-compose-message wrap section:first-child{
        border-bottom: 1px solid #ccc;
        position: relative;
    }
    .bv-mobile-compose-message .bv-modal-body > div > div > section,
    .bv-mobile-compose-message .bv-modal-body > div > div > div > section,
    .bv-mobile-compose-message .bv-modal-body ul > li > div > section{
        border-top: 1px solid #ccc;
        height: 175px;
        padding: 20px 0;
    }
    .bv-mobile-compose-message .bv-modal-body ul > li > div > section{
        position: relative;
        top: -45px;
    }
    .bv-mobile-compose-message to,
    .bv-mobile-compose-message subject{
        margin-bottom: 20px;
        display: inline-block;
        color: #AFAFAF;
        font-weight: 500;
    }
    .bv-mobile-compose-message to{
        margin-bottom: 15px;
    }
    .bv-mobile-compose-message to > btn{
    /*     background: url('../assets/images/android_icons_add-circle.png'); */
        width: 20px;
        height: 20px;
        position: absolute;
        right: 25px;
        top: 22px;
    }
    .bv-mobile-compose-message to > btn > svg{
        width: 21px;
        height: 21px;
    /*     fill: #2574A7; */
    }
    .bv-mobile-compose-message .ui-select-container{
        width: calc(100% - 80px);
        font-size: 14px;
    }

    .bv-mobile-compose-message .select2-container-multi .select2-choices .select2-search-choice {
        padding: 0px 18px 3px 3px;
        margin: 0px 0 3px 5px;
    }

    .bv-mobile-compose-message .select2-container-multi .select2-search-choice-close {
        margin-top: -3px;
    }

    .bv-mobile-compose-message .select2-container-multi .select2-choices {
        min-height: 24px;
        max-height: 24px;
    }
    .bv-mobile-compose-message .select2-container-multi .select2-choices .select2-search-field {
        height: 20px;
        margin-top: -3px;
    }
    .bv-mobile-compose-message .select2-container-multi .select2-choices .select2-search-field input {
        height: 18px;
        padding: 0px 0 0 5px;
        letter-spacing: 0.6px;
        font-family: inherit;
        font-size: inherit;
    }
    .bv-mobile-compose-message {
        font-size: 14px;
    }
    .bv-mobile-compose-message subjectinput{
        width: calc(100% - 115px);
        display: inline-block;
    }
    .bv-mobile-compose-message subjectinput input{
        width: 100%;
        font-size: 14px;
    }
    .bv-mobile-compose-message .bv-sup-red-astric{
        left: -4px;
        position: relative;
    }
    .bv-mobile-compose-message .select2-choices{
        padding: 6px 0px !important;
    }
    .bv-mobile-compose-message wrap{
        min-height: 100%;
        display: block;
        margin-bottom: -180px;
        height: 500px;
    }
    .bv-mobile-compose-message wrap:after{
        content: "";
        display: block;
        height: 180px;
    }
    .bv-mobile .bv-compose-message-screen .outerComments1{
        height: 100%;
    }

    .bv-mobile-compose-message attachments{
        top: -32px;
        position: relative;
        background-color: #fff;
        margin: 0 auto;
        display: block;
        width: 140px;
        text-align: center;
        color: #AFAFAF;
        font-weight: 500;
    }
    .bv-mobile-compose-message wrap section:last-child{
        height: calc(100% - 300px);
        /*min-height: 214px;*/
    }
    .bv-mobile-compose-message .bv-threads-background wrap section:last-child{
        height: calc(100% - 380px);
    }

    .bv-mobile-compose-message attachments > btn{
        width: 20px;
        height: 20px;
        position: absolute;
        display: block;
        right: 0px;
        top: 1px;
    }
    .bv-mobile-compose-message attachments > btn > svg{
        width: 21px;
        height: 21px;
    /*     fill: #2574A7; */
    }

    .bv-mobile-compose-message .compose-notice {
        position: absolute;
        top: 85px;
    }

    .bv-messages-mobile section{
        height: 45px;
        border-bottom: 1px solid #ccc;
        overflow: hidden;
    }
    .bv-messages-mobile inbox,
    .bv-messages-mobile sent{
        width: 50%;
        height: 100%;
        display: inline-block;
        text-align: center;
        font-size: 18px;
        position: relative;
        padding-top: 10px;
        /*color: #ababab;*/
    }
    .bv-messages-mobile inbox.active span,
    .bv-messages-mobile sent.active span{
        color: #000;
        border-bottom: 5px solid #000;
        padding-bottom: 7px;
    }

    .bv-messages-mobile .inboxItemsGrid li{
        border-bottom: 1px solid #ccc;
        padding-right: 20px;
    }

    .bv-messages-mobile .inboxItemsGrid li:last-child{
        border-bottom: inherit;
    }
    .bv-messages-mobile .inboxItemsGrid{
        height: calc(100% - 45px);
        overflow-y: auto;
    }
    .bv-messages-mobile .inboxItemsGrid li{
        padding:0;
        position:relative;
    }
    .bv-messages-mobile .inboxItemsGrid li > div{
        transition: all 50ms;
        height: 100%;
        width: 100%;
        background-color: #fff;
        padding:10px;
        position: relative;
        z-index: 2;
    }
    .bv-messages-mobile .bv-inbox-delete-btn{
        width: 100px;
        height: 100%;
        display: block;
        background-color: #E20023;
        position: absolute;
        right: 0;
        top: 0;
        border: inherit;
        color: #fff;
        z-index: 1;
    }

    .bv-messages-mobile .inboxListAttachmentIcon{
        margin: 0 10px;
    }

    .bv-messages-mobile .inboxItemsGrid li > div.delete{
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
    }
    /* .bv-messages-mobile .inboxItemsGrid li > div.delete .inboxListAttachmentIcon{
        transform: translateX(-100px);
    }
     */

    .bv-messages-mobile .bv-button-feedback-type-2{
        z-index: 2;
        left:0;
    }

    .bv-open-message-mobile-header-row {
        font-weight: 500;
    }

    .bv-open-message-mobile-header-row .lbl {
        width: 70px;
        display: inline-block;
        position: relative;
        text-align: right;
        color: #d1d1d1;
        margin-right: 5px;
    }

    .bv-open-message-mobile-header-row .val {
        display: inline-block;
        width: calc(100% - 85px);
    }

    .bv-open-message-mobile-subject {
        height: calc(100% - 20px);
        position: relative;
        padding-bottom: 20px;
        width: 100%;
     }
    .bv-open-message-mobile-comments {
        position: relative;
        overflow: auto;
        font-size: 20px;
    }

    .bv-mobile-compose-message .bv-slider {
        height: 165px;
        width: 100%;
        margin-top: -32px;
        text-align: center;
        text-align: -webkit-center;
    }
    .bv-mobile-compose-message .bv-slider > div {
        top: calc(50% - 40px) !important;
    }

    .bv-mobile-compose-message .bv-slider > div.bv-slider-right-btn {
        right: -5px;
    }

    .bv-mobile-compose-message .bv-slider > div.bv-slider-left-btn {
        left: -5px;
    }
    .bv-mobile-compose-message .bv-slider > ul > li{
        width: 33.33333% !important;
        margin: 20px 0 !important;
        box-shadow: none !important;
    }
    .bv-mobile-compose-message .bv-slider > ul > li > card{
        box-shadow: none;
    }

    .bv-mobile-compose-message .bv-slider > ul{
        min-width: inherit;
        margin: 0 25px !important;

    }
    .bv-mobile-compose-message .bv-slider > ul > li > ul > li:first-child{
        margin-bottom: 20px;
    }
    .bv-mobile-compose-message .message-attachment-image-container {
        width: 75px;
        height: 96px;
    }
    .bv-mobile-compose-message .bv-button-feedback-type-1{
        top: -12px !important;
        left: -12px !important;
    }
    .bv-mobile-compose-message .bv-mobile-menu .bv-button-feedback-type-1{
        top: 0px !important;
        left: 11px !important;
    }
    .bv-mobile-compose-message {
    /*  	z-index: 100 !important; */
    }
    .bv-mobile-compose-message-backdrop{
    /*  	z-index: 100 !important; */
     }

    #detailSection .itemComments {
        height: calc(100% - 295px);
        /*max-height: calc(100% - 295px);*/
        width: calc(100% - 2px);
        overflow: hidden;
        overflow-y: auto;
        padding: 14px 43px 10px 43px;
        display: inline-block;
        font-weight: 500;
        background: #fff;

        @media (min-height : 0px) and (max-height : 650px){
            height: calc(100% - 122px);
        }
    }
    #detailSection .itemDetails {
        height: 100%;
        width: calc(100% - 305px);
        float: right;
        background: #fff;
        display: inline-block;
        z-index: 2;
        overflow: hidden;
    }
    #detailSection .itemHeader {
        width: 100%;
        background: white;
        position: relative;
        height: 122px;
        padding: 0;
        border-bottom: 1.5px solid #DDDDDD;
        margin-left: 0px;

        .item-rows {
            padding: 16px 22px 21px 22px;
        }

        @media (min-width : 0px) and (max-width : 1160px){
            height: 140px;
        }
    }
    #detailSection .itemRow {
        height: auto;
        position: relative;
        display: flex;
        line-height:20px;
    }
    #detailSection .itemRow1 {
        height: auto;
        padding: 7px 0 0 0;
        position: relative;
        display: flex;
        line-height:20px;
    }
    #inboxRightSection{
        height: calc(100% - 25px);
        list-style-type: none;
        margin:0;
        padding:0;
        transition: 400ms all;
        position: relative;
    }
    .inboxRightSection-li{
      width: 100%;
      background: #fff;
      transition: 500ms all;
      margin-bottom: 5px;
    }
    .inboxRightSection-li.opened{
      height: 100%;
      min-height: 500px;
    }
    .bv-inbox-thread-panel{
        height: 40px;
        padding: 8px;
    }
    #inboxRightSection .itemComments {
        /*height: calc(100% - 304px);
        max-height: calc(100% - 304px);
        width:calc(100% - 7px);*/
        min-height: 100px;
    }
    #inboxRightSection .bv-hide-show-content-arrow {
        cursor: pointer;
        width: 15px;
        height: 26px;
        float: left;
        margin: 7px 5px 0 0;
    }
    #inboxRightSection .bv-show-panel-content-arrow {
        height: 15px;
        position: absolute;
        top: 6px;
        left: -17px;
        cursor: pointer;
        width: 15px;
    }
    #inboxRightSection .bv-show-panel-content-arrow svg {
        transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        margin-bottom: 5px;
        fill:#000;
        height: 15px;
        width: 15px;
    }
    #inboxRightSection .bv-hide-show-content-arrow svg {
        fill:#000;
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform-origin: 0 0;
        -moz-transform: rotate(0deg);
    }
    #inboxRightSection .itemRow{
        padding-top:3px;
    }
    .messageDetail{
        opacity:0;
        transition: opacity 700ms ease-in;
        height: 100%;
    }
    .messageDetail.opened{
        opacity:1;
    }
    .bv-inbox-subject-box {
        width: calc(100% - 21px);
        float: right;
    }
    .heightRecal {
        height:685px;
    }
    .itemDetails .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: rgba(0,0,0,0.35);
    }

    .bv-mobile-compose-message .bv-modal-btn-custom-box{
        position: absolute;
        top: 10px;
    }
    .bv-mobile-compose-message .bv-modal-btn-custom-box > div{
        position: relative;
        display: inline-block;
        width: 25px;
        height: 25px;
        fill: white;
        margin: 0px 15px 0 0;
    }
    .bv-mobile-compose-message .bv-modal-btn-custom-box > div > div{
        width: 25px;
        height: 25px;
    }
    .bv-mobile-compose-message .bv-modal-btn-custom [ng-if="showHeader"]{
        margin-left: 130px;
    }
}


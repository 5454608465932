@import 'src/utils/variables';
@import 'src/utils/classes';

.meetings-top-panel{

    .top-panel{
            width: 100%;
    		font-size: 13px;
    		color: #96979c;
    		padding-top: 23px;
    		font-family: "SourceSansProRegular";

        > div {
            .list-view{
            	float: right;
    			margin-right: 59px;
    			margin-top: 8px;
    			button{
                    outline: none;
                    width: 64px;
    				height: 30px;
    				font-size: 13px;
                }
            }

            .week-view,
            .month-view,
            .day-view{
                > div{
                    width: 295px;
                    height: 67px;
                    display: block;
                    float: left;
                    padding: 20px 18px;
                    text-align: center;

                    icon{
                        top: 8px;
                        position: relative;
                        font-size: 10px;
                        cursor: pointer;

                        &.ss-navigateleft{
                            float: left;
                        }
                        &.ss-navigateright{
                            float: right;
                        }
                    }

                    titl{
                        display: inline-block;
                        font-size: 23px;
                        font-family: $nasdaqFontSemibold;
                        color: $grayDark;
                    }
                }

                > button{
                    outline: none;
                    width: 105px;
                    height: 67px;
                    border-radius: 0;
                    border: none;
                    border-right: 1px solid $grayLighter;
                    border-bottom: 1px solid $grayLighter;
                    border-left: 1px solid $grayLighter;
                    &.not-active{
                     color:$gray;
                    }
                }
            }

            .nav-tabs{
                    position: relative;
    				display: block;
    				top: 0;
    				left: 0;
    				border-bottom: 3px solid #eaeaeb;
    				padding: 0 20px;


                li{
                        margin-bottom: -3px;
    					border-bottom: 3px solid transparent;
    					font-size: 11.5px;
    					width: 80px;
    					text-align: center;
    					&:hover, &.active{
    						border-bottom: 3px solid $blue;
    					}

                    bt{
                        
                        line-height: 0px;
    					padding: 12px 15px;
    					color: #96979c;
    					background: none;
    					font-size: 11.5px;
                     }

                     &.active bt, &:hover bt{
                        color: $blue;
        				background: none;
                     }
                 }
            }
        }
    }
/*
     .nav-tabs li.active,
     .nav-tabs li:hover{
        border-bottom: 3px solid $blue;
     }

     .nav-tabs li.active bt,
     .nav-tabs li:hover bt{
        color: $blue;
     }
*/
}
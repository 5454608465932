@import 'src/utils/variables';
@import 'src/utils/mixins';
@import 'src/utils/classes';

filter-criteria-panel{
	display:block;
	max-width:0;
	float:left;
	height:100%;
}

.filter-criteria-panel{
    position: relative;
    width: 300px;
    float: left;
    border-right: 1px solid $grayLight;
    height: 100%;
    left:-300px;
    box-sizing: content-box;
    transition: left .5s;
    -webkit-transition: left .5s;
    > section{
        position: relative;
        height: 43px;
        border-bottom: 1px solid $grayLight;
        padding: 9px;
        min-width: 300px;

        span{
            font-family: $nasdaqFontRegular;
            font-size: 16px;
            letter-spacing: -0.1px;
            color: $grayDarkestHeader;
        }

        > div{

            > div{
                height: 43px;
                width: 43px;
                position: absolute;
                top: 0px;
                text-align: center;
                padding: 12px;

                &:first-child{
                    right: 43px;
                    transform: rotateY(180deg);
                    -webkit-transform: rotateY(180deg);
                }
                &:last-child{
                    border-left: 1px solid $grayLighter;
                    right: 0px;
                }

                icon{
                    @extend .button;
                    width: 14px;
                    height: 19px;
                    font-size: 14px;
                    letter-spacing: -0.1px;
                    color: $grayMid;
                    cursor: pointer;

                    &.ss-refresh{

                    }
                    &.ss-navigateleft{

                    }
                }
            }
        }
    }

    > div{
        height: calc(100% - 45px);
        min-width: 300px;

        section{
            &.date-range-filter{
                > div{

                    &:first-child{
                        border-top: none !important;
                    }

                    &:last-child{

                        &.hide-dates{
                            .hasDatepicker{
                                color: rgba(0,0,0,0);
                            }
                        }

                        > div{
                            &:nth-child(1){
                                @extend .input;
                                border: 1px solid $grayMid;
                                border-radius: 3px;
                                padding: 0 5px;
                                margin: 0px 10px 13px;

                                select{
                                    width: 100%;
                                    height: 30px;
                                    border: none;
                                    font-size: 13px;
                                    letter-spacing: -0.1px;
                                    color: $grayDarkest;
                                    background: #fff;
                                    outline: none;
                                    cursor: pointer;
                                }
                            }

                            &:nth-child(2){
                                float: left;
                                padding: 0 10px;

                                icon{
                                    left: 21px;
                                }
                            }
                            &:nth-child(3){
                                display: inline-block;
                                padding-right: 10px;

                                icon{
                                    left: 11px;
                                }
                            }

                            &:nth-child(2),
                            &:nth-child(3){
                                width: 50%;
                                position: relative;

                                label{
                                    font-size: 13px;
                                    font-weight: 400;
                                    letter-spacing: -0.1px;
                                    color: $grayDarker;
                                    margin: 0;
                                }

                                input{
                                    @extend .input;
                                    width: 100%;
                                    border-radius: 3px;
                                    background-color: #ffffff;
                                    border: solid 1px $grayMid;
                                    height: 32px;
                                    padding-left: 28px;
                                    padding-right: 5px;
                                    font-size: 13.5px;
                                    letter-spacing: -0.1px;
                                    color: $grayDark;
                                    box-shadow: none;

                                    &[disabled="disabled"]:hover{
                                        border: solid 1px $grayMid !important;
                                    }
                                }

                                icon{
                                    position: absolute;
                                    bottom: 6px;
                                    font-size: 12px;
                                    color: $grayMid;
                                }
                            }
                        }
                    }
                }
            }

            &.teamspace-filter,
            &.groups-filter,
            &.user-search-filter,
            &.activity-type-filter{
                .filter-selection{
                    margin: 0 10px;
                    .search-box {
                    	position: relative;
                    	.dropdown{
	                    	&:after{
	                    		display:none;	
	                    	}
                    	}
                       .search-field{
                            position: relative;
						    padding: 0;
						    border: none;
						    height: auto;
	                       	input.form-control{
	                            padding: 5px 10px 5px 25px;
	                        }
	                        icon{
	                            left: 10px;
	                            font-size: 10px;
	                            top: 13px;
	                            color: $grayMid;
	                            display: block;
	                            width: 12px;
	                            position: absolute;
	                        }
	                        &.disabled {
						   		pointer-events:none;
						   		opacity: .6;
							}
							& .dropdown-menu li:first-child {
							    height: 34px;
							    padding: 6px 10px;
						    }
                       }
                       .dropdown-menu{
						    top: 35px;
						    left: 0;
						    width: 100%;
						    max-height: 200px;
						    	li{
	                                height: 34px;
	                                font-size: 14px;
	                                letter-spacing: -0.1px;
	                                color: $grayDarkest;
	                                cursor:pointer;
	                                padding: 6px 10px;
	                                font-weight: 300;
	                                position: relative;
	                                border-bottom: 1px solid $grayLighter;
	                                  & a {
	                                	    background: none;
										    color: $grayDarkest;
										    padding: 0
		                                }
	                                 &:last-child{
	                                    border-bottom-right-radius: 6px;
	                                    border-bottom-left-radius: 6px;
	                                    border-bottom: none;
	                                }
	                                &:hover{
	                                	background: $grayLightest;
	                                	& a {
	                                	    background: none;
										    color: $grayDarkest;
		                                }
	                                }
	                                &.active{
	                                	background: $grayLightest;
	                                	& a {
	                                	    background: none;
										    color: $grayDarkest;
		                                }
	                                }
	                        }
                               
                       }
 					}
                    .activity-type-list{
                        li{
                        	margin:3px 0;
                        	label{
                                font-size: 13px;
                                font-weight: 400;
                                letter-spacing:0;
                                color: #000000;
                                margin: 0;
                                cursor:pointer;
                            }
                        }
                    } 
                    .dropdown{
                        @extend .input;
                        min-height: 32px;
                        border: 1px solid $grayMid;
                        border-radius: 3px;
                        padding: 3px 10px;
						&.disabled {
						   pointer-events:none;
						   > span{
						   		color:$grayMid;
						   }
						   &:after{
	                            border-top-color: $grayMid;
	                        }
						}	
                        > span{
                            font-size: 13px;
                            letter-spacing: -0.1px;
                            color: $grayDarkest;
                            font-family: inherit;
                            font-weight: normal;
                            display: block;
                            min-height: 24px;
                            padding: 3px 5px;
                            padding-left: 0;
                            cursor: pointer;
                            position: relative;
                            right:0;
                            left:0;
                            z-index: 10;
                        }

                        .dropdown-menu{
                            left: -1px;
                            margin: 1px 0;
                            border-radius: 6px;
                            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
                            width: 279px;
                            border: none;
                            padding: 0;

                            li{
                                height: 34px;
                                font-size: 14px;
                                letter-spacing: -0.1px;
                                color: $grayDarkest;
                                cursor:pointer;
                                padding: 6px 10px;
                                font-weight: 300;
                                position: relative;
                                border-bottom: 1px solid $grayLighter;
                                &:first-child{
                                    height: 38px;
                                    padding: 0;
                                    border-top-right-radius: 6px;
                                    border-top-left-radius: 6px;

                                    input{
                                        width: 100%;
                                        border: none;
                                        border-bottom: 1px solid #d5d6d8;
                                        height: 38px;
                                        border-top-right-radius: 6px;
                                        border-top-left-radius: 6px;
                                        padding: 5px 10px 5px 25px;
                                    }

                                    icon{
                                        left: 10px;
                                        font-size: 10px;
                                        top: 13px;
                                        color: $grayMid;
                                        display: block;
                                        width: 12px;
                                    }
                                }

                                icon{
                                    position: absolute;
                                    right: 10px;
                                    top: 9px;
                                    display: none;
                                }

                                &:last-child{
                                    border-bottom-right-radius: 6px;
                                    border-bottom-left-radius: 6px;
                                    border-bottom: none;
                                }

                                 &.selected{
                                    background-color: $grayLighter;
                                    color: $grayMid;

                                    icon{
                                        display: block;
                                    }
                                }
                                &:hover{
                                	background: $grayLightest;
                                }
                            }
                        }

                        &:after{
                            content: '';
                            display: block;
                            border-top: 6px solid #1080A2;
                            border-right: 4px solid transparent;
                            border-left: 4px solid transparent;
                            right: 10px;
                            position: absolute;
                            top: 12px;
                        }
                    }
                }
            }

            &:nth-child(4){

                > div{
                    &:first-child{
                        border-bottom: 1px solid $grayLighter;
                    }
                }
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5){
                > div{

                    &:first-child{
                        min-height: 32px;
                        background: $lightMockGrayBackground;
                        padding: 2px 10px;
                        border-top: 1px solid $grayLighter;
                        cursor: pointer;
                    }

                    &:last-child{
                        padding-top: 16px;
                        padding-bottom: 16px;
                        border-top: 1px solid $grayLighter;

                        &.opened{
                            padding-bottom: 0;
                        }
                    }

                    > span{
                        font-family: $nasdaqFontRegular;
                        letter-spacing: 0.3px;
                        font-size: 13px;
                        font-weight: 600;
                        color: $grayDark;
                    }

                    button{
                        @extend .button;
                        border: 0;
                        background: 0;
                        margin: 0;
                        padding: 0;
                        float: right;
                        font-size: 12px;
                        font-weight: 400;
                        color: $nasdaqBlue;
                        margin-top: 4px;
                        letter-spacing: 0.4px;
                        outline: none;
                        transition: color 1s;
                        &:disabled {
						    color: $grayMid;
						    cursor:default;
						}
                    }
                }
                &:last-child{
                	&:last-child{
                		min-height:335px;
                	}
                }
            }
            
            .applied-filters{
                margin-top: 12px;
            }

            .applied-filters{
                margin-bottom: 8px;

                > span{
                    font-family: $nasdaqFontRegular;
                    display: block;
                    width: 100%;
                    border-bottom: 1px solid $grayLighter;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: -0.1px;
                    color: $grayDarker;
                }

                ul{
                    margin: 5px 0 0;

                    li{
                        @extend .ellipsis-hidden;
                        font-size: 13.5px;
                        letter-spacing: -0.1px;
                        color: $grayDarkest;
                        height: 30px;
                        border: none;
                        padding: 6px 20px 6px 0;
                        position: relative;

                        icon{
                            position: absolute;
                            right: 13px;
                            top: 0;
                            font-size: 13px;

                            &:before{
                                @extend .button-red;
                                position: absolute;
                                right: -13px;
                                top: 7px;
                                font-size: 13px;
                                color: $grayMid;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

        }

        .mCSB_scrollTools{
            right: -2px !important;
        }
    }
    .mCustomScrollBox{
    	overflow:hidden;
    }
}

.filter-panel-bookmark{
    display: block;
    position: absolute;
    top: 10px;
    left: 0;
    width: 19px;
    min-width: 19px;
    border-right: 1px solid $grayLight;
    border-top: 1px solid $grayLight;
    border-bottom: 1px solid $grayLight;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    height: 48px;
    z-index:99;
    transition: left .5s;
    -webkit-transition: left .5s;

    > div{
        padding: 0px 4px;

        &:last-child{
            transform: rotateY(180deg);
            -webkit-transform: rotateY(180deg);
        }

        icon{
            @extend .button;
            color: $grayMid;
            font-size: 11px;
        }
    }
}
.show-panel{
    .filter-criteria-panel{
    	left:0;
    } 
    .filter-panel-bookmark{
        left: -20px;
    }
    .report-view{
        padding-left:0;
	    @include calc(width, "100% - 300px");
	 }
}



.typeahead .custom-popup-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    background-color: #f9f9f9;
  }

  .typeahead .custom-popup-wrapper > .message {
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    color: #868686;
  }

  .typeahead .custom-popup-wrapper > .dropdown-menu {
    position: static;
    float: none;
    display: block;
    min-width: 160px;
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

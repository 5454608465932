@import 'src/utils/variables';

.bv-mobile .newItemList .top-panel{

    titl:nth-child(2){
            width: 43%;
            max-width: inherit;
    }

    titl:nth-child(3){
           width:25%;
    }

}
@import 'src/utils/variables';

.ui-datepicker{
    font-size: 11px;
	border-bottom-right-radius	:0px;
	border-top-right-radius : 0px;
	border-top-left-radius:0px;
	border-bottom-left-radius	:0px;
	border:1px solid #aaaaaa;

	.ui-state-default{
    	border:none;
    	background:none;
    	font-weight: normal;
    	color: #555555;
    	text-align: center;
    }
    .ui-state-focus {
    	background-color: #e5e5e5;
    	font-weight: normal;
    	color: #212121;
    }
    .ui-state-active{
    	/*background: url(#{$resources-path}/assets/images/ui-bg_glass_65_ffffff_1x400.png) repeat-x scroll 50% 50% #ffffff;*/
        border: 1px solid #aaaaaa;
        color: #212121;
        font-weight: normal;
    }
    .ui-state-highlight {
        /*background: #fbf9ee url(#{$resources-path}/assets/images/ui-bg_glass_55_fbf9ee_1x400_new.png) 50% 60% repeat-x;*/
        background: $nasdaqBlue;
        color: #ffffff;
        text-align: center;
        border: 1px solid #ffffff;
    }
    .ui-widget-header {
        border:none;
        background-color: #e5e5e5;
        color: #222222;
        font-weight: bold;
    }
}

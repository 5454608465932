@import 'src/utils/variables';
@import 'src/utils/classes';

.nasdaq{
    .signatures-list{
    	width: calc(100% - 15px);
        height: calc(100% - 101px);
        overflow: auto;
        .p-a-50{
        	padding:50px;
        }
    }
    .bigFont{
        font-size:x-large;
        font-weight:bold;
    }

    .boldFont{
        font-weight:bold;
    }

    .underline{
        text-decoration: underline;
    }

    .capitalize{
        text-transform:capitalize;
    }

    .blueFont{
        color:#2273a5;
    }
    .redFont{
        color:#FF0000;
    }
    .purple-font{
        color: #bb4598;
    }
    .signaturesMain{
      width:100%;
      height:100%;
      position: relative;
    }
    .signatures-section{
        overflow:hidden;
        width:100%;
        border-bottom: 1px solid $grayLighter;
        min-height: 57px;
        padding: 5px;
        display: flex;
    }
    .signatures-sectionOne,
    .signaturesMain .sectionOne{
        width: calc(100% - 424px);
        overflow: hidden;
        text-overflow: ellipsis;
        width: 70%;

    }
    .signatures-sectionTwo {
        padding-top: 12px;
        width: 30%;
    }
    .signatureName {
        display: inline-block;
        cursor: pointer;
        padding-left:18px;
        font-size: $commonFontSize;
        color: $nasdaqBlue;
        font-weight: $commonFontWeight;
        margin-top: 14px;
        position: relative;
    	max-width: 90%;
        > span{
        	    display: inline-block;
			    white-space: nowrap;
			    text-overflow: ellipsis;
			    overflow: hidden;
			    max-width: 100%;
        }
    }

    .signatureName:hover {
        text-decoration: underline;
    }

    .signature-menu-icon {
      height: 10px;
      width: 11px;
      position: absolute;
      fill: rgb(0, 0, 0);
      margin: 0px 0px 0 0px;
      display: none;
      cursor: pointer;
      right: -15px;
      top: 2px;
    }

    .signatures-section:hover,.questionBox:hover,.manage-signature-section:hover, .participantTableDataHover:hover, .bv-signature-removeAttendeesDiv2 .bv-ts-grid-ul-li:hover{
        background: #ebeef0;
    }

    .signatures-section:hover .signature-menu-icon {
      display: inline;
    }
    .signatures-section:hover > div > svg {
      display: inline;
    }

    .signatureListProgressBar {
        width: 100%;
        height: 20px;
        margin-top:0;
        border: 1px solid $grayLight;
        pointer-events: none;
        border-radius: 15px;
        box-shadow: inset 0px 0px 1px 0px $grayMid;
        background-color: $lightMockGrayBackground;
    }

    .signatureListProgressBar .progress-bar{
        background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 73%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
        background-size: 16px 44px;
        border: 1.9px solid $blueMid;
        border-radius: 15px;
        box-shadow: 0px 0px 1px 0px #000000;
        overflow: hidden;
        &.progress-bar-signature-value {
		    background-color: $blue;
		    color: $blue;
		}
    }
    .signatureListProgressBar .progress-bar::after{
        background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 73%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
        background-size: 16px 44px;
        height: 18px;
        width: 100%;
        display: block;
        content: "";
        position: relative;
        top: -20px;
        left: -8px;
    }
    .signatureListProgressBar .progress-bar span{
        text-align: center;
        top: -2px;
        position: relative;
        color: #fff !important;
    }
    .bv-signature-tracker-dialog {
            .tracker-header{
                color:$grayDarkest;
                padding:10px;
                border-bottom: 1px solid $grayLight;
                .header-label{
                    font-size:14px;
                    text-overflow: ellipsis;
    				white-space: nowrap;
    				overflow: hidden;
                }
                .sign-completed-label{
                    display:inline-block;
                    width:40%;
                    font-size:13px;
                }
                .progress-bar-container{
                    display:inline-block;
                    width:40%;
                    height:12px;
                    & .signatureListProgressBar{
                        height:15px;
                    }
                }
            }
            .tracker-details{
            	font-size:12px;
                .item-name{
                    > li{
                        font-family:$nasdaqFontRegular;
                        color:$grayDark;
                        padding:6px;
                        > span{
                        	display:inline-block;
                        	cursor:pointer;
                        	&::after{
						        content: "";
						        display: inline-block;
						        margin-left: 4px;
						        position: relative;
						        top: -1px;
						        border-top: 6px solid $gray;
						        border-right: 4px solid transparent;
						        border-left: 4px solid transparent;
						    }
						    
						    &.sorted::after{
						        border-top: 0;
						        border-bottom: 6px solid $gray;
						    }
                        }
                        &:first-child{
                            display:inline-block;
                            width:85%;
                        }
                        &:last-child{
                            display:inline-block;
                        }
                    }
                }
                .item-list{
                	height:200px;
    				max-width:98%;
                    li{
                        padding:10px;
                        border-top:1px solid $grayLightest;
                        div {
                        	display:inline-block;
                        	width:90%;
                        }
                        span{
                        	color:$green;
                        }
                        &:nth-child(even){
                        	background:$grayLightest;
                        }
                    }
                }
            }
        }
}
@import 'src/utils/variables';

.details-panel-top-panel{

    .top-panel{
        width: 100%;
        height: 42px;
        font-size: 13px;
        color: $gray;
        padding-top: 15px;
        font-family: $nasdaqFontSemibold;

        .nav-tabs{
            position: relative;
            display: block;
            top: 0;
            left: 0;
            border-bottom: 3px solid $grayLighter;
        }

         .nav-tabs li{
            margin-bottom: -3px;
            border-bottom: 3px solid transparent;
            font-size: 11.5px;
            width: 50%;
            text-align: center;
         }

         .nav-tabs li bt{
            line-height: 0px;
            padding: 11px 15px;
            color: $gray;
            background: none;
            font-size: 13.5px;
            margin-bottom: 1px;
         }

         .nav-tabs li.active,
         .nav-tabs li:hover{
            border-bottom: 3px solid $blue;
         }

         .nav-tabs li.active bt,
         .nav-tabs li:hover bt{
            color: $blue;
         }
    }
}
@import 'src/utils/variables';

.advanced-search{
    position: relative;
    height: 100%;
    overflow: auto;

    ad-search{
        height: 100%;

        .ad-search{
            height: 100%;

            .results-list{
                height: 100%;

                >div{
                    > ul{
                        height: calc(100% - 61px);
                        overflow: auto;

                        li{
                           &.title{
                              display: none;
                           }
                        }
                    }
                }
            }
        }
    }
}
@import 'src/utils/variables';

weekview{
    height: 100%;

    > div{
        height: calc(100% - 67px);

        > div{
            &.header{
                position: relative;
                z-index: 1;
                /*box-shadow: 0px 2px 7px -4px #000;*/
                box-shadow: -1px 2px 4px -3px #000;

                > ul{
                    &:first-child{
                       display: block;
                       width: 80px;
                       float: left;

                        li{
                            font-size: 13px;
                            padding: 10px;
                        }
                    }
                }
                ul{
                    &.current{
                        li{
                            font-family: $nasdaqFontRegular;
                        }
                    }

                    li{
                        font-size: 11.5px;
                        letter-spacing: normal;
                        line-height: 28px;
                        padding: 2px;
                        color: $grayDark;
                        font-family: $nasdaqFontLight;
                        text-align: center;

                        &:first-child{
                            height: 30px;
                        }
                        &:last-child{
                            height: 45px;
                        }

                        event{
                            width: 100%;
                            position: relative;
                            display: block;
                            float: left;
                            z-index: 2;
                            padding: 2px;
                            height: 41px;

                            > div{
                                height: 100%;
                                background: rgba(234, 234, 235, 0.33);
                                border-left: 3px solid $yellow;
                                padding: 7px;
                                font-size: 15px;
                                cursor: pointer;
                                text-align: left;

                                span{
                                    &:nth-child(1){
                                        display: block;
                                        font-family: $nasdaqFontSemibold;
                                        color: $grayDark;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.events{
                height: calc(100% - 75px);
                overflow: auto;
                position: relative;
                overflow-x: hidden !important;

                ul{
                    &.hours-row{
                        li{
                            &.current-timeline{
                                position: absolute;
                                border-right: 0;
                                border-bottom: 1px dashed $badgeColorBackground;
                                height: 0;
                                width: 100%;
                                z-index: 1;
                            }

                            &.current-timeline:before{
                                content: '';
                                height: 6px;
                                width: 6px;
                                border-radius: 5px;
                                background: $badgeColorBackground;
                                display: block;
                                top: -2.5px;
                                left: -9px;
                                position: relative;
                            }

                            titl{
                                left: -49px;
                                top: -9px;
                                position: relative;
                                width: 39px;
                                display: block;
                                font-size: 13.5px;
                                text-align: right;
                                font-family: $nasdaqFontRegular;
                                color: $grayMid;
                            }
                            line{
                                border-bottom: 1px dashed $grayLighter;
                                width: 100%;
                                display: block;
                                position: absolute;
                                margin-top: 5px;
                            }

                            &:nth-child(2){
                                line{
                                    margin-top: 24px;
                                }
                                titl{
                                    display: none;
                                }
                            }
                        }
                    }

                    &:not(.hours-row){
                        position: relative;
                    }

                    li{
                        &:last-child{
                            height: 0;
                            border: 0;
                        }

                        event{
                            width: 100%;
                            display: block;
                            position: relative;
                            float: left;
                            z-index: 2;
                            padding: 0 2px;

                            > div{
                                height: 100%;
                                background: rgba(234, 234, 235, 0.33);
                                border-top: 3px solid $yellow;
                                padding: 11px 7px;
                                font-size: 15px;
                                cursor: pointer;

                                span{
                                    &:nth-child(1){
                                        display: block;
                                        font-family: $nasdaqFontSemibold;
                                        color: $grayDark;
                                        max-width: 100%;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                    }
                                    &:nth-child(2){
                                        font-size: 13px;
                                        position: relative;
                                        top: -2px;
                                        color: $gray;
                                        height: calc(100% - 45px);
                                        overflow: hidden;
                                        display: inline-block;
                                        width: 100%;
                                    }
                                    &:nth-child(3){
                                        display: block;
                                        position: absolute;
                                        bottom: 12px;
                                        left: 12px;
                                        font-size: 12.5px;
                                        font-weight: 500;
                                        color: #6b6d75;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        width: calc(100% - 20px);
                                    }
                                }
                            }

                            &.small{
                                > div{
                                    padding: 0 7px;

                                    span{
                                        &:nth-child(2),
                                        &:nth-child(3){
                                            display: none;
                                        }
                                    }
                                }
                            }

                            &.medium{
                                > div{
                                    span{
                                        &:nth-child(2){
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .mCSB_scrollTools{
                    right: 0;
                }
            }

            .event-box{
                width: calc(100% - 80px);
                margin-left: 80px;
            }
        }

        ul{
            width: 14.26%;
            height: 100%;
            display: inline-block;

            &.current{
                background: rgba(233, 248, 255, 0.5);
            }

            &.hours-row{
                width: 80px;
                display: block;
                float: left;

                li{
                    margin-left: 55px;
                }
            }

            li{
                display: block;
                height: 50px;
                border-bottom: 1px solid $grayLighter;
                border-right: 1px solid $grayLighter;

                titl{
                    display: block;
                }
            }
        }
    }

}
input::-ms-clear{
	display: none;
}
.bv-placeholder-label{
	display: inline;
	position: relative;
}
.bv-placeholder-label span{
	position: absolute;
	top: 15px;
	margin-left: 10px;
	font-weight: 400;
	color: #9B9B9B;
	white-space: nowrap;
}
.ie .bv-create-folder .bv-modal-body .bv-placeholder-label span{
	top:-2px;
} 
.ie .bv-login-form .bv-placeholder-label span{
	font-size: 20px;
	letter-spacing: 2px;
	top: -5px;
}

.ie .bv-conf-settings .modal-dialog { 
  	position: absolute;
 	margin: auto;
    top: calc(50% - 300px);
    bottom: 0;
    left: 0;
    right: 0;
    transform: translate(0px, 0px) !important;
 }
.ie #inboxRightSection .bv-hide-show-content-arrow {
	position: static;
}
.ie .messagesMain {
	overflow: hidden;
} 

.bv-modal-window-new-attachment .bv-placeholder-label span{
	top: 0px;
}

.ie .bv-modal-window-new-attachment .modal-dialog{
	-ms-transform:  translate(-50.35%,-50.3%) !important;
}

.ie .bv-module-row-icon-div svg{
    height: 18px;
}

.ie java-check,
.ie [applet-directive]{
   display: none;
}

/* Surface styles */

body{

    &.bv-mobile{

        &.bv-surface{
            -ms-content-zooming: none;

            .bv-permissions .modal-dialog{
                height:inherit;
                width: 520px;
            }
            .bv-modal-window-compose-new-message .modal-dialog{
                height: inherit;
                width: 910px;
            }
            .bv-modal-window-repository-replace-file .modal-dialog{
                height: inherit;
                width: 450px;
            }
            .bv-version-tracker-dialog .modal-dialog{
                height: inherit;
                width: 600px;
            }
            .bv-audit-trail-dialog .modal-dialog{
                height: inherit;
                width: 600px;
            }
            .bv-copy-link .modal-dialog{
                height: inherit;
                width: 550px;
            }
            .bv-addToUserFavorites-dialog .modal-dialog{
                height: inherit;
                width: 600px;
            }
            .addToNewItemsDialog .modal-dialog{
                height: inherit;
                width: 370px;
            }
            .bv-rules-dialog .modal-dialog{
                height: inherit;
                width: 340px;
            }
            .bv-doc-prop .modal-dialog{
                height: inherit;
                width: 970px;
            }
            .bv-alert-item-list-dialog .modal-dialog{
                height: auto;
            }
            .bv-challenge-questions .modal-dialog{
                width: 355px;
                height: 308px;
            }
            .bv-modal-window-new-attachment  .modal-dialog
            {
                width:50% !important;
                height: inherit;
            }
            .bv-add-comments .modal-dialog{
                width: 600px !important;
                height: auto;
            }
            .bv-confirm .modal-dialog{
                height: auto !important;
            }
            .bv-running-conf-list-dialog .modal-dialog{
                height:	auto!important;
                width: inherit!important;
            }
            .bv-event-presentation .modal-dialog{
                    width: 950px!important;
                    height: auto!important;
            }
            .bvhome .bv-mobile-menu .dropdown-menu{
                top: 47px;
            }
            .bvhome .bv-mobile-menu .dropdown-menu{
                position: -ms-page !important;
            }
            .bv-search-results-mobile-backdrop{
                z-index: 99 !important;
            }
            .bv-search-results-mobile{
                z-index: 100 !important;

                .bv-modal-body{
                    height: calc(100% - 45px) !important;
                }
            }
            .bv-events .modal-dialog{
                width: 340px;
                height: 360px;
            }
            .bv-events .modal-content{
                height: 360px;
            }
            .bv-events .bv-modal-body > .bv-scrollbar{
                overflow: inherit;
            }
            .bv-modal-window-new-attachment .modal-content.bv-default-fill-color-white > div{
                height: calc(100% - 67px);
            }
            .bv-modal-window-new-attachment .attachment-list{
                height: 100%;
            }
            .bv-modal-window-new-attachment .attachment-list .treeDoc
            {
                overflow-y:inherit!important;
            }
            /* > .viewerDialogClass{
                zoom: 0;
                height: 97% !important;
                width: 98% !important;
            } */
            .bv-modal-window-new-attachment .documentsList ul li .bv-repository-checkbox{
                display: block;
            }
            /* .bv-modal-window-new-attachment .documentsList ul li.folder .bv-repository-checkbox{
                display: none;
            }
             */
            .bv-alert-item-list-dialog .bv-scrollbar
            {
                overflow-x:hidden;
            }

            .bv-alert-item-list-dialog .bv-item-list-main .bv-scrollbar
            {
                padding-right:20px;
            }
            /* .bv-mobile.bv-surface .bv-meeting-cards .bv-slider > ul > li{
                width: 31.7% !important;
                margin: 20px 0.8% !important;
            } */
            .chart-div
            {
                height: 90% !important;
            }
            .chart-div > .approvalsPieChart.bv-scrollbar
            {
                overflow:inherit!important;
            }

            /* .bv-compose-message-screen{
                min-height: auto!important;
                max-height: none!important;
                height: calc(100% - 315px);
            }*/
            .bv-compose-message-screen .outerComments1
            {
                overflow: auto!important;
                height:inherit!important;
            }
            .bv-mobile.bv-surface .bv-mobile-compose-message to, .bv-mobile-compose-message subject, .bv-mobile-compose-message attachments
            {
                color:#333;
            }
            .bv-mobile-compose-message .bv-slider > div.bv-slider-right-btn
            {
                right:6px;
            }
            .bv-mobile-compose-message .bv-slider > div.bv-slider-left-btn
            {
                left:10px;
            }
            .bv-mobile-compose-message .bv-slider > div
            {
                top:calc(50% - 25px) !important
            }
            .bv-mobile-compose-message .bv-slider > ul > li
            {
                width:16.50%!important;
            }
            .bv-conf-attendee-container-div,
            .bv-conf-attendee-container-div .bv-scroll-list{
                height: 100%;
            }
            .bv-conf-attendee-display-div .bv-scroll-list > .scroll-list-items{
                height: calc(100% - 85px);
            }
            .bv-conf-attendee-display-div{
                height: calc(100% - 20px);
            }
            .bv-conf-attendee-col1-row1{
                height: calc(100% - 70px);
            }
            .bv-conf-attendee .modal-dialog,
            .bv-conf-attendee .modal-dialog .modal-content{
                width: 510px !important;
                height: auto !important;
            }
            .bv-conf-details .modal-dialog,
            .bv-conf-details .modal-dialog .modal-content{
                width: 570px !important;
                height: 560px !important;
            }
            .bv-search-results-mobile .modal-dialog,
            .bv-search-results-mobile .modal-dialog .modal-content{
                width: 600px !important;
                height: 600px !important;
            }
            .bv-mobile-compose-message .modal-dialog,
            .bv-mobile-compose-message .modal-dialog .modal-content{
                width: 800px !important;
                height: 600px !important;
            }
            .bv-context-menu-arrow{
                zoom: 1.5;
            }
            /* .bv-mobile-compose-message wrap section:last-child */
            /* { */
            /* 	height:calc(100% - 316px)!important; */
            /* } */

            /* .bv-mobile-compose-message to
            {
                width:6%;
            }

            .bv-mobile-compose-message .ui-select-container
            {
                width:90%;
            }

            .bv-mobile-compose-message subject
            {
                width: 6%;
            }
            .bv-mobile-compose-message subjectinput
            {
                width: 90%;
            } */
            .bv-messages-mobile .inboxItemsGrid li > div{
                touch-action: pan-y;
            }
            /*
            .portalUserListContainer {
                height: calc(100% - 370px);
            }

            .portalUserListContainerBig {
                height: calc(100% - 370px);
            }

            .groupsBox {
                height: calc(100% - 370px);
            }
            */
            .bv-mobile-compose-message attachments > btn{
                    padding: 0px 0px 0px 15px;
            }
            .approve-responses-no-signature{
                height: calc(100% - 230px) !important;
            }
            .bv-compose-message-screen .compose-notice{
                bottom: 0px !important;
            }
            .bv-scrollbar{
                -ms-overflow-style: -ms-autohiding-scrollbar;
            }
            .bv-survey-userAnsweres-preview-modal .modal-dialog{
                height: inherit !important;
                width: inherit !important;
            }
            .bv-signature-dialog .modal-dialog{
                height: auto !important;
                width: 30% !important;
            }
            .teamUserProfileDetails{
                overflow-y: auto !important;
            }
            .groupsBox{
                overflow-y: auto !important;
            }
            .groupsBox .groupHeaderOpen{
                max-width: none!important;
            }
            .bv-mobile-compose-message .bv-default-fill-color-blue-plusIcon{
                height: 22px !important;
                width: 22px !important;
            }
            .bv-modal-window-compose-new-message .bv-compose-plusIcon-span{
                width: 25px !important;
            }
            .bv-compose-message-screen .leftScreen{
                width:69%;
            }
            .permissionContainer{
                padding-bottom:15px;
            }
        }
    }
}

.bv-compose-message-screen .rightScreen{
	width:28%;
	max-height: 445px;
    min-height: 445px;
	float: right;
	padding-left:10px;
	padding-right: 3px;
	border-left: 1px solid #DDDDDD;
}


@media screen, handheld, print{
	.bv-mobile .approval-attachments-row .bv-slider > div
	{
		top: calc(50% - 18px)!important;
	}
	.bv-mobile.bv-surface .approval-attachments-row .bv-slider > ul
	{ 
		margin: 0px 45px!important; 
	} 
	.bv-mobile.bv-surface .approval-attachments-row .bv-slider > div.bv-slider-left-btn
	{ 
 		transform: scale(2.0)!important; 
	} 
 	.bv-mobile.bv-surface .approval-attachments-row .bv-slider > div.bv-slider-right-btn
 	{ 
 		transform: scale(2.0)!important; 
 	}
	/*.bv-mobile.bv-surface .bv-meeting-cards .bv-slider > ul
	{
		padding:0px 0px 65px!important;
	}*/
	.bv-mobile.bv-surface .bv-compose-message-screen .outer
	{
		height:100%!important;
	}
	.bv-mobile.bv-surface .bv-mobile-support-reset-app-data
	{
		width:inherit !important;
	}
	.bv-mobile.bv-surface .connectedIconClass, .bv-mobile.bv-surface .notAvailableIconClass
	{
		padding: 0 9px;
	}
	.bv-mobile.bv-surface .userProfileDialog .modal-dialog,
	.bv-mobile.bv-surface .userProfileDialog .modal-dialog .modal-content
	{
		height: auto!important;
		width: auto!important;
	}
}

@media screen and (max-height: 1060px) and (min-width: 1900px) {
	.bv-mobile.bv-surface .dropdown-menu > li > a{
		padding: 12px 20px !important;
	}
}

@media screen and (max-height: 1500px) and (max-width: 2300px) {
	.bv-mobile.bv-surface > div{
		zoom: 1.5;
		height: 66.65% !important;
		width: 66.65% !important;
	}
	.bv-mobile.bv-surface > .viewerDialogClass{
	 	height: 65.45% !important;
		width: 65.45% !important;
	 }
	  
	.bv-mobile.bv-surface .bvhome .bv-mobile-menu .dropdown-menu{
		zoom: 1.5;
		left: calc(100% - 345px);
	}
	
	.bv-mobile.bv-surface .rightClickMenu,
	.bv-mobile.bv-surface .bv-context-menu-arrow-box{
		zoom: 1.5;
	}
	.bv-mobile.bv-surface .bv-context-menu-arrow:before{
		left: -10.2px;
	}
	.bv-mobile.bv-surface .bv-context-menu-arrow:after{
		left: -8.2px;
		top: 2px;
	}
	.bv-mobile.bv-surface .bv-modal-window-new-attachment
	{
		height: calc(66.65% + 50px) !important;
	}
	.bv-mobile.bv-surface .bv-modal-window-new-attachment .modal-dialog
	{
		top:45% !important;
	}
	.bv-mobile.bv-surface .newItemsMain .bv-slider > ul > li{
	  	margin: 67px 3.3% !important;
	  	height: calc(100% - 115px);
	}
	/* .bv-mobile.bv-surface .bv-mobile-compose-message attachments
	{
		width:auto;
		margin:0px 555px;
	} */
	.bv-mobile.bv-surface .bv-conf-attachment-row .bv-slider > ul {
		margin: 0px 4.36%;
	}
	.bv-mobile.bv-surface .bv-conf-attachment-row .bv-slider .bv-slider-items-2 > li{
		width: 43.3%;
	    margin: 130px 3.3%;
	    height: calc(100% - 185px);
	}
	.bv-mobile.bv-surface .expandHigh .bv-conf-attachment-row .bv-slider .bv-slider-items-2 > li{
		margin: 70px 3.3%;
		height: calc(100% - 55px);
	}
	.bv-mobile.bv-surface .bv-conf-attachment-row .bv-conf-attachment-name{
		top: 0px;
	}
	.bv-mobile.bv-surface .expandLow .bv-conf-attachment-row .bv-slider > div.bv-slider-right-btn{
		right: 10px;
	}
	.bv-mobile.bv-surface .expandLow .bv-conf-attachment-row .bv-slider > div.bv-slider-left-btn{
		left: 9px;
	}
	.bv-mobile.bv-surface .expandHigh .bv-meeting-cards .bv-slider > ul > li{
		width: 375px !important;
	}
	.bv-surface.bv-mobile .bv-modal-window-compose-new-message .modal-dialog
	{
		height: calc(66.65% + 10px) !important;
	}
	.bv-mobile.bv-surface .bv-survey-userAnsweres-preview-modal .modal-dialog
	{
		height: calc(66.65% + 150px) !important;
	}
	.bv-mobile.bv-surface .userSurveyPreviewDIV .userPreviewTopRight  .bv-slider > ul
	{ 
		margin: 0px 20px !important; 
		overflow-x: hidden !important;
	} 
	.bv-mobile.bv-surface .userSurveyPreviewDIV .userPreviewTopRight .bv-slider > div.bv-slider-left-btn
	{ 
 		transform: scale(2.0)!important;
 		left: 7px !important;
	} 
 	.bv-mobile.bv-surface .userSurveyPreviewDIV .userPreviewTopRight .bv-slider > div.bv-slider-right-btn
 	{ 
 		transform: scale(2.0)!important;
 		right: 7px !important;
 	}
 	.bv-mobile.bv-surface .survey-attachments-row .bv-slider > ul
	{ 
		margin: 0px 40px !important; 
		overflow-x: hidden !important;
	} 
	.bv-mobile.bv-surface .survey-attachments-row .bv-slider > div.bv-slider-left-btn
	{ 
 		transform: scale(2.0)!important;
 		left: 7px !important;
 	} 
 	.bv-mobile.bv-surface .survey-attachments-row .bv-slider > div.bv-slider-right-btn
 	{ 
 		transform: scale(2.0)!important;
 		right: 7px !important;
 	}
 	.bv-mobile.bv-surface .survey-attachments-row .bv-slider > div
 	{
 		top: calc(50% - 18px) !important;
 	}
 	.bv-mobile.bv-surface .bv-survey-question-top-right .survey-attachments-row .bv-slider > ul
	{ 
		margin: 0px 25px !important; 
		overflow-x: hidden !important;
	} 
 	.bv-mobile.bv-surface .survey-attachments-row-sections .bv-slider > ul
	{ 
		margin: 0px 45px !important;
	} 
 	.bv-mobile.bv-surface .survey-attachments-row-sections .bv-slider > div.bv-slider-left-btn
	{ 
 		transform: scale(2.0)!important;
 	} 
 	.bv-mobile.bv-surface .survey-attachments-row-sections .bv-slider > div.bv-slider-right-btn
 	{ 
 		transform: scale(2.0)!important;
 	}
 	.bv-mobile.bv-surface .survey-attachments-row-sections .bv-slider > div
 	{
 		top: calc(50% - 18px) !important;
 	}
 	.bv-mobile.bv-surface .survey-second-section .bv-cards-content .bv-cards survey-section-cards .bv-slider > div.bv-slider-left-btn
	{ 
 		transform: scale(1.0)!important;
 		left: 7px !important;
 	} 
 	.bv-mobile.bv-surface .survey-second-section .bv-cards-content .bv-cards survey-section-cards .bv-slider > div.bv-slider-right-btn
 	{ 
 		transform: scale(1.0)!important;
 		right: 7px !important;
 	}
 	.bv-mobile.bv-surface .userPreviewSectionDetails .bv-slider > ul
	{ 
		margin: 0px 20px !important; 
		overflow-x: hidden !important;
	} 
	.bv-mobile.bv-surface .userPreviewSectionDetails .bv-slider > div.bv-slider-left-btn
	{ 
 		transform: scale(2.0)!important;
 		left: 7px !important;
 	} 
 	.bv-mobile.bv-surface .userPreviewSectionDetails .bv-slider > div.bv-slider-right-btn
 	{ 
 		transform: scale(2.0)!important;
 		right: 7px !important;
 	}
	.bv-mobile.bv-surface .userPreviewResponse .bv-slider > ul
	{ 
		margin: 0px 25px !important; 
		overflow-x: hidden !important;
	} 
	.bv-mobile.bv-surface .userPreviewResponse .bv-slider > div.bv-slider-left-btn
	{ 
 		transform: scale(2.0)!important;
 		left: 7px !important;
 	} 
 	.bv-mobile.bv-surface .userPreviewResponse .bv-slider > div.bv-slider-right-btn
 	{ 
 		transform: scale(2.0)!important;
 		right: 7px !important;
 	}
 	.bv-mobile.bv-surface .survey-main .survey-second-section .bv-slider > div.bv-slider-left-btn
 	{
 		left: 7px !important;
 	}
 	.bv-mobile.bv-surface .survey-main .survey-second-section .bv-slider > div.bv-slider-right-btn
 	{
 		right: 7px !important;
 	}
	
}

@media screen and (min-height: 1500px) and (min-width: 2300px) {
	.bv-mobile.bv-surface > div{
	    zoom: 2;
		height: 50% !important;
		width: 50% !important;
	}
	.bv-mobile.bv-surface > .viewerDialogClass{
	 	height: 49% !important;
		width: 49% !important;
	}
	.bv-mobile.bv-surface .bvhome .bv-mobile-menu .dropdown-menu{
		zoom: 2;
		left: calc(100% - 435px) !important;
	}
	
	.bv-mobile.bv-surface .rightClickMenu,
	.bv-mobile.bv-surface .bv-context-menu-arrow-box{
		zoom: 2;
	}
	.bv-mobile.bv-surface .bv-context-menu-arrow:before{
		left: -11.5px;
	}
	.bv-mobile.bv-surface .bv-context-menu-arrow:after{
		left: -9.5px;
		top: 2px;
	}
	.bv-mobile.bv-surface .bv-modal-window-new-attachment
	{
		height: calc(50% + 150px) !important;
	}
	.bv-mobile.bv-surface .bv-modal-window-new-attachment .modal-dialog
	{
		top:45% !important;
	}
	.bv-mobile.bv-surface .newItemsMain .bv-slider > ul > li{
	  	margin: 106px 3.3% !important;
	  	height: calc(100% - 190px) !important;
	}
	.bv-mobile.bv-surface .bv-meeting-cards .bv-slider > ul > li{
		width: 320px !important;
	}
	/*.bv-mobile.bv-surface .bv-mobile-compose-message attachments
	{
		width:auto;
		margin:0px 600px;
	}*/
	.bv-mobile.bv-surface .bv-conf-attachment-row .bv-slider > ul {
		margin: 0px 4.36%;
	}
	.bv-mobile.bv-surface .bv-conf-attachment-row .bv-slider .bv-slider-items-2 > li{
		width: 43.3%;
	    margin: 160px 3.3%;
	    height: calc(100% - 260px);
	}
	.bv-mobile.bv-surface .expandHigh .bv-conf-attachment-row .bv-slider .bv-slider-items-2 > li{
		margin: 100px 3.3%;
		height: calc(100% - 130px);
	}
	.bv-mobile.bv-surface .bv-conf-attachment-row .bv-conf-attachment-name{
		top: 0px;
	}
	.bv-mobile.bv-surface .expandLow .bv-conf-attachment-row .bv-slider > div.bv-slider-right-btn{
		right: 10px;
	}
	.bv-mobile.bv-surface .expandLow .bv-conf-attachment-row .bv-slider > div.bv-slider-left-btn{
		left: 9px;
	}
	.bv-mobile.bv-surface .expandHigh .bv-meeting-cards .bv-slider > ul > li{
		width: 405px !important;
	}
	.bv-surface.bv-mobile .bv-modal-window-compose-new-message .modal-dialog
	{
		height: calc(66.65% + 10px) !important;
	}
	.bv-mobile.bv-surface .bv-survey-userAnsweres-preview-modal .modal-dialog
	{
		height: calc(66.65% + 150px) !important;
		width: calc(66.65% - 50px) !important;
	}
	/*   */
	
	.bv-mobile.bv-surface .userSurveyPreviewDIV .userPreviewTopRight  .bv-slider > ul
	{ 
		margin: 0px 25px !important; 
		overflow-x: hidden !important;
	} 
	.bv-mobile.bv-surface .userSurveyPreviewDIV .userPreviewTopRight .bv-slider > div.bv-slider-left-btn
	{ 
 		transform: scale(2.0)!important;
 		left: 7px !important;
	} 
 	.bv-mobile.bv-surface .userSurveyPreviewDIV .userPreviewTopRight .bv-slider > div.bv-slider-right-btn
 	{ 
 		transform: scale(2.0)!important;
 		right: 7px !important;
 	}
 	.bv-mobile.bv-surface .survey-attachments-row .bv-slider > ul
	{ 
		margin: 0px 40px !important; 
		overflow-x: hidden !important;
	} 
	.bv-mobile.bv-surface .survey-attachments-row .bv-slider > div.bv-slider-left-btn
	{ 
 		transform: scale(2.0)!important;
 		left: 7px !important;
 	} 
 	.bv-mobile.bv-surface .survey-attachments-row .bv-slider > div.bv-slider-right-btn
 	{ 
 		transform: scale(2.0)!important;
 		right: 7px !important;
 	}
 	.bv-mobile.bv-surface .survey-attachments-row .bv-slider > div
 	{
 		top: calc(50% - 18px) !important;
 	}
 	.bv-mobile.bv-surface .bv-survey-question-top-right .survey-attachments-row .bv-slider > ul
	{ 
		margin: 0px 45px !important; 
		overflow-x: hidden !important;
	} 
 	.bv-mobile.bv-surface .survey-attachments-row-sections .bv-slider > ul
	{ 
		margin: 0px 45px !important;
	} 
 	.bv-mobile.bv-surface .survey-attachments-row-sections .bv-slider > div.bv-slider-left-btn
	{ 
 		transform: scale(2.0)!important;
 	} 
 	.bv-mobile.bv-surface .survey-attachments-row-sections .bv-slider > div.bv-slider-right-btn
 	{ 
 		transform: scale(2.0)!important;
 	}
 	.bv-mobile.bv-surface .survey-attachments-row-sections .bv-slider > div
 	{
 		top: calc(50% - 18px) !important;
 	}
 	.bv-mobile.bv-surface .survey-second-section .bv-cards-content .bv-cards survey-section-cards .bv-slider > div.bv-slider-left-btn
	{ 
 		transform: scale(1.0)!important;
 		left: 7px !important;
 	} 
 	.bv-mobile.bv-surface .survey-second-section .bv-cards-content .bv-cards survey-section-cards .bv-slider > div.bv-slider-right-btn
 	{ 
 		transform: scale(1.0)!important;
 		right: 7px !important;
 	}
 	.bv-mobile.bv-surface .userPreviewSectionDetails .bv-slider > ul
	{ 
		margin: 0px 25px !important; 
		overflow-x: hidden !important;
	} 
	.bv-mobile.bv-surface .userPreviewSectionDetails .bv-slider > div.bv-slider-left-btn
	{ 
 		transform: scale(2.0)!important;
 		left: 7px !important;
 	} 
 	.bv-mobile.bv-surface .userPreviewSectionDetails .bv-slider > div.bv-slider-right-btn
 	{ 
 		transform: scale(2.0)!important;
 		right: 7px !important;
 	}
	.bv-mobile.bv-surface .userPreviewResponse .bv-slider > ul
	{ 
		margin: 0px 25px !important; 
		overflow-x: hidden !important;
	} 
	.bv-mobile.bv-surface .userPreviewResponse .bv-slider > div.bv-slider-left-btn
	{ 
 		transform: scale(2.0)!important;
 		left: 7px !important;
 	} 
 	.bv-mobile.bv-surface .userPreviewResponse .bv-slider > div.bv-slider-right-btn
 	{ 
 		transform: scale(2.0)!important;
 		right: 7px !important;
 	}
 	.bv-mobile.bv-surface .survey-main .survey-second-section .bv-slider > div.bv-slider-left-btn
 	{
 		left: 7px !important;
 	}
 	.bv-mobile.bv-surface .survey-main .survey-second-section .bv-slider > div.bv-slider-right-btn
 	{
 		right: 7px !important;
 	}
	
	
}
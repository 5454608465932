@import '../../utils/variables';

/*Color Branding CSS*/
.cp-container {
    width: auto;
    position: relative;
    box-sizing: border-box;
}

.cp-container .cp-transparency,
.cp-container .cp-swatches .cp-swatch {
}

.cp-container .cp-slider,
.cp-container .cp-preview {
    width: auto;
    height: 16px;
    margin: 0px 20px 10px 20px;
    position: relative;
}

.cp-container .cp-slider {
    cursor: ew-resize;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cp-container .cp-preview {
    height: 50px;
}

.cp-container .cp-slider span,
.cp-container .cp-preview input {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    font-family: Helvetica Neue,Arial,sans-serif;
    font-size: 26px;
    font-weight: 200;
    margin: 0px 0px;
    border-radius: 4px;
    letter-spacing: 3px;
    border: 1px solid lightgray;
}

.cp-container .cp-preview input#preview {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 200;
    margin: 0px 0px;
    border-radius: 4px;
    letter-spacing: 2px;
}

.cp-container .cp-preview input {
    line-height: 55px;
    padding: 0;
    border: 0;
    outline: none;
    box-shadow: none;
}
.cp-container .cp-marker {
    position: absolute;
    display: block;
    width: 23px;
    height: 25px;
    margin-left: -11px;
    top: -6px;
    background: url(#{$resources-path}/assets/images/slider2.png);
}

.cp-container.cp-unconvertible-cie-color .cp-slider.cp-cielightness .cp-marker,
.cp-container.cp-unconvertible-cie-color .cp-slider.cp-ciechroma .cp-marker,
.cp-container.cp-unconvertible-cie-color .cp-slider.cp-ciehue .cp-marker {
    background: url(#{$resources-path}/assets/images/slider2.png);
}

.cp-container .cp-swatches ul {
    width: auto;
    margin: -7px -7px 0 0;
    padding: 0;
    list-style-type: none;
}

.cp-container .cp-swatches li {
    float: left;
    margin: 7px 7px 0 0;
}

.cp-swatches button {
    float: left;
    margin: 7px 7px 0 0;
    width: 26px;
    height: 26px;
    display: inline-block;
    font-size: 12px;
    padding: 0;
}

.cp-container .cp-swatch {
	border-radius: 2px;
    width: 26px;
    height: 26px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    outline: none;
    padding: 0;
}

.cp-container .cp-swatch span {
    display: block;
    width: 100%;
    height: 100%;
}

.cp-container .cp-swatches .cp-swatch.actual {
    -webkit-box-shadow: 0 0 5px rgb(250, 128, 0);
    -moz-box-shadow: 0 0 5px rgb(250, 128, 0);
}

.cp-popover-container .popover {
    max-width: 1000px;
}

.popover-content .cp-container {
    width: 263px;
}

.popover-content .cp-container.cp-container-sm {
    width: 208px;
}

.popover-content .cp-container.cp-container-sm .cp-swatch,
.popover-content .cp-container.cp-container-sm .cp-swatches button {
    width: 23px;
    height: 23px;
}

.popover-content .cp-container.cp-container-lg {
    width: 369px;
}

.popover-content .cp-container.cp-container-lg .cp-swatch,
.popover-content .cp-container.cp-container-lg .cp-swatches button {
    width: 27px;
    height: 27px;
}

.dropdown-menu{
	left:-123px;

}
/*END Color Branding CSS*/

.configLabel{
	font-size: 15px;
	font-weight: bold;
	margin-left: 20px;
	padding-top: 15px;
	padding-bottom: 10px;	
}

/* .noselect{
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	padding-top: 15px;
  	padding-bottom: 8px;
}  */

#hslflat {
	width:33%;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    display:inline-block;
}
#hslflatText {
	width:33%;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    display:inline-block;
}
#hslflatMenu {
	width:32%;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    display:inline-block;
}

li#hsl .cp-container .cp-sliders p.pThreeLow{
/* 	color: #707070; */
	margin: 8px 0px 1px 22px;
	font-size: 13px;
	font-weight: lighter;
	text-align: left;
}

#cTitle {
white-space: nowrap;
margin: 17px 0px 9px 0px;
}

#leftColor {
	width: 120px;
	height: 35px;
	border: 1px solid #000;
    font-size: 16px;
    font-weight: 200;
    color: #707070;
    text-align:center;
    letter-spacing:1px;
    display: -webkit-inline-box;
    display: -moz-box;
}
#rightColor {
	width: 120px;
	height: 35px;
	border: 1px solid #000;
    font-size: 16px;
    font-weight: 200;
    color: #707070;
    text-align:center;
    letter-spacing:1px;
    display: -webkit-inline-box;
    display: -moz-box;
}
#middleColor {
	width: 120px;
	height: 35px;
	border: 1px solid #000;
    font-size: 16px;
    font-weight: 200;
    color: #707070;
    text-align:center;
    letter-spacing:1px;
    display: -webkit-inline-box;
    display: -moz-box;
}
ul li#hsl {
	width:100%;
	height:190px;
	display: -webkit-box;
	display: inline-flex;
}
ul li span#slider{
	margin: 0px;
	padding: 0px;
	height: 13px;	
}
ul li#preview {
	width:100%;
	height:50px;
	cursor: default;
}
ul li#BrandingColor{
	height:39px;
	cursor: auto;
	font-size: 20px;
	color: #515151;
	padding: 16px 0px;
	font-weight: bold;
	letter-spacing: 1px;
	margin-bottom: -15px;
}
.listBtn{
	display: block;
	background: rgb(0, 72, 118);
	width: 135px;
	height: 20px;
	font-size: 10px;
	font-weight: 300;
	text-align: center;
	text-decoration: none;
	line-height: 18px;
	color: #fff;
	position: absolute;
	right: 20px;
}

.listBtn.resetBtn{
	right:165px;
	background: #515151;	
}
.bv-width-800 .ngdialog-content{
	width:800px !important;
}
.bvBackgroundColor{
	background-color: $nasdaqBlue;
}
.bvTextColor{
	color:#fff;
}
.bvMenuColor{
	color:#515151;
}

.bv-branding-labels{
	margin: 10px 0px;
	height: 25px;
	font-size: 14px;
	margin-left: 20px;
	clear:left;
}
.logoLabelCurrent{
	margin: 5px 60px;
	font-weight: 400;
	font-size: 14px;
}
.logoLabelNew{
	margin: 0 0px;
	font-weight: 400;
	font-size: 14px;
    margin-left: 118px;
}
.uplodLogoSpan{
	margin-left: 20px;
	float:left;
}
.cp-sliders{
	color: rgb(0, 0, 0)!important;
}

.cp-sliders p{
	text-align:left;
	margin-left:20px;
	margin-bottom: 3px;
  	margin-top: 10px;
}
.uploadImgSvg{
	width: 65px;
	height: 65px;
	position: absolute;
	left: 70px;
	z-index: 1;
	top: 18px;
}

.brandingDialog .modal-dialog
{
	width: 808px;
	height: 610px;
}

.bv-branding-label {
	/* padding-bottom: 20px; */
    font-weight: bold;
    padding-top: 10px;
}

.bv-branding-preload {
	display:none; 
	position: relative; 
	top: -100px;
	background: #fff;
	border: solid 1px black;
	height: 102px;
	width:204px;
	z-index: 1;
	left: 1px;
}

.bv-branding-newlogo{
	position: relative;
	z-index: 20; 
	height: 98px; 
	margin: 0px auto; 
	display: block;
}

.bv-branding-logo {
	display: inline-block; 
	padding-bottom: 20px; 
	padding-left: 20px; 
    width: 330px;
	padding-top: 5px;
}
.bv-branding-input-margin{ 
	width: 100%;   
}
.bv-branding-dropdown{
	max-width: 310px;  
	display: inline-block;
	font-size: 16px;
  	font-weight: 400;
  	background-color: #e5e5e5 !important;
}
.bv-branding-logo-container{
	display: inline-block;
	vertical-align: top; 
    width: calc(100% - 360px);
    padding: 20px 80px;
}
.bv-branding-logo-img{
	width: 206px;
	height: 102px; 
	text-align: center; 
	border: solid 1px black;
	overflow: hidden;
}
.bv-branding-new-logo-container{
	width: 206px; 
	height: 100px; 
	padding-top: 40px; 
	background: #ccc;
}
.bv-branding-new-logo-label{
	position: relative; 
	float: left; 
	overflow: hidden; 
	height: 108px;
}
.horLine{
	width: 100%;
	height: 1px;
	border-bottom: 1px solid #ddd;
	margin: 10px 0px 0px;
}
.bv-branding-left-arrow{
	padding: 30px;
  	float: left;
  	width: 83px;
  	height: 97px;
}
.bv-branding-disabled{
	color:grey;
}
@import 'src/utils/variables';

.ie .bvhome{

    .bv-search-bar .bv-placeholder-label span{
        margin-left: 47px;
    }

    .filter-mobile-modules{
        .bv-placeholder-label span{
            padding-left: 30px; 
        }

    }
}
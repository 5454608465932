@import 'src/utils/variables';

.validate{
    position: relative;

    > span{
        display: none;
        letter-spacing: -0.1px;
        color: $red;
        font-size: 10px;
        position: relative;
        top: -5px;
    }

    &.triggered{
        > span{
            display: inline;
        }
    }
}

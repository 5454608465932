@import 'src/utils/variables';

.teamProfileFieldValue.ng-invalid {
   background:#fff0f0;
   border-color:#f00 !important;
 }
 .teamProfileFieldValue.ng-valid {
   background:white;
   border-color:$grayLight;
 }
 
 .secondaryEmails.ng-invalid {
   background:#fff0f0 !important;
   border-color:#f00 !important;
 }
 .secondaryEmails.ng-valid {
   background:white;
   border-color:$grayLight;
 }
 
  .newCustomFieldName.ng-invalid {
   background:#fff0f0 !important;
   border-color:#f00 !important;
 }
  .newCustomFieldName.ng-valid {
   background:white;
   border-color:$grayLight;
 }

.email-wizard {
    &.team-profile {
        input {
            border: 1px solid $grayLight;
            border-radius: 5px;
            padding: 5px;
            margin-top: 5px;
            margin-bottom: 20px;
        }
        & .email-warning {
            font-size: 12px;
            color: red;
        }

        & .teamUserProfileDetails {
            min-height: 0px;
            padding: 10px 100px 10px 100px;
        }

        & .teamProfileFieldNameDisplay {
            padding-top: 10px;
        }

        .bv-message-label {
            font-size: 14px;
            font-weight: 300;
        }
    }
}

.user-panel{

    &.dateTimeFormatDisplay {
        display: flex;

        &.timeFormatDisplay {
            flex: 1;
        }
    
        &.dateFormatDisplay {
            flex: 1;
        }
    }

    & .profileroles-multiselect-wrap {

        &.readonly {
            & .ui-select-container{
                & .select2-choices{
                    border: 0px;
                }
            }
        }

        & .select2-container-multi .select2-choices .select2-search-field {
            width: 100% !important;
        }

        & .ui-select-dropdown {
            width: 415px !important;
        }

        & ul{
            width: 415px !important;
            float: left;
            padding: 0 5px;

            &:last-child{
                width: 100%;
            }
        }

        & .select2-results {
            padding: 0px !important;
        }

        & .ui-select-container{
            width: 100%;
            & .select2-choices{
                box-shadow: none;
                background: none;
                border: 1px solid #d1d1d1;
                border-radius: 3px;
                max-height: 100000px;
            }
            & .ui-select-match-item{
                background: #00bce3;
                border-radius: 25px;
                color: #fff;
                height: 20px;
                font-size: 13px;
                padding: 5px 20px 0 5px;
                position: relative;
                text-transform: uppercase;
                font-family:'SourceSansProBold';
                & .select2-search-choice-close{
                    right: 15px;
                    top: 5px;
                    background-image: none !important;
                    position: absolute;
                    opacity: 0.5;
                    &::after{
                        transform: rotate(45deg);
                        position: absolute;
                        left: 15px;
                        content: ' ';
                        height: 8px;
                        width: 2px;
                        background-color: #fff;
                    }
                    &::before{
                        transform: rotate(-45deg);
                        position: absolute;
                        left: 15px;
                        content: ' ';
                        height: 8px;
                        width: 2px;
                        background-color: #fff;
                    }
                    &:hover {
                        opacity: 0.8;
                        background-image: none !important;
                    }
                }
            }

        }

    }

    .modal-dialog,
    .modal-content{
        width: 750px;
    }

    .bv-modal-blue-header{
        .bv-modal-btn-custom-box{
            display: inline-flex;

            icon.followed{
                color: #fff;
            }
        }

        .bv-modal-btn-custom-header{
            width: calc(100% - 50px);
            display: inline-flex;
        }
    }

    .bv-input-btn-box{
        button{
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3){
                background: none !important;
                color: red !important;
            }

            &:nth-child(1){
                right: 450px !important;
            }
            &:nth-child(2){
                right: 330px !important;
            }
            &:nth-child(3){
                right: 120px !important;
                width: 210px !important;
            }
        }
    }

    .team-profile{

        > div{
            padding: 30px;

            &.bv-input-btn-box{
                padding: 20px;
                top: -5px;
            }
        }

        .DportalUserListCircle{
            width: 210px;
            height: 210px;
            border-radius: 25px;
            margin: 0 45px 6px 0;

            .DportalUserImage{
                width: 210px;
                height: 210px;
            }
        }

        .teamUserProfileDetails{
            ul{
                width: 217px;
                float: left;
                padding: 0 5px;

                &:last-child{
                    width: 100%;
                }
            }
        }

        .teamProfileFieldNameDisplay{
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0px;

            .dateTimeFormInfo {
                width: auto;
                margin: 2px 0px 15px 0px;
                border-radius: 3px;
                border: solid 1px #d6d6d6;
                display: flex;

                & .left-bar {
                    border-radius: 3px 0px 0px 3px;
                    width: 8px;
                    max-height: 100%;
                    background-color: #00BCE3;
                    padding: 0px 10px 0px 0px;
                }

                span {
                    font-size: 14px;
                    font-family: Helvetica;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    color:#212121;
                    padding: 10px 0px 10px 0px;
                }

                & .info-icon {
                    padding: 12px 10px 0 10px;
                    color: #00BCE3;
                    font-size: 20px;
                }
                
            }
        }

        .teamProfileFieldDisplay{
            > div{
                position: relative;

                > span > svg{
                    right: 10px;
                }
            }

            &.newCustomField{
                span > svg{
                    margin-top: 15px;
                }
            }

            textarea{
                background: none;
                border: 1px solid #d5d6d8;
                border-radius: 5px;
                padding: 5px;
                font-size: 17.5px;
                letter-spacing: 0px;
                overflow: hidden;
                height: inherit;
            }

            &.secondary-emails{
                > div{
                    word-break: break-all;
                }
            }
        }

        input,
        .teamProfileFieldDisplay .edited{
            border: 1px solid $grayLight;
            border-radius: 5px;
            padding: 5px;
        }

        .teamProfileFieldValue{
            font-size: 17.5px;
            letter-spacing: 0px;
            overflow: hidden;
        }

        #cke_bioEdit{
            border: 1px solid $grayLight !important;
            border-radius: 5px;
            padding: 5px;
        }

        .bv-dropdown{
            button{
                    font-size: 17.5px;
                    letter-spacing: 0px;
                    overflow: hidden;
                    border: 1px solid #d5d6d8;
                    border-radius: 5px;
                    padding: 5px 10px 5px 5px;
                    width: 100%;
                    background: none;
                    text-align: left;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    .caret{
                        float: right;
                        top: 17px;
                        position: absolute;
                        right: 5px;
                    }
            }
        }

        #inlineCheckbox {
            font-weight: normal;
            font-size: 12px;
            vertical-align: middle;

            input[type="checkbox"] {
                margin: 0;
            }
        }
    }

    .user-tab{
        &.current-user{
            height: 395px;
        }
    }

    .group-tab{
        height: 395px;

        .groupsBox .groupHeader{
            max-width: none;
        }
    }

    .teamspace-tab{
        height: 395px;
        overflow: auto;

        .mCSB_scrollTools{
            right: 0 !important;
        }

        ul{

            li{
                width: 100%;
                border-bottom: 1px solid #eaeaeb;
                padding: 5px 8px 5px 5px;
                height: 40px;

                span{
                    display: inline-block;
                    padding-left: 10px;
                    font-size: 11.5px;
                    margin-top: 5px;
                }
            }
        }
    }
 }
 
 
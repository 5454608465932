
.dialogs{

    &.bv-modal-window-new-attachment{
        .btn-box{
            text-align: right;
            padding: 20px;
            display: none;
        }
    }

}
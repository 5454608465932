.preview-survey-dialog{
	 #description-container{
			    	overflow: auto;
			    }
			    .read-more-style {
			    	    display: none;
			    }
    .modal-dialog{
        width: 900px;

        .modal-content{
            height: 700px;
        }
    }

}
@import 'src/utils/variables';

.nasdaq{

    .dialogs {

        .input-btn{
            width: 78px;
            height: 49px;
            border: none;
            font-weight: 500;
            font-size: 12px;
            letter-spacing: 0.6px;
            line-height: 22px;
            color: red;
            background-color: transparent;
            outline: none !important;
            white-space: nowrap;
            overflow: hidden;
            border-bottom-right-radius: 5px;
            position: absolute;
            right: 90px;
            top: 0px;
        }

        .bv-input-btn-box,
        .bv-conf-settings-col2-row2-button-div{
            position: relative;
            z-index: 1;
            background: #fff;
            text-align: right;
            width: 100%;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-top: 1px solid $grayLighter;
            height: 50px;

            .bv-input-btn{
                @extend .input-btn;
            }

            .bv-default-background-color-blue{
                background-color: $green;
                right: 0px;
                color: #fff;
                &:disabled{
                    pointer-events: none;
                    opacity: 0.6;
                
                }
            }
            
            .bv-input-btn-0{
        		background-color: transparent;
    			color: red;
    			right: 120px;
        	}
        	.bv-input-btn-1{
        		width: 120px;
       		 }

        }
        
        .bv-conf-settings-col2-row2-button-div{

            .bv-input-btn{
                @extend .input-btn;

            }
        }

    }

    .active-windows{
      .bv-scroll-list{
        max-height: 400px;
        overflow: auto;
      }
    }
}
@import 'src/utils/variables';
@import 'src/utils/classes';

.nasdaq.bv-mobile{

.surveysMain {
     min-width: 100%;
 }
@media (max-width: 641px){
    .surveys-top-panel .top-panel{
        > div:nth-child(2){
            display: none;
        }
    }
    .survey-list{
        height: calc(100% - 20px);
    }
    .surveysMain {
        min-width: 100%;
        padding: 0;
        .surveys-section{
            display: block;
            padding: 5px 15px 15px;
            border: 1px solid #b6b7ba;
            .surveys-sectionOne{
               width: 100%;
                 .surveyName{
                    color: #1d1e25;
                    font-weight: bold;
                    font-size: 14px;
                    padding: 0;
                 }
            }
            .surveyType{
               display: none;
            }
            .surveyDue{
               width: 63%;
               margin-top: 0px;
               color: #000000;
               padding-top: 10px;
            }
            .bv-survey-openSurvey-icon-div{
               width: 100%;
               display: block;
               padding: 0;
               .ss-check{
                  display: none;
               }
               .progress{
                  display: inline-block;
                  width: 90%;
                  top: 0;
               }
            }
        } 
        .submitted-status{
            float:right;
            padding-top: 6px;
        }
        .survey-status-not-submitted-div{
            padding: 5px 0;
        }
        .survey-status-submitted{
            color: #2d303a;
            font-style: italic;
            margin-top: 3px;
            font-family: "HelveticaNeue-MediumItalic";
        }  
        .survey-status-not-submitted{
            font-style: italic;
            font-size: 14px;
            margin-top: 3px;
            font-family: "HelveticaNeue-MediumItalic";
        }    
    }
}
    
    #respondSurveyMain{
        .bv-cards-content .bv-slider > ul{
            padding: 0 0.1% 0 0;
        }
    }
    /*.surveys-sectionOne {
        width: 90%;
    }*/

    .survey-menu-icon {
       display: none !important;
    }

    .surveysListDIV .bv-context-menu-arrow-box {
        display: none !important;
    }

    .surveysMain .rightClickMenu {
      display: none !important;
    }
    .survey-main  {
        min-width:0px;
        max-width: 100%;
        width: 100%;
        overflow: visible;
    }

    #respondSurveyMain .bv-slider {
       overflow: visible;
    }

    #respondSurveyMain .bv-slider > div,
    #surveyConsumerSectionDIV .bv-slider > div{
        top: calc(59% - 50px) !important;
    }

    .survey-instructions {
        min-height: 0%;
        max-height:100%;
    }

    .survey-second-section {
        height: calc(100% - 435px);
        /*width: 100%;
        min-height: 80%;
        max-height: 100%;
        overflow-y: visible;
        overflow-x: hidden;*/
    }

    .survey-top-section, .bv-survey-question-top-section {
        width: 100%;
        /*max-height: 100%;*/
        /*min-height: 200px;*/
        height: 330px;
        overflow: auto;
    }
    .survey-top-left, .bv-survey-question-top-left {
        width: 100% !important;
        height: 100% !important;
        overflow: visible;
        border-right: none !important;
    }

    .survey-top-right, .bv-survey-question-top-right {
        width: 100% !important;
        height: 100% !important;
        overflow: visible;
    }

    .survey-bottom-section {
    /*
        height: 86px;
        position: fixed !important;
        bottom: 46px !important;
        max-width: 100%;
        min-height: 10px;
        height: 10px;
    */
    }

    .survey-section-cards .bv-slider > ul  {
       height: 100% !important;
    }

    .survey-section-cards .bv-slider > ul > li {
        width: 78%;
        margin: 20px 10%;
    }

    #respondSurveyMain .bv-cards-content>div:first-child {
       overflow: visible;
    }

    #respondSurveyMain{
        .surveyProgress div.bv-surveyProgress-left,
        .surveyProgress div.bv-surveyProgress-middle,
        .surveyProgress div.bv-surveyProgress-right{
            width: 33.33%;
            padding: 0 10px;
            margin: 0px;
            top: -9px;
        }
    }

    #surveyConsumerSectionDIV,
    #surveyConsumerQuestionDIV{
        .surveyProgress{
            button{
                margin: 0 15px;
                top: -9px;
                position: relative;
                line-height: 1.1em;

                &.bv-survey-question-mobile-finish-later-label{
                    width: 100px;
                    margin: 0 0 0 15px;
                    height: 28px;
                    display: inline;
                    background: black;
                }
            }

            .progress-bar-openSurvey-box{
                left: 0 !important;
                top: 0 !important;
                position: relative;
                width: calc(100% - 400px);
                display: inline-block !important;
            }
        }
    }

    /*
    #surveyConsumerQuestionDIV {
        position: fixed;
        bottom: 0px;
        height: 90% !important;
    }
    */

    #surveyConsumerQuestionDIV .surveyProgress div.bv-surveyProgress-right {
       padding-left: 4px;
    }

    #respondSurveyMain .surveyProgress,
    #surveyConsumerSectionDIV .surveyProgress,
    #surveyConsumerQuestionDIV .surveyProgress {
       padding-left: 0px;
       padding-right: 0px;
    }

    .surveyConsumerSectionDIVBottom {
        padding-top: 4px;
    }

    #respondSurveyMain{
        .surveyProgress .bv-input-btn {
            height: 28px;
            width: 100%;
            margin-left: 0px;
        }
    }

     #respondSurveyMain .surveyProgress .bv-surveyProgress-right .bv-input-btn {
       margin-left: 0%;

    }

    /*
     #surveyConsumerQuestionDIV .surveyProgress {
        margin-top: 2% !important;
    }
    */
    #surveyConsumerQuestionDIV .bv-section-bottom-footer {
        width: 100% !important;
    }

    /*
    #surveyConsumerQuestionDIV .bv-section-bottom-footer hr,
    #surveyConsumerSectionDIV .bv-section-bottom-footer hr {
       display: none !important;
    }

    #surveyConsumerQuestionDIV .questionProgressButton, #surveyConsumerQuestionDIV .finish-later-mobile {
       width: 100% !important;
    }
    */
    .bv-question-type-header-mobile {
      display:block !important;
      font-weight: 500;
      font-size: 14px;
      margin-left: 2%;
    }

    .bv-question-detail-div {
      min-height: 150px;
      height: 100%;
      overflow-y: visible;
      overflow-x: hidden;
    }

     .surveyConsumerSectionDIVTop {
        height: calc(100% - 65px);
    }

    #surveyConsumerSectionDIV,
    #surveyConsumerQuestionDIV{
        .bv-section-bottom-footer {
        /*
            width: 100% !important;
            height: 100px;

            .finish-later-mobile{
                float: right;

                .bv-survey-question-mobile-finish-later-label{
                    width: inherit;
                }
            }

            .progress-bar-openSurvey-box-mobile{
                margin: 0;
                top: 18px;
                left: 100px;
                right: 226px;
            }

            .bv-surveyProgress-middle{
                width: 105px;
                position: absolute;
                right: 100px;
            }

            .bv-surveyProgress-left,
            .bv-surveyProgress-right{
                margin: 0 15px;
            }
*/

            bottom: 0px !important;
            left: 0px !important;

            .surveyProgress{
                padding-left: 2%;

                button{
                    margin: 0 1% !important;
                    width: 30%;
                }

                .progress-bar-openSurvey-box{
                    position: absolute;
                    top: 41px !important;
                    width: 100%;
                    height: 20px;

                    span{
                        top: -6px;
                        position: relative;
                    }
                }
            }
        }

        .bv-question-details{
            padding: 15px;
            height: calc(100% - 265px);
        }
    }

     #surveyConsumerSectionDIV .bv-survey-question-back-arrow,
     #surveyConsumerSectionDIV .bv-section-bottom-right-arrow,
     #surveyConsumerQuestionDIV .bv-survey-question-back-arrow,
     #surveyConsumerQuestionDIV .bv-section-bottom-right-arrow{
       margin-top: 9px;
    }

    .survey_response_bottom_right_options {
        display:none;
    }

     .bv-survey-back-button-label, .bv-survey-arrow-next-button-label,
    .bv-survey-question-back-button-label, .bv-survey-question-next-label {
       height: 35px;
       width: 100%;
       margin-left: 0px;
    }

    .progress-bar-openSurvey-box{
         display:none;
     }

    #respondSurveyMain .progress-bar-openSurvey-box-mobile,
    #surveyConsumerSectionDIV  .progress-bar-openSurvey-box-mobile,
    #surveyConsumerQuestionDIV .progress-bar-openSurvey-box-mobile {
         display:block !important;
         /*margin-bottom: 0%;*/
         /*width: 100% !important;*/
         position: absolute;
         bottom: 0;
         margin: 0;
         height: 20px;
         top: 41px;

         span{
             top: -6px;
             position: relative;
         }
     }

    /*
     div.progress-bar-openSurvey-box-mobile {
        margin-left: 0px;
        height: 56px;
        background-color:#d6d6d6;
        margin-bottom:0px !important;
     }

     #respondSurveyMain .progress-bar-openSurvey-box-mobile {
        margin-top: 10%;
        padding-left: 0%;
     }
    */
/*
     #surveyConsumerSectionDIV  .progress-bar-openSurvey-box-mobile {
       margin-top:10%;
     }

     #surveyConsumerQuestionDIV .progress-bar-openSurvey-box-mobile {
       margin-top:12%;
     }
*/
      /*
      .progress-bar-openSurvey-box-mobile .progressValue0 {
         padding-left: 160px !important;
         color:#808080 !important;
     }*/

    .finish-later-mobile {
        display: block !important;
        position: relative;
        top: -9px;
    }

    .bv-survey-question-mobile-finish-later-label {
        width: 100%;
        height: 28px;
        border: none;
        font-weight: 500;
        font-size: 13.5px;
        line-height: 1.1em;
        color: #fff;
        outline: none !important;
        white-space: nowrap;
        overflow: hidden;
        padding: 0px 15px;
    }

    .survey-attachments-row {
        height: 177px;
        width: 100%;
    }

    .survey-attachments-row .bv-slider>ul {
        margin: 0 10px !important;
    }

    .survey-attachments-row .bv-slider>ul>li {
        width: 26%;
        height: 80%;
        margin: 10px 10px !important;
    }

    .survey-attachments-row .bv-slider > div.bv-slider-right-btn {
        right: -1% !important;
    }

    .survey-attachments-row .bv-slider > div.bv-slider-left-btn {
        left: -1% !important;
    }

    .survey-attachments-row .survey-attachment-image-container {
        width: 74px;
        height: 97px;
        margin: 0 10px 10px 0;
        box-shadow: none;
        position: relative;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        background: #fff;
    }

    .bv-question-center-yesno-div, .bv-question-center-truefalse-div, .bv-question-center-meetsdoesnotmeet-div {
        width: 90%;
    }

    .bv-question-details-commentsdiv {
        margin-left: 5%;
    }

    /*
    .bv-signature-dialog {
        top:10%;
        left:5%;
        right:5%;
    }

    .bv-signature-dialog .modal-dialog {
        height: 60% !important;
    }

    .bv-signature-dialog .signatureModal-sign-here-tooltip {
        left: 46%;
    }
    */

    .signatureModal-signature-outer{
        width: 300px;
    }

    .bv-signature-dialog{
        .modal-dialog{
            width: 100%;
        }
    }

    .signatureModal-sign-here-tooltip{
        top: calc(100% - 200px);
    }

    .surveys-top-panel .top-panel{
        padding: 0;
        height: auto;

        > div:nth-child(1){
            display: none;
        }

        > div:nth-child(2){
            padding-top: 10px;
        }
    }
    
    .survey-list{
        height: calc(100% - 30px);
    }
}